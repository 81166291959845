/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``settings.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const SettingsIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M13.125 3C12.0895 3 11.25 3.83947 11.25 4.875C11.25 5.91053 12.0895 6.75 13.125 6.75C14.1605 6.75 15 5.91053 15 4.875C15 3.83947 14.1605 3 13.125 3ZM11.0035 2.25C11.5831 1.78095 12.3213 1.5 13.125 1.5C14.989 1.5 16.5 3.01104 16.5 4.875C16.5 6.73896 14.989 8.25 13.125 8.25C12.3213 8.25 11.5831 7.96905 11.0035 7.5H4.125C2.67525 7.5 1.5 6.32475 1.5 4.875C1.5 3.42525 2.67525 2.25 4.125 2.25H11.0035ZM9.94204 3.75H4.125C3.50368 3.75 3 4.25368 3 4.875C3 5.49632 3.50368 6 4.125 6H9.94204C9.81767 5.64812 9.75 5.26946 9.75 4.875C9.75 4.48054 9.81767 4.10188 9.94204 3.75ZM1.5 13.125C1.5 11.261 3.01104 9.75 4.875 9.75C5.67874 9.75 6.41685 10.031 6.9965 10.5H13.875C15.3247 10.5 16.5 11.6753 16.5 13.125C16.5 14.5747 15.3247 15.75 13.875 15.75H6.9965C6.41685 16.2191 5.67874 16.5 4.875 16.5C3.01104 16.5 1.5 14.989 1.5 13.125ZM8.05796 14.25H13.875C14.4963 14.25 15 13.7463 15 13.125C15 12.5037 14.4963 12 13.875 12H8.05796C8.18233 12.3519 8.25 12.7305 8.25 13.125C8.25 13.5195 8.18233 13.8981 8.05796 14.25ZM4.875 11.25C3.83947 11.25 3 12.0895 3 13.125C3 14.1605 3.83947 15 4.875 15C5.91053 15 6.75 14.1605 6.75 13.125C6.75 12.0895 5.91053 11.25 4.875 11.25Z"
        transform=""
      />
    </g>
  </svg>
);

SettingsIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

SettingsIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

SettingsIcon.displayName = "SettingsIcon";

export default SettingsIcon;
