/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``bank.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const BankIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M8.75072 0.845181C8.91481 0.807893 9.08519 0.807893 9.24928 0.845181C9.44415 0.889463 9.61213 0.996793 9.69312 1.04854C9.70154 1.05392 9.70902 1.0587 9.7155 1.06275L15.3975 4.614C15.6812 4.79133 15.8132 5.13514 15.7209 5.45676C15.6287 5.77838 15.3346 6 15 6H3C2.66542 6 2.3713 5.77838 2.27906 5.45676C2.18682 5.13514 2.31878 4.79133 2.6025 4.614L8.2845 1.06275C8.29098 1.0587 8.29846 1.05392 8.30688 1.04854C8.38787 0.996793 8.55584 0.889463 8.75072 0.845181ZM9 2.38443L5.6151 4.5H12.3849L9 2.38443ZM4.5 6.75C4.91421 6.75 5.25 7.08578 5.25 7.5V13.5C5.25 13.9142 4.91421 14.25 4.5 14.25C4.08579 14.25 3.75 13.9142 3.75 13.5V7.5C3.75 7.08578 4.08579 6.75 4.5 6.75ZM7.5 6.75C7.91421 6.75 8.25 7.08578 8.25 7.5V13.5C8.25 13.9142 7.91421 14.25 7.5 14.25C7.08579 14.25 6.75 13.9142 6.75 13.5V7.5C6.75 7.08578 7.08579 6.75 7.5 6.75ZM10.5 6.75C10.9142 6.75 11.25 7.08578 11.25 7.5V13.5C11.25 13.9142 10.9142 14.25 10.5 14.25C10.0858 14.25 9.75 13.9142 9.75 13.5V7.5C9.75 7.08578 10.0858 6.75 10.5 6.75ZM13.5 6.75C13.9142 6.75 14.25 7.08578 14.25 7.5V13.5C14.25 13.9142 13.9142 14.25 13.5 14.25C13.0858 14.25 12.75 13.9142 12.75 13.5V7.5C12.75 7.08578 13.0858 6.75 13.5 6.75ZM1.5 15.75C1.5 15.3358 1.83579 15 2.25 15H15.75C16.1642 15 16.5 15.3358 16.5 15.75C16.5 16.1642 16.1642 16.5 15.75 16.5H2.25C1.83579 16.5 1.5 16.1642 1.5 15.75Z"
        transform=""
      />
    </g>
  </svg>
);

BankIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

BankIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

BankIcon.displayName = "BankIcon";

export default BankIcon;
