import { useDropdown as useYukaDropdown } from "yuka";
import useDropdownContext from "./internal/useDropdownContext";

/**
 * Utility that mimics the interface for Yuka's useDropdown hook, but behind the scenes registers
 * the dropdown function with the global state manager so that it can be toggled on and off
 * when hotkeys are used throughout the site.
 *
 * @param renderContents - a render prop for the contents of the dropdown
 * @param userConfig - Config object for dropdown options.
 *   - defaultAbove - prefer above
 *   - orientation - position vertically overlapping an element or horizontally
 *   if enough space is available
 */
const useDropdown = (renderContents, userConfig = {}) => {
  const [dropdownElement, dropdownRef, toggleDropdown] = useYukaDropdown(
    renderContents,
    userConfig
  );
  return useDropdownContext(
    dropdownElement,
    dropdownRef,
    toggleDropdown,
    userConfig
  );
};

export default useDropdown;
