/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``expand.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ExpandIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M3 4.06066L3 6.75C3 7.16421 2.66421 7.5 2.25 7.5C1.83579 7.5 1.5 7.16421 1.5 6.75L1.5 2.25C1.5 1.83579 1.83579 1.5 2.25 1.5L6.75 1.5C7.16421 1.5 7.5 1.83579 7.5 2.25C7.5 2.66421 7.16421 3 6.75 3L4.06066 3L7.28033 6.21967C7.57322 6.51256 7.57322 6.98744 7.28033 7.28033C6.98744 7.57322 6.51256 7.57322 6.21967 7.28033L3 4.06066ZM11.25 3C10.8358 3 10.5 2.66421 10.5 2.25C10.5 1.83579 10.8358 1.5 11.25 1.5H15.75C16.1642 1.5 16.5 1.83579 16.5 2.25V6.75C16.5 7.16421 16.1642 7.5 15.75 7.5C15.3358 7.5 15 7.16421 15 6.75V4.06066L11.7803 7.28033C11.4874 7.57322 11.0126 7.57322 10.7197 7.28033C10.4268 6.98744 10.4268 6.51256 10.7197 6.21967L13.9393 3H11.25ZM2.25 10.5C2.66421 10.5 3 10.8358 3 11.25L3 13.9393L6.21967 10.7197C6.51256 10.4268 6.98744 10.4268 7.28033 10.7197C7.57322 11.0126 7.57322 11.4874 7.28033 11.7803L4.06066 15H6.75C7.16421 15 7.5 15.3358 7.5 15.75C7.5 16.1642 7.16421 16.5 6.75 16.5H2.25C2.05109 16.5 1.86032 16.421 1.71967 16.2803C1.57902 16.1397 1.5 15.9489 1.5 15.75L1.5 11.25C1.5 10.8358 1.83579 10.5 2.25 10.5ZM13.9393 15L10.7197 11.7803C10.4268 11.4874 10.4268 11.0126 10.7197 10.7197C11.0126 10.4268 11.4874 10.4268 11.7803 10.7197L15 13.9393V11.25C15 10.8358 15.3358 10.5 15.75 10.5C16.1642 10.5 16.5 10.8358 16.5 11.25V15.75C16.5 16.1642 16.1642 16.5 15.75 16.5H11.25C10.8358 16.5 10.5 16.1642 10.5 15.75C10.5 15.3358 10.8358 15 11.25 15H13.9393Z"
        transform=""
      />
    </g>
  </svg>
);

ExpandIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ExpandIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

ExpandIcon.displayName = "ExpandIcon";

export default ExpandIcon;
