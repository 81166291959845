/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``arrow-up-right.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ArrowUpRightIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M5.25 6C4.83579 6 4.5 5.66421 4.5 5.25C4.5 4.83579 4.83579 4.5 5.25 4.5H12.75C13.1642 4.5 13.5 4.83579 13.5 5.25V12.75C13.5 13.1642 13.1642 13.5 12.75 13.5C12.3358 13.5 12 13.1642 12 12.75V7.06066L5.78033 13.2803C5.48744 13.5732 5.01256 13.5732 4.71967 13.2803C4.42678 12.9874 4.42678 12.5126 4.71967 12.2197L10.9393 6H5.25Z"
        transform=""
      />
    </g>
  </svg>
);

ArrowUpRightIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ArrowUpRightIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

ArrowUpRightIcon.displayName = "ArrowUpRightIcon";

export default ArrowUpRightIcon;
