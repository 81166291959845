import { css } from "styled-components";

import { ColorPalette, YukaColorPalette } from "../../StylingConstants";
import { getThemeColor } from "../../ThemeContext";
import { FontColors } from "../../Typography";
import { ButtonStyles } from "../constants";

const DEFAULT_BUTTON_CONTAINER = css`
  border-radius: 16px;
  padding: 0 12px;
  height: 32px;
`;

const OUTLINE_DISABLED = css`
  border: 1px solid ${ColorPalette.white15};
  background: transparent;
  ${FontColors.theme30}
`;

const NON_OUTLINE_DISABLED = css`
  border: 1px solid transparent;
  background: ${ColorPalette.white15};
  cursor: default;
  ${FontColors.theme30}
`;

const OUTLINE_DEFAULT = css`
  background: transparent;
  ${FontColors.theme80}
  border: 1px solid ${ColorPalette.white30};
`;

const OUTLINE_HOVER = css`
  background: ${YukaColorPalette.hoverTransparent};
`;

const OUTLINE_ACTIVE = css`
  background: ${YukaColorPalette.activeTransparent};
`;

const RAISED_DEFAULT = css`
  background: ${ColorPalette.white10};
  color: ${ColorPalette.white80};
  border: none;
`;

const RAISED_HOVER = css`
  background: ${ColorPalette.white15};
`;

const RAISED_ACTIVE = css`
  background: rgba(255, 255, 255, 0.17);
`;

const RAISED_SELECTED_DEFAULT = css`
  background: ${ColorPalette.blue200};
  color: ${ColorPalette.blue500};
  border: none;
`;

const RAISED_SELECTED_HOVER = css`
  background: ${ColorPalette.blue300};
`;

const RAISED_SELECTED_ACTIVE = css`
  background: ${ColorPalette.blue300};
  color: ${ColorPalette.blue700};

  svg path {
    fill: ${ColorPalette.blue700};
  }
`;

const CTA_DEFAULT = css`
  background: ${props => getThemeColor(props.theme, "branding500")};
  color: ${ColorPalette.black80};
  border: 1px solid transparent;
`;

const CTA_HOVER = css`
  background: ${props => getThemeColor(props.theme, "branding600")};
`;

const CTA_ACTIVE = css`
  background: ${props => getThemeColor(props.theme, "branding700")};
`;

const BUY_DEFAULT = css`
  background: ${props => getThemeColor(props.theme, "green")};
  color: ${ColorPalette.black80};
  border: 1px solid transparent;
`;

const BUY_HOVER = css`
  background: ${props => getThemeColor(props.theme, "greenHover")};
`;

const BUY_ACTIVE = css`
  background: ${props => getThemeColor(props.theme, "greenActive")};
`;

const SELL_DEFAULT = css`
  background: ${props => getThemeColor(props.theme, "red")};
  color: ${ColorPalette.white100};
  border: 1px solid transparent;
`;

const SELL_HOVER = css`
  background: ${props => getThemeColor(props.theme, "redHover")};
`;

const SELL_ACTIVE = css`
  background: ${props => getThemeColor(props.theme, "redActive")};
`;

const DEFAULT_BUTTON_THEME = {
  [ButtonStyles.CTA]: {
    container: DEFAULT_BUTTON_CONTAINER,
    disabled: NON_OUTLINE_DISABLED,
    default: CTA_DEFAULT,
    hover: CTA_HOVER,
    active: CTA_ACTIVE,
    iconSize: 16,
    // TODO we do not have selected support for this button style.
    selectedDefault: CTA_DEFAULT,
    selectedHover: CTA_HOVER,
    selectedActive: CTA_ACTIVE,
  },
  [ButtonStyles.RAISED]: {
    container: DEFAULT_BUTTON_CONTAINER,
    disabled: NON_OUTLINE_DISABLED,
    default: RAISED_DEFAULT,
    hover: RAISED_HOVER,
    active: RAISED_ACTIVE,
    selectedDefault: RAISED_SELECTED_DEFAULT,
    selectedHover: RAISED_SELECTED_HOVER,
    selectedActive: RAISED_SELECTED_ACTIVE,
    iconSize: 18,
  },
  [ButtonStyles.OUTLINE]: {
    container: DEFAULT_BUTTON_CONTAINER,
    disabled: OUTLINE_DISABLED,
    default: OUTLINE_DEFAULT,
    hover: OUTLINE_HOVER,
    active: OUTLINE_ACTIVE,
    iconSize: 16,
    // TODO we do not have selected support for this button style.
    selectedDefault: OUTLINE_DEFAULT,
    selectedHover: OUTLINE_HOVER,
    selectedActive: OUTLINE_ACTIVE,
  },
  [ButtonStyles.BUY]: {
    container: DEFAULT_BUTTON_CONTAINER,
    disabled: NON_OUTLINE_DISABLED,
    default: BUY_DEFAULT,
    hover: BUY_HOVER,
    active: BUY_ACTIVE,
    iconSize: 16,
    // TODO we do not have selected support for this button style.
    selectedDefault: BUY_DEFAULT,
    selectedHover: BUY_HOVER,
    selectedActive: BUY_ACTIVE,
  },
  [ButtonStyles.SELL]: {
    container: DEFAULT_BUTTON_CONTAINER,
    disabled: NON_OUTLINE_DISABLED,
    default: SELL_DEFAULT,
    hover: SELL_HOVER,
    active: SELL_ACTIVE,
    iconSize: 16,
    // TODO we do not have selected support for this button style.
    selectedDefault: SELL_DEFAULT,
    selectedHover: SELL_HOVER,
    selectedActive: SELL_ACTIVE,
  },
  fallback: {
    container: DEFAULT_BUTTON_CONTAINER,
    disabled: NON_OUTLINE_DISABLED,
    default: OUTLINE_DEFAULT,
    hover: OUTLINE_HOVER,
    active: OUTLINE_ACTIVE,
    iconSize: 16,
    // TODO we do not have selected support for this button style.
    selectedDefault: OUTLINE_DEFAULT,
    selectedHover: OUTLINE_HOVER,
    selectedActive: OUTLINE_ACTIVE,
  },
};

export default DEFAULT_BUTTON_THEME;
