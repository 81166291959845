import { createContext, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import useFetch from "../api/useFetch";
import { API_ENDPOINTS } from "../api/constants";
import { useDispatch, ACTIONS } from "../routes/StateProvider";
import { AuthContext } from "../auth";
import useWrite from "../api/useWrite";
import { BANNER_TYPE } from "../banner/constants";

const AgreementContext = createContext(null);

/**
 * This AgreementProvider component wraps all routes in HD to provide agreement-related operations.
 *
 * Checks for updated agreement files, and dispatches actions to show banners notifying
 * the user about the updates in the agreements. Users can interact with
 * these banners, specifically closing the banners will sign the agreement file
 *
 * @param {React.Component} children - the child components to render
 * @returns {React.Element}
 */
const AgreementProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);

  const signAgreement = useWrite(API_ENDPOINTS.SIGN_AGREEMENT(), false, {
    silent: true,
  });
  const agreementQuery = useFetch(
    API_ENDPOINTS.UPDATED_AGREEMENT_FILES(),
    {},
    { enabled: !!user }
  );

  const agreements = agreementQuery.cleanedData;

  const onClose = (agreement) => {
    signAgreement.mutate({ agreement_type: agreement.agreement_type });
  };

  useEffect(() => {
    if (!Array.isArray(agreements)) return;

    agreements.forEach((agreement) => {
      dispatch({
        type: ACTIONS.addBanner,
        banner: {
          id: agreement.id,
          color: BANNER_TYPE.INFORMATIONAL,
          message: `We have updated our ${agreement.agreement_type}, which governs your use of ZXData. By using this website and ZXData, you are agreeing to our updated ${agreement.agreement_type}.`,
          onClose: () => onClose(agreement),
        },
      });
    });
  }, [agreements]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AgreementContext.Provider value={{ agreements }}>
      {children}
    </AgreementContext.Provider>
  );
};

AgreementProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AgreementProvider;
