import _ from "lodash";

import getDateDividedData from "./getDateDividedData";
import makePriceDataTimeSeries from "./makePriceDataTimeSeries";

import {
  COMPANY_PROFILE_LINE_CHART_API_DATA_FIELDS,
  INDICATOR_TYPES,
  KEY_COMPANY_SERIES_TYPE,
} from "../constants";

/**
 * This function creates all the indicator timeseries data for the key company in the form:
 *
 * {
 *   [indicator1]: {
 *     3M: [dataPoint],
 *     6M: ...,
 *     ...
 *   },
 *   [indicator2]: {
 *     ...
 *   },
 *   ...
 * }
 *
 * @param {Array<object>} rawPriceData - an array of raw price data
 * @param {Array<object>} monthlyVolumeAggregateData - an array of monthly volume data
 * @returns {Array<object>} superchartData - maps superchart date ranges to price data timeseries
 */
const makeAllIndicatorData = (rawPriceData, monthlyVolumeAggregateData) => {
  const { TIME_FIELD } =
    COMPANY_PROFILE_LINE_CHART_API_DATA_FIELDS[KEY_COMPANY_SERIES_TYPE];
  const dateDividedData = getDateDividedData(rawPriceData, TIME_FIELD);
  const dateDividedVolumeData = getDateDividedData(
    monthlyVolumeAggregateData,
    TIME_FIELD
  );

  return _.mapValues(INDICATOR_TYPES, ({ apiDataKey, isVolume }) => {
    // Special case where we need to pull from `monthlyVolumeAggregateData` instead.
    // return _.mapValues()
    return _.mapValues(
      isVolume ? dateDividedVolumeData : dateDividedData,
      (dataSeries) =>
        makePriceDataTimeSeries(dataSeries, TIME_FIELD, apiDataKey, [], {
          isVolume,
        })
    );
  });
};

export default makeAllIndicatorData;
