import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Card, { CardStyles } from "./Card";
import { Fonts } from "../Typography";
import { PrimaryColorPalette } from "../StylingConstants";

const StyledValueContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: baseline;
`;

const StyledSupportContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

const StyledTrailingContent = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  line-height: 0;
`;

/**
 * An opinionated card style for rendering a single value
 */
const ValueCard = props => (
  <Card className={props.className} cardStyle={CardStyles.VALUE}>
    <Fonts.Overlinetheme50>{props.headline}</Fonts.Overlinetheme50>
    <StyledValueContainer>
      <Fonts.Headline3theme80>{props.value}</Fonts.Headline3theme80>
      {props.unit && <Fonts.Overlinetheme30>{props.unit}</Fonts.Overlinetheme30>}
    </StyledValueContainer>
    {props.supportingText && (
      <StyledSupportContainer>
        {props.supportingIcon && (
          <props.supportingIcon color={props.supportingIconColor} size={16} />
        )}
        <Fonts.Caption2theme50>{props.supportingText}</Fonts.Caption2theme50>
      </StyledSupportContainer>
    )}
    {props.additionalText && <Fonts.Caption2theme50>{props.additionalText}</Fonts.Caption2theme50>}
    {props.headerTrailingContent && (
      <StyledTrailingContent>{props.headerTrailingContent}</StyledTrailingContent>
    )}
  </Card>
);

ValueCard.propTypes = {
  headline: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
  className: PropTypes.string,
  unit: PropTypes.node,
  supportingText: PropTypes.node,
  supportingIcon: PropTypes.func,
  supportingIconColor: PropTypes.oneOf(Object.values(PrimaryColorPalette)),
  additionalText: PropTypes.node,
  headerTrailingContent: PropTypes.node,
};

ValueCard.defaultProps = {
  supportingIconColor: PrimaryColorPalette.white80,
};

export default ValueCard;
