import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { PrimaryColorPalette } from "../StylingConstants";
import Surface from "./internal/Surface";

import { YukaThemeProvider } from "../ThemeContext";
import { useRenderDynamicPosition } from "../utils/useDynamicPosition";

const MENU_WIDTH = 240;
const MENU_HEIGHT = 320;

const MenuDivider = styled.div`
  background: ${PrimaryColorPalette.white15};
  margin: 8px 0;
  height: 1px;
  width: 100%;
`;

const StyledSurface = styled(Surface)`
  border-radius: 12px;
  box-sizing: border-box;
  max-height: ${MENU_HEIGHT}px;
  overflow-y: auto;
  padding: 12px 0;
  min-width: ${MENU_WIDTH}px;
  ${props => props.$style}
`;

const _Menu = React.forwardRef((props, ref) => {
  const render = useRenderDynamicPosition();
  const menu = (
    <YukaThemeProvider theme={{ componentVersion: 2 }}>
      <StyledSurface
        role={props.role}
        tabIndex="-1"
        $style={props.style}
        className={props.className}
        ref={ref}
        elevated
      >
        {props.children}
      </StyledSurface>
    </YukaThemeProvider>
  );
  return render(menu, props.style);
});

_Menu.propTypes = {
  className: PropTypes.string,
  /** Positioning styles computed at runtime */
  style: PropTypes.object,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  role: PropTypes.string,
};

_Menu.defaultProps = {
  role: "menu",
};

const Menu = styled(_Menu)``;

Menu.displayName = "Menu";

export default Menu;
export { MENU_WIDTH, MenuDivider };
