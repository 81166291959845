import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  Fonts,
  IconButton,
  DotsVerticalIcon,
  IconButtonStyles,
  useAnchoredMenu,
  TrashIcon,
  PencilIcon,
  LinkExternalIcon,
  PageSection,
  useCurrentTab,
  PlusIcon,
  ButtonStyles,
  Button,
  TruncatedText,
} from "yuka";

import AddPortfolioCompanyModal from "./AddPortfolioCompanyModal";
import { MODE_CUSTOM_VALUATION, MODE_ZX_INDEX_VALUE } from "./constants";
import CreatePortfolioModal from "./CreatePortfolioModal";
import DeletePortfolioModal from "./DeletePortfolioModal";
import {
  usePortfolio,
  usePortfolioValue,
  useLastWeekPortfolioValue,
  usePortfolioUnrealizedGain,
  usePortfolioCompanies,
} from "./hooks";
import usePortfolioUnrealizedGainFromCustomValuation from "./hooks/usePortfolioUnrealizedGainFromCustomValuation";
import usePortfolioValueFromCustomValuation from "./hooks/usePortfolioValueFromCustomValuation";
import PortfolioSummaryTable from "./PortfolioSummaryTable";
import {
  PortfolioHeaderStats,
  StyledPortfolioNameHeader,
} from "./StyledComponents";

import AxiosInstance from "../api/AxiosInstance";
import { API_ENDPOINTS } from "../api/constants";
import { PAGE_WIDTH } from "../constants";
import { Tab, TabGroup, TabList } from "../hdYuka";
import { ACTIONS, useDispatch } from "../routes/StateProvider";
import {
  expandedMoneyFormat,
  percentFormat,
} from "../utils/displayFormatUtils";
import LoadingSpinner from "../utils/LoadingSpinner";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";
import { useWalkthrough, Walkthrough } from "../Walkthrough";

const StyledPortfolioLayout = styled.div`
  ${PAGE_WIDTH}
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
`;

const StyledPortfolioStat = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  &:not(:first-child) {
    margin-bottom: 6px;
  }
`;

const TruncatedPortfolioName = styled(TruncatedText)`
  max-width: 750px;
`;

const valueWithChangeDisplay = (value, change) => {
  if (value === null || change === null) {
    return <Fonts.Headline4theme30>-- (--%)</Fonts.Headline4theme30>;
  }
  if (value > 0) {
    return (
      <Fonts.Headline4buy>
        +{expandedMoneyFormat(value, 2, 2)} (+{percentFormat(change)})
      </Fonts.Headline4buy>
    );
  }
  if (value < 0) {
    return (
      <Fonts.Headline4sell>
        {expandedMoneyFormat(value, 2, 2)} ({percentFormat(change)})
      </Fonts.Headline4sell>
    );
  }
  return (
    <Fonts.Headline4theme80>
      {expandedMoneyFormat(value, 2, 2)} (+{percentFormat(change)})
    </Fonts.Headline4theme80>
  );
};

const PortfolioModeContext = React.createContext(MODE_ZX_INDEX_VALUE);

const MODAL_DELETE_PORTFOLIO = "deletePortfolio";
const MODAL_RENAME_PORTFOLIO = "renamePortfolio";
const MODAL_ADD_COMPANY = "addCompany";

const WALKTHROUGH_STEPS = [
  {
    title: "Add more companies",
    text:
      "You can add more companies to your portfolio and easily monitor the performance of your " +
      "holdings, all in one place.",
    positionSelector: "#add-company-to-portfolio-button",
  },
  {
    title: "Track your holdings",
    text: "Expand each row to add or edit individual share lots for your portfolio companies.",
    positionSelector: "#expand-table-row",
  },
  {
    title: "Custom valuations",
    text:
      "Switch to custom valuation mode to get a more personalized view of your portfolio " +
      "companies based on your valuations.",
    positionSelector: `#${MODE_CUSTOM_VALUATION}`,
  },
  {
    title: "Create multiple portfolios",
    text:
      "Organize your investments by creating multiple portfolios for different strategies or " +
      "clients.",
    positionSelector: "#create-portfolio-button",
  },
];

const PortfolioProfile = () => {
  const { id: portfolioId } = useParams();
  const dispatch = useDispatch();
  const currentMode = useCurrentTab("mode");
  const [portfolio, portfolioIsLoading] = usePortfolio();
  const [portfolioCompanies, portfolioCompaniesAreLoading] =
    usePortfolioCompanies(portfolioId);
  const { walkthroughShowing, completeWalkthrough } = useWalkthrough(
    "walkthrough_portfolios"
  );
  const [exporting, setExporting] = useState(false);
  const [modal, setModal] = useState(null);

  const [actionsMenu, triggerRef, toggleDisplay] = useAnchoredMenu({
    menuItems: [
      {
        onClick: () => {
          MixpanelEvents.openRenamePortfolioModal();
          setModal(MODAL_RENAME_PORTFOLIO);
        },
        key: "rename",
        text: "Rename",
        icon: PencilIcon,
      },
      {
        onClick: () => {
          MixpanelEvents.openDeletePortfolioModal();
          setModal(MODAL_DELETE_PORTFOLIO);
        },
        key: "delete",
        text: "Delete",
        icon: TrashIcon,
      },
      {
        onClick: () => {
          setExporting(true);
          MixpanelEvents.exportPortfolio(
            "portfolio",
            currentMode,
            portfolio?.apiId,
            portfolio?.name,
            portfolioCompanies?.length
          );
          AxiosInstance.get(API_ENDPOINTS.DOWNLOAD_PORTFOLIO(portfolio?.apiId))
            .then(() => {
              dispatch({
                type: ACTIONS.addToast,
                message:
                  "Your portfolio export is being generated, please check your email in a couple of " +
                  "minutes for receipt of the export.",
              });
            })
            .catch(() => {
              dispatch({
                type: ACTIONS.addToast,
                message:
                  "Failed to download portfolio. Please try again or contact support.",
              });
            })
            .finally(() => {
              setExporting(false);
              toggleDisplay();
            });
        },
        key: "export",
        text: "Export",
        icon: LinkExternalIcon,
      },
    ],
  });

  const portfolioValueFromZXIndexValue = usePortfolioValue(portfolio?.apiId);
  const portfolioValueFromCustomValuation =
    usePortfolioValueFromCustomValuation(portfolio?.apiId);
  const lastWeekPortfolioValue = useLastWeekPortfolioValue(portfolio?.apiId);
  const totalUnrealizedGain = usePortfolioUnrealizedGain(portfolio?.apiId);
  const totalUnrealizedGainFromCustomValuation =
    usePortfolioUnrealizedGainFromCustomValuation(portfolio?.apiId);

  const unrealizedGainPercentChange = useMemo(() => {
    const portfolioValue =
      currentMode === MODE_ZX_INDEX_VALUE
        ? portfolioValueFromZXIndexValue
        : portfolioValueFromCustomValuation;
    const unrealizedGainValue =
      currentMode === MODE_ZX_INDEX_VALUE
        ? totalUnrealizedGain
        : totalUnrealizedGainFromCustomValuation;
    // Reverse engineer cost basis and determine overall percentage change.
    if (portfolioValue === null || unrealizedGainValue === null) {
      return null;
    }
    const costBasis = portfolioValue - unrealizedGainValue;
    if (costBasis === 0) {
      return null; // Infinity percent change
    }
    return (portfolioValue - costBasis) / costBasis;
  }, [
    currentMode,
    portfolioValueFromCustomValuation,
    portfolioValueFromZXIndexValue,
    totalUnrealizedGain,
    totalUnrealizedGainFromCustomValuation,
  ]);

  const [weekChange, percentChange] = useMemo(() => {
    if (
      portfolioValueFromZXIndexValue === null ||
      lastWeekPortfolioValue === null
    ) {
      return [null, null];
    }
    if (lastWeekPortfolioValue === 0) {
      return [portfolioValueFromZXIndexValue, null]; // Infinity percent change.
    }
    return [
      portfolioValueFromZXIndexValue - lastWeekPortfolioValue,
      (portfolioValueFromZXIndexValue - lastWeekPortfolioValue) /
        lastWeekPortfolioValue,
    ];
  }, [portfolioValueFromZXIndexValue, lastWeekPortfolioValue]);

  const portfolioValueDisplay = useMemo(() => {
    const value =
      currentMode === MODE_ZX_INDEX_VALUE
        ? portfolioValueFromZXIndexValue
        : portfolioValueFromCustomValuation;

    return value ? expandedMoneyFormat(value, 2, 2) : "$0.00";
  }, [
    currentMode,
    portfolioValueFromZXIndexValue,
    portfolioValueFromCustomValuation,
  ]);

  const unrealizedGainDisplay = useMemo(() => {
    const unrealizedGainValue =
      currentMode === MODE_ZX_INDEX_VALUE
        ? totalUnrealizedGain
        : totalUnrealizedGainFromCustomValuation;
    return valueWithChangeDisplay(
      unrealizedGainValue,
      unrealizedGainPercentChange * 100
    );
  }, [
    currentMode,
    totalUnrealizedGain,
    totalUnrealizedGainFromCustomValuation,
    unrealizedGainPercentChange,
  ]);

  if (portfolioIsLoading) {
    return <LoadingSpinner />;
  }

  if (portfolio === null && portfolioId !== null) {
    if (portfolioId) {
      return <Fonts.Body1theme30>An error has occurred.</Fonts.Body1theme30>;
    }
    return null;
  }

  return (
    <PortfolioModeContext.Provider
      value={{ mode: currentMode || MODE_ZX_INDEX_VALUE }}
    >
      <StyledPortfolioLayout>
        {portfolioCompanies?.length > 0 && walkthroughShowing && (
          <Walkthrough
            name="portfolios"
            steps={WALKTHROUGH_STEPS}
            onFinish={completeWalkthrough}
          />
        )}
        <StyledPortfolioNameHeader>
          <div>
            <Fonts.Headline3theme80>
              <TruncatedPortfolioName>{portfolio.name}</TruncatedPortfolioName>
            </Fonts.Headline3theme80>
            <TabList variant="buttonGroup" queryParam="mode">
              <TabGroup defaultTabId={MODE_ZX_INDEX_VALUE} resizeable={false}>
                <Tab
                  id={MODE_ZX_INDEX_VALUE}
                  onSelectTab={() => {
                    if (currentMode !== MODE_ZX_INDEX_VALUE) {
                      MixpanelEvents.togglePortfolioMode(
                        currentMode,
                        MODE_ZX_INDEX_VALUE
                      );
                    }
                  }}
                >
                  ZX Index Value
                </Tab>
                <Tab
                  id={MODE_CUSTOM_VALUATION}
                  onSelectTab={() => {
                    if (currentMode !== MODE_CUSTOM_VALUATION) {
                      MixpanelEvents.togglePortfolioMode(
                        currentMode,
                        MODE_CUSTOM_VALUATION
                      );
                    }
                  }}
                >
                  Custom valuation
                </Tab>
              </TabGroup>
            </TabList>
          </div>
          <div>
            {!portfolioCompaniesAreLoading && portfolioCompanies?.length !== 0 && (
              <Button
                id="add-company-to-portfolio-button"
                buttonStyle={ButtonStyles.RAISED}
                leadingIcon={PlusIcon}
                onClick={() => {
                  setModal(MODAL_ADD_COMPANY);
                  MixpanelEvents.openAddCompanyToPortfolioModal("portfolio");
                }}
              >
                Add a company
              </Button>
            )}
            <IconButton
              ref={triggerRef}
              buttonStyle={IconButtonStyles.RAISED}
              icon={DotsVerticalIcon}
              onClick={toggleDisplay}
            />
          </div>
        </StyledPortfolioNameHeader>
        <PortfolioHeaderStats>
          <StyledPortfolioStat>
            <Fonts.Overlinetheme50>Portfolio value</Fonts.Overlinetheme50>
            <Fonts.Headline5theme80>
              {portfolioValueDisplay}
            </Fonts.Headline5theme80>
          </StyledPortfolioStat>
          {currentMode === MODE_ZX_INDEX_VALUE && (
            <StyledPortfolioStat>
              <Fonts.Overlinetheme50>Week change</Fonts.Overlinetheme50>
              {valueWithChangeDisplay(weekChange, percentChange * 100)}
            </StyledPortfolioStat>
          )}
          <StyledPortfolioStat>
            <Fonts.Overlinetheme50>Unrealized gain/loss</Fonts.Overlinetheme50>
            {unrealizedGainDisplay}
          </StyledPortfolioStat>
        </PortfolioHeaderStats>
        <PortfolioSummaryTable />
        <PageSection>{actionsMenu}</PageSection>
        {modal === MODAL_DELETE_PORTFOLIO && (
          <DeletePortfolioModal
            source="portfolio"
            onClose={() => setModal(null)}
          />
        )}
        {modal === MODAL_RENAME_PORTFOLIO && (
          <CreatePortfolioModal
            source="portfolio"
            portfolio={portfolio}
            onClose={() => setModal(null)}
          />
        )}
        {modal === MODAL_ADD_COMPANY && (
          <AddPortfolioCompanyModal
            mode={currentMode}
            onClose={() => setModal(null)}
          />
        )}
        {exporting && <LoadingSpinner />}
      </StyledPortfolioLayout>
    </PortfolioModeContext.Provider>
  );
};

export default PortfolioProfile;

export { PortfolioModeContext };
