import React from "react";
import PropTypes from "prop-types";

import Select from "../Select/Select";

const NO_SEARCH_MAX = 8;

/**
 * This is a standard select component that has been upgraded with a few usability tools, most
 * notably the ability to search the dropdown for a value you are looking for and allowing multiple
 * values to be selected and optionally removed in an easy to use manner.
 *
 */
const SelectInput = React.forwardRef(
  ({ onChange, value, name, multiple, searchable, ...subprops }, ref) => {
    const isSearchable = Boolean(
      searchable || (subprops.options || []).length > NO_SEARCH_MAX || subprops.loadOptions
    );
    return (
      <Select
        ref={ref}
        name={name}
        value={value}
        onChange={onChange}
        multiple={multiple}
        searchable={isSearchable}
        {...subprops}
      />
    );
  }
);

SelectInput.propTypes = {
  value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  onChange: PropTypes.func,
  name: PropTypes.string,
  multiple: PropTypes.bool,
  searchable: PropTypes.bool,
};

export default SelectInput;
