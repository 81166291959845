import _ from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import styled from "styled-components";
import {
  ColorPalette,
  FontColors,
  Fonts,
  HyperLink,
  LinkStyles,
  List,
  ListItem,
  PageSection,
} from "yuka";

import { useDropdown } from "../../../../hdYuka";
import { EMPTY_VALUE_PLACEHOLDER } from "../constants";
import { investorNameSort } from "../utils";

const ENTITY_LOADING = "Loading...";

const StyledList = styled(List)`
  height: unset;
  max-height: 400px;
  margin: -16px 0; // dropdown component has padding that can't be overwritten
  padding: 0 16px;
  width: 228px;
`;

const StyledListItem = styled(ListItem)`
  border-bottom: 1px dashed ${ColorPalette.white15};
  padding: 0;
`;

const EntityName = styled.span`
  ${({ $loading }) => ($loading ? FontColors.theme50 : FontColors.theme80)};
`;

const InvestorListHyperlinkCell = ({ value: fundingRound, entityProfiles }) => {
  const investorList = useMemo(() => {
    if (!_.isEmpty(fundingRound)) {
      return fundingRound.investors
        .map((investor) => ({
          investor: entityProfiles[investor[1]]?.name || ENTITY_LOADING,
        }))
        .sort(investorNameSort);
    }
    return [];
  }, [entityProfiles, fundingRound]);

  const [dropdownElement, triggerRef, toggleDisplay] = useDropdown(() => (
    <StyledList>
      <StyledListItem
        key="header"
        text={
          <Fonts.Overlinetheme50>
            {fundingRound.event} Investors
          </Fonts.Overlinetheme50>
        }
      />
      {investorList.map((investor, index) => (
        <StyledListItem
          key={`investor_${index}`}
          text={
            <EntityName $loading={investor.investor === ENTITY_LOADING}>
              {investor.investor}
            </EntityName>
          }
        />
      ))}
    </StyledList>
  ));

  // Determine if we've loaded all the investor profiles.
  const allInvestorsLoaded =
    fundingRound.investors?.length > 0 &&
    _.some(fundingRound.investors, (investor) => investor[1] in entityProfiles);

  return (
    <>
      {allInvestorsLoaded ? (
        <HyperLink
          ref={triggerRef}
          linkStyle={
            fundingRound.parent_funding_round
              ? LinkStyles.INVISIBLE
              : LinkStyles.DEFAULT
          }
          onClick={() => toggleDisplay(fundingRound)}
        >
          {fundingRound.investors.length} Investor
          {fundingRound.investors.length > 1 ? "s" : ""}
        </HyperLink>
      ) : (
        EMPTY_VALUE_PLACEHOLDER
      )}
      <PageSection>{dropdownElement}</PageSection>
    </>
  );
};

InvestorListHyperlinkCell.propTypes = {
  value: PropTypes.shape({
    investors: PropTypes.array,
    parent_funding_round: PropTypes.string,
    event: PropTypes.string,
  }),
  entityProfiles: PropTypes.shape({
    name: PropTypes.string,
  }),
};

export default InvestorListHyperlinkCell;
