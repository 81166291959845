import { useMemo, useState } from "react";
import _ from "lodash";

import { HALF_SECOND } from "./constants";

/**
 * A simple wrapper for useState that debounces the state update using lodash's debounce function,
 * and the specified delay. This function should be used when dealing with state changes that
 * occur frequently, and trigger long-running tasks.
 *
 * The main usage is a search box that triggers an API fetch. If we do not debounce the component's
 * state updates, we will trigger an API fetch for every keystroke. This is not ideal, as we will
 * be making many unnecessary API calls. Instead, we can debounce the state update, and only trigger
 * the API fetch after the user has stopped typing for a specified amount of time. Note that the
 * setter will be equipped with the `.cancel()` method as with any _.debounced function.
 *
 * @param initialValue
 * @param delay - A number of milliseconds to wait before sending a request.
 * @returns {Array} - the state value, a setter function that will debounce the
 *  state update, should be used as if it were a regular call to useState, and the regular
 *  setter in case the client sometimes wants to skip the debounced logic.
 */
const useDebouncedState = (initialValue, delay = HALF_SECOND) => {
  const [state, setState] = useState(initialValue);

  const debouncedSetter = useMemo(
    () => _.debounce(setState, delay, { leading: false, trailing: true }),
    [setState, delay]
  );
  // Sneaks in the raw setter as well if you're interested in sometimes skipping the debouncing.
  return [state, debouncedSetter, setState];
};

export default useDebouncedState;
