import PropTypes from "prop-types";
import { Modal, Fonts, Button, ModalStyles, ButtonStyles } from "yuka";

import { StyledModalButtonRow, StyledModalContent } from "./StyledComponents";

const AbandonShareLotsModal = ({ onClose, abandonShareLots }) => (
  <Modal
    modalStyle={ModalStyles.MINIMAL}
    title="Search for a new company?"
    onClose={onClose}
  >
    <StyledModalContent $width={400}>
      <Fonts.Headline2theme80>Search for a new company?</Fonts.Headline2theme80>
      <Fonts.Body1theme80>
        You will lose all entered share lots if you search for a new company
        without saving. Are you sure you want to proceed?
      </Fonts.Body1theme80>
    </StyledModalContent>
    <StyledModalButtonRow>
      <Button buttonStyle={ButtonStyles.RAISED} onClick={onClose}>
        Cancel
      </Button>
      <Button buttonStyle={ButtonStyles.CTA} onClick={abandonShareLots}>
        Proceed
      </Button>
    </StyledModalButtonRow>
  </Modal>
);

AbandonShareLotsModal.propTypes = {
  onClose: PropTypes.func,
  abandonShareLots: PropTypes.func,
};

export default AbandonShareLotsModal;
