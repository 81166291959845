/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``arrow-circle-broken-up-right.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ArrowCircleBrokenUpRightIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M13.773 4.22699C11.137 1.59095 6.86309 1.59095 4.22705 4.22699C2.46105 5.993 1.87721 8.49452 2.47916 10.7486C2.58604 11.1488 2.34825 11.5599 1.94806 11.6667C1.54787 11.7736 1.13682 11.5358 1.02995 11.1356C0.29552 8.38548 1.00646 5.32627 3.16639 3.16633C6.38822 -0.0554945 11.6118 -0.0554947 14.8337 3.16633C18.0555 6.38816 18.0555 11.6118 14.8337 14.8336C12.6737 16.9935 9.61451 17.7045 6.86435 16.97C6.46416 16.8632 6.22638 16.4521 6.33325 16.0519C6.44012 15.6517 6.85118 15.4139 7.25137 15.5208C9.50546 16.1228 12.007 15.5389 13.773 13.7729C16.409 11.1369 16.409 6.86303 13.773 4.22699ZM6.00012 6.75004C6.00012 6.33583 6.33591 6.00004 6.75012 6.00004H11.2501C11.6643 6.00004 12.0001 6.33583 12.0001 6.75004V11.25C12.0001 11.6643 11.6643 12 11.2501 12C10.8359 12 10.5001 11.6643 10.5001 11.25V8.56067L4.28031 14.7803C3.98741 15.0732 3.51254 15.0732 3.21965 14.7803C2.92676 14.4874 2.92676 14.0126 3.21966 13.7197L9.43944 7.50004H6.75012C6.33591 7.50004 6.00012 7.16425 6.00012 6.75004Z"
        transform=""
      />
    </g>
  </svg>
);

ArrowCircleBrokenUpRightIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ArrowCircleBrokenUpRightIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

ArrowCircleBrokenUpRightIcon.displayName = "ArrowCircleBrokenUpRightIcon";

export default ArrowCircleBrokenUpRightIcon;
