/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``edit.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const EditIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M12.2197 1.71968C13.341 0.598362 15.159 0.598364 16.2804 1.71968C17.4017 2.841 17.4017 4.65902 16.2804 5.78034L14.0323 8.02837C14.0317 8.02903 14.031 8.02969 14.0304 8.03034C14.0297 8.031 14.029 8.03166 14.0284 8.03231L6.37563 15.6851C6.36306 15.6976 6.3506 15.7101 6.33824 15.7225C6.15457 15.9064 5.99255 16.0687 5.80005 16.1957C5.63081 16.3073 5.44726 16.3955 5.25438 16.4579C5.035 16.5289 4.80707 16.5541 4.54866 16.5826C4.53128 16.5845 4.51377 16.5864 4.4961 16.5884L1.95783 16.8704C1.73138 16.8956 1.50578 16.8165 1.34467 16.6553C1.18357 16.4942 1.10443 16.2686 1.12959 16.0422L1.41162 13.5039C1.41359 13.4862 1.41552 13.4687 1.41744 13.4513C1.44594 13.1929 1.47108 12.965 1.5421 12.7456C1.60454 12.5528 1.69276 12.3692 1.80436 12.2C1.9313 12.0075 2.09358 11.8454 2.27755 11.6618C2.28992 11.6494 2.3024 11.637 2.31497 11.6244L12.2197 1.71968ZM10.5 5.56067L3.37563 12.685C3.13375 12.9269 3.08885 12.9768 3.0566 13.0257C3.0194 13.0821 2.99 13.1433 2.96918 13.2076C2.95114 13.2633 2.94022 13.3296 2.90245 13.6696L2.72395 15.2761L4.33046 15.0976C4.67043 15.0598 4.73667 15.0489 4.7924 15.0308C4.85669 15.01 4.91787 14.9806 4.97429 14.9434C5.02319 14.9112 5.07309 14.8663 5.31497 14.6244L12.4394 7.50001L10.5 5.56067ZM13.5 6.43935L11.5607 4.50001L13.2804 2.78034C13.8159 2.24481 14.6842 2.24481 15.2197 2.78034C15.7552 3.31588 15.7552 4.18415 15.2197 4.71968L13.5 6.43935Z"
        transform=""
      />
    </g>
  </svg>
);

EditIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

EditIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

EditIcon.displayName = "EditIcon";

export default EditIcon;
