import { DateTime } from "luxon";
import { useMemo } from "react";

import { API_ENDPOINTS } from "../../api/constants";
import useFetches from "../../api/useFetches";
import { getPortfolioCompanyName, isRequestedPortfolioCompany } from "../utils";
import isRemovedPortfolioCompany from "../utils/isRemovedPortfolioCompany";

/**
 * Fetches a portfolio company's relevant data and produces an easy to read object holding all
 * the high level stats. Useful for performing sorts across values related to the comapny but not
 * actually present on the PortfolioCompany model, and for displaying in the
 * PortfolioCompanyAccordionTrigger.
 */
const usePortfolioCompanyAggregates = (portfolioCompanies) => {
  const companyNames = (portfolioCompanies || []).map(getPortfolioCompanyName);

  const orderFlowReportsQueries = useFetches(
    (portfolioCompanies || []).map((company) => {
      if (
        isRemovedPortfolioCompany(company.company) ||
        isRequestedPortfolioCompany(company)
      ) {
        return null;
      }
      return {
        url: API_ENDPOINTS.COMPANY_PRICE_VOLUME_ORDER_FLOW(),
        queryParams: {
          company: company.company?.zb_id,
          end_date: DateTime.now().minus({ days: 1 }).toISODate(),
          start_date: DateTime.now().minus({ years: 1 }).toISODate(),
        },
      };
    })
  );

  const shareLotsQueries = useFetches(
    (portfolioCompanies || []).map((company) => ({
      url: API_ENDPOINTS.SHARE_LOTS(),
      queryParams: {
        portfolio_company: company.apiId,
      },
    }))
  );

  const customValuationsQueries = useFetches(
    (portfolioCompanies || []).map((company) => ({
      url: API_ENDPOINTS.CUSTOM_VALUATIONS(),
      queryParams: {
        "page[size]": 1,
        company: company.company?.zb_id,
        // We support custom valuations for unverified companies.
        requested_company: company.requested_company?.id,
      },
    }))
  );

  // We'll keep share lots ordered with newest share lots at the top.
  const shareLots = useMemo(() => {
    return shareLotsQueries.queryInfo.map((queryInfo, index) => {
      if (queryInfo.isSuccess) {
        return shareLotsQueries.cleanedData[index].data.sort(
          (a, b) =>
            DateTime.fromISO(b.purchase_date) -
            DateTime.fromISO(a.purchase_date)
        );
      }
      return null;
    });
  }, [shareLotsQueries.queryInfo, shareLotsQueries.cleanedData]);

  const latestCustomValuations = useMemo(() => {
    return customValuationsQueries.queryInfo.map((queryInfo, index) => {
      if (queryInfo.isSuccess) {
        return customValuationsQueries.cleanedData[index].results[0] || null;
      }
      return null;
    });
  }, [customValuationsQueries.queryInfo, customValuationsQueries.cleanedData]);

  const orderFlowStats = useMemo(() => {
    return orderFlowReportsQueries.queryInfo.map((queryInfo, index) => {
      const data = orderFlowReportsQueries.cleanedData[index]?.data;
      if (!queryInfo.isSuccess || data === null || data.length === 0) {
        return {
          low52weeks: null,
          high52weeks: null,
          currentZXIndexValue: null,
          currentZXIndexValuePercentChange: null,
          currentRobustnessScore: null,
        };
      }
      const zxIndexValues = data.map((report) => report.zx_index_price);
      const latestReport = data[0];
      return {
        low52weeks: Math.min(...zxIndexValues),
        high52weeks: Math.max(...zxIndexValues),
        currentZXIndexValue: latestReport.zx_index_price,
        currentZXIndexValuePercentChange:
          latestReport.zx_index_value_percent_change,
        currentRobustnessScore: latestReport.robustness,
      };
    });
  }, [orderFlowReportsQueries.queryInfo, orderFlowReportsQueries.cleanedData]);

  /*
   * The sum of share_quantity of the share lots.
   */
  const shareQuantities = useMemo(() => {
    return (shareLots || []).map((companyShareLots) => {
      if (!companyShareLots || companyShareLots.length === 0) {
        return null;
      }
      return companyShareLots.reduce(
        (acc, shareLot) => acc + shareLot.share_quantity,
        0
      );
    });
  }, [shareLots]);

  /*
   * The cost of all the shares to the user. Will be used to compute total unrealized gain.
   */
  const costBases = useMemo(() => {
    return (shareLots || []).map((companyShareLots) => {
      if (!companyShareLots || companyShareLots.length === 0) {
        return null;
      }
      return companyShareLots.reduce(
        (acc, shareLot) =>
          acc + Number(shareLot.price_per_share) * shareLot.share_quantity,
        0
      );
    });
  }, [shareLots]);

  /*
   * The total value of all the shares to the user, based on the current ZX Index Value.
   */
  const totalValuesFromZXIndexValue = useMemo(() => {
    return shareQuantities.map((shareQuantity, index) => {
      const currentZXIndexValue = orderFlowStats[index].currentZXIndexValue;
      if (shareQuantity === null || currentZXIndexValue === null) {
        return null;
      }
      return shareQuantity * currentZXIndexValue;
    });
  }, [shareQuantities, orderFlowStats]);

  /*
   * The total value of all the shares to the user, based on their latest custom valuation for
   * each company.
   */
  const totalValuesFromCustomValuation = useMemo(() => {
    return shareQuantities.map((shareQuantity, index) => {
      const customValuation = latestCustomValuations[index];
      if (shareQuantity === null || customValuation === null) {
        return null;
      }
      return shareQuantity * customValuation.computed_value;
    });
  }, [shareQuantities, latestCustomValuations]);

  /*
   * The difference between the current total value, and the cost basis for the share lots.
   */
  const unrealizedGainsFromZXIndexValue = useMemo(() => {
    return totalValuesFromZXIndexValue.map((totalValue, index) => {
      const costBasis = costBases[index];
      if (costBasis === null || totalValue === null) {
        return null;
      }
      return totalValue - costBasis;
    });
  }, [totalValuesFromZXIndexValue, costBases]);

  /*
   * The difference between the current total value, and the cost basis for the share lots, based on
   * the latest custom valuation for each company.
   */
  const unrealizedGainFromCustomValuation = useMemo(() => {
    return totalValuesFromCustomValuation.map((totalValue, index) => {
      const costBasis = costBases[index];
      if (costBasis === null || totalValue === null) {
        return null;
      }
      return totalValue - costBasis;
    });
  }, [totalValuesFromCustomValuation, costBases]);

  return (portfolioCompanies || []).map((company, index) => ({
    company,
    isLoading:
      orderFlowReportsQueries.queryInfo[index].isLoading ||
      shareLotsQueries.queryInfo[index].isLoading,
    isError:
      orderFlowReportsQueries.queryInfo[index].isError ||
      shareLotsQueries.queryInfo[index].isError,
    createdOn: company.created_on,
    removed: Boolean(company.company?.removed),
    removedOn: company.company?.removed_on,
    removedReason: company.company?.removed_reason,
    name: companyNames[index],
    ...orderFlowStats[index],
    customValuation: latestCustomValuations[index],
    shareLots: shareLots[index],
    shareQuantity: shareQuantities[index],
    costBasis: costBases[index],
    totalValueFromZXIndexValue: totalValuesFromZXIndexValue[index],
    totalValueFromCustomValuation: totalValuesFromCustomValuation[index],
    unrealizedGainFromZXIndexValue: unrealizedGainsFromZXIndexValue[index],
    unrealizedGainFromCustomValuation: unrealizedGainFromCustomValuation[index],
  }));
};

export default usePortfolioCompanyAggregates;
