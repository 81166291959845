/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``paperclip.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const PaperclipIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M13.4779 3.13649C12.7457 2.40425 11.5585 2.40425 10.8262 3.13649L4.06454 9.89819C2.81974 11.143 2.81974 13.1612 4.06454 14.406C5.30933 15.6508 7.32755 15.6508 8.57234 14.406L15.3341 7.64429C15.6269 7.3514 16.1018 7.3514 16.3947 7.64429C16.6876 7.93718 16.6876 8.41206 16.3947 8.70495L9.633 15.4667C7.80242 17.2972 4.83446 17.2972 3.00388 15.4667C1.17329 13.6361 1.17329 10.6681 3.00388 8.83753L9.76558 2.07583C11.0836 0.757806 13.2205 0.757806 14.5386 2.07583C15.8566 3.39384 15.8566 5.53078 14.5386 6.8488L8.04201 13.3453C7.23656 14.1508 5.93065 14.1508 5.1252 13.3453C4.31974 12.5399 4.31974 11.234 5.1252 10.4285L10.8262 4.72748C11.1191 4.43458 11.594 4.43458 11.8869 4.72748C12.1798 5.02037 12.1798 5.49524 11.8869 5.78814L6.18586 11.4892C5.96619 11.7089 5.96619 12.065 6.18586 12.2847C6.40553 12.5043 6.76168 12.5043 6.98135 12.2847L13.4779 5.78814C14.2101 5.0559 14.2101 3.86872 13.4779 3.13649Z"
        transform=""
      />
    </g>
  </svg>
);

PaperclipIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

PaperclipIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

PaperclipIcon.displayName = "PaperclipIcon";

export default PaperclipIcon;
