import { BannerCard } from "yuka";
import { useSelector, useDispatch, ACTIONS } from "../routes/StateProvider";

const BannerList = () => {
  const currentBanner = useSelector((state) => state.banners[0]);
  const dispatch = useDispatch();

  const handleClose = () => {
    if (currentBanner && currentBanner.onClose) {
      currentBanner.onClose();
    }
    dispatch({ type: ACTIONS.removeBanner });
  };

  return (
    <div>
      {currentBanner && (
        <BannerCard
          id={currentBanner.id}
          text={currentBanner.message}
          color={currentBanner.color}
          onClose={handleClose}
        />
      )}
    </div>
  );
};

export default BannerList;
