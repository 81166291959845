import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Button from "../Button/Button";
import RefreshIcon from "../Icons/yukacon/RefreshIcon";
import InlineValidatedField from "../FormField/internal/InlineValidatedField";

const StyledFilter = styled.form`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px;
  gap: 12px;

  > .flex-input {
    flex-grow: 0;
    width: ${props => props.$width}px;
    min-width: 100px;
  }

  > ${InlineValidatedField} {
    align-self: center;
  }

  > .btn:last-child {
    margin-left: auto;
  }
  > .btn,
  .icon-btn {
    align-self: center;
  }
`;

/**
 * A basic visual component to render filters with the traditional preferred default UX
 */
const Filter = ({
  onReset,
  resetText = "Clear all filters",
  resetDisabled = false,
  width = 200,
  children,
  ...props
}) => (
  <StyledFilter {...props} onSubmit={e => e.preventDefault()} $width={width}>
    {children}
    {onReset && (
      <Button onClick={onReset} disabled={resetDisabled} leadingIcon={RefreshIcon}>
        {resetText}
      </Button>
    )}
  </StyledFilter>
);

Filter.propTypes = {
  onReset: PropTypes.func,
  resetText: PropTypes.string,
  resetDisabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  width: PropTypes.number,
};

Filter.displayName = "Filter";

export default Filter;
