/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``check-circle.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const CheckCircleIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25ZM0.75 9C0.75 4.44365 4.44365 0.75 9 0.75C13.5563 0.75 17.25 4.44365 17.25 9C17.25 13.5563 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5563 0.75 9ZM12.9053 6.21967C13.1982 6.51256 13.1982 6.98744 12.9053 7.28033L8.40533 11.7803C8.11244 12.0732 7.63756 12.0732 7.34467 11.7803L5.09467 9.53033C4.80178 9.23744 4.80178 8.76256 5.09467 8.46967C5.38756 8.17678 5.86244 8.17678 6.15533 8.46967L7.875 10.1893L11.8447 6.21967C12.1376 5.92678 12.6124 5.92678 12.9053 6.21967Z"
        transform=""
      />
    </g>
  </svg>
);

CheckCircleIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

CheckCircleIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

CheckCircleIcon.displayName = "CheckCircleIcon";

export default CheckCircleIcon;
