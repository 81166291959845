import _ from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import {
  ComposedChart,
  Line,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import styled from "styled-components";
import {
  ActionChip,
  ColorPalette,
  Fonts,
  GridIcon,
  YukaColorPalette,
} from "yuka";

import ActiveDot from "./ActiveDot";
import ReportedMarksModal from "./ReportedMarksModal";

import { API_ENDPOINTS } from "../../../api/constants";
import useFetch from "../../../api/useFetch";
import { DataverseColors } from "../../../hdYuka/constants";
import SurfaceZeroCard from "../../../hdYuka/SurfaceZeroCard";
import { expandedMoneyFormat } from "../../../utils/displayFormatUtils";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import MixpanelEvents from "../../../utils/mixpanel/MixpanelEvents";
import { useCompany } from "../../hooks";
import {
  List,
  ListItem,
  StyledCircle,
  StyledEmptyPill,
  StyledCenteredEmptyState,
} from "../../StyledComponents";

const GraphXAxisYearContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LegendItemContainer = styled(Fonts.Body1theme50).attrs({ as: "div" })`
  display: flex;
  gap: 12px;
  align-items: center;
`;

// Debounce hover tracker by 1 second
const HOVER_INTERACTION_DEBOUNCE_DELAY = 1000;
const hoverCompanyReportedMarksCard = _.debounce(
  MixpanelEvents.hoverCompanyReportedMarksCard,
  HOVER_INTERACTION_DEBOUNCE_DELAY
);

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;

  > :last-child {
    margin-top: 24px;
  }
`;

const ReportedMarksStatsList = ({ min, max, weightedAvg, reportPeriod }) => (
  <List>
    <ListItem>
      <LegendItemContainer>
        <StyledCircle color={DataverseColors.branding400} />
        Dollar-Weighted AVG.
      </LegendItemContainer>
      {weightedAvg ? (
        <Fonts.Body2theme80>
          {expandedMoneyFormat(weightedAvg, 2, 2)}
        </Fonts.Body2theme80>
      ) : (
        <Fonts.Body2theme30>--</Fonts.Body2theme30>
      )}
    </ListItem>
    <ListItem>
      <LegendItemContainer>
        <StyledCircle color={ColorPalette.white15} />
        Max | Min
      </LegendItemContainer>
      <span>
        {max ? (
          <Fonts.Body2theme80>
            {expandedMoneyFormat(max, 2, 2)}
          </Fonts.Body2theme80>
        ) : (
          <Fonts.Body2theme30>--</Fonts.Body2theme30>
        )}
        <Fonts.Body2theme30> | </Fonts.Body2theme30>
        {min ? (
          <Fonts.Body2theme80>
            {expandedMoneyFormat(min, 2, 2)}
          </Fonts.Body2theme80>
        ) : (
          <Fonts.Body2theme30>--</Fonts.Body2theme30>
        )}
      </span>
    </ListItem>
    <ListItem>
      <LegendItemContainer>Report period</LegendItemContainer>
      {reportPeriod ? (
        <Fonts.Body2theme80>
          {DateTime.fromISO(reportPeriod).toLocaleString({
            month: "short",
            year: "numeric",
          })}
        </Fonts.Body2theme80>
      ) : (
        <Fonts.Body2theme30>--</Fonts.Body2theme30>
      )}
    </ListItem>
  </List>
);

ReportedMarksStatsList.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  weightedAvg: PropTypes.number,
  reportPeriod: PropTypes.string,
};

const ReportedMarksCard = () => {
  const [company, companyIsLoading] = useCompany();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = useCallback(() => setIsModalOpen(false), [setIsModalOpen]);
  const openModal = useCallback(() => {
    setIsModalOpen(true);
    MixpanelEvents.openCompanyReportedMarksModal(company?.name);
  }, [company, setIsModalOpen]);
  const [hoverStats, setHoverStats] = useState(null);

  const reportedMarksQuery = useFetch(
    API_ENDPOINTS.COMPANY_REPORTED_MARKS_VALUATION(company.zb_id)
  );
  const reportedMarksData = reportedMarksQuery?.data?.data;
  const graphData = useMemo(
    () =>
      _.map(reportedMarksData, (d, date) => ({
        ...d,
        date,
        area: [d.min, d.max],
      })).reverse(),
    [reportedMarksData]
  );

  const xAxisTicks = useMemo(() => _.map(graphData, "date"), [graphData]);
  const startDate = useMemo(() => _.first(xAxisTicks), [xAxisTicks]);
  const endDate = useMemo(() => _.last(xAxisTicks), [xAxisTicks]);

  // Set hover point data
  const onMouseMove = useCallback(
    (d) => {
      if (!graphData) {
        return;
      }

      hoverCompanyReportedMarksCard(company?.name, d.activeLabel);
      const hoveredMark = graphData[d.activeTooltipIndex];
      setHoverStats({
        ...hoveredMark,
        report_period: d.activeLabel,
        index: d.activeTooltipIndex,
      });
    },
    [graphData, company?.name]
  );
  // Remove hover stats on mouse leave
  const onMouseLeave = useCallback(() => setHoverStats(null), []);
  // Identical shape to hoverStats, used to display default legend value if no bar is being hovered
  const lastPeriodStats = useMemo(() => {
    const validData = _.filter(graphData, (d) => d.weighted_avg);
    const lastPeriodData = _.last(validData);
    return {
      ...lastPeriodData,
      report_period: lastPeriodData?.date,
      index: _.isEmpty(validData) ? null : _.size(validData) - 1,
    };
  }, [graphData]);
  const displayStats = useMemo(
    () => hoverStats || lastPeriodStats || null,
    [hoverStats, lastPeriodStats]
  );

  // The active dot on the <Line /> component requires a tooltip to be enabled
  // So we pass in a null tooltip function as a hack
  const renderNullTooltip = useCallback(() => null, []);

  if (companyIsLoading) {
    return <LoadingSpinner />;
  }

  if (_.isEmpty(reportedMarksData)) {
    return (
      <SurfaceZeroCard title="Reported Marks">
        <StyledContent>
          <StyledEmptyPill>
            <StyledCenteredEmptyState $margin={48}>
              Data not available
            </StyledCenteredEmptyState>
          </StyledEmptyPill>
          <ReportedMarksStatsList />
        </StyledContent>
      </SurfaceZeroCard>
    );
  }

  return (
    <SurfaceZeroCard
      title="Reported Marks"
      headerTrailingContent={
        <ActionChip
          text="Full data"
          leadingIcon={GridIcon}
          onClick={openModal}
        />
      }
    >
      <StyledContent>
        <ResponsiveContainer width="100%" minHeight={108}>
          <ComposedChart
            data={graphData}
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
            margin={{
              top: 0,
              right: 10,
              left: 10,
              bottom: 0,
            }}
          >
            <YAxis
              domain={[
                (dataMin) => dataMin * 0.98,
                (dataMax) => dataMax * 1.02,
              ]}
              hide
            />
            <XAxis
              axisLine={false}
              tickLine={false}
              interval={0}
              fontSize={11}
              tickFormatter={(value) => DateTime.fromISO(value).toFormat("MMM")}
              ticks={xAxisTicks}
              dataKey="date"
              stroke={ColorPalette.white50}
            />
            <Area
              type="monotone"
              dataKey="area"
              stroke="none"
              fill={ColorPalette.white15}
              connectNulls
              activeDot={false}
            />
            <Tooltip content={renderNullTooltip} />
            <Line
              type="monotone"
              dataKey="weighted_avg"
              stroke={DataverseColors.branding500}
              strokeWidth={2}
              dot={ActiveDot(DataverseColors.branding500, displayStats.index)}
              connectNulls
              activeDot={{
                r: 6,
                strokeWidth: 2,
                stroke: YukaColorPalette.surface0,
              }}
            />
          </ComposedChart>
        </ResponsiveContainer>
        <GraphXAxisYearContainer>
          <Fonts.Caption1theme50>
            {DateTime.fromISO(startDate).year}
          </Fonts.Caption1theme50>
          <Fonts.Caption1theme50>
            {DateTime.fromISO(endDate).year}
          </Fonts.Caption1theme50>
        </GraphXAxisYearContainer>
        <ReportedMarksStatsList
          min={displayStats?.min}
          max={displayStats?.max}
          weightedAvg={displayStats?.weighted_avg}
          reportPeriod={displayStats?.report_period}
        />
      </StyledContent>
      {isModalOpen && <ReportedMarksModal closeModal={closeModal} />}
    </SurfaceZeroCard>
  );
};

export default ReportedMarksCard;
