/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``globe.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const GlobeIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M2.41726 7.5C2.3078 7.98242 2.25 8.48446 2.25 9C2.25 9.51554 2.3078 10.0176 2.41726 10.5H5.3718C5.30144 10.0104 5.26056 9.51475 5.25016 9.01562C5.24995 9.00521 5.24995 8.99479 5.25016 8.98438C5.26056 8.48525 5.30144 7.98961 5.3718 7.5H2.41726ZM2.95164 6H5.68439C6.0288 4.74661 6.57164 3.55354 7.29428 2.46737C5.38861 2.96357 3.81059 4.27158 2.95164 6ZM9 2.6631C8.22024 3.6677 7.62896 4.79844 7.24829 6H10.7517C10.371 4.79844 9.77975 3.6677 9 2.6631ZM11.1106 7.5H6.88941C6.8083 7.99361 6.76144 8.49474 6.75017 9C6.76144 9.50526 6.8083 10.0064 6.88941 10.5H11.1106C11.1917 10.0064 11.2386 9.50526 11.2498 9C11.2386 8.49474 11.1917 7.99361 11.1106 7.5ZM12.6282 10.5C12.6986 10.0104 12.7394 9.51475 12.7498 9.01562C12.7501 9.00521 12.7501 8.99479 12.7498 8.98438C12.7394 8.48525 12.6986 7.98961 12.6282 7.5H15.5827C15.6922 7.98242 15.75 8.48446 15.75 9C15.75 9.51554 15.6922 10.0176 15.5827 10.5H12.6282ZM10.7517 12H7.24829C7.62896 13.2016 8.22024 14.3323 9 15.3369C9.77975 14.3323 10.371 13.2016 10.7517 12ZM7.29428 15.5326C6.57164 14.4465 6.0288 13.2534 5.68439 12H2.95164C3.81059 13.7284 5.38861 15.0364 7.29428 15.5326ZM10.7057 15.5326C11.4284 14.4465 11.9712 13.2534 12.3156 12H15.0484C14.1894 13.7284 12.6114 15.0364 10.7057 15.5326ZM15.0484 6H12.3156C11.9712 4.74661 11.4284 3.55354 10.7057 2.46737C12.6114 2.96357 14.1894 4.27158 15.0484 6ZM0.75 9C0.75 4.44365 4.44365 0.75 9 0.75C13.5563 0.75 17.25 4.44365 17.25 9C17.25 13.5563 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5563 0.75 9Z"
        transform=""
      />
    </g>
  </svg>
);

GlobeIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

GlobeIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

GlobeIcon.displayName = "GlobeIcon";

export default GlobeIcon;
