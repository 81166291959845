import styled from "styled-components";
import PropTypes from "prop-types";
import React from "react";

import { PrimaryColorPalette, YukaColorPalette } from "../StylingConstants";
import { caption2, FontColors } from "../Typography";
import { ArrowUpIcon, ArrowDownIcon, MinusIcon } from "../Icons";

const IndicatorStatuses = {
  INCREASED: "INCREASED",
  DECREASED: "DECREASED",
  UNCHANGED: "UNCHANGED",
};

const StyledBadge = styled.div`
  border-radius: 16px;
  height: 32px;
  background: ${YukaColorPalette.surface2};
  display: inline-flex;
  align-items: center;
  padding-left: 6px;
  padding-right: 12px;

  ${caption2}
  line-height: 12px;
`;

const StyledIndicator = styled.div`
  border-radius: 50%;
  line-height: 1;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props => {
    if (props.$status === IndicatorStatuses.INCREASED) {
      return `
        background: rgba(0,246,139,.3); // PrimaryColorPalette.BUY with transparency
      `;
    } else if (props.$status === IndicatorStatuses.DECREASED) {
      return `
        background: rgba(255,98,98,.3); // PrimaryColorPalette.SELL with transparency
      `;
    } else {
      return `
        background: ${PrimaryColorPalette.white15};
      `;
    }
  }}
`;

const StyledTitle = styled.div`
  margin-left: 6px;
  margin-right: 4px;
  ${FontColors.theme50}
`;

const StyledValue = styled.span`
  ${FontColors.theme80}
`;

/**
 * Specialized badge indicating whether something has increased, decreased, or not changed.
 */
const IndicatorBadge = React.forwardRef((props, ref) => {
  let indicatorIconColor = PrimaryColorPalette.white80;
  let IndicatorIcon = MinusIcon;
  if (props.indicator === IndicatorStatuses.INCREASED) {
    indicatorIconColor = PrimaryColorPalette.buy;
    IndicatorIcon = ArrowUpIcon;
  } else if (props.indicator === IndicatorStatuses.DECREASED) {
    indicatorIconColor = PrimaryColorPalette.sell;
    IndicatorIcon = ArrowDownIcon;
  }

  return (
    <StyledBadge ref={ref}>
      <StyledIndicator $status={props.indicator}>
        <IndicatorIcon size={16} color={indicatorIconColor} />
      </StyledIndicator>
      <StyledTitle>{props.title}</StyledTitle>
      <StyledValue>{props.value}</StyledValue>
    </StyledBadge>
  );
});

IndicatorBadge.displayName = "IndicatorBadge";

IndicatorBadge.propTypes = {
  title: PropTypes.string,
  value: PropTypes.node,
  indicator: PropTypes.oneOf(Object.values(IndicatorStatuses)),
};

IndicatorBadge.defaultProps = {
  indicator: IndicatorStatuses.UNCHANGED,
};

export default IndicatorBadge;
export { IndicatorStatuses };
