import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PieChart, Pie, Cell } from "recharts";
import styled, { css, useTheme } from "styled-components";
import {
  Card,
  List,
  ListStyles,
  Fonts,
  ChevronRightIcon,
  PrimaryColorPalette,
  CardStyles,
  TruncatedText,
} from "yuka";

import { ListItem } from "../../hdYuka";
import {
  usePortfolio,
  usePortfolioValue,
  usePortfoliosContainingCompany,
  usePortfolioCompanyAggregates,
} from "../../Portfolios/hooks";
import { ROUTING_PATH } from "../../routes/constants";
import { percentFormat } from "../../utils/displayFormatUtils";
import MixpanelEvents from "../../utils/mixpanel/MixpanelEvents";
import { useCompany } from "../hooks";
import { StyledCenteredEmptyState } from "../StyledComponents";

const StyledDonutChartContainer = styled.div`
  padding-right: 16px;
`;

const StyledListItem = styled(ListItem)`
  padding: 0 16px;

  ${(props) =>
    props.$hide &&
    css`
      display: none;
    `}
`;

// for recharts, 0 degrees is at 3 o'clock so we need to adjust the start and end angles
// by 90 degrees to get the correct orientation.
// we go from 450 to 90 degrees to make the chart go clockwise.
const DONUT_START_ANGLE = 450;
const DONUT_END_ANGLE = 90;

const DonutChart = ({ percentage }) => {
  const theme = useTheme();

  const data = [
    { value: percentage, color: theme.colors.branding500 },
    { value: 100 - percentage, color: PrimaryColorPalette.white15 },
  ];
  return (
    <StyledDonutChartContainer>
      <PieChart width={40} height={40}>
        <Pie
          data={data}
          outerRadius={20}
          innerRadius={14}
          dataKey="value"
          startAngle={DONUT_START_ANGLE}
          endAngle={DONUT_END_ANGLE}
        >
          {data.map((entry, index) => (
            <Cell stroke={null} key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    </StyledDonutChartContainer>
  );
};

DonutChart.propTypes = {
  percentage: PropTypes.number.isRequired,
};

const StyledPortfolioName = styled(TruncatedText)`
  max-width: 300px;
`;

const HoldingsListItem = ({ portfolioCompany, onComputePercentage, hide }) => {
  const portfolioId = portfolioCompany?.portfolio[1];
  const [portfolio, portfolioIsLoading] = usePortfolio(portfolioId);
  const totalValue = usePortfolioValue(portfolioId);
  const aggregates = usePortfolioCompanyAggregates([portfolioCompany]);
  const [percentage, setPercentage] = useState(null);

  useEffect(() => {
    if (totalValue === null || aggregates[0].isLoading) {
      return;
    }
    const newPercentage = totalValue
      ? (aggregates[0]?.totalValueFromZXIndexValue / totalValue) * 100
      : 0;
    if (newPercentage !== percentage) {
      onComputePercentage(newPercentage);
    }
    setPercentage(newPercentage);
  }, [aggregates, onComputePercentage, percentage, totalValue]);

  if (portfolioIsLoading || aggregates[0].isLoading) {
    return null;
  }

  return (
    <StyledListItem
      $hide={hide}
      listStyle={ListStyles.TWO_LINE}
      leadingContent={<DonutChart percentage={percentage} />}
      text={
        <Fonts.Body2theme80>
          <StyledPortfolioName>{portfolio?.name}</StyledPortfolioName>
        </Fonts.Body2theme80>
      }
      subtext={
        <>
          <Fonts.Body1theme80>{percentFormat(percentage)}</Fonts.Body1theme80>{" "}
          <Fonts.Body1theme50>of this portfolio</Fonts.Body1theme50>
        </>
      }
      trailingContent={<ChevronRightIcon color={PrimaryColorPalette.white50} />}
      url={ROUTING_PATH.PORTFOLIO(portfolioId)}
      onClick={() =>
        MixpanelEvents.clickHoldingsListItem(portfolioCompany.company.name)
      }
    />
  );
};

HoldingsListItem.propTypes = {
  hide: PropTypes.bool,
  onComputePercentage: PropTypes.func.isRequired,
  portfolioCompany: PropTypes.shape({
    portfolio: PropTypes.arrayOf(PropTypes.string),
    company: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const HoldingsCard = () => {
  const [company, companyIsLoading] = useCompany();
  const cardTitle = `Your holdings of ${company?.name}`;
  const [portfolioPercentages, setPortfolioPercentages] = useState({});
  const [portfolioCompanies, portfolioCompaniesLoading] =
    usePortfoliosContainingCompany(company.zb_id);

  const onComputePercentage = useCallback(
    (portfolioId, percentage) =>
      setPortfolioPercentages((prev) => ({
        ...prev,
        [portfolioId]: percentage,
      })),
    []
  );

  /*
   * We'll sort the portfolio companies by the percentage of the portfolio they represent.
   */
  const sortedPortfolioCompanies = useMemo(() => {
    if (
      !(
        portfolioCompanies &&
        Object.keys(portfolioPercentages).length === portfolioCompanies.length
      )
    ) {
      return portfolioCompanies;
    }
    return portfolioCompanies
      ?.sort(
        (a, b) =>
          portfolioPercentages[b.portfolio[1]] -
          portfolioPercentages[a.portfolio[1]]
      )
      .slice(0, 5);
  }, [portfolioCompanies, portfolioPercentages]);

  const allPercentagesLoaded = useMemo(() => {
    if (!portfolioCompanies || !company) {
      return false;
    }
    return (
      sortedPortfolioCompanies &&
      sortedPortfolioCompanies.length === Math.min(5, portfolioCompanies.length)
    );
  }, [company, portfolioCompanies, sortedPortfolioCompanies]);

  if (companyIsLoading || portfolioCompaniesLoading) {
    return (
      <Card title={cardTitle} cardStyle={CardStyles.SECTIONED}>
        <StyledCenteredEmptyState $margin={32}>
          Holdings are loading...
        </StyledCenteredEmptyState>
      </Card>
    );
  }

  if (!company || !portfolioCompanies) {
    return (
      <Card title={cardTitle} cardStyle={CardStyles.SECTIONED}>
        <StyledCenteredEmptyState $margin={32}>
          An error has occurred.
        </StyledCenteredEmptyState>
      </Card>
    );
  }

  if (portfolioCompanies.length === 0) {
    return (
      <Card title={cardTitle} cardStyle={CardStyles.SECTIONED}>
        <StyledCenteredEmptyState $margin={32}>
          No holdings of this company
        </StyledCenteredEmptyState>
      </Card>
    );
  }

  return (
    <Card title={cardTitle} cardStyle={CardStyles.SECTIONED}>
      <List>
        {sortedPortfolioCompanies.map((portfolioCompany) => (
          <HoldingsListItem
            hide={!allPercentagesLoaded}
            key={`holding_item_${portfolioCompany.apiId}`}
            portfolioCompany={portfolioCompany}
            onComputePercentage={(percentage) =>
              onComputePercentage(portfolioCompany.portfolio[1], percentage)
            }
          />
        ))}
        {!allPercentagesLoaded && (
          <StyledCenteredEmptyState $margin={32}>
            Holdings are loading...
          </StyledCenteredEmptyState>
        )}
      </List>
    </Card>
  );
};

export default HoldingsCard;
