import { useMemo } from "react";

import { API_ENDPOINTS } from "../../api/constants";
import useFetches from "../../api/useFetches";
import { isRequestedPortfolioCompany } from "../utils";

import { usePortfolioCompanies } from "./index";

/**
 * Returns the latest custom valuations for all the companies in a portfolio.
 *
 * @param portfolioId
 */
const useCustomValuationsForPortfolio = (portfolioId) => {
  const [portfolioCompanies, companiesLoading] =
    usePortfolioCompanies(portfolioId);

  const customValuationsQueries = useFetches(
    (portfolioCompanies || []).map((company) => ({
      url: API_ENDPOINTS.CUSTOM_VALUATIONS(),
      queryParams: {
        "page[size]": 1,
        ...(isRequestedPortfolioCompany(company)
          ? { requested_company: company.requested_company?.id }
          : { company: company.company?.zb_id }),
      },
    }))
  );

  const latestCustomValuations = useMemo(() => {
    return customValuationsQueries.queryInfo.map((queryInfo, index) => {
      if (queryInfo.isSuccess) {
        return customValuationsQueries.cleanedData[index].results[0] || null;
      }
      return null;
    });
  }, [customValuationsQueries.queryInfo, customValuationsQueries.cleanedData]);

  if (companiesLoading || customValuationsQueries.isLoading) {
    return [null, true];
  }

  return [latestCustomValuations, false];
};

export default useCustomValuationsForPortfolio;
