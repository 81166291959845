import _ from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Line, LineChart, ResponsiveContainer, YAxis } from "recharts";
import styled from "styled-components";
import { CardStyles, ColorPalette, Fonts } from "yuka";

import { API_ENDPOINTS } from "../../api/constants";
import useFetch from "../../api/useFetch";
import { SurfaceZeroCard } from "../../hdYuka";
import { DataverseColors } from "../../hdYuka/constants";
import { ROUTING_PATH } from "../../routes/constants";
import applyOpacityToHex from "../../utils/applyOpacityToHex";
import {
  expandedMoneyFormat,
  percentFormat,
} from "../../utils/displayFormatUtils";
import { useCompanyFetch } from "../hooks";

const StyledCompanyName = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledCardContent = styled.div`
  position: relative;
  padding: 0 16px;
  height: 136px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledChartContainer = styled(ResponsiveContainer)`
  position: absolute;
  left: 0;
  bottom: 0;
`;

const CompanyQuickPriceCard = ({ companyId, onClick }) => {
  const navigate = useNavigate();

  const companyQuery = useCompanyFetch(
    companyId,
    {},
    { redirectOnFail: false }
  );

  const orderFlowQuery = useFetch(
    API_ENDPOINTS.COMPANY_PRICE_VOLUME_ORDER_FLOW(),
    {
      company: companyId,
      end_date: DateTime.now().minus({ days: 1 }).toISODate(),
      start_date: DateTime.now().minus({ years: 1 }).toISODate(),
    },
    {
      enabled: Boolean(companyId && companyQuery.isSuccess),
    }
  );

  const company = useMemo(() => {
    if (companyQuery.isSuccess) {
      return companyQuery.cleanedData.data;
    }
    return null;
  }, [companyQuery.isSuccess, companyQuery.cleanedData]);

  const [currentZXIndexPrice, currentPercentageChange] = useMemo(() => {
    if (!orderFlowQuery.isSuccess) {
      return [null, null];
    }
    return [
      orderFlowQuery.cleanedData.data[0]?.zx_index_price,
      orderFlowQuery.cleanedData.data[0]?.zx_index_value_percent_change,
    ];
  }, [orderFlowQuery.isSuccess, orderFlowQuery.cleanedData]);

  const tinyChartData = useMemo(() => {
    if (!orderFlowQuery.isSuccess) {
      return [];
    }
    return orderFlowQuery.cleanedData.data
      .map((report) => ({
        price: Number(report.zx_index_price),
        time: report.report_period,
      }))
      .reverse();
  }, [orderFlowQuery.isSuccess, orderFlowQuery.cleanedData]);

  const priceDisplay = useMemo(() => {
    if (currentZXIndexPrice === null) {
      return <Fonts.Headline2theme50>--</Fonts.Headline2theme50>;
    }
    if (currentPercentageChange > 0) {
      return (
        <Fonts.Headline2buy>
          {expandedMoneyFormat(currentZXIndexPrice, 2, 2)} (+
          {percentFormat(currentPercentageChange * 100)})
        </Fonts.Headline2buy>
      );
    }
    if (currentPercentageChange < 0) {
      return (
        <Fonts.Headline2sell>
          {expandedMoneyFormat(currentZXIndexPrice, 2, 2)} (
          {percentFormat(currentPercentageChange * 100)})
        </Fonts.Headline2sell>
      );
    }

    return (
      <Fonts.Headline2theme80>
        {expandedMoneyFormat(currentZXIndexPrice)} (+
        {percentFormat(currentPercentageChange * 100)})
      </Fonts.Headline2theme80>
    );
  }, [currentZXIndexPrice, currentPercentageChange]);

  const graphColor = useMemo(() => {
    if (!Number(currentPercentageChange)) {
      return ColorPalette.white50;
    }
    if (currentPercentageChange > 0) {
      return applyOpacityToHex(DataverseColors.green, 0.5);
    }
    return applyOpacityToHex(DataverseColors.red, 0.5);
  }, [currentPercentageChange]);

  if (companyQuery.isLoading || orderFlowQuery.isLoading) {
    return (
      <SurfaceZeroCard cardStyle={CardStyles.MINIMAL}>
        <StyledCardContent>
          <Fonts.Body1theme30>Loading...</Fonts.Body1theme30>
        </StyledCardContent>
      </SurfaceZeroCard>
    );
  }

  if (!company) {
    return (
      <SurfaceZeroCard cardStyle={CardStyles.MINIMAL}>
        <StyledCardContent>
          <Fonts.Body1theme30>An error has occurred.</Fonts.Body1theme30>
        </StyledCardContent>
      </SurfaceZeroCard>
    );
  }

  return (
    <SurfaceZeroCard
      cardStyle={CardStyles.MINIMAL}
      onClick={() => {
        onClick(company.name);
        navigate(ROUTING_PATH.COMPANY(companyId));
      }}
    >
      <StyledCardContent>
        <StyledCompanyName>
          <Fonts.Body2theme80>{company.name}</Fonts.Body2theme80>
          <Fonts.Body1theme50>{company.legal_name}</Fonts.Body1theme50>
        </StyledCompanyName>
        {priceDisplay}
        <StyledChartContainer width="100%" height={48}>
          <LineChart
            data={tinyChartData}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          >
            <YAxis hide type="number" domain={["dataMin", "dataMax"]} />
            <Line
              type="monotone"
              dataKey="price"
              stroke={graphColor}
              strokeWidth={1}
              dot={false}
            />
          </LineChart>
        </StyledChartContainer>
      </StyledCardContent>
    </SurfaceZeroCard>
  );
};

CompanyQuickPriceCard.propTypes = {
  onClick: PropTypes.func,
  companyId: PropTypes.string.isRequired,
};

CompanyQuickPriceCard.defaultProps = {
  onClick: _.noop,
};

export default CompanyQuickPriceCard;
