import _ from "lodash";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Fonts } from "yuka";

import { ORDERING_FILTER_DEFAULT_VALUE } from "./constants";
import HeatMap from "./HeatMap";
import HeatMapFilters from "./HeatMapFilters";

import { API_ENDPOINTS } from "../api/constants";
import useFetch from "../api/useFetch";
import useInfiniteFetch from "../api/useInfiniteFetch";
import { PAGE_WIDTH } from "../constants";
import LoadingSpinner from "../utils/LoadingSpinner";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";
import useDocumentTitle from "../utils/useDocumentTitle";

const StyledMarketActivityContainer = styled.div`
  ${PAGE_WIDTH}
  padding-top: 48px;
  gap: 24px;
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  align-self: center;
  flex-direction: column;
`;

const MarketActivityView = () => {
  useDocumentTitle("ZXData: Market Activity");
  const [selectedOrdering, setSelectedOrdering] = useState(
    ORDERING_FILTER_DEFAULT_VALUE
  );
  const [selectedSector, setSelectedSector] = useState(null);

  useEffect(() => {
    MixpanelEvents.viewMarketActivity();
  }, []);

  useEffect(() => {
    if (selectedSector) {
      MixpanelEvents.heatMapFilterSector(selectedSector);
    }
  }, [selectedSector]);

  const sectorsQuery = useFetch(API_ENDPOINTS.COMPANY_SECTORS(), {
    "page[size]": 100,
  });
  const latestReportsQuery = useInfiniteFetch(
    API_ENDPOINTS.COMPANY_LATEST_ORDER_FLOW_REPORTS(),
    {
      "page[size]": 500,
      ordering: selectedOrdering,
      ...(selectedSector ? { sector_name: selectedSector } : {}),
    },
    { keepPreviousData: true }
  );

  return (
    <StyledMarketActivityContainer>
      <Fonts.Headline3theme80>
        Market Activity /{" "}
        <Fonts.Headline3theme50>
          Heat Map of ZX Index Value MoM Change
        </Fonts.Headline3theme50>
      </Fonts.Headline3theme80>
      <HeatMapFilters
        selectedOrdering={selectedOrdering}
        selectedSector={selectedSector}
        setSelectedOrdering={setSelectedOrdering}
        setSelectedSector={setSelectedSector}
        sectorNames={_.map(sectorsQuery.cleanedData?.data, "name")}
      />
      {latestReportsQuery.isLoading || (
        <HeatMap reports={latestReportsQuery.cleanedData.data} />
      )}
      {latestReportsQuery.isFetchingNextPage && (
        <LoadingSpinner absolute={false} />
      )}
    </StyledMarketActivityContainer>
  );
};

export default MarketActivityView;
