/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``compare-arrows.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const CompareArrowsIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule=""
        d="M5.94375 15L5.15625 14.2125L7.425 11.9438H1.5V10.8188H7.425L5.15625 8.55L5.94375 7.7625L9.5625 11.3813L5.94375 15ZM12.0563 10.2375L8.4375 6.61875L12.0563 3L12.8438 3.7875L10.575 6.05625H16.5V7.18125H10.575L12.8438 9.45L12.0563 10.2375Z"
        transform=""
      />
    </g>
  </svg>
);

CompareArrowsIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

CompareArrowsIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

CompareArrowsIcon.displayName = "CompareArrowsIcon";

export default CompareArrowsIcon;
