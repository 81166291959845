import PropTypes from "prop-types";

import BadgeStyles from "./BadgeStyles";

const BadgeStylePropType = PropTypes.oneOf(Object.values(BadgeStyles));

export { BadgeStyles, BadgeStylePropType };
export { default as Badge } from "./Badge";
export { default as BadgeList } from "./BadgeList";
export { default as IndicatorBadge, IndicatorStatuses } from "./IndicatorBadge";
