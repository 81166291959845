import React from "react";
import PropTypes from "prop-types";
import { Button, ButtonStyles, Card, Fonts } from "yuka";
import { StyledReverseRow } from "../StyledComponents";

const InitialStep = (props) => {
  return (
    <Card
      title={
        <span>
          Two-Factor Authentication:{" "}
          <Fonts.Headline2theme100>
            {props.is2FaEnabled ? "ON" : "OFF"}
          </Fonts.Headline2theme100>
        </span>
      }
      tagline={
        props.is2FaEnabled
          ? `While we strongly discourage it, you can disable two-factor authentication for your account.`
          : `Two-factor authentication is not enabled for your account.
                  Enable two-factor authentication for enhanced account security.`
      }
    >
      <StyledReverseRow>
        <Button
          buttonStyle={ButtonStyles.CTA}
          onClick={props.goToNext}
          type="submit"
        >
          {props.is2FaEnabled ? "Disable" : "Enable"} Two-Factor Authentication
        </Button>
      </StyledReverseRow>
    </Card>
  );
};

InitialStep.propTypes = {
  is2FaEnabled: PropTypes.bool.isRequired,
  goToNext: PropTypes.func.isRequired,
};

export default InitialStep;
