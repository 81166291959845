/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``bookmark-filled.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const BookmarkFilledIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule=""
        d="M7.31903 1.5H10.681C11.2847 1.49999 11.783 1.49998 12.1889 1.53315C12.6104 1.56759 12.998 1.64151 13.362 1.82698C13.9265 2.1146 14.3854 2.57354 14.673 3.13803C14.8585 3.50203 14.9324 3.88956 14.9669 4.31113C15 4.71703 15 5.21531 15 5.81904V15.75C15 16.0174 14.8577 16.2645 14.6264 16.3987C14.3952 16.5329 14.11 16.5338 13.8779 16.4012L9 13.6138L4.12211 16.4012C3.88998 16.5338 3.60479 16.5329 3.37356 16.3987C3.14232 16.2645 3 16.0174 3 15.75V5.81903C2.99999 5.21531 2.99998 4.71702 3.03315 4.31113C3.06759 3.88956 3.14151 3.50203 3.32698 3.13803C3.6146 2.57354 4.07354 2.1146 4.63803 1.82698C5.00203 1.64151 5.38956 1.56759 5.81113 1.53315C6.21703 1.49998 6.7153 1.49999 7.31903 1.5Z"
        transform=""
      />
    </g>
  </svg>
);

BookmarkFilledIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

BookmarkFilledIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

BookmarkFilledIcon.displayName = "BookmarkFilledIcon";

export default BookmarkFilledIcon;
