import _ from "lodash";

/**
 * Performs a multiplication by a power of 10 using string manipulation to
 * avoid floating point precision problems. Note that this does not work for
 * numbers of the form: 0.<6 or more zeroes><non-zero numbers> because their
 * toString() interpretation uses exponents. The alternative to toString is
 * toFixed, but that method seems unreliable when it comes to actually padding a
 * decimal value.
 *
 * Example: 0.000357871562323.toFixed(50) outputs
 * '0.00035787156232300001588650384398704318300588056445'
 * Which is pretty weird.
 *
 * The tradeoff for losing the precision beyond 6 decimal places (when the non-decimal
 * portion is zero), is that we avoid floating point precision problems like:
 * 5253959.56 * 10 == 52539595.599999994
 *
 * @param {number} number
 * @param {number} powerOfTen
 * @returns {number}
 */
const multiplyByPowerOfTen = (number, powerOfTen) => {
  let stringNumber = number.toString();
  // Pad enough zeroes to guarantee we do not get out-of-bounds errors.
  for (let i = 0; i < Math.abs(powerOfTen); i++) {
    if (powerOfTen > 0) {
      stringNumber += "0";
    } else {
      stringNumber = `0${stringNumber}`;
    }
  }
  let decimalIndex = stringNumber.indexOf(".");

  if (decimalIndex === -1) {
    decimalIndex =
      powerOfTen > 0 ? stringNumber.length - powerOfTen : stringNumber.length;
  } else {
    stringNumber = `${stringNumber.slice(0, decimalIndex)}${stringNumber.slice(
      decimalIndex + 1
    )}`;
  }

  const newDecimalIndex = decimalIndex + powerOfTen;
  stringNumber = `${stringNumber.slice(
    0,
    newDecimalIndex
  )}.${stringNumber.slice(newDecimalIndex)}`;
  return parseFloat(stringNumber);
};

/**
 * Rounds the decimal to a fixed number of decimal places.
 *
 * .toFixed() has inconsistent rounding for cases that are close to being rounded or down due
 * to the way that floating point numbers are stored in JS so we need additional logic
 *
 * Logic: multiplying dec by the amount of decimals that we care about so that we can convert it to
 * be stored as an integer (taken care of by the Math.round/Math.floor), this solves the precision
 * issue with floating point. Next, we modulo by 10 to see if the last digit is 5 or greater,
 * in which case we want to round up (adding 1 to the number). We then want to display it as a
 * decimal again so we divide the number by the adjustment and use .toFixed to achieve the
 * correct padding of 0s.
 *
 * There is a limit to the precision that this works to. Tested up to decimalPlaces = 6.
 * Fails with: (params) -> result
 *             (0.000000015,8) -> 0.00000000
 *
 * @param {number} dec
 * @param {number} decimalPlaces
 * @returns {string}
 */
export default (dec, decimalPlaces = 2) => {
  if (_.isNull(dec) || _.isUndefined(dec)) {
    return "";
  }

  const neg = dec < 0;
  const absNum = neg ? -1 * dec : dec;
  const BASE = 10;
  const floorAdjustment = BASE ** decimalPlaces;
  let roundedNum = 0;

  if (multiplyByPowerOfTen(absNum, decimalPlaces + 1) % BASE >= BASE / 2) {
    roundedNum =
      (Math.floor(multiplyByPowerOfTen(absNum, decimalPlaces)) + 1) /
      floorAdjustment;
  } else {
    roundedNum =
      Math.round(multiplyByPowerOfTen(absNum, decimalPlaces)) / floorAdjustment;
  }

  if (neg) {
    roundedNum *= -1;
  }

  return roundedNum.toFixed(decimalPlaces);
};
