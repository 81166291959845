import _ from "lodash";
import { useMemo } from "react";

import useLatestOrderflowForPortfolio from "./useLatestOrderflowForPortfolio";
import useShareLotsForPortfolio from "./useShareLotsForPortfolio";

import { usePortfolioCompanies } from "./index";

/**
 * Computes "last week's" portfolio total value. Similar to the usePortfolioValue hook, but
 * rather than multiplying the current ZX Index Value by the share quantities for each company, it
 * computes the previous week's ZX Index Value based on the current value and the percentage change
 * from the previous week, and uses that.
 *
 * @param {string} portfolioId
 * @return {number|null} - Returns the computed value on success, or null if any of the queries are
 *    still loading or encountered errors.
 */
const useLastWeekPortfolioValue = (portfolioId) => {
  const [portfolioCompanies, companiesLoading] =
    usePortfolioCompanies(portfolioId);
  // Get all the share lots and latest orderflow report for all the companies. useFetches guarantees
  // the order of the results matches the order of the queries, and we will make use of that fact
  // below.
  const [shareLots, shareLotsLoading] = useShareLotsForPortfolio(portfolioId);
  const [orderFlowReports, orderflowLoading] =
    useLatestOrderflowForPortfolio(portfolioId);

  const lastWeekValue = useMemo(() => {
    if (!orderFlowReports || !shareLots) {
      return null;
    }

    return (portfolioCompanies || [])
      .map((company, index) => {
        // Make use of the useFetches ordering guarantee.
        const companyShareLots = shareLots[index];
        const previousZXIndexValue = orderFlowReports[index][1]?.zx_index_price;

        if (!previousZXIndexValue) {
          // We only include companies with verified data in the portfolio value calculation.
          return 0;
        }
        const totalShareQuantity = companyShareLots.reduce(
          (acc, shareLot) => acc + shareLot.share_quantity,
          0
        );

        return totalShareQuantity * previousZXIndexValue;
      })
      .reduce((acc, value) => acc + value, 0);
  }, [orderFlowReports, shareLots, portfolioCompanies]);

  if (
    companiesLoading ||
    orderflowLoading ||
    shareLotsLoading ||
    _.isEmpty(portfolioCompanies)
  ) {
    return null;
  }
  return lastWeekValue;
};

export default useLastWeekPortfolioValue;
