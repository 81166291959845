import PropTypes from "prop-types";
import React, { useCallback, useState, useRef } from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  Button,
  ButtonStyles,
  caption2,
  Card,
  CardStyles,
  FontColors,
  HyperLink,
  TrashIcon,
  UploadIcon,
  StyledBody1,
  LinkTypes,
  InputRow,
} from "yuka";

import { PaddedForm, StyledRow, StyledReverseRow } from "./StyledComponents";

import { API_ENDPOINTS } from "../api/constants";
import useWrite from "../api/useWrite";
import { FinalFormField } from "../hdYuka";
import { ROUTING_PATH } from "../routes/constants";
import LoadingSpinner from "../utils/LoadingSpinner";
import UserAvatar from "../utils/UserAvatar";

const CenteredRow = styled(StyledRow)`
  padding: 0px 16px;
  align-items: center;
`;

const StyledMessage = styled.p`
  ${caption2}
  ${FontColors.theme50}
`;

const StyledAccountInformationActions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledHiddenInput = styled.div`
  display: none;
`;

const StyledButtonRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

const AccountInformation = (props) => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const changeAccountNameQuery = useWrite(
    API_ENDPOINTS.CHANGE_ACCOUNT_SETTINGS(),
    true,
    { silent: false }
  );

  const onSubmit = (values) => {
    changeAccountNameQuery.mutate(values, {
      onSuccess: () => {
        navigate(0);
      },
    });
  };

  const handleDeletePicture = () => {
    const data = {
      main_picture: null,
    };

    changeAccountNameQuery.mutate(data, {
      onSuccess: () => {
        navigate(0);
      },
    });
  };

  const triggerUpdatePicture = useCallback(() => {
    fileInputRef.current.click();
  }, []);

  return (
    <Card cardStyle={CardStyles.SECTIONED} title="Account Information">
      <CenteredRow>
        <UserAvatar user={props.user} size="96px" />
        <StyledAccountInformationActions>
          <StyledButtonRow>
            <Button
              buttonStyle={ButtonStyles.RAISED}
              leadingIcon={UploadIcon}
              onClick={triggerUpdatePicture}
            >
              Upload New Picture
            </Button>
            <Button
              buttonStyle={ButtonStyles.RAISED}
              leadingIcon={TrashIcon}
              onClick={handleDeletePicture}
            >
              Delete Picture
            </Button>
          </StyledButtonRow>
          <StyledBody1>{file}</StyledBody1>
        </StyledAccountInformationActions>
      </CenteredRow>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting, dirty }) => (
          <PaddedForm onSubmit={handleSubmit}>
            {submitting && <LoadingSpinner />}
            <StyledHiddenInput>
              <FinalFormField
                id="file"
                type="file"
                name="main_picture"
                ref={fileInputRef}
                onChange={(file) => {
                  setFile(file.name);
                }}
                parse={(fileList) => fileList[0]}
                format={(file) => [file]}
              />
            </StyledHiddenInput>
            <InputRow>
              <FinalFormField
                id="first_name"
                name="first_name"
                placeholder={props.user.first_name}
                type="text"
                label="First Name"
              />
            </InputRow>
            <InputRow>
              <FinalFormField
                id="last_name"
                name="last_name"
                placeholder={props.user.last_name}
                type="text"
                label="Last Name"
              />
            </InputRow>
            <StyledMessage>
              Updating your name will change how it is displayed throughout the
              platform. Your time zone is only used to display times on the site
              in your local time. For more details, visit our&nbsp;
              <HyperLink
                url={ROUTING_PATH.AGREEMENT("privacy")}
                linkType={LinkTypes.EXTERNAL_LINK}
              >
                Privacy Policy.
              </HyperLink>
            </StyledMessage>
            <StyledReverseRow>
              <Button
                buttonStyle={ButtonStyles.RAISED}
                disabled={submitting || !dirty}
                type="submit"
              >
                Save Changes
              </Button>
            </StyledReverseRow>
          </PaddedForm>
        )}
      </Form>
    </Card>
  );
};

AccountInformation.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }).isRequired,
};

export default AccountInformation;
