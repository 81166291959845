const ButtonStyles = {
  OUTLINE: "OUTLINE",
  CTA: "CTA",
  BUY: "BUY",
  SELL: "SELL",
  RAISED: "RAISED",
};

const IconButtonStyles = {
  STANDARD: "STANDARD",
  OUTLINE: "OUTLINE",
  RAISED: "RAISED",
};

export { ButtonStyles, IconButtonStyles };
