/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``certificate.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const CertificateIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M1.5 3C1.5 1.75736 2.50736 0.75 3.75 0.75H14.25C15.4926 0.75 16.5 1.75736 16.5 3V13.5C16.5 14.7426 15.4926 15.75 14.25 15.75H13.3497L11.9249 17.1748C11.6321 17.4676 11.1572 17.4676 10.8643 17.1748L9.00798 15.3186L7.15183 17.1748C6.85894 17.4676 6.38407 17.4676 6.09117 17.1748L4.66642 15.75H3.75C2.50736 15.75 1.5 14.7426 1.5 13.5V3ZM5.56084 14.5231L6.6215 15.5838L7.56829 14.637C7.12151 14.3939 6.74338 14.0406 6.47042 13.6135L5.56084 14.5231ZM6.00023 11.9624L3.96985 13.9928C3.89592 14.0667 3.83902 14.1545 3.80167 14.25H3.75C3.33579 14.25 3 13.9142 3 13.5V3C3 2.58579 3.33579 2.25 3.75 2.25H14.25C14.6642 2.25 15 2.58579 15 3V13.5C15 13.9142 14.6642 14.25 14.25 14.25H14.2144C14.1771 14.1545 14.1202 14.0667 14.0463 13.9928L11.9995 11.946C11.9707 10.3141 10.6388 9 9 9C7.3557 9 6.02037 10.3229 6.00023 11.9624ZM7.5101 12.175C7.50993 12.1734 7.50975 12.1719 7.50956 12.1703C7.50325 12.1144 7.5 12.0576 7.5 12C7.5 11.1716 8.17157 10.5 9 10.5C9.82843 10.5 10.5 11.1716 10.5 12C10.5 12.8215 9.83966 13.4887 9.02083 13.4999C9.01683 13.4998 9.01282 13.4998 9.00881 13.4999L8.99675 13.5C8.22899 13.4984 7.59667 12.9199 7.5101 12.175ZM10.4421 14.6313C10.8877 14.3865 11.2645 14.0318 11.5358 13.6037L12.4553 14.5231L11.3946 15.5838L10.4421 14.6313ZM6 4.5C6 4.08579 6.33579 3.75 6.75 3.75H11.25C11.6642 3.75 12 4.08579 12 4.5C12 4.91421 11.6642 5.25 11.25 5.25H6.75C6.33579 5.25 6 4.91421 6 4.5ZM4.5 7.125C4.5 6.71079 4.83579 6.375 5.25 6.375H12.75C13.1642 6.375 13.5 6.71079 13.5 7.125C13.5 7.53921 13.1642 7.875 12.75 7.875H5.25C4.83579 7.875 4.5 7.53921 4.5 7.125Z"
        transform=""
      />
    </g>
  </svg>
);

CertificateIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

CertificateIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

CertificateIcon.displayName = "CertificateIcon";

export default CertificateIcon;
