const BLUE900 = "#B1E2E7";
const BLUE800 = "#A2DCE2";
const BLUE700 = "#92D7DD";
const BLUE600 = "#83D1D8";
const BLUE500 = "#74CBD3";
const BLUE400 = "#64C5CE";
const BLUE300 = "#54BFC9";
const BLUE200 = "#45BAC4";
const BLUE100 = "#3BAFBA";

// The commented out colors are left in place to remind us which colors we still need to
// standardize in our theme.
const DataverseColors = {
  branding900: BLUE900,
  branding800: BLUE800,
  branding700: BLUE700,
  branding600: BLUE600,
  branding500: BLUE500,
  branding400: BLUE400,
  branding300: BLUE300,
  branding200: BLUE200,
  branding100: BLUE100,
  d300: "#2B393A",
  d200: "#344546",
  d100: "#3D5152",
  red: "#FFBC96",
  // redHover: "#ff7272",
  // redActive: "#ff7a7a",
  green: "#92CAB7",
  // greenHover: "#33f8a2",
  // greenActive: "#66fab9",
  yellow: "#DFCD6F",
  magenta: "#D593DB",
  indigo: "#557EE7",

  /* Component-specific coloring */
  link: BLUE500,
  checkbox: BLUE500,
  tabItem: BLUE500,
  focussedInputBorder: BLUE500,
};

export { DataverseColors };
