import PropTypes from "prop-types";
import { Layer } from "recharts";

import {
  SANKEY_NODE_COMMON,
  SANKEY_NODE_DIRECT_APPROVED,
  SANKEY_NODE_DIRECT_ROFR,
  SANKEY_NODE_FORWARD,
  SANKEY_NODE_PREFERRED,
  SANKEY_NODE_SPV,
  SANKEY_NODE_UNKNOWN_SECURITY,
} from "./constants";

const CompanyTransferabilitySankeyLink = (props) => {
  // All props are passed in by recharts.
  const {
    sourceX,
    sourceY,
    sourceControlX,
    targetX,
    targetY,
    targetControlX,
    linkWidth,
  } = props;

  const strokeTargetMap = {
    [SANKEY_NODE_DIRECT_APPROVED]: "rgba(116, 154, 211, 0.8)",
    [SANKEY_NODE_DIRECT_ROFR]: "rgba(116, 154, 211, 0.8)",
    [SANKEY_NODE_SPV]: "rgba(116, 154, 211, 0.8)",
    [SANKEY_NODE_FORWARD]: "rgba(116, 154, 211, 0.8)",
    [SANKEY_NODE_UNKNOWN_SECURITY]: "rgba(212, 185, 114, 0.80)",
    [SANKEY_NODE_PREFERRED]: "rgba(152, 116, 211, 0.80)",
    [SANKEY_NODE_COMMON]: "rgba(152, 116, 211, 0.80)",
  };

  return (
    <Layer>
      <path
        className="recharts-sankey-link"
        d={`
          M${sourceX},${sourceY}
          C${sourceControlX},${sourceY} ${targetControlX},${targetY} ${targetX},${targetY}
        `}
        fill="none"
        stroke={strokeTargetMap[props.payload.target.name]}
        strokeWidth={Math.max(linkWidth || 0, 1)}
        strokeOpacity="0.2"
      />
    </Layer>
  );
};

CompanyTransferabilitySankeyLink.propTypes = {
  sourceX: PropTypes.number,
  sourceY: PropTypes.number,
  sourceControlX: PropTypes.number,
  targetX: PropTypes.number,
  targetY: PropTypes.number,
  targetControlX: PropTypes.number,
  linkWidth: PropTypes.number,
  payload: PropTypes.shape({
    target: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
};

export default CompanyTransferabilitySankeyLink;
