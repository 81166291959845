import _ from "lodash";
import { useMemo } from "react";

import useLatestOrderflowForPortfolio from "./useLatestOrderflowForPortfolio";
import useShareLotsForPortfolio from "./useShareLotsForPortfolio";

import { usePortfolioCompanies } from "./index";

/**
 * Computes the current total value of a portfolio given its ID. For each of the companies in the
 * portfolio, multiplies the total # of shares across the share lots for that company by the
 * current ZX Index Value, and sums them to obtain the total value.
 *
 * @param {string} portfolioId
 * @return {number|null} - Returns the computed value on success, or null if any of the queries are
 *    still loading or encountered errors.
 */
const usePortfolioValue = (portfolioId) => {
  const [portfolioCompanies, companiesLoading] =
    usePortfolioCompanies(portfolioId);
  // Get all the share lots and latest orderflow report for all the companies. useFetches guarantees
  // the order of the results matches the order of the queries, and we will make use of that fact
  // below.
  const [shareLots, shareLotsLoading] = useShareLotsForPortfolio(portfolioId);
  const [orderFlowReports, orderFlowLoading] =
    useLatestOrderflowForPortfolio(portfolioId);

  const currentValue = useMemo(() => {
    if (!shareLots || !orderFlowReports) {
      return null;
    }
    return (portfolioCompanies || [])
      .map((company, index) => {
        // Make use of the useFetches ordering guarantee to use `index`.
        const companyShareLots = shareLots[index];
        const latestOrderFlowReport = orderFlowReports[index][0];
        if (!latestOrderFlowReport) {
          // We only include companies with verified data in the portfolio value calculation.
          return 0;
        }
        // Determine the current value based on the current ZX Index Value multiplied by the total
        // number of shares.
        const totalShareQuantity = companyShareLots.reduce(
          (acc, shareLot) => acc + shareLot.share_quantity,
          0
        );
        return (
          totalShareQuantity * Number(latestOrderFlowReport.zx_index_price)
        );
      })
      .reduce((acc, value) => acc + value, 0);
  }, [shareLots, orderFlowReports, portfolioCompanies]);

  if (
    companiesLoading ||
    orderFlowLoading ||
    shareLotsLoading ||
    _.isEmpty(portfolioCompanies)
  ) {
    return null;
  }
  return currentValue;
};

export default usePortfolioValue;
