import styled from "styled-components";

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const PaddedForm = styled.form`
  padding: 0px 16px;
`;

const StyledReverseRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export { PaddedForm, StyledRow, StyledReverseRow };
