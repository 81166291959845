/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``msg-circle.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const MsgCircleIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C8.00376 16.5 7.05112 16.3054 6.17941 15.9513C6.10067 15.9194 6.05521 15.901 6.02165 15.8884C6.01768 15.8869 6.01423 15.8856 6.01128 15.8845C6.01017 15.8847 6.00899 15.8848 6.00772 15.885C5.98004 15.8888 5.94232 15.895 5.87029 15.907L3.20187 16.3517C3.19475 16.3529 3.18756 16.3541 3.18033 16.3553C3.05986 16.3754 2.92508 16.3979 2.80803 16.4068C2.68039 16.4164 2.47734 16.4197 2.26275 16.3277C1.99747 16.2139 1.78608 16.0025 1.6723 15.7372C1.58026 15.5227 1.58361 15.3196 1.59325 15.192C1.60208 15.0749 1.62459 14.9401 1.64471 14.8197C1.64591 14.8124 1.64711 14.8053 1.6483 14.7981L2.09304 12.1297C2.10504 12.0577 2.11125 12.02 2.115 11.9923C2.11517 11.991 2.11533 11.9898 2.11547 11.9887C2.1144 11.9858 2.11313 11.9823 2.11164 11.9784C2.09904 11.9448 2.08064 11.8993 2.04867 11.8206C1.69464 10.9489 1.5 9.99624 1.5 9ZM9 3C5.68629 3 3 5.68629 3 9C3 9.79948 3.15596 10.5607 3.43843 11.2562C3.44289 11.2672 3.44749 11.2784 3.45217 11.2899C3.49797 11.402 3.55243 11.5354 3.58034 11.6599C3.60569 11.773 3.61664 11.8719 3.61666 11.9878C3.61667 12.115 3.59498 12.2436 3.57749 12.3474C3.57582 12.3573 3.57419 12.3669 3.57263 12.3763L3.16241 14.8376L5.62369 14.4274C5.63309 14.4258 5.64274 14.4242 5.65261 14.4225C5.75637 14.405 5.88498 14.3833 6.0122 14.3833C6.12811 14.3834 6.22697 14.3943 6.34008 14.4197C6.4646 14.4476 6.59796 14.502 6.71011 14.5478C6.72158 14.5525 6.73283 14.5571 6.74383 14.5616C7.43933 14.844 8.20052 15 9 15C12.3137 15 15 12.3137 15 9C15 5.68629 12.3137 3 9 3Z"
        transform=""
      />
    </g>
  </svg>
);

MsgCircleIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

MsgCircleIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

MsgCircleIcon.displayName = "MsgCircleIcon";

export default MsgCircleIcon;
