import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { ListHeader, ListItem, List } from "yuka";

import ZXLogoIcon from "../utils/svgs/ZXLogo";
import { ROUTING_PATH } from "../routes/constants";

const SideNavigationBar = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  box-sizing: border-box;
  height: 100vh;
  padding: 0px 12px;
  overflow-y: auto;
  width: 240px;
`;

const StyledNavigationHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 24px 12px 64px;
`;

const StyledNavigationSection = styled.div`
  flex-grow: 1;
  margin-bottom: 32px;
`;

const AgreementsSideNav = () => {
  const navigate = useNavigate();

  return (
    <SideNavigationBar>
      <StyledNavigationHeader>
        <ZXLogoIcon />
      </StyledNavigationHeader>
      <StyledNavigationSection>
        <List>
          <ListHeader>Terms and Agreements</ListHeader>
          <ListItem
            onClick={() => navigate(ROUTING_PATH.AGREEMENT("privacy"))}
            text="Privacy Policy"
          />
          <ListItem
            onClick={() => navigate(ROUTING_PATH.AGREEMENT("tou"))}
            text="Terms of Use"
          />
          <ListItem
            onClick={() => navigate(ROUTING_PATH.AGREEMENT("nda"))}
            text="Non-Disclosure Agreement"
          />
        </List>
      </StyledNavigationSection>
    </SideNavigationBar>
  );
};

export default AgreementsSideNav;
