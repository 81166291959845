import React, { useState } from "react";
import InitialStep from "./InitialStep";
import TokenRegistrationStep from "./TokenRegistrationStep";
import DisableConfirmStep from "./DisableConfirmStep";
import PropTypes from "prop-types";

// Main component to handle the 2fa wizard logic
const TwoFactorAuthentication = (props) => {
  const [currentStep, setCurrentStep] = useState(0);

  // Go to the next step
  const goToNext = () => {
    setCurrentStep(currentStep + 1);
  };

  // Go back to the previous step
  const goToPrevious = () => {
    setCurrentStep(currentStep - 1);
  };

  const enableSteps = [
    <InitialStep
      key="initial-step"
      goToNext={goToNext}
      is2FaEnabled={props.user?.token_auth_enabled}
    />,
    <TokenRegistrationStep
      key="token-registration"
      goToPrevious={goToPrevious}
    />,
  ];

  const disableSteps = [
    <InitialStep
      key="initial-step"
      goToNext={goToNext}
      is2FaEnabled={props.user?.token_auth_enabled}
    />,
    <DisableConfirmStep key="disable-confirm" goToPrevious={goToPrevious} />,
  ];

  return props.user?.token_auth_enabled
    ? disableSteps[currentStep]
    : enableSteps[currentStep];
};

TwoFactorAuthentication.propTypes = {
  user: PropTypes.shape({
    token_auth_enabled: PropTypes.bool, // Boolean indicating if 2FA is enabled
  }),
};

export default TwoFactorAuthentication;
