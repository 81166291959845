import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { Form } from "react-final-form";
import {
  body1,
  Button,
  ButtonStyles,
  Card,
  CardStyles,
  ColorPalette,
  FontColors,
  InputRow,
} from "yuka";

import { FinalFormField } from "../hdYuka";

import { PaddedForm } from "./StyledComponents";
import useWrite from "../api/useWrite";
import { API_ENDPOINTS } from "../api/constants";
import LoadingSpinner from "../utils/LoadingSpinner";
import PasswordSecurityTips from "../utils/PasswordSecurityTips";

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledInputContainer = styled.div`
  margin: 24px 0px;
`;

const StyledSecurityTips = styled.div`
  ${body1};
  ${FontColors.theme80};
`;

const StyledStatusMessage = styled.div`
  ${body1};
  padding-left: 18px;
  padding-bottom: 16px;
  color: ${(props) => (props.$error ? ColorPalette.sell : ColorPalette.buy)};
`;

const EditPasswordForm = (props) => {
  const changePasswordQuery = useWrite(
    API_ENDPOINTS.CHANGE_PASSWORD(props.userId),
    true,
    {
      silent: false,
      message: "Your password has been updated",
    }
  );
  const validate = (value) => (value ? undefined : "Required");
  const onSubmit = (values) => {
    changePasswordQuery.mutate(values);
  };

  return (
    <Card
      cardStyle={CardStyles.SECTIONED}
      title="Password"
      tagline="For your protection, if you want to change your password, we will ask you to confirm your existing password."
    >
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting, valid, dirty }) => (
          <React.Fragment>
            {submitting && <LoadingSpinner />}
            <PaddedForm onSubmit={handleSubmit}>
              <StyledSecurityTips>
                <PasswordSecurityTips />
              </StyledSecurityTips>
              <StyledInputContainer>
                <InputRow>
                  <FinalFormField
                    displayIndicator={false}
                    id="old_password"
                    type="password"
                    placeholder="Old password"
                    name="old_password"
                    label="Old Password"
                    validate={validate}
                  />
                </InputRow>
                <InputRow>
                  <FinalFormField
                    id="password"
                    type="password"
                    name="password"
                    placeholder="New password"
                    label="New Password"
                    validate={validate}
                    displayIndicator={false}
                  />
                </InputRow>
                <InputRow>
                  <FinalFormField
                    id="confirm-password"
                    type="password"
                    name="confirm_password"
                    placeholder="Confirm new password"
                    label="New Password Confirmation"
                    validate={validate}
                    displayIndicator={false}
                  />
                </InputRow>
                {changePasswordQuery.isSuccess && (
                  <StyledStatusMessage>Password changed!</StyledStatusMessage>
                )}
                {changePasswordQuery.isError && (
                  <StyledStatusMessage $error>
                    Error:{" "}
                    {changePasswordQuery.error.response.data.Error ||
                      changePasswordQuery.error.response.data.non_field_errors}
                  </StyledStatusMessage>
                )}
              </StyledInputContainer>
              <StyledButtonContainer>
                <Button
                  buttonStyle={ButtonStyles.CTA}
                  disabled={submitting || !dirty || !valid}
                  type="submit"
                >
                  Change Password
                </Button>
              </StyledButtonContainer>
            </PaddedForm>
          </React.Fragment>
        )}
      </Form>
    </Card>
  );
};

EditPasswordForm.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default EditPasswordForm;
