import PropTypes from "prop-types";
import styled from "styled-components";
import { Fonts } from "yuka";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const StyledSize30Text = styled(Fonts.Headline3theme80)`
  font-size: 30px;
  line-height: 40px;
`;

const FundingRoundsStat = ({ mainStatistic, supportingText }) => (
  <StyledContainer>
    <Fonts.Overlinetheme50>{supportingText}</Fonts.Overlinetheme50>
    <StyledSize30Text>{mainStatistic}</StyledSize30Text>
  </StyledContainer>
);

FundingRoundsStat.propTypes = {
  mainStatistic: PropTypes.elementType.isRequired,
  supportingText: PropTypes.elementType,
};

FundingRoundsStat.defaultProps = {
  supportingText: null,
};

export default FundingRoundsStat;
