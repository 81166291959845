import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import { ChevronDownIcon, ChevronUpIcon } from "../Icons";
import { PrimaryColorPalette, SelectAnchorTypes } from "../StylingConstants";
import { YukaThemeProvider } from "../ThemeContext";
import useDropdown from "../Layout/useDropdown";

import MultipleCheckbox from "./MultipleCheckbox";
import {
  StyledInput as BaseStyledInput,
  StyledInputWrapper,
  SIZE_SMALL,
  SIZE_REGULAR,
} from "./internal/StyledComponents";
import { FontColors, StyledOverline } from "../Typography";
import { ActionChip } from "../Button";

const StyledInput = styled(BaseStyledInput)`
  cursor: pointer;

  ${props =>
    props.$noSelection
      ? css`
          ${FontColors.theme30}
        `
      : ""}
`;

const StyledWrapper = styled.div`
  // remove top margin from multiple checkboxes (used to create space under label normally)
  margin: -8px 16px 0;
  min-width: 208px;
`;

/**
 * An extension to `MultipleCheckbox` to store it within a Select-like container. Primarily for use
 * in filters
 */
const CheckboxDropdown = React.forwardRef((props, ref) => {
  const internalRef = React.useRef(null);
  const inputRef = ref || internalRef;

  const renderCheckboxes = () => (
    <StyledWrapper>
      <StyledOverline>{props.dropdownDescription}</StyledOverline>
      <MultipleCheckbox {...props} value={props.value || []} />
    </StyledWrapper>
  );

  const [dropdownContent, containerRef, toggleIsOpen] = useDropdown(renderCheckboxes);

  const size = props.small ? SIZE_SMALL : SIZE_REGULAR;
  const iconSize = props.small ? 16 : 18;
  const leadingIconColor = props.disabled
    ? PrimaryColorPalette.white15
    : PrimaryColorPalette.white30;
  const defaultChevronColor = dropdownContent
    ? PrimaryColorPalette.white80
    : PrimaryColorPalette.white50;

  const TrailingIcon = dropdownContent ? ChevronUpIcon : ChevronDownIcon;
  const trailingIconColor = props.disabled ? PrimaryColorPalette.white15 : defaultChevronColor;

  const focusAndToggle = () => {
    inputRef.current?.focus();
    toggleIsOpen();
  };

  const valueDisplay = `${props.placeholder} (${props.value?.length ?? 0})`;

  let renderedComponent = (
    <StyledInputWrapper
      $disabled={props.disabled}
      $error={props.showError}
      $size={size}
      className={props.className}
      ref={containerRef}
      onClick={focusAndToggle}
      role="listbox"
    >
      {props.leadingIcon && <leadingIcon size={iconSize} color={leadingIconColor} />}
      <StyledInput
        ref={inputRef}
        placeholder={props.placeholder}
        name={props.name}
        disabled={props.disabled}
        value={valueDisplay}
        $noSelection={(props.value?.length ?? 0) === 0}
        readOnly
      />
      <TrailingIcon size={iconSize} color={trailingIconColor} />
    </StyledInputWrapper>
  );

  if (props.anchorType === SelectAnchorTypes.CHIP) {
    renderedComponent = (
      <ActionChip
        ref={containerRef}
        onClick={props.disabled ? null : focusAndToggle}
        text={valueDisplay}
        role="listbox"
      />
    );
  }

  return (
    <YukaThemeProvider theme={{ componentVersion: 2 }}>
      {dropdownContent}
      {renderedComponent}
    </YukaThemeProvider>
  );
});

CheckboxDropdown.propTypes = {
  // Common input props
  /** A class applied to the wrapper around the 'input' component of the select */
  className: PropTypes.string,
  /** Whether the select is disabled or not */
  disabled: PropTypes.bool,
  /** What type of anchor should this select be rendered as? */
  anchorType: PropTypes.oneOf(Object.values(SelectAnchorTypes)),
  /** field name for this select */
  name: PropTypes.string.isRequired,
  /** Event handler to be called whenever the value of the select changes */
  onChange: PropTypes.func,
  /** What to display if no option is selected for the input */
  placeholder: PropTypes.string,
  small: PropTypes.bool,
  leadingIcon: PropTypes.func,

  /** Allows parent component to control the value stored on the select externally */
  value: PropTypes.any,

  /** Short description of the dropdown options displayed above options. */
  dropdownDescription: PropTypes.string,

  showError: PropTypes.bool,
};

CheckboxDropdown.defaultProps = {
  multiple: false,
  searchable: false,
  anchorType: SelectAnchorTypes.TEXT_BOX,

  className: "",
  disabled: false,
  placeholder: "",
  defaultOption: "",

  noSort: false,
  showError: false,

  dropdownDescription: "",
};

CheckboxDropdown.displayName = "CheckboxDropdown";

export default CheckboxDropdown;
