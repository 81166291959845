import { ButtonStyles, IconButtonStyles } from "../Button";
import { DEFAULT_COLOR_THEME } from "../StylingConstants";
import { DEFAULT_BUTTON_THEME, DEFAULT_ICON_BUTTON_THEME } from "../Button/themes";

const getThemeColor = (theme, color) => {
  if (!theme?.colors?.[color]) {
    return DEFAULT_COLOR_THEME[color];
  }
  return theme.colors[color];
};

const getButtonThemeField = (theme, specifiedButtonStyle, field) => {
  const buttonStyle = Object.keys(ButtonStyles).includes(specifiedButtonStyle)
    ? specifiedButtonStyle
    : "fallback";

  if (!theme?.button?.[buttonStyle]?.[field]) {
    return DEFAULT_BUTTON_THEME[buttonStyle][field];
  }
  return theme.button[buttonStyle][field];
};

const getIconButtonThemeField = (theme, specifiedButtonStyle, field) => {
  const buttonStyle = Object.keys(IconButtonStyles).includes(specifiedButtonStyle)
    ? specifiedButtonStyle
    : "fallback";

  if (!theme?.iconButton?.[buttonStyle]?.[field]) {
    return DEFAULT_ICON_BUTTON_THEME[buttonStyle][field];
  }
  return theme.iconButton[buttonStyle][field];
};

export { getThemeColor, getButtonThemeField, getIconButtonThemeField };
