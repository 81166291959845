/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``arrow-up-left.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ArrowUpLeftIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M4.5 5.25C4.5 4.83579 4.83579 4.5 5.25 4.5H12.75C13.1642 4.5 13.5 4.83579 13.5 5.25C13.5 5.66421 13.1642 6 12.75 6H7.06066L13.2803 12.2197C13.5732 12.5126 13.5732 12.9874 13.2803 13.2803C12.9874 13.5732 12.5126 13.5732 12.2197 13.2803L6 7.06066V12.75C6 13.1642 5.66421 13.5 5.25 13.5C4.83579 13.5 4.5 13.1642 4.5 12.75V5.25Z"
        transform=""
      />
    </g>
  </svg>
);

ArrowUpLeftIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ArrowUpLeftIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

ArrowUpLeftIcon.displayName = "ArrowUpLeftIcon";

export default ArrowUpLeftIcon;
