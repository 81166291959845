import React, { useCallback, useContext, useState } from "react";
import { useParams } from "react-router-dom";

import SetNewPassword from "./SetNewPassword";

import AxiosInstance from "../api/AxiosInstance";
import { API_ENDPOINTS } from "../api/constants";
import { AuthContext } from "../auth";

const NewUserSetPasswordForm = () => {
  const [errors, setErrors] = useState({});
  const params = useParams();
  const token = params.token;
  const { authenticate } = useContext(AuthContext);

  const onSubmit = useCallback(
    (values) => {
      if (!values.password || !values.confirm_password) {
        setErrors({
          submission: undefined,
          ...(values.password ? {} : { password: "Password is required" }),
          ...(values.confirm_password
            ? {}
            : { confirm_password: "Password is required" }),
        });
        return;
      }
      if (values.password !== values.confirm_password) {
        // The view for resetting password uses a library that doesn't have support for
        // password confirmation, so we do it in the frontend.
        setErrors({
          submission: "Passwords do not match",
        });
        return;
      }
      return AxiosInstance.post(
        API_ENDPOINTS.RESET_PASSWORD_NEW_USER(),
        JSON.stringify({
          accept_tou: values.accept_tou,
          accept_privacy: values.accept_privacy,
          accept_nda: values.accept_nda,
          password: values.password,
          token,
        })
      )
        .then(({ data }) => {
          authenticate(data, true);
        })
        .catch(({ response: { data } }) => {
          let errorMsg = data.error || "Unknown error occurred";
          if (data.password) {
            errorMsg = data.password.join(" ");
          } else if (data.detail) {
            errorMsg = "Expired or invalid URL.";
          }
          setErrors({
            submission: errorMsg,
          });
        });
    },
    [authenticate, token]
  );

  return (
    <SetNewPassword
      onSubmit={onSubmit}
      includeAgreements
      errors={errors}
      setErrors={setErrors}
    />
  );
};

export default NewUserSetPasswordForm;
