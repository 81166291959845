import { YukaColorPalette } from "yuka";

/**
 * Helper to get the dot indicating the hover position of a line on the company profile line chart.
 *
 * @param chartElement - An object with a `color` property that is a string.
 * @return {object}
 */
const getActiveDot = (chartElement) => ({
  stroke: YukaColorPalette.surface0,
  fill: chartElement.color,
  strokeWidth: 2,
  r: 6,
});

export default getActiveDot;
