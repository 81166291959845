/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``sun.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const SunIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M9 0.75C9.41421 0.75 9.75 1.08579 9.75 1.5V3C9.75 3.41421 9.41421 3.75 9 3.75C8.58579 3.75 8.25 3.41421 8.25 3V1.5C8.25 1.08579 8.58579 0.75 9 0.75ZM3.1446 3.1446C3.43749 2.8517 3.91236 2.8517 4.20526 3.1446L5.26592 4.20526C5.55881 4.49815 5.55881 4.97302 5.26592 5.26592C4.97302 5.55881 4.49815 5.55881 4.20526 5.26592L3.1446 4.20526C2.8517 3.91236 2.8517 3.43749 3.1446 3.1446ZM14.8554 3.1446C15.1483 3.43749 15.1483 3.91236 14.8554 4.20526L13.7947 5.26592C13.5019 5.55881 13.027 5.55881 12.7341 5.26592C12.4412 4.97302 12.4412 4.49815 12.7341 4.20526L13.7947 3.1446C14.0876 2.8517 14.5625 2.8517 14.8554 3.1446ZM9 6C7.34315 6 6 7.34315 6 9C6 10.6569 7.34315 12 9 12C10.6569 12 12 10.6569 12 9C12 7.34315 10.6569 6 9 6ZM4.5 9C4.5 6.51472 6.51472 4.5 9 4.5C11.4853 4.5 13.5 6.51472 13.5 9C13.5 11.4853 11.4853 13.5 9 13.5C6.51472 13.5 4.5 11.4853 4.5 9ZM0.75 9C0.75 8.58579 1.08579 8.25 1.5 8.25H3C3.41421 8.25 3.75 8.58579 3.75 9C3.75 9.41421 3.41421 9.75 3 9.75H1.5C1.08579 9.75 0.75 9.41421 0.75 9ZM14.25 9C14.25 8.58579 14.5858 8.25 15 8.25H16.5C16.9142 8.25 17.25 8.58579 17.25 9C17.25 9.41421 16.9142 9.75 16.5 9.75H15C14.5858 9.75 14.25 9.41421 14.25 9ZM5.26592 12.7372C5.55881 13.0301 5.55881 13.5049 5.26592 13.7978L4.20526 14.8585C3.91236 15.1514 3.43749 15.1514 3.1446 14.8585C2.8517 14.5656 2.8517 14.0907 3.1446 13.7978L4.20526 12.7372C4.49815 12.4443 4.97302 12.4443 5.26592 12.7372ZM12.7341 12.7372C13.027 12.4443 13.5019 12.4443 13.7947 12.7372L14.8554 13.7978C15.1483 14.0907 15.1483 14.5656 14.8554 14.8585C14.5625 15.1514 14.0876 15.1514 13.7947 14.8585L12.7341 13.7978C12.4412 13.5049 12.4412 13.0301 12.7341 12.7372ZM9 14.25C9.41421 14.25 9.75 14.5858 9.75 15V16.5C9.75 16.9142 9.41421 17.25 9 17.25C8.58579 17.25 8.25 16.9142 8.25 16.5V15C8.25 14.5858 8.58579 14.25 9 14.25Z"
        transform=""
      />
    </g>
  </svg>
);

SunIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

SunIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

SunIcon.displayName = "SunIcon";

export default SunIcon;
