import { useEffect, useState } from "react";

/**
 * Hook used to determine if the component has finished mounting.
 * Useful for suppressing hook functionality on mount.
 *
 * @returns {boolean} True if component finished mounting, False otherwise.
 */
const useDidMount = () => {
  const [didMount, setDidMount] = useState(false);

  useEffect(() => {
    setDidMount(true);
  }, []);

  return didMount;
};

export default useDidMount;
