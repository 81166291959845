/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``arrow-down-right.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ArrowDownRightIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M4.71967 4.71967C5.01256 4.42678 5.48744 4.42678 5.78033 4.71967L12 10.9393V5.25C12 4.83579 12.3358 4.5 12.75 4.5C13.1642 4.5 13.5 4.83579 13.5 5.25V12.75C13.5 13.1642 13.1642 13.5 12.75 13.5H5.25C4.83579 13.5 4.5 13.1642 4.5 12.75C4.5 12.3358 4.83579 12 5.25 12H10.9393L4.71967 5.78033C4.42678 5.48744 4.42678 5.01256 4.71967 4.71967Z"
        transform=""
      />
    </g>
  </svg>
);

ArrowDownRightIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ArrowDownRightIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

ArrowDownRightIcon.displayName = "ArrowDownRightIcon";

export default ArrowDownRightIcon;
