import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalStyles, Fonts, Button, ButtonStyles } from "yuka";

import { usePortfolio } from "./hooks";
import { StyledModalButtonRow, StyledModalContent } from "./StyledComponents";
import { isVerifiedPortfolioCompany } from "./utils";

import { API_ENDPOINTS } from "../api/constants";
import useDelete from "../api/useDelete";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";

const DeletePortfolioCompanyModal = ({
  onClose,
  portfolioCompanyAggregate,
}) => {
  const [portfolio] = usePortfolio();
  const deleteCompanyQuery = useDelete(API_ENDPOINTS.PORTFOLIO_COMPANIES(), {
    invalidateUrls: [
      {
        url: API_ENDPOINTS.PORTFOLIO_COMPANIES(),
        queryParams: { portfolio_id: portfolio?.apiId },
      },
    ],
  });

  const onSubmit = () => {
    MixpanelEvents.removeCompanyFromPortfolio(
      "portfolio",
      portfolio.apiId,
      portfolio.name,
      portfolioCompanyAggregate.name,
      isVerifiedPortfolioCompany(portfolioCompanyAggregate.company)
    );
    deleteCompanyQuery.mutate({ id: portfolioCompanyAggregate.company.apiId });
    onClose();
  };

  return (
    <Modal modalStyle={ModalStyles.MINIMAL} onClose={onClose}>
      <StyledModalContent>
        <Fonts.Headline2theme80>
          Remove {portfolioCompanyAggregate.name}?
        </Fonts.Headline2theme80>
        <Fonts.Body1theme80>
          This company with{" "}
          {`${portfolioCompanyAggregate.shareLots.length} ${
            portfolioCompanyAggregate.shareLots.length === 1
              ? " share lot"
              : " share lots"
          }`}{" "}
          will be removed.
        </Fonts.Body1theme80>
      </StyledModalContent>
      <StyledModalButtonRow>
        <Button buttonStyle={ButtonStyles.RAISED} onClick={onClose}>
          Cancel
        </Button>
        <Button buttonStyle={ButtonStyles.CTA} onClick={onSubmit}>
          Remove
        </Button>
      </StyledModalButtonRow>
    </Modal>
  );
};

DeletePortfolioCompanyModal.propTypes = {
  onClose: PropTypes.func,
  portfolioCompanyAggregate: PropTypes.shape({
    name: PropTypes.string,
    company: PropTypes.shape({
      apiId: PropTypes.string,
    }),
    shareLots: PropTypes.array,
  }).isRequired,
};

export default DeletePortfolioCompanyModal;
