/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``corner-down-left.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const CornerDownLeftIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M15 2.25C15.4142 2.25 15.75 2.58579 15.75 3V4.08333C15.75 5.31523 15.75 6.28569 15.6862 7.06667C15.6211 7.86343 15.4859 8.5288 15.1778 9.13345C14.6744 10.1213 13.8713 10.9244 12.8834 11.4278C12.2788 11.7359 11.6134 11.8711 10.8167 11.9362C10.0357 12 9.06521 12 7.83329 12H4.81066L7.28033 14.4697C7.57322 14.7626 7.57322 15.2374 7.28033 15.5303C6.98744 15.8232 6.51256 15.8232 6.21967 15.5303L2.46967 11.7803C2.17678 11.4874 2.17678 11.0126 2.46967 10.7197L6.21967 6.96967C6.51256 6.67678 6.98744 6.67678 7.28033 6.96967C7.57322 7.26256 7.57322 7.73744 7.28033 8.03033L4.81066 10.5H7.8C9.07249 10.5 9.98176 10.4994 10.6945 10.4412C11.3985 10.3837 11.8445 10.2737 12.2025 10.0913C12.9081 9.73175 13.4817 9.15807 13.8413 8.45246C14.0237 8.09451 14.1337 7.64852 14.1912 6.94452C14.2494 6.23176 14.25 5.32249 14.25 4.05V3C14.25 2.58579 14.5858 2.25 15 2.25Z"
        transform=""
      />
    </g>
  </svg>
);

CornerDownLeftIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

CornerDownLeftIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

CornerDownLeftIcon.displayName = "CornerDownLeftIcon";

export default CornerDownLeftIcon;
