/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``thumbs-up.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ThumbsUpIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M7.40193 1.56149C7.62125 1.06802 8.11061 0.75 8.65062 0.75C10.0862 0.75 11.25 1.91378 11.25 3.34938V6H13.8774C15.716 6 17.1221 7.6389 16.8425 9.45617L16.0348 14.7062C15.8096 16.1697 14.5504 17.25 13.0697 17.25H3C1.75736 17.25 0.75 16.2426 0.75 15V9.75C0.75 8.50736 1.75736 7.5 3 7.5H4.76259L7.40193 1.56149ZM6 15.75H13.0697C13.81 15.75 14.4397 15.2098 14.5522 14.4781L15.3599 9.22809C15.4997 8.31945 14.7967 7.5 13.8774 7.5H11.25C10.4216 7.5 9.75 6.82843 9.75 6V3.34938C9.75 2.77092 9.30325 2.29681 8.73595 2.25326L6.13331 8.10921C6.09578 8.19366 6.05103 8.27344 6 8.34794V15.75ZM4.5 9V15.75H3C2.58579 15.75 2.25 15.4142 2.25 15V9.75C2.25 9.33579 2.58579 9 3 9H4.5Z"
        transform=""
      />
    </g>
  </svg>
);

ThumbsUpIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ThumbsUpIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

ThumbsUpIcon.displayName = "ThumbsUpIcon";

export default ThumbsUpIcon;
