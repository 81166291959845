import React, { useContext, useMemo } from "react";
import { Fonts } from "yuka";

import CompanyQuickPriceCard from "./cards/CompanyQuickPriceCard";
import EmptyCompanyCarousel from "./EmptyCompanyCarousel";
import { useCompany } from "./hooks";
import { CarouselContainer, StyledCarouselActions } from "./StyledComponents";

import { API_ENDPOINTS } from "../api/constants";
import useFetch from "../api/useFetch";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../hdYuka/Carousel";
import {
  ACTIONS,
  COMPANY_PROFILE_MIXPANEL_TARGET,
  GlobalContext,
} from "../routes/StateProvider";
import { WEEKLY } from "../utils/constants";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";

const MostActiveCompanies = () => {
  const { dispatch } = useContext(GlobalContext);
  const [company, companyIsLoading] = useCompany();
  const mostActiveCompaniesQuery = useFetch(
    API_ENDPOINTS.COMPANY_LATEST_ORDER_FLOW(),
    {
      time_frame: WEEKLY,
      most_active: true,
      "page[size]": 12,
    }
  );

  const mostActiveCompanies = useMemo(() => {
    if (!mostActiveCompaniesQuery.isSuccess) {
      return null;
    }
    return mostActiveCompaniesQuery.cleanedData.data.map(
      (company) => company.apiId
    );
  }, [
    mostActiveCompaniesQuery.isSuccess,
    mostActiveCompaniesQuery.cleanedData,
  ]);

  if (
    companyIsLoading ||
    !mostActiveCompanies ||
    mostActiveCompanies.length === 0
  ) {
    return <EmptyCompanyCarousel title="Most active companies" />;
  }

  return (
    <CarouselContainer>
      <Fonts.Headline1theme80>Most active companies</Fonts.Headline1theme80>
      <Carousel
        slidesToScroll={6}
        onScrollNext={() =>
          MixpanelEvents.scrollMostActiveCompaniesCarousel(
            "next",
            company?.name
          )
        }
        onScrollPrev={() =>
          MixpanelEvents.scrollMostActiveCompaniesCarousel(
            "prev",
            company?.name
          )
        }
      >
        <CarouselContent>
          {mostActiveCompanies.map((companyId, index) => (
            <CarouselItem key={index}>
              <CompanyQuickPriceCard
                companyId={companyId}
                onClick={(companyName) => {
                  dispatch({
                    type: ACTIONS.updateMixpanelEventSource,
                    source: "most active companies carousel",
                    target: COMPANY_PROFILE_MIXPANEL_TARGET,
                  });
                  MixpanelEvents.navigateToMostActiveCompany(
                    company?.name,
                    companyName
                  );
                }}
              />
            </CarouselItem>
          ))}
        </CarouselContent>
        <StyledCarouselActions>
          <CarouselPrevious />
          <CarouselNext />
        </StyledCarouselActions>
      </Carousel>
    </CarouselContainer>
  );
};

export default MostActiveCompanies;
