import { useMemo } from "react";

import useCustomValuationsForPortfolio from "./useCustomValuationsForPortfolio";
import usePortfolioValueFromCustomValuation from "./usePortfolioValueFromCustomValuation";
import useShareLotsForPortfolio from "./useShareLotsForPortfolio";

/**
 * Essentially the same as `usePortfolioUnrealizedGain`, but uses the latest custom valuation
 * for each company instead of the current ZX Index Value.
 *
 * @param {string} portfolioId
 * @return {number|null} - Returns the computed on success, or null if any of the queries are still
 *    loading or encountered errors.
 */
const usePortfolioUnrealizedGainFromCustomValuation = (portfolioId) => {
  const currentValue = usePortfolioValueFromCustomValuation(portfolioId);
  const [shareLots, shareLotsLoading] = useShareLotsForPortfolio(portfolioId);
  const [customValuations, customValuationsLoading] =
    useCustomValuationsForPortfolio(portfolioId);

  // Compute the cost basis across all share lots for portfolioID
  const costBasis = useMemo(() => {
    if (!shareLots || !customValuations) {
      return null;
    }
    return shareLots.reduce((acc, companyShareLots, index) => {
      // We do not include company share lots for companies whose current ZX Index Value is null,
      // because then the share lot would be -100%.
      if (!companyShareLots || !customValuations[index]?.computed_value) {
        return acc;
      }
      return (
        acc +
        companyShareLots.reduce(
          (acc, shareLot) =>
            acc + Number(shareLot.price_per_share) * shareLot.share_quantity,
          0
        )
      );
    }, 0);
  }, [shareLots, customValuations]);

  const totalUnrealizedGain = useMemo(() => {
    if (costBasis === null || currentValue === null) {
      return null;
    }
    return currentValue - costBasis;
  }, [currentValue, costBasis]);

  if (shareLotsLoading || customValuationsLoading) {
    return null;
  }
  return totalUnrealizedGain;
};

export default usePortfolioUnrealizedGainFromCustomValuation;
