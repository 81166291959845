import PropTypes from "prop-types";
import { useMemo } from "react";
import { Form } from "react-final-form";
import { Button, ButtonStyles, Fonts, Modal, ModalStyles } from "yuka";

import ShareLotFieldSet from "./ShareLotFieldSet";
import { StyledModalButtonRow, StyledModalContent } from "./StyledComponents";
import { getPortfolioCompanyName, validateShareLots } from "./utils";

import { API_ENDPOINTS } from "../api/constants";
import useWrite from "../api/useWrite";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";

/**
 * A modal that allows a user to create or view/edit a single share lot. Does not allow users
 * to view/create/edit multiple share lots at once.
 *
 * @param {object|null} shareLot - When null, modal will be treated as a "create" modal. When
 *    non-null, shareLot will be used as the default values for the field.
 * @param {function} onClose - Function to call when the modal is closed.
 * @param {string} companyName - Name of the company that this share lot is for.
 */
const SingleShareLotModal = ({ source, mode, shareLot, onClose, company }) => {
  const initialValues = useMemo(
    () =>
      shareLot
        ? {
            shareLot: {
              shareQuantity: shareLot.share_quantity,
              pricePerShare: shareLot.price_per_share,
              purchaseDate: shareLot.purchase_date,
              security: shareLot.security,
            },
          }
        : {},
    [shareLot]
  );

  const createShareLot = useWrite(API_ENDPOINTS.SHARE_LOTS());
  // We'll need to invalidate the LIST endpoint related to this share lot's portfolio company
  // in the case of an edit so that the change is reflected in the table.
  const editShareLot = useWrite(
    API_ENDPOINTS.SHARE_LOT_DETAIL(shareLot?.apiId),
    true,
    {
      invalidateUrls: [
        {
          url: API_ENDPOINTS.SHARE_LOTS(),
          queryParams: { portfolio_company: shareLot?.portfolio_company?.[1] },
        },
      ],
    }
  );

  const companyName = getPortfolioCompanyName(company);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(values) => {
        const { shareLot: formValues } = values;
        const baseSubmitData = {
          purchase_date: formValues.purchaseDate,
          price_per_share: formValues.pricePerShare,
          share_quantity: formValues.shareQuantity,
          security: formValues.security,
        };
        if (shareLot) {
          editShareLot.mutate(baseSubmitData);
          MixpanelEvents.editShareLot(source, mode, companyName);
        } else {
          const submitData = {
            portfolio_company: company.apiId,
            ...baseSubmitData,
          };

          createShareLot.mutate(submitData);
          MixpanelEvents.addShareLotsToPortfolio(source, mode, 1);
        }
        onClose();
      }}
      validate={(values) => {
        if (!values.shareLot) {
          return undefined;
        }
        return validateShareLots([values.shareLot]);
      }}
    >
      {({ handleSubmit, dirty, valid, submitting }) => (
        <Modal onClose={onClose} modalStyle={ModalStyles.MINIMAL}>
          <form onSubmit={handleSubmit}>
            <StyledModalContent $width={756}>
              <Fonts.Headline2theme80>
                {shareLot ? "Edit " : "Record "}a share lot for {companyName}
              </Fonts.Headline2theme80>
              <ShareLotFieldSet
                companyId={company?.company?.zb_id}
                includeLabels={true}
                name="shareLot"
              />
            </StyledModalContent>
            <StyledModalButtonRow>
              <Button buttonStyle={ButtonStyles.RAISED} onClick={onClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={!dirty || !valid || submitting}
                buttonStyle={ButtonStyles.CTA}
              >
                Save
              </Button>
            </StyledModalButtonRow>
          </form>
        </Modal>
      )}
    </Form>
  );
};

SingleShareLotModal.propTypes = {
  source: PropTypes.string,
  mode: PropTypes.string,
  company: PropTypes.shape({
    apiId: PropTypes.string.isRequired,
    company: PropTypes.shape({
      zb_id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    requested_company: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  }),
  shareLot: PropTypes.shape({
    apiId: PropTypes.string.isRequired,
    purchase_date: PropTypes.string.isRequired,
    price_per_share: PropTypes.string.isRequired,
    share_quantity: PropTypes.number.isRequired,
    security: PropTypes.string.isRequired,
    portfolio_company: PropTypes.arrayOf(PropTypes.string),
  }),
  onClose: PropTypes.func.isRequired,
};

SingleShareLotModal.defaultProps = {
  source: null,
  shareLot: null,
};

export default SingleShareLotModal;
