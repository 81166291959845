import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { Avatar, Fonts } from "yuka";

import RoundedSquareIcon from "../hdYuka/RoundedSquareIcon";

const StyledCompanyNameContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const StyledAvatarPlaceholder = styled(RoundedSquareIcon)`
  border-radius: 50%;
`;

const CompanyCellRenderer = ({ value: { name, avatar } }) => {
  return (
    <StyledCompanyNameContainer>
      {avatar ? (
        <Avatar
          avatar={avatar}
          avatarAlt={name.slice(0, 2).toUpperCase()}
          size="24px"
        />
      ) : (
        <StyledAvatarPlaceholder iconSize={24} />
      )}

      <Fonts.Body1theme80>{name}</Fonts.Body1theme80>
    </StyledCompanyNameContainer>
  );
};

CompanyCellRenderer.propTypes = {
  value: PropTypes.shape({
    name: PropTypes.string,
    avatar: PropTypes.string,
  }),
};

export default CompanyCellRenderer;
