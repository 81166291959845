/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``signal.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const SignalIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M13.773 4.22703C11.1369 1.59099 6.86307 1.59099 4.22703 4.22703C1.59099 6.86307 1.59099 11.1369 4.22703 13.773C4.51992 14.0659 4.51992 14.5407 4.22703 14.8336C3.93414 15.1265 3.45926 15.1265 3.16637 14.8336C-0.0554559 11.6118 -0.0554566 6.38819 3.16637 3.16637C6.38819 -0.0554563 11.6118 -0.0554563 14.8336 3.16637C18.0555 6.38819 18.0555 11.6118 14.8336 14.8336C14.5407 15.1265 14.0659 15.1265 13.773 14.8336C13.4801 14.5407 13.4801 14.0659 13.773 13.773C16.409 11.1369 16.409 6.86307 13.773 4.22703ZM11.6517 6.34835C10.1872 4.88388 7.81282 4.88388 6.34835 6.34835C4.88388 7.81281 4.88388 10.1872 6.34835 11.6517C6.64124 11.9445 6.64124 12.4194 6.34835 12.7123C6.05546 13.0052 5.58058 13.0052 5.28769 12.7123C3.23744 10.6621 3.23744 7.33794 5.28769 5.28769C7.33794 3.23744 10.6621 3.23744 12.7123 5.28769C14.7626 7.33794 14.7626 10.6621 12.7123 12.7123C12.4194 13.0052 11.9445 13.0052 11.6517 12.7123C11.3588 12.4194 11.3588 11.9445 11.6517 11.6517C13.1161 10.1872 13.1161 7.81281 11.6517 6.34835ZM9.53033 8.46967C9.23744 8.17678 8.76256 8.17678 8.46967 8.46967C8.17678 8.76256 8.17678 9.23744 8.46967 9.53033C8.76256 9.82322 8.76256 10.2981 8.46967 10.591C8.17678 10.8839 7.7019 10.8839 7.40901 10.591C6.53033 9.71231 6.53033 8.28769 7.40901 7.40901C8.28769 6.53033 9.71231 6.53033 10.591 7.40901C11.4697 8.28769 11.4697 9.71231 10.591 10.591C10.2981 10.8839 9.82322 10.8839 9.53033 10.591C9.23744 10.2981 9.23744 9.82322 9.53033 9.53033C9.82322 9.23744 9.82322 8.76256 9.53033 8.46967Z"
        transform=""
      />
    </g>
  </svg>
);

SignalIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

SignalIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

SignalIcon.displayName = "SignalIcon";

export default SignalIcon;
