import React from "react";
import _ from "lodash";

import tokenizedStringCompare from "../../utils/tokenizedStringCompare";

const SEARCH_KEY = "text";

/**
 * This hook builds the search component to render for a select and provide critical information
 * related to what the current search is and if there is a matching option to update other display
 * aspects of the select.
 *
 * @param {Array} allOptions - a list of all options in the select
 *
 * @returns {Array} See return value for details
 */
const useSearch = allOptions => {
  const [search, setSearch] = React.useState("");

  const matchingOptions = React.useMemo(() => {
    // Computes the new matching options. Note that we realign from the default sort so "better"
    // matches appear first. This puts prefix matches ahead of a generic token match
    const startsWithOptions = [];
    const includesOptions = [];
    allOptions.forEach(option => {
      if (_.startsWith(_.toLower(option[SEARCH_KEY]), _.toLower(search))) {
        startsWithOptions.push(option);
      } else if (tokenizedStringCompare(search, option[SEARCH_KEY])) {
        includesOptions.push(option);
      }
    });
    return startsWithOptions.concat(includesOptions);
  }, [allOptions, search]);

  // Handle actual search updates to manage state and matches appropriately.
  const onSearch = React.useCallback(
    searchValue => {
      setSearch(searchValue);
    },
    [setSearch]
  );

  return [onSearch, matchingOptions];
};

export default useSearch;
