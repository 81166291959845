import _ from "lodash";

import roundDecimal from "./roundDecimal";
import {
  ONE_BILLION,
  ONE_MILLION,
  ONE_THOUSAND,
  ONE_TRILLION,
  TEN_THOUSAND,
} from "./constants";

/**
 * Convert a number into a readable string. this has some similarities to
 * ``convert_num_to_readable``
 *
 * @param {number} numRaw - the number to be converted
 * @param {number} decimalPlaces - optional argument
 * @param {boolean} preferExpanded - optional boolean to specify if you want to prefer to display
 *  decimals and a fully expanded number for numbers in the range [1000, 9999.99]. In example,
 *  instead of rendering $1340.50 as $1.34K, render it instead as $1341.50. Useful when the
 *  tradeoff of on-page width is worth it for the precision of the number.
 * @returns {string} a readable string representation of num
 */
const convertNumToReadable = (
  numRaw,
  decimalPlaces = 2,
  preferExpanded = false
) => {
  const num = _.isString(numRaw) ? Number.parseFloat(numRaw) : numRaw;
  if (num === 0) {
    return "0";
  }

  if (
    !num ||
    num === Number.POSITIVE_INFINITY ||
    num === Number.NEGATIVE_INFINITY
  ) {
    return "";
  }

  const abs = Math.abs(num);
  if (abs < ONE_THOUSAND) {
    return roundDecimal(num, decimalPlaces);
  }
  // Offers a way to render numbers < 10,000 without decimals, i.e. 1341.50 => 1342
  if (abs < TEN_THOUSAND) {
    const rounded = roundDecimal(num, 2);
    if (parseFloat(rounded) < TEN_THOUSAND && preferExpanded) {
      return rounded;
    }
  }
  if (abs < ONE_MILLION) {
    // Edge case handling where numbers like 999997.4 are less than $1.00M, but large enough
    // that they round to $1000.00K, should be displayed as $1.00M.
    const rounded = roundDecimal(num / ONE_THOUSAND, decimalPlaces);
    if (parseFloat(rounded) < ONE_THOUSAND) {
      return `${rounded}K`;
    }
  }
  if (abs < ONE_BILLION) {
    const rounded = roundDecimal(num / ONE_MILLION, decimalPlaces);
    if (parseFloat(rounded) < ONE_THOUSAND) {
      return `${rounded}M`;
    }
  }
  if (abs < ONE_TRILLION) {
    const rounded = roundDecimal(num / ONE_BILLION, decimalPlaces);
    if (parseFloat(rounded) < ONE_THOUSAND) {
      return `${rounded}B`;
    }
  }
  return `${roundDecimal(num / ONE_TRILLION, decimalPlaces)}T`;
};

export default convertNumToReadable;
