/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``sort.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const SortIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M4.71967 2.46967C5.01256 2.17678 5.48744 2.17678 5.78033 2.46967L8.78033 5.46967C9.07322 5.76256 9.07322 6.23744 8.78033 6.53033C8.48744 6.82322 8.01256 6.82322 7.71967 6.53033L6 4.81066V15C6 15.4142 5.66421 15.75 5.25 15.75C4.83579 15.75 4.5 15.4142 4.5 15V4.81066L2.78033 6.53033C2.48744 6.82322 2.01256 6.82322 1.71967 6.53033C1.42678 6.23744 1.42678 5.76256 1.71967 5.46967L4.71967 2.46967ZM12 13.1893V3C12 2.58579 12.3358 2.25 12.75 2.25C13.1642 2.25 13.5 2.58579 13.5 3V13.1893L15.2197 11.4697C15.5126 11.1768 15.9874 11.1768 16.2803 11.4697C16.5732 11.7626 16.5732 12.2374 16.2803 12.5303L13.2803 15.5303C12.9874 15.8232 12.5126 15.8232 12.2197 15.5303L9.21967 12.5303C8.92678 12.2374 8.92678 11.7626 9.21967 11.4697C9.51256 11.1768 9.98744 11.1768 10.2803 11.4697L12 13.1893Z"
        transform=""
      />
    </g>
  </svg>
);

SortIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

SortIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

SortIcon.displayName = "SortIcon";

export default SortIcon;
