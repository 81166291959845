import { mpTrack } from "./MixpanelUtils";

import { MODE_TO_MIXPANEL_NAME_MAP } from "../../Portfolios/constants";
import { KEY_ARROW_UP } from "../useHotkeys";

const MixpanelEvents = {
  /* Main Navigation */
  viewCompanyList: () => {
    mpTrack("view company list", {
      "event version": 1,
    });
  },
  viewMarketActivity: () => {
    mpTrack("view market activity", {
      "event version": 1,
    });
  },
  viewInsights: () => {
    mpTrack("view insights", {
      "event version": 1,
    });
  },
  viewAccountSettings: () => {
    mpTrack("view account settings", {
      "event version": 1,
    });
  },

  /* Company List */
  clickCompanyListItem: (companyName) => {
    mpTrack("click company list item", {
      "event version": 1,
      company: companyName,
    });
  },
  filterCompanyList: (filters) => {
    mpTrack("filter company list", {
      ...filters,
      "event version": 2,
    });
  },
  resetCompanyListFilter: (filter, value) => {
    mpTrack("reset company list filter", {
      filter,
      "filter value": value,
      "event version": 1,
    });
  },
  clickResetAllCompanyListFilters: (filters) => {
    mpTrack("click reset all company list filters", {
      ...filters,
      "event version": 1,
    });
  },

  /* Company Profile */
  viewCompanyProfile: (source, companyName) => {
    mpTrack("view company profile", {
      "event version": 2,
      source,
      company: companyName,
    });
  },
  downloadXlsSpreadsheet: (source) => {
    mpTrack("download xls spreadsheet", {
      source,
      "event version": 2,
    });
  },
  addCompanyToWatchlist: (source, companyName) => {
    mpTrack("add company to watchlist", {
      "event version": 1,
      source,
      company: companyName,
    });
  },
  removeCompanyFromWatchlist: (source, companyName) => {
    mpTrack("remove company from watchlist", {
      "event version": 1,
      source,
      company: companyName,
    });
  },
  navigateToCompanyWebsite: (companyName, url) => {
    mpTrack("navigate to company website", {
      "event version": 1,
      url,
      company: companyName,
    });
  },
  changeCompanyProfileLineChartTimeFrame: (
    companyName,
    oldTimeFrame,
    newTimeFrame
  ) => {
    mpTrack("change company profile line chart time frame", {
      "event version": 1,
      company: companyName,
      "old time frame": oldTimeFrame,
      "new time frame": newTimeFrame,
    });
  },
  addComparisonToCompanyProfileLineChart: (companyName, symbol, exchange) => {
    mpTrack("super chart add comparison", {
      "event version": 2,
      symbol,
      exchange,
      company: companyName,
    });
  },
  removeComparisonFromCompanyProfileLineChart: (
    companyName,
    symbol,
    exchange
  ) => {
    mpTrack("remove comparison from company profile line chart", {
      "event version": 1,
      symbol,
      exchange,
      company: companyName,
    });
  },
  addIndicatorToCompanyProfileLineChart: (companyName, indicator) => {
    mpTrack("super chart add indicator", {
      "event version": 2,
      indicator,
      company: companyName,
    });
  },
  removeIndicatorFromCompanyProfileLineChart: (
    source,
    companyName,
    indicator
  ) => {
    mpTrack("remove indicator from company profile line chart", {
      "event version": 1,
      source,
      indicator,
      company: companyName,
    });
  },
  hoverCompanyProfileLineChartLegendItem: (companyName, legendItemName) => {
    mpTrack("hover company profile line chart legend item", {
      "event version": 1,
      company: companyName,
      "legend item": legendItemName,
    });
  },
  hoverCompanyProfileLineChart: (
    companyName,
    hoveredDate,
    numberOfComparisons,
    numberOfIndicators
  ) => {
    mpTrack("hover company profile line chart", {
      "event version": 1,
      company: companyName,
      date: hoveredDate,
      "number of comparisons": numberOfComparisons,
      "number of indicators": numberOfIndicators,
    });
  },
  openCompanyReport: (documentSource, articleType, companyName) => {
    mpTrack("open company report", {
      "event version": 1,
      "article type": articleType,
      documentSource,
      company: companyName,
    });
  },
  scrollCompanyReportsCarousel: (direction, articleType, companyName) => {
    mpTrack("scroll company reports carousel", {
      "event version": 1,
      "article type": articleType,
      direction,
      company: companyName,
    });
  },
  openReportsAndInterviewsDisclaimer: (companyName) => {
    mpTrack("open reports and interviews disclaimer", {
      "event version": 1,
      company: companyName,
    });
  },
  scrollCompanyNewsCarousel: (direction, companyName) => {
    mpTrack("scroll company news carousel", {
      "event version": 1,
      direction,
      company: companyName,
    });
  },
  openCompanyNewsArticle: (companyName, articleUrl) => {
    mpTrack("open company news article", {
      "event version": 1,
      url: articleUrl,
      company: companyName,
    });
  },
  navigateToMentionDotCom: (source, companyName) => {
    mpTrack("navigate to mention.com", {
      "event version": 1,
      source,
      company: companyName,
    });
  },
  scrollSimilarCompaniesCarousel: (direction, companyName) => {
    mpTrack("scroll similar companies carousel", {
      "event version": 1,
      direction,
      company: companyName,
    });
  },
  navigateToSimilarCompany: (companyName, similarCompanyName) => {
    mpTrack("navigate to similar company", {
      "event version": 1,
      "source company": companyName,
      "similar company": similarCompanyName,
    });
  },
  scrollMostActiveCompaniesCarousel: (direction, companyName) => {
    mpTrack("scroll most active companies carousel", {
      "event version": 1,
      direction,
      company: companyName,
    });
  },
  navigateToMostActiveCompany: (companyName, activeCompanyName) => {
    mpTrack("navigate to most active company", {
      "event version": 1,
      "source company": companyName,
      "most active company": activeCompanyName,
    });
  },
  viewCompanyCertificatesOfIncorporationList: (companyName) => {
    mpTrack("view company certificates of incorporation list", {
      "event version": 1,
      company: companyName,
    });
  },
  viewCompanyCertificateOfIncorporation: (companyName, coiDate) => {
    mpTrack("view company certificate of incorporation", {
      "event version": 1,
      company: companyName,
      date: coiDate,
    });
  },
  hoverCompanyReportedMarksCard: (companyName, reportPeriod) => {
    mpTrack("hover company reported marks card", {
      "event version": 1,
      company: companyName,
      "report period": reportPeriod,
    });
  },
  openCompanyReportedMarksModal: (companyName) => {
    mpTrack("open company reported marks modal", {
      "event version": 1,
      company: companyName,
    });
  },
  hoverCompanyReportedMarksModal: (companyName, reportPeriod) => {
    mpTrack("hover company reported marks modal", {
      "event version": 1,
      company: companyName,
      "report period": reportPeriod,
    });
  },
  selectCompanyReportedMarksModal: (companyName, reportPeriod) => {
    mpTrack("select company reported marks modal", {
      "event version": 1,
      company: companyName,
      "report period": reportPeriod,
    });
  },
  selectCompanyBidOfferRatioType: (companyName, type) => {
    mpTrack("select company bid offer ratio type", {
      "event version": 1,
      company: companyName,
      type,
    });
  },
  hoverCompanyBidOfferRatioCard: (companyName, reportPeriod) => {
    mpTrack("hover company bid offer ratio card", {
      "event version": 1,
      company: companyName,
      "report period": reportPeriod,
    });
  },

  /* Postmoney valuation calculator */
  toggleCustomEnterpriseValuationCalculator: (companyName, isOpen) => {
    mpTrack("toggle postmoney valuation calculator", {
      "event version": 1,
      company: companyName,
      open: isOpen,
    });
  },
  modifyCustomPricePerShareField: (companyName, oldValue, newValue) => {
    mpTrack("modify custom price per share field", {
      "event version": 1,
      company: companyName,
      "old value": oldValue === undefined ? "" : oldValue,
      "new value": newValue,
    });
  },
  modifyCustomValuationField: (companyName, oldValue, newValue) => {
    mpTrack("modify custom valuation field", {
      "event version": 1,
      company: companyName,
      "old value": oldValue === undefined ? "" : oldValue,
      "new value": newValue,
    });
  },
  clickHoldingsListItem: (companyName) => {
    mpTrack("click holdings list item", {
      "event version": 1,
      company: companyName,
    });
  },
  clickValuationCardViewPastValuautions: (companyName) => {
    mpTrack("click valuation card view past valuations", {
      "event version": 1,
      company: companyName,
    });
  },
  clickValuationCardNewValuation: (companyName) => {
    mpTrack("click valuation card new valuation", {
      "event version": 1,
      company: companyName,
    });
  },
  clickFundingsTab: (companyName, tabName) => {
    mpTrack("click fundings tab", {
      "event version": 1,
      company: companyName,
      tab: tabName,
    });
  },
  clickFundingsShowMore: (companyName, tabName, expand) => {
    mpTrack("click fundings show more", {
      "event version": 1,
      company: companyName,
      tab: tabName,
      expand,
    });
  },

  /* Valuation Calculator */
  openCustomValuationModal: (companyName, isCreate) => {
    mpTrack("open custom valuation modal", {
      "event version": 1,
      create: isCreate,
      company: companyName,
    });
  },
  closeValuationModal: (companyName, isCreate) => {
    mpTrack("close custom valuation modal", {
      "event version": 1,
      create: isCreate,
      company: companyName,
    });
  },
  clickValuationDate: (companyName) => {
    mpTrack("click valuation date", {
      "event version": 1,
      company: companyName,
    });
  },
  modifyValuationDate: (companyName, oldValue, newValue) => {
    mpTrack("modify custom valuation date", {
      "event version": 1,
      company: companyName,
      "old value": oldValue === undefined ? "" : oldValue,
      "new value": newValue,
    });
  },
  modifyPreviousValuation: (companyName, oldValue, newValue) => {
    mpTrack("modify custom valuation previous valuation value", {
      "event version": 1,
      company: companyName,
      "old value": oldValue === undefined ? "" : oldValue,
      "new value": newValue,
    });
  },
  modifyPreviousValuationDate: (companyName, oldValue, newValue) => {
    mpTrack("modify custom valuation previous valuation date", {
      "event version": 1,
      company: companyName,
      "old value": oldValue === undefined ? "" : oldValue,
      "new value": newValue,
    });
  },
  modifyValuationWeightField: (
    companyName,
    fieldName,
    fieldType,
    oldValue,
    newValue
  ) => {
    mpTrack("modify valuation weight field", {
      "event version": 1,
      company: companyName,
      field: fieldName,
      fieldType: fieldType,
      "old value": oldValue,
      "new value": newValue,
    });
  },
  selectValuationComparison: (companyName, comparison, comparisonType) => {
    mpTrack("select valuation comparison", {
      "event version": 1,
      company: companyName,
      comparison: comparison,
      comparisonType,
    });
  },
  clearValuationComparison: (companyName, comparisonType) => {
    mpTrack("clear valuation comparison", {
      "event version": 1,
      company: companyName,
      comparisonType,
    });
  },
  createCustomValuation: (companyName, valuationDate) => {
    mpTrack("create custom valuation", {
      "event version": 1,
      company: companyName,
      date: valuationDate,
    });
  },
  deleteCustomValuation: (companyName, valuationDate) => {
    mpTrack("delete custom valuation", {
      "event version": 1,
      company: companyName,
      date: valuationDate,
    });
  },
  modifyDefaultComponentValue: (
    companyName,
    fieldName,
    fieldType,
    defaultValue,
    newValue
  ) => {
    mpTrack("modify default component value", {
      "event version": 1,
      company: companyName,
      field: fieldName,
      fieldType,
      defaultValue,
      newValue,
    });
  },
  restoreDefaultComponentValue: (companyName, fieldName, fieldType) => {
    mpTrack("restore default component value", {
      "event version": 1,
      company: companyName,
      field: fieldName,
      fieldType,
    });
  },
  addCustomCriterion: (companyName) => {
    mpTrack("add custom criterion", {
      "event version": 1,
      company: companyName,
    });
  },
  removeCustomCriterion: (companyName, criterionName) => {
    mpTrack("remove custom criterion", {
      "event version": 1,
      company: companyName,
      field: criterionName,
    });
  },
  modifyCustomCriterionName: (companyName, oldName, newName) => {
    mpTrack("modify custom criterion name", {
      "event version": 1,
      company: companyName,
      "old name": oldName === undefined ? "" : oldName,
      "new name": newName,
    });
  },
  modifyCustomCriterionValue: (
    companyName,
    criterionName,
    oldValue,
    newValue
  ) => {
    mpTrack("modify custom criterion value", {
      "event version": 1,
      company: companyName,
      field: criterionName,
      "old value": oldValue === undefined ? "" : oldValue,
      "new value": newValue,
    });
  },

  /* Market Activity */
  heatMapFilterSector: (sector) => {
    mpTrack("heat map filter sector", {
      "event version": 1,
      sector: sector,
    });
  },

  /* Global search */
  toggleGlobalSearch: (hotkeyUsed, isOpen) => {
    mpTrack("toggle global search", {
      "event version": 1,
      hotkey: hotkeyUsed,
      open: isOpen,
    });
  },
  navigateGlobalSearchDropdown: (direction) => {
    mpTrack("navigate global search dropdown", {
      "event version": 1,
      direction: direction === KEY_ARROW_UP ? "up" : "down",
    });
  },
  globalSearchSelectCompany: (hotkeyUsed, searchQuery, companyName) => {
    mpTrack("global search select company", {
      "event version": 1,
      hotkey: hotkeyUsed,
      search: searchQuery,
      company: companyName,
    });
  },
  globalSearchQueryCompany: (searchQuery) => {
    mpTrack("global search query company", {
      "event version": 1,
      search: searchQuery,
    });
  },

  /* Portfolios */
  navigateToPortfolioProfile: (source, portfolioId, name) => {
    mpTrack("navigate to portfolio", {
      "event version": 1,
      source,
      portfolio: portfolioId,
      "portfolio name": name,
    });
  },
  openAddNewPortfolioModal: (source) => {
    mpTrack("open add new portfolio modal", {
      "event version": 1,
      source,
    });
  },
  addNewPortfolio: (source) => {
    mpTrack("add new portfolio", {
      "event version": 1,
      source,
    });
  },
  openRenamePortfolioModal: () => {
    mpTrack("open rename portfolio modal", {
      "event version": 1,
    });
  },
  renamePortfolio: (source, id, oldName, name) => {
    mpTrack("rename portfolio", {
      "event version": 1,
      source,
      id,
      "new name": name,
      "old name": oldName,
    });
  },
  openDeletePortfolioModal: () => {
    mpTrack("open delete portfolio modal", {
      "event version": 1,
    });
  },
  deletePortfolio: (
    source,
    portfolioId,
    portfolioName,
    numberOfCompanies,
    hasShareLots
  ) => {
    mpTrack("delete portfolio", {
      "event version": 1,
      source,
      portfolio: portfolioId,
      "portfolio name": portfolioName,
      "number of companies in portfolio": numberOfCompanies,
      "has share lots": hasShareLots,
    });
  },
  openAddCompanyToPortfolioModal: (source) => {
    mpTrack("open add company to portfolio modal", {
      "event version": 1,
      source,
    });
  },
  addCompanyToPortfolio: (
    source,
    portfolioId,
    portfolioName,
    companyName,
    isZXDataCompany
  ) => {
    mpTrack("add company to portfolio", {
      "event version": 1,
      source,
      portfolio: portfolioId,
      "portfolio name": portfolioName,
      company: companyName,
      "ZXData company": isZXDataCompany,
    });
  },
  openRemoveCompanyFromPortfolioModal: (
    source,
    mode,
    portfolioId,
    portfolioName,
    companyName,
    isZXDataCompany
  ) => {
    mpTrack("open remove company from portfolio modal", {
      "event version": 1,
      source,
      mode: MODE_TO_MIXPANEL_NAME_MAP[mode],
      portfolio: portfolioId,
      "portfolio name": portfolioName,
      company: companyName,
      "ZXData company": isZXDataCompany,
    });
  },
  removeCompanyFromPortfolio: (
    source,
    portfolioId,
    portfolioName,
    companyName,
    isZXDataCompany
  ) => {
    mpTrack("remove company from portfolio", {
      "event version": 1,
      portfolio: portfolioId,
      "portfolio name": portfolioName,
      company: companyName,
      "ZXData company": isZXDataCompany,
    });
  },
  openAddShareLotModal: (source, mode) => {
    mpTrack("open add share lot modal", {
      "event version": 1,
      source,
      mode: MODE_TO_MIXPANEL_NAME_MAP[mode],
    });
  },
  openEditShareLotModal: (source, mode) => {
    mpTrack("open edit share lot modal", {
      "event version": 1,
      source,
      mode: MODE_TO_MIXPANEL_NAME_MAP[mode],
    });
  },
  addShareLotsToPortfolio: (source, mode, quantity) => {
    mpTrack("add share lots", {
      "event version": 1,
      source,
      mode: MODE_TO_MIXPANEL_NAME_MAP[mode],
      "number created": quantity,
    });
  },
  editShareLot: (source, mode, companyName) => {
    mpTrack("edit share lot", {
      "event version": 1,
      source,
      mode: MODE_TO_MIXPANEL_NAME_MAP[mode],
      company: companyName,
    });
  },
  openRemoveShareLotModal: (source, mode, companyName) => {
    mpTrack("open remove share lot modal", {
      "event version": 1,
      source,
      mode: MODE_TO_MIXPANEL_NAME_MAP[mode],
      company: companyName,
    });
  },
  removeShareLot: (source, mode, companyName) => {
    mpTrack("remove share lot", {
      "event version": 1,
      source,
      mode: MODE_TO_MIXPANEL_NAME_MAP[mode],
      company: companyName,
    });
  },
  exportPortfolio: (
    source,
    mode,
    portfolioId,
    portfolioName,
    numberOfCompanies
  ) => {
    mpTrack("export portfolio", {
      "event version": 1,
      source,
      mode: MODE_TO_MIXPANEL_NAME_MAP[mode],
      portfolio: portfolioId,
      "portfolio name": portfolioName,
      "number of companies in portfolio": numberOfCompanies,
    });
  },
  expandPortfolioCompany: (mode) => {
    mpTrack("expand portfolio company", {
      "event version": 1,
      mode: MODE_TO_MIXPANEL_NAME_MAP[mode],
    });
  },
  collapsePortfolioCompany: (mode) => {
    mpTrack("collapse company", {
      "event version": 1,
      mode: MODE_TO_MIXPANEL_NAME_MAP[mode],
    });
  },
  expandAllCompanies: (mode) => {
    mpTrack("click expand all", {
      "event version": 1,
      mode: MODE_TO_MIXPANEL_NAME_MAP[mode],
    });
  },
  collapseAllCompanies: (mode) => {
    mpTrack("click collapse all", {
      "event version": 1,
      mode: MODE_TO_MIXPANEL_NAME_MAP[mode],
    });
  },
  sortPortfolioCompanies: (mode, sortBy, sortDirection) => {
    mpTrack("sort portfolio", {
      "event version": 1,
      mode: MODE_TO_MIXPANEL_NAME_MAP[mode],
      "sort by": sortBy,
      "sort direction": sortDirection,
    });
  },
  togglePortfolioMode: (oldMode, newMode) => {
    mpTrack("toggle portfolio mode", {
      "event version": 1,
      "old mode": MODE_TO_MIXPANEL_NAME_MAP[oldMode],
      "new mode": MODE_TO_MIXPANEL_NAME_MAP[newMode],
    });
  },

  /* Insights */
  openMonthlyMoversArticle: (articleTitle) => {
    mpTrack("open monthly movers article", {
      "event version": 1,
      article: articleTitle,
    });
  },
  openReportOrPressRelease: (articleTitle) => {
    mpTrack("open report or press release", {
      "event version": 1,
      article: articleTitle,
    });
  },

  /* Miscellaneous */
  clickGlobalNavigationPage: (option) => {
    mpTrack("click global navigation page", {
      "event version": 1,
      option,
    });
  },
  useHotkey: (hotkey) => {
    mpTrack("use hotkey", {
      "event version": 1,
      hotkey,
    });
  },
  openTooltip: (tooltipName) => {
    mpTrack("open helper tooltip", {
      "event version": 1,
      tooltip: tooltipName,
    });
  },
  openReportABug: () => {
    mpTrack("open report a bug", {
      "event version": 1,
    });
  },
  openTermsOfUse: () => {
    mpTrack("open terms of use", {
      "event version": 1,
    });
  },
  openPrivacyPolicy: () => {
    mpTrack("open privacy policy", {
      "event version": 1,
    });
  },
  openNDA: () => {
    mpTrack("open NDA", {
      "event version": 1,
    });
  },

  /* Walkthroughs */
  startWalkthrough: (name) => {
    mpTrack("start walkthrough", {
      "event version": 1,
      walkthrough: name,
    });
  },
  completeWalkthrough: (name, stepsCompleted) => {
    mpTrack("complete walkthrough", {
      "event version": 1,
      walkthrough: name,
      "steps completed": stepsCompleted,
    });
  },
  skipWalkthrough: (name, stepSkippedOn, stepsRemaining) => {
    mpTrack("skip walkthrough", {
      "event version": 1,
      walkthrough: name,
      "skipped on step": stepSkippedOn,
      "steps remaining": stepsRemaining,
    });
  },
  completeWalkthroughStep: (name, step) => {
    mpTrack("complete walkthrough step", {
      "event version": 1,
      walkthrough: name,
      step,
    });
  },
};

export default MixpanelEvents;
