import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useFormState } from "react-final-form";
import { Fonts } from "yuka";

import { useLatestReportedMarks } from "./hooks";
import { StyledDetailPanelFields } from "./StyledComponents";

const FundMarksField = ({ companyId }) => {
  const { values } = useFormState();
  const [, reportedMarksDate] = useLatestReportedMarks(
    companyId,
    values.valuation_date,
    Boolean(companyId)
  );

  return (
    <StyledDetailPanelFields>
      <Fonts.Body1theme50>
        If available we use the dollar-weighted average price at which funds are
        marking their positions as our default value.
      </Fonts.Body1theme50>
      {reportedMarksDate && (
        <Fonts.Body1theme50>
          Our most recent reported marks are from{" "}
          <Fonts.Body1theme80>
            {DateTime.fromISO(reportedMarksDate).toFormat("LLLL, yyyy")}
          </Fonts.Body1theme80>
          .
        </Fonts.Body1theme50>
      )}
    </StyledDetailPanelFields>
  );
};

FundMarksField.propTypes = {
  companyId: PropTypes.string,
};

export default FundMarksField;
