import _ from "lodash";
import { IconButton } from "../../Button";
import PropTypes from "prop-types";
import React from "react";

const IconButtonCell = ({ value, ...props }) => {
  // Ensures that the column onClick handler is not also passed to the `Cell` component.
  const { onClick: columnOnClick, ...columnProps } = props.column;
  const onClick = columnOnClick ? event => columnOnClick({ event, ...props }) : _.identity;
  return <IconButton {...columnProps} icon={value || props.column.icon} onClick={onClick} />;
};

IconButtonCell.propTypes = {
  value: PropTypes.string.isRequired,
  column: PropTypes.shape({
    dateFormatter: PropTypes.func,
    onClick: PropTypes.func,
    icon: PropTypes.func,
  }).isRequired,
};

IconButtonCell.displayName = "IconButtonCell";

export default IconButtonCell;
