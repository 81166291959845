/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``wifi.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const WifiIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M8.99992 4.125C6.07046 4.125 3.404 5.2437 1.40173 7.07852C1.09634 7.35836 0.621919 7.33766 0.342075 7.03227C0.0622309 6.72689 0.0829362 6.25247 0.388322 5.97262C2.65635 3.89428 5.68077 2.625 8.99992 2.625C12.3191 2.625 15.3435 3.89428 17.6115 5.97262C17.9169 6.25247 17.9376 6.72689 17.6578 7.03227C17.3779 7.33766 16.9035 7.35836 16.5981 7.07852C14.5958 5.2437 11.9294 4.125 8.99992 4.125ZM8.99999 7.875C7.09999 7.875 5.36661 8.58054 4.04471 9.74501C3.7339 10.0188 3.25997 9.9888 2.98617 9.67799C2.71237 9.36717 2.74238 8.89325 3.05319 8.61945C4.63848 7.22295 6.72113 6.375 8.99999 6.375C11.2788 6.375 13.3615 7.22295 14.9468 8.61945C15.2576 8.89325 15.2876 9.36717 15.0138 9.67799C14.74 9.9888 14.2661 10.0188 13.9553 9.74501C12.6334 8.58054 10.9 7.875 8.99999 7.875ZM8.99992 11.625C8.11394 11.625 7.30156 11.9313 6.65995 12.4444C6.33646 12.7031 5.8645 12.6506 5.6058 12.3271C5.3471 12.0036 5.39962 11.5317 5.72311 11.273C6.62104 10.5549 7.76131 10.125 8.99992 10.125C10.22 10.125 11.3448 10.5421 12.2364 11.2411C12.5624 11.4966 12.6196 11.968 12.364 12.294C12.1085 12.62 11.6371 12.6771 11.3111 12.4216C10.6741 11.9223 9.87275 11.625 8.99992 11.625ZM8.25 14.625C8.25 14.2108 8.58579 13.875 9 13.875H9.0075C9.42172 13.875 9.7575 14.2108 9.7575 14.625C9.7575 15.0392 9.42172 15.375 9.0075 15.375H9C8.58579 15.375 8.25 15.0392 8.25 14.625Z"
        transform=""
      />
    </g>
  </svg>
);

WifiIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

WifiIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

WifiIcon.displayName = "WifiIcon";

export default WifiIcon;
