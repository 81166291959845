import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import Card, { CardStyles } from "./Card";
import GraphLegend from "./GraphLegend";

const StyledGraphContainer = styled.div`
  padding: 0 16px;
  flex-grow: 1;
  min-height: 0;
`;

/**
 * In general, graph cards offer a standardized card used to display a card that contains only
 * a legend and all other children, in order. In cases where a more involved card layout involving
 * a graph is required implementors should use the standard Card component, and manually attach
 * the legend.
 *
 * Examples of "more involved layouts" include but are not limited to:
 * 1. Graph legend needs to appear below the graph.
 * 2. Card needs to render a series of filter chips above the graph's legend.
 */
const GraphCard = ({ legend, children, ...props }) => {
  return (
    <Card cardStyle={CardStyles.SECTIONED} {...props}>
      <GraphLegend legendData={legend} />
      <StyledGraphContainer>{children}</StyledGraphContainer>
    </Card>
  );
};

GraphCard.propTypes = {
  children: PropTypes.node.isRequired,
  legend: PropTypes.array,
};

export default GraphCard;
