import React, { useState } from "react";

import LoginForm from "./LoginForm";
import TokenForm from "./TokenForm";

const Login = () => {
  const [credentials, setCredentials] = useState(null);

  return credentials ? (
    <TokenForm credentials={credentials} setCredentials={setCredentials} />
  ) : (
    <LoginForm setCredentials={setCredentials} />
  );
};

export default Login;
