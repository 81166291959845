import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import styled from "styled-components";

import useDynamicPosition from "../utils/useDynamicPosition";
import { Orientations, PrimaryColorPalette, Z_INDEXES } from "../StylingConstants";
import { caption2, FontColors } from "../Typography";
import { YukaThemeProvider } from "../ThemeContext";

const THRESHOLD = 16;
const OVERHANG = 0; // how much the element is shifted over the element
const SPACING = 8; // gap from the element to the tooltip

const StyledSpacingFloater = styled.div`
  ${props =>
    props.$orientation === Orientations.VERTICAL
      ? `
    padding: 0 ${SPACING}px;
  `
      : `
    padding: ${SPACING}px 0;
  `}
  max-width: 240px;
  position: absolute;
  z-index: ${Z_INDEXES.zIndexModalVisible};
`;

const StyledTooltip = styled.div`
  ${caption2}
  ${FontColors.theme80}
  background: ${PrimaryColorPalette.white80};
  border-radius: 4px;
  max-width: 240px;
  padding: 4px 8px;
  width: fit-content;
  overflow-wrap: anywhere;
`;

/**
 * Yuka v2.0
 *
 * A tooltip that appear when you hover over the element with the provider ref attached.
 *
 * Use this using `useTooltip`.
 *
 * Usage: `const tooltipElement = useTooltip(ref, tooltipContents)`
 *
 * `tooltipElement` is null whenever you are not hovering the ref.
 */
const Tooltip = React.forwardRef(({ orientation, contents, container, id }, ref) => {
  const [style] = useDynamicPosition({
    containerRef: container,
    elementRef: ref,
    OVERHANG,
    SPACING: 0, // Wrapping so that technically we overlap for hover reasons
    THRESHOLD,
    orientation,
  });

  return ReactDOM.createPortal(
    <YukaThemeProvider theme={{ mode: "light" }}>
      <StyledSpacingFloater style={style} ref={ref} $orientation={orientation} role="presentation">
        <StyledTooltip id={id} role="tooltip">
          {contents}
        </StyledTooltip>
      </StyledSpacingFloater>
    </YukaThemeProvider>,
    document.body
  );
});

Tooltip.propTypes = {
  contents: PropTypes.node,
  container: PropTypes.shape({
    current: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  }),
  orientation: PropTypes.oneOf([Orientations.VERTICAL, Orientations.HORIZONTAL]),
  id: PropTypes.string.isRequired,
};

Tooltip.defaultProps = {
  contents: null,
  container: null,
};

Tooltip.displayName = "Tooltip";

/* @component */
export default Tooltip;
