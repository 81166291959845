import { useLayoutEffect, useMemo, useState } from "react";

/**
 * Stolen from react-use https://github.com/streamich/react-use/blob/master/src/useMeasure.ts
 */
const useElementDimensions = () => {
  const [element, ref] = useState(null);
  const [rect, setRect] = useState({ width: 0 });

  const observer = useMemo(
    () =>
      // Doesn't work on opera mini and KaiOS 2.5; 0.19% global usage ¯\_(ツ)_/¯
      // eslint-disable-next-line compat/compat
      new ResizeObserver((entries) => {
        if (entries[0]) {
          const { x, y, width, height, top, left, bottom, right } =
            entries[0].contentRect;
          setRect({ x, y, width, height, top, left, bottom, right });
        }
      }),
    []
  );

  useLayoutEffect(() => {
    if (!element) return;
    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [element, observer]);

  return [ref, element, rect];
};

export default useElementDimensions;
