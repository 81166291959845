import PropTypes from "prop-types";
import React from "react";
import { Checkbox } from "../../FormField";

const CheckboxHeader = props => {
  return (
    <label htmlFor={`${props.header}header_checkbox`}>
      <Checkbox
        checked={props.isAllRowsSelected}
        name={`${props.header}header_checkbox`}
        id={`${props.header}header_checkbox`}
        onChange={props.toggleAllRowsSelected}
      />
    </label>
  );
};

CheckboxHeader.propTypes = {
  isAllRowsSelected: PropTypes.bool,
  toggleAllRowsSelected: PropTypes.func,
  value: PropTypes.string.isRequired,
  column: PropTypes.shape({
    row: PropTypes.shape({}),
    column: PropTypes.shape({}),
    onBookmark: PropTypes.func.isRequired,
  }).isRequired,
};

CheckboxHeader.displayName = "CheckboxHeader";

export default CheckboxHeader;
