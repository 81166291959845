import PropTypes from "prop-types";
import React from "react";
import { Button, ButtonStyles, Modal, ModalStyles, Fonts } from "yuka";

import {
  StyledModalButtonRow,
  StyledModalContent,
} from "../../Portfolios/StyledComponents";

/**
 * Disclaimer for where we source our articles.
 * @param onClose
 * @return {JSX.Element}
 * @constructor
 */
const ReportsAndInterviewsDisclaimerModal = ({ onClose }) => (
  <Modal modalStyle={ModalStyles.MINIMAL} onClose={onClose}>
    <StyledModalContent $width={624}>
      <Fonts.Headline1theme80>
        Disclaimer for reports and interviews
      </Fonts.Headline1theme80>
      <Fonts.Body1theme50>
        ZXData is providing a link to independent third-party research reports,
        such as the Sacra reports, solely as a courtesy to ZXData subscribers.
        ZXData is not recommending the use of, or reliance on, such reports and
        has not prepared or contributed to any such independent third-party
        research reports, nor is Zanbato affiliated with the entities producing
        such reports. Zanbato compensates the third party research providers for
        access to these reports. ZXData makes no warranties or representations
        of any kind, and takes no responsibility for the accuracy, completeness,
        timeliness, authenticity, or continued availability and/or updating of
        the independent third-party research reports. Any recommendation,
        opinion or advice regarding securities or markets contained in such
        independent third-party research reports does not necessarily reflect
        the views of ZXData. To the fullest extent provided by law, in no event
        shall Zanbato or its affiliates be liable for damages of any kind
        arising out of or in connection with the independent third-party
        research reports.
      </Fonts.Body1theme50>
    </StyledModalContent>
    <StyledModalButtonRow>
      <Button onClick={onClose} buttonStyle={ButtonStyles.RAISED}>
        Close
      </Button>
    </StyledModalButtonRow>
  </Modal>
);

ReportsAndInterviewsDisclaimerModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ReportsAndInterviewsDisclaimerModal;
