/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``percent.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const PercentIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M5.25 4.5C4.83579 4.5 4.5 4.83579 4.5 5.25C4.5 5.66421 4.83579 6 5.25 6C5.66421 6 6 5.66421 6 5.25C6 4.83579 5.66421 4.5 5.25 4.5ZM3 5.25C3 4.00736 4.00736 3 5.25 3C6.49264 3 7.5 4.00736 7.5 5.25C7.5 6.49264 6.49264 7.5 5.25 7.5C4.00736 7.5 3 6.49264 3 5.25ZM14.7803 3.21967C15.0732 3.51256 15.0732 3.98744 14.7803 4.28033L4.28033 14.7803C3.98744 15.0732 3.51256 15.0732 3.21967 14.7803C2.92678 14.4874 2.92678 14.0126 3.21967 13.7197L13.7197 3.21967C14.0126 2.92678 14.4874 2.92678 14.7803 3.21967ZM12.75 12C12.3358 12 12 12.3358 12 12.75C12 13.1642 12.3358 13.5 12.75 13.5C13.1642 13.5 13.5 13.1642 13.5 12.75C13.5 12.3358 13.1642 12 12.75 12ZM10.5 12.75C10.5 11.5074 11.5074 10.5 12.75 10.5C13.9926 10.5 15 11.5074 15 12.75C15 13.9926 13.9926 15 12.75 15C11.5074 15 10.5 13.9926 10.5 12.75Z"
        transform=""
      />
    </g>
  </svg>
);

PercentIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

PercentIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

PercentIcon.displayName = "PercentIcon";

export default PercentIcon;
