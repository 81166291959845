import PropTypes from "prop-types";
import React from "react";
import styled, { keyframes } from "styled-components";
import { ColorPalette } from "../../StylingConstants";

const loadSpinner = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledIcon = styled.div`
  animation: ${loadSpinner} 0.5s infinite linear;

  background: ${ColorPalette.fullWhite};
  background: linear-gradient(to right, ${ColorPalette.fullWhite}, transparent 38%);

  border-radius: 50%;
  height: 18px;
  left: calc(50% - 9px);
  position: relative;
  top: calc(50% - 9px);
  width: 18px;

  &:before {
    background: ${ColorPalette.fullWhite};
    border-radius: 100% 0 0 0;
    content: "";
    height: 50%;
    position: absolute;
    width: 50%;

    top: 0;
    left: 0;
  }

  &:after {
    background: rgba(22, 22, 22, 1);
    border-radius: 50%;
    bottom: 0;
    content: "";
    height: 70%;
    margin: auto;
    position: absolute;
    width: 70%;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

const StyledContainer = styled.div`
  background: rgba(22, 22, 22, 0.8);
  border-radius: 4px;
  height: 48px;
  margin: 0 auto;
  width: 48px;
`;

/**
 * Loading spinner to display when loading content.
 *
 * @param {object} props
 * @returns {JSX.Element}
 */
const LoadingSpinner = ({ showContainer = true }) => {
  const icon = <StyledIcon />;

  return showContainer ? <StyledContainer>{icon}</StyledContainer> : icon;
};

LoadingSpinner.propTypes = {
  showContainer: PropTypes.bool,
};

export default LoadingSpinner;
