import styled from "styled-components";
import { ColorPalette, Fonts } from "yuka";
import { shortMoneyFormat } from "../utils/displayFormatUtils";
import { useMemo } from "react";
import PropTypes from "prop-types";

const StyledFullWidth = styled.div`
  max-width: 120px;
`;

const LabelContainer = styled(Fonts.Caption2theme30).attrs({ as: "div" })`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const StyledIndicatorBar = styled.div`
  position: relative;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: ${ColorPalette.white15};
`;

const StyledIndicatorDot = styled.div`
  position: absolute;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: ${ColorPalette.white50};
  ${(props) => props.$left && `left: ${props.$left}%;`}
`;

/*
 * Assumes low, high, and current are properly sorted.
 */
const PriceRangeIndicator = ({ low, high, current }) => {
  const percentageOfCurrent = useMemo(() => {
    // Determine what percentage of the way between low and high current is
    if (low < high && current >= low && current <= high) {
      return ((current - low) / (high - low)) * 100;
    }
    // Special case for not a lot of change over time. Note that we don't check current here
    // since if low === high current must equal low and high.
    if (low === high) {
      return 50;
    }
  }, [low, high, current]);

  return (
    <StyledFullWidth>
      <LabelContainer>
        <span>{shortMoneyFormat(low)}</span>
        <span>{shortMoneyFormat(high)}</span>
      </LabelContainer>
      <StyledIndicatorBar>
        <StyledIndicatorDot $left={percentageOfCurrent} />
      </StyledIndicatorBar>
    </StyledFullWidth>
  );
};

PriceRangeIndicator.propTypes = {
  low: PropTypes.number.isRequired,
  high: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
};

export default PriceRangeIndicator;
