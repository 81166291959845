/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``log-out.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const LogOutIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M0.75 9C0.75 4.88479 3.96951 1.5 8 1.5C9.325 1.5 10.5679 1.86834 11.6362 2.51011C11.9913 2.72341 12.1062 3.18417 11.8929 3.53924C11.6796 3.89431 11.2189 4.00924 10.8638 3.79595C10.02 3.28907 9.04287 3 8 3C4.85079 3 2.25 5.65936 2.25 9C2.25 12.3406 4.85079 15 8 15C9.04287 15 10.02 14.7109 10.8638 14.2041C11.2189 13.9908 11.6796 14.1057 11.8929 14.4608C12.1062 14.8158 11.9913 15.2766 11.6362 15.4899C10.5679 16.1317 9.325 16.5 8 16.5C3.96951 16.5 0.75 13.1152 0.75 9ZM12.9697 5.46967C13.2626 5.17678 13.7374 5.17678 14.0303 5.46967L17.0303 8.46967C17.3232 8.76256 17.3232 9.23744 17.0303 9.53033L14.0303 12.5303C13.7374 12.8232 13.2626 12.8232 12.9697 12.5303C12.6768 12.2374 12.6768 11.7626 12.9697 11.4697L14.6893 9.75H6.75C6.33579 9.75 6 9.41421 6 9C6 8.58579 6.33579 8.25 6.75 8.25H14.6893L12.9697 6.53033C12.6768 6.23744 12.6768 5.76256 12.9697 5.46967Z"
        transform=""
      />
    </g>
  </svg>
);

LogOutIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

LogOutIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

LogOutIcon.displayName = "LogOutIcon";

export default LogOutIcon;
