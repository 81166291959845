import React, { useState } from "react";
import styled from "styled-components";
import { Fonts, HyperLink, LinkStyles } from "yuka";

import DocumentCard from "./DocumentCard";

import { API_ENDPOINTS } from "../../api/constants";
import useFetch from "../../api/useFetch";
import { SurfaceZeroCard } from "../../hdYuka";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../hdYuka/Carousel";
import MixpanelEvents from "../../utils/mixpanel/MixpanelEvents";
import { useCompany } from "../hooks";
import { ReportsAndInterviewsDisclaimerModal } from "../modals";
import {
  CarouselControlButtonRow,
  StyledCenteredEmptyState,
} from "../StyledComponents";

const RELATION_CONNECTED = "connected";
const RELATION_SUBJECT = "subject";
const TYPE_COMPANY = "company";

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledDisclaimer = styled(HyperLink)`
  align-self: flex-end;
`;

const AlsoMentionedIn = styled(Fonts.Body2theme80).attrs({ as: "div" })``;

const CompanyDocumentsCard = () => {
  const [disclaimerModalShowing, setDisclaimerModalShowing] = useState(false);
  const [company, companyIsLoading] = useCompany();

  const documentsQuery = useFetch(
    API_ENDPOINTS.SACRA_DOCUMENTS(company?.zb_id),
    {},
    {
      enabled: Boolean(!companyIsLoading && company?.zb_id),
    }
  );
  const reportsQuery = useFetch(
    API_ENDPOINTS.CONTRARY_REPORTS(company?.zb_id),
    {},
    {
      enabled: Boolean(!companyIsLoading && company?.zb_id),
    }
  );

  const contraryReportMapper = (doc) => ({
    id: doc.title, // ContraryReport.title has a unique constraint.
    link: doc.url,
    relation: RELATION_SUBJECT,
    date: doc.article_updated_on,
    title: doc.title,
    type: TYPE_COMPANY,
    source: "Contrary",
  });
  const sacraDocumentMapper = (doc) => ({
    ...doc,
    id: `SacraReport-${doc.id}`,
    source: "Sacra",
  });
  const contraryReports =
    reportsQuery?.cleanedData?.map(contraryReportMapper) || [];
  const sacraDocuments =
    documentsQuery?.cleanedData?.documents.map(sacraDocumentMapper) || [];

  const documentSorter = (a, b) => {
    // Sort the documents by date, newest (largest date) first.
    if (a.date < b.date) return 1;
    if (a.date > b.date) return -1;
    return 0;
  };
  const documents = contraryReports.concat(sacraDocuments).sort(documentSorter);
  const subjectDocuments = documents?.filter(
    (document) => document.relation === RELATION_SUBJECT
  );
  const connectedDocuments = documents?.filter(
    (document) => document.relation === RELATION_CONNECTED
  );

  if (companyIsLoading || documentsQuery.isLoading || reportsQuery.isLoading) {
    return (
      <SurfaceZeroCard title="Reports and Interviews">
        <StyledCenteredEmptyState $margin={48}>
          Loading reports and interviews...
        </StyledCenteredEmptyState>
      </SurfaceZeroCard>
    );
  }

  if (!company) {
    return (
      <SurfaceZeroCard title="Reports and Interviews">
        <StyledCenteredEmptyState $margin={48}>
          An error has occurred.
        </StyledCenteredEmptyState>
      </SurfaceZeroCard>
    );
  }

  return (
    <SurfaceZeroCard title="Reports and Interviews">
      <CardContent>
        {subjectDocuments.length ? (
          <Carousel
            slidesToScroll={3}
            onScrollNext={() =>
              MixpanelEvents.scrollCompanyReportsCarousel(
                "next",
                "subject",
                company.name
              )
            }
            onScrollPrev={() =>
              MixpanelEvents.scrollCompanyReportsCarousel(
                "prev",
                "subject",
                company.name
              )
            }
          >
            <CarouselContent>
              {subjectDocuments.map((document, index) => (
                <CarouselItem key={index}>
                  <DocumentCard
                    document={document}
                    titleTextLines={3}
                    onClick={() =>
                      MixpanelEvents.openCompanyReport(
                        document.source,
                        "subject",
                        company.name
                      )
                    }
                  />
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselControlButtonRow>
              <CarouselPrevious />
              <CarouselNext />
            </CarouselControlButtonRow>
          </Carousel>
        ) : (
          <StyledCenteredEmptyState $margin={48}>
            Reports and interviews not available
          </StyledCenteredEmptyState>
        )}
        <AlsoMentionedIn>
          {company.name} is also mentioned in...
        </AlsoMentionedIn>
        {connectedDocuments.length ? (
          <Carousel
            slidesToScroll={4}
            onScrollNext={() =>
              MixpanelEvents.scrollCompanyReportsCarousel(
                "next",
                "related",
                company.name
              )
            }
            onScrollPrev={() =>
              MixpanelEvents.scrollCompanyReportsCarousel(
                "prev",
                "related",
                company.name
              )
            }
          >
            <CarouselContent>
              {connectedDocuments.map((document, index) => (
                <CarouselItem key={index}>
                  <DocumentCard
                    document={document}
                    titleTextLines={2}
                    onClick={() =>
                      MixpanelEvents.openCompanyReport(
                        document.source,
                        "related",
                        company.name
                      )
                    }
                  />
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselControlButtonRow>
              <CarouselPrevious />
              <CarouselNext />
            </CarouselControlButtonRow>
          </Carousel>
        ) : (
          <StyledCenteredEmptyState $margin={48}>
            Articles not available
          </StyledCenteredEmptyState>
        )}
        <StyledDisclaimer
          linkStyle={LinkStyles.SUPPORT}
          onClick={() => {
            MixpanelEvents.openReportsAndInterviewsDisclaimer(company.name);
            setDisclaimerModalShowing(true);
          }}
        >
          <Fonts.Caption2theme50>View disclaimer</Fonts.Caption2theme50>
        </StyledDisclaimer>
        {disclaimerModalShowing && (
          <ReportsAndInterviewsDisclaimerModal
            onClose={() => setDisclaimerModalShowing(false)}
          />
        )}
      </CardContent>
    </SurfaceZeroCard>
  );
};

export default CompanyDocumentsCard;
