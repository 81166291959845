import { DEFAULT_NUMBER_PRECISION } from "./constants";
import { numberFormat } from "../../utils/displayFormatUtils";
import PropTypes from "prop-types";

const NumberCell = ({ value, ...props }) => {
  let precision;
  // Need to allow for 0 precision.
  if (!props.column.precision && props.column.precision !== 0) {
    precision = DEFAULT_NUMBER_PRECISION;
  } else {
    precision = props.column.precision;
  }
  const formatter = props.column.formatter || (value => numberFormat(value, precision, precision));
  // Calls formatter with value ONLY. Clients must specify other parameters using factory functions
  // similar to the usage of `numberFormat` in the line above.
  return formatter(value);
};

NumberCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  column: PropTypes.shape({
    precision: PropTypes.number,
    formatter: PropTypes.func,
    align: PropTypes.oneOf(["left", "right", "center"]),
  }).isRequired,
};

NumberCell.displayName = "NumberCell";

export default NumberCell;
