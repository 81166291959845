import { DateTime } from "luxon";
import { useMemo } from "react";

import { API_ENDPOINTS } from "../../api/constants";
import useFetch from "../../api/useFetch";

/*
 * Retrieves OrderFlowReport objects for the company that do not occur in the future to the
 * provided valuation date. Possibly returns undefined if there are no such objects.
 */
const useLatestOrderFlowReport = (companyId, endDate, queryEnabled = false) => {
  const startDate = useMemo(() => {
    // We'll store a query start date to limit the number of results we get back.
    if (!endDate) {
      return null;
    }
    return DateTime.fromISO(endDate).minus({ weeks: 4 }).toISODate();
  }, [endDate]);

  // We offset the end_date by 1 day so that on Fridays we never get different values depending on
  // when in the day the user runs the valuation (we generate OrderFlowReports on Fridays).
  const orderFlowQuery = useFetch(
    API_ENDPOINTS.COMPANY_PRICE_VOLUME_ORDER_FLOW(),
    {
      company: companyId,
      end_date: DateTime.fromISO(endDate).minus({ days: 1 }).toISODate(),
      ...(startDate ? { start_date: startDate } : {}),
    },
    {
      enabled: queryEnabled,
    }
  );

  return useMemo(
    () => [orderFlowQuery.cleanedData?.data?.[0], orderFlowQuery.isLoading],
    [orderFlowQuery.cleanedData?.data, orderFlowQuery.isLoading]
  );
};

export default useLatestOrderFlowReport;
