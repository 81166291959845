import _ from "lodash";

import convertNumToReadable from "./convertNumToReadable";
import addCommasToNum from "./addCommasToNum";
import roundDecimal from "./roundDecimal";

/**
 * Receives number and returns formatted string with dollar sign, commas and rounding
 * i.e (4000000) -> $4,000,000
 * i.e (4000000.5, 2, 0) -> $4,000,000.50
 * i.e (4000000.1234, 0, 2) -> $4,000,000.12
 *
 * @param {number} number
 * @param {number} minPrecision
 * @param {number} maxPrecision
 * @returns {string}
 */
// eslint-disable-next-line no-magic-numbers
const expandedMoneyFormat = (number, minPrecision = 0, maxPrecision = 6) => {
  const moneyFormat =
    number < 0
      ? `-$${addCommasToNum(roundDecimal(-number, maxPrecision), minPrecision)}`
      : `$${addCommasToNum(roundDecimal(number, maxPrecision), minPrecision)}`;
  return _.isNull(number) ? "--" : moneyFormat;
};

/**
 * Receives number and returns formatted shorthand string, with suffix to represent size
 * i.e. (4000000) -> $4M
 * i.e. (4123450) -> $4.12M
 * i.e. (4123450, 3) -> $4.123M
 * Similar to ``convert_num_to_readable`` in numberformat.py
 *
 * @param {number} number
 * @param {number} precision
 * @param {boolean} preferExpanded - Do not abbreviate nor slice off cents for values in
 *  the range [1000, 9999.99].
 * @returns {string}
 */
const shortMoneyFormat = (number, precision = 2, preferExpanded = false) =>
  _.isNull(number) || _.isUndefined(number)
    ? "--"
    : `$${convertNumToReadable(number, precision, preferExpanded)}`;

/**
 * Receives number and number of decimal places and returns a string
 * representing the percentage value
 * i.e (50) -> 50.00%
 * i.e (50, 3) -> 50.000%
 * Similar to ``get_percent`` in numberformat.py
 *
 * @param {number} number
 * @param {number} decimalPlaces
 * @returns {string}
 */
const percentFormat = (number, decimalPlaces = 2) =>
  _.isNull(number) ? "--" : `${roundDecimal(number, decimalPlaces)}%`;

export { expandedMoneyFormat, shortMoneyFormat, percentFormat };
