/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``hash.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const HashIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M7.24829 1.5102C7.65687 1.5783 7.93288 1.96472 7.86478 2.37329L7.38533 5.24999H11.8646L12.3852 2.1267C12.4533 1.71812 12.8397 1.4421 13.2483 1.5102C13.6569 1.5783 13.9329 1.96472 13.8648 2.37329L13.3853 5.24999H15.375C15.7892 5.24999 16.125 5.58578 16.125 5.99999C16.125 6.41421 15.7892 6.74999 15.375 6.74999H13.1353L12.3853 11.25H14.625C15.0392 11.25 15.375 11.5858 15.375 12C15.375 12.4142 15.0392 12.75 14.625 12.75H12.1353L11.6148 15.8733C11.5467 16.2819 11.1603 16.5579 10.7517 16.4898C10.3431 16.4217 10.0671 16.0353 10.1352 15.6267L10.6146 12.75H6.13533L5.61478 15.8733C5.54669 16.2819 5.16027 16.5579 4.75169 16.4898C4.34311 16.4217 4.0671 16.0353 4.13519 15.6267L4.61464 12.75H1.875C1.46079 12.75 1.125 12.4142 1.125 12C1.125 11.5858 1.46079 11.25 1.875 11.25H4.86464L5.61464 6.74999H2.625C2.21079 6.74999 1.875 6.41421 1.875 5.99999C1.875 5.58578 2.21079 5.24999 2.625 5.24999H5.86464L6.38519 2.1267C6.45329 1.71812 6.83971 1.4421 7.24829 1.5102ZM7.13533 6.74999L6.38533 11.25H10.8646L11.6146 6.74999H7.13533Z"
        transform=""
      />
    </g>
  </svg>
);

HashIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

HashIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

HashIcon.displayName = "HashIcon";

export default HashIcon;
