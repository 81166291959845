import _ from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useForm, useFormState } from "react-final-form";
import { FinalFormField, DollarIcon, Fonts } from "yuka";

import {
  COMPONENT_TYPE_MAP,
  VALUATION_COMPONENT_PRIVATE_MARKET_INDEX_COMPARISON,
  ZX_EQUAL_WEIGHTED_INDEX_SYMBOL,
} from "./constants";
import { usePrivateMarketIndices } from "./hooks";
import { Styled16Gap, StyledDetailPanelFields } from "./StyledComponents";

import { API_ENDPOINTS } from "../api/constants";
import useFetch from "../api/useFetch";
import { HALF_SECOND } from "../utils/constants";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";
import useDebouncedState from "../utils/useDebouncedState";

const debouncedModifyPreviousValuationValue = _.debounce(
  (companyName, previousValue, newValue) => {
    MixpanelEvents.modifyPreviousValuation(
      companyName,
      previousValue,
      newValue
    );
  },
  HALF_SECOND,
  { leading: false, trailing: true }
);

const IndexPerformanceField = ({
  companyName,
  companyId,
  editable,
  visible,
}) => {
  const [loadedDefault, setLoadedDefault] = useState(false);
  const { change } = useForm();
  const { values } = useFormState();
  const {
    selected_sector_index: selectedSectorIndexFromForm,
    previous_pps: previousValuation,
    previous_date: previousValuationDate,
  } = values;

  const [oldPreviousValuationValue, setOldPreviousValuationValue] =
    useDebouncedState(previousValuation);
  const [oldPreviousValuationDate, setOldPreviousValuationDate] =
    useDebouncedState(previousValuationDate);

  const sectorQuery = useFetch(
    API_ENDPOINTS.COMPANY_SECTORS(),
    {
      company: companyId,
    },
    { enabled: Boolean(companyId) }
  );
  const companySector = sectorQuery.cleanedData?.data?.[0];

  const privateMarketIndices = usePrivateMarketIndices();

  const defaultPrivateMarketIndex = useMemo(() => {
    if (privateMarketIndices && companySector) {
      // Try to find matching sector index.
      const sectorIndex = privateMarketIndices.find(
        (index) => index.name === companySector?.name
      );

      if (sectorIndex) {
        return sectorIndex;
      }

      // Fall back to the ZXEQ if there's no matching sector index.
      return privateMarketIndices.find(
        (index) => index.symbol === ZX_EQUAL_WEIGHTED_INDEX_SYMBOL
      );
    }
    return null;
  }, [privateMarketIndices, companySector]);

  useEffect(() => {
    if (
      defaultPrivateMarketIndex &&
      !loadedDefault &&
      !selectedSectorIndexFromForm
    ) {
      setLoadedDefault(true);
      change(
        "selected_sector_index",
        defaultPrivateMarketIndex.private_market_index?.[1]
      );
    }
  }, [
    change,
    loadedDefault,
    selectedSectorIndexFromForm,
    defaultPrivateMarketIndex,
  ]);

  // This component must be rendered despite not matching the "currently selected component"
  // because we need to perform the logic above, where we prepopulate the `selected_sector_index`
  // form value. That form value is used downstream by the `IndexComparisonComponentRow` to
  // render the percentage gain/loss of the index, and we need to be able to render that gain
  // prior to the user actually selecting this row. Therefore, we allow all the above logic to
  // execute and render nothing in the DOM.
  if (!visible) {
    return null;
  }

  return (
    <StyledDetailPanelFields>
      <Fonts.Body1theme50>
        Index performance tracks the performance of a group of companies that
        represent a portion of the market or a sector.
      </Fonts.Body1theme50>
      <Fonts.Body1theme50>
        Please fill in
        <Fonts.Body1theme80> ALL </Fonts.Body1theme80>
        the following fields in order to include index performance in the
        calculation.
      </Fonts.Body1theme50>
      <Styled16Gap>
        <FinalFormField
          disabled={!editable}
          onChange={(indexId) => {
            if (!indexId) {
              MixpanelEvents.clearValuationComparison(
                companyName,
                COMPONENT_TYPE_MAP[
                  VALUATION_COMPONENT_PRIVATE_MARKET_INDEX_COMPARISON
                ]
              );
            } else {
              const selectedIndex = privateMarketIndices?.find(
                (index) => index?.private_market_index[1] === indexId
              );
              MixpanelEvents.selectValuationComparison(
                companyName,
                `${selectedIndex?.name} (${selectedIndex?.symbol})`,
                COMPONENT_TYPE_MAP[
                  VALUATION_COMPONENT_PRIVATE_MARKET_INDEX_COMPARISON
                ]
              );
            }
          }}
          type="select"
          label="Private market index"
          name="selected_sector_index"
          options={privateMarketIndices?.map((index) => ({
            label: `${index.name} (${index.symbol})`,
            value: index.private_market_index[1],
          }))}
          placeholder="Select an index"
          noSort
        />
        <FinalFormField
          disabled={!editable}
          onChange={(value) => {
            debouncedModifyPreviousValuationValue(
              companyName,
              Number(oldPreviousValuationValue),
              value
            );
            setOldPreviousValuationValue(value);
          }}
          id="previous_pps"
          name="previous_pps"
          type="number"
          label="Previous valuation"
          leadingIcon={DollarIcon}
        />
        <FinalFormField
          onChange={(value) => {
            MixpanelEvents.modifyPreviousValuationDate(
              companyName,
              oldPreviousValuationDate,
              value
            );
            setOldPreviousValuationDate(value);
          }}
          disabled={!editable}
          id="previous_date"
          name="previous_date"
          type="date"
          placeholder=""
          label="Previous valuation date"
          parse={(value) => DateTime.fromJSDate(value).toISODate()}
        />
      </Styled16Gap>
    </StyledDetailPanelFields>
  );
};

IndexPerformanceField.propTypes = {
  companyName: PropTypes.string,
  companyId: PropTypes.string,
  visible: PropTypes.bool,
  editable: PropTypes.bool,
};

export default IndexPerformanceField;
