import PropTypes from "prop-types";
import React from "react";
import { YukaColorPalette } from "yuka";

/*
 * Custom dot prop for <Line /> to highlight selected period
 */
const SelectedPeriodDot = (props) => {
  if (props.payload?.date === props.selectedPeriod) {
    const { stroke, value } = props;

    if (!value) {
      return null;
    }

    return (
      <circle
        {...props}
        fill={stroke}
        r={6}
        stroke={YukaColorPalette.surface0}
        strokeWidth={2}
      />
    );
  }
  return null;
};

SelectedPeriodDot.propTypes = {
  payload: PropTypes.shape({
    date: PropTypes.string,
  }),
  r: PropTypes.number.isRequired,
  stroke: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  selectedPeriod: PropTypes.string,
};

SelectedPeriodDot.defaultProps = {
  payload: null,
  selectedPeriod: null,
};

export default SelectedPeriodDot;
