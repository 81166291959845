import { useState, useCallback, useEffect } from "react";

const useMouseOver = ref => {
  const [mouseOver, setMouseOver] = useState(false);

  const onMouseOver = useCallback(() => {
    setMouseOver(() => true);
  }, [setMouseOver]);

  const onMouseOut = useCallback(() => {
    setMouseOver(() => false);
  }, [setMouseOver]);

  // Check :hover to avoid race condition with mouse event callbacks when moving cursor fast
  // https://github.com/facebook/react/issues/4492#issuecomment-569410248
  if (
    mouseOver &&
    ref.current &&
    !ref.current.matches(`${ref.current.nodeName.toLowerCase()}:hover`)
  ) {
    setMouseOver(false);
  }

  if (
    !mouseOver &&
    ref.current &&
    ref.current.matches(`${ref.current.nodeName.toLowerCase()}:hover`)
  ) {
    setMouseOver(true);
  }

  useEffect(() => {
    const myRef = ref.current;
    if (myRef) {
      myRef.addEventListener("mouseenter", onMouseOver);
      myRef.addEventListener("mouseleave", onMouseOut);
    }
    return () => {
      // Using myRef here because the ref is mutable. This is cleaning up the correct handler
      if (myRef) {
        myRef.removeEventListener("mouseenter", onMouseOver);
        myRef.removeEventListener("mouseleave", onMouseOut);
      }
    };
  });

  return mouseOver;
};

export default useMouseOver;
