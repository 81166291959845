import { useMemo } from "react";

/**
 * Helper to compute several useful metadata fields for a table for given input parameters.
 * Essentially used to group table metadata calculations in one easy-to-follow place.
 *
 * Optimally used with a set of user-defined columns, prior to react-table processing.
 */
const useTableMetadata = (columns) => {
  // The specified table width is the sum of the column widths, to know precisely how wide
  // the table is. The specifiedTableWidth is used when computing the proportional
  // column widths, since their percentages need to be percentage of the table's total width.
  const specifiedTableWidth = useMemo(() => columns.reduce(
    (prevSum, currElem) => prevSum + (currElem.width || 0), 0),
    [columns]
  );

  // The total width of columns that need to be rendered using fixed widths. Used to determine the
  // remaining size of the table to compute normalized percentage column widths.
  const fixedWidthColumnWidth = columns
    .filter(column => column.useFixedWidth)
    .reduce((prevSum, currColumn) => prevSum + (currColumn.width || 0), 0);

  const numberOfStickyColumns = useMemo(
    () => columns.reduce((prevSum, currColumn) => prevSum + (currColumn.sticky ? 1 : 0), 0),
    [columns]
  );

  return {
    specifiedTableWidth,
    fixedWidthColumnWidth,
    numberOfStickyColumns,
  };
};

export default useTableMetadata;
