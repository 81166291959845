import PropTypes from "prop-types";
import styled from "styled-components";

import { PrimaryColorPalette } from "../StylingConstants";

import ListItem from "./ListItem";
import Definition from "./Definition";

const List = styled.ul.attrs({ role: "list", className: "zx-list" })`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  ${props =>
    props.divider
      ? `
    > ${ListItem} {
      border-bottom: 1px solid ${PrimaryColorPalette.white15};
    }
    > ${Definition} {
      border-bottom: 1px solid ${PrimaryColorPalette.white15};
    }
  `
      : ""}
`;

List.propTypes = {
  /** Should items be displayed with a divider between them */
  divider: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

List.defaultProps = {
  divider: false,
  className: "",
};

List.displayName = "List";

/* @component */
export default List;
