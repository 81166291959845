/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``toggle-left.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ToggleLeftIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M0.75 9C0.75 6.10051 3.10051 3.75 6 3.75H12C14.8995 3.75 17.25 6.10051 17.25 9C17.25 11.8995 14.8995 14.25 12 14.25H6C3.10051 14.25 0.75 11.8995 0.75 9ZM6 5.25C3.92893 5.25 2.25 6.92893 2.25 9C2.25 11.0711 3.92893 12.75 6 12.75H12C14.0711 12.75 15.75 11.0711 15.75 9C15.75 6.92893 14.0711 5.25 12 5.25H6ZM6 7.875C5.37868 7.875 4.875 8.37868 4.875 9C4.875 9.62132 5.37868 10.125 6 10.125C6.62132 10.125 7.125 9.62132 7.125 9C7.125 8.37868 6.62132 7.875 6 7.875ZM3.375 9C3.375 7.55025 4.55025 6.375 6 6.375C7.44975 6.375 8.625 7.55025 8.625 9C8.625 10.4497 7.44975 11.625 6 11.625C4.55025 11.625 3.375 10.4497 3.375 9Z"
        transform=""
      />
    </g>
  </svg>
);

ToggleLeftIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ToggleLeftIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

ToggleLeftIcon.displayName = "ToggleLeftIcon";

export default ToggleLeftIcon;
