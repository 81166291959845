/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";
import { ColorPalette } from "../StylingConstants";

/*
 * Yuka v2.0
 * This component corresponds to ``alert_dot.svg``. A notification dot to be used in icon buttons.
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const AlertDot = ({ color }) => (
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none">
    <circle cx="4" cy="4" r="4" fill={color} />
  </svg>
);

AlertDot.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
};

AlertDot.defaultProps = {
  color: ColorPalette.blue500,
};

AlertDot.displayName = "AlertDot";

export default AlertDot;
