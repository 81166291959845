import { useEffect } from "react";

/**
 * Add this to the top of any component to set the page title.
 * The title will reset to "ZXData" afterwards as a safe default,
 * but assuming some other component is mounted subsequently, we should
 * have smooth transitions between titles
 *
 * See https://dev.to/luispa/how-to-add-a-dynamic-title-on-your-react-app-1l7k
 * for the inspiration
 *
 * @param {string} title - the title for the page
 */
function useDocumentTitle(title) {
  useEffect(() => {
    document.title = title;

    return () => {
      document.title = "ZXData";
    };
  }, [title]);
}

export default useDocumentTitle;
