import roundDecimal from "./roundDecimal";

/**
 * Returns the percent representation of a fraction.
 * i.e. 3/4 -> 75.00
 * Returns '0' if `denominator` is falsy.
 *
 * @param {number} numerator
 * @param {number} denominator
 * @param {number} decimalPlaces
 * @returns {string}
 */
export default (numerator, denominator, decimalPlaces = 2) => {
  if (!numerator || !denominator) {
    return 0;
  }
  return roundDecimal((numerator / denominator) * 100, decimalPlaces);
};
