/* eslint-disable react/prop-types */
import { Link } from "react-router-dom";

const LinkTypes = {
  // add missing type="button" to button links
  // to prevent form submission in case they are used inside a form
  // See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#notes
  BUTTON: () => ({ type: "button" }),
  DOWNLOAD: url => ({ href: url, download: true, rel: "noreferrer noopener", target: "_blank" }),
  LOCAL_LINK: url => ({ to: url }),
  EXTERNAL_LINK: url => ({ href: url, rel: "noreferrer noopener", target: "_blank" }),
  LINK: url => ({ href: url }),
};

/**
 * A text link element with helpers to ensure attributes and navigation behaves as desired.
 *
 * Provides a backwards compatible interface with the legacy element with warnings. This can be
 * expected to be removed in a future release
 *
 * to (DEPRECATED) - This prop is a string or object that react-router can parse, and if specified, results in
 *   an `<a>` tag being rendered and managed by react router. Should be used for in-app routing
 *
 * href (DEPRECATED) - This prop is a string that is a valid href that is rendered as the corresponding
 *   attribute in an `<a>` tag managed as normal by the browser. Should be used for out of app
 *   routing.
 *
 * url - This prop is a string that is a valid href or a string/object that react-router can parse.
 *   This results in either an `<a>` tag or a `<Link>` being rendered.
 *
 * Either url, to, or href must be specified for `target`, `rel`, or `download` attributes to be applied
 *
 * LinkTypes guideline:
 * - `BUTTON` - when no navigation is performed but need a text link visually
 * - `DOWNLOAD` - when the link is directly to a document to be downloaded
 * - `LOCAL_LINK` - the link navigates within a SPA
 * - `EXTERNAL_LINK` - the link navigates away from the environment, opens in a new tab
 * - `LINK` - a traditional `<a>` tag without any special behaviors
 *
 * @param {string} url - The URL we are linking to if applicable
 * @param {function} linkType - One of LinkTypes
 * @param {object} legacyProps - The V1 hyperlink props used to map to V2 behavior
 *
 * @returns {Array}
 *   - computedLinkProps {object} - set of props to pass into the rendered link component
 *   - renderAs {string|function} - the component to render the link as
 *     either an "a", "button", or Link
 */
const getLinkProps = (url, _linkType, legacyProps) => {
  const linkType = _linkType ?? (url ? LinkTypes.LINK : LinkTypes.BUTTON);
  let computedLinkProps = linkType?.(url);
  let renderAs = "a";

  // Backwards compatibility with legacy HyperLink props
  if (
    legacyProps &&
    (legacyProps.newTab ||
      legacyProps.accent ||
      legacyProps.download ||
      legacyProps.href ||
      legacyProps.to ||
      legacyProps.active ||
      legacyProps.rel ||
      legacyProps.target)
  ) {
    // eslint-disable-next-line no-console
    console.warn(
      "You are passing V1 props to a V2 link-like element. Some actions may not act is expected"
    );
    if (!_linkType) {
      computedLinkProps = {
        to: legacyProps.to,
        href: legacyProps.href,
        download: legacyProps.download,
        rel: legacyProps.newTab ? "noreferrer noopener" : "",
        target: legacyProps.newTab ? "_blank" : "",
      };
      // Compute which base element to use to render
      if (legacyProps.to) {
        renderAs = Link;
      } else if (!legacyProps.href) {
        renderAs = "button";
      }
    }
  } else {
    // Default V2 behavior
    // Default props
    if (!computedLinkProps) {
      if (url) {
        computedLinkProps = LinkTypes.LINK(url);
      } else {
        computedLinkProps = LinkTypes.BUTTON();
      }
    }

    // Compute which base element to use to render
    if (linkType == LinkTypes.LOCAL_LINK) {
      renderAs = Link;
    } else if (linkType == LinkTypes.BUTTON) {
      renderAs = "button";
    }
  }

  return [computedLinkProps, renderAs];
};

export default getLinkProps;
export { LinkTypes };
