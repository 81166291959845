import { useCallback, useRef } from "react";

/**
 * Helper hook to automatically focus the submit button in a form after submitting. This hook
 * should be used when we want to render input validation errors on form submit failing, and need
 * to unfocus the inputs in the form to do so.
 * Users of this hook should call the returned wrapped on submit function, and should attach
 * the ref to a non-input element (e.g. a button) in the form.
 *
 * @param onSubmit {Function} - The onSubmit function to wrap with the ref focus().
 * @returns {[Function, React.MutableRefObject<null>]}
 */
const useUnfocusOnSubmit = (onSubmit) => {
  const submitButtonRef = useRef(null);

  const wrappedOnSubmit = useCallback(
    (...args) => {
      submitButtonRef.current?.focus();
      return onSubmit(...args);
    },
    [onSubmit]
  );

  return [wrappedOnSubmit, submitButtonRef];
};

export default useUnfocusOnSubmit;
