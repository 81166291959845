/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``sliders.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const SlidersIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M3.75 1.5C4.16421 1.5 4.5 1.83579 4.5 2.25V5.25C4.5 5.66421 4.16421 6 3.75 6C3.33579 6 3 5.66421 3 5.25V2.25C3 1.83579 3.33579 1.5 3.75 1.5ZM9 1.5C9.41421 1.5 9.75 1.83579 9.75 2.25V4.62803C10.6239 4.93691 11.25 5.77034 11.25 6.75C11.25 7.99264 10.2426 9 9 9C7.75736 9 6.75 7.99264 6.75 6.75C6.75 5.77034 7.37611 4.93691 8.25 4.62803V2.25C8.25 1.83579 8.58579 1.5 9 1.5ZM14.25 1.5C14.6642 1.5 15 1.83579 15 2.25V6.75C15 7.16421 14.6642 7.5 14.25 7.5C13.8358 7.5 13.5 7.16421 13.5 6.75V2.25C13.5 1.83579 13.8358 1.5 14.25 1.5ZM9 6C8.58579 6 8.25 6.33579 8.25 6.75C8.25 7.16421 8.58579 7.5 9 7.5C9.41421 7.5 9.75 7.16421 9.75 6.75C9.75 6.33579 9.41421 6 9 6ZM3.75 9C3.33579 9 3 9.33579 3 9.75C3 10.1642 3.33579 10.5 3.75 10.5C4.16421 10.5 4.5 10.1642 4.5 9.75C4.5 9.33579 4.16421 9 3.75 9ZM1.5 9.75C1.5 8.50736 2.50736 7.5 3.75 7.5C4.99264 7.5 6 8.50736 6 9.75C6 10.7297 5.37389 11.5631 4.5 11.872V15.75C4.5 16.1642 4.16421 16.5 3.75 16.5C3.33579 16.5 3 16.1642 3 15.75L3 11.872C2.12611 11.5631 1.5 10.7297 1.5 9.75ZM14.25 10.5C13.8358 10.5 13.5 10.8358 13.5 11.25C13.5 11.6642 13.8358 12 14.25 12C14.6642 12 15 11.6642 15 11.25C15 10.8358 14.6642 10.5 14.25 10.5ZM12 11.25C12 10.0074 13.0074 9 14.25 9C15.4926 9 16.5 10.0074 16.5 11.25C16.5 12.2297 15.8739 13.0631 15 13.372V15.75C15 16.1642 14.6642 16.5 14.25 16.5C13.8358 16.5 13.5 16.1642 13.5 15.75V13.372C12.6261 13.0631 12 12.2297 12 11.25ZM9 10.5C9.41421 10.5 9.75 10.8358 9.75 11.25V15.75C9.75 16.1642 9.41421 16.5 9 16.5C8.58579 16.5 8.25 16.1642 8.25 15.75V11.25C8.25 10.8358 8.58579 10.5 9 10.5Z"
        transform=""
      />
    </g>
  </svg>
);

SlidersIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

SlidersIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

SlidersIcon.displayName = "SlidersIcon";

export default SlidersIcon;
