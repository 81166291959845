import _ from "lodash";
import PropTypes from "prop-types";
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import {
  FontColors,
  List,
  ListItem,
  overline,
  SearchIcon,
  Thumbnail,
  body1,
  ListStyles,
  YukaColorPalette,
  CornerDownLeftIcon,
  SlashIcon,
} from "yuka";

import { LIST_ITEM_HEIGHT } from "./constants";
import HotkeyPrompt from "./HotkeyPrompt";
import {
  getRecentlyVisitedCompanies,
  removeCompanyFromRecentlyVisitedCompaniesById,
} from "./utils";

import { API_ENDPOINTS } from "../api/constants";
import useFetches from "../api/useFetches";
import useInfiniteFetch from "../api/useInfiniteFetch";
import { useDropdown, Input } from "../hdYuka";
import RoundedSquareIcon from "../hdYuka/RoundedSquareIcon";
import { ROUTING_PATH } from "../routes/constants";
import {
  ACTIONS,
  COMPANY_PROFILE_MIXPANEL_TARGET,
  useDispatch,
} from "../routes/StateProvider";
import highlightText from "../utils/highlightText";
import LoadingSpinner from "../utils/LoadingSpinner";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";
import { StyledLoadingSpinnerContainer } from "../utils/StyledComponents";
import useDebouncedState from "../utils/useDebouncedState";
import useHotkeys, {
  KEY_ARROW_DOWN,
  KEY_ARROW_UP,
  KEY_ENTER,
  KEY_ESCAPE,
  KEY_NUMPAD_ENTER,
  KEY_SLASH,
} from "../utils/useHotkeys";
import useScrollable from "../utils/useScrollable";

const StyledResultHeader = styled.div`
  ${FontColors.theme30}
  ${overline}
  align-items: center;
  display: flex;
  margin: 0 16px 0;
`;

const StyledNoResults = styled.div`
  ${body1}
  ${FontColors.theme50}
  text-align: center;
  margin-top: 27px;
  margin-bottom: 27px;
`;

const StyledSearchBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: auto;
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  min-width: 300px;
  max-width: 800px;
  border-radius: 12px;
`;

const StyledSearchBar = styled(Input)`
  overflow-y: auto;
  width: 100%;
  border: none;
  background: ${YukaColorPalette.surface2};
`;

const StyledDropdownContainer = styled.div`
  // show 6.5 items, indicating scrollability.
  max-height: ${LIST_ITEM_HEIGHT * 7 - LIST_ITEM_HEIGHT / 2};
  margin-bottom: -16px; // Remove padding from bottom of scrollable region
  overflow-y: auto;
  width: 800px;
`;

const HoverableListItem = styled(ListItem)`
  ${(props) =>
    props.$hovered
      ? css`
          background: rgba(255, 255, 255, 0.05);
        `
      : ``}

  > :first-child {
    flex-shrink: 0;
  }
`;

const StyledTheme80 = styled.span`
  ${FontColors.theme80}
`;

const StyledTheme30 = styled.span`
  ${FontColors.theme30}
`;

const FETCH_NEXT_COMPANIES_PAGE_THRESHOLD = 104; // Height of two search results
const MIN_SEARCH_QUERY_LENGTH = 1;

const CompanySearch = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchBarRef = useRef(null);

  // Used for keyboard navigation.
  const scrollableListRef = useRef(null);
  const hoveredElementRef = useRef(null);
  const [scrollState, setScrollState] = useState({
    hoveredIndex: 0,
    arrowDirection: KEY_ARROW_DOWN,
  });
  const [focussed, setFocussed] = useState(false);

  const [searchQuery, setSearchQuery] = useDebouncedState("");
  // This query does a quick fetch to each of the companies stored in the local storage object, to
  // verify that they're still accessible in our API. If any of the requests return a 404, the
  // offending company will be removed from the local storage. This will prevent us from displaying
  // a link to an extremely recently IPO'd company in the recent list.
  useFetches(
    getRecentlyVisitedCompanies().map((company) => ({
      url: API_ENDPOINTS.COMPANY(company[0]),
      options: {
        onError: (error) => {
          if (error.status === 404) {
            removeCompanyFromRecentlyVisitedCompaniesById(company[0]);
          }
        },
      },
    }))
  );

  // search companies from ZX
  const companyQuery = useInfiniteFetch(
    API_ENDPOINTS.COMPANY(),
    { search: searchQuery },
    {
      enabled: searchQuery.length >= MIN_SEARCH_QUERY_LENGTH,
      cacheTime: 0, // pagination with a cached search was having issues
    }
  );

  useEffect(() => {
    if (searchQuery) {
      MixpanelEvents.globalSearchQueryCompany(searchQuery);
    }
  }, [searchQuery]);

  const onScrollDownFetchNext = useCallback(
    (distanceFromBottom) => {
      if (
        distanceFromBottom < FETCH_NEXT_COMPANIES_PAGE_THRESHOLD &&
        companyQuery.hasNextPage &&
        !companyQuery.isFetchingNextPage
      ) {
        companyQuery.fetchNextPage().then((response) => response.data);
      }
    },
    [companyQuery]
  );

  useScrollable({
    scrollRef: scrollableListRef,
    onScrollUp: _.noop,
    onScrollDown: onScrollDownFetchNext,
  });

  const showSearchResults = useMemo(
    () => searchQuery.length >= MIN_SEARCH_QUERY_LENGTH,
    [searchQuery]
  );

  const companies = useMemo(() => {
    // Returns either the results from the API fetch, or the local storage companies that were
    // recently visited.
    if (showSearchResults) {
      return companyQuery.isSuccess ? companyQuery.cleanedData.data : [];
    }
    return getRecentlyVisitedCompanies().map((company) => ({
      zb_id: company[0],
      name: company[1],
      main_picture: company[2],
      legal_name: company[3] || company[1], // Possibly legal_name field is empty in local storage.
    }));
  }, [companyQuery, showSearchResults]);

  const onSelectSearchResult = useCallback(
    (company, hotkeyUsed = false) => {
      setSearchQuery("");
      if (searchBarRef.current) {
        searchBarRef.current.value = "";
      }
      setFocussed(!focussed);
      MixpanelEvents.globalSearchSelectCompany(
        hotkeyUsed,
        searchQuery,
        company?.name
      );
      dispatch({
        type: ACTIONS.updateMixpanelEventSource,
        source: "global search",
        target: COMPANY_PROFILE_MIXPANEL_TARGET,
      });
      navigate(ROUTING_PATH.COMPANY(company.zb_id));
    },
    [dispatch, setSearchQuery, focussed, searchBarRef, navigate, searchQuery]
  );

  useEffect(() => {
    // A bit of sorcery here. Essentially we want to scroll the currently hovered element into view,
    // but maintain a buffer of 1 element on the top and bottom of the scroll so you get a preview
    // of what's next to come.
    if (scrollableListRef.current) {
      const { hoveredIndex, arrowDirection } = scrollState;
      // We determine where in the list the currently hovered element is so that we can scroll
      // to a position that gives the buffer on either side, if necessary.
      const hoveredElementPositionOnScreen =
        hoveredIndex * LIST_ITEM_HEIGHT - scrollableListRef.current.scrollTop;
      // Note that arrowDirection is relevant here because we don't want to scroll down when the
      // hoveredElement is at the top of the list, but that could happen without checking to see
      // if the user is attempting to scroll down.
      if (
        arrowDirection === KEY_ARROW_DOWN &&
        hoveredElementPositionOnScreen + 2 * LIST_ITEM_HEIGHT >
          scrollableListRef.current.clientHeight
      ) {
        // The hovered element is at the bottom of the list, and we are scrolling down.
        scrollableListRef.current.scrollTop =
          (hoveredIndex + 2) * LIST_ITEM_HEIGHT -
          scrollableListRef.current.clientHeight;
      } else if (
        arrowDirection === KEY_ARROW_UP &&
        hoveredElementPositionOnScreen - LIST_ITEM_HEIGHT < 0
      ) {
        // The hovered element is at the top of the list, and we are scrolling up.
        scrollableListRef.current.scrollTop =
          (hoveredIndex - 1) * LIST_ITEM_HEIGHT;
      } else if (
        arrowDirection === KEY_ARROW_DOWN &&
        hoveredElementPositionOnScreen + 2 * LIST_ITEM_HEIGHT < 0
      ) {
        // The hovered element is offscreen at the top of the list, and we are scrolling down.
        scrollableListRef.current.scrollTop =
          (hoveredIndex - 1) * LIST_ITEM_HEIGHT;
      } else if (
        arrowDirection === KEY_ARROW_UP &&
        hoveredElementPositionOnScreen + LIST_ITEM_HEIGHT >
          scrollableListRef.current.clientHeight
      ) {
        // The hovered element is offscreen at the bottom of the list, and we are scrolling up
        scrollableListRef.current.scrollTop =
          hoveredIndex * LIST_ITEM_HEIGHT -
          (scrollableListRef.current.clientHeight - 2 * LIST_ITEM_HEIGHT);
      }
    }
  }, [scrollState, scrollableListRef, hoveredElementRef]);

  const SearchDropdown = () => (
    <>
      <StyledResultHeader>
        {showSearchResults ? "Company Results" : "Recently Visited Companies"}
      </StyledResultHeader>
      <StyledDropdownContainer ref={scrollableListRef}>
        {companies.length === 0 && !companyQuery.isLoading ? (
          <StyledNoResults>
            {showSearchResults
              ? "No private companies found. Try adjusting your search."
              : "No recently visited companies. Start your search above."}
          </StyledNoResults>
        ) : (
          <List>
            {companies.map((company, index) => (
              <HoverableListItem
                listStyle={ListStyles.TWO_LINE}
                ref={
                  index === scrollState.hoveredIndex ? hoveredElementRef : null
                }
                $hovered={index === scrollState.hoveredIndex}
                avatar={
                  company.main_picture ? (
                    <Thumbnail src={company.main_picture} size="24px" />
                  ) : (
                    <RoundedSquareIcon />
                  )
                }
                key={company.zb_id}
                onClick={() => onSelectSearchResult(company)}
                trailingContent={
                  index === scrollState.hoveredIndex ? (
                    <HotkeyPrompt hotkey={CornerDownLeftIcon} action="select" />
                  ) : null
                }
                text={highlightText(
                  company.name,
                  searchQuery,
                  null,
                  showSearchResults ? StyledTheme30 : null
                )}
                subtext={highlightText(
                  company.legal_name,
                  searchQuery,
                  showSearchResults ? StyledTheme80 : null,
                  showSearchResults ? StyledTheme30 : null
                )}
              />
            ))}
          </List>
        )}
        {(!companyQuery.isSuccess || companyQuery.isFetching) &&
          showSearchResults && (
            <StyledLoadingSpinnerContainer>
              <LoadingSpinner absolute={false} />
            </StyledLoadingSpinnerContainer>
          )}
      </StyledDropdownContainer>
    </>
  );

  SearchDropdown.propTypes = {
    toggleIsOpen: PropTypes.func.isRequired,
  };

  const [dropdownElement, dropdownRef, toggleDropdown] = useDropdown(
    SearchDropdown,
    { id: "GlobalSearchDropdown" }
  );

  const handleSlashKeyPress = useCallback(
    (event) => {
      if (!focussed) {
        MixpanelEvents.toggleGlobalSearch(true, true);
        searchBarRef.current?.focus();
        toggleDropdown();
        event.preventDefault();
      }
    },
    [searchBarRef, focussed, toggleDropdown]
  );
  const handleEscapeKeyPress = useCallback(() => {
    if (focussed) {
      MixpanelEvents.toggleGlobalSearch(true, false);
      setFocussed(false);
      toggleDropdown();
      searchBarRef.current?.blur();
    }
  }, [focussed, searchBarRef, toggleDropdown]);
  const handleEnterKeyPress = useCallback(() => {
    if (focussed) {
      setFocussed(false);
      toggleDropdown();
      searchBarRef.current?.blur();
      const company = companies[scrollState.hoveredIndex];
      onSelectSearchResult(company, true);
    }
  }, [onSelectSearchResult, focussed, companies, scrollState, toggleDropdown]);

  const handleArrowDownKeyPress = useCallback(
    (event) => {
      if (focussed) {
        MixpanelEvents.navigateGlobalSearchDropdown(KEY_ARROW_DOWN);
        setScrollState({
          hoveredIndex: Math.max(
            Math.min(scrollState.hoveredIndex + 1, companies.length - 1),
            0
          ),
          arrowDirection: KEY_ARROW_DOWN,
        });
        event.preventDefault();
      }
    },
    [scrollState, focussed, companies]
  );
  const handleArrowKeyUpPress = useCallback(
    (event) => {
      if (focussed) {
        MixpanelEvents.navigateGlobalSearchDropdown(KEY_ARROW_UP);
        setScrollState({
          hoveredIndex: Math.max(
            Math.min(scrollState.hoveredIndex - 1, companies.length - 1),
            0
          ),
          arrowDirection: KEY_ARROW_UP,
        });
        event.preventDefault();
      }
    },
    [scrollState, focussed, companies]
  );

  const hotkeyConfigs = useMemo(
    () => ({
      [KEY_SLASH]: { callback: handleSlashKeyPress, shiftDisabled: true },
      [KEY_ENTER]: { callback: handleEnterKeyPress },
      [KEY_NUMPAD_ENTER]: { callback: handleEnterKeyPress },
      [KEY_ESCAPE]: { callback: handleEscapeKeyPress },
      [KEY_ARROW_DOWN]: { callback: handleArrowDownKeyPress },
      [KEY_ARROW_UP]: { callback: handleArrowKeyUpPress },
    }),
    [
      handleSlashKeyPress,
      handleEnterKeyPress,
      handleEscapeKeyPress,
      handleArrowDownKeyPress,
      handleArrowKeyUpPress,
    ]
  );

  useHotkeys(hotkeyConfigs);

  const handleFocus = useCallback(() => {
    setFocussed(true);
  }, []);
  const handleBlur = useCallback(
    (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.relatedTarget)
      ) {
        setFocussed(false);
        setScrollState({
          ...scrollState,
          hoveredIndex: 0,
        });
      }
    },
    [dropdownRef, scrollState]
  );

  useEffect(() => {
    const current = dropdownRef.current;

    if (current) {
      current.addEventListener("focusin", handleFocus);
      current.addEventListener("focusout", handleBlur);
    }
    return () => {
      if (current) {
        current.removeEventListener("focusin", handleFocus);
        current.removeEventListener("focusout", handleBlur);
      }
    };
  }, [dropdownRef, handleFocus, handleBlur]);

  const trailingContent = focussed
    ? null
    : () => <HotkeyPrompt hotkey={SlashIcon} action="search" />;

  const handleClick = useCallback(
    (event) => {
      if (!dropdownElement) {
        MixpanelEvents.toggleGlobalSearch(false, true);
      } else {
        searchBarRef.current?.blur();
        event.stopPropagation();
      }
      toggleDropdown();
    },
    [dropdownElement, searchBarRef, toggleDropdown]
  );

  return (
    <StyledSearchBarContainer
      id="global-search-bar"
      ref={dropdownRef}
      onClick={handleClick}
    >
      <StyledSearchBar
        ref={searchBarRef}
        id="company-search"
        leadingIcon={SearchIcon}
        name="CompanySearch"
        onChange={(event) => setSearchQuery(event.target.value)}
        trailingIcon={trailingContent}
        placeholder="Search company"
        type="text"
      />
      {dropdownElement}
    </StyledSearchBarContainer>
  );
};

export default CompanySearch;
