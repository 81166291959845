import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const SIZE_TO_RADIUS = {
  "24px": "6px",
  "32px": "8px",
  "48px": "12px",
  "64px": "16px",
  "96px": "24px",
};

const StyledThumbnail = styled.img`
  ${props => `
    height: ${props.size};
    width: ${props.size};
    border-radius: ${SIZE_TO_RADIUS[props.size]};
  `}
`;

/**
 * Yuka v2.0
 * This returns a rounded square with a logo inside.
 */
const Thumbnail = props => (
  <StyledThumbnail className={props.className} alt={props.alt} size={props.size} src={props.src} />
);

Thumbnail.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  /** Provided as px since that is what we read off of zeplin mockups */
  size: PropTypes.oneOf(["24px", "32px", "48px", "64px", "96px"]).isRequired,
};

Thumbnail.defaultProps = {
  alt: "",
};

Thumbnail.displayName = "Thumbnail";

export default Thumbnail;
