/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``alarm-clock.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const AlarmClockIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M4.28033 1.71967C4.57322 2.01256 4.57322 2.48744 4.28033 2.78033L2.03033 5.03033C1.73744 5.32322 1.26256 5.32322 0.96967 5.03033C0.676777 4.73744 0.676777 4.26256 0.96967 3.96967L3.21967 1.71967C3.51256 1.42678 3.98744 1.42678 4.28033 1.71967ZM13.7197 1.71967C14.0126 1.42678 14.4874 1.42678 14.7803 1.71967L17.0303 3.96967C17.3232 4.26256 17.3232 4.73744 17.0303 5.03033C16.7374 5.32322 16.2626 5.32322 15.9697 5.03033L13.7197 2.78033C13.4268 2.48744 13.4268 2.01256 13.7197 1.71967ZM4.22703 4.97703C5.4929 3.71116 7.20979 3 9 3C10.7902 3 12.5071 3.71116 13.773 4.97703C15.0388 6.2429 15.75 7.95979 15.75 9.75C15.75 11.2884 15.2248 12.7727 14.2738 13.9631L15.5303 15.2197C15.8232 15.5126 15.8232 15.9874 15.5303 16.2803C15.2374 16.5732 14.7626 16.5732 14.4697 16.2803L13.2131 15.0238C12.0227 15.9748 10.5384 16.5 9 16.5C7.46159 16.5 5.97733 15.9748 4.7869 15.0238L3.53033 16.2803C3.23744 16.5732 2.76256 16.5732 2.46967 16.2803C2.17678 15.9874 2.17678 15.5126 2.46967 15.2197L3.72624 13.9631C2.77517 12.7727 2.25 11.2884 2.25 9.75C2.25 7.95979 2.96116 6.2429 4.22703 4.97703ZM9 4.5C7.60761 4.5 6.27226 5.05312 5.28769 6.03769C4.30312 7.02226 3.75 8.35761 3.75 9.75C3.75 11.1424 4.30312 12.4777 5.28769 13.4623C6.27226 14.4469 7.60761 15 9 15C10.3924 15 11.7277 14.4469 12.7123 13.4623C13.6969 12.4777 14.25 11.1424 14.25 9.75C14.25 8.35761 13.6969 7.02226 12.7123 6.03769C11.7277 5.05312 10.3924 4.5 9 4.5ZM9 6C9.41421 6 9.75 6.33579 9.75 6.75V9.43934L11.0303 10.7197C11.3232 11.0126 11.3232 11.4874 11.0303 11.7803C10.7374 12.0732 10.2626 12.0732 9.96967 11.7803L8.46967 10.2803C8.32902 10.1397 8.25 9.94891 8.25 9.75V6.75C8.25 6.33579 8.58579 6 9 6Z"
        transform=""
      />
    </g>
  </svg>
);

AlarmClockIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

AlarmClockIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

AlarmClockIcon.displayName = "AlarmClockIcon";

export default AlarmClockIcon;
