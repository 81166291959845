/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``slash-circle.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const SlashCircleIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M3.72608 4.78674C2.80235 5.94149 2.25 7.40624 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75C10.5938 15.75 12.0585 15.1976 13.2133 14.2739L3.72608 4.78674ZM4.78674 3.72608L14.2739 13.2133C15.1976 12.0585 15.75 10.5938 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C7.40624 2.25 5.94149 2.80235 4.78674 3.72608ZM0.75 9C0.75 4.44365 4.44365 0.75 9 0.75C13.5563 0.75 17.25 4.44365 17.25 9C17.25 13.5563 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5563 0.75 9Z"
        transform=""
      />
    </g>
  </svg>
);

SlashCircleIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

SlashCircleIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

SlashCircleIcon.displayName = "SlashCircleIcon";

export default SlashCircleIcon;
