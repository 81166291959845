/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``speedometer.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const SpeedometerIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M2.2912 8.25H3.375C3.78921 8.25 4.125 8.58579 4.125 9C4.125 9.41421 3.78921 9.75 3.375 9.75H2.2912C2.43511 11.0519 2.94929 12.2422 3.72609 13.2133L4.49253 12.4468C4.78543 12.154 5.2603 12.154 5.55319 12.4468C5.84609 12.7397 5.84609 13.2146 5.55319 13.5075L4.78676 14.2739C5.75783 15.0507 6.94812 15.5649 8.25 15.7088V14.625C8.25 14.2108 8.58579 13.875 9 13.875C9.41421 13.875 9.75 14.2108 9.75 14.625V15.7088C11.0519 15.5649 12.2422 15.0507 13.2133 14.2739L12.4482 13.5088C12.1553 13.216 12.1553 12.7411 12.4482 12.4482C12.7411 12.1553 13.216 12.1553 13.5088 12.4482L14.2739 13.2133C15.0507 12.2422 15.5649 11.0519 15.7088 9.75H14.625C14.2108 9.75 13.875 9.41421 13.875 9C13.875 8.58579 14.2108 8.25 14.625 8.25H15.7088C15.5663 6.96083 15.0607 5.78108 14.2966 4.81526L11.0324 8.0335C11.1719 8.32632 11.25 8.65404 11.25 9C11.25 10.2426 10.2426 11.25 9 11.25C7.75736 11.25 6.75 10.2426 6.75 9C6.75 7.75736 7.75736 6.75 9 6.75C9.34876 6.75 9.67898 6.82935 9.9736 6.97098L13.2417 3.74892C12.2654 2.95936 11.0646 2.43652 9.75 2.2912V3.375C9.75 3.78921 9.41421 4.125 9 4.125C8.58579 4.125 8.25 3.78921 8.25 3.375V2.2912C6.93352 2.43673 5.73115 2.96085 4.75417 3.75229L5.52389 4.52201C5.81678 4.8149 5.81678 5.28977 5.52389 5.58267C5.231 5.87556 4.75612 5.87556 4.46323 5.58267L3.70005 4.81948C2.9378 5.78452 2.4335 6.96271 2.2912 8.25ZM14.9136 14.7526C16.3595 13.2665 17.25 11.2372 17.25 9C17.25 4.44365 13.5563 0.75 9 0.75C4.44365 0.75 0.75 4.44365 0.75 9C0.75 11.2373 1.64059 13.2666 3.08659 14.7528C3.10881 14.7829 3.13358 14.8118 3.16089 14.8392C3.18821 14.8665 3.21711 14.8912 3.24729 14.9135C4.73342 16.3594 6.76271 17.25 9 17.25C11.2372 17.25 13.2665 16.3595 14.7526 14.9136C14.7828 14.8913 14.8118 14.8665 14.8392 14.8392C14.8665 14.8118 14.8913 14.7828 14.9136 14.7526ZM9 8.25C8.58579 8.25 8.25 8.58579 8.25 9C8.25 9.41421 8.58579 9.75 9 9.75C9.41421 9.75 9.75 9.41421 9.75 9C9.75 8.58579 9.41421 8.25 9 8.25Z"
        transform=""
      />
    </g>
  </svg>
);

SpeedometerIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

SpeedometerIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

SpeedometerIcon.displayName = "SpeedometerIcon";

export default SpeedometerIcon;
