import { API_ENDPOINTS } from "../../api/constants";
import useFetch from "../../api/useFetch";

/**
 * Hook that queries /api/valuations/ using a given companyId
 *
 * @param {string} companyId
 * @return {[object|null, boolean, object]} - The valuation object and a boolean that indicates
 *    whether or not the query is still loading or has failed. Also returns the query object
 *    in case we need to access the query itself.
 */
const useCompanyCustomValuations = (companyId) => {
  const valuationsQuery = useFetch(
    API_ENDPOINTS.CUSTOM_VALUATIONS(),
    {
      company: companyId,
    },
    { enabled: Boolean(companyId) }
  );

  if (valuationsQuery.isLoading) {
    return [null, true];
  }

  if (!valuationsQuery.isSuccess) {
    return [null, false];
  }

  return [valuationsQuery.cleanedData.results, false];
};

export default useCompanyCustomValuations;
