import PropTypes from "prop-types";
import React, { useRef } from "react";
import _ from "lodash";
import styled, { css } from "styled-components";
import { overline } from "../Typography";

import { ArrowDownIcon, ArrowUpIcon } from "../Icons";
import { ColorPalette, Orientations } from "../StylingConstants";
import { getThemeColorStyle, getThemeHeaderStyle } from "./StyledComponents";
import { useTooltip } from "../Tooltip";
import { useYukaTheme } from "../ThemeContext";

const HEADER_COMPONENT_GAP = 4;
const DEFAULT_HEADER_LEADING_ICON_SIZE = 16;

const StyledHeader = styled.div`
  ${overline};
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  gap: ${HEADER_COMPONENT_GAP}px;
  height: ${props => getThemeHeaderStyle("height", props)}px;
  align-items: center;

  // Alignment
  ${props => {
    if (props.$column.align === "right") {
      return css`
        justify-content: flex-end;
      `;
    } else if (props.$column.align === "center") {
      return css`
        justify-content: center;
      `;
    }
    return css`
      justify-content: flex-start;
    `;
  }};

  // Padding
  ${props => {
    if (props.$column.padding || props.$column.padding === 0) {
      // If padding is explicitly set on the column, use it for all sides.
      return css`
        padding: ${props.$column.padding}px;
      `;
    }
    return css`
      padding: ${props => getThemeHeaderStyle("paddingTop", props)}px
        ${props => getThemeHeaderStyle("paddingRight", props)}px
        ${props => getThemeHeaderStyle("paddingBottom", props)}px
        ${props => getThemeHeaderStyle("paddingLeft", props)}px;
    `;
  }}

  // Borders
  border-top: ${props => getThemeHeaderStyle("borderTop", props)};
  border-bottom: ${props => getThemeHeaderStyle("borderBottom", props)};
  border-left: ${props => getThemeHeaderStyle("borderLeft", props)};
  ${props => {
    if (!props.$isFinalColumn && (props.$showBorders || props.$isFinalStickyColumn)) {
      const accessor = props.$isFinalStickyColumn ? "thickenedBorderRight" : "borderRight";
      return css`
        border-right: ${props => getThemeHeaderStyle(accessor, props)};
      `;
    }
  }}

  // Background color + cursor based on sort-ability.
  ${props =>
    props.$column.sortable
      ? css`
          &:hover {
            background-color: ${props => getThemeColorStyle("headerHover", props)};
            cursor: pointer;
          }
          &:active {
            background-color: ${props => getThemeColorStyle("headerActive", props)};
          }
        `
      : css`
          cursor: default;
        `}

  svg:not(:root) {
    flex-shrink: 0;
  }
`;

const StyledHeaderText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const DefaultHeaderRenderer = ({
  column,
  manualSortBy = false,
  onSort = _.noop,
  sortState = [],
  numberOfStickyColumns,
  ...props
}) => {
  const theme = useYukaTheme();
  const containerRef = useRef(null);
  const headerRef = useRef(null);
  let [sortingByThisColumnAsc, sortingByThisColumnDesc] = [false, false];
  let nextSortingCriteria = { id: column.id };

  if (sortState.length) {
    const currentSort = sortState[0];
    // props.sortState looks like [{ id: "string", desc: false, [hideArrow]: true }]
    // If currentSort.hideArrow, both of these will be false, despite the data being sorted
    // in the table. This way we can have a default sort state that's not indicated visually.
    sortingByThisColumnAsc =
      !currentSort.hideArrow && currentSort.id === column.id && !currentSort.desc;
    sortingByThisColumnDesc =
      !currentSort.hideArrow && currentSort.id === column.id && currentSort.desc === true;
    // Sort order by clicking the headers goes in the cycle "unsorted, ascending, descending, unsorted".
    if (sortingByThisColumnAsc) {
      nextSortingCriteria.desc = true;
    } else if (sortingByThisColumnDesc) {
      nextSortingCriteria = null;
    } else {
      nextSortingCriteria.desc = false;
    }
  } else if (!manualSortBy) {
    sortingByThisColumnAsc = column.isSorted === true && !column.isSortedDesc;
    sortingByThisColumnDesc = column.isSorted === true && column.isSortedDesc === true;
  }

  const onClick = column.sortable ? () => onSort(nextSortingCriteria) : _.noop;

  const headerContentFromHeaderRenderer = column.headerRenderer
    ? column.headerRenderer({ header: column.header, ...column, ...props })
    : column.header || "";

  // Attach a tooltip to headers with truncated content.
  // Allow column-specified padding.
  let paddingLeft =
    column.padding || column.padding === 0 ? column.padding : theme.tableStyles.header.paddingLeft;
  let paddingRight =
    column.padding || column.padding === 0 ? column.padding : theme.tableStyles.header.paddingRight;

  if (sortingByThisColumnAsc || sortingByThisColumnDesc) {
    paddingRight += 16 + HEADER_COMPONENT_GAP;
  }
  if (column.headerLeadingIcon) {
    paddingLeft +=
      (column.headerLeadingIconSize || DEFAULT_HEADER_LEADING_ICON_SIZE) + HEADER_COMPONENT_GAP;
  }

  let tooltipContent = null;
  if (typeof headerContentFromHeaderRenderer === "string") {
    // We can only apply tooltips to string-type cell content.
    if (
      headerRef.current?.scrollWidth &&
      containerRef.current?.offsetWidth &&
      headerRef.current?.scrollWidth >
        containerRef.current?.offsetWidth - paddingLeft - paddingRight
    ) {
      tooltipContent = headerContentFromHeaderRenderer;
    }
  }
  const tooltip = useTooltip(containerRef, tooltipContent, {
    orientation:
      column.headerTooltip && typeof column.headerTooltip === "function"
        ? Orientations.HORIZONTAL
        : Orientations.VERTICAL,
  });

  return (
    <>
      <StyledHeader
        $column={column}
        $showBorders={props.showBorders}
        $isFinalColumn={props.columns.length === column.index + 1}
        $isFinalStickyColumn={column.sticky && numberOfStickyColumns === column.index + 1}
        onClick={onClick}
        ref={containerRef}
      >
        {column.headerLeadingIcon && (
          <column.headerLeadingIcon
            size={column.headerLeadingIconSize}
            color={column.headerLeadingIconColor}
          />
        )}
        {typeof headerContentFromHeaderRenderer === "string" ? (
          <StyledHeaderText ref={headerRef}>{headerContentFromHeaderRenderer}</StyledHeaderText>
        ) : (
          headerContentFromHeaderRenderer
        )}
        {sortingByThisColumnAsc && <ArrowUpIcon color={ColorPalette.white80} />}
        {sortingByThisColumnDesc && <ArrowDownIcon color={ColorPalette.white80} />}
      </StyledHeader>
      {tooltipContent ? tooltip : null}
    </>
  );
};

DefaultHeaderRenderer.propTypes = {
  column: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    index: PropTypes.number,
    sticky: PropTypes.bool,
    isSorted: PropTypes.bool,
    isSortedDesc: PropTypes.bool,
    sortable: PropTypes.bool,
    padding: PropTypes.number,
    renderHeaderOverflow: PropTypes.bool,
    headerRenderer: PropTypes.func,
    header: PropTypes.string,
    headerTooltip: PropTypes.func,
    headerLeadingIcon: PropTypes.func,
    headerLeadingIconSize: PropTypes.number,
    headerLeadingIconColor: PropTypes.string,
  }).isRequired,
  showBorders: PropTypes.bool,
  numberOfStickyColumns: PropTypes.number,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  manualSortBy: PropTypes.bool,
  onSort: PropTypes.func,
  sortState: PropTypes.arrayOf(PropTypes.shape({})),
};

DefaultHeaderRenderer.displayName = "DefaultHeaderRenderer";

export default DefaultHeaderRenderer;
