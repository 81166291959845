/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``command.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const CommandIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M1.5 4.5C1.5 2.84315 2.84315 1.5 4.5 1.5C6.15685 1.5 7.5 2.84315 7.5 4.5V6H10.5V4.5C10.5 2.84315 11.8431 1.5 13.5 1.5C15.1569 1.5 16.5 2.84315 16.5 4.5C16.5 6.15685 15.1569 7.5 13.5 7.5H12V10.5H13.5C15.1569 10.5 16.5 11.8431 16.5 13.5C16.5 15.1569 15.1569 16.5 13.5 16.5C11.8431 16.5 10.5 15.1569 10.5 13.5V12H7.5V13.5C7.5 15.1569 6.15685 16.5 4.5 16.5C2.84315 16.5 1.5 15.1569 1.5 13.5C1.5 11.8431 2.84315 10.5 4.5 10.5H6V7.5H4.5C2.84315 7.5 1.5 6.15685 1.5 4.5ZM6 6V4.5C6 3.67157 5.32843 3 4.5 3C3.67157 3 3 3.67157 3 4.5C3 5.32843 3.67157 6 4.5 6H6ZM7.5 7.5V10.5H10.5V7.5H7.5ZM6 12H4.5C3.67157 12 3 12.6716 3 13.5C3 14.3284 3.67157 15 4.5 15C5.32843 15 6 14.3284 6 13.5V12ZM12 12V13.5C12 14.3284 12.6716 15 13.5 15C14.3284 15 15 14.3284 15 13.5C15 12.6716 14.3284 12 13.5 12H12ZM12 6H13.5C14.3284 6 15 5.32843 15 4.5C15 3.67157 14.3284 3 13.5 3C12.6716 3 12 3.67157 12 4.5V6Z"
        transform=""
      />
    </g>
  </svg>
);

CommandIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

CommandIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

CommandIcon.displayName = "CommandIcon";

export default CommandIcon;
