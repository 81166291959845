import React from "react";

import useDidMount from "../../utils/useDidMount";
import useFormValue from "../useFormValue";

const ChangeListener = ({ onChange, name }) => {
  const value = useFormValue(name);
  const didMount = useDidMount();

  React.useEffect(() => {
    if (didMount) {
      onChange(value);
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default ChangeListener;
