import { ColorPalette } from "yuka";

// Current ranges are:
// 0-5%
// 5-50%
// 50%+
const DARK_CUTOFF = 0;
const MID_CUTOFF = 0.05;
const LIGHT_CUTOFF = 0.5;

// All rgb values are based off ColorPalette.buy/ColorPalette.sell
// Have to use rgba to set background opacity that doesn't affect text opacity
const LIGHT_GREEN_COLOR = ColorPalette.buy;
const MID_GREEN_COLOR = "rgba(0, 246, 139, 0.8)";
const DARK_GREEN_COLOR = "rgba(0, 246, 139, 0.6)";
const GREEN_TEXT_COLOR = ColorPalette.darkBlack;
const GREEN_TEXT_LIGHT_COLOR = ColorPalette.black15;

const LIGHT_RED_COLOR = ColorPalette.sell;
const MID_RED_COLOR = "rgba(255, 98, 98, 0.8)";
const DARK_RED_COLOR = "rgba(255, 98, 98, 0.6)";
const RED_TEXT_COLOR = ColorPalette.darkWhite;
const RED_TEXT_LIGHT_COLOR = ColorPalette.white15;

const GRAY_COLOR = ColorPalette.minWhite;
const GRAY_TEXT_COLOR = ColorPalette.darkWhite;

const ORDERING_FILTER_DEFAULT_VALUE = "-zx_index_value_percent_change";
const ORDERING_FILTER_NAME = "ordering";
const SECTOR_FILTER_NAME = "sector";

export {
  DARK_CUTOFF,
  DARK_GREEN_COLOR,
  DARK_RED_COLOR,
  GRAY_COLOR,
  GRAY_TEXT_COLOR,
  GREEN_TEXT_COLOR,
  GREEN_TEXT_LIGHT_COLOR,
  LIGHT_CUTOFF,
  LIGHT_GREEN_COLOR,
  LIGHT_RED_COLOR,
  MID_CUTOFF,
  MID_GREEN_COLOR,
  MID_RED_COLOR,
  ORDERING_FILTER_DEFAULT_VALUE,
  ORDERING_FILTER_NAME,
  RED_TEXT_COLOR,
  RED_TEXT_LIGHT_COLOR,
  SECTOR_FILTER_NAME,
};
