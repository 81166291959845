const PARAMS_DICT = {
  privacy: "Privacy Policy",
  tou: "Terms of Use",
  nda: "Non-Disclosure Agreement",
};

const AGREEMENT_NOT_SIGNED_ERROR = {
  10: "privacy",
  20: "tou",
  40: "nda",
};

export { PARAMS_DICT, AGREEMENT_NOT_SIGNED_ERROR };
