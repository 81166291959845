/*
 * Returns the name of a portfolio company, via the related Company instance, or the related
 * RequestedCompany instance, for components that don't care about the difference.
 */
import {
  isRequestedPortfolioCompany,
  isVerifiedPortfolioCompany,
} from "../utils";

const getPortfolioCompanyName = (portfolioCompany) => {
  if (isVerifiedPortfolioCompany(portfolioCompany)) {
    return portfolioCompany.company.name;
  }
  if (isRequestedPortfolioCompany(portfolioCompany)) {
    return portfolioCompany.requested_company.name;
  }
  return null; // Should not hit this theoretically.
};

export default getPortfolioCompanyName;
