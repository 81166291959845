import React from "react";

import Tooltip from "./Tooltip";

import useMouseOver from "../utils/useMouseOver";
import useId from "../utils/useId";

const useTooltip = (ref, contents, config) => {
  const mouseOver = useMouseOver(ref);
  const id = useId();
  const tooltipRef = React.useRef(null);
  const mouseOverTooltip = useMouseOver(tooltipRef);
  const [isEscapePressed, setIsEscapePressed] = React.useState(false);

  const isVisible = React.useMemo(
    () => (mouseOver || mouseOverTooltip) && !isEscapePressed,
    [mouseOver, mouseOverTooltip, isEscapePressed]
  );

  React.useEffect(() => {
    // Reset after mouse out
    if (!mouseOver && !mouseOverTooltip && isEscapePressed) {
      setIsEscapePressed(false);
    }
    const keyPressHandler = e => {
      if (e.code === "Escape" && (mouseOver || mouseOverTooltip)) {
        setIsEscapePressed(true);
      }
    };
    window.addEventListener("keyup", keyPressHandler);
    return () => {
      window.removeEventListener("keyup", keyPressHandler);
    };
  }, [mouseOver, mouseOverTooltip, isEscapePressed]);

  React.useEffect(() => {
    if (isVisible) {
      ref.current?.setAttribute?.("aria-describedby", id);
    } else {
      ref.current?.removeAttribute?.("aria-describedby");
    }
  }, [isVisible, id, ref]);

  return isVisible && contents ? (
    <Tooltip ref={tooltipRef} container={ref} id={id} contents={contents} {...config} />
  ) : null;
};

export default useTooltip;
