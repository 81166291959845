/**
 * The default theme configuration.
 *
 * By leveraging a default theme, we can enable highly configurable styles to be defined.
 */
import DefaultStyleConfiguration from "../Table/utils/DefaultStyleConfiguration";
import { DEFAULT_COLOR_THEME } from "../StylingConstants";
import { DEFAULT_BUTTON_THEME, DEFAULT_ICON_BUTTON_THEME } from "../Button/themes";

export default {
  tableStyles: DefaultStyleConfiguration,
  mode: "dark",
  colors: DEFAULT_COLOR_THEME,
  button: DEFAULT_BUTTON_THEME,
  iconButton: DEFAULT_ICON_BUTTON_THEME,
};
