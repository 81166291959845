import _ from "lodash";
import { Avatar } from "../../Avatar";
import PropTypes from "prop-types";
import React from "react";

const AvatarCell = ({ value, ...props }) => {
  const { onClick: columnOnClick, ...columnProps } = props.column;
  const onClick = columnOnClick ? () => columnOnClick(props) : _.noop;
  return <Avatar avatar={value} {...columnProps} onClick={onClick} />;
};

AvatarCell.propTypes = {
  value: PropTypes.string,
  column: PropTypes.shape({
    onClick: PropTypes.func,
  }).isRequired,
};

AvatarCell.defaultProps = {
  value: "",
};

AvatarCell.displayName = "AvatarCell";

export default AvatarCell;
