import styled from "styled-components";

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 16px;
`;

export { StyledButtonContainer };
