import styled from "styled-components";

import Badge from "./Badge";

/**
 * Yuka v2.0
 *
 * @param {object} props
 * @returns {Element}
 */
const BadgeList = styled.div.attrs({ className: "badge-list" })`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  margin: -4px 0 0 -4px;

  > ${Badge} {
    margin: 4px;
    width: auto; // revert width change below
  }
`;

BadgeList.displayName = "BadgeList";

/* @component */
export default BadgeList;
