import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";

import Surface, { __internalsSurfaceContext } from "../Layout/internal/Surface";
import { FontColors, body2, caption2 } from "../Typography";
import { IconButton, IconButtonStyles, Button } from "../Button";
import { PrimaryColorPalette } from "../StylingConstants";
import { XCloseIcon } from "../Icons";
import { ONE_SECOND } from "../utils/constants";
import { YukaThemeProvider } from "../ThemeContext";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledToast = styled(Surface)`
  align-items: center;
  animation: ${fadeIn} 1s;
  border-radius: 12px;
  display: flex;
  padding: 16px 16px 16px 0;
  width: fit-content;

  ${props =>
    props.$removed
      ? `
    transition: visibility 0s 1s, opacity 1s linear;
    opacity: 0;
    visibility: hidden;
  `
      : ""}
`;

const StyledIndicator = styled.div`
  width: 4px;
  align-self: stretch;
  display: flex;
  background: ${props => props.$color};
`;

const StyledContent = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
  margin: 0 48px 0 16px;
  max-width: 480px;
`;

const StyledTitle = styled.span`
  ${FontColors.theme100}
  ${body2}
  line-height: 16px;
`;

const StyledMessage = styled.p`
  ${FontColors.theme80}
  ${caption2}
`;

const ToastTypes = {
  info: { indicator: null },
  success: { indicator: PrimaryColorPalette.buy },
  warning: { indicator: PrimaryColorPalette.alert },
  error: { indicator: PrimaryColorPalette.sell },
};

/**
 * The display content for an alert on the site
 */
const ToastMessage = React.forwardRef((props, ref) => {
  const [removed, setRemoved] = React.useState(false);
  const indicatorColor = ToastTypes[props.type]?.indicator;

  const { onClose: propsOnClose } = props;

  const onClose = React.useCallback(() => {
    setRemoved(true);
    setTimeout(() => {
      propsOnClose();
    }, ONE_SECOND);
  }, [propsOnClose]);

  React.useEffect(() => {
    let timeout;
    if (props.displayTime) {
      timeout = setTimeout(() => {
        onClose();
      }, props.displayTime);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [onClose, props.displayTime]);

  return (
    <YukaThemeProvider theme={{ componentVersion: 2 }}>
      <__internalsSurfaceContext.Provider value={3}>
        <StyledToast ref={ref} elevated $removed={removed} role="alert" aria-live="polite">
          {indicatorColor && <StyledIndicator $color={indicatorColor} />}
          <StyledContent>
            <StyledTitle>{props.title}</StyledTitle>
            {props.detail && <StyledMessage>{props.detail}</StyledMessage>}
          </StyledContent>
          {props.actions?.map(({ text, onClick }) => (
            <Button key={text} onClick={onClick}>
              {text}
            </Button>
          ))}
          <IconButton buttonStyle={IconButtonStyles.OUTLINE} onClick={onClose} icon={XCloseIcon} />
        </StyledToast>
      </__internalsSurfaceContext.Provider>
    </YukaThemeProvider>
  );
});

ToastMessage.displayName = "ToastMessage";

ToastMessage.propTypes = {
  /** The main contents to show within the toast */
  title: PropTypes.node.isRequired,
  /** The detailed contents to show within the toast */
  detail: PropTypes.node,
  /** Triggers the toast close functionality */
  onClose: PropTypes.func,
  /** "success", "info", "warning", or "error" */
  type: PropTypes.oneOf(Object.keys(ToastTypes)),
  /** Milliseconds the toast should appear for. Set to 0 to never disappear */
  displayTime: PropTypes.number,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
};

ToastMessage.defaultProps = {
  type: "info",
  displayTime: ONE_SECOND * 5.5,
};

ToastMessage.displayName = "ToastMessage";

export default ToastMessage;
