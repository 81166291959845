const TWO_SECONDS = 2000;
const ONE_SECOND = 1000;
const HALF_SECOND = 500;
const QUARTER_SECOND = 250;
const TENTH_SECOND = 100;
const TIME_OPTIONS = [TWO_SECONDS, ONE_SECOND, HALF_SECOND, QUARTER_SECOND, TENTH_SECOND];

const RESIZE_DEBOUNCE_DELAY = TENTH_SECOND;

// Key Codes
const ESCAPE_KEY = 27;
const LEFT_ARROW_KEY = 37;
const RIGHT_ARROW_KEY = 39;

const MOBILE_SCREEN = 420;
const SMALL_SCREEN = 600;
const MEDIUM_SCREEN = 992;
const LARGE_SCREEN = 1200;

// Bowser Browser Names
const BOWSER_FIREFOX = "Firefox";
const BOWSER_SAFARI = "Safari";

export {
  TIME_OPTIONS,
  TWO_SECONDS,
  ONE_SECOND,
  HALF_SECOND,
  RESIZE_DEBOUNCE_DELAY,
  QUARTER_SECOND,
  TENTH_SECOND,
  ESCAPE_KEY,
  LEFT_ARROW_KEY,
  RIGHT_ARROW_KEY,
  MOBILE_SCREEN,
  SMALL_SCREEN,
  MEDIUM_SCREEN,
  LARGE_SCREEN,
  BOWSER_FIREFOX,
  BOWSER_SAFARI,
};
