import { useCallback, useLayoutEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash";

import HeatMapBlock from "./HeatMapBlock";

const HeatMapContainer = styled.div`
  display: flex;
  overflow: auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 16px;
  gap: 1px;
`;

const HeatMap = (props) => {
  const [firstBlockRef, setFirstBlockRef] = useState(null);
  const containerRef = useRef(null);
  const [firstBlockWidth, setFirstBlockWidth] = useState(null);

  const isLastRow = useCallback(
    (index) => {
      if (_.isNull(containerRef.current) || _.isNull(firstBlockRef)) {
        return false;
      }

      const containerRect = containerRef.current.getBoundingClientRect();
      const blockRect = firstBlockRef.getBoundingClientRect();

      // Calculate the number of blocks in a row
      const blocksPerRow = Math.floor(containerRect.width / blockRect.width);

      // Calculate the number of rows
      const numRows = Math.ceil(_.size(props.reports) / blocksPerRow);

      // Calculate the row index of the item
      const rowIndex = Math.floor(index / blocksPerRow) + 1;

      return rowIndex === numRows;
    },
    [containerRef, firstBlockRef, props.reports]
  );

  useLayoutEffect(() => {
    if (!firstBlockRef) {
      return;
    }

    const measure = () => {
      const rect = firstBlockRef.getBoundingClientRect();
      setFirstBlockWidth(rect.width);
    };

    measure();

    window.addEventListener("resize", measure);

    return () => {
      window.removeEventListener("resize", measure);
    };
  }, [firstBlockRef, setFirstBlockWidth]);

  return (
    <HeatMapContainer ref={containerRef}>
      {_.map(props.reports, (r, i) => (
        <HeatMapBlock
          key={r.company_name}
          firstBlockRef={i === 0 ? setFirstBlockRef : null}
          width={isLastRow(i) ? firstBlockWidth : null}
          change={r.zx_index_value_percent_change}
          companyId={r.company_id}
          companyName={r.company_name}
          sectorNames={r.sector_names}
        />
      ))}
    </HeatMapContainer>
  );
};

HeatMap.propTypes = {
  reports: PropTypes.arrayOf(
    PropTypes.shape({
      company_id: PropTypes.string,
      company_name: PropTypes.string,
      sector_names: PropTypes.string,
      zx_index_value_percent_change: PropTypes.number,
    })
  ).isRequired,
};

export default HeatMap;
