import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import styled from "styled-components";
import {
  Table,
  TrashIcon,
  PencilIcon,
  YukaThemeProvider,
  ColorPalette,
  CurrencyCell,
  Fonts,
  IconButton,
} from "yuka";

import { MODE_ZX_INDEX_VALUE } from "./constants";
import DeleteShareLotConfirmationModal from "./DeleteShareLotConfirmationModal";
import { PortfolioModeContext } from "./PortfolioProfile";
import SingleShareLotModal from "./SingleShareLotModal";

import addCommasToNum from "../utils/addCommasToNum";
import {
  expandedMoneyFormat,
  percentFormat,
} from "../utils/displayFormatUtils";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";

const StyledButtonRow = styled.div`
  width: 100%;
  justify-content: flex-end;
  display: flex;
`;

const ShareLotTableStyles = {
  colors: {
    header: "transparent",
    row: "transparent",
    rowHover: ColorPalette.white05,
    rowActive: ColorPalette.white05,
  },
  header: {
    paddingLeft: 0,
    paddingRight: 0,
    borderTop: "none",
    borderBottom: `1px dashed ${ColorPalette.white15}`,
  },
  cells: {
    paddingLeft: 0,
    paddingRight: 0,
    borderBottom: `1px dashed ${ColorPalette.white15}`,
  },
};

const COLUMNS = [
  {
    id: "security",
    accessor: (shareLot) => shareLot,
    header: "Share lot",
    width: 5,
    cellRenderer: ({ value: shareLot }) => {
      return (
        <>
          <Fonts.Body1theme80>{shareLot.security}</Fonts.Body1theme80>
          <Fonts.Body1theme50>
            &nbsp;(
            {DateTime.fromISO(shareLot.purchase_date).toFormat("MM/dd/yyyy")})
          </Fonts.Body1theme50>
        </>
      );
    },
  },
  {
    id: "pricePerShare",
    accessor: "price_per_share",
    header: "Purchase price",
    width: 4,
    cellRenderer: CurrencyCell,
  },
  {
    id: "shareQuantity",
    accessor: "share_quantity",
    header: "Quantity",
    width: 2,
    cellRenderer: ({ value }) => addCommasToNum(value),
  },
  {
    id: "totalValue",
    accessor: (shareLot) => shareLot,
    header: "Value",
    width: 3,
    cellRenderer: ({ value: shareLot, companyAggregate, mode }) => {
      const value =
        mode === MODE_ZX_INDEX_VALUE
          ? companyAggregate.currentZXIndexValue
          : companyAggregate.customValuation?.computed_value || null;
      if (value === null) {
        return <Fonts.Body1theme30>--</Fonts.Body1theme30>;
      }
      return expandedMoneyFormat(value * shareLot.share_quantity, 2, 2);
    },
  },
  {
    id: "unrealizedGain",
    accessor: (shareLot) => shareLot,
    header: "Unrealized gain",
    width: 5,
    cellRenderer: ({ value: shareLot, companyAggregate, mode }) => {
      const value =
        mode === MODE_ZX_INDEX_VALUE
          ? companyAggregate.currentZXIndexValue
          : companyAggregate.customValuation?.computed_value || null;
      if (value === null) {
        return <Fonts.Body1theme30>--</Fonts.Body1theme30>;
      }
      const costBasis = shareLot.price_per_share * shareLot.share_quantity;
      const currentTotalValue = value * shareLot.share_quantity;
      const unrealizedGain = currentTotalValue - costBasis;
      const percentageChange = costBasis
        ? ((currentTotalValue - costBasis) / costBasis) * 100
        : null;
      if (unrealizedGain > 0) {
        return (
          <Fonts.Body1buy>
            +{expandedMoneyFormat(unrealizedGain, 2, 2)} (+
            {percentFormat(percentageChange)})
          </Fonts.Body1buy>
        );
      }
      if (unrealizedGain < 0) {
        return (
          <Fonts.Body1sell>
            {expandedMoneyFormat(unrealizedGain, 2, 2)} (
            {percentFormat(percentageChange)})
          </Fonts.Body1sell>
        );
      }
      return `${expandedMoneyFormat(unrealizedGain)} (${percentFormat(
        percentageChange
      )})`;
    },
  },
  {
    id: "blank",
    width: 64,
    useFixedWidth: true,
    header: "",
  },
  {
    id: "actions",
    align: "right",
    accessor: (shareLot) => shareLot,
    width: 4,
    cellRenderer: ({
      value: shareLot,
      row,
      mode,
      companyName,
      editShareLot,
      deleteShareLot,
      hoveredRowId,
    }) => {
      // Conditionally render the action buttons if the rows hovered.
      if (hoveredRowId === row.id) {
        return (
          <StyledButtonRow>
            <IconButton
              icon={PencilIcon}
              onClick={() => {
                MixpanelEvents.openEditShareLotModal("share lots table", mode);
                editShareLot(shareLot);
              }}
            />
            <IconButton
              icon={TrashIcon}
              onClick={() => {
                MixpanelEvents.openRemoveShareLotModal(
                  "share lots table",
                  mode,
                  companyName
                );
                deleteShareLot(shareLot);
              }}
            />
          </StyledButtonRow>
        );
      }
      return "";
    },
  },
];

const ShareLotTable = ({ companyAggregate }) => {
  const [editingShareLot, setEditingShareLot] = useState(null);
  const [deletingShareLot, setDeletingShareLot] = useState(null);
  const [hoveredRowId, setHoveredRowId] = useState(null);

  const { mode } = useContext(PortfolioModeContext);
  const { shareLots, company, name } = companyAggregate;

  return (
    <YukaThemeProvider theme={{ tableStyles: ShareLotTableStyles }}>
      <Table
        companyName={name}
        mode={mode}
        companyAggregate={companyAggregate}
        editShareLot={setEditingShareLot}
        deleteShareLot={setDeletingShareLot}
        usePercentageColumnWidths
        columns={COLUMNS}
        data={shareLots}
        hoveredRowId={hoveredRowId}
        onRowClick={() => {}}
        onRowMouseOver={({ row }) => setHoveredRowId(row.id)}
        onRowMouseOut={() => setHoveredRowId(null)}
      />
      {editingShareLot && (
        <SingleShareLotModal
          mode={mode}
          source="share lots table"
          onClose={() => setEditingShareLot(null)}
          shareLot={editingShareLot}
          company={company}
        />
      )}
      {deletingShareLot && (
        <DeleteShareLotConfirmationModal
          source="share lots table"
          mode={mode}
          shareLot={deletingShareLot}
          company={company}
          onClose={() => setDeletingShareLot(null)}
        />
      )}
    </YukaThemeProvider>
  );
};

ShareLotTable.propTypes = {
  companyAggregate: PropTypes.shape({
    name: PropTypes.string,
    company: PropTypes.shape({}),
    shareLots: PropTypes.arrayOf(
      PropTypes.shape({
        security: PropTypes.string,
        price_per_share: PropTypes.string,
        share_quantity: PropTypes.number,
      })
    ),
    currentZXIndexValue: PropTypes.string,
  }),
};

export default ShareLotTable;
