import PropTypes from "prop-types";
import React, { useRef } from "react";
import styled from "styled-components";
import { Form } from "react-final-form";
import {
  body1,
  Button,
  ButtonStyles,
  Card,
  CardStyles,
  FontColors,
  InputRow,
  overline,
} from "yuka";

import { FinalFormField } from "../hdYuka";

import AxiosInstance from "../api/AxiosInstance";
import { useDispatch, ACTIONS } from "../routes/StateProvider";

import { PaddedForm } from "./StyledComponents";
import LoadingSpinner from "../utils/LoadingSpinner";
import { API_ENDPOINTS } from "../api/constants";

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledInputContainer = styled.div`
  margin: 24px 0px;
`;

const CurrentEmailSpan = styled.span`
  ${overline}
  ${FontColors.theme50}
  margin-right: 120px;
`;

const UserEmailSpan = styled.span`
  ${body1}
  ${FontColors.theme80}
`;

const ERROR_MESSAGES = {
  EMAIL_MISMATCH: "Email addresses do not match.",
  INVALID_EMAIL: "Enter a valid email address.",
  INCORRECT_PASSWORD: "Password is not correct",
};

const EditEmailForm = (props) => {
  const dispatch = useDispatch();
  const newEmailRef = useRef();
  const newEmailConfirmRef = useRef();
  const passwordRef = useRef();

  const validate = (value) => (value ? undefined : "Required");

  const onSubmit = (values, form) => {
    return AxiosInstance.post(
      API_ENDPOINTS.EMAIL_CHANGE_REQUEST(),
      JSON.stringify(values)
    )
      .then(() => {
        dispatch({
          type: ACTIONS.addToast,
          message:
            "Confirmation email sent. Click the link in the email to complete the process.",
        });

        // Clear form of data and remove any focus to not re-trigger validation
        newEmailRef.current.blur();
        newEmailConfirmRef.current.blur();
        passwordRef.current.blur();
        form.restart();
      })
      .catch(({ response: { data } }) => {
        let errorMsg = "Unknown error occurred";
        let errorField = "password";

        // Remove focus from input fields so validation messages display properly when enter is pressed
        newEmailRef.current.blur();
        newEmailConfirmRef.current.blur();
        passwordRef.current.blur();

        // Map specific error messages to form field names for validation messages
        const errorMap = {
          [ERROR_MESSAGES.EMAIL_MISMATCH]: "new_email_confirm",
          [ERROR_MESSAGES.INVALID_EMAIL]: "new_email_confirm",
          [ERROR_MESSAGES.INCORRECT_PASSWORD]: "password",
        };

        if (data?.Error) {
          errorMsg = data.Error[0];
          errorField = errorMap[errorMsg] || errorField;
        } else if (data?.new_email_confirm) {
          errorMsg = data.new_email_confirm[0];
          errorField = errorMap[errorMsg] || errorField;
        }

        return {
          [errorField]: errorMsg,
        };
      });
  };

  return (
    <Card
      cardStyle={CardStyles.SECTIONED}
      title="Email"
      tagline="After requesting an email change, you'll receive a confirmation email with a link. Click the link to complete the process. Please note that each email can be linked to just one account."
    >
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting, dirty }) => (
          <React.Fragment>
            {submitting && <LoadingSpinner />}
            <PaddedForm onSubmit={handleSubmit}>
              <div>
                <CurrentEmailSpan>Your current email</CurrentEmailSpan>
                <UserEmailSpan>
                  {props.user?.email ?? "Email not found!"}
                </UserEmailSpan>
              </div>
              <StyledInputContainer>
                <InputRow>
                  <FinalFormField
                    id="new_email"
                    type="email"
                    name="new_email"
                    placeholder="Email"
                    label="New Email Address"
                    validate={validate}
                    displayIndicator={false}
                    ref={newEmailRef}
                  />
                </InputRow>
                <InputRow>
                  <FinalFormField
                    id="new_email_confirm"
                    type="email"
                    name="new_email_confirm"
                    placeholder="Confirm email"
                    label="New Email Address Confirmation"
                    validate={validate}
                    displayIndicator={false}
                    ref={newEmailConfirmRef}
                  />
                </InputRow>
                <InputRow>
                  <FinalFormField
                    id="password"
                    type="password"
                    name="password"
                    placeholder="Password"
                    label="Password"
                    validate={validate}
                    displayIndicator={false}
                    ref={passwordRef}
                  />
                </InputRow>
              </StyledInputContainer>
              <StyledButtonContainer>
                <Button
                  buttonStyle={ButtonStyles.CTA}
                  disabled={submitting || !dirty}
                  type="submit"
                >
                  Change Email
                </Button>
              </StyledButtonContainer>
            </PaddedForm>
          </React.Fragment>
        )}
      </Form>
    </Card>
  );
};

EditEmailForm.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
  }).isRequired,
};

export default EditEmailForm;
