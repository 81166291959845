/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``triple-chevron-down.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const TripleChevronDownIcon = ({ color, bottomColor, middleColor, topColor, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={bottomColor || color}
        fillRule="evenodd"
        d="M9.53033 15.5303C9.23744 15.8232 8.76256 15.8232 8.46967 15.5303L4.71967 11.7803C4.42678 11.4874 4.42678 11.0126 4.71967 10.7197C5.01256 10.4268 5.48744 10.4268 5.78033 10.7197L9 13.9393L12.2197 10.7197C12.5126 10.4268 12.9874 10.4268 13.2803 10.7197C13.5732 11.0126 13.5732 11.4874 13.2803 11.7803L9.53033 15.5303Z"
        transform=""
      />
      <path
        fill={middleColor || color}
        fillRule=""
        d="M4.71967 6.46967C5.01256 6.17678 5.48744 6.17678 5.78033 6.46967L9 9.68934L12.2197 6.46967C12.5126 6.17678 12.9874 6.17678 13.2803 6.46967C13.5732 6.76256 13.5732 7.23744 13.2803 7.53033L9.53033 11.2803C9.23744 11.5732 8.76256 11.5732 8.46967 11.2803L4.71967 7.53033C4.42678 7.23744 4.42678 6.76256 4.71967 6.46967Z"
        transform=""
      />
      <path
        fill={topColor || color}
        fillRule=""
        d="M4.71967 1.96967C5.01256 1.67678 5.48744 1.67678 5.78033 1.96967L9 5.18934L12.2197 1.96967C12.5126 1.67678 12.9874 1.67678 13.2803 1.96967C13.5732 2.26256 13.5732 2.73744 13.2803 3.03033L9.53033 6.78033C9.23744 7.07322 8.76256 7.07322 8.46967 6.78033L4.71967 3.03033C4.42678 2.73744 4.42678 2.26256 4.71967 1.96967Z"
        transform=""
      />
    </g>
  </svg>
);

TripleChevronDownIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  bottomColor: PropTypes.string,
  middleColor: PropTypes.string,
  topColor: PropTypes.string,
  size: PropTypes.number,
};

TripleChevronDownIcon.defaultProps = {
  color: "#ffffff",
  bottomColor: null,
  middleColor: null,
  topColor: null,
  size: 16,
};

TripleChevronDownIcon.displayName = "TripleChevronDownIcon";

export default TripleChevronDownIcon;
