/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``backward.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const BackwardIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M5.78033 3.21967C6.07322 3.51256 6.07322 3.98744 5.78033 4.28033L4.06066 6H12.375C14.6532 6 16.5 7.84683 16.5 10.125C16.5 12.4032 14.6532 14.25 12.375 14.25H9C8.58579 14.25 8.25 13.9142 8.25 13.5C8.25 13.0858 8.58579 12.75 9 12.75H12.375C13.8247 12.75 15 11.5747 15 10.125C15 8.67525 13.8247 7.5 12.375 7.5H4.06066L5.78033 9.21967C6.07322 9.51256 6.07322 9.98744 5.78033 10.2803C5.48744 10.5732 5.01256 10.5732 4.71967 10.2803L1.71967 7.28033C1.42678 6.98744 1.42678 6.51256 1.71967 6.21967L4.71967 3.21967C5.01256 2.92678 5.48744 2.92678 5.78033 3.21967Z"
        transform=""
      />
    </g>
  </svg>
);

BackwardIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

BackwardIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

BackwardIcon.displayName = "BackwardIcon";

export default BackwardIcon;
