import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { convertDelimitedStringToNum, THOUSAND, MILLION, BILLION } from "./internal/InputUtils";
import { StyledInput } from "./internal/StyledComponents";
import InputWrapper from "./internal/InputWrapper";

import { getSetting } from "../Settings";

const AUTO_END_CHARACTERS = [".", THOUSAND, MILLION, BILLION];

/*
 * This function can be passed as `props.parse` to React Final Form `Field`
 */
const parseDelimitedValue = value => {
  const cachedRes = value || "";

  // If trailing 0 after decimal or trailing decimal, reattach and store as string to keep those
  // characters.
  if (value === ".") {
    return "0.";
  }
  const trailingCharsMatch = cachedRes.match(/(\.0+)$|\.[0-9]*[1-9](0+)$|(\.)$/);
  if (trailingCharsMatch) {
    // 3 possible groups to match against above. Only 1 will actually match, grab it
    // Either trailing 0's only after the decimal, trailing 0's after decimal positions or hanging .
    // eslint-disable-next-line no-magic-numbers
    const myMatch = trailingCharsMatch[1] || trailingCharsMatch[2] || trailingCharsMatch[3];
    // Implicit conversion to string representing raw number
    return convertDelimitedStringToNum(value) + myMatch;
  }
  return convertDelimitedStringToNum(value);
};

/*
 * This function can be passed as `props.format` to React Final Form `Field`
 */
const formatDelimitedValue = storeValue => {
  if (_.isNil(storeValue)) {
    return storeValue;
  }
  // Takes numerical value (or string with trailing insignificant digits), inserts commas in
  const initialInParts = storeValue.toString().split(".");
  initialInParts[0] = initialInParts[0].split(/(?=(?:\d{3})+$)/).join(",");

  return initialInParts.join(".");
};

/**
 * This field provides additional behavior around inputting large numbers. Specifically, it
 *
 * 1. Automatically adds commas (and moves the cursor intuitively)
 * 2. Expands trailing "k", "m", and "b" with zeros
 *
 * This functionality is provided via helpers that are not actually visible within the stories
 * but are provided as helper functions for React Final Form to use alongside
 *
 * Note: This currently depends on final-form to apply formatting properly.
 */
class DelimitedNumberInput extends React.Component {
  constructor() {
    super();
    this.ref = React.createRef();
    this.onChange = this.onChange.bind(this);
  }
  getSnapshotBeforeUpdate() {
    const newValue = this.props.value || "";
    // Grab the value in the actual input after the last keystroke
    const currentCommaCount = this.ref.current.value.split(",").length - 1;
    // Grab the updated value with commas inserted as appropriate
    const updatedCommaCount = newValue.split(",").length - 1;

    let cursorPos = 0;
    try {
      cursorPos = this.ref.current.selectionStart;
    } catch (e) {
      // in Safari, accessing current.selectionStart may throw a TypeError.
      // in this case, use the default value of 0
      // manually log the error because we are catching it
      const errorPostDetails = { e, route: window.location.href };
      getSetting("logError")(errorPostDetails);
    }

    // Grab the added commas for cursor positioning and the previous cursor position
    return {
      // The number of commas programmatically added/removed
      commaDiff: updatedCommaCount - currentCommaCount,
      cursorPos,
      cursorAtEnd: cursorPos === this.ref.current.value.length,
      lastChar: this.ref.current.value[this.ref.current.value.length - 1],
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    // Make sure cursor position is set correctly
    const value = this.props.value;
    // If we have a decimal at the end, cursor is at the end, stay there
    // If we used k, m, b as a shortcut, move to end of new value
    const autoEnd =
      value &&
      _.includes(AUTO_END_CHARACTERS, _.toLower(snapshot.lastChar)) &&
      snapshot.cursorAtEnd;
    if (snapshot && document.activeElement === this.ref.current && !autoEnd) {
      const cursorAdjustment = snapshot.commaDiff;
      if (snapshot.cursorPos - cursorAdjustment < 0) {
        // If the cursor should go back to the beginning (deleted from front, etc)
        this.ref.current.selectionStart = 0;
        this.ref.current.selectionEnd = 0;
      } else {
        // We typed in the middle/end or removed characters from the middle
        // Position it to where the cursor was and adjust for any commas that we added/removed
        this.ref.current.selectionStart = snapshot.cursorPos + snapshot.commaDiff;
        this.ref.current.selectionEnd = snapshot.cursorPos + snapshot.commaDiff;
      }
    }
  }
  onChange(event) {
    const prevValue = this.props.value || "";
    const newValue = event.target.value || "";

    // If trying to remove a single comma, reset the cursor.
    const cursorPos = event.target.selectionStart;
    if (
      prevValue !== newValue &&
      newValue &&
      prevValue.length === newValue.length + 1 &&
      newValue.split(",").length - prevValue.split(",").length < 0
    ) {
      /* eslint-disable no-param-reassign */
      event.target.value = this.props.value;
      event.target.selectionStart = cursorPos + 1;
      event.target.selectionEnd = cursorPos + 1;
      /* eslint-enable no-param-reassign */
      return;
    }
    this.props.onChange(event);
  }
  render() {
    return (
      <InputWrapper {...this.props} inputRef={this.ref}>
        <StyledInput
          ref={this.ref}
          {..._.omit(this.props, [
            "leadingIcon",
            "trailingIcon",
            "showError",
            "className",
            "style",
          ])}
          onChange={this.onChange}
          value={this.props.value || ""}
          type="text"
        />
      </InputWrapper>
    );
  }
}

DelimitedNumberInput.propTypes = {
  value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  onChange: PropTypes.func,
  leadingIcon: PropTypes.func,
  trailingIcon: PropTypes.func,
  showError: PropTypes.bool,
  small: PropTypes.bool,
};

DelimitedNumberInput.defaultProps = {
  showError: false,
  small: false,
};

export default DelimitedNumberInput;
export { parseDelimitedValue, formatDelimitedValue };
