import PropTypes from "prop-types";
import React from "react";
import { Dot } from "recharts";
import { YukaColorPalette } from "yuka";

/**
 * This is a factory specifically to use as the `dot` prop passed into a recharts `Line`
 * component. The factory returns a component to render a dot when the user hovers over it.
 *
 * `dataKey` will be used to fish the correct chart data value out of the hover state, to know if
 *    the user is hovering specifically the bar or just somewhere in the graph.
 * `activeIndex` will be used to determine if the dot should be displayed
 */
const ActiveDot = (color, activeIndex) => {
  const DotComponent = ({ cx, cy, ...props }) => {
    return activeIndex === props.index ||
      activeIndex === props.payload?.time ? (
      <Dot
        fill={color}
        stroke={YukaColorPalette.surface0}
        strokeWidth={2}
        r={6}
        cx={cx}
        cy={cy}
      />
    ) : null;
  };

  DotComponent.propTypes = {
    cx: PropTypes.number.isRequired,
    cy: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    payload: PropTypes.shape({
      time: PropTypes.string,
    }),
  };

  return DotComponent;
};

export default ActiveDot;
