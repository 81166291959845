import _ from "lodash";
import { DateTime } from "luxon";
import React, { useMemo } from "react";
import styled from "styled-components";
import {
  Fonts,
  HyperLink,
  PageSection,
  TruncatedText,
  useDropdown,
} from "yuka";

import useCompany from "./hooks/useCompany";
import PostMoneyCalculatorDropdown from "./PostMoneyCalculatorDropdown";
import { borderStyle, List, ListItem } from "./StyledComponents";
import convertRelativeRankingToString from "./utils/convertRelativeRankingToString";

import { API_ENDPOINTS } from "../api/constants";
import useFetch from "../api/useFetch";
import { WEEKLY, ORDER_FLOW_TIME_FRAME_MONTHLY } from "../utils/constants";
import convertNumToReadable from "../utils/convertNumToReadable";
import {
  expandedMoneyFormat,
  percentFormat,
} from "../utils/displayFormatUtils";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";

const Container = styled.div`
  display: flex;
  padding: 16px 0;
  border-top: ${borderStyle};
  border-bottom: ${borderStyle};
`;

const ONE_MILLION = 1000000;

const FundingRoundContainer = styled(Fonts.Body2theme80).attrs({ as: "div" })`
  max-width: 65%;
  display: flex;
  align-items: center;
`;

const CompanyHighLevelStats = () => {
  const [company, isLoading] = useCompany();

  // Latest Order Flow
  const latestOrderFlowReportQuery = useFetch(
    API_ENDPOINTS.COMPANY_LATEST_ORDER_FLOW(),
    {
      time_frame: WEEKLY,
      id: company?.zb_id,
    },
    {
      enabled: Boolean(company.zb_id),
    }
  );
  const latestOrderFlowReport = useMemo(
    () => latestOrderFlowReportQuery?.cleanedData?.data[0],
    [latestOrderFlowReportQuery]
  );

  // Latest Funding Round
  const latestFundingRoundQuery = useFetch(API_ENDPOINTS.HD_FUNDING_ROUNDS(), {
    company: company.zb_id,
    "page[size]": 1,
  });
  const latestFundingRound = useMemo(
    () => latestFundingRoundQuery?.cleanedData?.data[0],
    [latestFundingRoundQuery]
  );

  // Latest Valid Reported Mark
  const reportedMarksQuery = useFetch(
    API_ENDPOINTS.COMPANY_REPORTED_MARKS_VALUATION(company.zb_id)
  );
  const reportedMarksData = useMemo(
    () => reportedMarksQuery?.data?.data,
    [reportedMarksQuery]
  );
  const reportedMarksWithDate = useMemo(
    () =>
      _.map(reportedMarksData, (data, date) => ({
        date,
        ...data,
      })),
    [reportedMarksData]
  );
  const validReportedMarks = useMemo(
    () => _.filter(reportedMarksWithDate, (d) => !_.isNull(d.weighted_avg)),
    [reportedMarksWithDate]
  );
  const latestReportedMark = useMemo(
    () => _.first(validReportedMarks),
    [validReportedMarks]
  );

  // Used only for the Relative Rankings stat
  const headerStatsQuery = useFetch(API_ENDPOINTS.HEADER_STATS(company.zb_id), {
    time_frame: ORDER_FLOW_TIME_FRAME_MONTHLY,
  });
  const ranking = useMemo(
    () => headerStatsQuery?.data?.relative_ranking?.current,
    [headerStatsQuery]
  );

  const [customPostmoneyValuationDropdown, dropdownRef, toggleDropdown] =
    useDropdown(() => (
      <PostMoneyCalculatorDropdown
        latestFundingRound={latestFundingRound}
        latestOrderFlow={latestOrderFlowReport}
      />
    ));

  /**
   * Handle the cases for funding round display individually for maximum specificity + the flattest
   * possible DOM rendering.
   */
  const latestFundingRoundDisplay = useMemo(() => {
    const roundName = latestFundingRound?.security || latestFundingRound?.event;

    if (!latestFundingRound || (!roundName && !latestFundingRound.date)) {
      return <Fonts.Body2theme30>--</Fonts.Body2theme30>;
    }

    if (!roundName && latestFundingRound.date) {
      return (
        <Fonts.Body2theme80>
          {DateTime.fromISO(latestFundingRound.date).toLocaleString()}
        </Fonts.Body2theme80>
      );
    }

    if (roundName && !latestFundingRound.date) {
      return (
        <FundingRoundContainer>
          <TruncatedText>{roundName}</TruncatedText>
        </FundingRoundContainer>
      );
    }

    return (
      <FundingRoundContainer>
        <TruncatedText>{roundName}</TruncatedText>
        &nbsp;({DateTime.fromISO(latestFundingRound.date).toLocaleString()})
      </FundingRoundContainer>
    );
  }, [latestFundingRound]);

  if (isLoading) {
    return null;
  }

  return (
    <Container>
      <List>
        <ListItem>
          <Fonts.Body1theme50>ZX Index Value (Current)</Fonts.Body1theme50>
          {latestOrderFlowReport?.zx_index_value ? (
            <Fonts.Body2theme80>
              {expandedMoneyFormat(latestOrderFlowReport.zx_index_value, 2, 2)}
            </Fonts.Body2theme80>
          ) : (
            <Fonts.Body2theme30>--</Fonts.Body2theme30>
          )}
        </ListItem>
        <ListItem>
          <Fonts.Body1theme50>ZX Index Value (Trailing)</Fonts.Body1theme50>
          {latestOrderFlowReport?.zx_index_value_trailing ? (
            <Fonts.Body2theme80>
              {expandedMoneyFormat(
                latestOrderFlowReport.zx_index_value_trailing,
                2,
                2
              )}
            </Fonts.Body2theme80>
          ) : (
            <Fonts.Body2theme30>--</Fonts.Body2theme30>
          )}
        </ListItem>
        <ListItem>
          <Fonts.Body1theme50>
            Reported marks (
            {latestReportedMark
              ? DateTime.fromISO(latestReportedMark.date).toLocaleString({
                  month: "short",
                  year: "numeric",
                })
              : "--"}
            )
          </Fonts.Body1theme50>
          {latestReportedMark?.weighted_avg ? (
            <Fonts.Body2theme80>
              {expandedMoneyFormat(latestReportedMark.weighted_avg, 2, 2)}
            </Fonts.Body2theme80>
          ) : (
            <Fonts.Body2theme30>--</Fonts.Body2theme30>
          )}
        </ListItem>
      </List>
      <List>
        <ListItem>
          <Fonts.Body1theme50>Last funding round price</Fonts.Body1theme50>
          {latestFundingRound?.price_per_share ? (
            <Fonts.Body2theme80>
              {expandedMoneyFormat(latestFundingRound.price_per_share, 2, 2)}
            </Fonts.Body2theme80>
          ) : (
            <Fonts.Body2theme30>--</Fonts.Body2theme30>
          )}
        </ListItem>
        <ListItem>
          <Fonts.Body1theme50>Last funding round</Fonts.Body1theme50>
          {latestFundingRoundDisplay}
        </ListItem>
        <ListItem>
          <Fonts.Body1theme50>
            Post money valuation
            {latestFundingRound &&
              latestFundingRound.postmoney_valuation &&
              latestFundingRound.price_per_share && (
                <>
                  &nbsp;(
                  <HyperLink
                    ref={dropdownRef}
                    onClick={() => {
                      MixpanelEvents.toggleCustomEnterpriseValuationCalculator(
                        company.name,
                        !customPostmoneyValuationDropdown
                      );
                      toggleDropdown();
                    }}
                  >
                    Custom
                  </HyperLink>
                  )<PageSection>{customPostmoneyValuationDropdown}</PageSection>
                </>
              )}
          </Fonts.Body1theme50>
          {latestFundingRound?.postmoney_valuation ? (
            <Fonts.Body2theme80>
              ${convertNumToReadable(latestFundingRound.postmoney_valuation, 2)}
            </Fonts.Body2theme80>
          ) : (
            <Fonts.Body2theme30>--</Fonts.Body2theme30>
          )}
        </ListItem>
      </List>
      <List>
        <ListItem>
          <Fonts.Body1theme50>Volume</Fonts.Body1theme50>
          {latestOrderFlowReport?.total_volume ? (
            <Fonts.Body2theme80>
              $
              {convertNumToReadable(
                latestOrderFlowReport.total_volume * ONE_MILLION,
                0
              )}
            </Fonts.Body2theme80>
          ) : (
            <Fonts.Body2theme30>--</Fonts.Body2theme30>
          )}
        </ListItem>
        <ListItem>
          <Fonts.Body1theme50>Bid / Offer ratio</Fonts.Body1theme50>
          {latestOrderFlowReport?.bid_ask_ratio ? (
            <Fonts.Body2theme80>
              {percentFormat(latestOrderFlowReport.bid_ask_ratio * 100, 0)}
            </Fonts.Body2theme80>
          ) : (
            <Fonts.Body2theme30>--</Fonts.Body2theme30>
          )}
        </ListItem>
        <ListItem>
          <Fonts.Body1theme50>Ranking</Fonts.Body1theme50>
          {ranking ? (
            <Fonts.Body2theme80>
              {convertRelativeRankingToString(ranking)}
            </Fonts.Body2theme80>
          ) : (
            <Fonts.Body2theme30>--</Fonts.Body2theme30>
          )}
        </ListItem>
      </List>
    </Container>
  );
};

export default CompanyHighLevelStats;
