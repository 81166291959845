import _ from "lodash";
import React, { useContext } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import {
  LinkTypes,
  LogOutIcon,
  Orientations,
  UserCircleIcon,
  Fonts,
  ChevronDownIcon,
  Button,
  ButtonStyles,
} from "yuka";

import { AuthContext } from "../auth";
import { useAnchoredMenu } from "../hdYuka";
import { ROUTING_PATH } from "../routes/constants";
import { fullDisplayName } from "../user/utils";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";
import UserAvatar from "../utils/UserAvatar";

const StyledProfileButton = styled(Button).attrs({
  buttonStyle: ButtonStyles.RAISED,
})`
  padding: 8px 12px;
  height: 40px;
`;

const AvatarContainer = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 12px;
`;

const UserNameContainer = styled.div`
  margin-right: 8px;
`;

/**
 * Renders a card with the users profile information that has an anchored menu.
 *
 * @returns {React.Element}
 */
const ProfileInfo = () => {
  const { user, logout } = useContext(AuthContext);

  const menuItems = _.compact([
    {
      url: ROUTING_PATH.ACCOUNT(),
      icon: UserCircleIcon,
      linkType: LinkTypes.LOCAL_LINK,
      onClick: () => MixpanelEvents.viewAccountSettings(),
      text: "Account",
    },
    {
      url: ROUTING_PATH.LOGIN(),
      text: "Log Out",
      icon: LogOutIcon,
      onClick: logout,
    },
  ]);

  const [dropdownElement, targetRef, toggleDisplay] = useAnchoredMenu({
    id: "UserProfileMenu",
    sectioned: true,
    orientation: Orientations.VERTICAL,
    menuItems: [{ menuItems }],
  });

  const profileInfoTarget = (
    <StyledProfileButton
      ref={targetRef}
      onClick={toggleDisplay}
      buttonStyle={ButtonStyles.RAISED}
      leadingIcon={() => (
        <AvatarContainer>
          <UserAvatar user={user} size="24px" />
        </AvatarContainer>
      )}
      trailingIcon={ChevronDownIcon}
    >
      <UserNameContainer>
        <Fonts.Body1theme50>{fullDisplayName(user)}</Fonts.Body1theme50>
      </UserNameContainer>
    </StyledProfileButton>
  );

  return (
    <React.Fragment>
      {profileInfoTarget}
      {ReactDOM.createPortal(dropdownElement, document.body)}
    </React.Fragment>
  );
};

export default ProfileInfo;
