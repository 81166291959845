/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``dots-horizontal.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const DotsHorizontalIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M2.25 9C2.25 8.17157 2.92157 7.5 3.75 7.5C4.57843 7.5 5.25 8.17157 5.25 9C5.25 9.82843 4.57843 10.5 3.75 10.5C2.92157 10.5 2.25 9.82843 2.25 9ZM7.5 9C7.5 8.17157 8.17157 7.5 9 7.5C9.82843 7.5 10.5 8.17157 10.5 9C10.5 9.82843 9.82843 10.5 9 10.5C8.17157 10.5 7.5 9.82843 7.5 9ZM12.75 9C12.75 8.17157 13.4216 7.5 14.25 7.5C15.0784 7.5 15.75 8.17157 15.75 9C15.75 9.82843 15.0784 10.5 14.25 10.5C13.4216 10.5 12.75 9.82843 12.75 9Z"
        transform=""
      />
    </g>
  </svg>
);

DotsHorizontalIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

DotsHorizontalIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

DotsHorizontalIcon.displayName = "DotsHorizontalIcon";

export default DotsHorizontalIcon;
