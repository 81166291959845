import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";

import FullPageFormLayout from "./internal/FullPageFormLayout";
import WizardContext from "./internal/WizardContext";

import ComponentGroup from "../Layout/ComponentGroup";
import { LinkTypes } from "../Button/getLinkProps";

const actionProps = [
  "submitText",
  "submitDisabled",
  "onSubmit",
  "onClose",
  "altText",
  "onClickAlt",
];
const passthroughProps = [
  "title",
  "subtitle",
  "footer",
  "leftContent",
  "logo",
  "homeUrl",
  "homeLinkType",
];

/**
 * This is the base interface for creating single step forms that take up the full page.
 *
 * Consider this as a sibling to `Wizard`.
 */
const FullPageForm = props => {
  let rightContent = null;
  if (props.summary && props.rightContent) {
    rightContent = (
      <>
        <ComponentGroup>{props.rightContent}</ComponentGroup>
        <ComponentGroup>{props.summary}</ComponentGroup>
      </>
    );
  } else if (props.summary) {
    rightContent = <ComponentGroup>{props.summary}</ComponentGroup>;
  } else if (props.rightContent) {
    rightContent = <ComponentGroup>{props.rightContent}</ComponentGroup>;
  }

  return (
    <WizardContext.Provider value={_.pick(props, actionProps)}>
      <FullPageFormLayout {..._.pick(props, passthroughProps)} rightContent={rightContent} />
    </WizardContext.Provider>
  );
};

FullPageForm.propTypes = {
  /*
   * Form header. This accepts nodes in order to support custom typography formatting, but is not
   * designed for more complex elements.
   */
  title: PropTypes.node.isRequired,
  /* Disclaimers and other footer content */
  footer: PropTypes.node,
  /* The main body content of the form */
  leftContent: PropTypes.node.isRequired,
  /* Interactive elements that are supplemental parts of the form. e.g. file uploads */
  rightContent: PropTypes.node,
  /*
   * Elements that show a summary of the state of the form. Is displayed below the right side
   * content if both are specified
   */
  summary: PropTypes.node,
  /* Site logo */
  logo: PropTypes.element.isRequired,
  /* URL to navigate to if logo clicked */
  homeUrl: PropTypes.string,
  homeLinkType: PropTypes.oneOf(Object.values(LinkTypes)),
  /* Close event handler */
  onClose: PropTypes.func.isRequired,
  /* Text to display for a submit button */
  submitText: PropTypes.string,
  /* Submit event handler */
  onSubmit: PropTypes.func.isRequired,
  /* Text to display for an alternate action. Only applicable if using the default wizard submit */
  onClickAlt: PropTypes.func,
  /* Alternate action event handler. Only applicable if using the default wizard submit */
  altText: PropTypes.string,
};

FullPageForm.defaultProps = {
  submitText: "Submit",
  homeUrl: "/",
  homeLinkType: LinkTypes.LOCAL_LINK,
};

FullPageForm.displayName = "FullPageForm";

export default FullPageForm;
