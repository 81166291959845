import { API_ENDPOINTS } from "../../api/constants";
import useFetch from "../../api/useFetch";

/**
 * Fetches the companies in a portfolio. The order of the returned array is guaranteed to match
 * the order of the companies in the portfolio. Returns the list of companies and a boolean
 * indicating the loading state of the query. If the query is still loading or encountered an error,
 * the returned company list is `null`.
 *
 * @param {string} portfolioId
 * @return {[Array<object>|null, boolean]}
 */
const usePortfolioCompanies = (portfolioId) => {
  const companiesQuery = useFetch(
    API_ENDPOINTS.PORTFOLIO_COMPANIES(),
    {
      portfolio: portfolioId,
    },
    {
      enabled: Boolean(portfolioId),
    }
  );

  if (companiesQuery.isLoading) {
    return [null, true];
  }

  if (!companiesQuery.isSuccess) {
    return [null, false];
  }

  return [companiesQuery.cleanedData.data, false];
};

export default usePortfolioCompanies;
