import React, { useContext } from "react";
import styled from "styled-components";
import { ColorPalette, Fonts, HyperLink, LinkStyles, LinkTypes } from "yuka";

import { PORTFOLIOS_FEATURE_NAME } from "../api/constants";
import { AuthContext } from "../auth";
import { PAGE_WIDTH } from "../constants";
import { DATAVERSE_ENJOYERS } from "../global-nav/constants";
import { ROUTING_PATH } from "../routes/constants";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";
import { SiteLogoGray, SiteLogoDataverseGray } from "../utils/svgs/SiteLogo";
import useHasFeatureAccess from "../utils/useHasFeatureAccess";

const SiteFooterContainer = styled.div`
  ${PAGE_WIDTH}
  display: flex;
  flex-shrink: 0;
  align-self: center;
  align-items: center;
  gap: 32px;
  padding-top: 48px;
  padding-bottom: 48px;
  border-top: 1px dashed ${ColorPalette.white15};
`;

/**
 * Renders a footer containing the site logo, and links to relevant legal documentation. Also
 * lets users report bugs. Should be inserted at the bottom of every page, within the scrollable
 * container for that page so that it becomes visible after scrolling to the bottom.
 */
const SiteFooter = () => {
  const [portfoliosFeatureAccess] = useHasFeatureAccess(
    PORTFOLIOS_FEATURE_NAME
  );

  const { user } = useContext(AuthContext);
  const siteLogo = DATAVERSE_ENJOYERS.includes(user?.email) ? (
    <SiteLogoDataverseGray />
  ) : (
    <SiteLogoGray />
  );

  return (
    <SiteFooterContainer>
      <HyperLink linkStyle={LinkStyles.UNSTYLED} url={ROUTING_PATH.HOME()}>
        {siteLogo}
      </HyperLink>
      <div />
      <HyperLink
        onClick={() => MixpanelEvents.openReportABug()}
        url="mailto:support@zanbato.com"
        linkStyle={LinkStyles.SUPPORT}
      >
        <Fonts.Body1theme50>Report a Bug</Fonts.Body1theme50>
      </HyperLink>
      <HyperLink
        onClick={() => MixpanelEvents.openTermsOfUse()}
        url={ROUTING_PATH.AGREEMENT("tou")}
        linkStyle={LinkStyles.SUPPORT}
        linkType={LinkTypes.EXTERNAL_LINK}
      >
        <Fonts.Body1theme50>Terms of Use</Fonts.Body1theme50>
      </HyperLink>
      <HyperLink
        onClick={() => MixpanelEvents.openPrivacyPolicy()}
        url={ROUTING_PATH.AGREEMENT("privacy")}
        linkStyle={LinkStyles.SUPPORT}
        linkType={LinkTypes.EXTERNAL_LINK}
      >
        <Fonts.Body1theme50>Privacy Policy</Fonts.Body1theme50>
      </HyperLink>
      <HyperLink
        onClick={() => MixpanelEvents.openNDA()}
        url={ROUTING_PATH.AGREEMENT("nda")}
        linkStyle={LinkStyles.SUPPORT}
        linkType={LinkTypes.EXTERNAL_LINK}
      >
        <Fonts.Body1theme50>NDA</Fonts.Body1theme50>
      </HyperLink>
      {portfoliosFeatureAccess && (
        <HyperLink
          url={ROUTING_PATH.INSIGHTS()}
          linkStyle={LinkStyles.SUPPORT}
          linkType={LinkTypes.LOCAL_LINK}
        >
          <Fonts.Body1theme50>Insights</Fonts.Body1theme50>
        </HyperLink>
      )}
    </SiteFooterContainer>
  );
};

export default SiteFooter;
