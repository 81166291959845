import PropTypes from "prop-types";

/*
 * The primary ZX Logo in grayscale
 *
 * @param {Object} props
 * @returns {React.Element}
 */
const ZXLogo = ({ size }) => (
  <svg width={size} height={size} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        fillOpacity=".3"
        d="M8 4a4 4 0 1 1-8 0 4 4 0 0 1 8 0M24 20a4 4 0 1 1-8 0 4 4 0 0 1 8 0"
      />
      <g fill="#FEFEFE">
        <path d="M24 4a4 4 0 1 1-8 0 4 4 0 0 1 8 0" />
        <path
          d="M20.36 9.297c-.121.008-.237.036-.36.036A5.339 5.339 0 0 1 14.667 4c0-.123.028-.24.036-.36L3.64 14.704c.121-.01.237-.038.36-.038A5.34 5.34 0 0 1 9.333 20c0 .124-.028.24-.036.36L20.36 9.297z"
          opacity=".5"
        />
        <path d="M8 20a4 4 0 1 1-8 0 4 4 0 0 1 8 0" />
      </g>
    </g>
  </svg>
);

ZXLogo.displayName = "ZXLogo";

ZXLogo.propTypes = {
  size: PropTypes.number,
};

ZXLogo.defaultProps = {
  size: 24,
};

export default ZXLogo;
