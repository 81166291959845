/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``triple-chevron-up.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const TripleChevronUpIcon = ({ color, bottomColor, middleColor, topColor, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={topColor || color}
        fillRule="evenodd"
        d="M8.46967 2.46967C8.76256 2.17678 9.23744 2.17678 9.53033 2.46967L13.2803 6.21967C13.5732 6.51256 13.5732 6.98744 13.2803 7.28033C12.9874 7.57322 12.5126 7.57322 12.2197 7.28033L9 4.06066L5.78033 7.28033C5.48744 7.57322 5.01256 7.57322 4.71967 7.28033C4.42678 6.98744 4.42678 6.51256 4.71967 6.21967L8.46967 2.46967Z"
        transform=""
      />
      <path
        fill={middleColor || color}
        fillRule=""
        d="M13.2803 11.5303C12.9874 11.8232 12.5126 11.8232 12.2197 11.5303L9 8.31066L5.78033 11.5303C5.48744 11.8232 5.01256 11.8232 4.71967 11.5303C4.42678 11.2374 4.42678 10.7626 4.71967 10.4697L8.46967 6.71967C8.76256 6.42678 9.23744 6.42678 9.53033 6.71967L13.2803 10.4697C13.5732 10.7626 13.5732 11.2374 13.2803 11.5303Z"
        transform=""
      />
      <path
        fill={bottomColor || color}
        fillRule=""
        d="M13.2803 16.0303C12.9874 16.3232 12.5126 16.3232 12.2197 16.0303L9 12.8107L5.78033 16.0303C5.48744 16.3232 5.01256 16.3232 4.71967 16.0303C4.42678 15.7374 4.42678 15.2626 4.71967 14.9697L8.46967 11.2197C8.76256 10.9268 9.23744 10.9268 9.53033 11.2197L13.2803 14.9697C13.5732 15.2626 13.5732 15.7374 13.2803 16.0303Z"
        transform=""
      />
    </g>
  </svg>
);

TripleChevronUpIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  bottomColor: PropTypes.string,
  middleColor: PropTypes.string,
  topColor: PropTypes.string,
  size: PropTypes.number,
};

TripleChevronUpIcon.defaultProps = {
  color: "#ffffff",
  bottomColor: null,
  middleColor: null,
  topColor: null,
  size: 16,
};

TripleChevronUpIcon.displayName = "TripleChevronUpIcon";

export default TripleChevronUpIcon;
