import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { useCheckbox } from "@react-aria/checkbox";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import { useToggleState } from "react-stately";

import { CheckIcon } from "../Icons";
import { PrimaryColorPalette } from "../StylingConstants";
import { getThemeColor } from "../ThemeContext";

const StyledCheckbox = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 16px;
  width: 16px;
  border-radius: 4px;
  flex-shrink: 0;

  svg {
    display: ${props => (props.$checked ? "initial" : "none")};
  }

  ${props => {
    if (props.$disabled) {
      return css`
        border-color: ${PrimaryColorPalette.white15};
        background: ${PrimaryColorPalette.white15};
      `;
    }
    if (props.$checked) {
      return css`
        border-color: ${getThemeColor(props.theme, "checkbox")};
        background: ${getThemeColor(props.theme, "checkbox")};
      `;
    }
    return css`
      border: 1px solid ${PrimaryColorPalette.white50};
      &:hover {
        border-color: ${PrimaryColorPalette.white80};
      }
    `;
  }}
`;

/**
 * A styled checkbox input.
 *
 * This uses a custom SVG with the underlying html input hidden.
 */
const Checkbox = React.forwardRef((props, ref) => {
  const ariaProps = {
    ...props,
    defaultSelected: props.defaultChecked,
    isSelected: props.checked,
    isDisabled: props.disabled,
    "aria-labelledby": props.id ?? props.name,
  };
  const state = useToggleState(ariaProps);
  const altRef = React.useRef(null);
  const { inputProps } = useCheckbox(ariaProps, state, ref || altRef);

  return (
    <>
      <VisuallyHidden>
        <input ref={ref || altRef} {...inputProps} />
      </VisuallyHidden>
      <StyledCheckbox $checked={state.isSelected} $disabled={props.disabled} aria-hidden="true">
        <CheckIcon
          size={16}
          color={props.disabled ? PrimaryColorPalette.white15 : PrimaryColorPalette.black80}
        />
      </StyledCheckbox>
    </>
  );
});

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  onChange: PropTypes.func,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  defaultChecked: PropTypes.bool,
  checked: PropTypes.bool,
  wrapLabel: PropTypes.bool,
};

Checkbox.defaultProps = {
  disabled: false,
  style: null,
  wrapLabel: false,
};

export default Checkbox;
