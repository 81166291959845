import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  Button,
  ButtonStyles,
  FontColors,
  PageSection,
  PlusIcon,
  Fonts,
  YukaThemeProvider,
  ColorPalette,
} from "yuka";

import CreatePortfolioModal from "./CreatePortfolioModal";
import { usePortfolio } from "./hooks";
import PortfolioProfile from "./PortfolioProfile";
import PortfolioTabContent from "./PortfolioTabContent";
import { StyledEmptyStateContainer, StyledFlexGrow } from "./StyledComponents";

import { API_ENDPOINTS } from "../api/constants";
import useFetch from "../api/useFetch";
import { PAGE_WIDTH } from "../constants";
import { TabList, Tab, TabDivider, TabGroup, ActionButton } from "../hdYuka";
import { KEY_ACTION_BUTTON_CONTAINER } from "../hdYuka/yukaThemeOverrides";
import { ROUTING_PATH } from "../routes/constants";
import SiteFooter from "../SiteFooter/component";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";

const StyledTabListPageSection = styled(PageSection)`
  flex-shrink: 0;
  padding: 0 24px;
`;

const StyledLayout = styled.div`
  ${PAGE_WIDTH}
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const PortfolioProfileContainer = styled.div`
  width: 100%;
  padding-top: 48px;
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-top: 1px solid ${ColorPalette.white15};
`;

const StyledNoPortfoliosTab = styled(Tab)`
  ${FontColors.theme30}
`;

const Portfolios = () => {
  const navigate = useNavigate();
  const [portfolio, portfolioIsLoading] = usePortfolio();
  const [createPortfolioModalOpen, setCreatePortfolioModalOpen] =
    useState(false);
  const portfolioQuery = useFetch(API_ENDPOINTS.PORTFOLIOS());

  const portfolios = useMemo(() => {
    return portfolioQuery.isSuccess && !portfolioQuery.isRefetching
      ? portfolioQuery.cleanedData.data
      : [];
  }, [
    portfolioQuery.isSuccess,
    portfolioQuery.isRefetching,
    portfolioQuery.cleanedData,
  ]);

  const firstPortfolioId = portfolios?.[0]?.apiId;

  useEffect(() => {
    // When a portfolio is deleted, we navigate away. Either to the first portfolio in their list,
    // or to the base page if there are no portfolios.
    if (!portfolioIsLoading && !portfolio) {
      if (firstPortfolioId) {
        navigate(ROUTING_PATH.PORTFOLIO(firstPortfolioId), { replace: true });
      } else {
        navigate(ROUTING_PATH.PORTFOLIOS(), { replace: true });
      }
    }
  }, [firstPortfolioId, portfolioIsLoading, portfolio, navigate]);

  const portfoliosTabContent = useMemo(() => {
    if (portfolioQuery.isLoading) {
      return (
        <StyledNoPortfoliosTab id="loading-portfolios">
          Loading portfolios
        </StyledNoPortfoliosTab>
      );
    }

    if (portfolios.length > 0) {
      return (
        <TabGroup scrollIntoView defaultTabId={firstPortfolioId}>
          {portfolios.map(({ apiId, name }) => (
            <Tab
              key={`tab_${apiId}`}
              id={apiId}
              onSelectTab={() =>
                MixpanelEvents.navigateToPortfolioProfile(
                  "portfolios tab",
                  apiId,
                  name
                )
              }
            >
              <PortfolioTabContent portfolioId={apiId} name={name} />
            </Tab>
          ))}
        </TabGroup>
      );
    }
    return (
      <StyledNoPortfoliosTab id="no-portfolios">
        You don't have any portfolios yet
      </StyledNoPortfoliosTab>
    );
  }, [portfolioQuery?.isLoading, portfolios, firstPortfolioId]);

  return (
    <YukaThemeProvider
      theme={{
        button: {
          [ButtonStyles.CTA]: {
            container: KEY_ACTION_BUTTON_CONTAINER,
          },
          [ButtonStyles.RAISED]: {
            container: KEY_ACTION_BUTTON_CONTAINER,
          },
        },
      }}
    >
      <StyledTabListPageSection>
        <TabList routeId="id">
          <Tab id="create-portfolio-button">
            <ActionButton
              icon={PlusIcon}
              onClick={() => setCreatePortfolioModalOpen(true)}
            >
              New portfolio
            </ActionButton>
          </Tab>
          <TabDivider />
          {portfoliosTabContent}
        </TabList>
      </StyledTabListPageSection>
      <PortfolioProfileContainer>
        <StyledLayout>
          {portfolios.length === 0 && !portfolioQuery.isLoading && (
            <StyledFlexGrow>
              <StyledEmptyStateContainer>
                <Fonts.Headline2theme80>
                  No portfolios yet
                </Fonts.Headline2theme80>
                <Fonts.Body1theme50>
                  Create your first portfolio to track and manage your private
                  companies and/or holdings with advanced insights.
                </Fonts.Body1theme50>
                <Button
                  buttonStyle={ButtonStyles.RAISED}
                  leadingIcon={PlusIcon}
                  onClick={() => setCreatePortfolioModalOpen(true)}
                >
                  Create a portfolio
                </Button>
              </StyledEmptyStateContainer>
            </StyledFlexGrow>
          )}
          <PortfolioProfile />
        </StyledLayout>
        <SiteFooter />
      </PortfolioProfileContainer>
      {createPortfolioModalOpen && (
        <CreatePortfolioModal
          source={portfolios.length === 0 ? "first portfolio" : "portfolio"}
          onClose={() => setCreatePortfolioModalOpen(false)}
        />
      )}
    </YukaThemeProvider>
  );
};

export default Portfolios;
