import { DateTime } from "luxon";
import { YukaColorPalette } from "yuka";

import LegendItem from "./LegendItem";
import VolumeAggregateLegendItem from "./VolumeAggregateLegendItem";

const WEEKS_IN_3_MONTHS = 13;
const WEEKS_IN_6_MONTHS = 26;
const WEEKS_IN_1_YEAR = 52;

const THREE_MONTHS_KEY = "3M";
const SIX_MONTHS_KEY = "6M";
const ONE_YEAR_KEY = "1Y";
const TWO_YEAR_KEY = "2Y";
const YTD_KEY = "YTD";

/*
 * Some hardcoded values to implement the hover state for the graph, which has a fixed size and
 * offset.
 */
const GRAPH_TOOLTIP_STYLES = {
  textAlign: "center",
  width: 72,
  height: 24,
  fontSize: 12,
  lineHeight: "24px",
  left: -36,
  background: YukaColorPalette.surface3,
  borderRadius: 4,
};

const KEY_COMPANY_SERIES_TYPE = "KEY_COMPANY";
const ZX_COMPARISON_SERIES_TYPE = "ZX_COMPARISON";
const ZX_MARKET_INDEX_SERIES_COMPARISON_TYPE = "ZX_MARKET_INDEX_COMPARISON";
const PUBLIC_COMPARISON_SERIES_TYPE = "PUBLIC_COMPARISON";

const API_TYPE_PRIVATE_COMPANY = "Company";
const API_TYPE_PRIVATE_MARKET_INDEX = "PrivateMarketIndex";
const API_TYPE_PUBLIC_SECURITY = "PublicSecurity";

const API_TYPE_TO_SERIES_TYPE = {
  [API_TYPE_PRIVATE_COMPANY]: ZX_COMPARISON_SERIES_TYPE,
  [API_TYPE_PRIVATE_MARKET_INDEX]: ZX_MARKET_INDEX_SERIES_COMPARISON_TYPE,
  [API_TYPE_PUBLIC_SECURITY]: PUBLIC_COMPARISON_SERIES_TYPE,
};

const ZX_COMPANY_EXCHANGE = "ZX";
const ZX_MARKET_INDEX = "ZXI";

const COMPANY_PROFILE_LINE_CHART_API_DATA_FIELDS = {
  [KEY_COMPANY_SERIES_TYPE]: {
    TIME_FIELD: "report_period",
    PRICE_DATA_FIELD: "zx_index_price",
    ADDITIONAL_DATA_FIELDS: ["robustness", "volume"],
  },
  [ZX_COMPARISON_SERIES_TYPE]: {
    TIME_FIELD: "report_period",
    PRICE_DATA_FIELD: "zx_index_price",
    ADDITIONAL_DATA_FIELDS: ["robustness"],
  },
  [ZX_MARKET_INDEX_SERIES_COMPARISON_TYPE]: {
    TIME_FIELD: "created_on",
    PRICE_DATA_FIELD: "value",
    ADDITIONAL_DATA_FIELDS: [],
    config: {
      castDateTimeToDate: true,
    },
  },
  [PUBLIC_COMPARISON_SERIES_TYPE]: {
    TIME_FIELD: "date",
    PRICE_DATA_FIELD: "close_price",
    ADDITIONAL_DATA_FIELDS: [],
  },
};

const PRICE_DATA_START_DATES = {
  [THREE_MONTHS_KEY]: DateTime.now().minus({ weeks: WEEKS_IN_3_MONTHS }),
  [SIX_MONTHS_KEY]: DateTime.now().minus({ weeks: WEEKS_IN_6_MONTHS }),
  [ONE_YEAR_KEY]: DateTime.now().minus({ weeks: WEEKS_IN_1_YEAR }),
  [YTD_KEY]: DateTime.now().startOf("year"),
};

const getBidOfferValue = (id, indicatorDataPoint) => {
  return {
    [`${id}_bid`]:
      indicatorDataPoint.bid !== null ? Number(indicatorDataPoint.bid) : null,
    [`${id}_offer`]:
      indicatorDataPoint.offer !== null
        ? Number(indicatorDataPoint.offer)
        : null,
  };
};

const INDICATOR_CATEGORY_BID_OFFER = "Bid & Ask";
const INDICATOR_CATEGORY_OVERALL = "Overall";

const INDICATOR_TYPES = {
  ZIVT: {
    name: "ZX Index Value - Trailing",
    apiDataKey: "zx_index_value_trailing",
    category: INDICATOR_CATEGORY_OVERALL,
    LegendItem: LegendItem,
  },
  DWA: {
    name: "Dollar-Weighted AVG.",
    apiDataKey: "overall_dwa",
    category: INDICATOR_CATEGORY_OVERALL,
    LegendItem: LegendItem,
  },
  SA: {
    name: "Simple AVG.",
    apiDataKey: "overall_simple_average",
    category: INDICATOR_CATEGORY_OVERALL,
    LegendItem: LegendItem,
  },
  V3MA: {
    name: "Volume - 3M AGG.",
    apiDataKey: "total_volume",
    isVolume: true,
    category: INDICATOR_CATEGORY_OVERALL,
    getIndicatorValueObject: getBidOfferValue,
    LegendItem: VolumeAggregateLegendItem,
  },
  BZX: {
    name: "Bid ZX Index Value",
    apiDataKey: "zx_index_value_bid_side",
    category: INDICATOR_CATEGORY_BID_OFFER,
    LegendItem: LegendItem,
  },
  BDWA: {
    name: "Bid Dollar-Weighted AVG.",
    apiDataKey: "bid_dwa",
    category: INDICATOR_CATEGORY_BID_OFFER,
    LegendItem: LegendItem,
  },
  BSA: {
    name: "Bid Simple AVG.",
    apiDataKey: "bid_simple_average",
    category: INDICATOR_CATEGORY_BID_OFFER,
    LegendItem: LegendItem,
  },
  OZX: {
    name: "Offer ZX Index Value",
    apiDataKey: "zx_index_value_ask_side",
    category: INDICATOR_CATEGORY_BID_OFFER,
    LegendItem: LegendItem,
  },
  ODWA: {
    name: "Offer Dollar-Weighted AVG.",
    apiDataKey: "ask_dwa",
    category: INDICATOR_CATEGORY_BID_OFFER,
    LegendItem: LegendItem,
  },
  OSA: {
    name: "Offer Simple AVG.",
    apiDataKey: "ask_simple_average",
    category: INDICATOR_CATEGORY_BID_OFFER,
    LegendItem: LegendItem,
  },
};

const AXIS_LABEL_FONT_SIZE = 14;

export {
  API_TYPE_TO_SERIES_TYPE,
  GRAPH_TOOLTIP_STYLES,
  AXIS_LABEL_FONT_SIZE,
  PRICE_DATA_START_DATES,
  TWO_YEAR_KEY,
  ONE_YEAR_KEY,
  SIX_MONTHS_KEY,
  THREE_MONTHS_KEY,
  YTD_KEY,
  INDICATOR_TYPES,
  COMPANY_PROFILE_LINE_CHART_API_DATA_FIELDS,
  KEY_COMPANY_SERIES_TYPE,
  PUBLIC_COMPARISON_SERIES_TYPE,
  INDICATOR_CATEGORY_OVERALL,
  INDICATOR_CATEGORY_BID_OFFER,
  ZX_COMPARISON_SERIES_TYPE,
  ZX_MARKET_INDEX_SERIES_COMPARISON_TYPE,
  ZX_COMPANY_EXCHANGE,
  ZX_MARKET_INDEX,
};
