/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``filter.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const FilterIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M1.5 4.5C1.5 4.08579 1.83579 3.75 2.25 3.75H15.75C16.1642 3.75 16.5 4.08579 16.5 4.5C16.5 4.91421 16.1642 5.25 15.75 5.25H2.25C1.83579 5.25 1.5 4.91421 1.5 4.5ZM3.75 9C3.75 8.58579 4.08579 8.25 4.5 8.25H13.5C13.9142 8.25 14.25 8.58579 14.25 9C14.25 9.41421 13.9142 9.75 13.5 9.75H4.5C4.08579 9.75 3.75 9.41421 3.75 9ZM6 13.5C6 13.0858 6.33579 12.75 6.75 12.75H11.25C11.6642 12.75 12 13.0858 12 13.5C12 13.9142 11.6642 14.25 11.25 14.25H6.75C6.33579 14.25 6 13.9142 6 13.5Z"
        transform=""
      />
    </g>
  </svg>
);

FilterIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

FilterIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

FilterIcon.displayName = "FilterIcon";

export default FilterIcon;
