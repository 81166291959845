/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``toggle-right.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ToggleRightIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M0.75 9C0.75 6.10051 3.10051 3.75 6 3.75H12C14.8995 3.75 17.25 6.10051 17.25 9C17.25 11.8995 14.8995 14.25 12 14.25H6C3.10051 14.25 0.75 11.8995 0.75 9ZM6 5.25C3.92893 5.25 2.25 6.92893 2.25 9C2.25 11.0711 3.92893 12.75 6 12.75H12C14.0711 12.75 15.75 11.0711 15.75 9C15.75 6.92893 14.0711 5.25 12 5.25H6ZM12 7.875C11.3787 7.875 10.875 8.37868 10.875 9C10.875 9.62132 11.3787 10.125 12 10.125C12.6213 10.125 13.125 9.62132 13.125 9C13.125 8.37868 12.6213 7.875 12 7.875ZM9.375 9C9.375 7.55025 10.5503 6.375 12 6.375C13.4497 6.375 14.625 7.55025 14.625 9C14.625 10.4497 13.4497 11.625 12 11.625C10.5503 11.625 9.375 10.4497 9.375 9Z"
        transform=""
      />
    </g>
  </svg>
);

ToggleRightIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ToggleRightIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

ToggleRightIcon.displayName = "ToggleRightIcon";

export default ToggleRightIcon;
