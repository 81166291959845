import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { body1, FontColors, YukaColorPalette } from "yuka";

import {
  TABLIST_VARIANTS,
  VARIANT_BUTTON_GROUP,
  VARIANT_DEFAULT,
} from "./constants";

const SCROLL_MARGINS = 64;

const StyledTab = styled.div`
  scroll-margin: ${SCROLL_MARGINS}px;
  ${FontColors.theme50}
  ${body1}
  position: relative;
  display: flex;
  align-items: flex-start;
  white-space: nowrap;
  line-height: 24px;

  height: 40px;
  box-sizing: border-box;

  ${(props) => {
    if (props.$variant === VARIANT_BUTTON_GROUP) {
      const sharedStyles = css`
        height: 30px;
        padding: 8px;
        border-radius: 8px;
        align-items: center;
      `;
      if (props.$selected) {
        return css`
          ${sharedStyles}
          ${FontColors.theme80}
          background-color: ${YukaColorPalette.surface2};
        `;
      }
      return css`
        ${sharedStyles}
      `;
    }
    // Default variant.
    if (props.$selected) {
      return css`
        ${FontColors.theme80}
        border-bottom: 2px solid white;
      `;
    }
    return css`
      border-bottom: 2px solid ${YukaColorPalette.surface1};
    `;
  }}

  &:hover {
    ${(props) =>
      props.onClick &&
      css`
        cursor: pointer;
        ${FontColors.theme80}
      `}
  }
`;

const StyledFlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
`;

const Tab = ({
  id,
  variant,
  className,
  children,
  currentTab,
  onSelectTab,
  scrollPosition,
  parentWidth,
  scrollIntoView,
}) => {
  const ref = useRef(null);
  const isSelected = id === currentTab || String(id) === currentTab;

  useEffect(() => {
    if (isSelected && parentWidth && scrollIntoView) {
      /*
       * Determines the tab extends beyond the left boundary of its parent. Checks to see if
       * all the space to the left of the tab is less than the parent's
       * scroll position + minus the scroll margin. The scroll margin is
       * used to ensure this function returns `true` even if there's a SCROLL_MARGIN worth of
       * buffer between the left side of the tab and the left side of the parent's container, since
       * that buffer is hidden below the parent's scroll-left arrow.
       */
      const isOverflowingToLeft =
        ref.current?.offsetLeft < scrollPosition + SCROLL_MARGINS;

      /*
       * Determines the tab extends beyond the right boundary of its parent. Checks to see if
       * all the space to the left of the tab + the width of the tab is greater than the parent's
       * scroll position + the parent's visible width, minus the scroll margin. The scroll margin is
       * used to ensure this function returns `true` even if there's a SCROLL_MARGIN worth of
       * buffer between the right side of the tab and the right side of the parent's container, since
       * that buffer is hidden below the parent's scroll-right arrow.
       */

      const isOverflowingToRight =
        ref.current?.offsetLeft + ref.current?.clientWidth >
        parentWidth + scrollPosition - SCROLL_MARGINS;

      if (isOverflowingToRight) {
        // If we need to scroll to the left to view the tab, line up the right-side of the tab
        // at the right-side of the parent.
        ref.current?.scrollIntoView({
          inline: "end",
          behavior: "smooth",
        });
      } else if (isOverflowingToLeft) {
        // If we need to scroll to the right to view the tab, line up the left-side of the tab
        // at the left-side of the parent.
        ref.current?.scrollIntoView({
          inline: "start",
          behavior: "smooth",
        });
      }
    }
    // We need to exclude scrollPosition otherwise the scroll slows down b/c it runs this effect
    // every frame.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollIntoView, isSelected, parentWidth]);

  return (
    <StyledTab
      id={id}
      className={className}
      ref={ref}
      role="tab"
      onClick={onSelectTab}
      $variant={variant}
      $selected={id === currentTab || String(id) === currentTab}
    >
      <StyledFlexRow>{children}</StyledFlexRow>
    </StyledTab>
  );
};

Tab.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(TABLIST_VARIANTS),
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  currentTab: PropTypes.string,
  onSelectTab: PropTypes.func,
  scrollPosition: PropTypes.number,
  parentWidth: PropTypes.number,
  scrollIntoView: PropTypes.bool,
};

Tab.defaultProps = {
  variant: VARIANT_DEFAULT,
  onSelectTab: null,
};

export default Tab;
