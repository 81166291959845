import { ColorPalette, YukaColorPalette } from "../../StylingConstants";

// Table styling constants.
const defaultBorder = `1px solid ${ColorPalette.white15}`;
// Border used to indicate the end of the sticky column section.
const thickenedBorder = `1px solid ${ColorPalette.white30}`;
const TableColors = {
  header: YukaColorPalette.surface2,
  headerText: ColorPalette.white50,
  headerActive: YukaColorPalette.surface4,
  headerHover: YukaColorPalette.surface3,
  row: YukaColorPalette.surface1,
  rowText: ColorPalette.white80,
  rowActive: YukaColorPalette.surface3,
  rowActiveText: ColorPalette.white100,
  rowHover: YukaColorPalette.surface2,
  rowSelected: ColorPalette.blue100,
  rowSelectedText: ColorPalette.white80,
};

// We support table coloring based on the surface level the table sits on. We need to use opaque
// colors for the table since we support side scroll. In general, table row color matches
// the surface level, and the others are derived. Headers are typically slightly lighter than
// the surface.

const SURFACE_1_COLORS = {
  ...TableColors,
  header: "#212121",
  headerHover: "#2C2C2C",
  headerActive: "#313131",
  row: YukaColorPalette.surface1,
  rowHover: "#252525",
  rowActive: YukaColorPalette.surface2,
};

const SURFACE_2_COLORS = {
  ...TableColors,
  header: "#303030",
  headerHover: "#3B3B3B",
  headerActive: "#3F3F3F",
  row: YukaColorPalette.surface2,
  rowHover: "#353535",
  rowActive: "#393939",
};

const SURFACE_3_COLORS = {
  ...TableColors,
  header: "#404040",
  headerHover: "#4A4A4A",
  headerActive: "#4E4E4E",
  row: YukaColorPalette.surface3,
  rowHover: "#444444",
  rowActive: "#484848",
};

const SURFACE_4_COLORS = {
  // You should avoid using tables at this surface level.
  ...TableColors,
  header: "#4F4F4F",
  headerHover: "#585858",
  headerActive: "#5C5C5C",
  row: YukaColorPalette.surface4,
  rowHover: "#535353",
  rowActive: "#575757",
};

const SURFACE_5_COLORS = {
  // You should really avoid using tables at this surface level.
  ...TableColors,
  header: "#5F5F5F",
  headerHover: "#676767",
  headerActive: "#6B6B6B",
  row: YukaColorPalette.surface5,
  rowHover: "#626262",
  rowActive: "#666666",
};

// Array so we can access based on surface number.
const TABLE_SURFACE_COLORS = [
  TableColors, // We shouldn't be rendering tables on surface 0.
  SURFACE_1_COLORS,
  SURFACE_2_COLORS,
  SURFACE_3_COLORS,
  SURFACE_4_COLORS,
  SURFACE_5_COLORS,
];

const Header = {
  height: 40,
  paddingLeft: 16,
  paddingRight: 16,
  paddingTop: 16,
  paddingBottom: 16,

  borderTop: defaultBorder,
  borderRight: defaultBorder,
  thickenedBorderRight: thickenedBorder,
  borderBottom: defaultBorder,
  borderLeft: "none",
};

const Cells = {
  height: 46,

  paddingTop: 16,
  paddingRight: 16,
  paddingBottom: 16,
  paddingLeft: 16,

  borderTop: "none",
  borderBottom: defaultBorder,
  borderRight: defaultBorder,
  borderLeft: "none",
  thickenedBorderRight: thickenedBorder,
};

const DefaultStyleConfiguration = currentSurface => ({
  colors:
    currentSurface >= 0 && currentSurface <= 5 ? TABLE_SURFACE_COLORS[currentSurface] : TableColors,
  header: Header,
  cells: Cells,
});

export default DefaultStyleConfiguration;
