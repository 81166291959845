import React from "react";
import _ from "lodash";

import useDidMount from "../../utils/useDidMount";

/**
 * Simple hook to handle storing the current value of a select and enforcing the change handler is
 * called as appropriate.
 *
 * Some of the more complicated logic lives in useSearch because of tight coupling
 *
 * @param {any} initialValue
 * @param {Function} onChange
 * @param {Boolean} multiple
 *
 * @returns {Array}
 * - value
 * - setValue - function to update the value
 */
const useSelectValue = (initialValue, onChange, multiple) => {
  const useDefaultValue =
    initialValue === null || initialValue === undefined || initialValue === "";
  const defaultValue = multiple ? [] : null;

  const [value, setValue] = React.useState(useDefaultValue ? defaultValue : initialValue);
  const didMount = useDidMount();

  // Handle controlled component provided value changing
  React.useEffect(() => {
    setValue(useDefaultValue ? defaultValue : initialValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  // Trigger onChange whenever value changes
  React.useEffect(() => {
    if (didMount && onChange) {
      onChange(value === undefined ? null : value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const updateValue = React.useCallback(
    updatedValue => {
      if (!multiple) {
        setValue(() => updatedValue);
        return;
      } else if (updatedValue === null) {
        // Special case to clear multi selects
        setValue([]);
        return;
      }
      // Safe even if value is an object because the reference is stable
      if (value.find(val => val === updatedValue)) {
        setValue(_.without(value, updatedValue));
      } else {
        setValue([...value, updatedValue]);
      }
    },
    [value, multiple, setValue]
  );

  return [value, updateValue];
};

export default useSelectValue;
