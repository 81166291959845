import styled, { css } from "styled-components";

import { DEFAULT_COLOR_THEME, PrimaryColorPalette } from "./StylingConstants";

const fontFamilyHeadline = '"Google Sans", "Roboto", sans-serif';
const fontFamilyBody = '"Google Sans Text", "Google Sans", "Roboto", sans-serif';

const size11 = "0.6875rem";
const size12 = "0.75rem";
const size14 = "0.875rem";
const size16 = "1rem";
const size18 = "1.125rem";
const size20 = "1.25rem";
const size24 = "1.5rem";
const size34 = "2.125rem";
const size48 = "3rem";

const weightNormal = "400";
const weightBold = "700";

const lineHeightTitle = "1.25";
const lineHeightNormal = "1.5";
const lineHeightMinimum = "1";

const letterSpacing1 = "0";
const letterSpacing2 = "0.05rem";

const headline5 = {
  "font-family": fontFamilyHeadline,
  "font-size": size48,
  "font-weight": weightNormal,
  "line-height": lineHeightTitle,
  "letter-spacing": letterSpacing1,
};

const headline4 = {
  "font-family": fontFamilyHeadline,
  "font-size": size34,
  "font-weight": weightNormal,
  "line-height": lineHeightTitle,
  "letter-spacing": letterSpacing1,
};

const headline3 = {
  "font-family": fontFamilyHeadline,
  "font-size": size24,
  "font-weight": weightNormal,
  "line-height": lineHeightTitle,
  "letter-spacing": letterSpacing1,
};

const headline2 = {
  "font-family": fontFamilyHeadline,
  "font-size": size20,
  "font-weight": weightNormal,
  "line-height": lineHeightTitle,
  "letter-spacing": letterSpacing1,
};

const headline1 = {
  "font-family": fontFamilyHeadline,
  "font-size": size18,
  "font-weight": weightNormal,
  "line-height": lineHeightTitle,
  "letter-spacing": letterSpacing1,
};

const body2 = {
  "font-family": fontFamilyBody,
  "font-size": size16,
  "font-weight": weightNormal,
  "line-height": lineHeightTitle,
  "letter-spacing": letterSpacing1,
};

const body1 = {
  "font-family": fontFamilyBody,
  "font-size": size14,
  "font-weight": weightNormal,
  "line-height": lineHeightNormal,
  "letter-spacing": letterSpacing1,
};

const caption2 = {
  "font-family": fontFamilyBody,
  "font-size": size12,
  "font-weight": weightNormal,
  "line-height": lineHeightNormal,
  "letter-spacing": letterSpacing1,
};

const caption1 = {
  "font-family": fontFamilyBody,
  "font-size": size11,
  "font-weight": weightNormal,
  "line-height": lineHeightMinimum,
  "letter-spacing": letterSpacing1,
};

const overline = {
  "font-family": fontFamilyBody,
  "font-size": size11,
  "font-weight": weightNormal,
  "line-height": lineHeightMinimum,
  "letter-spacing": letterSpacing2,
  "text-transform": "uppercase",
};

const TextModifiers = {
  transformUpper: { "text-transform": "uppercase" },

  transformLower: { "text-transform": "lowercase" },
  strikethrough: { "text-decoration": "line-through" },
  underline: { "text-decoration": "underline" },
};

const FontWeights = {
  normal: { "font-weight": weightNormal },
  bold: { "font-weight": weightBold },
};

const getFontColorByTheme = (theme, opacity = 80) => {
  // If we don't have a mode - use default which is dark
  const modeToFont = theme.mode === "light" ? "black" : "white";
  return { color: PrimaryColorPalette[`${modeToFont}${opacity}`] };
};

const getFontThemeColor = (theme, color) => {
  if (!theme?.colors?.[color]) {
    return DEFAULT_COLOR_THEME[color];
  }
  return theme.colors[color];
};

const FontColors = {
  theme15: css`
    ${props => getFontColorByTheme(props.theme, 15)}
  `,
  theme30: css`
    ${props => getFontColorByTheme(props.theme, 30)}
  `,
  theme50: css`
    ${props => getFontColorByTheme(props.theme, 50)}
  `,
  theme80: css`
    ${props => getFontColorByTheme(props.theme, 80)}
  `,
  theme100: css`
    ${props => getFontColorByTheme(props.theme, 100)}
  `,
  buy: css`
    ${props => ({ color: getFontThemeColor(props.theme, "green") })}
  `,
  sell: css`
    ${props => ({ color: getFontThemeColor(props.theme, "red") })}
  `,
  yellow: css`
    ${props => ({ color: getFontThemeColor(props.theme, "yellow") })}
  `,
  branding: css`
    ${props => ({ color: getFontThemeColor(props.theme, "branding500") })}
  `,
};

const StyledHeadline1 = styled.h1`
  ${headline1}${FontColors.theme80}
`;
const StyledHeadline2 = styled.h2`
  ${headline2}${FontColors.theme80}
`;
const StyledHeadline3 = styled.h3`
  ${headline3}${FontColors.theme80}
`;
const StyledHeadline4 = styled.h4`
  ${headline4}${FontColors.theme80}
`;
const StyledHeadline5 = styled.h5`
  ${headline5}${FontColors.theme80}
`;
const StyledBody1 = styled.span`
  ${body1}${FontColors.theme80}
`;
const StyledBody2 = styled.span`
  ${body2}${FontColors.theme80}
`;
const StyledCaption1 = styled.span`
  ${caption1}${FontColors.theme50}
`;
const StyledCaption2 = styled.span`
  ${caption2}${FontColors.theme50}
`;
const StyledOverline = styled.span`
  ${overline}${FontColors.theme30}
`;

const typographyExports = {};
Object.keys(FontColors).forEach(color => {
  typographyExports[`Headline1${color}`] = styled.span`
    ${FontColors[color]}
    ${headline1}
  `;
  typographyExports[`Headline2${color}`] = styled.span`
    ${FontColors[color]}
    ${headline2}
  `;
  typographyExports[`Headline3${color}`] = styled.span`
    ${FontColors[color]}
    ${headline3}
  `;
  typographyExports[`Headline4${color}`] = styled.span`
    ${FontColors[color]}
    ${headline4}
  `;
  typographyExports[`Headline5${color}`] = styled.span`
    ${FontColors[color]}
    ${headline5}
  `;
  typographyExports[`Body1${color}`] = styled.span`
    ${FontColors[color]}
    ${body1}
  `;
  typographyExports[`Body2${color}`] = styled.span`
    ${FontColors[color]}
    ${body2}
  `;
  typographyExports[`Caption1${color}`] = styled.span`
    ${FontColors[color]}
    ${caption1}
  `;
  typographyExports[`Caption2${color}`] = styled.span`
    ${FontColors[color]}
    ${caption2}
  `;
  typographyExports[`Overline${color}`] = styled.span`
    ${FontColors[color]}
    ${overline}
  `;
});

Object.keys(typographyExports).forEach(key => {
  typographyExports[key].displayName = `Fonts.${key}`;
});

export {
  headline1,
  headline2,
  headline3,
  headline4,
  headline5,
  body1,
  body2,
  caption1,
  caption2,
  overline,
  TextModifiers,
  FontWeights,
  FontColors,
  StyledHeadline1,
  StyledHeadline2,
  StyledHeadline3,
  StyledHeadline4,
  StyledHeadline5,
  StyledBody1,
  StyledBody2,
  StyledCaption1,
  StyledCaption2,
  StyledOverline,
  size14,
  size12,
  typographyExports as Fonts,
};
