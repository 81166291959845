/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``search.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const SearchIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M7.5 3C5.01472 3 3 5.01472 3 7.5C3 9.98528 5.01472 12 7.5 12C9.98528 12 12 9.98528 12 7.5C12 5.01472 9.98528 3 7.5 3ZM1.5 7.5C1.5 4.18629 4.18629 1.5 7.5 1.5C10.8137 1.5 13.5 4.18629 13.5 7.5C13.5 8.88652 13.0297 10.1632 12.2399 11.1792L16.2803 15.2197C16.5732 15.5126 16.5732 15.9874 16.2803 16.2803C15.9874 16.5732 15.5126 16.5732 15.2197 16.2803L11.1793 12.2399C10.1633 13.0297 8.88655 13.5 7.5 13.5C4.18629 13.5 1.5 10.8137 1.5 7.5Z"
        transform=""
      />
    </g>
  </svg>
);

SearchIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

SearchIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

SearchIcon.displayName = "SearchIcon";

export default SearchIcon;
