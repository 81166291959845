/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``info-circle.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const InfoCircleIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25ZM0.75 9C0.75 4.44365 4.44365 0.75 9 0.75C13.5563 0.75 17.25 4.44365 17.25 9C17.25 13.5563 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5563 0.75 9ZM8.25 6C8.25 5.58579 8.58579 5.25 9 5.25H9.0075C9.42171 5.25 9.7575 5.58579 9.7575 6C9.7575 6.41421 9.42171 6.75 9.0075 6.75H9C8.58579 6.75 8.25 6.41421 8.25 6ZM9 8.25C9.41421 8.25 9.75 8.58579 9.75 9V12C9.75 12.4142 9.41421 12.75 9 12.75C8.58579 12.75 8.25 12.4142 8.25 12V9C8.25 8.58579 8.58579 8.25 9 8.25Z"
        transform=""
      />
    </g>
  </svg>
);

InfoCircleIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

InfoCircleIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

InfoCircleIcon.displayName = "InfoCircleIcon";

export default InfoCircleIcon;
