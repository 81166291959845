const EMPTY_VALUE_PLACEHOLDER = "--";

const NAME_FORM_FIELD_NAME_PREFIX = "name_";
const WEIGHT_FORM_FIELD_NAME_PREFIX = "weight_";
const COMPONENT_TYPE_FIELD_NAME_PREFIX = "component_type_";
const CURRENT_VALUE_FORM_FIELD_NAME_PREFIX = "current_value_";
const INITIAL_VALUE_FORM_FIELD_NAME_PREFIX = "initial_value_";

const SECURITY_TYPE_MARKET_INDICES = "ZX Market Indices";

const VALUATION_COMPONENT_OTHER = 0;
const VALUATION_COMPONENT_ZX_INDEX_VALUE = 10;
const VALUATION_COMPONENT_ZX_INDEX_VALUE_TRAILING = 20;
const VALUATION_COMPONENT_REPORTED_MARKS = 30;
const VALUATION_COMPONENT_FUNDING_ROUND = 40;
const VALUATION_COMPONENT_PRIVATE_MARKET_INDEX_COMPARISON = 50;
const VALUATION_COMPONENT_COMPANY_COMPARISON = 60;

const COMPONENT_TYPE_MAP = {
  [VALUATION_COMPONENT_OTHER]: "Other",
  [VALUATION_COMPONENT_ZX_INDEX_VALUE]: "ZX Index Value",
  [VALUATION_COMPONENT_ZX_INDEX_VALUE_TRAILING]: "ZX Index Value Trailing",
  [VALUATION_COMPONENT_REPORTED_MARKS]: "Reported Marks",
  [VALUATION_COMPONENT_FUNDING_ROUND]: "Funding Round",
  [VALUATION_COMPONENT_PRIVATE_MARKET_INDEX_COMPARISON]:
    "Private Market Index Comparison",
  [VALUATION_COMPONENT_COMPANY_COMPARISON]: "Company Comparison",
};

const ZX_EQUAL_WEIGHTED_INDEX_SYMBOL = "ZXEQ";

export {
  EMPTY_VALUE_PLACEHOLDER,
  VALUATION_COMPONENT_OTHER,
  VALUATION_COMPONENT_ZX_INDEX_VALUE,
  VALUATION_COMPONENT_ZX_INDEX_VALUE_TRAILING,
  VALUATION_COMPONENT_REPORTED_MARKS,
  VALUATION_COMPONENT_FUNDING_ROUND,
  VALUATION_COMPONENT_PRIVATE_MARKET_INDEX_COMPARISON,
  VALUATION_COMPONENT_COMPANY_COMPARISON,
  NAME_FORM_FIELD_NAME_PREFIX,
  WEIGHT_FORM_FIELD_NAME_PREFIX,
  COMPONENT_TYPE_FIELD_NAME_PREFIX,
  CURRENT_VALUE_FORM_FIELD_NAME_PREFIX,
  INITIAL_VALUE_FORM_FIELD_NAME_PREFIX,
  SECURITY_TYPE_MARKET_INDICES,
  COMPONENT_TYPE_MAP,
  ZX_EQUAL_WEIGHTED_INDEX_SYMBOL,
};
