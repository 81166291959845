import React from "react";
import _ from "lodash";

import useDidMount from "./useDidMount";
import { RESIZE_DEBOUNCE_DELAY } from "./constants";

/**
 * Hook for tracking dimensions of an element based on provided ref.
 *
 * @returns {object} dimensions { width, height }
 */
const useElementDimensions = ref => {
  const didMount = useDidMount();
  const [dimensions, setDimensions] = React.useState({
    height: 0,
    width: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  });

  const onWindowResize = React.useCallback(() => {
    if (!ref.current) {
      setDimensions({ height: 0, width: 0 });
      return;
    }
    const dims = ref.current.getBoundingClientRect();
    setDimensions(dims);
  }, [ref]);

  const onWindowResizeDebounce = React.useMemo(
    () => _.debounce(onWindowResize, RESIZE_DEBOUNCE_DELAY),
    [onWindowResize]
  );

  React.useEffect(() => {
    // Arbitrarily checking dims here
    if (didMount) {
      onWindowResize();
    }
  }, [didMount, onWindowResize]);

  React.useEffect(() => {
    window.addEventListener("resize", onWindowResizeDebounce);
    return () => {
      window.removeEventListener("resize", onWindowResizeDebounce);
    };
  }, [onWindowResizeDebounce]);

  return dimensions;
};

export default useElementDimensions;
