import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import styled, { css } from "styled-components";

import getLinkProps, { LinkTypes } from "./getLinkProps";

import { ColorPalette } from "../StylingConstants";
import AlertDot from "./AlertDot";
import { getIconButtonThemeField, getThemeColor, useYukaTheme } from "../ThemeContext";
import { IconButtonStyles } from "./constants";

const StyledAlertDot = styled.div`
  position: absolute;
  height: 8px;
  width: 8px;

  ${props =>
    props.$buttonStyle === IconButtonStyles.RAISED
      ? css`
          right: 12px;
          top: 8px;
        `
      : css`
          right: 5px;
          top: 1px;
        `}
`;

const StyledButton = styled.button.attrs({ className: "icon-btn" })`
  // Apply selector using class name instead of & because styled components acts weird
  .btn + &,
  .icon-btn + & {
    margin-left: 12px;
  }
  position: relative;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  flex-grow: 0;
  flex-shrink: 0;
  outline: none;

  .flex-input + & {
    // align icon buttons with our inputs
    // we move up from the bottom because flex-input may have a label above, so centering
    // and positioning from the top are not reliable
    align-self: flex-end;
    margin-bottom: 4px;
  }

  // 1 - Icon
  // icon size and color set in props

  // 2 - Container
  ${props => getIconButtonThemeField(props.theme, props.$buttonStyle, "container")}
  line-height: 16px;

  // 3 - Disabled/selected state
  ${props => {
    if (props.disabled) {
      return getIconButtonThemeField(props.theme, props.$buttonStyle, "disabled");
    }
    return css`
      ${getIconButtonThemeField(
        props.theme,
        props.$buttonStyle,
        props.$selected ? "selectedDefault" : "default"
      )}

      &&:hover {
        ${getIconButtonThemeField(
          props.theme,
          props.$buttonStyle,
          props.$selected ? "selectedHover" : "hover"
        )}
      }

      &&:active {
        ${getIconButtonThemeField(
          props.theme,
          props.$buttonStyle,
          props.$selected ? "selectedActive" : "active"
        )}
      }
    `;
  }}
`;

const computeIconButtonIconColor = (yukaTheme, buttonStyle, disabled, selected) => {
  if (disabled) {
    return ColorPalette.white15;
  } else if (buttonStyle === IconButtonStyles.RAISED && selected) {
    return getThemeColor(yukaTheme, "branding500");
  }
  return ColorPalette.white50;
};

/**
 * Wrapper component for SVG icon buttons.
 *
 * to (DEPRECATED) - This prop is a string or object that react-router can parse, and if specified, results in
 *   an `<a>` tag being rendered and managed by react router. Should be used for in-app routing
 *
 * href (DEPRECATED) - This prop is a string that is a valid href that is rendered as the corresponding
 *   attribute in an `<a>` tag managed as normal by the browser. Should be used for out of app
 *   routing.
 *
 * url - This prop is a string that is a valid href or a string/object that react-router can parse.
 *   This results in either an `<a>` tag or a `<Link>` being rendered.
 *
 * Either url, to, or href must be specified for `target`, `rel`, or `download` attributes to be applied
 *
 * onClick - This prop is a function handler that results in an html `<button>` being rendered if
 *   none of the above are specified. Must be set for `type` to be applied.
 *
 * buttonStyle - Render a standard or outline IconButton.
 *   If not supplied then we render a standard IconButton.
 *
 * LinkTypes guideline:
 * - `BUTTON` - when no navigation is performed but need a text link visually
 * - `DOWNLOAD` - when the link is directly to a document to be downloaded
 * - `LOCAL_LINK` - the link navigates within a SPA
 * - `EXTERNAL_LINK` - the link navigates away from the environment, opens in a new tab
 * - `LINK` - a traditional `<a>` tag without any special behaviors
 */
const IconButton = React.forwardRef((props, ref) => {
  const yukaTheme = useYukaTheme();
  const iconColor = props.iconColor
    ? props.iconColor
    : computeIconButtonIconColor(yukaTheme, props.buttonStyle, props.disabled, props.selected);

  const legacyProps = _.pick(props, [
    "newTab",
    "accent",
    "download",
    "href",
    "to",
    "active",
    "rel",
    "target",
  ]);
  const [computedLinkProps, renderAs] = getLinkProps(props.url, props.linkType, legacyProps);

  return (
    <StyledButton
      ref={ref}
      {...computedLinkProps}
      id={props.id}
      className={props.className}
      data-testid={props.dataTestid}
      disabled={props.disabled}
      onClick={props.onClick}
      type={props.type}
      $selected={props.selected}
      $buttonStyle={props.buttonStyle}
      as={renderAs}
      role={props.role}
    >
      <props.icon size={16} color={iconColor} />
      {props.hasAlert && (
        <StyledAlertDot $buttonStyle={props.buttonStyle}>
          <AlertDot />
        </StyledAlertDot>
      )}
    </StyledButton>
  );
});

IconButton.propTypes = {
  icon: PropTypes.func.isRequired,
  iconColor: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  dataTestid: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  url: PropTypes.string,
  type: PropTypes.string,
  linkType: PropTypes.oneOf([...Object.values(LinkTypes)]),
  selected: PropTypes.bool,
  hasAlert: PropTypes.bool,
  buttonStyle: PropTypes.oneOf(Object.values(IconButtonStyles)),
  /** Aria role */
  role: PropTypes.string,
};

IconButton.defaultProps = {
  className: "",
  dataTestid: "",
  selected: false,
  disabled: false,
  href: null,
  onClick: _.noop,
  to: null,
  url: null,
  type: "button",
  iconColor: null,
  buttonStyle: IconButtonStyles.STANDARD,
};

IconButton.displayName = "IconButton";

export default IconButton;
export { IconButtonStyles };
