/**
 * Match compare string by each part in the array of search strings (split by spaces).
 *
 * @param {string} searchString
 * @param {string} compareString
 * @returns {boolean}
 */
export default (searchString, compareString) => {
  const tokenizedSearch = searchString.toLowerCase().trim().split(/\s+/);
  return tokenizedSearch.every(keyword => compareString.toLowerCase().includes(keyword));
};
