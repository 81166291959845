import PropTypes from "prop-types";
import { Button, ButtonStyles, Modal, ModalStyles, Fonts } from "yuka";

import { StyledModalButtonRow, StyledModalContent } from "./StyledComponents";
import { getPortfolioCompanyName } from "./utils";

import { API_ENDPOINTS } from "../api/constants";
import useDelete from "../api/useDelete";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";

const DeleteShareLotConfirmationModal = ({
  source,
  mode,
  shareLot,
  company,
  onClose,
}) => {
  const deleteShareLot = useDelete(API_ENDPOINTS.SHARE_LOTS());

  const companyName = getPortfolioCompanyName(company);

  return (
    <Modal modalStyle={ModalStyles.MINIMAL} onClose={onClose}>
      <StyledModalContent>
        <Fonts.Headline2theme80>Delete this share lot?</Fonts.Headline2theme80>
        <Fonts.Body1theme80>
          This share lot will be deleted from {companyName}
        </Fonts.Body1theme80>
      </StyledModalContent>
      <StyledModalButtonRow>
        <Button onClick={onClose} buttonStyle={ButtonStyles.RAISED}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            MixpanelEvents.removeShareLot(source, mode, companyName);
            deleteShareLot.mutate({ id: shareLot.apiId });
            onClose();
          }}
          buttonStyle={ButtonStyles.CTA}
        >
          Delete
        </Button>
      </StyledModalButtonRow>
    </Modal>
  );
};

DeleteShareLotConfirmationModal.propTypes = {
  source: PropTypes.string,
  mode: PropTypes.string,
  shareLot: PropTypes.shape({
    apiId: PropTypes.string.isRequired,
  }).isRequired,
  company: PropTypes.shape({
    company: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    requested_company: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DeleteShareLotConfirmationModal;
