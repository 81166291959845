import { DateTime } from "luxon";

import { API_ENDPOINTS } from "../../api/constants";
import useFetch from "../../api/useFetch";

/**
 * Similar to useLatestOrderflowForPortfolio, except operates on a single company. Queries for 1
 * year of data but only returns the 2 latest order flow reports. Querying for 1 year of data is so
 * we can make use of the cache.
 *
 * @param companyId
 */
const useLatestOrderflowForCompany = (companyId) => {
  const query = useFetch(
    API_ENDPOINTS.COMPANY_PRICE_VOLUME_ORDER_FLOW(),
    {
      company: companyId,
      end_date: DateTime.now().minus({ days: 1 }).toISODate(),
      start_date: DateTime.now().minus({ years: 1 }).toISODate(),
    },
    {
      enabled: Boolean(companyId),
    }
  );

  if (query.isLoading) {
    return [null, true];
  }

  if (!query.isSuccess) {
    return [null, false];
  }

  return [[query.cleanedData.data?.[0], query.cleanedData.data?.[1]], false];
};

export default useLatestOrderflowForCompany;
