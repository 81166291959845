import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { ButtonStyles, HyperLink, Fonts, LinkStyles, LinkTypes } from "yuka";

import { UNKNOWN_ERROR } from "./constants";
import {
  AuthButton,
  AuthFormInput,
  AuthFormInputErrorTrailingContent,
  FormContents,
} from "./StyledComponents";

import AxiosInstance from "../api/AxiosInstance";
import { API_ENDPOINTS } from "../api/constants";
import { AuthContext } from "../auth";
import { ROUTING_PATH } from "../routes/constants";
import LoadingSpinner from "../utils/LoadingSpinner";
import useLocalStorage from "../utils/useLocalStorage";
import useUnfocusOnSubmit from "../utils/useUnfocusOnSubmit";

const REMEMBER_2FA_KEY = "remember_2fa_token";

const LoginForm = ({ setCredentials }) => {
  const navigate = useNavigate();

  const [errors, setErrors] = useState({});
  const { authenticate, user } = useContext(AuthContext);
  const { getItem } = useLocalStorage();

  const onSubmit = useCallback(
    (values) => {
      const remember2faToken = getItem(REMEMBER_2FA_KEY);
      const combinedValues = {
        ...values,
        remember_2fa_token: remember2faToken,
      };
      if (!values.email || !values.password) {
        setErrors({
          submission: undefined,
          ...(values.email ? {} : { email: "Email is required" }),
          ...(values.password ? {} : { password: "Password is required" }),
        });
        return;
      }

      return AxiosInstance.post(
        API_ENDPOINTS.LOGIN(),
        JSON.stringify(combinedValues)
      )
        .then(({ data }) => {
          if (data.require_2fa) {
            // If 2FA is required, stash the credentials and switch to TokenForm
            setCredentials(values);
          } else {
            authenticate(data, true);
          }
        })
        .catch(({ response: { data } }) => {
          let errorMsg = UNKNOWN_ERROR;
          if (data) {
            errorMsg = data.detail || errorMsg;
          }
          setErrors({ submission: errorMsg });
        });
    },
    [authenticate, getItem, setCredentials]
  );
  const [wrappedOnSubmit, submitRef] = useUnfocusOnSubmit(onSubmit);

  useEffect(() => {
    if (user) navigate(ROUTING_PATH.COMPANIES());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form onSubmit={wrappedOnSubmit}>
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          {submitting && <LoadingSpinner />}
          <FormContents>
            <AuthFormInput
              id="email"
              name="email"
              placeholder="Email"
              type="text"
              $error={errors.email}
              onChange={() => setErrors({ ...errors, email: undefined })}
              trailingIcon={() =>
                errors.email ? (
                  <AuthFormInputErrorTrailingContent>
                    {errors.email}
                  </AuthFormInputErrorTrailingContent>
                ) : null
              }
            />
            <AuthFormInput
              displayIndicator={false}
              id="password"
              $error={errors.password}
              name="password"
              placeholder="Password"
              type="password"
              onChange={() => setErrors({ ...errors, password: undefined })}
              trailingIcon={() =>
                errors.password ? (
                  <AuthFormInputErrorTrailingContent>
                    {errors.password}
                  </AuthFormInputErrorTrailingContent>
                ) : null
              }
            />
            {errors.submission && (
              <Fonts.Body2sell>{errors.submission}</Fonts.Body2sell>
            )}
            <AuthButton
              ref={submitRef}
              disabled={submitting}
              buttonStyle={ButtonStyles.CTA}
              type="submit"
            >
              Log in
            </AuthButton>
            <HyperLink
              linkType={LinkTypes.LOCAL_LINK}
              linkStyle={LinkStyles.SUPPORT}
              url={ROUTING_PATH.RESET_PASSWORD()}
            >
              <Fonts.Body2theme50>Forgot your password?</Fonts.Body2theme50>
            </HyperLink>
          </FormContents>
        </form>
      )}
    </Form>
  );
};

LoginForm.propTypes = {
  setCredentials: PropTypes.func.isRequired,
};

export default LoginForm;
