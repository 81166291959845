import * as AccordionPrimitive from "@radix-ui/react-accordion";
import React from "react";
import { ChevronDownIcon, ColorPalette } from "yuka";

import cn from "../../utils/cn";

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn(
      "border-0 [&[data-state=open]]:my-4 [&[data-state=open]]:border [&[data-state=open]]:border-white30 [&[data-state=open]]:rounded-xl overflow-hidden",
      className
    )}
    {...props}
  />
));
AccordionItem.displayName = "AccordionItem";

const AccordionTrigger = React.forwardRef(
  ({ className, children, chevronId, ...props }, ref) => (
    <AccordionPrimitive.Header className="flex">
      <AccordionPrimitive.Trigger
        ref={ref}
        className={cn(
          `flex flex-1 items-center gap-4 justify-between py-6 px-4 transition-all hover:bg-surface1 [&[data-state=open]]:bg-surface1 [&>div>svg]:transition-all [&[data-state=open]>div>svg]:rotate-180`,
          className
        )}
        {...props}
      >
        {children}
        <div id={chevronId}>
          <ChevronDownIcon size={16} color={ColorPalette.white50} />
        </div>
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  )
);
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef(
  ({ className, children, ...props }, ref) => (
    <AccordionPrimitive.Content
      ref={ref}
      className="px-4 overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
      {...props}
    >
      <div className={cn("pb-4 pt-0", className)}>{children}</div>
    </AccordionPrimitive.Content>
  )
);

AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
