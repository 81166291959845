import _ from "lodash";
import { DateTime } from "luxon";

const nullOrFloat = (value) => (value === null ? null : parseFloat(value));

/**
 * Makes a timeseries array that is used to draw a line on the superchart.
 *
 * This function accepts API price data and makes an array of `dataPoint` objects:
 * { time, absolute, percent }; `time` is an ISO datestring, `absolute` is the $ value at `time`,
 * and `percent` is the % change of absolute from the first/earliest `absolute` in the array.
 *
 * @param {Array<object>} data - an array of raw price data
 * @param {string} dateKey - the name of the date field in the data elements
 * @param {string} valueKey - the name of the price value field in the data elements
 * @param {Array<string>} additionalDataKeys - names of fields that we also want in the timeseries
 * @param {object} config - optional config object
 * @returns {Array<object>} [dataPoint] - a dataPoint is a { time, absolute, percent } object
 */
const makePriceDataTimeSeries = (
  data,
  dateKey,
  valueKey,
  additionalDataKeys = [],
  { castDateTimeToDate, isVolume } = {
    castDateTimeToDate: false,
    isVolume: false,
  }
) => {
  // the last data point is the earliest in time; drop empty data until we have a real start point
  const cleanData = _.dropRightWhile(data, (datum) => !datum?.[valueKey]);

  const startValue = parseFloat(_.last(cleanData)?.[valueKey]);

  return _.reverse(
    _.map(cleanData, (datum, i) => {
      // We preserve whitespace points.
      const isWhiteSpace = _.isEqual(Object.keys(datum), [dateKey]);
      // Will conveniently only apply to public market data. We'll use this flag to ignore
      // values that would be computed as NaN.
      const isMarketClosedDay = datum?.is_market_closed_day;

      const time = castDateTimeToDate
        ? DateTime.fromISO(datum[dateKey]).toISODate()
        : datum[dateKey];

      if (isWhiteSpace || isMarketClosedDay) {
        return { time };
      }

      if (isVolume) {
        return {
          time,
          total: nullOrFloat(datum.total_volume),
          bid: nullOrFloat(datum.bid_volume),
          offer: nullOrFloat(datum.ask_volume),
          // for each additional data key, add a { key: datum[key] } property to the timeseries
          ..._.zipObject(
            additionalDataKeys,
            _.map(additionalDataKeys, (key) => datum[key])
          ),
        };
      }

      if (datum[valueKey] === null) {
        return {
          time,
          absolute: null,
          percent: null,
          ..._.zipObject(
            additionalDataKeys,
            _.map(additionalDataKeys, (key) => datum[key])
          ),
        };
      }

      return {
        time,
        absolute: parseFloat(datum[valueKey]),
        percent:
          i === cleanData.length - 1
            ? 0
            : ((parseFloat(datum[valueKey]) - startValue) / startValue) * 100,
        // for each additional data key, add a { key: datum[key] } property to the timeseries
        ..._.zipObject(
          additionalDataKeys,
          _.map(additionalDataKeys, (key) => datum[key])
        ),
      };
    })
  );
};

export default makePriceDataTimeSeries;
