import _ from "lodash";

const STORAGE_KEY = "mostRecentlyVisitedCompanies";
const QUEUE_SIZE = 5;

const getRecentlyVisitedCompanies = () =>
  !_.isNil(window.localStorage.getItem(STORAGE_KEY))
    ? JSON.parse(window.localStorage.getItem(STORAGE_KEY))
    : [];

const pushRecentlyVisitedCompanies = (company) => {
  const oldQueue = getRecentlyVisitedCompanies();
  const newCompany = [
    company.zb_id,
    company.name,
    company.main_picture,
    company.legal_name,
  ];

  if (oldQueue.length && newCompany[0] === oldQueue[0][0]) return;

  // Push company into queue, pull it out of existing queue if it exists, trim to max size
  const newQueue = [
    newCompany,
    ..._.filter(oldQueue, (c) => c[0] !== newCompany[0]).slice(
      0,
      QUEUE_SIZE - 1
    ),
  ];

  window.localStorage.setItem(STORAGE_KEY, JSON.stringify(newQueue));
};

const removeCompanyFromRecentlyVisitedCompaniesById = (companyId) => {
  const oldQueue = getRecentlyVisitedCompanies();
  const newQueue = _.filter(oldQueue, (c) => c[0] !== companyId);
  window.localStorage.setItem(STORAGE_KEY, JSON.stringify(newQueue));
};

export {
  getRecentlyVisitedCompanies,
  pushRecentlyVisitedCompanies,
  removeCompanyFromRecentlyVisitedCompaniesById,
};
