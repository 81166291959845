/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``arrow-down.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ArrowDownIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M9 3C9.41421 3 9.75 3.33579 9.75 3.75V12.4393L13.7197 8.46967C14.0126 8.17678 14.4874 8.17678 14.7803 8.46967C15.0732 8.76256 15.0732 9.23744 14.7803 9.53033L9.53033 14.7803C9.23744 15.0732 8.76256 15.0732 8.46967 14.7803L3.21967 9.53033C2.92678 9.23744 2.92678 8.76256 3.21967 8.46967C3.51256 8.17678 3.98744 8.17678 4.28033 8.46967L8.25 12.4393V3.75C8.25 3.33579 8.58579 3 9 3Z"
        transform=""
      />
    </g>
  </svg>
);

ArrowDownIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ArrowDownIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

ArrowDownIcon.displayName = "ArrowDownIcon";

export default ArrowDownIcon;
