import { YukaColorPalette, ColorPalette } from "yuka";

const EMPTY_VALUE_PLACEHOLDER = "--";
const STOCK_SPLIT = "StockSplit";

const EVENT_NAME_COLUMN_ID = "event";

const FundingTableStyles = {
  colors: {
    header: YukaColorPalette.surface0,
    row: YukaColorPalette.surface0,
  },
  header: {
    borderTop: `1px dashed ${ColorPalette.white15}`,
    borderBottom: `1px dashed ${ColorPalette.white15}`,
  },
  cells: {
    borderBottom: `1px dashed ${ColorPalette.white15}`,
  },
};

const INITIAL_TABLE_SIZE = 5;
const EMPTY_STATE_MARGIN = 84;

const TAB_MAP = {
  FUNDING_ROUNDS: "Funding rounds",
  INVESTORS: "Investors",
  COIS: "COIs",
};

export {
  STOCK_SPLIT,
  EVENT_NAME_COLUMN_ID,
  EMPTY_VALUE_PLACEHOLDER,
  FundingTableStyles,
  INITIAL_TABLE_SIZE,
  EMPTY_STATE_MARGIN,
  TAB_MAP,
};
