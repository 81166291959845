import React, { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Fonts } from "yuka";

import CompanyQuickPriceCard from "./cards/CompanyQuickPriceCard";
import EmptyCompanyCarousel from "./EmptyCompanyCarousel";
import { useCompany } from "./hooks";
import { CarouselContainer, StyledCarouselActions } from "./StyledComponents";

import { API_ENDPOINTS } from "../api/constants";
import useFetch from "../api/useFetch";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../hdYuka/Carousel";
import {
  ACTIONS,
  COMPANY_PROFILE_MIXPANEL_TARGET,
  GlobalContext,
} from "../routes/StateProvider";
import { WEEKLY } from "../utils/constants";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";

const SimilarCompanies = () => {
  const { dispatch } = useContext(GlobalContext);
  const { id: companyId } = useParams();
  const [company, companyIsLoading] = useCompany();

  const sectorsQuery = useFetch(
    API_ENDPOINTS.COMPANY_SECTORS(),
    {
      company: companyId,
    },
    {
      enabled: Boolean(companyId),
    }
  );

  const sectorIds = useMemo(() => {
    if (sectorsQuery.isLoading || sectorsQuery.isError) {
      return [];
    }
    return (sectorsQuery.cleanedData.data || []).map((sector) => sector.apiId);
  }, [sectorsQuery]);

  const similarCompaniesQuery = useFetch(
    API_ENDPOINTS.COMPANY_LATEST_ORDER_FLOW(),
    {
      time_frame: WEEKLY,
      sectors: sectorIds,
      most_active: true, // Will return only companies with a robustness score of 6 or higher.
      "page[size]": 12,
    }
  );

  const similarCompanies = useMemo(() => {
    if (!similarCompaniesQuery.isSuccess) {
      return null;
    }
    return similarCompaniesQuery.cleanedData.data
      .map((company) => company.apiId)
      .filter((id) => id !== companyId);
  }, [
    companyId,
    similarCompaniesQuery.isSuccess,
    similarCompaniesQuery.cleanedData,
  ]);

  if (companyIsLoading || !similarCompanies || similarCompanies.length === 0) {
    return <EmptyCompanyCarousel title="Similar companies" />;
  }

  return (
    <CarouselContainer>
      <Fonts.Headline1theme80>Similar companies</Fonts.Headline1theme80>
      <Carousel
        slidesToScroll={6}
        onScrollNext={() =>
          MixpanelEvents.scrollSimilarCompaniesCarousel("next", company?.name)
        }
        onScrollPrev={() =>
          MixpanelEvents.scrollSimilarCompaniesCarousel("prev", company?.name)
        }
      >
        <CarouselContent>
          {similarCompanies.map((companyId, index) => (
            <CarouselItem key={index}>
              <CompanyQuickPriceCard
                companyId={companyId}
                onClick={(similarCompanyName) => {
                  dispatch({
                    type: ACTIONS.updateMixpanelEventSource,
                    source: "similar companies carousel",
                    target: COMPANY_PROFILE_MIXPANEL_TARGET,
                  });
                  MixpanelEvents.navigateToSimilarCompany(
                    company?.name,
                    similarCompanyName
                  );
                }}
              />
            </CarouselItem>
          ))}
        </CarouselContent>
        <StyledCarouselActions>
          <CarouselPrevious />
          <CarouselNext />
        </StyledCarouselActions>
      </Carousel>
    </CarouselContainer>
  );
};

export default SimilarCompanies;
