/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``layout-right.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const LayoutRightIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M5.81903 1.5H12.181C12.7847 1.49999 13.283 1.49998 13.6889 1.53315C14.1104 1.56759 14.498 1.64151 14.862 1.82698C15.4265 2.1146 15.8854 2.57354 16.173 3.13803C16.3585 3.50203 16.4324 3.88956 16.4669 4.31113C16.5 4.71702 16.5 5.21529 16.5 5.81901V12.181C16.5 12.7847 16.5 13.283 16.4669 13.6889C16.4324 14.1104 16.3585 14.498 16.173 14.862C15.8854 15.4265 15.4265 15.8854 14.862 16.173C14.498 16.3585 14.1104 16.4324 13.6889 16.4669C13.283 16.5 12.7847 16.5 12.181 16.5H5.81901C5.21529 16.5 4.71702 16.5 4.31113 16.4669C3.88956 16.4324 3.50203 16.3585 3.13803 16.173C2.57354 15.8854 2.1146 15.4265 1.82698 14.862C1.64151 14.498 1.56759 14.1104 1.53315 13.6889C1.49998 13.283 1.49999 12.7847 1.5 12.181V5.81903C1.49999 5.2153 1.49998 4.71703 1.53315 4.31113C1.56759 3.88956 1.64151 3.50203 1.82698 3.13803C2.1146 2.57354 2.57354 2.1146 3.13803 1.82698C3.50203 1.64151 3.88956 1.56759 4.31113 1.53315C4.71703 1.49998 5.2153 1.49999 5.81903 1.5ZM12 15H12.15C12.7924 15 13.2292 14.9994 13.5667 14.9718C13.8955 14.945 14.0637 14.8963 14.181 14.8365C14.4632 14.6927 14.6927 14.4632 14.8365 14.181C14.8963 14.0637 14.945 13.8955 14.9718 13.5667C14.9994 13.2292 15 12.7924 15 12.15V5.85C15 5.20757 14.9994 4.77085 14.9718 4.43328C14.945 4.10447 14.8963 3.93631 14.8365 3.81902C14.6927 3.53677 14.4632 3.3073 14.181 3.16349C14.0637 3.10372 13.8955 3.05503 13.5667 3.02816C13.2292 3.00058 12.7924 3 12.15 3H12V15ZM10.5 3V15H5.85C5.20757 15 4.77085 14.9994 4.43328 14.9718C4.10447 14.945 3.93631 14.8963 3.81902 14.8365C3.53677 14.6927 3.3073 14.4632 3.16349 14.181C3.10372 14.0637 3.05503 13.8955 3.02816 13.5667C3.00058 13.2292 3 12.7924 3 12.15V5.85C3 5.20757 3.00058 4.77085 3.02816 4.43328C3.05503 4.10447 3.10372 3.93631 3.16349 3.81902C3.3073 3.53677 3.53677 3.3073 3.81902 3.16349C3.93631 3.10372 4.10447 3.05503 4.43328 3.02816C4.77085 3.00058 5.20757 3 5.85 3H10.5Z"
        transform=""
      />
    </g>
  </svg>
);

LayoutRightIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

LayoutRightIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

LayoutRightIcon.displayName = "LayoutRightIcon";

export default LayoutRightIcon;
