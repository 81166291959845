/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``arrow-up.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ArrowUpIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M8.46967 3.21967C8.76256 2.92678 9.23744 2.92678 9.53033 3.21967L14.7803 8.46967C15.0732 8.76256 15.0732 9.23744 14.7803 9.53033C14.4874 9.82322 14.0126 9.82322 13.7197 9.53033L9.75 5.56066V14.25C9.75 14.6642 9.41421 15 9 15C8.58579 15 8.25 14.6642 8.25 14.25V5.56066L4.28033 9.53033C3.98744 9.82322 3.51256 9.82322 3.21967 9.53033C2.92678 9.23744 2.92678 8.76256 3.21967 8.46967L8.46967 3.21967Z"
        transform=""
      />
    </g>
  </svg>
);

ArrowUpIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ArrowUpIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

ArrowUpIcon.displayName = "ArrowUpIcon";

export default ArrowUpIcon;
