import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";

import AxiosInstance from "./AxiosInstance";
import { isFileUpload, constructFormData } from "./utils";

import { useDispatch, ACTIONS } from "../routes/StateProvider";

/**
 * Wrapper around `useMutation` for POST request
 * https://tanstack.com/query/v4/docs/reference/useMutation
 *
 * @param {string} url
 * @returns {Array} see useMutation documentation for return values.
 */
const useWrite = (url, usePut = false, config = {}) => {
  const dispatch = useDispatch();
  const apiUrl = `${url}`;
  const queryClient = useQueryClient();
  const axiosFn = usePut ? AxiosInstance.put : AxiosInstance.post;

  const mutationFn = useCallback(
    (data) => {
      if (isFileUpload(data)) {
        const formData = constructFormData(data);
        // cannot stringify a FormData object as it will return empty object
        return axiosFn(apiUrl, formData);
      } else {
        return axiosFn(apiUrl, JSON.stringify(data));
      }
    },
    [apiUrl, axiosFn]
  );

  const mutation = useMutation({
    mutationFn,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [url] });
      if (config?.invalidateUrls) {
        // Useful for invalidating LIST endpoint calls when a PUT request is made to a related
        // DETAIL route.
        config.invalidateUrls.forEach(({ url: otherUrl, queryParams }) =>
          queryClient.invalidateQueries({ queryKey: [otherUrl, queryParams] })
        );
      }

      if (!config?.silent) {
        if (config?.message) {
          dispatch({ type: ACTIONS.addToast, message: config.message });
        } else {
          dispatch({
            type: ACTIONS.addToast,
            message: `Successfully ${usePut ? "updated" : "created"}`,
          });
        }
      }
    },
    onError: () => {
      if (!config?.silent) {
        dispatch({
          type: ACTIONS.addToast,
          message: "Update failed, please check your information and try again",
        });
      }
    },
  });

  return mutation;
};

export default useWrite;
