import React, { useCallback, useState } from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { ButtonStyles, HyperLink, Fonts, LinkTypes, LinkStyles } from "yuka";

import AxiosInstance from "../../api/AxiosInstance";
import { API_ENDPOINTS } from "../../api/constants";
import { ROUTING_PATH } from "../../routes/constants";
import LoadingSpinner from "../../utils/LoadingSpinner";
import { UNKNOWN_ERROR } from "../constants";
import {
  AuthButton,
  AuthFormInput,
  AuthFormInputErrorTrailingContent,
  FormContents,
  StyledFormDescription,
} from "../StyledComponents";

const ResetPasswordRequest = () => {
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const onSubmit = useCallback(
    (values) => {
      if (!values.email) {
        setErrors({
          submission: undefined,
          ...(values.email ? {} : { email: "Email is required" }),
        });
        return;
      }

      return AxiosInstance.post(
        API_ENDPOINTS.RESET_PASSWORD_SEND_EMAIL(),
        JSON.stringify(values)
      )
        .then(() => {
          navigate(ROUTING_PATH.RESET_PASSWORD_CONFIRM());
        })
        .catch(({ response: { data } }) => {
          let errorMsg = UNKNOWN_ERROR;
          if (data.email) {
            errorMsg = data.email;
          }
          setErrors({ submission: errorMsg });
        });
    },
    [navigate]
  );

  return (
    <React.Fragment>
      <StyledFormDescription>
        Please enter your email address, and we will send instructions on how to
        reset your password.
      </StyledFormDescription>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            {submitting && <LoadingSpinner />}
            <FormContents>
              <AuthFormInput
                id="email"
                $error={errors.email}
                name="email"
                placeholder="Email"
                type="text"
                onChange={() => setErrors({ ...errors, email: undefined })}
                trailingIcon={() =>
                  errors.email ? (
                    <AuthFormInputErrorTrailingContent>
                      {errors.email}
                    </AuthFormInputErrorTrailingContent>
                  ) : null
                }
              />
              {errors.submission && (
                <Fonts.Body2sell>{errors.submission}</Fonts.Body2sell>
              )}
              <AuthButton
                buttonStyle={ButtonStyles.CTA}
                disabled={submitting}
                type="submit"
              >
                Reset password
              </AuthButton>
              <HyperLink
                linkType={LinkTypes.LOCAL_LINK}
                linkStyle={LinkStyles.SUPPORT}
                url={ROUTING_PATH.LOGIN()}
              >
                <Fonts.Body2theme50>Back to Login</Fonts.Body2theme50>
              </HyperLink>
            </FormContents>
          </form>
        )}
      </Form>
    </React.Fragment>
  );
};

export default ResetPasswordRequest;
