import React from "react";
import styled from "styled-components";

import NavigationStateContext from "./NavigationStateContext";

import { overline, FontColors } from "../Typography";

const StyledNavigationCategoryHeader = styled.div`
  margin: 24px 24px 16px;
  ${overline}
  ${FontColors.theme30}
`;

const NavigationCategoryHeader = props => {
  const collapsed = React.useContext(NavigationStateContext);

  if (collapsed) {
    return null;
  }

  return <StyledNavigationCategoryHeader {...props} />;
};

export default NavigationCategoryHeader;
