// These are the column types this table allows for editable table data. If you need to use
// the standard editing controls, map your external columns to include a `column_type` parameter
// with one of these constants as the value so the table knows how to edit the cells.
const ColumnType = {
  PLAIN_TEXT: 1,
  DATE: 2,
  NUMBER: 3,
  PERCENT: 4,
  SINGLE_SELECT: 5,
  CURRENCY: 6,
};
export { ColumnType };
