/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``arrows-down.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ArrowsDownIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M5.25 2.25C5.66421 2.25 6 2.58579 6 3V13.1893L7.71967 11.4697C8.01256 11.1768 8.48744 11.1768 8.78033 11.4697C9.07322 11.7626 9.07322 12.2374 8.78033 12.5303L5.78033 15.5303C5.48744 15.8232 5.01256 15.8232 4.71967 15.5303L1.71967 12.5303C1.42678 12.2374 1.42678 11.7626 1.71967 11.4697C2.01256 11.1768 2.48744 11.1768 2.78033 11.4697L4.5 13.1893V3C4.5 2.58579 4.83579 2.25 5.25 2.25ZM12.75 2.25C13.1642 2.25 13.5 2.58579 13.5 3V9.43934L15.2197 7.71967C15.5126 7.42678 15.9874 7.42678 16.2803 7.71967C16.5732 8.01256 16.5732 8.48744 16.2803 8.78033L13.2803 11.7803C12.9874 12.0732 12.5126 12.0732 12.2197 11.7803L9.21967 8.78033C8.92678 8.48744 8.92678 8.01256 9.21967 7.71967C9.51256 7.42678 9.98744 7.42678 10.2803 7.71967L12 9.43934V3C12 2.58579 12.3358 2.25 12.75 2.25Z"
        transform=""
      />
    </g>
  </svg>
);

ArrowsDownIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ArrowsDownIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

ArrowsDownIcon.displayName = "ArrowsDownIcon";

export default ArrowsDownIcon;
