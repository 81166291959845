import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import {
  Fonts,
  HyperLink,
  LinkStyles,
  LinkTypes,
  YukaColorPalette,
} from "yuka";

import space from "../images/space.png";
import { ROUTING_PATH } from "../routes/constants";
import SiteLogo from "../utils/svgs/SiteLogo";
import applyOpacityToHex from "../utils/applyOpacityToHex";

const StyledByline = styled.div`
  margin-bottom: 48px;
`;

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-image:
    linear-gradient(180deg, transparent, ${applyOpacityToHex(
      YukaColorPalette.surface0,
      0.5
    )} 100%),
    url(${space});
  background-size: cover;
  background-position: center;

  > * {
    max-width: 600px;
  }
}
`;

const StyledFooter = styled.div`
  position: absolute;
  bottom: 50px;
  width: 600px;
  align-items: center;
  display: flex;
  justify-content: space-between;

  > :last-child {
    display: flex;
    gap: 32px;
    align-items: center;
  }
`;

const LoginBase = ({ children, bylineText }) => (
  <StyledWrapper>
    <SiteLogo size={28} />
    <StyledByline>
      <Fonts.Headline1theme50>{bylineText}</Fonts.Headline1theme50>
    </StyledByline>
    <div>{children}</div>
    <StyledFooter>
      <Fonts.Body1theme50>&copy; Zanbato, Inc. 2024</Fonts.Body1theme50>
      <Fonts.Body1theme50>
        <HyperLink
          linkStyle={LinkStyles.SUPPORT}
          linkType={LinkTypes.EXTERNAL_LINK}
          url={ROUTING_PATH.AGREEMENT("tou")}
        >
          <Fonts.Body1theme50>Terms of Use</Fonts.Body1theme50>
        </HyperLink>
        <HyperLink
          linkStyle={LinkStyles.SUPPORT}
          linkType={LinkTypes.EXTERNAL_LINK}
          url={ROUTING_PATH.AGREEMENT("privacy")}
        >
          <Fonts.Body1theme50>Privacy Policy</Fonts.Body1theme50>
        </HyperLink>
      </Fonts.Body1theme50>
    </StyledFooter>
  </StyledWrapper>
);

LoginBase.propTypes = {
  bylineText: PropTypes.string,
  children: PropTypes.node,
};

LoginBase.defaultProps = {
  bylineText: "",
  children: null,
};

export default LoginBase;
