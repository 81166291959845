import React from "react";

import { Button, ButtonStyles } from "../Button";

import WizardContext from "./internal/WizardContext";

/**
 * Renders buttons to manage progress in a wizard. Notably - submit/advance, and an alternate
 * typically used to go to the previous step.
 */
const StandardWizardActions = () => {
  const { onSubmit, submitDisabled, submitText, onClickAlt, altText } =
    React.useContext(WizardContext);

  return (
    <span>
      {onClickAlt && <Button onClick={onClickAlt}>{altText}</Button>}
      <Button disabled={submitDisabled} onClick={onSubmit} buttonStyle={ButtonStyles.CTA}>
        {submitText}
      </Button>
    </span>
  );
};

export default StandardWizardActions;
