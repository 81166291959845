import React from "react";
import { DEFAULT_CURRENCY, DEFAULT_NO_VALUE, DEFAULT_NUMBER_PRECISION } from "./constants";
import { numberFormat } from "../../utils/displayFormatUtils";
import PropTypes from "prop-types";
import { StyledTableNoValueText } from "../StyledComponents";

const CurrencyCell = ({ value, ...props }) => {
  let precision;
  const symbol = props.column.symbol || DEFAULT_CURRENCY;
  // Need to allow for 0 precision.
  if (!props.column.precision && props.column.precision !== 0) {
    precision = DEFAULT_NUMBER_PRECISION;
  } else {
    precision = props.column.precision;
  }
  if (props.column.formatter) {
    return props.column.formatter(value);
  }
  return value ? (
    `${symbol}${numberFormat(value, precision, precision)}`
  ) : (
    <StyledTableNoValueText>{DEFAULT_NO_VALUE}</StyledTableNoValueText>
  );
};

CurrencyCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  column: PropTypes.shape({
    precision: PropTypes.number,
    symbol: PropTypes.string,
    align: PropTypes.oneOf(["left", "right", "center"]),
  }).isRequired,
};

CurrencyCell.displayName = "CurrencyCell";

export default CurrencyCell;
