/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``user-circle.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const UserCircleIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 10.6659 2.85346 12.1908 3.8537 13.3681C4.54103 12.5332 5.58276 12 6.75 12H11.25C12.4172 12 13.459 12.5332 14.1463 13.3681C15.1465 12.1908 15.75 10.6659 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25ZM14.5277 15.1244C16.1994 13.6146 17.25 11.43 17.25 9C17.25 4.44365 13.5563 0.75 9 0.75C4.44365 0.75 0.75 4.44365 0.75 9C0.75 11.43 1.80059 13.6146 3.47234 15.1244C3.48104 15.1326 3.48996 15.1407 3.49909 15.1485C4.95878 16.4553 6.88658 17.25 9 17.25C11.1134 17.25 13.0412 16.4553 14.5009 15.1485C14.51 15.1407 14.519 15.1326 14.5277 15.1244ZM13.0505 14.4001C12.6395 13.8529 11.9854 13.5 11.25 13.5H6.75C6.01457 13.5 5.3605 13.8529 4.94954 14.4001C6.07776 15.2477 7.48022 15.75 9 15.75C10.5198 15.75 11.9222 15.2477 13.0505 14.4001ZM9 4.875C7.75736 4.875 6.75 5.88236 6.75 7.125C6.75 8.36764 7.75736 9.375 9 9.375C10.2426 9.375 11.25 8.36764 11.25 7.125C11.25 5.88236 10.2426 4.875 9 4.875ZM5.25 7.125C5.25 5.05393 6.92893 3.375 9 3.375C11.0711 3.375 12.75 5.05393 12.75 7.125C12.75 9.19607 11.0711 10.875 9 10.875C6.92893 10.875 5.25 9.19607 5.25 7.125Z"
        transform=""
      />
    </g>
  </svg>
);

UserCircleIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

UserCircleIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

UserCircleIcon.displayName = "UserCircleIcon";

export default UserCircleIcon;
