import PropTypes from "prop-types";
import React from "react";
import {
  ButtonStyles,
  ChevronDownIcon,
  ChevronUpIcon,
  useDropdown,
} from "yuka";

import HighlightedValueButton from "./HighlightedValueButton";

/**
 * Wrapper for HighlightedValueButton that includes a dropdown to select a value to highlight.
 * Does not manage its own value, value must be provided in props.
 *
 * @param name
 * @param value
 * @param onChange
 * @param RenderDropdown
 * @param children
 * @param props
 * @return {JSX.Element}
 */
const FilterButton = ({
  dropdownComponent: RenderDropdown,
  children,
  value,
  onChange,
  ...props
}) => {
  const [dropdownElement, dropdownRef, toggleDropdown] = useDropdown(
    (dropdownProps) => (
      <RenderDropdown
        {...dropdownProps}
        value={value}
        onChange={(newValue) => onChange(newValue)}
      />
    )
  );

  return (
    <div>
      <HighlightedValueButton
        selected={Boolean(value)}
        ref={dropdownRef}
        onClick={toggleDropdown}
        buttonStyle={ButtonStyles.RAISED}
        trailingIcon={dropdownElement ? ChevronUpIcon : ChevronDownIcon}
        highlightedValue={value}
        {...props}
      >
        {children}
      </HighlightedValueButton>
      {dropdownElement}
    </div>
  );
};

FilterButton.propTypes = {
  dropdownComponent: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
};

export default FilterButton;
