import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import InlineValidatedField from "./internal/InlineValidatedField";
import Checkbox from "./Checkbox";

import { Orientations } from "../StylingConstants";

const SingleCheckbox = styled(InlineValidatedField)`
  margin-top: 4px;
  & + & {
    margin-top: 8px;
  }
`;

const StyledCheckboxList = styled.div`
  display: flex;
  flex-direction: ${props => (props.$horizontal ? "row" : "column")};
  gap: ${props => (props.$horizontal ? "24px" : "0")};
`;

/**
 * An abstraction for a set of checkboxes for 1 field
 *
 * @param {object} props
 * @returns {Element}
 */
const MultipleCheckbox = React.forwardRef(
  // eslint-disable-next-line no-unused-vars
  (props, ref) => {
    const propsValue = Array.isArray(props.value) ? props.value : [];
    const onChange = value => {
      const prev = propsValue ?? [];
      if (prev.findIndex(val => val === value) === -1) {
        const next = [...prev, value];
        props.onChange?.(next);
      } else {
        const withoutCurrent = prev.filter(v => v !== value);
        props.onChange?.(withoutCurrent);
      }
    };
    return (
      <StyledCheckboxList $horizontal={props.orientation === Orientations.HORIZONTAL}>
        {props.options.map(({ label, value, helpText }) => (
          <SingleCheckbox
            key={label}
            input={{
              type: "checkbox",
              name: props.name,
              disabled: props.disabled,
              value: label,
              onChange: () => onChange(value),
              checked: (propsValue ?? []).includes(value),
            }}
            id={label}
            label={label}
            helpText={helpText}
            wrapLabel={props.wrapLabel}
            component={Checkbox}
          />
        ))}
      </StyledCheckboxList>
    );
  }
);

MultipleCheckbox.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  wrapLabel: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    })
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  orientation: PropTypes.oneOf([Orientations.HORIZONTAL, Orientations.VERTICAL]),
};

MultipleCheckbox.defaultProps = {
  wrapLabel: false,
  orientation: Orientations.VERTICAL,
};

export default MultipleCheckbox;
