import React from "react";
import { ButtonStyles, LinkTypes } from "yuka";

import { ROUTING_PATH } from "../../routes/constants";
import { AuthButton, StyledFormDescription } from "../StyledComponents";

const ResetPasswordConfirm = () => (
  <>
    <StyledFormDescription>
      We’ve emailed you instructions for setting your password. If an account
      exists with the email you entered, You should receive them shortly. If you
      don’t receive an email, please make sure you’ve entered the address you
      registered with, and check your spam folder.
    </StyledFormDescription>
    <AuthButton
      buttonStyle={ButtonStyles.RAISED}
      linkType={LinkTypes.LOCAL_LINK}
      url={ROUTING_PATH.LOGIN()}
    >
      Back to Login
    </AuthButton>
  </>
);

export default ResetPasswordConfirm;
