import PropTypes from "prop-types";
import { useMemo, useRef } from "react";
import { useForm, useFormState } from "react-final-form";
import styled from "styled-components";
import { PlusIcon } from "yuka";

import {
  COMPANY_SEARCH_FORM_FIELD_NAME,
  SELECTED_COMPANY_ID_FIELD_NAME,
} from "./constants";
import ShareLotFieldSet from "./ShareLotFieldSet";

import { ActionButton } from "../hdYuka";

const ShareLotsGridContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ShareLotsFieldArray = (props) => {
  const { change } = useForm();
  // Used to automatically scroll down when adding rows.
  const addShareLotButtonRef = useRef(null);

  const {
    values: {
      [SELECTED_COMPANY_ID_FIELD_NAME]: selectedCompanyId,
      [COMPANY_SEARCH_FORM_FIELD_NAME]: searchQuery,
    },
  } = useFormState();

  const disabled = useMemo(
    () => (!searchQuery && !selectedCompanyId) || props.disabled,
    [searchQuery, selectedCompanyId, props.disabled]
  );

  return (
    <ShareLotsGridContainer>
      {props.fields.map((field, index) => (
        <ShareLotFieldSet
          companyId={selectedCompanyId}
          key={`share_lot_${props.fields.value[index].tempId}`}
          disabled={disabled}
          name={field}
          includeLabels={index === 0}
          onClear={() => {
            if (props.fields.length === 1) {
              // If we only have 1 share lot, just reset the field.
              change("shareLots", [{ tempId: Math.random() }]);
            } else {
              // Otherwise, just remove the row.
              return props.fields.remove(index);
            }
          }}
        />
      ))}
      {!disabled && (
        <ActionButton
          ref={addShareLotButtonRef}
          icon={PlusIcon}
          onClick={() => {
            setTimeout(
              () =>
                addShareLotButtonRef.current.scrollIntoView({
                  behavior: "smooth",
                }),
              1
            );
            return props.fields.push({ tempId: Math.random() });
          }}
        >
          Add share lot
        </ActionButton>
      )}
    </ShareLotsGridContainer>
  );
};

ShareLotsFieldArray.propTypes = {
  disabled: PropTypes.bool,
  fields: PropTypes.shape({
    map: PropTypes.func,
    value: PropTypes.arrayOf(
      PropTypes.shape({
        tempId: PropTypes.number,
      })
    ),
    length: PropTypes.number,
    remove: PropTypes.func,
    push: PropTypes.func,
  }),
};

export default ShareLotsFieldArray;
