import React from "react";
import styled from "styled-components";
import { FontColors } from "yuka";

const TipList = styled.div`
  ${FontColors.theme50}
  margin-bottom: 24px;

  > ul {
    margin: 0;
    padding: 0;
    list-style-position: inside;

    li {
      padding-left: 14px;
    }
  }
`;

/**
 * Used in the password reset + set forms.
 *
 * @returns {React.Element}
 */
const PasswordSecurityTips = () => (
  <TipList>
    Password security tips:
    <ul>
      <li>Make sure your password is at least 8 characters long</li>
      <li>Note that passwords are case-sensitive</li>
      <li>
        Use a combination of letters, numbers and special characters to make
        your password harder to guess
      </li>
    </ul>
  </TipList>
);

export default PasswordSecurityTips;
