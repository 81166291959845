import React from "react";

/**
 * Manages state to detect if a popup of some kind is open and provides utilities to manage
 * visibility
 *
 * @param {object} ref
 *
 * @returns {Array}
 * - {boolean} if the popup is open
 * - {Function} toggle popup state
 * - {Function} close popup
 */
const useIsPopupOpen = (containerRef, popupRef) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const onOutsideClick = React.useCallback(
    event => {
      if (
        !containerRef.current?.contains(event.target) &&
        !popupRef.current?.contains(event.target) &&
        !(containerRef.current === event.target)
      ) {
        setIsOpen(false);
      }
    },
    [containerRef, popupRef]
  );

  React.useEffect(() => {
    document.addEventListener("mousedown", onOutsideClick);
    return () => {
      document.removeEventListener("mousedown", onOutsideClick);
    };
  });

  const toggleIsOpen = React.useCallback(() => setIsOpen(_isOpen => !_isOpen), []);

  return [isOpen, toggleIsOpen];
};

export default useIsPopupOpen;
