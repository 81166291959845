import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import ValuePropType from "./propTypes/ValuePropType";
import { OptionPropTypeArray } from "./propTypes/OptionPropType";

import Input from "../FormField/Input";
import useAnchoredMenu from "../Layout/useAnchoredMenu";
import usePrevious from "../utils/usePrevious";

import useSelectAsync from "./internal/useSelectAsync";
import useMenuOptions from "./internal/useMenuOptions";
import useSearch from "./internal/useSearch";
import {
  DEFAULT_LABEL_KEY,
  DEFAULT_VALUE_KEY,
  DEFAULT_ICON_KEY,
  DEFAULT_THUMBNAIL_KEY,
} from "./internal/Constants";

const inputProps = [
  "id",
  "name",
  "showError",
  "placeholder",
  "id",
  "leadingIcon",
  "trailingIcon",
  "disabled",
  "small",
  "onFocus",
  "onBlur",
];

/**
 * Presents a text input that suggests options as the user types. Also used as a "searchable" select
 * interface. Sibling to `MultiAutocomplete` which supports an interface for selecting multiple
 * values at once.
 *
 * See `Select` for more guidance on the configuration options, they use the same internals.
 *
 */
const Autocomplete = React.forwardRef((props, ref) => {
  const internalRef = React.useRef(null);
  const inputRef = ref || internalRef;

  const prevValue = usePrevious(props.value);

  const [fetchedOptions, handleFetch, loadOptionFromValue] = useSelectAsync({
    initialOptions: props.options,
    loadOptions: props.loadOptions,
    loadOptionFromValue: props.loadOptionFromValue,
    uniqBy: props.optionValueKey,
  });

  const optionsAsMenuItems = useMenuOptions({
    options: props.loadOptions ? fetchedOptions : props.options,
    sortAlphabetically: !props.noSort,
    labelKey: props.optionLabelKey,
    valueKey: props.optionValueKey,
    iconKey: props.optionIconKey,
    thumbnailKey: props.optionThumbnailKey,
  });

  // Find an option matching a provided value. Also match text on loaded options
  const optionMatchingValueOrText = optionsAsMenuItems.find(
    option =>
      option.value === props.value || option.text.toLowerCase() === props.value?.toLowerCase?.()
  );

  React.useEffect(() => {
    // Load a missing option when value is programmatically updated
    if (props.value && loadOptionFromValue && !optionMatchingValueOrText) {
      loadOptionFromValue(props.value);
    }
  }, [props.value, loadOptionFromValue, optionMatchingValueOrText]);

  const [inputValue, setInputValue] = React.useState(
    optionMatchingValueOrText?.text || props.value
  );

  const { onChange } = props;

  React.useEffect(() => {
    // When value is loaded on a delay for async - update text to match
    if (optionMatchingValueOrText) {
      if (inputValue !== optionMatchingValueOrText.text) {
        setInputValue(optionMatchingValueOrText.text);
      }
      if (props.value !== optionMatchingValueOrText.value) {
        onChange?.(optionMatchingValueOrText.value);
      }
    } else if (!props.value && props.value !== prevValue) {
      // Cleared programmatically - special case
      setInputValue("");
    }
  }, [props.value, optionMatchingValueOrText, inputValue, setInputValue, onChange, prevValue]);

  const inputOnChange = e => {
    const search = e.target.value;
    // Use the value of a matching option if we have one, otherwise just use the entered text
    const updatedValue =
      optionsAsMenuItems.find(option => option.text.toLowerCase() === search.toLowerCase())
        ?.value || search;
    props.onChange?.(updatedValue);
    setInputValue(search);
    onSearch(search);
    handleFetch(search);
    if (!dropdownContent) {
      toggleIsOpen();
    }
  };

  const selectOption = option => inputOnChange({ target: { value: option.text } });

  const [onSearch, filteredOptions] = useSearch(optionsAsMenuItems);

  const [dropdownContent, containerRef, toggleIsOpen] = useAnchoredMenu({
    menuItems: filteredOptions,
    defaultAbove: false,
    onItemClick: selectOption,
    closeOnSelect: true,
    keyHandlerRef: inputRef,
    role: "listbox",
  });

  return (
    <>
      <div ref={containerRef}>
        <Input
          ref={inputRef}
          onChange={inputOnChange}
          value={inputValue}
          role="combobox"
          {..._.pick(props, inputProps)}
        />
      </div>
      {!props.disabled && Boolean(inputValue) && filteredOptions.length > 0 && dropdownContent}
    </>
  );
});

Autocomplete.propTypes = {
  /** A class applied to the wrapper around the 'input' component of the select */
  className: PropTypes.string,
  /** Whether the select is disabled or not */
  disabled: PropTypes.bool,
  /** field name for this select */
  name: PropTypes.string.isRequired,
  /** Event handler to be called whenever the value of the select changes */
  onChange: PropTypes.func,
  /** What to display if no option is selected for the input */
  placeholder: PropTypes.string,

  // Value Props
  /** Function that returns a promise that evaluates to an array of options */
  loadOptions: PropTypes.func,
  /** Function that accepts a value and returns a promise that evaluates to an option instance */
  loadOptionFromValue: PropTypes.func,
  /** The list of options that can be selected from the input. Ungrouped */
  options: OptionPropTypeArray,
  /**
   * The key in options provided that corresponds to what value should appear in the option display
   */
  optionLabelKey: PropTypes.string,
  /**
   * The key in options provided that corresponds to what icon should appear in the option display
   */
  optionIconKey: PropTypes.string,
  /**
   * The key in options provided that corresponds to what thumbnail should appear in the option display
   */
  optionThumbnailKey: PropTypes.string,
  /**
   * The key in options provided that corresponds to the internal value stored from selecting an
   * option
   */
  optionValueKey: PropTypes.string,
  /** Allows parent component to control the value stored on the select externally */
  value: ValuePropType,

  /** If set, leaves options in the order provided. Otherwise sorts alphabetically */
  noSort: PropTypes.bool,
  showError: PropTypes.bool,
};

Autocomplete.defaultProps = {
  className: "",
  disabled: false,
  placeholder: "",
  onChange: () => {},
  loadOptions: null,
  loadOptionFromValue: null,
  options: [],
  optionThumbnailKey: DEFAULT_THUMBNAIL_KEY,
  optionValueKey: DEFAULT_VALUE_KEY,
  optionLabelKey: DEFAULT_LABEL_KEY,
  optionIconKey: DEFAULT_ICON_KEY,
  noSort: false,
  showError: false,
};

Autocomplete.displayName = "Autocomplete";

export default Autocomplete;
