/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``exchange.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ExchangeIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M3.96967 0.96967C4.26256 0.676777 4.73744 0.676777 5.03033 0.96967L6.53033 2.46967C6.82322 2.76256 6.82322 3.23744 6.53033 3.53033L5.03033 5.03033C4.73744 5.32322 4.26256 5.32322 3.96967 5.03033C3.67678 4.73744 3.67678 4.26256 3.96967 3.96967L4.16449 3.77484C3.08099 3.93683 2.25 4.87137 2.25 6C2.25 6.41421 1.91421 6.75 1.5 6.75C1.08579 6.75 0.75 6.41421 0.75 6C0.75 4.02955 2.26977 2.41405 4.20108 2.26174L3.96967 2.03033C3.67678 1.73744 3.67678 1.26256 3.96967 0.96967ZM6.91544 4.68806C7.49821 2.42386 9.55268 0.75 12 0.75C14.8995 0.75 17.25 3.10051 17.25 6C17.25 8.4473 15.5762 10.5018 13.312 11.0845C12.9109 11.1878 12.502 10.9463 12.3987 10.5452C12.2955 10.144 12.537 9.73514 12.9381 9.63189C14.5556 9.21555 15.75 7.74631 15.75 6C15.75 3.92893 14.0711 2.25 12 2.25C10.2537 2.25 8.78443 3.44438 8.3681 5.06194C8.26485 5.46308 7.85597 5.70457 7.45483 5.60133C7.05369 5.49808 6.8122 5.0892 6.91544 4.68806ZM6 8.25C3.92893 8.25 2.25 9.92893 2.25 12C2.25 14.0711 3.92893 15.75 6 15.75C8.07107 15.75 9.75 14.0711 9.75 12C9.75 9.92893 8.07107 8.25 6 8.25ZM0.75 12C0.75 9.1005 3.10051 6.75 6 6.75C8.8995 6.75 11.25 9.1005 11.25 12C11.25 14.8995 8.8995 17.25 6 17.25C3.10051 17.25 0.75 14.8995 0.75 12ZM16.5 11.25C16.9142 11.25 17.25 11.5858 17.25 12C17.25 13.9705 15.7302 15.5859 13.7989 15.7383L14.0303 15.9697C14.3232 16.2626 14.3232 16.7374 14.0303 17.0303C13.7374 17.3232 13.2626 17.3232 12.9697 17.0303L11.4697 15.5303C11.1768 15.2374 11.1768 14.7626 11.4697 14.4697L12.9697 12.9697C13.2626 12.6768 13.7374 12.6768 14.0303 12.9697C14.3232 13.2626 14.3232 13.7374 14.0303 14.0303L13.8355 14.2252C14.919 14.0632 15.75 13.1286 15.75 12C15.75 11.5858 16.0858 11.25 16.5 11.25Z"
        transform=""
      />
    </g>
  </svg>
);

ExchangeIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ExchangeIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

ExchangeIcon.displayName = "ExchangeIcon";

export default ExchangeIcon;
