/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``dots-grid.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const DotsGridIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M2.25 3.75C2.25 2.92157 2.92157 2.25 3.75 2.25C4.57843 2.25 5.25 2.92157 5.25 3.75C5.25 4.57843 4.57843 5.25 3.75 5.25C2.92157 5.25 2.25 4.57843 2.25 3.75ZM7.5 3.75C7.5 2.92157 8.17157 2.25 9 2.25C9.82843 2.25 10.5 2.92157 10.5 3.75C10.5 4.57843 9.82843 5.25 9 5.25C8.17157 5.25 7.5 4.57843 7.5 3.75ZM12.75 3.75C12.75 2.92157 13.4216 2.25 14.25 2.25C15.0784 2.25 15.75 2.92157 15.75 3.75C15.75 4.57843 15.0784 5.25 14.25 5.25C13.4216 5.25 12.75 4.57843 12.75 3.75ZM2.25 9C2.25 8.17157 2.92157 7.5 3.75 7.5C4.57843 7.5 5.25 8.17157 5.25 9C5.25 9.82843 4.57843 10.5 3.75 10.5C2.92157 10.5 2.25 9.82843 2.25 9ZM7.5 9C7.5 8.17157 8.17157 7.5 9 7.5C9.82843 7.5 10.5 8.17157 10.5 9C10.5 9.82843 9.82843 10.5 9 10.5C8.17157 10.5 7.5 9.82843 7.5 9ZM12.75 9C12.75 8.17157 13.4216 7.5 14.25 7.5C15.0784 7.5 15.75 8.17157 15.75 9C15.75 9.82843 15.0784 10.5 14.25 10.5C13.4216 10.5 12.75 9.82843 12.75 9ZM2.25 14.25C2.25 13.4216 2.92157 12.75 3.75 12.75C4.57843 12.75 5.25 13.4216 5.25 14.25C5.25 15.0784 4.57843 15.75 3.75 15.75C2.92157 15.75 2.25 15.0784 2.25 14.25ZM7.5 14.25C7.5 13.4216 8.17157 12.75 9 12.75C9.82843 12.75 10.5 13.4216 10.5 14.25C10.5 15.0784 9.82843 15.75 9 15.75C8.17157 15.75 7.5 15.0784 7.5 14.25ZM12.75 14.25C12.75 13.4216 13.4216 12.75 14.25 12.75C15.0784 12.75 15.75 13.4216 15.75 14.25C15.75 15.0784 15.0784 15.75 14.25 15.75C13.4216 15.75 12.75 15.0784 12.75 14.25Z"
        transform=""
      />
    </g>
  </svg>
);

DotsGridIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

DotsGridIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

DotsGridIcon.displayName = "DotsGridIcon";

export default DotsGridIcon;
