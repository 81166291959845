import styled from "styled-components";
import { BookmarkFilledIcon, BookmarkIcon } from "../../Icons";
import { ColorPalette } from "../../StylingConstants";
import React from "react";
import PropTypes from "prop-types";

const CursorSpan = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

/*
 * A generic toggleable icon cell. Typically implemented using the bookmark icon, or the pin icon,
 * since those two icons are shipped with a "-filled" alternative. Supersedes the older
 * BookmarkCell.
 */
const ToggleIconCell = ({ value, row, column }) => {
  const OffIcon = column.offIcon || BookmarkIcon;
  const OnIcon = column.onIcon || BookmarkFilledIcon;
  const offColor = column.offColor || ColorPalette.white50;
  const onColor = column.onColor || ColorPalette.blue400;

  return (
    <CursorSpan onClick={event => column.toggle({ row, column, value: !value, event })}>
      {value ? <OnIcon color={onColor} /> : <OffIcon color={offColor} />}
    </CursorSpan>
  );
};

ToggleIconCell.propTypes = {
  value: PropTypes.bool.isRequired,
  row: PropTypes.shape({}),
  column: PropTypes.shape({
    offColor: PropTypes.string,
    onColor: PropTypes.string,
    offIcon: PropTypes.func,
    onIcon: PropTypes.func,
    toggle: PropTypes.func.isRequired,
  }).isRequired,
};

ToggleIconCell.displayName = "ToggleIconCell";

export default ToggleIconCell;
