const isEmptyShareLot = (shareLot) =>
  !shareLot.shareQuantity &&
  shareLot.shareQuantity !== 0 &&
  !shareLot.purchaseDate &&
  !shareLot.pricePerShare &&
  shareLot.pricePerShare !== 0 &&
  shareLot.pricePerShare !== "0." &&
  !shareLot.security;

/*
 * Use in forms that need to use the <ShareLotFieldSet /> component.
 */
const validateShareLots = (shareLots) => {
  if (
    shareLots.some((shareLot) => {
      if (isEmptyShareLot(shareLot)) {
        // If all the relevant values are falsey, there are no errors, we just won't submit
        // this empty share lot to the backend.
        return false;
      }
      return !(
        shareLot.shareQuantity &&
        shareLot.shareQuantity !== 0 &&
        shareLot.purchaseDate &&
        shareLot.pricePerShare &&
        shareLot.pricePerShare !== 0 &&
        shareLot.pricePerShare !== "0." &&
        shareLot.security
      );
    })
  ) {
    return "Do not leave any share lots partially filled in";
  }
  return undefined;
};

export { isEmptyShareLot, validateShareLots };
