/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``pie-chart.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const PieChartIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M8.25 2.2912C4.87504 2.66428 2.25 5.52558 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75C10.2138 15.75 11.3527 15.4296 12.3369 14.8688L8.39324 9.44084C8.30014 9.3127 8.25 9.15838 8.25 9V2.2912ZM9.75 2.2918V8.25L15.7082 8.25C15.6379 7.62106 15.4793 7.00387 15.2362 6.41689C14.9028 5.61213 14.417 4.87964 13.8054 4.25972C13.7839 4.23786 13.7621 4.21613 13.7403 4.19456C13.1204 3.58305 12.3879 3.09715 11.5831 2.76381C10.9961 2.52068 10.3789 2.36211 9.75 2.2918ZM15.7082 9.75L10.472 9.75L13.5497 13.9862C14.148 13.4403 14.6447 12.7897 15.0143 12.0644C15.3838 11.3392 15.6182 10.5549 15.7082 9.75ZM17.25 9C17.25 13.5564 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5563 0.75 9C0.75 4.44365 4.44365 0.75 9 0.75C11.2534 0.75 13.2958 1.65345 14.7847 3.11789C14.8011 3.13398 14.8174 3.15014 14.8336 3.16637C14.8499 3.1826 14.866 3.1989 14.8821 3.21526C16.3466 4.70419 17.25 6.74659 17.25 9Z"
        transform=""
      />
    </g>
  </svg>
);

PieChartIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

PieChartIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

PieChartIcon.displayName = "PieChartIcon";

export default PieChartIcon;
