import React from "react";
import _ from "lodash";

import { TENTH_SECOND } from "./constants";
/**
 * Hook for tracking window dimensions.
 *
 * @returns {object} dimensions { width, height }
 */
const useWindowDimensions = () => {
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const onWindowResize = React.useCallback(() => {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  }, []);

  const onWindowResizeDebounce = React.useMemo(
    () => _.debounce(onWindowResize, TENTH_SECOND),
    [onWindowResize]
  );

  React.useEffect(() => {
    window.addEventListener("resize", onWindowResizeDebounce);
    return () => {
      window.removeEventListener("resize", onWindowResizeDebounce);
    };
  }, [onWindowResizeDebounce]);

  return dimensions;
};

export default useWindowDimensions;
