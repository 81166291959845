import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { ColorPalette } from "../../StylingConstants";

const StyledProgressBar = styled.div`
  width: 240px;
  height: 4px;
  border-radius: 2px;
  background: ${ColorPalette.white05};
`;

const StyledProgressMeter = styled.div`
  height: 4px;
  background: ${ColorPalette.blue500};
`;

/**
 * A simple presentational component that creates a progress indicator
 *
 * @param {object} props
 *
 * @returns {React.Element}
 */
const ProgressBar = props => (
  <StyledProgressBar>
    <StyledProgressMeter
      style={{ ...props.style, width: `${(props.progress / props.total) * 100}%` }}
    />
  </StyledProgressBar>
);

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

ProgressBar.defaultProps = {
  style: {},
};

ProgressBar.displayName = "ProgressBar";

export default ProgressBar;
