import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Surface from "./internal/Surface";

const StyledSurface = styled(Surface)`
  // Assorted helpful defaults
  min-height: 0;
  min-width: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const PageSection = React.forwardRef(({ className, children }, ref) => (
  <StyledSurface className={className} ref={ref}>
    {children}
  </StyledSurface>
));

PageSection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default PageSection;
