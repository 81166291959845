import PropTypes from "prop-types";
import React from "react";
import { useForm, useFormState } from "react-final-form";
import styled from "styled-components";
import {
  CheckIcon,
  ColorPalette,
  Fonts,
  List,
  ListItem,
  PageSection,
  XCloseIcon,
  Field,
} from "yuka";

import { DataverseColors } from "../hdYuka/constants";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";

const StyledDropdownContainer = styled(PageSection)`
  padding: 12px 0;
  margin: -16px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledSection = styled(Fonts.Overlinetheme50).attrs({ as: "div" })`
  padding: 8px 12px;
`;

const StyledListItem = styled(ListItem)`
  padding: 0 12px;
  height: 32px;
  min-height: 32px;
`;

const IconPlaceholderDiv = styled.div`
  width: 18px;
  height: 18px;
`;

const StyledHorizontalRule = styled.hr`
  border: none;
  border-bottom: 1px solid ${ColorPalette.white15};
  margin-top: 12px;
`;

const StyledCheckboxList = styled.div`
  padding: 0 12px;
  min-width: 200px;
  box-sizing: border-box;

  > * {
    height: 32px;
    display: flex;
    align-items: center;
  }
`;

const SingleSelect = ({ label, options, value, onChange, toggleIsOpen }) => (
  <StyledDropdownContainer>
    {value && (
      <div>
        <StyledListItem
          leadingIcon={XCloseIcon}
          onClick={() => {
            MixpanelEvents.resetCompanyListFilter(label, value);
            toggleIsOpen();
            onChange(null);
          }}
          text="Reset"
        />
        <StyledHorizontalRule />
      </div>
    )}
    <StyledSection>{label}</StyledSection>
    <List>
      {options.map((option) => (
        <StyledListItem
          key={option.key || option.value}
          leadingIconColor={DataverseColors.branding500}
          leadingIcon={value === option.value ? CheckIcon : IconPlaceholderDiv}
          text={option.label}
          onClick={() => {
            onChange(option.value);
            toggleIsOpen();
          }}
        />
      ))}
    </List>
  </StyledDropdownContainer>
);

SingleSelect.propTypes = {
  toggleIsOpen: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
};

SingleSelect.defaultProps = {
  value: null,
};

const StyledCheckbox = styled(Field)`
  height: 32px;

  > div {
    > span {
      color: ${ColorPalette.white80} !important;
    }
  }
`;

const MultiSelect = ({ name, label, options }) => {
  const {
    values: { [name]: value },
  } = useFormState();
  const { change } = useForm();

  return (
    <StyledDropdownContainer>
      {value?.length > 0 && (
        <div>
          <StyledListItem
            leadingIcon={XCloseIcon}
            onClick={() => change(name, [])}
            text="Reset"
          />
          <StyledHorizontalRule />
        </div>
      )}

      <StyledSection>{label}</StyledSection>
      <StyledCheckboxList>
        {options.map((option) => (
          <StyledCheckbox
            type="checkbox"
            key={option.key || option.value}
            label={option.label}
            checked={value.includes(option.value)}
            onClick={() => {
              const newValue = value.includes(option.value)
                ? value.filter((v) => v !== option.value)
                : [...value, option.value];
              change(name, newValue);
            }}
          />
        ))}
      </StyledCheckboxList>
    </StyledDropdownContainer>
  );
};

MultiSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),
};

export default SingleSelect;
export { MultiSelect };
