import React, { useMemo } from "react";
import styled from "styled-components";
import { FontColors, Fonts, HyperLink, LinkStyles, LinkTypes } from "yuka";

import MentionArticleCard from "./MentionArticleCard";

import { API_ENDPOINTS } from "../../api/constants";
import useFetch from "../../api/useFetch";
import { SurfaceZeroCard } from "../../hdYuka";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../hdYuka/Carousel";
import MixpanelEvents from "../../utils/mixpanel/MixpanelEvents";
import { useCompany } from "../hooks";
import {
  CarouselControlButtonRow,
  StyledCenteredEmptyState,
} from "../StyledComponents";

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledDisclaimer = styled(Fonts.Caption2theme30)`
  align-self: flex-end;
  > :first-child {
    ${FontColors.theme50}
  }
`;

const CompanyNewsCard = () => {
  const [company, companyIsLoading] = useCompany();

  const companyArticleQuery = useFetch(
    API_ENDPOINTS.COMPANY_ARTICLES(company?.zb_id),
    {},
    {
      enabled: Boolean(company.zb_id),
    }
  );

  const companyArticles = useMemo(() => {
    if (!companyArticleQuery.isSuccess) {
      return null;
    }
    return companyArticleQuery?.cleanedData?.results;
  }, [companyArticleQuery]);

  if (companyIsLoading || companyArticleQuery.isLoading) {
    return (
      <SurfaceZeroCard title={`News about ${company?.name}`}>
        <StyledCenteredEmptyState $margin={48}>
          Loading news...
        </StyledCenteredEmptyState>
      </SurfaceZeroCard>
    );
  }

  if (companyArticles === null || companyArticles.length === 0) {
    return (
      <SurfaceZeroCard title={`News about ${company?.name}`}>
        <StyledCenteredEmptyState $margin={48}>
          News not available
        </StyledCenteredEmptyState>
      </SurfaceZeroCard>
    );
  }

  return (
    <SurfaceZeroCard title={`News about ${company?.name}`}>
      <CardContent>
        <Carousel
          slidesToScroll={2}
          onScrollNext={() =>
            MixpanelEvents.scrollCompanyNewsCarousel("next", company?.name)
          }
          onScrollPrev={() =>
            MixpanelEvents.scrollCompanyNewsCarousel("prev", company?.name)
          }
        >
          <CarouselContent>
            {companyArticles.map((article, index) => (
              <CarouselItem key={index}>
                <MentionArticleCard
                  onClick={() =>
                    MixpanelEvents.openCompanyNewsArticle(
                      company?.name,
                      article.original_url
                    )
                  }
                  article={article}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselControlButtonRow>
            <CarouselPrevious />
            <CarouselNext />
          </CarouselControlButtonRow>
        </Carousel>
        <StyledDisclaimer>
          Convenience links for news & press releases are powered by{" "}
          <HyperLink
            linkType={LinkTypes.EXTERNAL_LINK}
            linkStyle={LinkStyles.INVISIBLE}
            url="https://mention.com/en/"
            onClick={() =>
              MixpanelEvents.navigateToMentionDotCom(
                "company news",
                company?.name
              )
            }
          >
            Mention
          </HyperLink>
        </StyledDisclaimer>
      </CardContent>
    </SurfaceZeroCard>
  );
};

export default CompanyNewsCard;
