import _ from "lodash";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button, Select } from "yuka";

import {
  ORDERING_FILTER_DEFAULT_VALUE,
  ORDERING_FILTER_NAME,
  SECTOR_FILTER_NAME,
} from "./constants";

const StyledFilterContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;

  > div {
    flex: 1;
    max-width: 280px;
  }
`;

const HeatMapFilter = (props) => {
  const resetFilters = () => {
    props.setSelectedOrdering(ORDERING_FILTER_DEFAULT_VALUE);
    props.setSelectedSector(null);
  };

  return (
    <StyledFilterContainer>
      <Select
        multiple
        name={SECTOR_FILTER_NAME}
        options={_.map(props.sectorNames, (s) => ({
          label: s,
          value: s,
        }))}
        placeholder="All Sectors"
        onChange={(val) => props.setSelectedSector(val)}
        value={props.selectedSector || null}
        noSort
      />
      <Select
        name={ORDERING_FILTER_NAME}
        placeholder="Positive to negative"
        options={[
          {
            label: "Positive to negative",
            value: "-zx_index_value_percent_change",
          },
          {
            label: "Negative to positive",
            value: "zx_index_value_percent_change",
          },
        ]}
        onChange={(val) => props.setSelectedOrdering(val)}
        value={props.selectedOrdering || ORDERING_FILTER_DEFAULT_VALUE}
        noSort
      />
      <Button
        onClick={resetFilters}
        disabled={
          (props.selectedSector === null || _.isEmpty(props.selectedSector)) &&
          props.selectedOrdering === ORDERING_FILTER_DEFAULT_VALUE
        }
      >
        Reset Filters
      </Button>
    </StyledFilterContainer>
  );
};

HeatMapFilter.propTypes = {
  sectorNames: PropTypes.arrayOf(PropTypes.string),
  selectedOrdering: PropTypes.string,
  selectedSector: PropTypes.string,
  setSelectedOrdering: PropTypes.func.isRequired,
  setSelectedSector: PropTypes.func.isRequired,
};

HeatMapFilter.defaultProps = {
  sectorNames: [],
  selectedOrdering: ORDERING_FILTER_DEFAULT_VALUE,
  selectedSector: null,
};

export default HeatMapFilter;
