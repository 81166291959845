import _ from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import styled, { css } from "styled-components";
import { ColorPalette, FontColors, body1, PrimaryColorPalette } from "yuka";

const StyledOption = styled.div`
  ${body1}
  line-height: 14px;
  ${(props) => (props.$active ? FontColors.theme100 : FontColors.theme50)}
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  height: 22px;
  padding-left: 4px;
  padding-right: 4px;
  white-space: nowrap;

  ${(props) =>
    props.$active
      ? `
    border-bottom: 2px solid ${ColorPalette.blue400};
    `
      : css`
          &:hover {
            ${FontColors.theme80}
          }
        `}

  ${(props) =>
    props.$disabled
      ? css`
          color: ${PrimaryColorPalette.white30};
          border-bottom: none;
          cursor: unset;
          &:hover {
            ${FontColors.theme30}
          }
        `
      : ""}
`;

const OptionItem = (props) => (
  <StyledOption
    $active={props.selected}
    $disabled={props.disabled}
    onClick={!props.disabled ? props.handleOptionClick : _.noop}
  >
    {props.label}
  </StyledOption>
);

OptionItem.propTypes = {
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  handleOptionClick: PropTypes.func.isRequired,
};

OptionItem.defaultProps = {
  selected: false,
  disabled: false,
};

const StyledOptionSelectList = styled.div`
  display: flex;
  gap: 24px;
`;

const StyledTitle = styled.div`
  ${body1}
  ${FontColors.theme80}

  ${(props) =>
    props.$disabled
      ? `
      color: ${PrimaryColorPalette.white30};
  `
      : ""}
`;

/**
 * This component renders a list of selectable UI options with an optional default.
 */
const OptionSelectList = (props) => {
  const { options, onOptionChange } = props;

  const [currentSelection, setCurrentSelection] = useState(props.defaultOption);

  const handleOptionClick = useCallback(
    (name) => {
      onOptionChange(name);
      setCurrentSelection(name);
    },
    [onOptionChange]
  );

  return (
    <StyledOptionSelectList>
      <StyledTitle $disabled={props.disabled}>{props.title}</StyledTitle>
      {options.map(({ label }) => (
        <OptionItem
          selected={currentSelection === label}
          handleOptionClick={() => handleOptionClick(label)}
          key={label}
          label={label}
          disabled={props.disabled}
        />
      ))}
    </StyledOptionSelectList>
  );
};

OptionSelectList.propTypes = {
  onOptionChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
    })
  ),
  title: PropTypes.string,
  defaultOption: PropTypes.string,
  disabled: PropTypes.bool,
};

OptionSelectList.defaultProps = {
  onOptionChange: _.noop,
  title: "",
  defaultOption: null,
  disabled: false,
};

export default OptionSelectList;
