import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";
import { BuildingIcon, ColorPalette } from "yuka";

const StyledThumbnailPlaceholderContainer = styled.div`
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.$background &&
    css`
      background: ${props.$background};
    `}
  border-radius: 6px;
`;

/**
 * A simple component that wraps a Yuka icon in a grey box. Typically used as a placeholder for a
 * company/security logo, or just as a more visible icon.
 *
 * @param icon
 * @return {JSX.Element}
 */
const RoundedSquareIcon = ({
  icon: Icon,
  color,
  containerSize,
  iconSize,
  backgroundColor,
  className,
}) => (
  <StyledThumbnailPlaceholderContainer
    className={className}
    $width={containerSize}
    $height={containerSize}
    $background={backgroundColor}
  >
    <Icon size={iconSize} color={color} />
  </StyledThumbnailPlaceholderContainer>
);

RoundedSquareIcon.propTypes = {
  className: PropTypes.string,
  containerSize: PropTypes.number,
  iconSize: PropTypes.number,
  icon: PropTypes.elementType,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
};

RoundedSquareIcon.defaultProps = {
  className: "",
  containerSize: 24,
  iconSize: 18,
  icon: BuildingIcon,
  backgroundColor: ColorPalette.white50,
  color: ColorPalette.black50,
};

export default RoundedSquareIcon;
