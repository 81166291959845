import _ from "lodash";
import { useState, useEffect } from "react";

import useLocalStorage from "../../utils/useLocalStorage";
import useDidMount from "../../utils/useDidMount";

/**
 * A hook to add to the top level of a form to opt into storing form values in global state.
 *
 * This allows them to be extracted via `formValueSelector` and `createFormValueSelector` selectors
 * elsewhere. Does not return anything, just injects the handlers to treat this form as a filter
 *
 * Should be used to apply filtering to frontend data outside the form context. Common use case is
 * to store the filter values from a form that controls rows displayed in a table.
 *
 * @param {string} formName - the key to identify this form within redux state
 * @param {object} config
 *  - persist: If values should persist even after unmounting. Default: true
 */
const useFilter = (
  formName,
  { formApi, onChange = null, persist = true, initialValues, values } = {}
) => {
  const localStorageId = `filter-${formName}`;
  const didMount = useDidMount();
  const [getItem, setItem] = useLocalStorage();

  const [prevValues, setPrevValues] = useState(getItem(localStorageId) || initialValues || {});

  // Store updated values in local storage
  const onChangeWithLocalState = ({ values: newValues }) => {
    const diffValues = _.pickBy(newValues, (value, field) => value !== prevValues?.[field]);
    setPrevValues(() => newValues);
    setItem(localStorageId, newValues);

    if (didMount && onChange) {
      onChange(newValues, prevValues, diffValues);
    }
  };

  // This is intended to only be run on initial render. Edge case where form name changes is treated
  // like a remount.
  useEffect(
    () => {
      // Load saved values into component state
      const diffValues = _.pickBy(prevValues, (value, field) => value !== initialValues?.[field]);
      // Passed values override persisted ones
      if (values) {
        Object.entries(values).forEach(([field, value]) => {
          diffValues[field] = value;
        });
      }
      if (values || !prevValues) {
        setItem(localStorageId, {
          ...(initialValues || {}),
          ...(prevValues || {}),
          ...(values || {}),
        });
      }
      formApi.batch(() => {
        Object.entries(diffValues).forEach(([field, value]) => {
          formApi.change(field, value);
        });
      });

      return () => {
        if (!persist) {
          setItem(localStorageId, null);
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formName]
  );

  return onChangeWithLocalState;
};

export default useFilter;
