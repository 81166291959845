import _ from "lodash";

/*
 * Specifically for creating event handlers within Table.
 */
export default (props, parameters = {}) => {
  const createEventHandler =
    handler =>
    ({ event, ...args }) => {
      event.preventDefault();
      handler({ event, ...args, ...parameters });
      event.stopPropagation();
    };

  const {
    onCellClick,
    onCellRightClick,
    // onCellDoubleClick,
  } = props;

  const eventHandlerProps = {};

  if (onCellClick && onCellClick !== _.noop) {
    eventHandlerProps.onCellClick = createEventHandler(onCellClick);
  }

  if (onCellRightClick && onCellRightClick !== _.noop) {
    eventHandlerProps.onCellRightClick = createEventHandler(onCellRightClick);
  }

  // No double click support for now; seemingly requires a new component in the tree to
  // prevent firing single clicks on every double click.
  // https://stackoverflow.com/questions/35491425/double-click-and-click-on-reactjs-component
  // if (onCellDoubleClick && onCellDoubleClick !== _.noop) {
  //   eventHandlerProps.onDoubleClick = createEventHandler(onCellDoubleClick);
  // }

  return eventHandlerProps;
};
