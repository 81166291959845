import styled from "styled-components";
import { Fonts, YukaColorPalette } from "yuka";

const LegendItemContainer = styled(Fonts.Body1theme80).attrs({ as: "div" })`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  gap: 12px;
  height: 32px;
  box-sizing: border-box;
  border-radius: 8px;
  background: ${(props) =>
    props.$isHovered ? YukaColorPalette.surface2 : YukaColorPalette.surface1};
`;

const StyledCircle = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

export { LegendItemContainer, StyledCircle };
