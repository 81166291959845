import React from "react";
import PropTypes from "prop-types";

import { CardStyles, StyledSurface } from "../../Layout/Card";

import { useRenderDynamicPosition } from "../../utils/useDynamicPosition";

const Dropdown = React.forwardRef(({ cardStyle, style, children }, ref) => {
  const render = useRenderDynamicPosition();

  const dropdown = (
    <StyledSurface elevated style={style} ref={ref} $cardStyle={cardStyle}>
      {children}
    </StyledSurface>
  );

  return render(dropdown, style);
});

Dropdown.propTypes = {
  cardStyle: PropTypes.oneOf(Object.values(CardStyles)).isRequired,
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
};

export default Dropdown;
