import { useMemo } from "react";

import { API_ENDPOINTS } from "../../api/constants";
import useFetches from "../../api/useFetches";

import { usePortfolioCompanies } from "./index";
import isRemovedPortfolioCompany from "../utils/isRemovedPortfolioCompany";

/**
 * Returns all the share lots for a portfolio, grouped by portfolio company, ordered in the same
 * order as the portfolio companies. Useful in determining overall cost basis of a portfolio.
 *
 * @param {string} portfolioId
 * @return {[Array<Array<object>>|null, boolean]} - Returns a pair of values, the first an array of
 *    share lot arrays, grouped in the same order as the companies for the portfolio. Returns null
 *    for the array instead if any of the queries are loading or encountered errors. The second
 *    is a boolean indicating the loading state of the queries.
 */
const useShareLotsForPortfolio = (portfolioId) => {
  const [portfolioCompanies, companiesLoading] =
    usePortfolioCompanies(portfolioId);

  const shareLotsQueries = useFetches(
    (portfolioCompanies || []).map((company) =>
      isRemovedPortfolioCompany(company)
        ? null
        : {
            url: API_ENDPOINTS.SHARE_LOTS(),
            queryParams: { portfolio_company: company.apiId },
          }
    )
  );

  const shareLots = useMemo(() => {
    return shareLotsQueries.isSuccess
      ? shareLotsQueries.cleanedData.map((shareLotsList) => shareLotsList.data)
      : null;
  }, [shareLotsQueries.isSuccess, shareLotsQueries.cleanedData]);

  if (companiesLoading || shareLotsQueries.isLoading) {
    return [null, true];
  }
  return [shareLots, false];
};

export default useShareLotsForPortfolio;
