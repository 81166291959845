import { DateTime } from "luxon";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  DollarIcon,
  FinalFormField,
  FormSection,
  IconButton,
  IconButtonStyles,
  TrashIcon,
} from "yuka";

import SecuritySelectField from "../FormFields/SecuritySelectField";

const StyledShareLotRow = styled(FormSection)`
  display: inline-grid;
  justify-content: flex-end;
  gap: 16px;
  grid-template-columns: ${(props) =>
    props.$clearable ? "1fr 1fr 1fr 1fr 40px" : "1fr 1fr 1fr 1fr"};
`;

const StyledQuantityField = styled(FinalFormField)`
  input {
    text-align: right;
  }
`;

const StyledIconButtonContainer = styled.div`
  align-self: flex-end;
`;

const ShareLotFieldSet = ({
  name,
  disabled,
  includeLabels,
  onClear,
  companyId,
}) => {
  return (
    <StyledShareLotRow $clearable={Boolean(onClear)} name={name}>
      <StyledQuantityField
        label={includeLabels ? "Quantity" : undefined}
        placeholder={0}
        disabled={disabled}
        name="shareQuantity"
        type="number"
      />
      <FinalFormField
        label={includeLabels ? "Purchase date" : undefined}
        placeholder="Select date"
        name="purchaseDate"
        disabled={disabled}
        type="date"
        parse={(value) => DateTime.fromJSDate(value).toISODate()}
      />
      <FinalFormField
        label={includeLabels ? "Purchase price" : undefined}
        placeholder="10.50"
        name="pricePerShare"
        disabled={disabled}
        type="number"
        leadingIcon={DollarIcon}
      />
      <SecuritySelectField
        companyId={companyId}
        includeLabel={includeLabels}
        disabled={disabled}
      />
      {onClear && (
        <StyledIconButtonContainer>
          <IconButton
            disabled={disabled}
            buttonStyle={IconButtonStyles.RAISED}
            onClick={onClear}
            icon={TrashIcon}
          />
        </StyledIconButtonContainer>
      )}
    </StyledShareLotRow>
  );
};

ShareLotFieldSet.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  includeLabels: PropTypes.bool,
  onClear: PropTypes.func,
  companyId: PropTypes.string,
};

export default ShareLotFieldSet;
