import { useCallback, useMemo } from "react";
import { useInfiniteQuery } from "react-query";
import queryString from "query-string";
import _ from "lodash";

import AxiosInstance from "./AxiosInstance";
import { KN_PAGE_QUERY_PARAM } from "./constants";
import {
  cleanJsonApiData,
  knGetNextPageParam,
  defaultQueryOptions,
} from "./utils";

/**
 * Wrapper around `useInfiniteQuery`
 * https://tanstack.com/query/v4/docs/reference/useQuery?from=reactQueryV3&original=https://react-query-v3.tanstack.com/reference/useInfiniteQuery
 *
 * @param {string} url
 * @param {string} pageQueryParam
 * @param {function} getNextPageParam
 * @param {object} queryParams
 * @param {object} options
 * @returns {Array} see useInfiniteQuery documentation for return values. Also adds `cleanedData` to the returnInfo.
 */

const useInfiniteFetch = (
  url,
  queryParams = {},
  options = {},
  pageQueryParam = KN_PAGE_QUERY_PARAM,
  getNextPageParam = knGetNextPageParam
) => {
  const queryFn = useCallback(
    ({ pageParam = 1 }) => {
      queryParams[pageQueryParam] = pageParam;
      const qs = _.isEmpty(queryParams)
        ? ""
        : `?${queryString.stringify(queryParams)}`;
      const apiUrl = `${url}${qs}`;
      return AxiosInstance.get(apiUrl).then((response) => response.data);
    },
    [queryParams, pageQueryParam, url]
  );

  const queryOptions = { ...options, ...defaultQueryOptions };

  const queryInfo = useInfiniteQuery(
    // to differentiate paginated query and regular query
    [url, queryParams, "paginated"],
    queryFn,
    {
      ...queryOptions,
      getNextPageParam,
    }
  );

  const cleanedData = useMemo(() => {
    if (!queryInfo.isLoading && queryInfo.data) {
      // Create an object composed of the links and meta from the last page, plus the data and includes from all pages.
      const currentPageNumber = queryInfo.data.pages.length;
      const currentPage = queryInfo.data.pages[currentPageNumber - 1];
      const fullData = {
        links: currentPage.links,
        meta: currentPage.meta,
        data: queryInfo.data.pages.map((page) => page.data).flat(),
        included: queryInfo.data.pages
          .map((page) => page.included || [])
          .flat(),
      };
      if (fullData.data.length) {
        return cleanJsonApiData(fullData);
      }
      return fullData;
    }
    return [];
  }, [queryInfo.isLoading, queryInfo.data]);

  const returnInfo = useMemo(
    () => ({
      ...queryInfo,
      cleanedData,
    }),
    [queryInfo, cleanedData]
  );

  return returnInfo;
};

export default useInfiniteFetch;
