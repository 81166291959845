import { DateTime } from "luxon";
import { useState, useMemo } from "react";
import styled from "styled-components";
import { Fonts, Card, PlusIcon, ClockIcon } from "yuka";

import { ActionButton } from "../../hdYuka";
import { expandedMoneyFormat } from "../../utils/displayFormatUtils";
import MixpanelEvents from "../../utils/mixpanel/MixpanelEvents";
import { useCompany, useCompanyCustomValuations } from "../hooks";
import PastValuationsModal from "../modals/PastValuationsModal";
import { ValuationCalculatorModal } from "../../ValuationCalculator";
import { StyledCenteredEmptyState } from "../StyledComponents";

const StyledValuationActions = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const StyledCustomValuations = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
`;

const StyledDataContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  row-gap: 8px;
`;

const MODAL_CREATE_VALUATION = "CREATE_VALUATION";
const MODAL_VIEW_VALUATIONS = "VIEW_VALUATIONS";

const CARD_TITLE = "Recent custom valuations";

const RecentCustomValuationsCard = () => {
  const [company, companyIsLoading] = useCompany();
  const [modalShowing, setModalShowing] = useState(null);
  const [valuations, valuationsIsLoading] = useCompanyCustomValuations(
    company?.zb_id
  );

  const latestValuation = useMemo(() => {
    if (valuations && valuations.length) {
      return valuations[0];
    }
    return {};
  }, [valuations]);

  if (companyIsLoading || valuationsIsLoading) {
    return (
      <Card title={CARD_TITLE}>
        <StyledCenteredEmptyState $margin={32}>
          Recent valuations are loading...
        </StyledCenteredEmptyState>
      </Card>
    );
  }

  const mostRecentDate = DateTime.fromISO(latestValuation.valuation_date);

  return (
    <Card title={CARD_TITLE}>
      <StyledCustomValuations>
        <StyledDataContainer>
          <Fonts.Overlinetheme50>Custom valuation</Fonts.Overlinetheme50>
          {latestValuation.computed_value ? (
            <Fonts.Headline3theme80>
              {expandedMoneyFormat(latestValuation.computed_value, 2, 2)}
            </Fonts.Headline3theme80>
          ) : (
            <Fonts.Headline3theme30>--</Fonts.Headline3theme30>
          )}
        </StyledDataContainer>
        <StyledDataContainer>
          <Fonts.Overlinetheme50>Date</Fonts.Overlinetheme50>
          {mostRecentDate.isValid ? (
            <Fonts.Headline3theme80>
              {mostRecentDate.toFormat("MM/dd/yyyy")}
            </Fonts.Headline3theme80>
          ) : (
            <Fonts.Headline3theme30>--</Fonts.Headline3theme30>
          )}
        </StyledDataContainer>
      </StyledCustomValuations>
      <StyledValuationActions>
        <ActionButton
          icon={PlusIcon}
          onClick={() => {
            MixpanelEvents.clickValuationCardNewValuation(company.name);
            setModalShowing(MODAL_CREATE_VALUATION);
          }}
        >
          New valuation
        </ActionButton>
        <ActionButton
          icon={ClockIcon}
          onClick={() => {
            MixpanelEvents.clickValuationCardViewPastValuautions(company.name);
            setModalShowing(MODAL_VIEW_VALUATIONS);
          }}
        >
          View past valuations
        </ActionButton>
      </StyledValuationActions>
      {modalShowing === MODAL_CREATE_VALUATION && (
        <ValuationCalculatorModal
          onClose={() => {
            setModalShowing(null);
          }}
          companyId={company.zb_id}
          companyName={company.name}
          previousValuations={valuations}
        />
      )}
      {modalShowing === MODAL_VIEW_VALUATIONS && (
        <PastValuationsModal
          onClose={() => setModalShowing(null)}
          valuations={valuations}
        />
      )}
    </Card>
  );
};

export default RecentCustomValuationsCard;
