import { useMemo } from "react";
import { NAME_FORM_FIELD_NAME_PREFIX } from "../constants";
import {
  componentTypeFieldName,
  currentValueFieldName,
  initialValueFieldName,
  nameFieldName,
  replaceDotWithUnderscore,
  weightFieldName,
} from "../utils";

/**
 * A helper that extracts the relevant form field values from form state and combines them
 * into an array of objects where each object represents 1 of the weighted components in the form.
 * Useful for keeping weights + values in 1 place while not constantly doing name-based lookups.

 *
 * @return {Array}
 */
const useComponents = (values) => {
  const componentIds = useMemo(
    () =>
      Object.keys(values)
        .filter((key) => key.startsWith(NAME_FORM_FIELD_NAME_PREFIX))
        .map((key) => key.replace(NAME_FORM_FIELD_NAME_PREFIX, "")),
    [values]
  );

  return useMemo(
    () =>
      componentIds.map((componentId) => ({
        id: componentId,
        name: values[nameFieldName(componentId)],
        weight: values[weightFieldName(componentId)],
        componentType: values[componentTypeFieldName(componentId)],
        initialValue: values[initialValueFieldName(componentId)],
        currentValue: values[currentValueFieldName(componentId)],
      })),
    [values, componentIds]
  );
};

/*
 * Gets a single component based on ID.
 */
const useComponent = (values, id) => {
  const components = useComponents(values);
  const foundComponent = components.find(
    (component) => component.id === replaceDotWithUnderscore(id)
  );
  if (!foundComponent) {
    return {};
  }
  return foundComponent;
};

export default useComponents;

export { useComponent };
