/**
 * Calculate the percentage gain of the private market index. Assumes values are sorted in
 * descending order by time, so the last item in the list should be the oldest value, and the first
 * item should be the most recent.
 *
 * @param privateMarketIndexValues - a list of private market index values, where the `value` key
 *    of each object represents the price per share of the index.
 * @return {number} - Positive for gain, negative for loss.
 *    i.e. 0.1 -> a 10% gain, -0.05 -> a 5% loss.
 */
const computePrivateMarketGain = (privateMarketIndexValues) => {
  if (!privateMarketIndexValues || privateMarketIndexValues.length === 0) {
    return null;
  }
  // Get the first and last items in the list, and calculate the percentage change.
  const firstValue =
    privateMarketIndexValues[privateMarketIndexValues.length - 1].value;
  const lastValue = privateMarketIndexValues[0].value;
  // If either value is missing or falsey (other than 0), return null.
  if ((!firstValue && firstValue !== 0) || (!lastValue && lastValue !== 0)) {
    return null;
  }
  return (lastValue - firstValue) / firstValue;
};

export default computePrivateMarketGain;
