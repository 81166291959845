import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { FieldPrefixContext } from "../Form/FormSection";
import TYPE_MAPPING, { DEFAULT_MAPPING } from "./fieldMapping";

// A slimmed down meta status we can pass down to reuse the main interface leveraged by final-form
const DEFAULT_META = {
  active: false,
  touched: true,
};

const INPUT_PROP_NAMES = [
  "type",
  "checked",
  "onFocus",
  "onBlur",
  "multiple",
  "defaultChecked",
  "disabled",
];

/**
 * An HTML input (or other form field) not connected to react final form
 */
const Field = React.forwardRef((props, ref) => {
  const prefix = React.useContext(FieldPrefixContext);
  const name = `${prefix}${props.name}`;
  const idProp = props.id || name;

  const [Component, Wrapper, additionalProps] = TYPE_MAPPING[props.type] || DEFAULT_MAPPING;

  const onChange = e => {
    const handler = props.handleChange || props.onChange; // eslint-disable-line react/prop-types
    if (!handler) {
      return;
    }
    if (additionalProps?.parse) {
      return handler({
        ...e,
        target: { ...e.target, value: additionalProps.parse(e.target.value) },
      });
    }
    return handler(e);
  };

  const inputProps = {
    name,
    onChange,
    value: additionalProps?.format ? additionalProps.format(props.value) : props.value,
    ..._.pick(props, INPUT_PROP_NAMES),
  };

  const subProps = _.omit(props, [
    "name",
    "onChange",
    "handleChange",
    "input",
    "component",
    ...INPUT_PROP_NAMES,
  ]);

  const meta = {
    ...DEFAULT_META,
    invalid: Boolean(props.errorMessage),
    error: props.errorMessage,
  };

  return (
    <Wrapper
      ref={ref}
      component={Component || props.component}
      input={inputProps}
      meta={meta}
      {...subProps}
      id={idProp}
    />
  );
});

Field.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  errorMessage: PropTypes.string,
  /* React component to render the underlying input if "type" is custom */
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

Field.defaultProps = {
  // eslint-disable-next-line no-undefined
  id: undefined,
  label: null,
  disabled: false,
  type: "text",
  className: "",
  onChange: _.noop,
  errorMessage: "",
};

Field.displayName = "Field";

export default Field;
