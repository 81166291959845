import PropTypes from "prop-types";

// Lightweight wrapper for a valid select value
export default PropTypes.oneOfType([
  PropTypes.object,
  PropTypes.array,
  PropTypes.string,
  PropTypes.number,
  PropTypes.bool,
]);
