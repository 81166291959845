import { useAnchoredMenu as yukaUseAnchoredMenu } from "yuka";

import useDropdownContext from "./internal/useDropdownContext";

/**
 * Utility that mimics the interface for Yuka's useAnchoredMenu hook, but behind the scenes
 * registers the toggle function with the global state manager so that it can be toggled on and off
 * when hotkeys are used throughout the site.
 *
 * @param menuConfig - Config object for menu options that will be forwarded to
 *    Yuka's useAnchoredMenu.
 */
const useAnchoredMenu = (menuConfig) => {
  const [menuElement, menuRef, toggleMenu] = yukaUseAnchoredMenu(menuConfig);
  return useDropdownContext(menuElement, menuRef, toggleMenu, menuConfig);
};

export default useAnchoredMenu;
