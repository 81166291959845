import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  StyledInput as BaseStyledInput,
  StyledInputWrapper as BaseStyledInputWrapper,
  SIZE_SMALL,
  SIZE_REGULAR,
} from "../FormField/internal/StyledComponents";
import { PrimaryColorPalette } from "../StylingConstants";
import { InputChip } from "../Button/Chip";
import useAnchoredMenu from "../Layout/useAnchoredMenu";

import ValuePropType from "./propTypes/ValuePropType";
import { OptionPropTypeArray } from "./propTypes/OptionPropType";
import useSelectAsync from "./internal/useSelectAsync";
import useSelectValue from "./internal/useSelectValue";
import useSearch from "./internal/useSearch";
import useMenuOptions from "./internal/useMenuOptions";
import useSelectValueDisplay from "./internal/useSelectValueDisplay";
import {
  DEFAULT_LABEL_KEY,
  DEFAULT_VALUE_KEY,
  DEFAULT_ICON_KEY,
  DEFAULT_THUMBNAIL_KEY,
} from "./internal/Constants";

const StyledInput = styled(BaseStyledInput)`
  width: 50px;
  flex-grow: 1;
`;

const StyledInputWrapper = styled(BaseStyledInputWrapper)`
  flex-wrap: wrap;
  height: fit-content;
  padding: 6px 11px;
  gap: 6px;
`;

/**
 * Yuka v1.0
 * A custom multi select component that renders the values in a chip list
 * This is a thin wrapper around or standard select component, only multiple is forced.
 *
 * @param {object} props
 * @returns {React.Element}
 */
const MultiAutocomplete = React.forwardRef((props, ref) => {
  const internalRef = React.useRef(null);
  const inputRef = ref || internalRef;
  const [fetchedOptions, handleFetch, loadOptionFromValue] = useSelectAsync({
    initialOptions: props.options,
    loadOptions: props.loadOptions,
    loadOptionFromValue: props.loadOptionFromValue,
    uniqBy: props.optionValueKey,
  });

  const optionsAsMenuItems = useMenuOptions({
    options: props.loadOptions ? fetchedOptions : props.options,
    sortAlphabetically: !props.noSort,
    labelKey: props.optionLabelKey,
    valueKey: props.optionValueKey,
    iconKey: props.optionIconKey,
    thumbnailKey: props.optionThumbnailKey,
  });

  const [value, setValue] = useSelectValue(props.value, props.onChange, true);
  const [valueDisplay] = useSelectValueDisplay(
    optionsAsMenuItems,
    value,
    true,
    loadOptionFromValue
  );

  const [onSearch, filteredOptions] = useSearch(optionsAsMenuItems);

  const [dropdownContent, containerRef, toggleIsOpen] = useAnchoredMenu({
    menuItems: filteredOptions,
    defaultAbove: false,
    onItemClick: item => {
      setValue(item.value);
      setInputValue("");
    },
    closeOnSelect: false,
  });

  const [inputValue, setInputValue] = React.useState(props.value);
  const inputOnChange = e => {
    setInputValue(e.target.value);
    onSearch(e.target.value);
    handleFetch(e.target.value);
    if (!dropdownContent) {
      toggleIsOpen();
    }
  };

  const size = props.small ? SIZE_SMALL : SIZE_REGULAR;
  const iconSize = props.small ? 16 : 18;
  const iconColor = props.disabled ? PrimaryColorPalette.white15 : PrimaryColorPalette.white30;

  const focusAndToggle = () => {
    inputRef.current?.focus();
    toggleIsOpen();
  };

  // If popup is not open pass through to allow tabbing through form
  const onKeyPress = event => {
    if (event.key === "Enter" || event.key === "Tab") {
      setValue(inputValue);
      setInputValue("");
      event.preventDefault();
    }
  };

  return (
    <>
      <StyledInputWrapper
        $disabled={props.disabled}
        $error={props.showError}
        $size={size}
        className={props.className}
        ref={containerRef}
        onClick={focusAndToggle}
      >
        {props.leadingIcon && <props.leadingIcon size={iconSize} color={iconColor} />}
        {valueDisplay.map(option => (
          <InputChip
            key={option.value}
            leadingIcon={option.icon}
            avatar={option.thumbnail}
            onClose={() => setValue(option.value)}
            text={option.text}
          />
        ))}
        <StyledInput
          ref={inputRef}
          onChange={inputOnChange}
          value={inputValue}
          placeholder={value.length ? "" : props.placeholder}
          name={props.name}
          disabled={props.disabled}
          onKeyPress={onKeyPress}
          role="combobox"
        />
        {props.trailingIcon && <props.trailingIcon size={iconSize} color={iconColor} />}
      </StyledInputWrapper>
      {!props.disabled && Boolean(inputValue) && filteredOptions.length > 0 && dropdownContent}
    </>
  );
});

MultiAutocomplete.propTypes = {
  /** A class applied to the wrapper around the 'input' component of the select */
  className: PropTypes.string,
  /** Whether the select is disabled or not */
  disabled: PropTypes.bool,
  /** field name for this select */
  name: PropTypes.string.isRequired,
  /** Event handler to be called whenever the value of the select changes */
  onChange: PropTypes.func,
  /** What to display if no option is selected for the input */
  placeholder: PropTypes.string,

  // Value Props
  /** Function that returns a promise that evaluates to an array of options */
  loadOptions: PropTypes.func,
  /** Function that accepts a value and returns a promise that evaluates to an option instance */
  loadOptionFromValue: PropTypes.func,
  /** The list of options that can be selected from the input. Ungrouped */
  options: OptionPropTypeArray,
  /**
   * The key in options provided that corresponds to what value should appear in the option display
   */
  optionLabelKey: PropTypes.string,
  /**
   * The key in options provided that corresponds to what icon should appear in the option display
   */
  optionIconKey: PropTypes.string,
  /**
   * The key in options provided that corresponds to what thumbnail should appear in the option display
   */
  optionThumbnailKey: PropTypes.string,
  /**
   * The key in options provided that corresponds to the internal value stored from selecting an
   * option
   */
  optionValueKey: PropTypes.string,
  /** Allows parent component to control the value stored on the select externally */
  value: ValuePropType,

  /** If set, leaves options in the order provided. Otherwise sorts alphabetically */
  noSort: PropTypes.bool,
  showError: PropTypes.bool,
  small: PropTypes.bool,
  leadingIcon: PropTypes.func,
  trailingIcon: PropTypes.func,
};

MultiAutocomplete.defaultProps = {
  className: "",
  disabled: false,
  placeholder: "",
  onChange: () => {},
  loadOptions: null,
  loadOptionFromValue: null,
  options: [],
  optionThumbnailKey: DEFAULT_THUMBNAIL_KEY,
  optionValueKey: DEFAULT_VALUE_KEY,
  optionLabelKey: DEFAULT_LABEL_KEY,
  optionIconKey: DEFAULT_ICON_KEY,
  noSort: false,
  showError: false,
  small: false,
};

MultiAutocomplete.displayName = "MultiAutocomplete";

export default MultiAutocomplete;
