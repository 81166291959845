import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import _ from "lodash";
import styled, { css } from "styled-components";
import { caption2 } from "../Typography";

import { ColorPalette, Orientations, Z_INDEXES } from "../StylingConstants";
import { ColumnType } from "./utils/constants";
import { useYukaTheme } from "../ThemeContext";
import { getThemeCellStyle, getThemeHeaderStyle } from "./StyledComponents";
import { useTooltip } from "../Tooltip";

const StyledContent = styled.div`
  /* We need to render a flex box if rendering something other than text. */
  ${props =>
    props.$isText
      ? css`
          display: inline-block;
          text-align: ${props.$align ? props.$align : "left"};
        `
      : css`
          display: flex;
          flex-direction: row;
          justify-content: ${props => {
            if (props.$align === "right") {
              return "flex-end";
            } else if (props.$align === "center") {
              return "center";
            }
            return "flex-start";
          }};
        `}
  border-radius: 4px;
  ${props =>
    props.$renderOverflow
      ? ""
      : css`
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        `}
  ${props =>
    props.$width ? `width: ${props.$width - props.$paddingLeft - props.$paddingRight}px;` : ""}
`;

const StyledCell = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;

  // Padding
  ${props => {
    if (props.$column.padding || props.$column.padding === 0) {
      // If padding is explicitly set on the column, use it for all sides.
      return css`
        padding: ${props.$column.padding}px;
      `;
    }
    return css`
      padding: ${props => getThemeCellStyle("paddingTop", props)}px
        ${props => getThemeCellStyle("paddingRight", props)}px
        ${props => getThemeCellStyle("paddingBottom", props)}px
        ${props => getThemeCellStyle("paddingLeft", props)}px;
    `;
  }}

  // Borders
  border-top: ${props => getThemeCellStyle("borderTop", props)};
  border-bottom: ${props => getThemeCellStyle("borderBottom", props)};
  border-left: ${props => getThemeCellStyle("borderLeft", props)};
  ${props => {
    if (!props.$isFinalColumn && (props.$showBorders || props.$isFinalStickyColumn)) {
      const accessor = props.$isFinalStickyColumn ? "thickenedBorderRight" : "borderRight";
      return css`
        border-right: ${props => getThemeHeaderStyle(accessor, props)};
      `;
    }
  }}

  ${props => (props.$column.width ? `width: ${props.$column.width}px;` : "")}
  ${props =>
    props.$inputError && props.$isEditingCell
      ? css`
          position: absolute;
          left: ${props.$column.totalLeft}px;
          height: 75px;
        `
      : css`
          height: ${props => getThemeCellStyle("height", props)}px;
        `}

  ${props =>
    props.$isSelected
      ? css`
          z-index: ${Z_INDEXES.zIndexVisible};
          border-radius: 4px;
          outline: 2px solid ${ColorPalette.blue400};
          outline-offset: -2px;
        `
      : ""}
`;

const StyledContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const StyledError = styled.div`
  ${caption2};
  margin-top: 16px;
  color: rgba(255, 255, 255, 0.5);
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 0;
  border: none;
  background-color: transparent;
  color: ${ColorPalette.white100};

  &:focus {
    outline: none;
  }
`;

const getEditComponentForColumn = (column, isEditingCell, inputProps) => {
  switch (column.column_type) {
    case ColumnType.PLAIN_TEXT:
    case ColumnType.NUMBER:
      return (
        <StyledContainer>
          <StyledInput
            ref={input => {
              if (input && isEditingCell) {
                input.focus();
              }
              return input;
            }}
            {...inputProps}
          />
          {inputProps.inputError && isEditingCell && (
            <StyledError>{inputProps.inputError}</StyledError>
          )}
        </StyledContainer>
      );
    default:
      return <span>edit not supported yet</span>;
  }
};

const DefaultCellRenderer = props => {
  const theme = useYukaTheme();
  // Must be a function since `props.value` can be a React component in the case of
  // IconButton cells, etc.
  const [value, setValue] = useState(() => props.value);
  const [inputError, setInputError] = useState(null);
  const containerRef = useRef(null);
  const cellRef = useRef(null);
  const { updateData, row, column, columns, value: propsValue, numberOfStickyColumns } = props;

  const confirmEdit = () => {
    if (column.column_type === ColumnType.NUMBER) {
      updateData?.(row, column, value !== "" ? Number(value) : null);
    } else {
      updateData?.(row, column, value);
    }
  };

  const onChange = e => {
    if (column.column_type !== ColumnType.NUMBER || !isNaN(Number(e.currentTarget.value))) {
      setInputError(null);
      setValue(e.currentTarget.value);
    } else {
      setInputError("Please enter a number");
    }
  };
  // Cell selection requires rows to have IDs.
  const isCellSelected =
    props.selectedCell?.row === row.original.id && props.selectedCell.col === column.id;
  const isEditingCell =
    props.editingCell?.row === row.original.id && props.editingCell?.col === column.id;
  if (!isEditingCell && inputError) {
    // Should never have an inputError if the cell is not being edited, leaving a cell is
    // acknowledgement of the error.
    setInputError(null);
  }

  // props.column.cellRenderer is guaranteed to exist because it's injected if it is undefined.
  // Note: this used to call `column.cellRenderer({ ...props, value}) : value` which I believe
  // had something to do with supporting editable cells. Might need to consider this for when/if
  // editable cells makes a return. The old code caused bugs when filtering table rows, because
  // of the slight delay of `value` vs `propsValue`.
  const cellContentFromCellRenderer =
    column.cellRenderer && !isEditingCell ? column.cellRenderer(props) : propsValue;

  // Always a string value for the cell
  let cellContentFromStringRenderer;
  if (column.cellValueStringRenderer) {
    cellContentFromStringRenderer = column.cellValueStringRenderer(props);
  } else if (typeof cellContentFromCellRenderer === "string") {
    cellContentFromStringRenderer = cellContentFromCellRenderer;
  }

  // Allow column-specified padding.
  const paddingLeft =
    column.padding || column.padding === 0 ? column.padding : theme.tableStyles.cells.paddingLeft;
  const paddingRight =
    column.padding || column.padding === 0 ? column.padding : theme.tableStyles.cells.paddingRight;

  // Determine the cell content to actually render, if the cell isn't being edited it may
  // need to call updateData to propagate changes.
  let cellContent;
  if (isEditingCell) {
    // If the cell is only used to render strings, then we need to truncate
    // them with ellipses, otherwise we should give users full control over rendering overflow.
    cellContent = (
      <StyledContent
        $isText={typeof cellContentFromCellRenderer === "string"}
        $renderOverflow={props.column.renderOverflow}
        $width={column.width}
        $paddingLeft={paddingLeft}
        $paddingRight={paddingRight}
        ref={cellRef}
      >
        {getEditComponentForColumn(column, isEditingCell, {
          value: cellContentFromCellRenderer,
          inputError,
          onChange,
          onBlur: confirmEdit,
        })}
      </StyledContent>
    );
  } else {
    // Leverages type-coercion to compare, since "0001234" == 1234
    if (updateData && propsValue != value) {
      confirmEdit();
    } else if (propsValue !== value) {
      setValue(propsValue);
      // Note for future implementation of editable cells: this used to be
      // `setValue(Number(propsValue))`, which was a bug causing infinite re-renders for non-number
      // fields, but used to "Cast to update the local value constant to remove prepended zeros"
      // according to the comments.
    }
    cellContent = (
      <StyledContent
        $renderOverflow={column.renderOverflow}
        $isText={typeof cellContentFromCellRenderer === "string"}
        $width={column.width}
        $align={column.align}
        $paddingLeft={paddingLeft}
        $paddingRight={paddingRight}
        ref={cellRef}
      >
        {typeof cellContentFromCellRenderer === "string" ? (
          <span>{cellContentFromCellRenderer}</span>
        ) : (
          cellContentFromCellRenderer
        )}
      </StyledContent>
    );
  }

  // Attach a tooltip to cells with truncated content.
  let tooltipContent = null;
  if (!isCellSelected && cellContentFromStringRenderer) {
    // We can only apply tooltips to string-type cell content.
    if (
      cellRef.current?.scrollWidth &&
      containerRef.current?.offsetWidth &&
      cellRef.current?.scrollWidth > containerRef.current?.offsetWidth - paddingLeft - paddingRight
    ) {
      tooltipContent = cellContentFromStringRenderer;
    }
  }
  const tooltip = useTooltip(cellRef, tooltipContent, {
    orientation:
      props.column.tooltip && typeof props.column.tooltip === "function"
        ? Orientations.HORIZONTAL
        : Orientations.VERTICAL,
  });

  return (
    <>
      <StyledCell
        ref={containerRef}
        $isEditingCell={isEditingCell}
        $inputError={inputError}
        $isFinalColumn={columns.length === column.index + 1}
        $isFinalStickyColumn={column.sticky && numberOfStickyColumns === column.index + 1}
        $showBorders={props.showBorders}
        $column={column}
        $isSelected={
          props.selectedCell.row &&
          props.selectedCell.col &&
          props.selectedCell?.row === props.row.original.id &&
          props.selectedCell?.col === props.column.id
        }
        onClick={
          column.onCellClick
            ? event => column.onCellClick?.({ event, isCellSelected, ...props })
            : _.noop
        }
        onContextMenu={
          column.onCellRightClick
            ? event => column.onCellRightClick?.({ event, isCellSelected, ...props })
            : _.noop
        }
        // No double click support for now; seemingly requires a new component in the tree to
        // prevent firing single clicks on every double click.
        // https://stackoverflow.com/questions/35491425/double-click-and-click-on-reactjs-component
        // onDoubleClick={column.onCellDoubleClick ? event => column.onCellDoubleClick?.({event, row, isCellSelected}) : _.noop}
      >
        {cellContent}
      </StyledCell>
      {tooltipContent ? tooltip : null}
    </>
  );
};

DefaultCellRenderer.propTypes = {
  column: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    index: PropTypes.number,
    align: PropTypes.oneOf(["left", "right", "center"]),
    sticky: PropTypes.bool,
    column_type: PropTypes.number,
    cellRenderer: PropTypes.func,
    cellValueStringRenderer: PropTypes.func,
    onCellClick: PropTypes.func,
    onCellRightClick: PropTypes.func,
    padding: PropTypes.number,
    renderOverflow: PropTypes.bool,
    tooltip: PropTypes.func,
    width: PropTypes.number.isRequired,
  }).isRequired,
  showBorders: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  numberOfStickyColumns: PropTypes.number,
  value: PropTypes.any,
  updateData: PropTypes.func,
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  }).isRequired,
  selectedCell: PropTypes.shape({
    row: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    col: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  editingCell: PropTypes.shape({
    row: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    col: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};

DefaultCellRenderer.displayName = "DefaultCellRenderer";

export default DefaultCellRenderer;
