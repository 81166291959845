import PropTypes from "prop-types";
import React from "react";

/**
 * Clickable logo in the header that goes back to the company page.
 * Using a SVG instead of a png due to issues with the Parcel URL reference
 * leading to a `Cannot use 'import.meta' outside a module` error
 *
 * @returns {React.Element}
 */
const SiteLogo = ({ size }) => (
  <svg
    width="153"
    height={size}
    viewBox="0 0 153 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M47.2786 21.5879H59.2697V25.6199H42.0006V21.5879L53.6277 6.13186H42.9736V2.09985H58.8707V6.13186L47.2786 21.5879Z"
      fill="white"
    />
    <path
      d="M70.8757 17.8358L76.1257 25.6198H81.4877L73.5497 13.8597L70.8757 17.8358Z"
      fill="white"
    />
    <path
      d="M69.1815 7.38475L65.6185 2.09973H60.2565L66.5005 11.3538L69.1815 7.38475Z"
      fill="white"
    />
    <path
      d="M65.6185 25.6198H60.2565L76.1255 2.09973H81.4875L65.6185 25.6198Z"
      fill="white"
    />
    <path
      d="M82.8877 25.6198L87.4518 2.09973H94.4378C97.4898 2.09973 99.9258 2.98873 101.753 4.77374C103.58 6.55174 104.49 9.01575 104.49 12.1518C104.49 16.2258 103.132 19.4948 100.409 21.9448C97.6858 24.3948 94.2558 25.6268 90.1118 25.6268H82.8877V25.6198ZM85.1068 23.8698H90.2798C93.7098 23.8698 96.6008 22.8198 98.9668 20.7128C101.333 18.6058 102.509 15.7848 102.509 12.2428C102.509 9.57575 101.753 7.51075 100.241 6.04074C98.7288 4.57074 96.7058 3.84274 94.1788 3.84274H89.0408L85.1068 23.8698Z"
      fill="white"
    />
    <path
      d="M112.953 26.1239C110.846 26.1239 109.159 25.4519 107.878 24.1079C106.604 22.7639 105.96 20.9859 105.96 18.7669C105.96 16.1699 106.793 13.9929 108.466 12.2289C110.132 10.4718 112.211 9.58984 114.696 9.58984C116.285 9.58984 117.657 9.96084 118.812 10.6958C119.967 11.4308 120.765 12.4669 121.213 13.7899H121.283L121.99 10.0938H123.74L120.716 25.6199H118.966L119.54 22.7989H119.47C117.811 25.0179 115.641 26.1239 112.953 26.1239ZM113.527 24.4439C115.585 24.4439 117.307 23.7299 118.686 22.2949C120.065 20.8599 120.751 19.0819 120.751 16.9539C120.751 15.2319 120.219 13.8529 119.155 12.8239C118.091 11.7948 116.649 11.2768 114.836 11.2768C112.862 11.2768 111.203 11.9978 109.845 13.4469C108.487 14.8889 107.815 16.6809 107.815 18.8089C107.815 20.5309 108.333 21.9029 109.362 22.9249C110.391 23.9469 111.784 24.4509 113.527 24.4509V24.4439Z"
      fill="white"
    />
    <path
      d="M131.237 11.578L129.319 21.392C129.249 21.77 129.221 22.12 129.221 22.435C129.221 22.904 129.375 23.289 129.676 23.576C129.977 23.87 130.411 24.01 130.971 24.01H131.944L131.643 25.69H130.565C129.599 25.69 128.843 25.424 128.283 24.885C127.723 24.346 127.443 23.618 127.443 22.701C127.443 22.344 127.485 21.917 127.576 21.427L129.494 11.585H126.232L126.533 10.108H129.76L130.971 3.89197H132.721L131.51 10.108H135.206L134.905 11.585H131.244L131.237 11.578Z"
      fill="white"
    />
    <path
      d="M141.856 26.1239C139.749 26.1239 138.062 25.4519 136.781 24.1079C135.507 22.7639 134.863 20.9859 134.863 18.7669C134.863 16.1699 135.696 13.9929 137.369 12.2289C139.035 10.4718 141.114 9.58984 143.599 9.58984C145.188 9.58984 146.56 9.96084 147.715 10.6958C148.87 11.4308 149.668 12.4669 150.116 13.7899H150.186L150.893 10.0938H152.643L149.619 25.6199H147.869L148.443 22.7989H148.373C146.714 25.0179 144.544 26.1239 141.856 26.1239ZM142.43 24.4439C144.488 24.4439 146.21 23.7299 147.589 22.2949C148.968 20.8599 149.654 19.0819 149.654 16.9539C149.654 15.2319 149.122 13.8529 148.058 12.8239C146.994 11.7948 145.552 11.2768 143.739 11.2768C141.765 11.2768 140.106 11.9978 138.748 13.4469C137.39 14.8889 136.718 16.6809 136.718 18.8089C136.718 20.5309 137.236 21.9029 138.265 22.9249C139.294 23.9469 140.687 24.4509 142.43 24.4509V24.4439Z"
      fill="white"
    />
    <circle cx="4.66717" cy="4.66717" r="4.66668" fill="white" />
    <circle cx="23.334" cy="23.3333" r="4.66668" fill="white" />
    <rect
      x="19.94"
      y="1.46045"
      width="9.33336"
      height="27.0667"
      transform="rotate(45 19.94 1.46045)"
      fill="url(#paint0_linear_6206_25103)"
    />
    <circle cx="4.66668" cy="23.3333" r="4.66668" fill="white" />
    <circle cx="23.3336" cy="4.66668" r="4.66668" fill="white" />
    <defs>
      <linearGradient
        id="paint0_linear_6206_25103"
        x1="24.9172"
        y1="1.22346"
        x2="24.3015"
        y2="27.2097"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#74CBD3" />
        <stop offset="1" stopColor="#6C7594" />
      </linearGradient>
    </defs>
  </svg>
);

SiteLogo.propTypes = {
  size: PropTypes.number,
};

SiteLogo.defaultProps = {
  size: 28,
};

const SiteLogoDataverse = ({ size }) => (
  <svg
    width="135"
    height={size}
    viewBox="0 0 135 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Dataverse logo">
      <path
        id="Vector"
        d="M123.875 19.1447V2.74585H135V5.67749H127.06V9.15881H134.263V12.0904H127.06V16.2131H135V19.1447H123.875Z"
        fill="white"
      />
      <path
        id="Vector_2"
        d="M114.488 19.5112C112.618 19.5112 111.112 19.076 109.971 18.2057C108.846 17.3354 108.109 16.068 107.76 14.4037L110.97 13.7166C111.144 14.587 111.524 15.2817 112.111 15.8008C112.713 16.32 113.513 16.5795 114.512 16.5795C115.399 16.5795 116.112 16.404 116.651 16.0528C117.19 15.6863 117.459 15.1901 117.459 14.564C117.459 14.106 117.348 13.7319 117.127 13.4418C116.921 13.1517 116.564 12.8921 116.057 12.6631C115.55 12.4188 114.852 12.1668 113.965 11.9073L112.729 11.5408C112.317 11.4186 111.865 11.2583 111.374 11.0598C110.882 10.8461 110.415 10.5636 109.971 10.2124C109.527 9.86121 109.163 9.42605 108.878 8.9069C108.608 8.38776 108.473 7.76173 108.473 7.02882C108.473 6.15849 108.695 5.37214 109.139 4.66977C109.583 3.9674 110.233 3.41008 111.088 2.99782C111.944 2.58556 112.99 2.37943 114.226 2.37943C116.033 2.37943 117.444 2.78405 118.458 3.59331C119.472 4.40256 120.082 5.50956 120.288 6.9143L117.245 7.34947C117.087 6.70817 116.762 6.19666 116.271 5.81494C115.779 5.41795 115.114 5.21945 114.274 5.21945C113.466 5.21945 112.816 5.37214 112.325 5.67752C111.833 5.9829 111.588 6.38753 111.588 6.8914C111.588 7.41054 111.825 7.84571 112.301 8.1969C112.792 8.53281 113.553 8.85346 114.583 9.15884L115.772 9.50239C116.738 9.77723 117.586 10.1208 118.315 10.533C119.044 10.93 119.615 11.4492 120.027 12.0905C120.439 12.7165 120.645 13.5181 120.645 14.4953C120.645 15.61 120.352 16.5414 119.765 17.2896C119.195 18.0225 118.442 18.5798 117.507 18.9615C116.572 19.328 115.566 19.5112 114.488 19.5112Z"
        fill="white"
      />
      <path
        id="Vector_3"
        d="M92.1172 19.1447V2.74585H99.2489C100.945 2.74585 102.3 3.20392 103.314 4.12006C104.344 5.02092 104.859 6.28825 104.859 7.92202C104.859 9.05193 104.518 10.0215 103.837 10.8308C103.171 11.6247 102.323 12.1821 101.293 12.5027L101.27 12.663L105.216 18.9615V19.1447H101.674L97.8463 12.9837H95.3027V19.1447H92.1172ZM95.3027 10.1437H99.2252C99.9542 10.1437 100.541 9.9528 100.984 9.57107C101.428 9.17408 101.65 8.60913 101.65 7.87622C101.65 7.14331 101.444 6.58599 101.032 6.20427C100.62 5.80727 100.025 5.60878 99.2489 5.60878H95.3027V10.1437Z"
        fill="white"
      />
      <path
        id="Vector_4"
        d="M77.4219 19.1447V2.74585H88.5474V5.67749H80.6074V9.15881H87.8104V12.0904H80.6074V16.2131H88.5474V19.1447H77.4219Z"
        fill="white"
      />
      <path
        id="Vector_5"
        d="M63.6603 19.1447L57.8599 2.74585H61.3069L64.6826 12.6401L65.2769 14.7243H65.467L66.0851 12.6401L70.6841 0H74.1549L67.0598 19.1447H63.6603Z"
        fill="white"
      />
      <path
        id="Vector_6"
        d="M54.2269 2.8553L60.0273 19.2541H56.5803L53.2047 9.35987L52.6103 7.27565H52.4202L51.8021 9.35987L47.2031 22H43.7323L50.8274 2.8553H54.2269Z"
        fill="white"
      />
      <path
        id="Vector_7"
        d="M35.8405 19.1447V5.67749H30.6106V2.74585H44.256V5.67749H39.026V19.1447H35.8405Z"
        fill="white"
      />
      <path
        id="Vector_8"
        d="M0 19.1446V2.74579H5.65784C8.4313 2.74579 10.5391 3.45579 11.9813 4.87581C13.4394 6.29582 14.1684 8.31896 14.1684 10.9452C14.1684 13.5715 13.4394 15.5946 11.9813 17.0146C10.5391 18.4346 8.4313 19.1446 5.65784 19.1446H0ZM3.18551 16.213H5.51521C7.27437 16.213 8.62148 15.7931 9.55652 14.9533C10.4916 14.1135 10.9591 12.7775 10.9591 10.9452C10.9591 9.11294 10.4916 7.77691 9.55652 6.93712C8.62148 6.09732 7.27437 5.67743 5.51521 5.67743H3.18551V16.213Z"
        fill="white"
      />
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8662 2.83029H25.1937L30.8979 19.0604H28.0458L23.1021 6.07619L18.5386 19.0604H15.2112L21.3908 2.83031H21.8662L21.8662 2.83029Z"
        fill="white"
      />
    </g>
  </svg>
);

SiteLogoDataverse.propTypes = {
  size: PropTypes.number,
};

SiteLogoDataverse.defaultProps = {
  size: 22,
};

const SiteLogoDataverseGray = ({ size }) => (
  <svg
    width="135"
    height={size}
    viewBox="0 0 135 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Dataverse logo">
      <path
        id="Vector"
        d="M123.875 19.1447V2.74585H135V5.67749H127.06V9.15881H134.263V12.0904H127.06V16.2131H135V19.1447H123.875Z"
        fill="white"
        fillOpacity="0.3"
      />
      <path
        id="Vector_2"
        d="M114.488 19.5112C112.618 19.5112 111.112 19.076 109.971 18.2057C108.846 17.3353 108.109 16.068 107.76 14.4037L110.97 13.7166C111.144 14.5869 111.524 15.2817 112.111 15.8008C112.713 16.3199 113.513 16.5795 114.512 16.5795C115.399 16.5795 116.112 16.4039 116.651 16.0527C117.19 15.6863 117.459 15.19 117.459 14.564C117.459 14.1059 117.348 13.7319 117.127 13.4417C116.921 13.1516 116.564 12.8921 116.057 12.663C115.55 12.4187 114.852 12.1668 113.965 11.9072L112.729 11.5408C112.317 11.4186 111.865 11.2583 111.374 11.0598C110.882 10.846 110.415 10.5636 109.971 10.2124C109.527 9.86118 109.163 9.42602 108.878 8.90687C108.608 8.38773 108.473 7.7617 108.473 7.02879C108.473 6.15846 108.695 5.37211 109.139 4.66974C109.583 3.96737 110.233 3.41005 111.088 2.99779C111.944 2.58553 112.99 2.37939 114.226 2.37939C116.033 2.37939 117.444 2.78402 118.458 3.59328C119.472 4.40253 120.082 5.50953 120.288 6.91427L117.245 7.34944C117.087 6.70814 116.762 6.19663 116.271 5.81491C115.779 5.41792 115.114 5.21942 114.274 5.21942C113.466 5.21942 112.816 5.37211 112.325 5.67749C111.833 5.98287 111.588 6.38749 111.588 6.89137C111.588 7.41051 111.825 7.84568 112.301 8.19687C112.792 8.53278 113.553 8.85343 114.583 9.15881L115.772 9.50236C116.738 9.7772 117.586 10.1208 118.315 10.533C119.044 10.93 119.615 11.4492 120.027 12.0904C120.439 12.7165 120.645 13.5181 120.645 14.4953C120.645 15.6099 120.352 16.5413 119.765 17.2895C119.195 18.0224 118.442 18.5798 117.507 18.9615C116.572 19.3279 115.566 19.5112 114.488 19.5112Z"
        fill="white"
        fillOpacity="0.3"
      />
      <path
        id="Vector_3"
        d="M92.1172 19.1447V2.74585H99.2489C100.945 2.74585 102.3 3.20392 103.314 4.12006C104.344 5.02092 104.859 6.28825 104.859 7.92202C104.859 9.05193 104.518 10.0215 103.837 10.8308C103.171 11.6247 102.323 12.1821 101.293 12.5027L101.27 12.663L105.216 18.9615V19.1447H101.674L97.8463 12.9837H95.3027V19.1447H92.1172ZM95.3027 10.1437H99.2252C99.9542 10.1437 100.541 9.9528 100.984 9.57107C101.428 9.17408 101.65 8.60913 101.65 7.87622C101.65 7.14331 101.444 6.58599 101.032 6.20427C100.62 5.80727 100.025 5.60878 99.2489 5.60878H95.3027V10.1437Z"
        fill="white"
        fillOpacity="0.3"
      />
      <path
        id="Vector_4"
        d="M77.4219 19.1447V2.74585H88.5474V5.67749H80.6074V9.15881H87.8104V12.0904H80.6074V16.2131H88.5474V19.1447H77.4219Z"
        fill="white"
        fillOpacity="0.3"
      />
      <path
        id="Vector_5"
        d="M63.6603 19.1447L57.8599 2.74585H61.3069L64.6826 12.6401L65.2769 14.7243H65.467L66.0851 12.6401L70.6841 0H74.1549L67.0598 19.1447H63.6603Z"
        fill="white"
        fillOpacity="0.3"
      />
      <path
        id="Vector_6"
        d="M54.2269 2.8553L60.0273 19.2541H56.5803L53.2047 9.35987L52.6103 7.27565H52.4202L51.8021 9.35987L47.2031 22H43.7323L50.8274 2.8553H54.2269Z"
        fill="white"
        fillOpacity="0.3"
      />
      <path
        id="Vector_7"
        d="M35.8405 19.1447V5.67749H30.6106V2.74585H44.256V5.67749H39.026V19.1447H35.8405Z"
        fill="white"
        fillOpacity="0.3"
      />
      <path
        id="Vector_8"
        d="M0 19.1447V2.74585H5.65784C8.4313 2.74585 10.5391 3.45586 11.9813 4.87587C13.4394 6.29588 14.1684 8.31902 14.1684 10.9453C14.1684 13.5715 13.4394 15.5947 11.9813 17.0147C10.5391 18.4347 8.4313 19.1447 5.65784 19.1447H0ZM3.18551 16.2131H5.51521C7.27437 16.2131 8.62148 15.7932 9.55652 14.9534C10.4916 14.1136 10.9591 12.7776 10.9591 10.9453C10.9591 9.113 10.4916 7.77697 9.55652 6.93718C8.62148 6.09738 7.27437 5.67749 5.51521 5.67749H3.18551V16.2131Z"
        fill="white"
        fillOpacity="0.3"
      />
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8662 2.83032H25.1937L30.8979 19.0604H28.0458L23.1021 6.07622L18.5386 19.0604H15.2112L21.3908 2.83034H21.8662L21.8662 2.83032Z"
        fill="white"
        fillOpacity="0.3"
      />
    </g>
  </svg>
);

SiteLogoDataverseGray.propTypes = {
  size: PropTypes.number,
};

SiteLogoDataverseGray.defaultProps = {
  size: 22,
};

const SiteLogoSmall = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="mark">
      <circle
        id="Ellipse 52"
        cx="4.33383"
        cy="4.33383"
        r="4.33334"
        fill="white"
      />
      <circle
        id="Ellipse 60"
        cx="21.6673"
        cy="21.6668"
        r="4.33334"
        fill="white"
      />
      <rect
        id="Rectangle 21"
        x="18.5158"
        y="1.3562"
        width="8.66669"
        height="25.1334"
        transform="rotate(45 18.5158 1.3562)"
        fill="url(#paint0_linear_6443_25979)"
      />
      <circle
        id="Ellipse 58"
        cx="4.33334"
        cy="21.6668"
        r="4.33334"
        fill="white"
      />
      <circle
        id="Ellipse 54"
        cx="21.6669"
        cy="4.33334"
        r="4.33334"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_6443_25979"
        x1="23.1374"
        y1="1.13614"
        x2="22.5657"
        y2="25.2662"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#74CBD3" />
        <stop offset="1" stopColor="#6C7594" />
      </linearGradient>
    </defs>
  </svg>
);

SiteLogoSmall.propTypes = {
  size: PropTypes.number,
};

SiteLogoSmall.defaultProps = {
  size: 26,
};

const SiteLogoGray = ({ size }) => (
  <svg
    height={size}
    width="120"
    viewBox="0 0 120 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.3">
      <path
        d="M37.1475 16.9619H46.569V20.1299H33.0005V16.9619L42.136 4.81791H33.765V1.6499H46.2555V4.81791L37.1475 16.9619Z"
        fill="white"
      />
      <path
        d="M55.688 14.0139L59.8131 20.1299H64.0261L57.7891 10.8899L55.688 14.0139Z"
        fill="white"
      />
      <path
        d="M54.3569 5.80241L51.5574 1.6499H47.3444L52.2504 8.92092L54.3569 5.80241Z"
        fill="white"
      />
      <path
        d="M51.5574 20.1299H47.3444L59.8129 1.6499H64.0259L51.5574 20.1299Z"
        fill="white"
      />
      <path
        d="M65.1261 20.1299L68.7121 1.6499H74.2011C76.5991 1.6499 78.5131 2.3484 79.9486 3.75091C81.3841 5.14791 82.0991 7.08392 82.0991 9.54792C82.0991 12.7489 81.0321 15.3174 78.8926 17.2424C76.7531 19.1674 74.0581 20.1354 70.8021 20.1354H65.1261V20.1299ZM66.8696 18.7549H70.9341C73.6291 18.7549 75.9006 17.9299 77.7596 16.2744C79.6186 14.6189 80.5426 12.4024 80.5426 9.61942C80.5426 7.52392 79.9486 5.90141 78.7606 4.74641C77.5726 3.59141 75.9831 3.01941 73.9976 3.01941H69.9606L66.8696 18.7549Z"
        fill="white"
      />
      <path
        d="M88.7487 20.5259C87.0932 20.5259 85.7677 19.9979 84.7612 18.9419C83.7602 17.8859 83.2542 16.4889 83.2542 14.7454C83.2542 12.7049 83.9086 10.9944 85.2232 9.60842C86.5322 8.22791 88.1657 7.53491 90.1182 7.53491C91.3667 7.53491 92.4447 7.82641 93.3522 8.40391C94.2597 8.98142 94.8867 9.79542 95.2387 10.8349H95.2937L95.8492 7.93091H97.2242L94.8482 20.1299H93.4732L93.9242 17.9134H93.8692C92.5657 19.6569 90.8607 20.5259 88.7487 20.5259ZM89.1997 19.2059C90.8167 19.2059 92.1697 18.6449 93.2532 17.5174C94.3367 16.3899 94.8757 14.9929 94.8757 13.3209C94.8757 11.9679 94.4577 10.8844 93.6217 10.0759C92.7857 9.26742 91.6527 8.86042 90.2282 8.86042C88.6772 8.86042 87.3737 9.42692 86.3067 10.5654C85.2397 11.6984 84.7117 13.1064 84.7117 14.7784C84.7117 16.1314 85.1187 17.2094 85.9272 18.0124C86.7357 18.8154 87.8302 19.2114 89.1997 19.2114V19.2059Z"
        fill="white"
      />
      <path
        d="M103.115 9.09712L101.608 16.8081C101.553 17.1051 101.531 17.3801 101.531 17.6276C101.531 17.9961 101.652 18.2986 101.888 18.5241C102.125 18.7551 102.466 18.8651 102.906 18.8651H103.67L103.434 20.1852H102.587C101.828 20.1852 101.234 19.9761 100.794 19.5526C100.354 19.1291 100.134 18.5571 100.134 17.8366C100.134 17.5561 100.167 17.2206 100.238 16.8356L101.745 9.10262H99.1822L99.4187 7.94212H101.954L102.906 3.05811H104.281L103.329 7.94212H106.233L105.997 9.10262H103.12L103.115 9.09712Z"
        fill="white"
      />
      <path
        d="M111.458 20.5259C109.803 20.5259 108.477 19.9979 107.471 18.9419C106.47 17.8859 105.964 16.4889 105.964 14.7454C105.964 12.7049 106.618 10.9944 107.933 9.60842C109.242 8.22791 110.875 7.53491 112.828 7.53491C114.076 7.53491 115.154 7.82641 116.062 8.40391C116.969 8.98142 117.596 9.79542 117.948 10.8349H118.003L118.559 7.93091H119.934L117.558 20.1299H116.183L116.634 17.9134H116.579C115.275 19.6569 113.57 20.5259 111.458 20.5259ZM111.909 19.2059C113.526 19.2059 114.879 18.6449 115.963 17.5174C117.046 16.3899 117.585 14.9929 117.585 13.3209C117.585 11.9679 117.167 10.8844 116.331 10.0759C115.495 9.26742 114.362 8.86042 112.938 8.86042C111.387 8.86042 110.083 9.42692 109.016 10.5654C107.949 11.6984 107.421 13.1064 107.421 14.7784C107.421 16.1314 107.828 17.2094 108.637 18.0124C109.445 18.8154 110.54 19.2114 111.909 19.2114V19.2059Z"
        fill="white"
      />
      <circle cx="3.66716" cy="3.66716" r="3.66668" fill="white" />
      <circle cx="18.3339" cy="18.3334" r="3.66668" fill="white" />
      <rect
        x="15.6672"
        y="1.14746"
        width="7.33335"
        height="21.2667"
        transform="rotate(45 15.6672 1.14746)"
        fill="white"
        fillOpacity="0.5"
      />
      <circle cx="3.66668" cy="18.3334" r="3.66668" fill="white" />
      <circle cx="18.3335" cy="3.66668" r="3.66668" fill="white" />
    </g>
  </svg>
);

SiteLogoGray.propTypes = {
  size: PropTypes.number,
};

SiteLogo.defaultProps = {
  size: 22,
};

export default SiteLogo;

export {
  SiteLogoDataverse,
  SiteLogoSmall,
  SiteLogoGray,
  SiteLogoDataverseGray,
};
