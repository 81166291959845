/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``heart.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const HeartIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M8.9946 2.78774C7.23967 1.25408 4.64433 0.940234 2.62885 2.6623C0.448756 4.52502 0.132009 7.66213 1.85597 9.88052C2.51037 10.7226 3.78761 11.9877 5.01507 13.1462C6.2569 14.3184 7.49586 15.426 8.10686 15.9671L8.11849 15.9774C8.17594 16.0283 8.24746 16.0917 8.31602 16.1433C8.3981 16.2051 8.51593 16.2819 8.67328 16.3288C8.88246 16.3912 9.10732 16.3912 9.3165 16.3288C9.47385 16.2819 9.59168 16.2051 9.67376 16.1433C9.74232 16.0917 9.81384 16.0283 9.87129 15.9774L9.88292 15.9671C10.4939 15.426 11.7329 14.3184 12.9747 13.1462C14.2022 11.9877 15.4794 10.7226 16.1338 9.88052C17.8511 7.67065 17.5833 4.50935 15.3531 2.65572C13.3152 0.961905 10.7475 1.25349 8.9946 2.78774ZM8.42495 4.33937C7.14615 2.84435 5.11344 2.51238 3.60325 3.80272C2.02576 5.15056 1.81349 7.38134 3.04038 8.96009C3.6127 9.69655 4.80504 10.8853 6.04468 12.0554C7.20196 13.1478 8.35958 14.1859 8.99489 14.7498C9.6302 14.1859 10.7878 13.1478 11.9451 12.0554C13.1847 10.8853 14.3771 9.69655 14.9494 8.96009C16.1829 7.37282 15.9863 5.13246 14.3943 3.8093C12.8441 2.52085 10.838 2.85095 9.56483 4.33937C9.42234 4.50596 9.2141 4.60186 8.99489 4.60186C8.77568 4.60186 8.56744 4.50596 8.42495 4.33937Z"
        transform=""
      />
    </g>
  </svg>
);

HeartIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

HeartIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

HeartIcon.displayName = "HeartIcon";

export default HeartIcon;
