import React, { useMemo } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import styled from "styled-components";
import { useRadioGroupState } from "react-stately";
import { useRadioGroup } from "@react-aria/radio";

import RadioInput from "./RadioInput";

import { Orientations } from "../StylingConstants";

const StyledRadioGroup = styled.div`
  display: flex;
  flex-direction: ${props => (props.$horizontal ? "row" : "column")};
  gap: ${props => (props.$horizontal ? "24px" : "8px")};
  margin-top: 4px;
`;

/**
 * Creates a group of styled radio buttons determined by the items passed.
 *
 * input - A dictionary containing: onChange, the function to be executed when a
 * new radio button is selected. value, the default value of the group. name, the
 * name of the group.
 *
 * items - Either a list of labels that will be assigned a value from 0 up automatically,
 * or a dictionary of labels and values for each radio button in the group. Radio items
 * have an isDisabled field that can be set to true to disable the singular item.
 *
 * disabled - If true, disables the radio group, defaults to false.
 */
const RadioGroup = React.forwardRef(
  // eslint-disable-next-line no-unused-vars
  ({ items, ...props }, ref) => {
    const parsedItems = useMemo(() => {
      if (!_.isEmpty(items) && _.isString(_.first(items))) {
        return _.map(items, item => ({
          value: _.toString(item),
          label: _.toString(item),
        }));
      }
      return _.map(items, item => ({
        ...item,
        value: item.value,
      }));
    }, [items]);

    const ariaProps = {
      ...props,
      isDisabled: props.disabled,
      "aria-labelledby": props.id ?? props.name,
    };

    const state = useRadioGroupState(ariaProps);
    const { radioGroupProps } = useRadioGroup(ariaProps, state);

    return (
      <StyledRadioGroup
        $horizontal={props.orientation === Orientations.HORIZONTAL}
        {...radioGroupProps}
      >
        {parsedItems.map(item => (
          <RadioInput
            state={state}
            key={item.label}
            label={item.label}
            value={item.value}
            helpText={item.helpText}
            wrapLabel={props.wrapLabel}
            disabled={props.disabled || item.disabled}
          />
        ))}
      </StyledRadioGroup>
    );
  }
);

const itemPropTypes = PropTypes.arrayOf(
  PropTypes.oneOfType([
    PropTypes.string,
    (props, propName, componentName) => {
      const curValue = props[propName];
      if (!_.isObject(curValue) || _.isUndefined(curValue.label) || _.isUndefined(curValue.value)) {
        return new Error(`Invalid option prop '${curValue}' supplied to ${componentName}`);
      }
      return null;
    },
  ])
);

RadioGroup.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  /** Starting value */
  value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  onChange: PropTypes.func,
  items: itemPropTypes.isRequired,
  disabled: PropTypes.bool,
  orientation: PropTypes.oneOf([Orientations.HORIZONTAL, Orientations.VERTICAL]),
  wrapLabel: PropTypes.bool,
};

RadioGroup.defaultProps = {
  disabled: false,
  wrapLabel: false,
  orientation: Orientations.VERTICAL,
};

RadioGroup.displayName = "RadioGroup";

export default RadioGroup;
