import { DateTime } from "luxon";
import { useMemo } from "react";

import usePortfolioCompanies from "./usePortfolioCompanies";

import { API_ENDPOINTS } from "../../api/constants";
import useFetches from "../../api/useFetches";
import { isVerifiedPortfolioCompany } from "../utils";
import isRemovedPortfolioCompany from "../utils/isRemovedPortfolioCompany";

/**
 * This hook is used to fetch the latest order flow report for each of the companies in a portfolio,
 * based on portfolio ID. It returns an array of pairs of order flow reports, one pair for each
 * company in the portfolio. The ordering of the returned pairs of OFRs is the same as the ordering
 * of the companies in the portfolio. The ordering within a pair is the first item is the more
 * recent report_period.
 *
 * For any companies that do not have a latest order flow report, their index in the returned array
 * will be the sentinel value `undefined`.
 *
 * @param {string} portfolioId
 * @return {Array<object|undefined>}
 */
const useLatestOrderflowForPortfolio = (portfolioId) => {
  const [portfolioCompanies, companiesLoading] =
    usePortfolioCompanies(portfolioId);

  // We do not fetch for requested companies because there is no company ID to query orderflow with.
  const latestOrderFlowReportQueries = useFetches(
    (portfolioCompanies || []).map((company) =>
      isVerifiedPortfolioCompany(company) &&
      !isRemovedPortfolioCompany(company.company)
        ? {
            url: API_ENDPOINTS.COMPANY_PRICE_VOLUME_ORDER_FLOW(),
            queryParams: {
              company: company.company?.zb_id,
              end_date: DateTime.now().minus({ days: 1 }).toISODate(),
              start_date: DateTime.now().minus({ years: 1 }).toISODate(),
            },
          }
        : null
    )
  );

  const orderFlowReports = useMemo(() => {
    return latestOrderFlowReportQueries.isAnySuccess
      ? latestOrderFlowReportQueries.cleanedData.map((orderFlowReport) => {
          if (!orderFlowReport) {
            return [null, null];
          }
          return [orderFlowReport?.data?.[0], orderFlowReport?.data?.[1]];
        })
      : null;
  }, [
    latestOrderFlowReportQueries.isAnySuccess,
    latestOrderFlowReportQueries.cleanedData,
  ]);

  if (companiesLoading) {
    return [null, true];
  }
  return [orderFlowReports, latestOrderFlowReportQueries.isLoading];
};

export default useLatestOrderflowForPortfolio;
