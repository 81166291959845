/*
 * This function is used to sort the investor names in the modal that pops up from the
 * Funding Round table and in the Financings card.
 */
const investorNameSort = (a, b) => {
  // Case-insensitive sort on entity profile name
  const nameA = a.investor.toLowerCase();
  const nameB = b.investor.toLowerCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

export { investorNameSort };
