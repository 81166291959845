import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { YukaColorPalette, body1, FontColors, caption2 } from "yuka";

import getLinkProps, { LinkTypes } from "./getLinkProps";

const StyledText = styled.span`
  ${body1}
  flex-grow: 1;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledSubtext = styled.span`
  ${caption2}
  ${FontColors.theme50}
  margin-top: 4px;
  flex-grow: 1;
  ${(props) =>
    props.$wrap
      ? `
      white-space: wrap;
    `
      : `
      line-height: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}
`;

const StyledAfterText = styled.div`
  margin-left: 24px;
  line-height: 1;
`;

const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.$wrap
      ? `
    margin: 12px 0;
  `
      : `
    margin: 14px 0;
  `}
  flex-grow: 1;
  min-width: 0;
`;

const StyledListItem = styled.li`
  // normalizing buttons
  background: transparent;
  outline: none;
  border: none;
  color: inherit;
  text-align: left;
  letter-spacing: inherit;
  list-style: none;
  font-size: inherit;
  font-family: inherit;
  margin: 0;
  line-height: inherit;
  width: 100%;

  flex-shrink: 0;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  min-width: 200px;
  min-height: 48px;
  padding: 0 16px;
  ${(props) =>
    props.onClick || props.href
      ? `
    cursor: pointer;
    &:hover {
      background: ${YukaColorPalette.hoverTransparent};
    }
    &:active {
      background: ${YukaColorPalette.activeTransparent};
    }
  `
      : ""}
`;

const SingleLineListItem = React.forwardRef((props, ref) => (
  <StyledListItem
    as={props.as}
    {...props.linkProps}
    ref={ref}
    className={props.className}
    onClick={props.onClick}
    role={props.role}
  >
    {props.leadingContent}
    <StyledText>{props.text}</StyledText>
    {props.trailingContent && (
      <StyledAfterText>{props.trailingContent}</StyledAfterText>
    )}
  </StyledListItem>
));

const TwoLineListItem = React.forwardRef((props, ref) => (
  <StyledListItem
    as={props.as}
    {...props.linkProps}
    ref={ref}
    className={props.className}
    onClick={props.onClick}
    role={props.role}
  >
    {props.leadingContent}
    <StyledTextWrapper $wrap={false}>
      <StyledText>{props.text}</StyledText>
      <StyledSubtext $wrap={false}>{props.subtext}</StyledSubtext>
    </StyledTextWrapper>
    {props.trailingContent && (
      <StyledAfterText>{props.trailingContent}</StyledAfterText>
    )}
  </StyledListItem>
));

const ThreeLineListItem = React.forwardRef((props, ref) => (
  <StyledListItem
    as={props.as}
    {...props.linkProps}
    ref={ref}
    className={props.className}
    onClick={props.onClick}
    role={props.role}
  >
    {props.leadingContent}
    <StyledTextWrapper $wrap>
      <StyledText>{props.text}</StyledText>
      <StyledSubtext $wrap>{props.subtext}</StyledSubtext>
    </StyledTextWrapper>
    {props.trailingContent && (
      <StyledAfterText>{props.trailingContent}</StyledAfterText>
    )}
  </StyledListItem>
));

const ListStyles = {
  SINGLE_LINE: "SINGLE_LINE",
  TWO_LINE: "TWO_LINE",
  THREE_LINE: "THREE_LINE",
};

/**
 * Copy pasted from yuka/ListItem
 *
 * The only difference is that the leading icon now accepts any styled component,
 * where in yuka it only supports either an Icon or an Avatar.
 *
 * The assumption is that the leading content will have it's own styling rather than have
 * margin and size styling hard coded into the component.
 */
const ListItem = React.forwardRef((props, ref) => {
  let StyledElement;
  switch (props.listStyle) {
    case ListStyles.SINGLE_LINE:
      StyledElement = SingleLineListItem;
      break;
    case ListStyles.TWO_LINE:
      StyledElement = TwoLineListItem;
      break;
    case ListStyles.THREE_LINE:
      StyledElement = ThreeLineListItem;
      break;
  }

  let [linkProps, linkAs] = getLinkProps(props.url, props.linkType);

  if (!props.url && !props.onClick) {
    linkAs = "li";
    // clear out link props as unneeded on a <li>
    linkProps = {};
  }

  return (
    <StyledElement ref={ref} as={linkAs} {...props} linkProps={linkProps} />
  );
});

ListItem.propTypes = {
  listStyle: PropTypes.oneOf(Object.values(ListStyles)),
  text: PropTypes.node.isRequired,
  subtext: PropTypes.node,
  leadingContent: PropTypes.element,
  avatar: PropTypes.element,
  trailingContent: PropTypes.element,
  onClick: PropTypes.func,
  className: PropTypes.string,
  linkType: PropTypes.oneOf(Object.values(LinkTypes)),
  url: PropTypes.string,
  role: PropTypes.string,
  tabIndex: PropTypes.string,
};

ListItem.defaultProps = {
  listStyle: ListStyles.SINGLE_LINE,
  role: "listitem",
};

ListItem.displayName = "ListItem";

SingleLineListItem.propTypes = ListItem.propTypes;
TwoLineListItem.propTypes = ListItem.propTypes;
ThreeLineListItem.propTypes = ListItem.propTypes;

const WrappedListItem = styled(ListItem)``;

WrappedListItem.displayName = "StyledListItem";

export default WrappedListItem;
export { ListStyles, ListItem as DocsListItem };
