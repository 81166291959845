/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``cursor.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const CursorIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M3.67539 1.96481C3.6838 1.96783 3.6922 1.97085 3.70059 1.97386L15.6448 6.26153C15.855 6.33696 16.0566 6.40929 16.2119 6.48067C16.3431 6.54098 16.6301 6.67813 16.7924 6.97634C16.9698 7.3024 16.9749 7.69501 16.8061 8.02563C16.6518 8.32799 16.3684 8.47262 16.2388 8.53636C16.0855 8.61179 15.8859 8.68939 15.6777 8.7703L10.7043 10.7044L8.77026 15.6778C8.68935 15.8859 8.61176 16.0855 8.53632 16.2389C8.47259 16.3684 8.32795 16.6518 8.0256 16.8062C7.69498 16.975 7.30237 16.9698 6.9763 16.7924C6.6781 16.6301 6.54095 16.3431 6.48064 16.2119C6.40925 16.0566 6.33692 15.855 6.2615 15.6448L1.97386 3.70059C1.97084 3.69219 1.96783 3.68379 1.96481 3.67539C1.90411 3.5064 1.84252 3.33494 1.8049 3.19017C1.7692 3.05283 1.71003 2.78768 1.80848 2.5034C1.92135 2.17748 2.17748 1.92135 2.5034 1.80848C2.78768 1.71003 3.05283 1.7692 3.19017 1.8049C3.33494 1.84253 3.5064 1.90411 3.67539 1.96481ZM10.7575 10.5694C10.7575 10.5693 10.7575 10.5693 10.7575 10.5694V10.5694ZM10.5694 10.7575C10.5693 10.7575 10.5693 10.7575 10.5694 10.7575V10.7575ZM3.49309 3.49309L7.52806 14.7334L9.33026 10.0992C9.33226 10.0941 9.33453 10.0881 9.33707 10.0814C9.36189 10.0162 9.41319 9.88138 9.49877 9.76139C9.57115 9.65993 9.65989 9.57119 9.76136 9.49881C9.88134 9.41323 10.0162 9.36193 10.0814 9.33711C10.0881 9.33457 10.094 9.3323 10.0992 9.3303L14.7334 7.52809L3.49309 3.49309Z"
        transform=""
      />
    </g>
  </svg>
);

CursorIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

CursorIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

CursorIcon.displayName = "CursorIcon";

export default CursorIcon;
