import React from "react";
import _ from "lodash";

/**
 * Parses the provided list of options into a standardized format with customizations applied
 *
 * @param {Object} config
 *
 * @returns {Array}
 * - the cleaned, flattened option list
 */
const useMenuOptions = ({
  options: initialOptions,
  labelKey,
  valueKey,
  iconKey,
  thumbnailKey,
  sortAlphabetically,
}) => {
  // We cache the base set of options to help reduce rerenders when lazily populated
  const [cachedInitialOptions, setCache] = React.useState(initialOptions);

  React.useEffect(() => {
    if (!_.isEqual(initialOptions, cachedInitialOptions)) {
      setCache(initialOptions);
    }
  }, [initialOptions, cachedInitialOptions]);

  // Coerces the options from props into our standardized format to be used by the remainder of
  // the select logic and components.
  const parsedOptions = React.useMemo(
    () =>
      cachedInitialOptions.map(opt => ({
        value: opt[valueKey],
        text: opt[labelKey],
        icon: opt[iconKey],
        thumbnail: opt[thumbnailKey],
        role: "option",
      })),
    [cachedInitialOptions, labelKey, valueKey, iconKey, thumbnailKey]
  );

  // Cleaned options ready to pass to the menu config
  const options = React.useMemo(() => {
    let cleanedOptions = parsedOptions;
    if (sortAlphabetically) {
      cleanedOptions = _.sortedUniqBy(
        _.orderBy(cleanedOptions, [option => _.lowerCase(option.label)]),
        "value"
      );
    }
    return cleanedOptions;
  }, [parsedOptions, sortAlphabetically]);

  return options;
};

export default useMenuOptions;
