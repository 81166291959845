import Checkbox from "./Checkbox";
import CheckboxDropdown from "./CheckboxDropdown";
import DatePicker, { formatDate } from "./DatePicker";
import DelimitedNumberInput, {
  parseDelimitedValue,
  formatDelimitedValue,
} from "./DelimitedNumberInput";
import FileInput from "./FileInput";
import HiddenInput from "./HiddenInput";
import Input from "./Input";
import PasswordInput from "./PasswordInput";
import MultipleCheckbox from "./MultipleCheckbox";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import SwitchInput from "./SwitchInput";
import TextArea from "./TextArea";
import Autocomplete from "./Autocomplete";

import BlockValidatedField from "./internal/BlockValidatedField";
import InlineValidatedField from "./internal/InlineValidatedField";

// Most of these are valid HTML input type attribute values. Some we remap under the covers
const TYPE_MAPPING = {
  autocomplete: [Autocomplete, BlockValidatedField],
  checkbox: [Checkbox, InlineValidatedField],
  "custom-inline": [null, InlineValidatedField],
  "custom-block": [null, BlockValidatedField],
  date: [DatePicker, BlockValidatedField, { format: formatDate }],
  file: [FileInput, BlockValidatedField],
  hidden: [HiddenInput, BlockValidatedField],
  "multiple-checkbox": [MultipleCheckbox, BlockValidatedField],
  "checkbox-dropdown": [CheckboxDropdown, BlockValidatedField],
  number: [
    DelimitedNumberInput,
    BlockValidatedField,
    { parse: parseDelimitedValue, format: formatDelimitedValue },
  ],
  password: [PasswordInput, BlockValidatedField],
  // We use a single input type="radio" in our interface for a group of radio inputs, we need to
  // override the type so other libraries don't apply the default behavior that mirrors browser
  // type radio more closely
  radio: [RadioGroup, BlockValidatedField, { type: "hidden" }],
  select: [Select, BlockValidatedField],
  switch: [SwitchInput, InlineValidatedField],
  text: [Input, BlockValidatedField],
  textarea: [TextArea, BlockValidatedField],
};

const DEFAULT_MAPPING = TYPE_MAPPING.text;

// Includes unconventional input types that the browser may add some default validation on
const WITH_ALIASES = {
  tel: TYPE_MAPPING.text,
  email: TYPE_MAPPING.text,
  url: TYPE_MAPPING.text,
  ...TYPE_MAPPING,
};

export default WITH_ALIASES;
export { DEFAULT_MAPPING };
