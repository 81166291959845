import React from "react";
import PropTypes from "prop-types";

import { Autocomplete, MultiAutocomplete } from "../Select";

/**
 * This component is a standard text input enhanced to provide options from a list of
 * pre-existing values and remap the selected options (if matching) to appropriate internal values.
 * This can hold either a single value or be enhanced to select multiple values, and supports
 * user entered values as well.
 *
 * If you need the ability to select options from a list, but not add custom values, generally
 * prefer `Select` instead.
 */
const AutocompleteInput = React.forwardRef(({ multiple, ...subprops }, ref) => {
  const Component = multiple ? MultiAutocomplete : Autocomplete;
  return <Component ref={ref} {...subprops} />;
});

AutocompleteInput.propTypes = {
  multiple: PropTypes.bool,
};

export default AutocompleteInput;
