import PropTypes from "prop-types";
import React, { useContext, useRef, useState } from "react";
import { Form } from "react-final-form";
import { ButtonStyles, Fonts, HyperLink, LinkStyles, LinkTypes } from "yuka";

import { UNKNOWN_ERROR } from "./constants";
import {
  AuthButton,
  AuthCheckboxes,
  AuthFormInput,
  AuthFormInputErrorTrailingContent,
  FormContents,
  StyledFormDescription,
} from "./StyledComponents";

import AxiosInstance from "../api/AxiosInstance";
import { API_ENDPOINTS } from "../api/constants";
import { AuthContext } from "../auth";
import { FinalFormField } from "../hdYuka";
import { ROUTING_PATH } from "../routes/constants";
import { ONE_MINUTE } from "../utils/constants";
import LoadingSpinner from "../utils/LoadingSpinner";
import useLocalStorage from "../utils/useLocalStorage";

const REMEMBER_2FA_KEY = "remember_2fa_token";
const REMEMBER_2FA_TTL = 30 * 24 * 60 * ONE_MINUTE;

const TokenForm = ({ credentials, setCredentials }) => {
  const otpTokenRef = useRef();
  const [errors, setErrors] = useState({});
  const { authenticate } = useContext(AuthContext);
  const { setItem } = useLocalStorage();

  const onSubmit = (values) => {
    const combinedValues = { ...credentials, ...values };

    if (!combinedValues.otp_token) {
      setErrors({
        otp_token: "Token is required",
      });
      return;
    }

    return AxiosInstance.post(
      API_ENDPOINTS.TOKEN_LOGIN(),
      JSON.stringify(combinedValues)
    )
      .then(({ data }) => {
        // useLocalStorage to store a 30 day signed token to remember device
        if (data.remember_2fa_token) {
          setItem(REMEMBER_2FA_KEY, data.remember_2fa_token, REMEMBER_2FA_TTL);
        }
        setCredentials(null);
        authenticate(data, true);
      })
      .catch(({ response: { data } }) => {
        let errorMsg = UNKNOWN_ERROR;
        if (data?.error) {
          errorMsg = data.error[0] || errorMsg;
        }
        setErrors({ submission: errorMsg });
      });
  };

  return (
    <>
      <StyledFormDescription>
        Please enter the token from your token generator
      </StyledFormDescription>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            {submitting && <LoadingSpinner />}
            <FormContents>
              <AuthFormInput
                name="otp_token"
                placeholder="Token"
                type="text"
                ref={otpTokenRef}
                $error={errors.otp_token}
                onChange={() => setErrors({ ...errors, otp_token: undefined })}
                trailingIcon={() =>
                  errors.otp_token ? (
                    <AuthFormInputErrorTrailingContent>
                      {errors.otp_token}
                    </AuthFormInputErrorTrailingContent>
                  ) : null
                }
              />
              {errors.submission && (
                <Fonts.Body2sell>{errors.submission}</Fonts.Body2sell>
              )}
              <AuthCheckboxes>
                <FinalFormField
                  id="remember_2fa"
                  name="remember_2fa"
                  type="checkbox"
                  label="Remember this device for 30 days"
                />
              </AuthCheckboxes>
              <AuthButton
                buttonStyle={ButtonStyles.CTA}
                disabled={submitting}
                type="submit"
              >
                Log in
              </AuthButton>
              <HyperLink
                linkStyle={LinkStyles.SUPPORT}
                linkType={LinkTypes.LOCAL_LINK}
                url={ROUTING_PATH.LOGIN()}
                onClick={() => setCredentials(null)}
              >
                <Fonts.Body2theme50>Back to Login</Fonts.Body2theme50>
              </HyperLink>
            </FormContents>
          </form>
        )}
      </Form>
    </>
  );
};

TokenForm.propTypes = {
  credentials: PropTypes.object.isRequired,
  setCredentials: PropTypes.func.isRequired,
};

export default TokenForm;
