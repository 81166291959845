import roundDecimal from "./roundDecimal";
import addCommasToNum from "./addCommasToNum";

/**
 * Receives number and returns formatted string with commas and rounding
 * i.e (4000000) -> 4,000,000
 * i.e (4000000.5, 2, 0) -> 4,000,000.50
 * i.e (4000000.1234, 0, 2) -> 4,000,000.12
 *
 * @param {?number} number
 * @param {number} minPrecision
 * @param {number} maxPrecision
 * @returns {string}
 */
// eslint-disable-next-line no-magic-numbers
const numberFormat = (number, minPrecision = 0, maxPrecision = 6) => {
  const formatted = `${addCommasToNum(Number(roundDecimal(number, maxPrecision)), minPrecision)}`;
  return number === null ? "--" : formatted;
};

/**
 * Receives number and number of decimal places and returns a string
 * representing the percentage value
 * i.e (0.5) -> 50.00%
 * i.e (0.5, 3) -> 50.000%
 * Similar to ``get_percent`` in numberformat.py
 *
 * @param {?number} number
 * @param {number} decimalPlaces
 * @returns {string}
 */
const percentFormat = (number, decimalPlaces = 2) =>
  number === null ? "--" : `${roundDecimal(number, decimalPlaces)}%`;

export { numberFormat, percentFormat };
