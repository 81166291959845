import { useCallback, useContext, useState } from "react";

import { API_ENDPOINTS } from "../api/constants";
import useWrite from "../api/useWrite";
import { AuthContext } from "../auth";

/**
 * Hook to manage the presentation boolean for a walkthrough, based on the field from the user's
 * completed walkthrough progress.
 *
 * @param walkthroughFieldName - name of the relevant walkthrough in user.walkthrough_progress.
 * @return {{walkthroughShowing: boolean, completeWalkthrough: function}}
 */
const useWalkthrough = (walkthroughFieldName) => {
  const { user, setUserData } = useContext(AuthContext);
  const [walkthroughShowing, setWalkthroughShowing] = useState(
    !user?.walkthrough_progress?.[walkthroughFieldName]
  );

  const walkthroughUpdate = useWrite(
    API_ENDPOINTS.WALKTHROUGH_PROGRESS(user?.id),
    true,
    {
      silent: true,
    }
  );

  const completeWalkthrough = useCallback(() => {
    setWalkthroughShowing(false);
    walkthroughUpdate.mutate(
      { [walkthroughFieldName]: true },
      {
        onSuccess: () => {
          setUserData();
        },
      }
    );
  }, [walkthroughFieldName, setUserData, walkthroughUpdate]);

  return {
    walkthroughShowing:
      walkthroughShowing && !user?.walkthrough_progress?.[walkthroughFieldName],
    completeWalkthrough,
  };
};

export default useWalkthrough;
