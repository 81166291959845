import _ from "lodash";
import { Badge } from "../../Badge";
import PropTypes from "prop-types";
import React from "react";

const BadgeCell = ({ value, ...props }) => {
  let renderedValue;
  // Ensures that the column onClick handler is not also passed to the `Cell` component.
  const {
    onClick: columnOnClick,
    badgeMapping,
    defaultBadgeStyle,
    emptyPlaceholder,
  } = props.column;
  const onClick = columnOnClick ? () => columnOnClick(props) : _.noop;
  if (badgeMapping && badgeMapping[value]) {
    // Ensure the user's badge mapping is well defined to handle this case.
    renderedValue = (
      <Badge badgeStyle={badgeMapping?.[value]} onClick={onClick}>
        {value}
      </Badge>
    );
  } else if (value) {
    // If there is no badgeMapping or there is no mapped badge style, use the provided default
    // or fall all the way back to the default on `Badge`.
    renderedValue = (
      <Badge badgeStyle={defaultBadgeStyle || undefined} onClick={onClick}>
        {value}
      </Badge>
    );
  } else {
    // No value supplied. Render some default value instead of a badge. Often empty string.
    renderedValue = emptyPlaceholder || "";
  }
  return renderedValue;
};

BadgeCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  column: PropTypes.shape({
    badgeMapping: PropTypes.shape({}).isRequired,
    onClick: PropTypes.func,
  }).isRequired,
};

BadgeCell.displayName = "BadgeCell";

export default BadgeCell;
