import { API_ENDPOINTS } from "../../api/constants";
import useFetch from "../../api/useFetch";

/**
 * Fetches portfolios containing a company.
 * Mostly copied from `usePortfolioCompanies`, but is a separate hook since the intent is
 * very different, as this hook is used to fetch portfolios containing a given company
 * while `usePortfolioCompanies` is used to fetch companies in a given portfolio.
 *
 * Note that this hook is intended to be used with zb_ids and currently does not support
 * requested companies
 *
 * @param {string} companyId
 * @return {[Array<object>|null, boolean]}
 */
const usePortfoliosContainingCompany = (companyId) => {
  const companiesQuery = useFetch(
    API_ENDPOINTS.PORTFOLIO_COMPANIES(),
    {
      company__zb_id: companyId,
    },
    {
      enabled: Boolean(companyId),
    }
  );

  if (companiesQuery.isLoading) {
    return [null, true];
  }

  if (!companiesQuery.isSuccess) {
    return [null, false];
  }

  return [companiesQuery.cleanedData.data, false];
};

export default usePortfoliosContainingCompany;
