/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``arrow-down-left.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ArrowDownLeftIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M5.25 4.5C5.66421 4.5 6 4.83579 6 5.25V10.9393L12.2197 4.71967C12.5126 4.42678 12.9874 4.42678 13.2803 4.71967C13.5732 5.01256 13.5732 5.48744 13.2803 5.78033L7.06066 12H12.75C13.1642 12 13.5 12.3358 13.5 12.75C13.5 13.1642 13.1642 13.5 12.75 13.5H5.25C4.83579 13.5 4.5 13.1642 4.5 12.75V5.25C4.5 4.83579 4.83579 4.5 5.25 4.5Z"
        transform=""
      />
    </g>
  </svg>
);

ArrowDownLeftIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ArrowDownLeftIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

ArrowDownLeftIcon.displayName = "ArrowDownLeftIcon";

export default ArrowDownLeftIcon;
