import PropTypes from "prop-types";
import React from "react";
import { Fonts } from "yuka";

import { CarouselContainer, EmptyCarouselCard } from "./StyledComponents";

import { Carousel, CarouselContent, CarouselItem } from "../hdYuka/Carousel";

/**
 * To be used by the Most active companies and similar companies carousels. Used to indicate
 * there is no data to display.
 */
const EmptyCompanyCarousel = ({ title }) => (
  <CarouselContainer>
    <Fonts.Headline1theme80>{title}</Fonts.Headline1theme80>
    <Carousel slidesToScroll={6} empty>
      <CarouselContent>
        {[...Array(7)].map((_, index) => (
          <CarouselItem key={`empty_card_${index}`}>
            <EmptyCarouselCard key={index} />
          </CarouselItem>
        ))}
      </CarouselContent>
    </Carousel>
  </CarouselContainer>
);

EmptyCompanyCarousel.propTypes = {
  title: PropTypes.string,
};

export default EmptyCompanyCarousel;
