import PropTypes from "prop-types";
import React from "react";
import styled, { keyframes } from "styled-components";

import { body1, ColorPalette } from "yuka";

const loadSpinner = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const StyledLoadingText = styled.span`
  ${body1};
`;

const StyledIcon = styled.div`
  animation: ${loadSpinner} 0.5s infinite linear;
  background: ${ColorPalette.fullWhite};
  background: -moz-linear-gradient(
    left,
    ${ColorPalette.fullWhite},
    transparent 38%
  );
  background: -webkit-linear-gradient(
    left,
    ${ColorPalette.fullWhite},
    transparent 38%
  );
  background: -o-linear-gradient(
    left,
    ${ColorPalette.fullWhite},
    transparent 38%
  );
  background: -ms-linear-gradient(
    left,
    ${ColorPalette.fullWhite},
    transparent 38%
  );
  background: linear-gradient(
    to right,
    ${ColorPalette.fullWhite},
    transparent 38%
  );
  border-radius: 50%;
  height: 18px;
  left: calc(50% - 9px);
  position: relative;
  top: calc(50% - 9px);
  width: 18px;
  &:before {
    background: ${ColorPalette.fullWhite};
    border-radius: 100% 0 0 0;
    content: "";
    height: 50%;
    position: absolute;
    width: 50%;
    top: 0;
    left: 0;
  }
  &:after {
    background: rgba(22, 22, 22, 1);
    border-radius: 50%;
    bottom: 0;
    content: "";
    height: 70%;
    margin: auto;
    position: absolute;
    width: 70%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

const StyledContainer = styled.div`
  background: rgba(22, 22, 22, 0.8);
  border-radius: 4px;
  height: 48px;
  margin: 0 auto;
  width: 48px;
`;

const StyledContent = styled.div`
  left: 50%;
  position: ${(props) => (props.absolute ? "absolute" : "relative")};
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`;

/**
 * Loading spinner to display when loading content.
 *
 * @param {object} props
 * @returns {React.Element}
 */
const LoadingSpinner = (props) => {
  const icon = <StyledIcon />;

  const spinner = props.showContainer ? (
    <StyledContainer>{icon}</StyledContainer>
  ) : (
    icon
  );
  return (
    <StyledContent absolute={props.absolute} className={props.className}>
      {spinner}
      {props.loadingText && (
        <StyledLoadingText>{props.loadingText}</StyledLoadingText>
      )}
    </StyledContent>
  );
};

LoadingSpinner.propTypes = {
  absolute: PropTypes.bool,
  className: PropTypes.string,
  loadingText: PropTypes.string,
  showContainer: PropTypes.bool,
};

LoadingSpinner.defaultProps = {
  absolute: true,
  className: "",
  loadingText: "",
  showContainer: true,
};

export default LoadingSpinner;
