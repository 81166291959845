import styled from "styled-components";

import InlineValidatedField from "../FormField/internal/InlineValidatedField";

const InputRow = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 16px;

  > ${InlineValidatedField} {
    align-self: center;
  }

  > .flex-input {
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
  }

  > ${InlineValidatedField}, > .flex-input,
  > .icon-btn,
  > .btn {
    + .flex-input,
    + ${InlineValidatedField} {
      margin-left: 12px;
    }

    + .btn,
    + .icon-btn {
      margin-left: 12px;
      // Comes out to 32px tall, input is 40. Fakes align-self: center but stays aligned when
      // error messages are visible
      margin-top: 4px;
    }
  }
`;

export default InputRow;
