import { ButtonStyles, IconButtonStyles } from "yuka";

import { DataverseColors } from "./constants";
import {
  KEY_ACTION_BUTTON_CONTAINER,
  KEY_ACTION_ICON_BUTTON_CONTAINER,
  KEY_ACTION_SELECTED_ACTIVE,
  KEY_ACTION_SELECTED_DEFAULT,
  KEY_ACTION_SELECTED_HOVER,
} from "./yukaThemeOverrides";

/*
 * ZXData overrides some yuka defaults.
 */
export default {
  button: {
    [ButtonStyles.RAISED]: {
      container: KEY_ACTION_BUTTON_CONTAINER,
      selectedDefault: KEY_ACTION_SELECTED_DEFAULT,
      selectedHover: KEY_ACTION_SELECTED_HOVER,
      selectedActive: KEY_ACTION_SELECTED_ACTIVE,
    },
    [ButtonStyles.CTA]: {
      container: KEY_ACTION_BUTTON_CONTAINER,
    },
  },
  iconButton: {
    [IconButtonStyles.RAISED]: {
      container: KEY_ACTION_ICON_BUTTON_CONTAINER,
    },
  },
  colors: DataverseColors,
};
