import { useMemo } from "react";

/**
 * This hook provides the interface to set the value of a select and manage that via options.
 * Specifically, it provides 2 values.
 * 1. A function that can be passed either an option object or a string value for non existent
 * options that will select/toggle that option in the value as appropriate based on if it is a multi
 * select.
 * 2. An array or single object representing the selected options reflected in the current value.
 * Always an array for a multi select, always an option object or undefined for a single select.
 *
 * @param {Function} addNewOption
 * @param {any} value - see ValuePropType for valid shape of this param
 * @param {Function} setValue
 * @param {boolean} multiple
 * @param {Array} allOptions
 *
 * @returns {Array} See [1, 2] for values in the array
 */
const useSelectValueDisplay = (allOptions, value, multiple = false, loadOptionFromValue = null) => {
  // Collects options the match the selected value. This is necessary since the value is not what
  // is displayed to the user, but the label for the value
  const selectedOptions = useMemo(() => {
    if (multiple) {
      return value.map(
        val => allOptions.find(option => option.value === val) || { text: val, value: val }
      );
    }
    const options = allOptions.find(option => option?.value === value);
    if (!options && loadOptionFromValue) {
      loadOptionFromValue(value);
    }
    return options;
  }, [value, multiple, allOptions]);

  if (multiple) {
    return [selectedOptions, null];
  }

  const valueDefault = value === null ? "" : value;

  return selectedOptions ? [selectedOptions.text, selectedOptions.icon] : [valueDefault, null];
};

export default useSelectValueDisplay;
