import { useParams } from "react-router-dom";

import { API_ENDPOINTS } from "../../api/constants";
import useFetch from "../../api/useFetch";

/**
 * Pulls the portfolio ID out of the URL and fetches the portfolio data. Useful for determining
 * if the URL provided ID actually matches a portfolio for the user.
 *
 * @param {string} id - The portfolio ID to fetch. If not provided, the hook will attempt to
 *    pull the ID from the URL.
 * @return {[object|null, boolean]} - The portfolio object and a boolean indicating whether the
 *    fetch is still in progress. Returns null as the portfolio if the query is loading or if the
 *    query failed.
 */
const usePortfolio = (id = undefined) => {
  const { id: urlId } = useParams();
  const portfolioId = id || urlId;

  const portfolioQuery = useFetch(
    API_ENDPOINTS.PORTFOLIO_DETAIL(portfolioId),
    {},
    {
      enabled: Boolean(portfolioId),
    }
  );

  if (portfolioQuery.isLoading) {
    return [null, true];
  }

  if (!portfolioQuery.isSuccess) {
    return [null, false];
  }
  return [portfolioQuery.cleanedData?.data, false];
};

export default usePortfolio;
