import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";

import { useTooltip } from "./Tooltip";

const EllipsisText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  display: inline-block;
`;

const TruncatedText = props => {
  const widthMeasuringRef = React.useRef(null);
  const [width, setWidth] = React.useState([0, 0]);
  const tooltip = useTooltip(widthMeasuringRef, props.children);

  React.useEffect(() => {
    setWidth([widthMeasuringRef.current?.scrollWidth, widthMeasuringRef.current?.offsetWidth]);
  }, [widthMeasuringRef.current?.scrollWidth, widthMeasuringRef.current?.offsetWidth]);

  return (
    <>
      <EllipsisText ref={widthMeasuringRef} {...props} />
      {width[0] > width[1] && tooltip}
    </>
  );
};

TruncatedText.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TruncatedText;
