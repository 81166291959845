import arrayMutators from "final-form-arrays";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { Form, useForm, useFormState } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import styled from "styled-components";
import {
  Button,
  ButtonStyles,
  FontColors,
  Fonts,
  headline2,
  Modal,
  ModalStyles,
  TruncatedText,
} from "yuka";

import {
  COMPANY_SEARCH_FORM_FIELD_NAME,
  COMPANY_MATCH_FIELD_NAME,
  SELECTED_COMPANY_ID_FIELD_NAME,
  COMPANY_MATCH_NO_MATCHES,
  COMPANY_MATCH_ALREADY_IN_PORTFOLIO,
} from "./constants";
import { usePortfolio } from "./hooks";
import PortfolioCompanySearch from "./PortfolioCompanySearch";
import ShareLotsFieldArray from "./ShareLotsFieldArray";
import { StyledModalButtonRow, StyledModalContent } from "./StyledComponents";
import { isEmptyShareLot, validateShareLots } from "./utils";

import { API_ENDPOINTS } from "../api/constants";
import useWrite from "../api/useWrite";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";

const StyledScrollableModalContent = styled(StyledModalContent)`
  width: 800px;
  min-height: 400px;
  max-height: 600px;
`;

const StyledPortfolioName = styled(TruncatedText)`
  ${headline2}
  ${FontColors.theme80}
  max-width: 800px;
`;

const StyledWarning = styled(Fonts.Body2yellow).attrs({ as: "p" })``;
const StyledDescription = styled(Fonts.Body2theme50).attrs({ as: "p" })``;

const CLOSE_ON_SUBMIT = "closeOnSubmit";

const AddPortfolioCompanyForm = ({ onClose }) => {
  const { submit, reset, change } = useForm();
  const {
    dirty,
    valid,
    submitting,
    values: {
      [COMPANY_MATCH_FIELD_NAME]: companyMatch, // Serves as sentinel values.
    },
  } = useFormState();
  const [portfolio] = usePortfolio();

  const submitDisabled = useMemo(
    () =>
      !dirty ||
      !valid ||
      submitting ||
      companyMatch === COMPANY_MATCH_ALREADY_IN_PORTFOLIO,
    [dirty, valid, submitting, companyMatch]
  );

  return (
    <Modal modalStyle={ModalStyles.MINIMAL} onClose={onClose}>
      <StyledScrollableModalContent>
        <StyledPortfolioName>
          Add a company to {portfolio?.name}
        </StyledPortfolioName>
        <div>
          <PortfolioCompanySearch />
        </div>
        {companyMatch === COMPANY_MATCH_NO_MATCHES && (
          <StyledWarning>
            No matches found. You can add this company and share lots now, and
            we'll update the data once the company is available on our platform.
          </StyledWarning>
        )}
        {companyMatch === COMPANY_MATCH_ALREADY_IN_PORTFOLIO && (
          <StyledWarning>
            This company is already in your portfolio.
          </StyledWarning>
        )}
        <StyledDescription>
          Adding share lots enables us to provide ZX Index Value data for the
          companies in your portfolio, helping you better track metrics and
          performance.
        </StyledDescription>
        <FieldArray
          name="shareLots"
          component={ShareLotsFieldArray}
          disabled={companyMatch === COMPANY_MATCH_ALREADY_IN_PORTFOLIO}
        />
      </StyledScrollableModalContent>
      <StyledModalButtonRow>
        <Button buttonStyle={ButtonStyles.RAISED} onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={submitDisabled}
          buttonStyle={ButtonStyles.CTA}
          onClick={() => {
            change(CLOSE_ON_SUBMIT, false);
            submit();
            reset();
          }}
        >
          Save & add another
        </Button>
        <Button
          disabled={submitDisabled}
          buttonStyle={ButtonStyles.CTA}
          onClick={() => {
            change(CLOSE_ON_SUBMIT, true);
            return submit();
          }}
        >
          Save
        </Button>
      </StyledModalButtonRow>
    </Modal>
  );
};

AddPortfolioCompanyForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const AddPortfolioCompanyModal = ({ mode, onClose }) => {
  const addCompany = useWrite(API_ENDPOINTS.PORTFOLIO_COMPANIES(), false, {
    message: "Company added to portfolio",
  });
  const [portfolio] = usePortfolio();

  const initialValues = useMemo(() => {
    return {
      shareLots: [{ tempId: Math.random() }],
      closeOnSubmit: true,
      [SELECTED_COMPANY_ID_FIELD_NAME]: null,
      [COMPANY_SEARCH_FORM_FIELD_NAME]: "",
    };
  }, []);

  return (
    <Form
      mutators={{ ...arrayMutators }}
      initialValues={initialValues}
      validate={(values) => {
        // Ensures companySearch or selectedCompany is filled in and we have no partially filled
        // in share lots.
        const {
          [SELECTED_COMPANY_ID_FIELD_NAME]: selectedCompanyId,
          [COMPANY_SEARCH_FORM_FIELD_NAME]: companySearch,
          shareLots,
        } = values;
        if (!selectedCompanyId && !companySearch) {
          return "Select a company";
        }
        return validateShareLots(shareLots);
      }}
      onSubmit={({ closeOnSubmit, ...values }) => {
        const submitData = { portfolio: portfolio.apiId };
        if (values.selectedCompany) {
          submitData.company = values.selectedCompany;
        } else if (values.companySearch) {
          // Backend will handle creating a new company.
          submitData.requested_company = values.companySearch;
        }
        if (!values.shareLots.every(isEmptyShareLot)) {
          submitData.share_lots = values.shareLots
            .filter((shareLot) => !isEmptyShareLot(shareLot))
            .map((shareLot) => ({
              purchase_date: shareLot.purchaseDate,
              price_per_share: shareLot.pricePerShare,
              share_quantity: shareLot.shareQuantity,
              security: shareLot.security, // Will be handled in the backend.
            }));
        }
        addCompany.mutate(submitData);
        MixpanelEvents.addCompanyToPortfolio(
          "portfolio",
          portfolio.apiId,
          portfolio.name,
          values.companySearch,
          Boolean(values[SELECTED_COMPANY_ID_FIELD_NAME])
        );
        if (submitData.share_lots?.length > 0) {
          MixpanelEvents.addShareLotsToPortfolio(
            "add company modal",
            mode,
            submitData.share_lots.length
          );
        }
        if (closeOnSubmit) {
          onClose();
        }
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <AddPortfolioCompanyForm onClose={onClose} />
        </form>
      )}
    </Form>
  );
};

AddPortfolioCompanyModal.propTypes = {
  mode: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default AddPortfolioCompanyModal;
