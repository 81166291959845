import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Surface from "./internal/Surface";

import { ChevronRightIcon, ChevronLeftIcon } from "../Icons";
import IconButton from "../Button/IconButton";
import HyperLink, { LinkStyles } from "../Button/HyperLink";
import { LinkTypes } from "../Button/getLinkProps";
import NavigationStateContext from "../Navigation/NavigationStateContext";
import { YukaThemeProvider } from "../ThemeContext";
import { TENTH_SECOND } from "../utils/constants";

const StyledSurface = styled(Surface)`
  margin-right: 2px;
  height: 100vh;
  position: sticky;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  width: ${props => (props.$collapsed ? "66px" : "256px")};
  transition: width ${TENTH_SECOND};
  flex-shrink: 0;
`;

const LogoWrapper = styled(HyperLink).attrs({
  linkStyle: LinkStyles.UNSTYLED,
})`
  margin: 12px;
  padding: 12px;
`;

const StyledFooter = styled.div`
  margin: auto 12px 12px;
`;

const CollapsibleIconWrapper = styled(Surface)`
  border-radius: 50%;
  position: absolute;
  top: 24px;
  right: -12px;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
`;

/**
 * The global navigation should always be an element fixed to the left of the page and will take up
 * the full page height. It may be collapsible, and generally has a footer containing an element
 * displaying basic user information that when clicked opens a menu.
 */
const GlobalNavigation = props => {
  const [collapsed, setCollapsed] = React.useState(false);
  return (
    <YukaThemeProvider theme={{ componentVersion: 2 }}>
      <NavigationStateContext.Provider value={collapsed}>
        <StyledSurface $collapsed={collapsed}>
          {props.collapsible && (
            <CollapsibleIconWrapper>
              <IconButton
                icon={collapsed ? ChevronRightIcon : ChevronLeftIcon}
                onClick={() => setCollapsed(prev => !prev)}
              />
            </CollapsibleIconWrapper>
          )}
          <LogoWrapper url={props.homeUrl} linkType={props.homeLinkType}>
            {props.logo}
          </LogoWrapper>
          <ScrollContainer role="menubar" aria-orientation="vertical">
            {props.children}
          </ScrollContainer>
          <StyledFooter>{props.footer?.({ collapsed })}</StyledFooter>
        </StyledSurface>
      </NavigationStateContext.Provider>
    </YukaThemeProvider>
  );
};

GlobalNavigation.propTypes = {
  /* URL to navigate to if logo clicked */
  homeUrl: PropTypes.string,
  homeLinkType: PropTypes.oneOf(Object.values(LinkTypes)),
  /* Site logo */
  logo: PropTypes.element.isRequired,
  children: PropTypes.node.isRequired,
  /* can we collapse the global nav to just icons? */
  collapsible: PropTypes.bool,
  /* render function, passes object with `collapsed` state */
  footer: PropTypes.func,
};

GlobalNavigation.defaultProps = {
  homeUrl: "/",
  homeLinkType: LinkTypes.LOCAL_LINK,
};

export default GlobalNavigation;
