import React from "react";
import PropTypes from "prop-types";
import { Avatar } from "yuka";

/**
 * Returns the user's thumbnail in a circular container with the given
 * dimensions.
 *
 * @returns {React.Element}
 *
 */

const UserAvatar = (props) => {
  const profilePicture = props.user?.thumbnail;
  const defaultPicture = `${props.user?.first_name?.charAt(0) ?? ""}${
    props.user?.last_name?.charAt(0) ?? ""
  }`;

  return (
    <Avatar
      avatar={profilePicture}
      avatarAlt={defaultPicture}
      colorId={props.user?.id ? props.user.id : 0}
      size={props.size}
    />
  );
};

UserAvatar.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    thumbnail: PropTypes.string,
  }).isRequired,
  size: PropTypes.string,
};

UserAvatar.defaultProps = {
  size: "32px",
};

export default UserAvatar;
