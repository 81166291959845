/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``chevron-up.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ChevronUpIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M8.46967 6.21967C8.76256 5.92678 9.23744 5.92678 9.53033 6.21967L14.0303 10.7197C14.3232 11.0126 14.3232 11.4874 14.0303 11.7803C13.7374 12.0732 13.2626 12.0732 12.9697 11.7803L9 7.81066L5.03033 11.7803C4.73744 12.0732 4.26256 12.0732 3.96967 11.7803C3.67678 11.4874 3.67678 11.0126 3.96967 10.7197L8.46967 6.21967Z"
        transform=""
      />
    </g>
  </svg>
);

ChevronUpIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ChevronUpIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

ChevronUpIcon.displayName = "ChevronUpIcon";

export default ChevronUpIcon;
