/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``dollar.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const DollarIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M9 0.75C9.41421 0.75 9.75 1.08579 9.75 1.5V2.25H10.5C12.5711 2.25 14.25 3.92893 14.25 6C14.25 6.41421 13.9142 6.75 13.5 6.75C13.0858 6.75 12.75 6.41421 12.75 6C12.75 4.75736 11.7426 3.75 10.5 3.75H9.75V8.25H10.5C12.5711 8.25 14.25 9.92893 14.25 12C14.25 14.0711 12.5711 15.75 10.5 15.75H9.75V16.5C9.75 16.9142 9.41421 17.25 9 17.25C8.58579 17.25 8.25 16.9142 8.25 16.5V15.75H7.5C5.42893 15.75 3.75 14.0711 3.75 12C3.75 11.5858 4.08579 11.25 4.5 11.25C4.91421 11.25 5.25 11.5858 5.25 12C5.25 13.2426 6.25736 14.25 7.5 14.25H8.25V9.75H7.5C5.42893 9.75 3.75 8.07107 3.75 6C3.75 3.92893 5.42893 2.25 7.5 2.25H8.25V1.5C8.25 1.08579 8.58579 0.75 9 0.75ZM8.25 3.75H7.5C6.25736 3.75 5.25 4.75736 5.25 6C5.25 7.24264 6.25736 8.25 7.5 8.25H8.25V3.75ZM9.75 9.75V14.25H10.5C11.7426 14.25 12.75 13.2426 12.75 12C12.75 10.7574 11.7426 9.75 10.5 9.75H9.75Z"
        transform=""
      />
    </g>
  </svg>
);

DollarIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

DollarIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

DollarIcon.displayName = "DollarIcon";

export default DollarIcon;
