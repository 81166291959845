/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``moon.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const MoonIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M8.9983 1.30776C9.10487 1.64477 8.96168 2.01046 8.65459 2.18549C7.0674 3.09015 6 4.79603 6 6.75027C6 9.64977 8.35051 12.0003 11.25 12.0003C13.2041 12.0003 14.9099 10.933 15.8146 9.34605C15.9896 9.03898 16.3553 8.89581 16.6923 9.00241C17.0293 9.10902 17.2462 9.43646 17.2128 9.78834C16.8155 13.9753 13.2906 17.2501 9 17.2501C4.44365 17.2501 0.75 13.5564 0.75 9.00009C0.75 4.70926 4.02513 1.18427 8.21241 0.787251C8.5643 0.753886 8.89172 0.970743 8.9983 1.30776ZM5.48632 3.23544C3.54557 4.42104 2.25 6.55948 2.25 9.00009C2.25 12.728 5.27208 15.7501 9 15.7501C11.4404 15.7501 13.5787 14.4547 14.7644 12.5142C13.7409 13.1396 12.5377 13.5003 11.25 13.5003C7.52208 13.5003 4.5 10.4782 4.5 6.75027C4.5 5.46237 4.86079 4.25898 5.48632 3.23544Z"
        transform=""
      />
    </g>
  </svg>
);

MoonIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

MoonIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

MoonIcon.displayName = "MoonIcon";

export default MoonIcon;
