import React from "react";
import { createForm } from "final-form";
import { Form, FormSpy } from "react-final-form";
import PropTypes from "prop-types";

import Filter from "./Filter";
import useFilter from "./internal/useFilter";

const FinalFormFilter = ({
  formName,
  onChange: clientOnChange,
  initialValues,
  values,
  persist = true,
  ...props
}) => {
  const formApi = createForm({ initialValues, onSubmit: () => null });
  const onChange = useFilter(formName, {
    formApi,
    onChange: clientOnChange,
    persist,
    initialValues,
    values,
  });

  return (
    <Form form={formApi}>
      {({ form: { reset }, dirty }) => (
        <React.Fragment>
          <FormSpy subscription={{ values: true }} onChange={onChange} />
          <Filter {...props} resetDisabled={!dirty} onReset={reset} />
        </React.Fragment>
      )}
    </Form>
  );
};

FinalFormFilter.propTypes = {
  formName: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  /** Only observed on mount - set default filters that override previous values */
  values: PropTypes.object,
  /** Should the form values be stored after unmount */
  persist: PropTypes.bool,
  onChange: PropTypes.func,
};

FinalFormFilter.displayName = "FinalFormFilter";

export default FinalFormFilter;
