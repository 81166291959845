import { DateTime } from "luxon";
import { useMemo } from "react";

/**
 * Custom hook to generate the x-axis ticks for the company profile line graph. We prefer to show
 * close to 12 ticks if the date range spans > 12 months of data. We take an even distribution of
 * the dates so that the spaces between the dates are equal. If the date range spans < 12 months
 * of data, we show all the dates.
 *
 * We will derive our x-axis ticks from the main company data and also dates for the relevant
 * comparisons that are applied, since a company's data might not stretch far enough back into
 * the past/the future to provide the ticks for the selected range.
 *
 * @param companyData
 * @param comparisonData
 * @return {Array<string>} - List of dates to show on the x-axis.
 */
const useXAxisTicks = (companyData, comparisonData) =>
  useMemo(() => {
    // Get the first report_period in each month for the companyData
    // let curComparisonDate = comparisonDates?.[0];
    // let curCompanyDate = companyData?.data?.[0].time;
    let allDates = new Set();
    if (companyData?.data) {
      for (let i = 0; i < companyData.data.length; i++) {
        allDates.add(companyData.data[i].time);
      }
    }
    comparisonData?.forEach((comparison) => {
      for (let i = 0; i < comparison?.data?.length; i++) {
        allDates.add(comparison?.data[i].time);
      }
    });
    let sortedDates = Array.from(allDates).sort();
    const dates = [];
    let lastDate = null;
    for (let i = 0; i < sortedDates.length; i++) {
      const date = DateTime.fromISO(sortedDates[i]);
      if (lastDate === null || date.month !== lastDate.month) {
        dates.push(date.toISODate());
        lastDate = date;
      }
    }

    // If dates is larger than 12, ensure we get exactly 12 evenly distributed dates.
    if (dates.length > 12) {
      const sampleSize = Math.floor(dates.length / 12);
      return dates.filter((_, i) => i % sampleSize === 0);
    }
    return dates;
  }, [companyData, comparisonData]);

export default useXAxisTicks;
