/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``arrows-up.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ArrowsUpIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M4.71967 2.46967C5.01256 2.17678 5.48744 2.17678 5.78033 2.46967L8.78033 5.46967C9.07322 5.76256 9.07322 6.23744 8.78033 6.53033C8.48744 6.82322 8.01256 6.82322 7.71967 6.53033L6 4.81066V15C6 15.4142 5.66421 15.75 5.25 15.75C4.83579 15.75 4.5 15.4142 4.5 15V4.81066L2.78033 6.53033C2.48744 6.82322 2.01256 6.82322 1.71967 6.53033C1.42678 6.23744 1.42678 5.76256 1.71967 5.46967L4.71967 2.46967ZM12.2197 6.21967C12.5126 5.92678 12.9874 5.92678 13.2803 6.21967L16.2803 9.21967C16.5732 9.51256 16.5732 9.98744 16.2803 10.2803C15.9874 10.5732 15.5126 10.5732 15.2197 10.2803L13.5 8.56066V15C13.5 15.4142 13.1642 15.75 12.75 15.75C12.3358 15.75 12 15.4142 12 15V8.56066L10.2803 10.2803C9.98744 10.5732 9.51256 10.5732 9.21967 10.2803C8.92678 9.98744 8.92678 9.51256 9.21967 9.21967L12.2197 6.21967Z"
        transform=""
      />
    </g>
  </svg>
);

ArrowsUpIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ArrowsUpIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

ArrowsUpIcon.displayName = "ArrowsUpIcon";

export default ArrowsUpIcon;
