/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``target.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const TargetIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M14.3701 0.759686C14.6081 0.798303 14.813 0.94896 14.9208 1.16459L15.559 2.44098L16.8354 3.07918C17.051 3.187 17.2017 3.39189 17.2403 3.62986C17.2789 3.86783 17.2008 4.10986 17.0303 4.28033L14.7803 6.53033C14.6396 6.67098 14.4489 6.75 14.25 6.75H12.3106L9.5303 9.53029C9.23741 9.82318 8.76254 9.82318 8.46965 9.53029C8.17675 9.23739 8.17676 8.76252 8.46965 8.46963L11.25 5.68934V3.75C11.25 3.55109 11.329 3.36032 11.4696 3.21967L13.7196 0.969671C13.8901 0.7992 14.1321 0.721069 14.3701 0.759686ZM12.75 5.25H13.9393L15.2338 3.95546L14.6646 3.67082C14.5194 3.59825 14.4017 3.48056 14.3291 3.33541L14.0445 2.76612L12.75 4.06066V5.25ZM9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 8.58579 16.0858 8.25 16.5 8.25C16.9142 8.25 17.25 8.58579 17.25 9C17.25 13.5563 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5563 0.75 9C0.75 4.44365 4.44365 0.750001 9 0.750001C9.41421 0.750001 9.75 1.08579 9.75 1.5C9.75 1.91421 9.41421 2.25 9 2.25ZM9 6C7.34315 6 6 7.34315 6 9C6 10.6569 7.34315 12 9 12C10.6569 12 12 10.6569 12 9C12 8.58579 12.3358 8.25 12.75 8.25C13.1642 8.25 13.5 8.58579 13.5 9C13.5 11.4853 11.4853 13.5 9 13.5C6.51472 13.5 4.5 11.4853 4.5 9C4.5 6.51472 6.51472 4.5 9 4.5C9.41421 4.5 9.75 4.83579 9.75 5.25C9.75 5.66421 9.41421 6 9 6Z"
        transform=""
      />
    </g>
  </svg>
);

TargetIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

TargetIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

TargetIcon.displayName = "TargetIcon";

export default TargetIcon;
