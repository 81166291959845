import styled from "styled-components";
import React from "react";

import { FontColors } from "yuka";

const UnhighlightedText = styled.span`
  ${FontColors.theme30}
`;

/**
 * Based on a search, highlight the matching sections of text.
 *
 * Note that the current styles have the "highlighted" text color match the default;
 * we are actually just dimming the rest of the text
 *
 * @param {string} text
 * @param {string} search
 * @param {React.Element?} HighlightElement - Optional element to use to render the text portion
 *  that matches the search term. Defaults to a regular <span> that will inherit styling.
 * @param {React.Element?} NoMatchElement - Optional element to render in place of the text
 *  when the search term is not present. Defaults to a regular <span> that will inherit styling.
 *
 * @returns {React.Fragment}
 */
const highlightText = (
  text,
  search,
  HighlightElement = null,
  NoMatchElement = null
) => {
  const lowerText = text.toLowerCase();

  // Return unchanged text if search term is not present
  if (!search.trim() || !lowerText.includes(search.toLowerCase().trim())) {
    return NoMatchElement ? (
      <NoMatchElement>{text}</NoMatchElement>
    ) : (
      <span>{text}</span>
    );
  }

  const tokens = search.split(" ");
  const indexes = tokens
    .map((token) => ({ index: lowerText.indexOf(token.toLowerCase()), token }))
    .sort((a, b) => a.index - b.index);
  const resultSegments = [];
  let prevEnd = 0;

  indexes.forEach(({ index, token }) => {
    if (index > prevEnd) {
      resultSegments.push(
        <UnhighlightedText>{text.slice(prevEnd, index)}</UnhighlightedText>
      );
      prevEnd = index;
    }
    if (index + token.length > prevEnd) {
      const textSlice = text.slice(prevEnd, index + token.length);

      resultSegments.push(
        HighlightElement ? (
          <HighlightElement>{textSlice}</HighlightElement>
        ) : (
          <span>{textSlice}</span>
        )
      );
      prevEnd = index + token.length;
    }
  });

  if (prevEnd < text.length) {
    resultSegments.push(
      <UnhighlightedText>{text.slice(prevEnd)}</UnhighlightedText>
    );
  }
  return <React.Fragment>{resultSegments}</React.Fragment>;
};

export default highlightText;
