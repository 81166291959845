import React from "react";
import PropTypes from "prop-types";
import { useForm, useFormState } from "react-final-form";
import { useNavigate } from "react-router-dom";

import FullPageForm from "./FullPageForm";
import ControlledWizard from "./ControlledWizard";
import Wizard from "./Wizard";

const useManagedResolution = (requireDirtyForSubmit, requireValidForSubmit) => {
  const { submit } = useForm();
  const navigate = useNavigate();
  const { valid, submitting, dirty } = useFormState();

  return {
    submitDisabled:
      (requireValidForSubmit && !valid) || (requireDirtyForSubmit && !dirty) || submitting,
    onSubmit: submit,
    onClose: () => navigate(-1),
  };
};

export const FinalFormFullPageForm = ({
  requireDirtyForSubmit,
  requireValidForSubmit,
  ...props
}) => {
  const config = useManagedResolution(requireDirtyForSubmit, requireValidForSubmit);
  return <FullPageForm {...config} {...props} />;
};

FinalFormFullPageForm.displayName = "FinalFormFullPageForm";
FinalFormFullPageForm.propTypes = {
  requireDirtyForSubmit: PropTypes.bool,
  requireValidForSubmit: PropTypes.bool,
};
FinalFormFullPageForm.defaultProps = {
  requireDirtyForSubmit: true,
  requireValidForSubmit: true,
};

export const FinalFormControlledWizard = ({
  requireDirtyForSubmit,
  requireValidForSubmit,
  ...props
}) => {
  const config = useManagedResolution(requireDirtyForSubmit, requireValidForSubmit);
  return <ControlledWizard {...config} {...props} />;
};

FinalFormControlledWizard.displayName = "FinalFormControlledWizard";
FinalFormControlledWizard.propTypes = {
  requireDirtyForSubmit: PropTypes.bool,
  requireValidForSubmit: PropTypes.bool,
};
FinalFormControlledWizard.defaultProps = {
  requireDirtyForSubmit: true,
  requireValidForSubmit: true,
};

export const FinalFormWizard = ({
  requireDirtyForSubmit,
  requireValidForSubmit,
  steps,
  ...props
}) => {
  const config = useManagedResolution(requireDirtyForSubmit, requireValidForSubmit);
  const { values } = useFormState();

  const wrappedSteps = React.useMemo(
    () =>
      steps.map(step => ({
        ...step,
        onAdvance: step.onAdvance ? () => step.onAdvance(values) : undefined,
      })),
    [steps, values]
  );

  return <Wizard {...config} {...props} steps={wrappedSteps} />;
};

FinalFormWizard.displayName = "FinalFormWizard";
FinalFormWizard.propTypes = {
  requireDirtyForSubmit: PropTypes.bool,
  requireValidForSubmit: PropTypes.bool,
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
};
FinalFormWizard.defaultProps = {
  requireDirtyForSubmit: true,
  requireValidForSubmit: true,
};
