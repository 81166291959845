/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``activity.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ActivityIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M6.75 1.5C7.07282 1.5 7.35943 1.70657 7.46151 2.01283L11.25 13.3783L12.7885 8.76283C12.8906 8.45657 13.1772 8.25 13.5 8.25H16.5C16.9142 8.25 17.25 8.58579 17.25 9C17.25 9.41421 16.9142 9.75 16.5 9.75H14.0406L11.9615 15.9872C11.8594 16.2934 11.5728 16.5 11.25 16.5C10.9272 16.5 10.6406 16.2934 10.5385 15.9872L6.75 4.62171L5.21151 9.23717C5.10943 9.54343 4.82282 9.75 4.5 9.75H1.5C1.08579 9.75 0.75 9.41421 0.75 9C0.75 8.58579 1.08579 8.25 1.5 8.25H3.95943L6.03849 2.01283C6.14057 1.70657 6.42718 1.5 6.75 1.5Z"
        transform=""
      />
    </g>
  </svg>
);

ActivityIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ActivityIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

ActivityIcon.displayName = "ActivityIcon";

export default ActivityIcon;
