import { API_TYPE_TO_SERIES_TYPE } from "../constants";

const _mapGenericSecurityToRelatedObject = (security) =>
  security.public_security ||
  security.private_company ||
  security.private_market_index;

const mapGenericSecurityToSeriesType = (security) =>
  API_TYPE_TO_SERIES_TYPE[_mapGenericSecurityToRelatedObject(security)[0]];

const mapGenericSecurityToId = (security) =>
  _mapGenericSecurityToRelatedObject(security)[1];

export { mapGenericSecurityToSeriesType, mapGenericSecurityToId };
