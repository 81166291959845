import _ from "lodash";

import { HALF_SECOND } from "../utils/constants";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";

/*
 * Helper to debounce the modification mixpanel event for the field each of the editable components
 * uses.
 */
const debouncedModifyDefaultComponentValue = _.debounce(
  (companyName, fieldName, fieldType, defaultValue, newValue) =>
    MixpanelEvents.modifyDefaultComponentValue(
      companyName,
      fieldName,
      fieldType,
      defaultValue,
      newValue
    ),
  HALF_SECOND,
  { leading: false, trailing: true }
);

const debouncedModifyCustomComponentName = _.debounce(
  (companyName, oldName, newName) =>
    MixpanelEvents.modifyCustomCriterionName(companyName, oldName, newName),
  HALF_SECOND,
  { leading: false, trailing: true }
);

const debouncedModifyCustomComponentValue = _.debounce(
  (companyName, componentName, oldValue, newValue) =>
    MixpanelEvents.modifyCustomCriterionValue(
      companyName,
      componentName,
      oldValue,
      newValue
    ),
  HALF_SECOND,
  { leading: false, trailing: true }
);

export {
  debouncedModifyDefaultComponentValue,
  debouncedModifyCustomComponentName,
  debouncedModifyCustomComponentValue,
};
