/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``compass.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const CompassIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25ZM0.75 9C0.75 4.44365 4.44365 0.75 9 0.75C13.5563 0.75 17.25 4.44365 17.25 9C17.25 13.5563 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5563 0.75 9ZM10.9941 7.00585L8.00293 8.00293L7.00585 10.9941L9.99707 9.99707L10.9941 7.00585ZM11.2975 5.33739C11.4344 5.3052 11.6907 5.25548 11.9649 5.35328C12.283 5.4667 12.5333 5.717 12.6467 6.03506C12.7445 6.30933 12.6948 6.56557 12.6626 6.70254C12.6295 6.8434 12.5741 7.00942 12.5203 7.17074C12.5176 7.17904 12.5148 7.18732 12.512 7.19559L11.3964 10.5426C11.3944 10.5485 11.3922 10.5553 11.3897 10.563C11.3655 10.6377 11.3153 10.7924 11.2221 10.9287C11.1433 11.0438 11.0438 11.1433 10.9287 11.2221C10.7924 11.3153 10.6377 11.3655 10.563 11.3897C10.5553 11.3922 10.5485 11.3944 10.5426 11.3964L7.17074 12.5203C7.00942 12.5741 6.84339 12.6295 6.70254 12.6626C6.56557 12.6948 6.30933 12.7445 6.03506 12.6467C5.717 12.5333 5.4667 12.283 5.35328 11.9649C5.25548 11.6907 5.3052 11.4344 5.33739 11.2975C5.37048 11.1566 5.42586 10.9906 5.47967 10.8293C5.48244 10.821 5.48521 10.8127 5.48796 10.8044L6.60362 7.45743C6.60558 7.45154 6.6078 7.44469 6.6103 7.43699C6.63453 7.36227 6.68467 7.20762 6.77792 7.0713C6.85668 6.95616 6.95616 6.85668 7.0713 6.77792C7.20762 6.68467 7.36227 6.63453 7.43699 6.6103C7.44469 6.6078 7.45154 6.60558 7.45743 6.60362L10.8044 5.48796C10.8127 5.48521 10.8209 5.48245 10.8292 5.47968C10.9906 5.42587 11.1566 5.37049 11.2975 5.33739Z"
        transform=""
      />
    </g>
  </svg>
);

CompassIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

CompassIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

CompassIcon.displayName = "CompassIcon";

export default CompassIcon;
