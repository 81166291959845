/*
 * styled-components css definitions that will be passed into the yuka theme provider at various
 * levels to customize the look and feel of our components.
 */
import { css } from "styled-components";
import { ColorPalette } from "yuka";

import { DataverseColors } from "./constants";

const KEY_ACTION_BUTTON_CONTAINER = css`
  border-radius: 12px;
  padding: 0 12px;
  height: 40px;
`;

const KEY_ACTION_ICON_BUTTON_CONTAINER = css`
  border-radius: 12px;
  padding: 11px;
  width: 40px;
  height: 40px;
`;

const KEY_ACTION_SELECTED_DEFAULT = css`
  background: ${DataverseColors.d300};
  color: ${ColorPalette.white80};
  border: none;
  svg path {
    fill: ${ColorPalette.white50};
  }
`;

const KEY_ACTION_SELECTED_HOVER = css`
  background: ${DataverseColors.d200};
  color: ${ColorPalette.white80};
  svg path {
    fill: ${ColorPalette.white50};
  }
`;

const KEY_ACTION_SELECTED_ACTIVE = css`
  background: ${DataverseColors.d100};
  color: ${ColorPalette.white80};
  svg path {
    fill: ${ColorPalette.white50};
  }
`;

export {
  KEY_ACTION_BUTTON_CONTAINER,
  KEY_ACTION_ICON_BUTTON_CONTAINER,
  KEY_ACTION_SELECTED_DEFAULT,
  KEY_ACTION_SELECTED_HOVER,
  KEY_ACTION_SELECTED_ACTIVE,
};
