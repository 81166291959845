/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``pin-filled.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const PinFilledIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule=""
        d="M9.66561 1.24573C9.99615 1.02894 10.3989 0.951373 10.7863 1.02991C11.0788 1.0892 11.3023 1.24678 11.4688 1.3856C11.6279 1.51827 11.8061 1.69647 11.9979 1.88827L11.9979 1.88831L15.8973 5.78771L15.8973 5.78772C16.0891 5.97949 16.2674 6.15767 16.4 6.31683C16.5388 6.48337 16.6964 6.70684 16.7557 6.99932C16.8343 7.38673 16.7567 7.78948 16.5399 8.12002C16.3762 8.36956 16.1469 8.51852 15.9562 8.62159C15.7739 8.7201 15.5422 8.81936 15.2929 8.92619L13.3948 9.73964C13.3522 9.7579 13.3314 9.76685 13.3164 9.77368L13.3154 9.77415L13.3146 9.77492C13.3027 9.78631 13.2867 9.80227 13.2539 9.83505L12.0832 11.0057C12.032 11.0569 12.0068 11.0822 11.9891 11.1012L11.9879 11.1026L11.9874 11.1044C11.9815 11.1297 11.9744 11.1646 11.9601 11.2357L11.4041 14.0158C11.3382 14.3456 11.2789 14.6421 11.2098 14.8749C11.1406 15.1078 11.0267 15.4088 10.7703 15.6363C10.4422 15.9274 10.0032 16.0604 9.56872 16.0001C9.22908 15.953 8.96747 15.7658 8.78067 15.6104C8.59402 15.455 8.38022 15.2412 8.14245 15.0034L8.14244 15.0034L5.99265 12.8536L2.28033 16.5659C1.98744 16.8588 1.51256 16.8588 1.21967 16.5659C0.926777 16.273 0.926777 15.7982 1.21967 15.5053L4.93199 11.7929L2.78225 9.6432L2.78222 9.64317C2.54442 9.40541 2.33059 9.1916 2.17527 9.00496C2.01984 8.81816 1.83258 8.55655 1.7855 8.21691C1.72527 7.78247 1.8582 7.34343 2.1493 7.01537C2.37687 6.75889 2.67779 6.64508 2.91074 6.57588C3.14351 6.50673 3.44005 6.44744 3.76982 6.38151L6.54997 5.82548C6.62099 5.81127 6.65596 5.80418 6.68124 5.7982L6.68304 5.79777L6.68439 5.79652C6.70339 5.7788 6.72869 5.75364 6.77991 5.70242L7.95058 4.53175C7.98336 4.49897 7.99932 4.48295 8.01071 4.47103L8.01148 4.47022L8.01195 4.4692C8.01878 4.4542 8.02773 4.43343 8.04599 4.39082L8.85945 2.49275C8.96628 2.24342 9.06553 2.01176 9.16404 1.82946C9.26711 1.63872 9.41607 1.40941 9.66561 1.24573Z"
        transform=""
      />
    </g>
  </svg>
);

PinFilledIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

PinFilledIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

PinFilledIcon.displayName = "PinFilledIcon";

export default PinFilledIcon;
