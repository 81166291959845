import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import {
  List,
  ListItem,
  useDropdown,
  Fonts,
  FinalFormField,
  ColorPalette,
  Input,
} from "yuka";

import { API_ENDPOINTS } from "../api/constants";
import useFetch from "../api/useFetch";
import { KEY_ENTER } from "../utils/useHotkeys";

const StyledDropdownContent = styled.div`
  width: 300px;
  margin-top: -4px;
  max-height: 184px;
`;

const StyledHr = styled.hr`
  border: none;
  margin-top: 8px;
  border-bottom: 1px solid ${ColorPalette.white15};
`;

const StyledCustomSecurityFooter = styled.div`
  padding: 16px 12px;
  white-space: wrap;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledListItem = styled(ListItem)`
  padding: 0 12px;
  height: 32px;
  min-height: 32px;
`;

const StyledField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  > * {
    line-height: 18px;
  }
`;

const Component = (props) => {
  const inputRef = useRef(null);

  const { onChange, value, disabled } = props;

  const SecuritySelectDropdown = useCallback(
    ({ toggleIsOpen }) => {
      const handleKeyPress = (event) => {
        if (event.key === KEY_ENTER) {
          onChange(event.target.value);
          toggleIsOpen();
        }
      };

      return (
        <StyledDropdownContent>
          <List>
            {props.options.map((option) => (
              <StyledListItem
                onClick={() => {
                  onChange(option.label);
                  toggleIsOpen();
                }}
                key={option.value}
                text={option.label}
              />
            ))}
          </List>
          <StyledHr />
          <StyledCustomSecurityFooter>
            <Fonts.Body1theme50>
              If you can't find a specific security in the list above, please
              enter it below
            </Fonts.Body1theme50>
            <Input
              onKeyDown={handleKeyPress}
              disabled={disabled}
              small
              onChange={(e) => onChange(e.target.value)}
            />
          </StyledCustomSecurityFooter>
        </StyledDropdownContent>
      );
    },
    [disabled, onChange, props.options]
  );

  const [dropdownElement, dropdownRef, toggleDropdown] = useDropdown(
    (dropdownProps) => <SecuritySelectDropdown {...dropdownProps} />
  );

  useEffect(() => {
    if (dropdownElement) {
      inputRef?.current.blur();
    }
  }, [dropdownElement]);

  return (
    <StyledField
      ref={dropdownRef}
      onClick={
        disabled
          ? null
          : () => {
              dropdownRef.current?.blur();
              if (!dropdownElement) {
                toggleDropdown();
              }
            }
      }
    >
      <Input
        placeholder="Select security"
        disabled={disabled}
        ref={inputRef}
        value={value}
      />
      {dropdownElement}
    </StyledField>
  );
};

Component.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

/**
 * A field that allows the user to select a security from a list of securities, or enter their
 * own if they don't find the one they're looking for.
 *
 * @param includeLabel
 * @param disabled
 * @param companyId
 * @param props
 * @return {JSX.Element}
 */
const SecuritySelectField = ({
  includeLabel,
  disabled,
  companyId,
  ...props
}) => {
  const hdFundingRoundsQuery = useFetch(
    API_ENDPOINTS.HD_FUNDING_ROUNDS(),
    {
      company: companyId,
      "page[size]": 10000,
    },
    {
      enabled: Boolean(companyId),
    }
  );

  const securityOptions = useMemo(() => {
    if (hdFundingRoundsQuery.isSuccess) {
      return hdFundingRoundsQuery.cleanedData.data
        .filter((fundingRound) => Boolean(fundingRound.security))
        .map((fundingRound) => ({
          label: fundingRound.security,
          value: fundingRound.apiId,
        }));
    }
    return [];
  }, [hdFundingRoundsQuery.isSuccess, hdFundingRoundsQuery.cleanedData?.data]);

  return (
    <FinalFormField
      type="custom-block"
      label={includeLabel ? "Security" : undefined}
      name="security"
      disabled={disabled}
      options={securityOptions}
      component={Component}
      parse={(value) =>
        hdFundingRoundsQuery.cleanedData?.data.find(
          (fundingRound) => fundingRound.apiId === value
        )?.security || value
      }
      {...props}
    />
  );
};

SecuritySelectField.propTypes = {
  includeLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  companyId: PropTypes.string.isRequired,
};

export default SecuritySelectField;
