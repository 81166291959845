import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import InputWrapper from "./internal/InputWrapper";
import { StyledInput } from "./internal/StyledComponents";

/**
 * A component that renders a standard text input
 */
const Input = React.forwardRef((props, ref) => {
  const internalRef = React.useRef(null);
  return (
    <InputWrapper {...props} inputRef={ref || internalRef}>
      <StyledInput
        ref={ref || internalRef}
        {..._.omit(props, ["leadingIcon", "trailingIcon", "showError", "className", "style"])}
      />
    </InputWrapper>
  );
});

Input.propTypes = {
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  /**
   * If true, disables the input field.
   */
  disabled: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  /**
   * The initial value of the input.
   */
  value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  /**
   * Function to be executed by an onChange event.
   */
  onChange: PropTypes.func,
  type: PropTypes.string,
  /**
   * Yuka Icon to be displayed on the left side of the input field.
   */
  leadingIcon: PropTypes.func,
  /**
   * Text that will appear if nothing has been entered in the input.
   */
  placeholder: PropTypes.string,
  /**
   * Icon to be displayed on the right side of the input filed.
   */
  trailingIcon: PropTypes.func,
  /**
   * When true, an error message is shown.
   */
  showError: PropTypes.bool,
  /**
   * When true, the input is reduced in size
   */
  small: PropTypes.bool,
};

Input.defaultProps = {
  name: null,
  autoComplete: "off",
  disabled: false,
  helpText: null,
  leadingIcon: null,
  placeholder: "",
  style: null,
  trailingIcon: null,
  showError: false,
  small: false,
};

Input.displayName = "Input";

export default Input;
