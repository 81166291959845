import _ from "lodash";
import { useField } from "react-final-form";

/**
 * Convenience hook to grab the value of a single field within the current form.
 *
 * This can only be used within a form context and will crash otherwise.
 *
 * @param {string} fieldName - the field you want to get the value of
 * @param {any} defaultValue - if value is null/undefined, this value will be returned as a fallback
 *
 * @returns {any}
 */
const useFormValue = (fieldName, defaultValue) => {
  // Subscribe to updates to the field's value only, extract it from the returned shape
  const {
    input: { value },
  } = useField(fieldName, { subscription: { value: true } });

  // A 0 or false should be passed through, but null/undefined should not
  if ((_.isNil(value) || value === "") && !_.isUndefined(defaultValue)) {
    return defaultValue;
  }

  return value;
};

export default useFormValue;
