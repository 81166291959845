import styled from "styled-components";
import PropTypes from "prop-types";
import React from "react";
import { BookmarkIcon, BookmarkFilledIcon } from "../../Icons";
import { ColorPalette } from "../../StylingConstants";
import { getThemeColor, useYukaTheme } from "../../ThemeContext";

const CursorSpan = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

/*
 * Has been superseded by ToggleIconCell, so avoid using this if possible. ToggleIconCell is just
 * a more customizable version of this cell.
 */
const BookmarkCell = ({ value, row, column }) => {
  const theme = useYukaTheme();

  return (
    <CursorSpan onClick={event => column.onBookmark({ row, column, value: !value, event })}>
      {value ? (
        <BookmarkFilledIcon color={getThemeColor(theme, "branding400")} />
      ) : (
        <BookmarkIcon color={ColorPalette.white50} />
      )}
    </CursorSpan>
  );
};

BookmarkCell.propTypes = {
  value: PropTypes.bool.isRequired,
  row: PropTypes.shape({}),
  column: PropTypes.shape({
    onBookmark: PropTypes.func.isRequired,
  }).isRequired,
};

BookmarkCell.displayName = "BookmarkCell";

export default BookmarkCell;
