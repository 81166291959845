/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``arrow-left.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ArrowLeftIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M9.53033 3.21967C9.82322 3.51256 9.82322 3.98744 9.53033 4.28033L5.56066 8.25H14.25C14.6642 8.25 15 8.58579 15 9C15 9.41421 14.6642 9.75 14.25 9.75H5.56066L9.53033 13.7197C9.82322 14.0126 9.82322 14.4874 9.53033 14.7803C9.23744 15.0732 8.76256 15.0732 8.46967 14.7803L3.21967 9.53033C2.92678 9.23744 2.92678 8.76256 3.21967 8.46967L8.46967 3.21967C8.76256 2.92678 9.23744 2.92678 9.53033 3.21967Z"
        transform=""
      />
    </g>
  </svg>
);

ArrowLeftIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ArrowLeftIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

ArrowLeftIcon.displayName = "ArrowLeftIcon";

export default ArrowLeftIcon;
