import _ from "lodash";
import { DateTime } from "luxon";

import { PRICE_DATA_START_DATES, TWO_YEAR_KEY } from "../constants";

/**
 * Creates slices of the provided timeseries data corresponding to the length of data needed
 * for each selectable date range on the superchart (3 months, 6 months, 1 year, YTD, 2 years).
 *
 * `data` is 2 years of price data in descending order such that the 0th element is from today,
 * that way the function will return an object of this form:
 *
 * {
 *   3M: data[0:i]
 *   6M: data[0:j]
 *   1Y: data[0:k]
 *   YTD: data[0:l]
 *   2Y: data
 * } where `i` is the index that takes us to the data point from 3 months ago, j is 6mo, etc.
 *
 * @param {Array<object>} data - an array of raw price data
 * @param {string} dateKey - the name of the date field in the data elements
 * @returns {object} dateDividedData - splices of `data` as described above
 */
const getDateDividedData = (data, dateKey) => {
  // find indices of the rawPriceData array that correspond to the first data points in
  // the chartable data for last 3 months, 6 months, 1 year, and YTD.
  const cutOffIndices = _.mapValues(PRICE_DATA_START_DATES, (startDate) => {
    const index = _.findIndex(
      data,
      (datum) => DateTime.fromISO(datum[dateKey]) < startDate
    );
    // findIndex returns -1 when the item isn't found; in these cases we want all the data
    return index === -1 ? data.length : index;
  });

  return {
    ..._.mapValues(cutOffIndices, (index) => data.slice(0, index)),
    [TWO_YEAR_KEY]: data,
  };
};

export default getDateDividedData;
