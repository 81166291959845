import PropTypes from "prop-types";
import React from "react";

import ControlledWizard from "./ControlledWizard";
import useWizardSteps from "./internal/useWizardSteps";

import { LinkTypes } from "../Button/getLinkProps";

/**
 * An interface for displaying fully managed multi step forms. Use this when you don't wish to
 * manage the state for the progress of the form.
 */
const Wizard = ({
  steps,
  initialStep,
  onSubmit,
  onClickAlt,
  submitText,
  advanceText,
  ...props
}) => {
  const config = useWizardSteps(steps, {
    initialStep,
    onSubmit,
    onClickAlt,
    submitText,
    advanceText,
  });

  return <ControlledWizard {...props} {...config} />;
};

Wizard.propTypes = {
  /* Main configuration */
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      leftContent: PropTypes.node,
      rightContent: PropTypes.node,
      onAdvance: PropTypes.func,
    })
  ).isRequired,
  initialStep: PropTypes.number,
  /*
   * Form header. This accepts nodes in order to support custom typography formatting, but is not
   * designed for more complex elements.
   */
  title: PropTypes.node.isRequired,
  /* Disclaimers and other footer content */
  footer: PropTypes.node,
  /*
   * Elements that show a summary of the state of the form. Is displayed below the right side
   * content if both are specified
   */
  summary: PropTypes.node,
  /* Site logo */
  logo: PropTypes.element.isRequired,
  /* URL to navigate to if logo clicked */
  homeUrl: PropTypes.string,
  homeLinkType: PropTypes.oneOf(Object.values(LinkTypes)),
  /* Close event handler */
  onClose: PropTypes.func.isRequired,
  /* Text to display for a submit button */
  submitText: PropTypes.string,
  /* Text to display for an advance button */
  advanceText: PropTypes.string,
  /* Submit event handler */
  onSubmit: PropTypes.func.isRequired,
  /* Text to display for an alternate action. Only applicable if using the default wizard submit */
  onClickAlt: PropTypes.func,
  /* Alternate action event handler. Only applicable if using the default wizard submit */
  altText: PropTypes.string,
};

Wizard.defaultProps = {
  homeUrl: "/",
  homeLinkType: LinkTypes.LOCAL_LINK,
  initialStep: 1,
  submitText: "Submit",
  advanceText: "Next",
  altText: "Back",
};

Wizard.displayName = "Wizard";

export default Wizard;
