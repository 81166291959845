import PropTypes from "prop-types";
import React from "react";
import { Checkbox } from "../../FormField";

const CheckboxCell = ({ row, column }) => {
  return (
    <label htmlFor={`${column?.id}row_checkbox_${row.index}`}>
      <Checkbox
        checked={row.isSelected}
        name={`${column?.id}row_checkbox_${row.index}`}
        id={`${column?.id}row_checkbox_${row.index}`}
        onChange={row.toggleRowSelected}
      />
    </label>
  );
};

CheckboxCell.propTypes = {
  value: PropTypes.string.isRequired,
  row: PropTypes.shape({
    isSelected: PropTypes.bool,
    index: PropTypes.number,
    toggleRowSelected: PropTypes.func,
  }).isRequired,
  column: PropTypes.shape({
    column: PropTypes.shape({}),
    onBookmark: PropTypes.func.isRequired,
  }).isRequired,
};

CheckboxCell.displayName = "CheckboxCell";

export default CheckboxCell;
