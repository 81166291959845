import { useNavigate } from "react-router-dom";

import { API_ENDPOINTS } from "../../api/constants";
import useFetch from "../../api/useFetch";
import { removeCompanyFromRecentlyVisitedCompaniesById } from "../../global-header/utils";
import { ROUTING_PATH } from "../../routes/constants";

/**
 * Simple hook that fetches a company by ID, and in the event of a 404 navigates the user back to
 * the company list page. The 404 also triggers a purge in the local storage "recent companies"
 * list.
 *
 * @param companyId
 * @param queryParams
 * @param config
 * @return {Array}
 */
const useCompanyFetch = (
  companyId,
  queryParams = {},
  config = { redirectOnFail: true }
) => {
  const navigate = useNavigate();

  return useFetch(API_ENDPOINTS.COMPANY(companyId), queryParams, {
    onError: (error) => {
      if (error.status === 404) {
        // Purge from local storage.
        removeCompanyFromRecentlyVisitedCompaniesById(companyId);
        // Navigate to company list.
        if (config.redirectOnFail) {
          navigate(ROUTING_PATH.COMPANIES());
        }
      }
    },
  });
};

export default useCompanyFetch;
