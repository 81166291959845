/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``code-snippet.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const CodeSnippetIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M10.6627 1.51787C11.067 1.60772 11.322 2.00836 11.2321 2.41271L8.23214 15.9127C8.14228 16.3171 7.74165 16.572 7.3373 16.4821C6.93295 16.3923 6.678 15.9917 6.76786 15.5873L9.76786 2.08731C9.85772 1.68296 10.2583 1.42801 10.6627 1.51787ZM5.78033 4.71968C6.07322 5.01257 6.07322 5.48744 5.78033 5.78034L2.56066 9.00001L5.78033 12.2197C6.07322 12.5126 6.07322 12.9874 5.78033 13.2803C5.48744 13.5732 5.01256 13.5732 4.71967 13.2803L0.96967 9.53034C0.676777 9.23745 0.676777 8.76257 0.96967 8.46968L4.71967 4.71968C5.01256 4.42678 5.48744 4.42678 5.78033 4.71968ZM12.2197 4.71968C12.5126 4.42678 12.9874 4.42678 13.2803 4.71968L17.0303 8.46968C17.3232 8.76257 17.3232 9.23745 17.0303 9.53034L13.2803 13.2803C12.9874 13.5732 12.5126 13.5732 12.2197 13.2803C11.9268 12.9874 11.9268 12.5126 12.2197 12.2197L15.4393 9.00001L12.2197 5.78034C11.9268 5.48744 11.9268 5.01257 12.2197 4.71968Z"
        transform=""
      />
    </g>
  </svg>
);

CodeSnippetIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

CodeSnippetIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

CodeSnippetIcon.displayName = "CodeSnippetIcon";

export default CodeSnippetIcon;
