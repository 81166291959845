/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``line-chart.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const LineChartIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M1.5 2.25C1.5 1.83579 1.83579 1.5 2.25 1.5C2.66421 1.5 3 1.83579 3 2.25V15H15.75C16.1642 15 16.5 15.3358 16.5 15.75C16.5 16.1642 16.1642 16.5 15.75 16.5H2.25C1.83579 16.5 1.5 16.1642 1.5 15.75V2.25ZM15.5473 6.51279C15.8305 6.21052 15.8151 5.7359 15.5128 5.45269C15.2105 5.16949 14.7359 5.18494 14.4527 5.48721L11.5913 8.54126L9.24911 7.32513L9.22113 7.3103C9.12082 7.25672 8.91249 7.14545 8.68033 7.12453C8.48549 7.10697 8.28944 7.14053 8.11154 7.22188C7.89955 7.31882 7.74008 7.49304 7.6633 7.57693L7.64185 7.60022L4.70269 10.7372C4.41949 11.0395 4.43494 11.5141 4.73721 11.7973C5.03948 12.0805 5.5141 12.0651 5.79731 11.7628L8.65875 8.70874L11.0009 9.92488L11.0289 9.9397C11.1292 9.99328 11.3375 10.1046 11.5697 10.1255C11.7645 10.143 11.9606 10.1095 12.1385 10.0281C12.3505 9.93119 12.5099 9.75696 12.5867 9.67307L12.6082 9.64979L15.5473 6.51279Z"
        transform=""
      />
    </g>
  </svg>
);

LineChartIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

LineChartIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

LineChartIcon.displayName = "LineChartIcon";

export default LineChartIcon;
