import PropTypes from "prop-types";
import { useFormState } from "react-final-form";
import { FinalFormField, Fonts } from "yuka";

import {
  debouncedModifyCustomComponentName,
  debouncedModifyCustomComponentValue,
} from "./debouncedMixpanelEvents";
import { useComponent } from "./hooks";
import { Styled16Gap, StyledDetailPanelFields } from "./StyledComponents";
import { currentValueFieldName, nameFieldName } from "./utils";

import useDebouncedState from "../utils/useDebouncedState";

const CustomComponentField = ({ companyName, editable, componentId }) => {
  const { values } = useFormState();
  // These will be used for mixpanel tracking.
  const { name, currentValue } = useComponent(values, componentId);
  const [previousName, setPreviousName] = useDebouncedState(name);
  const [previousValue, setPreviousValue] = useDebouncedState(currentValue);

  return (
    <StyledDetailPanelFields>
      <Fonts.Body1theme50>
        You can customize your own criterion to include in the valuation
        calculation.
      </Fonts.Body1theme50>
      <Styled16Gap>
        <FinalFormField
          disabled={!editable}
          name={nameFieldName(componentId)}
          label="Criterion name"
          placeholder="Untitled"
          onChange={(value) => {
            debouncedModifyCustomComponentName(
              companyName,
              previousName,
              value
            );
            setPreviousName(value);
          }}
        />
        <FinalFormField
          disabled={!editable}
          name={currentValueFieldName(componentId)}
          type="number"
          label="Value"
          placeholder="Enter a price"
          onChange={(value) => {
            debouncedModifyCustomComponentValue(
              companyName,
              name,
              previousValue,
              value
            );
            setPreviousValue(value);
          }}
        />
      </Styled16Gap>
    </StyledDetailPanelFields>
  );
};

CustomComponentField.propTypes = {
  companyName: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  componentId: PropTypes.number,
};

export default CustomComponentField;
