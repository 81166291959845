import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  StyledInput as BaseStyledInput,
  StyledInputWrapper,
  SIZE_SMALL,
  SIZE_REGULAR,
} from "../FormField/internal/StyledComponents";
import { ChevronDownIcon, ChevronUpIcon, XCircleIcon } from "../Icons";
import { PrimaryColorPalette, SelectAnchorTypes } from "../StylingConstants";
import { ActionChip, InputChip } from "../Button/Chip";
import { YukaThemeProvider } from "../ThemeContext";

import useSelectAsync from "./internal/useSelectAsync";
import useSelectValue from "./internal/useSelectValue";
import useMenuOptions from "./internal/useMenuOptions";
import useSelectValueDisplay from "./internal/useSelectValueDisplay";
import {
  DEFAULT_LABEL_KEY,
  DEFAULT_VALUE_KEY,
  DEFAULT_ICON_KEY,
  DEFAULT_THUMBNAIL_KEY,
} from "./internal/Constants";
import { OptionPropTypeArray } from "./propTypes/OptionPropType";
import ValuePropType from "./propTypes/ValuePropType";

import useAnchoredMenu from "../Layout/useAnchoredMenu";

const StyledInput = styled(BaseStyledInput)`
  width: 0;
  flex-grow: 1;
`;

const StyledMultiSelectInputWrapper = styled(StyledInputWrapper)`
  flex-wrap: wrap;
  height: fit-content;
  padding: 6px 11px;
  gap: 7px;
`;

const StyledClearButton = styled.button`
  border: none;
  background: transparent;
  outline: none;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0;
  margin-left: 12px;
  line-height: 1;
  svg {
    pointer-events: bounding-box;
  }
  path:hover {
    fill: ${PrimaryColorPalette.white80};
  }
`;

const ClearButton = ({ onClick }) => (
  <StyledClearButton onClick={onClick}>
    <XCircleIcon size={16} color={PrimaryColorPalette.white50} />
  </StyledClearButton>
);

ClearButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

/**
 * A standard select component to get desired behavior on ZX.
 *
 * Common use case should leverage:
 * - options or loadOptions
 * - name
 * - placeholder
 * - value
 * - (within modals) popup
 * - some combination of create, disabled, multiple
 *
 * Other options are generally for advanced use cases. Use `Autocomplete` or `MultiAutocomplete`
 * when you want a text search of the dropdown options instead of `Select`
 *
 * When customizing this, try doing it in the following ways
 * 1. Use the boolean props available
 * 2. Use the component props available (for example, `inputComponent`)
 * 3. Add a new prop as necessary if no existing configuration works
 *   - Ensure all new props are well thought out, named generically and necessary.
 * 4. Create a thin wrapper around this that uses a ref and handlers to grab any stateful
 * information necessary.
 *   - Good option if you need different display logic that doesn't fit the existing structure
 *   - An example of this is `ChipSelect`
 * 5. If all else fails, build a new component/rework this one to be more extensible as needed
 *   - This is the nuclear option. Any such approach should minimize duplication and push for single
 *     source of truth as much as possible
 *
 * The first 2 methods should cover the vast majority of use cases
 */
const Select = React.forwardRef((props, ref) => {
  const internalRef = React.useRef(null);
  const inputRef = ref || internalRef;

  const [fetchedOptions, handleFetch, loadOptionFromValue] = useSelectAsync({
    initialOptions: props.options,
    loadOptions: props.loadOptions,
    loadOptionFromValue: props.loadOptionFromValue,
    uniqBy: props.optionValueKey,
  });

  const [value, setValue] = useSelectValue(
    props.value,
    props.onChange,
    props.multiple,
    loadOptionFromValue
  );

  const optionsAsMenuItems = useMenuOptions({
    options: props.loadOptions ? fetchedOptions : props.options,
    sortAlphabetically: !props.noSort,
    labelKey: props.optionLabelKey,
    valueKey: props.optionValueKey,
    iconKey: props.optionIconKey,
    thumbnailKey: props.optionThumbnailKey,
  });

  const [valueDisplay, valueIcon] = useSelectValueDisplay(
    optionsAsMenuItems,
    value,
    props.multiple,
    loadOptionFromValue
  );

  const [dropdownContent, containerRef, toggleIsOpen] = useAnchoredMenu({
    searchable: props.searchable,
    menuItems: optionsAsMenuItems,
    role: "listbox",
    defaultAbove: false,
    onItemClick: item => setValue(item.value),
    closeOnSelect: !props.multiple,
    onSearch: handleFetch,
    keyHandlerRef: inputRef,
  });

  const size = props.small ? SIZE_SMALL : SIZE_REGULAR;
  const iconSize = props.small ? 16 : 18;
  const leadingIconColor = props.disabled
    ? PrimaryColorPalette.white15
    : PrimaryColorPalette.white30;
  const defaultChevronColor = dropdownContent
    ? PrimaryColorPalette.white80
    : PrimaryColorPalette.white50;

  let TrailingIcon = dropdownContent ? ChevronUpIcon : ChevronDownIcon;
  // No clear button on multi selects
  if (value !== null && !props.disabled && !props.multiple) {
    const onClear = e => {
      setValue(null);
      e.preventDefault();
      e.stopPropagation();
    };
    TrailingIcon = () => <ClearButton onClick={onClear} />;
  }
  const trailingIconColor = props.disabled ? PrimaryColorPalette.white15 : defaultChevronColor;

  const focusAndToggle = () => {
    inputRef.current?.focus();
    toggleIsOpen();
  };

  const LeadingIcon = props.leadingIcon || valueIcon;
  const Wrapper = props.multiple ? StyledMultiSelectInputWrapper : StyledInputWrapper;
  // Determine what to render based on the anchor type the user chose.
  let renderedComponent = (
    <Wrapper
      $disabled={props.disabled}
      $error={props.showError}
      $size={size}
      className={props.className}
      ref={containerRef}
      onClick={props.disabled ? null : focusAndToggle}
    >
      {LeadingIcon && <LeadingIcon size={iconSize} color={leadingIconColor} />}
      {props.multiple ? (
        <>
          {valueDisplay.map(option => (
            <div key={option.value}>
              <InputChip
                leadingIcon={option.icon}
                avatar={option.thumbnail}
                onClose={() => setValue(option.value)}
                text={option.text}
              />
            </div>
          ))}
          <StyledInput
            ref={inputRef}
            placeholder={value && value.length ? "" : props.placeholder}
            name={props.name}
            disabled={props.disabled}
            role="combobox"
            readOnly
          />
        </>
      ) : (
        <StyledInput
          ref={inputRef}
          placeholder={props.placeholder}
          name={props.name}
          disabled={props.disabled}
          value={valueDisplay}
          readOnly
          role="combobox"
        />
      )}
      <TrailingIcon size={iconSize} color={trailingIconColor} />
    </Wrapper>
  );
  // Explicitly do not support chip-style selects + multi-selection. We may add this down the line
  // but so far have not received any designs implementing this functionality. For multi-selects
  // of this type, we'd typically use a `CheckboxDropdown`.
  if (!props.multiple && props.anchorType === SelectAnchorTypes.CHIP) {
    // ActionChip will discard the `onClose` prop so we can just pass it into either.
    const ChipComponent = _.isNull(value) ? ActionChip : InputChip;
    renderedComponent = (
      <ChipComponent
        ref={containerRef}
        onClick={props.disabled ? null : focusAndToggle}
        onClose={() => setValue(null)}
        text={valueDisplay ? valueDisplay : props.placeholder}
        role="listbox"
      />
    );
  }

  return (
    <YukaThemeProvider theme={{ componentVersion: 2 }}>
      {dropdownContent}
      {renderedComponent}
    </YukaThemeProvider>
  );
});

Select.propTypes = {
  /** Does the select allow multiple options to be selected? */
  multiple: PropTypes.bool,
  /** Should the user be allowed to search through options? */
  searchable: PropTypes.bool,
  /** What type of anchor should this select be rendered as? */
  anchorType: PropTypes.oneOf(Object.values(SelectAnchorTypes)),

  // Common input props
  /** A class applied to the wrapper around the 'input' component of the select */
  className: PropTypes.string,
  /** Whether the select is disabled or not */
  disabled: PropTypes.bool,
  /** field name for this select */
  name: PropTypes.string.isRequired,
  /** Event handler to be called whenever the value of the select changes */
  onChange: PropTypes.func,
  /** What to display if no option is selected for the input */
  placeholder: PropTypes.string,
  small: PropTypes.bool,
  leadingIcon: PropTypes.func,

  // Value Props
  /** Function that returns a promise that evaluates to an array of options */
  loadOptions: PropTypes.func,
  /** Function that accepts a value and returns a promise that evaluates to an option instance */
  loadOptionFromValue: PropTypes.func,
  /** The list of options that can be selected from the input. Ungrouped */
  options: OptionPropTypeArray,
  /**
   * The key in options provided that corresponds to what value should appear in the option display
   */
  optionLabelKey: PropTypes.string,
  /**
   * The key in options provided that corresponds to what icon should appear in the option display
   */
  optionIconKey: PropTypes.string,
  /**
   * The key in options provided that corresponds to what thumbnail should appear in the option display
   */
  optionThumbnailKey: PropTypes.string,
  /**
   * The key in options provided that corresponds to the internal value stored from selecting an
   * option
   */
  optionValueKey: PropTypes.string,
  /** Allows parent component to control the value stored on the select externally */
  value: ValuePropType,

  /** If set, leaves options in the order provided. Otherwise sorts alphabetically */
  noSort: PropTypes.bool,
  showError: PropTypes.bool,
};

Select.defaultProps = {
  multiple: false,
  searchable: false,
  anchorType: SelectAnchorTypes.TEXT_BOX,

  className: "",
  disabled: false,
  placeholder: "",
  defaultOption: "",

  options: [],
  optionThumbnailKey: DEFAULT_THUMBNAIL_KEY,
  optionValueKey: DEFAULT_VALUE_KEY,
  optionLabelKey: DEFAULT_LABEL_KEY,
  optionIconKey: DEFAULT_ICON_KEY,

  noSort: false,
  showError: false,
};

Select.displayName = "Select";

export default Select;
