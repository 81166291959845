import {
  VALUATION_COMPONENT_FUNDING_ROUND,
  VALUATION_COMPONENT_OTHER,
  VALUATION_COMPONENT_PRIVATE_MARKET_INDEX_COMPARISON,
  VALUATION_COMPONENT_REPORTED_MARKS,
  VALUATION_COMPONENT_ZX_INDEX_VALUE,
  VALUATION_COMPONENT_ZX_INDEX_VALUE_TRAILING,
} from "../constants";

/**
 * Returns the weighted value for a given component type.
 * Most component types are simple multiplication by the weight; however, some require additional
 * calculations.
 *
 * @param {number|string} currentValue - The current value of the component
 * @param {number} weight - The weight of the component
 * @param {number} componentType - The type of the component
 * @param formValues - Supplementary form values required in some calculations.
 * @return {number|null}
 */
const getWeightedValueForComponent = (
  currentValue,
  weight,
  componentType,
  formValues
) => {
  if (weight === 0) {
    // Short circuit so we don't see null values for 0-weighted components, since we don't care
    // about those in the final sum/validity of the form.
    return 0;
  }
  switch (componentType) {
    case VALUATION_COMPONENT_ZX_INDEX_VALUE:
    case VALUATION_COMPONENT_ZX_INDEX_VALUE_TRAILING:
    case VALUATION_COMPONENT_REPORTED_MARKS:
    case VALUATION_COMPONENT_FUNDING_ROUND:
    case VALUATION_COMPONENT_OTHER:
      return ((Number(currentValue) || 0) * weight) / 100;
    case VALUATION_COMPONENT_PRIVATE_MARKET_INDEX_COMPARISON:
      // This type involves the previous valuation multiplied by a percentage change.
      if (
        !formValues ||
        (!formValues.previous_pps && formValues.previous_pps !== 0)
      ) {
        return null;
      }
      return (
        ((1 + Number(currentValue) || 0) *
          (formValues.previous_pps || 0) *
          weight) /
        100
      );
    default:
      return null;
  }
};

export default getWeightedValueForComponent;
