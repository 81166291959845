import _ from "lodash";
import { useCallback } from "react";

const getWithExpiry = (key) => {
  const itemStr = window.localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }

  // Delete if expired
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (item.expiry && now.getTime() > item.expiry) {
    window.localStorage.removeItem(key);
    return null;
  }

  return item.value;
};

const setWithExpiry = (key, value, ttl = null) => {
  const now = new Date();
  const item = {
    value,
    expiry: !_.isNull(ttl) ? now.getTime() + ttl : null,
  };
  window.localStorage.setItem(key, JSON.stringify(item));
};

const removeItem = (key) => {
  window.localStorage.removeItem(key);
};

const useLocalStorage = () => {
  const getItem = useCallback((key) => getWithExpiry(key), []);
  const setItem = useCallback(
    (key, value, ttl) => setWithExpiry(key, value, ttl),
    []
  );
  const deleteItem = useCallback((key) => removeItem(key), []);

  return { getItem, setItem, deleteItem };
};

export default useLocalStorage;
