import { useContext, useEffect, useState } from "react";
import { ACTIONS, GlobalContext } from "../../routes/StateProvider";

/**
 * Hook helper to implement logic shared between useAnchoredMenu and useDropdown
 */
const useDropdownContext = (element, ref, toggle, config) => {
  const [id] = useState(config.id || String(Math.random()));
  const [previousElement, setPreviousElement] = useState(element);
  const {
    state: { registeredDropdowns },
    dispatch,
  } = useContext(GlobalContext);

  useEffect(() => {
    if (element && !previousElement) {
      setPreviousElement(() => element);
      dispatch({ type: ACTIONS.toggleDropdown, id });
      Object.keys(registeredDropdowns).forEach((dropdown) => {
        // Toggles off the other registered dropdowns.
        if (dropdown !== id && registeredDropdowns[dropdown][1]) {
          registeredDropdowns[dropdown][0]();
        }
      });
    } else if (!element && previousElement) {
      setPreviousElement(() => element);
      dispatch({ type: ACTIONS.toggleDropdown, id });
    }
  }, [dispatch, element, previousElement, id, registeredDropdowns]);

  useEffect(() => {
    // Register the anchoredMenu on mount. Note that these are included in the same global
    // context as dropdowns.
    dispatch({
      type: ACTIONS.registerDropdown,
      id,
      toggleDropdown: toggle,
    });
    return () => {
      dispatch({ type: ACTIONS.unregisterDropdown, id });
    };
  }, [dispatch, toggle, id]);

  return [element, ref, toggle];
};

export default useDropdownContext;
