import PropTypes from "prop-types";
import styled from "styled-components";

import { overline } from "../Typography";

import BadgeStyles from "./BadgeStyles";

const Badge = styled.div.attrs({ className: "badge" })`
  ${overline};
  line-height: 12px;
  letter-spacing: 0.05em;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-flex;
  height: 16px;
  padding: 2px 4px;
  background-color: ${props => props.badgeStyle.backgroundColor};
  color: ${props => props.badgeStyle.color};
  text-wrap: nowrap;
`;

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  badgeStyle: PropTypes.oneOf(Object.values(BadgeStyles)),
};

Badge.defaultProps = {
  className: "",
  badgeStyle: BadgeStyles.DARK_GRAY,
};

Badge.displayName = "Badge";

/* @component */
export default Badge;
