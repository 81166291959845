import styled, { css } from "styled-components";
import { ColorPalette, YukaColorPalette } from "yuka";

import { PAGE_WIDTH } from "../constants";

const StyledPortfolioNameHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  > :first-child {
    gap: 16px;
  }

  > * {
    display: flex;
    align-items: center;
  }
`;

const PortfolioHeaderStats = styled.div`
  display: flex;
  gap: 64px;
  align-items: flex-end;
`;

const StyledEmptyStateContainer = styled.div`
  width: ${PAGE_WIDTH};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  text-align: center;
  align-self: center;
`;

const PortfolioTableRowGridLayout = styled.div`
  flex-grow: 1;
  display: grid;
  align-items: center;
  ${(props) =>
    props.$showData
      ? css`
          grid-template-columns: 5fr 4fr 2fr 3fr 5fr 4fr 32px;
        `
      : css`
          grid-template-columns: 5fr 18fr 32px;
        `}}
`;

const PortfolioTableHeader = styled(PortfolioTableRowGridLayout)`
  height: 42px;
  padding: 0 16px;
  box-sizing: border-box;
  grid-template-columns: 5fr 4fr 2fr 3fr 5fr 4fr 64px;
  border-top: 1px dashed ${ColorPalette.white15};
  border-bottom: 1px dashed ${ColorPalette.white15};
`;

const StyledModalContent = styled.div`
  background: ${YukaColorPalette.surface1};
  display: flex;

  flex-direction: column;
  gap: 24px;
  padding: 24px 24px 0 24px;
  overflow-y: auto;
  box-sizing: border-box;
  ${(props) => (props.$maxHeight ? `max-height: ${props.$maxHeight}px;` : "")}
  ${(props) => (props.$minHeight ? `min-height: ${props.$minHeight}px;` : "")}
  ${(props) => (props.$width ? `width: ${props.$width}px;` : "")}
`;

const StyledModalButtonRow = styled.div`
  background: ${YukaColorPalette.surface1};
  box-sizing: border-box;
  width: 100%;
  padding: 24px;
  display: flex;
  justify-content: flex-end;
`;

const StyledFlexGrow = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
`;

export {
  StyledFlexGrow,
  PortfolioTableHeader,
  PortfolioTableRowGridLayout,
  StyledPortfolioNameHeader,
  PortfolioHeaderStats,
  StyledEmptyStateContainer,
  StyledModalContent,
  StyledModalButtonRow,
};
