import { DateTime } from "luxon";
import { useMemo } from "react";

import { API_ENDPOINTS } from "../../api/constants";
import useFetch from "../../api/useFetch";

const useLatestReportedMarks = (companyId, valuationDate, queryEnabled) => {
  // The reported marks endpoint deals in "months ago" as its date range querying format, so we'll
  // determine the values here, based on valuationDate.
  const valuationDateObj = DateTime.fromISO(valuationDate);
  let monthsAgo = Math.ceil(
    Math.max(
      DateTime.fromObject({
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1, // months are 1-based in Luxon
        day: 1,
      }).diff(valuationDateObj, "months").months,
      0
    )
  );
  const companyReportedMarksValuationQuery = useFetch(
    API_ENDPOINTS.COMPANY_REPORTED_MARKS_VALUATION(companyId),
    {
      months_ago_start: monthsAgo + 12,
      months_ago_end: monthsAgo - 3,
    },
    {
      enabled: queryEnabled,
    }
  );

  return useMemo(() => {
    if (!companyReportedMarksValuationQuery.isSuccess) {
      return [null, null, companyReportedMarksValuationQuery.isLoading];
    }
    // Find the most recent date with data for reported marks.
    const mostRecentDateWithData = Object.keys(
      companyReportedMarksValuationQuery.data.data
    )
      .sort((a, b) => new Date(b) - new Date(a))
      .filter((dateKey) =>
        Boolean(
          companyReportedMarksValuationQuery.data.data[dateKey].weighted_avg
        )
      )?.[0];

    return [
      companyReportedMarksValuationQuery.data?.data?.[mostRecentDateWithData],
      mostRecentDateWithData,
      companyReportedMarksValuationQuery.isLoading,
    ];
  }, [
    companyReportedMarksValuationQuery.isSuccess,
    companyReportedMarksValuationQuery.isLoading,
    companyReportedMarksValuationQuery.data?.data,
  ]);
};

export default useLatestReportedMarks;
