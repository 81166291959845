/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``arrow-circle-broken-down-left.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ArrowCircleBrokenDownLeftIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M10.7486 2.47918C8.49453 1.87722 5.993 2.46106 4.227 4.22707C1.59096 6.86311 1.59096 11.137 4.227 13.773C6.86304 16.409 11.1369 16.409 13.7729 13.773C15.5389 12.007 16.1228 9.50548 15.5208 7.25138C15.414 6.85119 15.6517 6.44014 16.0519 6.33327C16.4521 6.2264 16.8632 6.46418 16.97 6.86437C17.7045 9.61452 16.9935 12.6737 14.8336 14.8337C11.6118 18.0555 6.38816 18.0555 3.16634 14.8337C-0.0554865 11.6118 -0.055487 6.38823 3.16634 3.16641C5.32627 1.00647 8.38549 0.295535 11.1356 1.02997C11.5358 1.13684 11.7736 1.54789 11.6667 1.94808C11.5599 2.34827 11.1488 2.58605 10.7486 2.47918ZM14.7803 3.21966C15.0732 3.51255 15.0732 3.98743 14.7803 4.28032L8.56078 10.5H11.2501C11.6644 10.5 12.0001 10.8358 12.0001 11.25C12.0001 11.6643 11.6644 12 11.2501 12H6.75015C6.33593 12 6.00015 11.6643 6.00015 11.25V6.75004C6.00015 6.33583 6.33593 6.00004 6.75015 6.00004C7.16436 6.00004 7.50015 6.33583 7.50015 6.75004V9.43935L13.7197 3.21968C14.0126 2.92678 14.4874 2.92677 14.7803 3.21966Z"
        transform=""
      />
    </g>
  </svg>
);

ArrowCircleBrokenDownLeftIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ArrowCircleBrokenDownLeftIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

ArrowCircleBrokenDownLeftIcon.displayName = "ArrowCircleBrokenDownLeftIcon";

export default ArrowCircleBrokenDownLeftIcon;
