import PropTypes from "prop-types";
import styled from "styled-components";

import { overline, FontColors } from "../Typography";

const ListHeader = styled.div`
  ${overline}
  ${FontColors.theme30}
  margin: 0 12px 12px;
`;

ListHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

ListHeader.displayName = "ListHeader";

/* @component */
export default ListHeader;
