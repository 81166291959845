/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``alert-triangle.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const AlertTriangleIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule=""
        d="M7.515 2.9175C7.92 2.2125 8.145 1.8225 8.595 1.6275C8.985 1.455 9.4275 1.455 9.81 1.6275C10.2525 1.8225 10.4775 2.2125 10.89 2.9175L17.055 13.575C17.4675 14.28 17.6925 14.67 17.64 15.1575C17.595 15.5775 17.37 15.9675 17.0325 16.215C16.635 16.5 16.185 16.5 15.3675 16.5H3.03C2.2125 16.5 1.7625 16.5 1.365 16.215C1.02 15.9675 0.802497 15.5775 0.757497 15.1575C0.704997 14.67 0.929997 14.28 1.3425 13.575L7.515 2.9175ZM8.805 3.675L2.64 14.325C2.49 14.58 2.34 14.835 2.28 14.985C2.4375 15 2.7375 15 3.03 15H15.3675C15.66 15 15.96 15 16.1175 14.985C16.05 14.8425 15.9 14.58 15.7575 14.325L9.585 3.675C9.4425 3.42 9.285 3.165 9.195 3.0375C9.105 3.1575 8.955 3.42 8.805 3.675ZM9.2025 13.875C8.79 13.875 8.4525 13.5375 8.4525 13.125C8.4525 12.7125 8.7825 12.375 9.2025 12.375H9.21C9.6225 12.375 9.96 12.7125 9.96 13.125C9.96 13.5375 9.6225 13.875 9.2025 13.875ZM9.195 10.875C8.7825 10.875 8.445 10.5375 8.445 10.125V7.125C8.445 6.7125 8.7825 6.375 9.195 6.375C9.6075 6.375 9.945 6.7125 9.945 7.125V10.125C9.945 10.545 9.615 10.875 9.195 10.875Z"
        transform=""
      />
    </g>
  </svg>
);

AlertTriangleIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

AlertTriangleIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

AlertTriangleIcon.displayName = "AlertTriangleIcon";

export default AlertTriangleIcon;
