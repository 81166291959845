import _ from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useFormState } from "react-final-form";
import { ActionChip, FinalFormField, Fonts } from "yuka";

import {
  COMPONENT_TYPE_MAP,
  VALUATION_COMPONENT_FUNDING_ROUND,
} from "./constants";
import { debouncedModifyDefaultComponentValue } from "./debouncedMixpanelEvents";
import { useComponent, useLatestHDFundingRound } from "./hooks";
import { Styled16Gap, StyledDetailPanelFields } from "./StyledComponents";
import { currentValueFieldName, initialValueFieldName } from "./utils";

const LastFundingRoundField = ({
  companyName,
  companyId,
  editable,
  resetValue,
  componentId,
}) => {
  const { values } = useFormState();

  const { name, initialValue } = useComponent(values, componentId);

  const [hdFundingRound] = useLatestHDFundingRound(
    companyId,
    values.valuation_date,
    Boolean(companyId)
  );

  return (
    <StyledDetailPanelFields>
      <Fonts.Body1theme50>
        If available, we use the cost of a single share during the most recent
        investment round as our default value.
        {hdFundingRound && hdFundingRound.price_per_share && (
          <Fonts.Body1theme50>
            Our sources indicate that the most recent primary financing round
            was
            <Fonts.Body1theme80> {hdFundingRound.event} </Fonts.Body1theme80>
            in
            <Fonts.Body1theme80>
              {" "}
              {DateTime.fromISO(hdFundingRound.date).toFormat("LLLL, yyyy")}
            </Fonts.Body1theme80>
          </Fonts.Body1theme50>
        )}
      </Fonts.Body1theme50>
      {editable && (
        <Fonts.Body1theme50>
          You can override the default value by entering your own number below:
        </Fonts.Body1theme50>
      )}
      <Styled16Gap>
        <FinalFormField
          disabled={!editable}
          initialValue={values[initialValueFieldName(componentId)]}
          name={currentValueFieldName(componentId)}
          type="number"
          label="Financing round"
          placeholder="Enter a price"
          onChange={(value) => {
            if (value !== initialValue && (value || initialValue)) {
              debouncedModifyDefaultComponentValue(
                companyName,
                name,
                COMPONENT_TYPE_MAP[VALUATION_COMPONENT_FUNDING_ROUND],
                initialValue,
                value
              );
            }
          }}
        />
        {editable && <ActionChip text="Restore default" onClick={resetValue} />}
      </Styled16Gap>
    </StyledDetailPanelFields>
  );
};

LastFundingRoundField.propTypes = {
  companyName: PropTypes.string,
  companyId: PropTypes.string,
  editable: PropTypes.bool,
  resetValue: PropTypes.func,
  componentId: PropTypes.number,
};

LastFundingRoundField.defaultProps = {
  resetValue: _.noop,
};

export default LastFundingRoundField;
