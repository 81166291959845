/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``reverse-right.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ReverseRightIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M11.4697 1.71967C11.7626 1.42678 12.2374 1.42678 12.5303 1.71967L15.5303 4.71967C15.8232 5.01256 15.8232 5.48744 15.5303 5.78033L12.5303 8.78033C12.2374 9.07322 11.7626 9.07322 11.4697 8.78033C11.1768 8.48744 11.1768 8.01256 11.4697 7.71967L13.1893 6H7.5C5.42893 6 3.75 7.67893 3.75 9.75C3.75 11.8211 5.42893 13.5 7.5 13.5H15C15.4142 13.5 15.75 13.8358 15.75 14.25C15.75 14.6642 15.4142 15 15 15H7.5C4.60051 15 2.25 12.6495 2.25 9.75C2.25 6.85051 4.60051 4.5 7.5 4.5H13.1893L11.4697 2.78033C11.1768 2.48744 11.1768 2.01256 11.4697 1.71967Z"
        transform=""
      />
    </g>
  </svg>
);

ReverseRightIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ReverseRightIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

ReverseRightIcon.displayName = "ReverseRightIcon";

export default ReverseRightIcon;
