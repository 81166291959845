import _ from "lodash";

import getDateDividedData from "./getDateDividedData";
import makePriceDataTimeSeries from "./makePriceDataTimeSeries";

import { COMPANY_PROFILE_LINE_CHART_API_DATA_FIELDS } from "../constants";

const makeSuperchartData = (rawPriceData, seriesType) => {
  const { TIME_FIELD, PRICE_DATA_FIELD, ADDITIONAL_DATA_FIELDS, config } =
    COMPANY_PROFILE_LINE_CHART_API_DATA_FIELDS[seriesType];
  const dateDividedData = getDateDividedData(rawPriceData, TIME_FIELD);

  return _.mapValues(dateDividedData, (dataSeries) =>
    makePriceDataTimeSeries(
      dataSeries,
      TIME_FIELD,
      PRICE_DATA_FIELD,
      ADDITIONAL_DATA_FIELDS,
      config
    )
  );
};

export default makeSuperchartData;
