import { DateTime } from "luxon";

import isRemovedPortfolioCompany from "./isRemovedPortfolioCompany";

import {
  MODE_ZX_INDEX_VALUE,
  SORT_CRITERIA_ADDED_DATE,
  SORT_CRITERIA_COMPANY,
  SORT_CRITERIA_CUSTOM_VALUATION,
  SORT_CRITERIA_QUANTITY,
  SORT_CRITERIA_UNREALIZED_GAIN,
  SORT_CRITERIA_VALUE,
  SORT_CRITERIA_ZX_INDEX_VALUE,
  SORT_DIRECTION_ASC,
} from "../constants";

import { isRequestedPortfolioCompany } from "./index";

/*
 * All the below sort functions should only be used on objects that come out of the
 * usePortfolioCompanyAggregate hook. This is because the functions rely on the data being
 * in a specific format.
 *
 * Each sort function will be called with a `direction` and a `mode` to construct the sorting
 * comparator function. The direction is either SORT_DIRECTION_ASC or SORT_DIRECTION_DESC, and the
 * mode is either MODE_ZX_INDEX_VALUE or MODE_CUSTOM_VALUATION (used to determine which fields to
 * use when determining "quantity" or "total value").
 *
 * Note that not all the sort functions actually make use of the `mode` parameter, when mode is
 * irrelevant to determining sort.
 */

const sortByAddedDate = (direction) => (a, b) => {
  if (direction === SORT_DIRECTION_ASC) {
    return DateTime.fromISO(a.createdOn) - DateTime.fromISO(b.createdOn);
  }
  return DateTime.fromISO(b.createdOn) - DateTime.fromISO(a.createdOn);
};

const sortByCompany = (direction) => (a, b) => {
  if (direction === SORT_DIRECTION_ASC) {
    return `${a.name}`.localeCompare(b.name);
  }
  return `${b.name}`.localeCompare(a.name);
};

const sortByZXIndexValue = (direction) => (a, b) => {
  // For requested/removed companies we force them to the bottom of sorted lists, except
  // by company name and date added.
  if (
    isRequestedPortfolioCompany(a.company) ||
    isRemovedPortfolioCompany(a.company)
  )
    return 1;
  if (
    isRequestedPortfolioCompany(b.company) ||
    isRemovedPortfolioCompany(b.company)
  )
    return -1;

  if (direction === SORT_DIRECTION_ASC) {
    return a.currentZXIndexValue - b.currentZXIndexValue;
  }
  return b.currentZXIndexValue - a.currentZXIndexValue;
};

const sortByCustomValuation = (direction, mode) => (a, b) => {
  // For requested/removed companies we force them to the bottom of sorted lists, except
  // by company name and date added.
  if (
    (isRequestedPortfolioCompany(a.company) && mode === MODE_ZX_INDEX_VALUE) ||
    isRemovedPortfolioCompany(a.company)
  )
    return 1;
  if (
    (isRequestedPortfolioCompany(b.company) && mode === MODE_ZX_INDEX_VALUE) ||
    isRemovedPortfolioCompany(b.company)
  )
    return -1;

  // Special || usage here to avoid NaN's from using `undefined`.
  const aValuation = a.customValuation?.computed_value || 0;
  const bValuation = b.customValuation?.computed_value || 0;
  if (direction === SORT_DIRECTION_ASC) {
    return aValuation - bValuation;
  }
  return bValuation - aValuation;
};

const sortByQuantity = (direction, mode) => (a, b) => {
  // For requested/removed companies we force them to the bottom of sorted lists, except
  // by company name and date added.
  if (
    (isRequestedPortfolioCompany(a.company) && mode === MODE_ZX_INDEX_VALUE) ||
    isRemovedPortfolioCompany(a.company)
  )
    return 1;
  if (
    (isRequestedPortfolioCompany(b.company) && mode === MODE_ZX_INDEX_VALUE) ||
    isRemovedPortfolioCompany(b.company)
  )
    return -1;

  if (direction === SORT_DIRECTION_ASC) {
    return a.shareQuantity - b.shareQuantity;
  }
  return b.shareQuantity - a.shareQuantity;
};

const sortByValue = (direction, mode) => (a, b) => {
  // For requested/removed companies we force them to the bottom of sorted lists, except
  // by company name and date added.
  if (
    (isRequestedPortfolioCompany(a.company) && mode === MODE_ZX_INDEX_VALUE) ||
    isRemovedPortfolioCompany(a.company)
  )
    return 1;
  if (
    (isRequestedPortfolioCompany(b.company) && mode === MODE_ZX_INDEX_VALUE) ||
    isRemovedPortfolioCompany(b.company)
  )
    return -1;

  if (direction === SORT_DIRECTION_ASC) {
    return mode === MODE_ZX_INDEX_VALUE
      ? a.totalValueFromZXIndexValue - b.totalValueFromZXIndexValue
      : a.totalValueFromCustomValuation - b.totalValueFromCustomValuation;
  }
  return mode === MODE_ZX_INDEX_VALUE
    ? b.totalValueFromZXIndexValue - a.totalValueFromZXIndexValue
    : b.totalValueFromCustomValuation - a.totalValueFromCustomValuation;
};

const sortByUnrealizedGain = (direction, mode) => (a, b) => {
  // For requested/removed companies we force them to the bottom of sorted lists, except
  // by company name and date added.
  if (
    (isRequestedPortfolioCompany(a.company) && mode === MODE_ZX_INDEX_VALUE) ||
    isRemovedPortfolioCompany(a.company)
  )
    return 1;
  if (
    (isRequestedPortfolioCompany(b.company) && mode === MODE_ZX_INDEX_VALUE) ||
    isRemovedPortfolioCompany(b.company)
  )
    return -1;

  if (direction === SORT_DIRECTION_ASC) {
    return mode === MODE_ZX_INDEX_VALUE
      ? a.unrealizedGainFromZXIndexValue - b.unrealizedGainFromZXIndexValue
      : a.unrealizedGainFromCustomValuation -
          b.unrealizedGainFromCustomValuation;
  }
  return mode === MODE_ZX_INDEX_VALUE
    ? b.unrealizedGainFromZXIndexValue - a.unrealizedGainFromZXIndexValue
    : b.unrealizedGainFromCustomValuation - a.unrealizedGainFromCustomValuation;
};

const SORT_FUNCTION_MAP = {
  [SORT_CRITERIA_ADDED_DATE]: sortByAddedDate,
  [SORT_CRITERIA_COMPANY]: sortByCompany,
  [SORT_CRITERIA_ZX_INDEX_VALUE]: sortByZXIndexValue,
  [SORT_CRITERIA_CUSTOM_VALUATION]: sortByCustomValuation,
  [SORT_CRITERIA_QUANTITY]: sortByQuantity,
  [SORT_CRITERIA_VALUE]: sortByValue,
  [SORT_CRITERIA_UNREALIZED_GAIN]: sortByUnrealizedGain,
};

export { SORT_FUNCTION_MAP };
