import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { Button, Fonts } from "yuka";

import { FilterButtonChildrenContainer } from "./StyledComponents";

const HighlightedValue = styled(Fonts.Body1theme80)`
  color: ${(props) => props.theme.colors.branding400};
`;

/**
 * Button presentational component to render the normal button children, with the addition of a
 * highlighted value. All values are contained in a flexbox with the children on the left
 * and the highlighted value on the right (still to the left of the trailing icon).
 */
const HighlightedValueButton = React.forwardRef(
  ({ children, highlightedValue, ...props }, ref) => (
    <Button ref={ref} {...props}>
      <FilterButtonChildrenContainer>
        {children}
        <HighlightedValue>{highlightedValue}</HighlightedValue>
      </FilterButtonChildrenContainer>
    </Button>
  )
);

HighlightedValueButton.propTypes = {
  children: PropTypes.node.isRequired,
  highlightedValue: PropTypes.node,
};

export default HighlightedValueButton;
