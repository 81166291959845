import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";

import { FontColors, headline2, body1, caption2 } from "../Typography";
import HyperLink from "../Button/HyperLink";

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: relative;
  ${props => (props.$padded ? "margin: 0 16px;" : "")}
`;

const Headline = styled.span`
  ${headline2}
`;

const SupportingText = styled.span`
  ${body1}
  ${FontColors.theme50}
`;

const StyledLink = styled(HyperLink)`
  ${props => (props.as ? "" : caption2)}
  position: absolute;
  top: 0;
  right: 0;
`;

const StyledTrailingContent = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
`;

const CardHeader = props => (
  <StyledHeader $padded={props.padded}>
    {props.premiumNode}
    {props.headline && <Headline id={props.id}>{props.headline}</Headline>}
    {props.supportingText && <SupportingText>{props.supportingText}</SupportingText>}
    {props.trailingContent && (
      <StyledTrailingContent>{props.trailingContent}</StyledTrailingContent>
    )}
    {props.linkText && <StyledLink {...props.linkProps}>{props.linkText}</StyledLink>}
  </StyledHeader>
);

CardHeader.propTypes = {
  id: PropTypes.string,
  padded: PropTypes.bool,
  premiumNode: PropTypes.node,
  headline: PropTypes.node,
  supportingText: PropTypes.node,
  linkText: PropTypes.string,
  linkProps: PropTypes.object,
  trailingContent: PropTypes.node,
};

export default CardHeader;
