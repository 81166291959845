import styled from "styled-components";

import AgreementsSideNav from "./AgreementsSideNav";
import AgreementContentView from "./AgreementsContentView";

const StyledDocView = styled.div`
  display: flex;
`;

const DocView = () => (
  <StyledDocView>
    <AgreementsSideNav />
    <AgreementContentView />
  </StyledDocView>
);

export default DocView;
