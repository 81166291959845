import PropTypes from "prop-types";
import React from "react";
import { Button, ButtonStyles, Modal, ModalStyles, Fonts } from "yuka";

import {
  StyledModalButtonRow,
  StyledModalContent,
} from "../../Portfolios/StyledComponents";

/**
 * Disclaimer for where we source our articles.
 * @param onClose
 * @return {JSX.Element}
 */
const ReportsAndInterviewsDisclaimerModal = ({ onClose }) => (
  <Modal modalStyle={ModalStyles.MINIMAL} onClose={onClose}>
    <StyledModalContent $width={624}>
      <Fonts.Headline1theme80>
        Disclaimer for post-money valuation
      </Fonts.Headline1theme80>
      <Fonts.Body1theme50>
        Share count as stated or implied in Company press release or news
        article. Price per share as per company filings. Although care has been
        taken to ensure the accuracy, completeness, and reliability of the
        information, Zanbato makes no warranty, express or implied, or assumes
        any legal liability or responsibility for the accuracy, completeness,
        reliability or usefulness of any information.
      </Fonts.Body1theme50>
    </StyledModalContent>
    <StyledModalButtonRow>
      <Button onClick={onClose} buttonStyle={ButtonStyles.RAISED}>
        Close
      </Button>
    </StyledModalButtonRow>
  </Modal>
);

ReportsAndInterviewsDisclaimerModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ReportsAndInterviewsDisclaimerModal;
