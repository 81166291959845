import PropTypes from "prop-types";
import _ from "lodash";

const OptionPropType = PropTypes.oneOfType([
  PropTypes.object,
  (propValue, key, componentName) => {
    const curValue = propValue[key];
    if (!_.isArray(curValue) || curValue.length !== 2 || !(typeof curValue[1] === "string")) {
      return new Error(`Invalid option prop '${curValue}' supplied to ${componentName}`);
    }
    return null;
  },
]);

const OptionPropTypeArray = PropTypes.arrayOf(OptionPropType);

export { OptionPropType, OptionPropTypeArray };
