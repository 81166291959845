/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``scissor.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ScissorIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M4.5 3C3.67157 3 3 3.67157 3 4.5C3 5.05513 3.30156 5.53982 3.74979 5.79921L3.89292 5.87207C4.07852 5.95431 4.28392 6 4.5 6C5.32843 6 6 5.32843 6 4.5C6 3.67157 5.32843 3 4.5 3ZM6.13692 7.01447C6.95758 6.47913 7.5 5.55291 7.5 4.5C7.5 2.84315 6.15685 1.5 4.5 1.5C2.84315 1.5 1.5 2.84315 1.5 4.5C1.5 5.59593 2.08765 6.5546 2.96503 7.07811C2.98688 7.09233 3.00969 7.10556 3.03342 7.1177C3.10401 7.15734 3.17638 7.1942 3.25036 7.22814L6.73079 9L3.25037 10.7719C3.17638 10.8058 3.10401 10.8427 3.03341 10.8823C3.00968 10.8944 2.98688 10.9077 2.96503 10.9219C2.08765 11.4454 1.5 12.4041 1.5 13.5C1.5 15.1569 2.84315 16.5 4.5 16.5C6.15685 16.5 7.5 15.1569 7.5 13.5C7.5 12.4471 6.95758 11.5209 6.13692 10.9855L8.38393 9.8416L15.4097 13.4184C15.7789 13.6063 16.2304 13.4594 16.4184 13.0903C16.6063 12.7211 16.4594 12.2695 16.0903 12.0816L10.0371 9L16.0903 5.91837C16.4594 5.73045 16.6063 5.27887 16.4184 4.90974C16.2304 4.54061 15.7789 4.39371 15.4097 4.58163L8.38393 8.1584L6.13692 7.01447ZM3.7498 12.2008L3.89291 12.1279C4.07852 12.0457 4.28392 12 4.5 12C5.32843 12 6 12.6716 6 13.5C6 14.3284 5.32843 15 4.5 15C3.67157 15 3 14.3284 3 13.5C3 12.9449 3.30156 12.4602 3.7498 12.2008Z"
        transform=""
      />
    </g>
  </svg>
);

ScissorIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ScissorIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

ScissorIcon.displayName = "ScissorIcon";

export default ScissorIcon;
