import _ from "lodash";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { Table, YukaThemeProvider, Fonts, Button, ButtonStyles } from "yuka";

import {
  FundingTableStyles,
  INITIAL_TABLE_SIZE,
  EMPTY_STATE_MARGIN,
  TAB_MAP,
} from "./constants";
import { StyledButtonContainer } from "./StyledComponents";
import { investorNameSort } from "./utils";

import MixpanelEvents from "../../../utils/mixpanel/MixpanelEvents";
import { StyledCenteredEmptyState } from "../../StyledComponents";
import { useCompany } from "../../hooks";

const InvestorsTable = ({ entityProfiles, fundingRounds }) => {
  const [isShowingMore, setIsShowingMore] = useState(false);
  const [company] = useCompany();

  const entityProfileTableData = useMemo(() => {
    // For each entity profile, map them to the funding rounds they participated in
    return Object.values(
      _.mapValues(entityProfiles, (entityProfile) => ({
        investor: entityProfile.name,
        fundingRounds: _.filter(
          _.map(
            _.filter(fundingRounds, (fundingRound) =>
              _.some(
                fundingRound.investors,
                (investor) => investor[1] === entityProfile.apiId
              )
            ),
            (fundingRound) => fundingRound.event
          )
        ),
      }))
    )
      .sort(investorNameSort)
      .slice(0, isShowingMore ? undefined : INITIAL_TABLE_SIZE);
  }, [entityProfiles, fundingRounds, isShowingMore]);

  if (entityProfileTableData.length === 0) {
    return (
      <StyledCenteredEmptyState $margin={EMPTY_STATE_MARGIN}>
        Investor data not available
      </StyledCenteredEmptyState>
    );
  }

  return (
    <YukaThemeProvider theme={{ tableStyles: FundingTableStyles }}>
      <Table
        usePercentageColumnWidths
        columns={[
          {
            id: "investorName",
            header: "Investor",
            accessor: "investor",
            width: 50,
            sortable: true,
          },
          {
            id: "fundingRounds",
            header: "Funding Rounds",
            accessor: (entity) => entity,
            width: 50,
            sortable: true,
            cellRenderer: ({ value: entity }) => (
              <span>
                {entity.fundingRounds.map((fundingRound, index) => (
                  <span key={index}>
                    {index > 0 && <Fonts.Body1theme30> | </Fonts.Body1theme30>}
                    {fundingRound}
                  </span>
                ))}
              </span>
            ),
          },
        ]}
        data={entityProfileTableData}
        emptyTablePlaceholder={
          <Fonts.Headline1theme15>
            Investor data not available
          </Fonts.Headline1theme15>
        }
      />
      {Object.keys(entityProfiles).length > INITIAL_TABLE_SIZE && (
        <StyledButtonContainer>
          <Button
            buttonStyle={ButtonStyles.RAISED}
            onClick={() => {
              MixpanelEvents.clickFundingsShowMore(
                company?.name,
                TAB_MAP.INVESTORS,
                !isShowingMore
              );
              setIsShowingMore(!isShowingMore);
            }}
          >
            {isShowingMore ? "Show fewer" : "Show more"}
          </Button>
        </StyledButtonContainer>
      )}
    </YukaThemeProvider>
  );
};

InvestorsTable.propTypes = {
  entityProfiles: PropTypes.shape({
    name: PropTypes.string,
    apiId: PropTypes.string,
  }).isRequired,
  fundingRounds: PropTypes.arrayOf(
    PropTypes.shape({ investors: PropTypes.arrayOf(PropTypes.array) })
  ).isRequired,
};

export default InvestorsTable;
