import { DateTime } from "luxon";
import { useState, useEffect } from "react";
import styled from "styled-components";
import {
  HyperLink,
  LinkTypes,
  body1,
  Fonts,
  Button,
  ButtonStyles,
  Table,
  YukaThemeProvider,
} from "yuka";

import {
  EMPTY_STATE_MARGIN,
  FundingTableStyles,
  INITIAL_TABLE_SIZE,
  TAB_MAP,
} from "./constants";
import { StyledButtonContainer } from "./StyledComponents";

import { API_ENDPOINTS } from "../../../api/constants";
import useFetch from "../../../api/useFetch";
import MixpanelEvents from "../../../utils/mixpanel/MixpanelEvents";
import { useCompany } from "../../hooks";
import { StyledCenteredEmptyState } from "../../StyledComponents";

const StyledLink = styled(HyperLink)`
  ${body1}
`;

const StyledLoadingState = styled.div`
  display: flex;
  justify-content: center;
`;

const COIList = () => {
  const [company] = useCompany();
  const [isShowingMore, setIsShowingMore] = useState(false);
  useEffect(() => {
    MixpanelEvents.viewCompanyCertificatesOfIncorporationList(company?.name);
  }, [company]);
  const coiFilings = useFetch(
    API_ENDPOINTS.COI_FILINGS(),
    {
      company: company.zb_id,
    },
    { enabled: Boolean(company?.zb_id) }
  );
  const companyCOIs = coiFilings?.cleanedData?.data || [];

  if (coiFilings.isLoading) {
    return (
      <StyledLoadingState>
        <Fonts.Body1theme30>Loading...</Fonts.Body1theme30>
      </StyledLoadingState>
    );
  }
  if (coiFilings.isError) {
    return (
      <StyledCenteredEmptyState>
        An error has occurred.
      </StyledCenteredEmptyState>
    );
  }
  if (coiFilings.isSuccess && companyCOIs.length === 0) {
    return (
      <StyledCenteredEmptyState $margin={EMPTY_STATE_MARGIN}>
        COIs not available
      </StyledCenteredEmptyState>
    );
  }
  const numberOfRows = isShowingMore ? companyCOIs.length : INITIAL_TABLE_SIZE;

  const columns = [
    {
      accessor: "filing_date",
      header: "Date",
      width: 4,
      cellRenderer: ({ value: filingDate }) =>
        DateTime.fromISO(filingDate).toLocaleString(DateTime.DATE_FULL),
    },
    {
      id: "viewLink",
      accessor: (coiFiling) => coiFiling,
      header: "",
      width: 1,
      align: "right",
      cellRenderer: ({ value: coiFiling }) => (
        <StyledLink
          url={coiFiling.file_kn_url}
          linkType={LinkTypes.EXTERNAL_LINK}
        >
          View
        </StyledLink>
      ),
    },
  ];

  return (
    <YukaThemeProvider theme={{ tableStyles: FundingTableStyles }}>
      <Table
        data={companyCOIs.slice(0, numberOfRows)}
        columns={columns}
        usePercentageColumnWidths
      />
      {companyCOIs.length > INITIAL_TABLE_SIZE && (
        <StyledButtonContainer>
          <Button
            buttonStyle={ButtonStyles.RAISED}
            onClick={() => {
              MixpanelEvents.clickFundingsShowMore(company?.name, TAB_MAP.COIS);
              setIsShowingMore(!isShowingMore);
            }}
          >
            {isShowingMore ? "Show fewer" : "Show more"}
          </Button>
        </StyledButtonContainer>
      )}
    </YukaThemeProvider>
  );
};

export default COIList;
