import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { ColorPalette } from "../StylingConstants";

const StyledContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 28px;
  height: 8px;
  margin: 4px 0;
`;

const StyledSlider = styled.span`
  position: absolute;
  cursor: pointer;
  height: 100%;
  width: 100%;
  background-color: ${ColorPalette.minWhite};
  transition: 0.25s;
  border-radius: 12px;

  &:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    top: -4px;
    background-color: ${ColorPalette.fullWhite};
    transition: 0.25s;
    border-radius: 50%;
  }
`;

const StyledInput = styled.input`
  display: none;

  &:checked + ${StyledSlider} {
    background-color: ${ColorPalette.blue400};
    &:before {
      transform: translateX(12px);
    }
  }
`;

/**
 * A left-right toggle corresponding to a on-off switch. The value maps onto a checkbox input
 * and is frequently used for boolean values
 */
const SwitchInput = React.forwardRef((props, ref) => (
  <StyledContainer>
    <StyledInput ref={ref} {...props} type="checkbox" />
    <StyledSlider role="switch" />
  </StyledContainer>
));

SwitchInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  onChange: PropTypes.func,
};

SwitchInput.displayName = "SwitchInput";

export default SwitchInput;
