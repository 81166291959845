import axios from "axios";

const getBaseUrl = () => {
  if (window.location.hostname === "localhost") {
    // Assumes hwandudaedo backend is running on port 8004
    return "http://localhost:8004/api/";
  } else {
    // Trim "zxdata."
    const domain = window.location.hostname.slice(7);
    return `https://hd.${domain}/api/`;
  }
};

const AxiosInstance = axios.create({
  baseURL: getBaseUrl(),
  responseType: "json",
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
  },
});

export default AxiosInstance;
