import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import styled from "styled-components";

import TabItem from "./TabItem";
import useTabRoute from "./useTabRoute";

import { ColorPalette } from "../StylingConstants";

const StyledTabList = styled.div`
  border-bottom: 1px solid ${ColorPalette.minWhite};
  display: flex;
`;

const StyledChildren = styled.div`
  margin-left: auto;
`;

/**
 * This component renders a list of card tabs and calls the provided change handler with the name
 * of the tab clicked when clicked.
 *
 * Use a TabList when you need secondary tab navigation on your page (such as sections of a profile)
 */
const TabList = props => {
  const [currentTab, handleChangeTab] = useTabRoute(
    props.tabs.map(({ label }) => label),
    props.paramName,
    props.defaultTab
  );

  const handleTabClick = name => {
    props.onTabChange(name);
    handleChangeTab(name);
  };

  return (
    <StyledTabList className={props.className} role="tablist">
      {props.tabs.map(({ label, count }) => (
        <TabItem
          selected={currentTab === label}
          handleTabClick={_.partial(handleTabClick, label)}
          count={count}
          key={label}
          label={label}
        />
      ))}
      {props.children && <StyledChildren>{props.children}</StyledChildren>}
    </StyledTabList>
  );
};

TabList.propTypes = {
  onTabChange: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      count: PropTypes.number,
    })
  ).isRequired,
  paramName: PropTypes.string,
  defaultTab: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

TabList.defaultProps = {
  onTabChange: _.noop,
  paramName: "tab",
  defaultTab: null,
};

TabList.displayName = "TabList";

export default TabList;
