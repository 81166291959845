/*
 * Date formats available by default to be used in cell renderers.
 * All formatters consume a date in string format.
 */

// 1/19/2022
const monthDayYear = date => {
  const dateObject = new Date(date);
  return `${dateObject.getMonth() + 1}/${dateObject.getDate()}/${dateObject.getFullYear()}`;
};

// 19/1/2022
const dayMonthYear = date => {
  const dateObject = new Date(date);
  return `${dateObject.getDate()}/${dateObject.getMonth() + 1}/${dateObject.getFullYear()}`;
};

// January 19, 2022
const full = date => {
  const dateObject = new Date(date);
  return dateObject.toLocaleString("en-us", {
    month: "long",
    year: "numeric",
    day: "numeric",
  });
};

// 2022-01-19
const iso = date => {
  const dateObject = new Date(date);
  const dayString = `0${dateObject.getDate()}`.slice(-2);
  const monthString = `0${dateObject.getMonth() + 1}`.slice(-2);
  return `${dateObject.getFullYear()}-${monthString}-${dayString}`;
};

export { monthDayYear, dayMonthYear, full, iso };
