import React from "react";
import styled from "styled-components";

import Menu, { MenuDivider } from "../Menu";
import MenuItem from "../../List/MenuItem";
import Input from "../../FormField/Input";
import { SearchIcon } from "../../Icons";
import tokenizedStringCompare from "../../utils/tokenizedStringCompare";
import { FontColors, overline } from "../../Typography";

import useMenuNavigation from "./useMenuNavigation";

const UnhighlightedText = styled.span`
  ${FontColors.theme30}
`;

const MenuSearchInput = styled(Input)`
  border: none;
  background-color: transparent;
  height: 32px;
`;

const StyledSectionHeader = styled.div`
  margin-left: 12px;
  height: 32px;
  display: flex;
  align-items: center;
  ${FontColors.theme30}
  ${overline}
`;

/**
 * Based on a tokenized search, highlight the matching sections of text.
 *
 * Note that the current styles have the "highlighted" text color match the default;
 * we are actually just dimming the rest of the text
 *
 * @param {string} text
 * @param {string} search
 *
 * @returns {React.Element}
 */
const highlightText = (text, search) => {
  const tokens = search.split(" ");
  const lowerText = text.toLowerCase();
  const indexes = tokens
    .map(token => ({ index: lowerText.indexOf(token.toLowerCase()), token }))
    .sort((a, b) => a.index - b.index);
  const resultSegments = [];
  let prevEnd = 0;
  indexes.forEach(({ index, token }) => {
    if (index > prevEnd) {
      resultSegments.push(<UnhighlightedText>{text.slice(prevEnd, index)}</UnhighlightedText>);
      prevEnd = index;
    }
    if (index + token.length > prevEnd) {
      resultSegments.push(<span>{text.slice(prevEnd, index + token.length)}</span>);
      prevEnd = index + token.length;
    }
  });
  if (prevEnd < text.length) {
    resultSegments.push(<UnhighlightedText>{text.slice(prevEnd)}</UnhighlightedText>);
  }
  return <>{resultSegments}</>;
};

/**
 * A hook that integrates the Menu element(s) into an external interface that can be used easily.
 *
 * This hook is intended to be used internally by `useAnchoredMenu` and `useGlobalMenu`
 *
 * @param menuItems - An array of objects in the following shape:
 * {
 *   text: string,
 *   icon: func (Icon component),
 *   thumbnail: Element,
 *   menuItems, // For nesting menu levels
 *   disabled: bool,
 * }
 * Optionally, this can be an array of a config, in which case each config is considered a distinct
 * section. In order to support this, specify the sectioned parameter.
 *
 * This config has the shape:
 * {
 *   header: string,
 *   menuItems: Same shape as above
 *   nonMenuItemNode: Element
 * }
 *
 * @param searchable - a boolean for whether this is searchable. Will error if using multiple
 *   sections in the menu.
 * @param sectioned - a boolean for whether menu items reflect multiple sections. Will error if
 *   enabling search as well
 * @param onSearch - callback function whenever the user searches
 * @param elementRef - ref to attach to the menu
 * @param onItemClick - handler to pass on click of any item (can also specify individually)
 * @param onSearch - handler to handle searching within the menu
 * @param keyHandlerRef - a ref to attach to an element to attach keyboard event listeners to.
 *   Falls back to elementRef
 *
 */
const useMenu = (
  { menuItems, searchable, sectioned, elementRef, onItemClick, onSearch, keyHandlerRef, role },
  style
) => {
  if (sectioned && searchable) {
    throw new Error("Menus cannot be both sectioned and searchable");
  }

  const [searchValue, setSearch] = React.useState("");
  const searchRef = React.useRef(null);

  /**
   * Convert the data in a menu item object into a menu item element
   *
   * @param {object} item
   * @returns {React.Element}
   */
  const parseMenuItem = React.useCallback(
    item => {
      const onClick = item.onClick || onItemClick;

      let generatedSubmenu = null;
      if (item.menuItems) {
        generatedSubmenu = <Menu>{item.menuItems.map(parseMenuItem)}</Menu>;
      }

      return (
        <MenuItem
          key={item.key || `${item.value}_$_${item.text}`}
          {...item}
          leadingIcon={item.icon}
          leadingIconColor={item.iconColor}
          onClick={() => {
            // Clear search on item selection.
            setSearch("");
            onClick?.(item);
          }}
          submenu={generatedSubmenu}
        />
      );
    },
    [onItemClick]
  );

  const displayedUnselectedMenuItems = React.useMemo(() => {
    if (searchable && searchValue !== "") {
      return menuItems.filter(menuItem => tokenizedStringCompare(searchValue, menuItem.text));
    }
    return menuItems;
  }, [searchable, searchValue, menuItems]);

  const [onKeyDown, selectedElement] = useMenuNavigation(
    onItemClick,
    displayedUnselectedMenuItems,
    sectioned
  );

  const displayedMenuItems = React.useMemo(() => {
    if (selectedElement === null) {
      return displayedUnselectedMenuItems;
    }
    return displayedUnselectedMenuItems.map((topLevel, topIndex) => {
      if (selectedElement[0] === topIndex) {
        if (sectioned || (topLevel.menuItems && selectedElement.length === 2)) {
          return {
            ...topLevel,
            menuItems: topLevel.menuItems.map((item, index) =>
              index === selectedElement[1] ? { ...item, selected: true } : item
            ),
          };
        }
        return {
          ...topLevel,
          selected: true,
        };
      }
      return topLevel;
    });
  }, [displayedUnselectedMenuItems, selectedElement, sectioned]);

  const computedMenuItems = React.useMemo(() => {
    if (sectioned) {
      const _computed = [];
      displayedMenuItems.forEach((section, sectionNumber) => {
        if (section.header) {
          _computed.push(<StyledSectionHeader>{section.header}</StyledSectionHeader>);
        }
        if (section.nonMenuItemNode) {
          _computed.push(section.nonMenuItemNode);
        }
        _computed.push(...section.menuItems.map(parseMenuItem));
        if (sectionNumber !== displayedMenuItems.length - 1) {
          _computed.push(<MenuDivider role="separator" key={sectionNumber} />);
        }
      });
      return _computed;
    }

    // filter and convert the text to a highlighted version
    if (searchable && searchValue !== "") {
      return displayedMenuItems
        .map(menuItem => ({
          ...menuItem,
          key: `${menuItem.value}_$_${menuItem.text}`, // explicitly use the text because it is stable
          text: highlightText(menuItem.text, searchValue),
        }))
        .map(parseMenuItem);
    }

    return displayedMenuItems.map(parseMenuItem);
  }, [displayedMenuItems, sectioned, searchable, searchValue, parseMenuItem]);

  React.useEffect(() => {
    const target = searchRef.current;
    const altTarget = keyHandlerRef?.current || elementRef?.current;
    if (onKeyDown) {
      target?.addEventListener("keydown", onKeyDown);
      altTarget?.addEventListener("keydown", onKeyDown);
    }
    return () => {
      if (onKeyDown) {
        target?.removeEventListener("keydown", onKeyDown);
        altTarget?.removeEventListener("keydown", onKeyDown);
      }
    };
  });

  return (
    <Menu style={style} ref={elementRef} role={role}>
      {searchable && (
        <MenuSearchInput
          ref={searchRef}
          leadingIcon={SearchIcon}
          onChange={event => {
            setSearch(event.target.value);
            if (onSearch) {
              onSearch(event.target.value);
            }
          }}
          autoFocus // eslint-disable-line jsx-a11y/no-autofocus
          placeholder="Search"
          value={searchValue}
        />
      )}
      {computedMenuItems}
    </Menu>
  );
};

export default useMenu;
