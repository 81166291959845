import _ from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import {
  CartesianGrid,
  ComposedChart,
  Line,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import styled from "styled-components";
import {
  Button,
  ButtonStyles,
  ColorPalette,
  DateCell,
  Fonts,
  Modal,
  ModalStyles,
  NumberCell,
  Table,
  YukaColorPalette,
  YukaThemeProvider,
} from "yuka";

import SelectedPeriodDot from "./SelectedPeriodDot";

import { API_ENDPOINTS } from "../../../api/constants";
import useFetch from "../../../api/useFetch";
import { DataverseColors } from "../../../hdYuka/constants";
import {
  StyledModalButtonRow,
  StyledModalContent,
} from "../../../Portfolios/StyledComponents";
import {
  expandedMoneyFormat,
  shortMoneyFormat,
} from "../../../utils/displayFormatUtils";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import MixpanelEvents from "../../../utils/mixpanel/MixpanelEvents";
import { LegendItemContainer } from "../../CompanyProfileLineGraph/StyledComponents";
import { useCompany } from "../../hooks";
import { StyledCenteredEmptyState, StyledCircle } from "../../StyledComponents";

const LegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const ColoredText = styled(Fonts.Body1theme80)`
  color: ${({ color }) => color};
`;

const ReportedMarksLegendItemContainer = styled(LegendItemContainer)`
  background-color: ${YukaColorPalette.surface2};
`;

const UnderlyingFundsTableStyles = {
  colors: {
    header: YukaColorPalette.surface1,
    row: YukaColorPalette.surface1,
  },
  header: {
    borderTop: `1px dashed ${ColorPalette.white15}`,
    borderBottom: `1px dashed ${ColorPalette.white15}`,
  },
  cells: {
    borderBottom: `1px dashed ${ColorPalette.white15}`,
  },
};

const UNDERLYING_FUNDS_TABLE_COLUMNS = [
  {
    id: "underlying_fund",
    header: "Underlying Fund",
    accessor: "name",
    width: 150,
  },
  {
    id: "price_per_share",
    header: "Price / Share",
    accessor: "price_per_share",
    width: 100,
    cellRenderer: NumberCell,
    formatter: (value) => expandedMoneyFormat(value, 2, 2),
  },
  {
    id: "security",
    header: "Security",
    accessor: "security",
    width: 100,
  },
  {
    id: "notional_value",
    header: "Notional Value",
    accessor: "total_value",
    width: 100,
    cellRenderer: NumberCell,
    formatter: shortMoneyFormat,
  },
  {
    id: "date",
    header: "Date",
    accessor: "date",
    width: 75,
    cellRenderer: DateCell,
    dateFormatter: (value) => DateTime.fromISO(value).toFormat("MMM yy"),
  },
];

// Debounce hover tracker by 1 second
const HOVER_INTERACTION_DEBOUNCE_DELAY = 1000;
const hoverCompanyReportedMarksModal = _.debounce(
  MixpanelEvents.hoverCompanyReportedMarksModal,
  HOVER_INTERACTION_DEBOUNCE_DELAY
);

const ReportedMarksModal = (props) => {
  const [company, companyIsLoading] = useCompany();
  // These hovered/selected period values correspond to the X-axis label values
  // e.g. '2023-07-01'
  const [hoveredPeriod, setHoveredPeriod] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(null);

  const reportedMarksQuery = useFetch(
    API_ENDPOINTS.COMPANY_REPORTED_MARKS_VALUATION(company.zb_id)
  );
  const reportedMarksData = reportedMarksQuery?.data?.data;
  const graphData = useMemo(
    () =>
      _.reverse(
        _.map(reportedMarksData, (d, date) => ({
          date,
          area: [d.min, d.max],
          weighted_avg: d.weighted_avg,
          simple_avg: d.simple_avg,
          median: d.median,
        }))
      ),
    [reportedMarksData]
  );

  const xAxisTicks = useMemo(() => _.map(graphData, "date"), [graphData]);

  const hoveredReportedMarksData = useMemo(
    () => (hoveredPeriod ? reportedMarksData[hoveredPeriod] : null),
    [reportedMarksData, hoveredPeriod]
  );
  const selectedReportedMarksData = useMemo(
    () => (selectedPeriod ? reportedMarksData[selectedPeriod] : null),
    [reportedMarksData, selectedPeriod]
  );
  const displayedReportedMarksData = useMemo(
    () => selectedReportedMarksData || hoveredReportedMarksData,
    [selectedReportedMarksData, hoveredReportedMarksData]
  );

  // Set hover point data
  const onMouseMove = useCallback(
    (d) => {
      if (!reportedMarksData) {
        return;
      }

      hoverCompanyReportedMarksModal(company?.name, d.activeLabel);
      if (d.activeLabel) {
        setHoveredPeriod(d.activeLabel);
      } else {
        setHoveredPeriod(null);
      }
    },
    [reportedMarksData, setHoveredPeriod, company?.name]
  );

  // Set selected point data
  const onClick = useCallback(
    (d) => {
      if (!reportedMarksData) {
        return;
      }

      MixpanelEvents.selectCompanyReportedMarksModal(
        company?.name,
        d.activeLabel
      );
      if (d.activeLabel) {
        setSelectedPeriod(d.activeLabel);
      } else {
        setSelectedPeriod(null);
      }
    },
    [reportedMarksData, setSelectedPeriod, company?.name]
  );

  // The active dot on the <Line /> component requires a tooltip to be enabled
  // So we pass in a null tooltip function as a hack
  const renderNullTooltip = useCallback(() => null, []);

  if (companyIsLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Modal modalStyle={ModalStyles.MINIMAL} onClose={props.closeModal}>
      <YukaThemeProvider
        theme={{
          tableStyles: UnderlyingFundsTableStyles,
        }}
      >
        <StyledModalContent $width={900} $maxHeight={800} $minHeight={800}>
          <Fonts.Headline2theme80>
            Reported Marks for {company.name}
          </Fonts.Headline2theme80>
          <LegendContainer>
            <ReportedMarksLegendItemContainer>
              <StyledCircle color={DataverseColors.branding400} />
              <Fonts.Body1theme80>Dollar-Weighted AVG.</Fonts.Body1theme80>
              {displayedReportedMarksData?.weighted_avg ? (
                <ColoredText color={DataverseColors.branding400}>
                  {expandedMoneyFormat(
                    displayedReportedMarksData.weighted_avg,
                    2,
                    2
                  )}
                </ColoredText>
              ) : (
                <Fonts.Body1theme30>--</Fonts.Body1theme30>
              )}
            </ReportedMarksLegendItemContainer>
            <ReportedMarksLegendItemContainer>
              <StyledCircle color={DataverseColors.indigo} />
              <Fonts.Body1theme80>Simple AVG.</Fonts.Body1theme80>
              {displayedReportedMarksData?.simple_avg ? (
                <ColoredText color={DataverseColors.indigo}>
                  {expandedMoneyFormat(
                    displayedReportedMarksData.simple_avg,
                    2,
                    2
                  )}
                </ColoredText>
              ) : (
                <Fonts.Body1theme30>--</Fonts.Body1theme30>
              )}
            </ReportedMarksLegendItemContainer>
            <ReportedMarksLegendItemContainer>
              <StyledCircle color={DataverseColors.magenta} />
              <Fonts.Body1theme80>Median</Fonts.Body1theme80>
              {displayedReportedMarksData?.median ? (
                <ColoredText color={DataverseColors.magenta}>
                  {expandedMoneyFormat(displayedReportedMarksData.median, 2, 2)}
                </ColoredText>
              ) : (
                <Fonts.Body1theme30>--</Fonts.Body1theme30>
              )}
              <span>
                <Fonts.Body1theme80>Max</Fonts.Body1theme80>
                <Fonts.Body1theme30> | </Fonts.Body1theme30>
                <Fonts.Body1theme80>Min</Fonts.Body1theme80>
              </span>
              <span>
                {displayedReportedMarksData?.max ? (
                  <Fonts.Body1theme80>
                    {expandedMoneyFormat(displayedReportedMarksData.max, 2, 2)}
                  </Fonts.Body1theme80>
                ) : (
                  <Fonts.Body1theme30>--</Fonts.Body1theme30>
                )}
                <Fonts.Body1theme30> | </Fonts.Body1theme30>
                {displayedReportedMarksData?.min ? (
                  <Fonts.Body1theme80>
                    {expandedMoneyFormat(displayedReportedMarksData.min, 2, 2)}
                  </Fonts.Body1theme80>
                ) : (
                  <Fonts.Body1theme30>--</Fonts.Body1theme30>
                )}
              </span>
            </ReportedMarksLegendItemContainer>
          </LegendContainer>
          <ResponsiveContainer width="100%" height={250}>
            <ComposedChart
              data={graphData}
              onMouseMove={onMouseMove}
              onClick={onClick}
              margin={{
                top: 0,
                right: 0,
                left: 12,
                bottom: 16,
              }}
            >
              <YAxis
                axisLine={false}
                tickLine={false}
                fontSize={14}
                domain={[
                  (dataMin) => dataMin * 0.98,
                  (dataMax) => dataMax * 1.02,
                ]}
                orientation="right"
                tickFormatter={(value) => expandedMoneyFormat(value, 2, 2)}
                stroke={ColorPalette.white50}
              />
              <XAxis
                axisLine={false}
                tickLine={false}
                interval={0}
                fontSize={14}
                tickFormatter={(value) =>
                  DateTime.fromISO(value).toFormat("MMM")
                }
                ticks={xAxisTicks}
                dataKey="date"
                stroke={ColorPalette.white50}
              />
              <CartesianGrid
                vertical={false}
                stroke="rgba(255, 255, 255, 0.05)"
              />
              <Area
                type="monotone"
                dataKey="area"
                stroke="none"
                fill={ColorPalette.white15}
                connectNulls
                activeDot={false}
              />
              <Tooltip content={renderNullTooltip} />
              <Line
                type="monotone"
                dataKey="weighted_avg"
                stroke={DataverseColors.branding400}
                strokeWidth={2}
                dot={<SelectedPeriodDot selectedPeriod={selectedPeriod} />}
                connectNulls
                activeDot={{
                  r: 6,
                  strokeWidth: 2,
                  stroke: YukaColorPalette.surface0,
                }}
              />
              <Line
                type="monotone"
                dataKey="simple_avg"
                stroke={DataverseColors.indigo}
                strokeWidth={2}
                dot={<SelectedPeriodDot selectedPeriod={selectedPeriod} />}
                connectNulls
                activeDot={{
                  r: 6,
                  strokeWidth: 2,
                  stroke: YukaColorPalette.surface0,
                }}
              />
              <Line
                type="monotone"
                dataKey="median"
                stroke={DataverseColors.magenta}
                strokeWidth={2}
                dot={<SelectedPeriodDot selectedPeriod={selectedPeriod} />}
                connectNulls
                activeDot={{
                  r: 6,
                  strokeWidth: 2,
                  stroke: YukaColorPalette.surface0,
                }}
              />
            </ComposedChart>
          </ResponsiveContainer>
          <Table
            columns={UNDERLYING_FUNDS_TABLE_COLUMNS}
            data={selectedReportedMarksData?.funds || []}
            emptyTablePlaceholder={
              <StyledCenteredEmptyState $margin={120}>
                Please select a specific month to see the underlying funds
              </StyledCenteredEmptyState>
            }
          />
        </StyledModalContent>
        <StyledModalButtonRow>
          <Button buttonStyle={ButtonStyles.RAISED} onClick={props.closeModal}>
            Close
          </Button>
        </StyledModalButtonRow>
      </YukaThemeProvider>
    </Modal>
  );
};

ReportedMarksModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ReportedMarksModal;
