import React from "react";
import { API_ENDPOINTS } from "../api/constants";
import useFetch from "../api/useFetch";
import styled from "styled-components";
import {
  BarLineChartIcon,
  caption2,
  Card,
  CardStyles,
  Fonts,
  FontColors,
  LinkExternalIcon,
  LinkTypes,
  List,
  ListItem,
  ListStyles,
  Thumbnail,
} from "yuka";
import _ from "lodash";
import { DateTime } from "luxon";
import LoadingSpinner from "../utils/LoadingSpinner";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";
import { PAGE_WIDTH } from "../constants";
import SiteFooter from "../SiteFooter/component";

const InsightsContainer = styled.div`
  ${PAGE_WIDTH}
  padding-top: 48px;
  gap: 24px;
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  padding-bottom: 16px;
`;

const StyledCard = styled(Card)`
  height: fit-content;
`;

const StyledDate = styled.span`
  ${caption2}
  ${FontColors.theme50}
  white-space: nowrap;
`;

const StyledPageLayout = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  gap: 16px;
  height: 100%;

  > * {
    flex-grow: 1;
  }
`;

const SECONDARY_MARKET_MONTHLY_MOVERS = 10;
const INSTITUTIONAL_MARKS_MONTHLY_MOVERS = 20;

const Insights = () => {
  React.useEffect(() => {
    MixpanelEvents.viewInsights();
  }, []);

  const insightArticleQueryInfo = useFetch(API_ENDPOINTS.INSIGHT_ARTICLE());
  const insightArticles = insightArticleQueryInfo?.cleanedData?.data;

  const [monthlyMoverArticles, pressReportArticles] = _.partition(
    insightArticles,
    (article) => {
      return (
        article.article_type === SECONDARY_MARKET_MONTHLY_MOVERS ||
        article.article_type === INSTITUTIONAL_MARKS_MONTHLY_MOVERS
      );
    }
  );

  return (
    <InsightsContainer>
      <Fonts.Headline3theme80>Insights</Fonts.Headline3theme80>
      {insightArticleQueryInfo.isLoading ? (
        <LoadingSpinner absolute={false} />
      ) : (
        <StyledPageLayout>
          <StyledCard
            cardStyle={CardStyles.SECTIONED}
            tagline="ZX Monthly Movers summarizes notable movements in the holding values of venture-backed private companies"
            title="ZX Monthly Movers"
          >
            <List divider>
              {_.map(monthlyMoverArticles, (article) => (
                <ListItem
                  leadingIcon={BarLineChartIcon}
                  linkType={LinkTypes.EXTERNAL_LINK}
                  listStyle={ListStyles.TWO_LINE}
                  onClick={() =>
                    MixpanelEvents.openMonthlyMoversArticle(article.title)
                  }
                  subtext={article.subtitle}
                  text={article.title}
                  trailingContent={
                    <StyledDate>
                      {`Published on ${DateTime.fromISO(
                        article.posted_on
                      ).toLocaleString(DateTime.DATE_SHORT)}`}
                    </StyledDate>
                  }
                  url={article.url}
                />
              ))}
            </List>
          </StyledCard>
          <StyledCard
            cardStyle={CardStyles.SECTIONED}
            title="Reports & Press Releases"
          >
            <List divider>
              {_.map(pressReportArticles, (article) => (
                <ListItem
                  avatar={
                    <Thumbnail
                      src={article.thumbnail_kn_url}
                      alt={article.title}
                      size="32px"
                    />
                  }
                  linkType={LinkTypes.EXTERNAL_LINK}
                  listStyle={ListStyles.TWO_LINE}
                  onClick={() =>
                    MixpanelEvents.openReportOrPressRelease(article.title)
                  }
                  subtext={`Posted on ${DateTime.fromISO(
                    article.posted_on
                  ).toLocaleString(DateTime.DATE_SHORT)}`}
                  text={article.title}
                  trailingContent={<LinkExternalIcon />}
                  url={article.url}
                />
              ))}
            </List>
          </StyledCard>
        </StyledPageLayout>
      )}
      <SiteFooter />
    </InsightsContainer>
  );
};

export default Insights;
