/* eslint-disable react/no-array-index-key */
import _ from "lodash";
import PropTypes from "prop-types";
import { FieldArray as RFFFieldArray } from "react-final-form-arrays";
import styled from "styled-components";
import React, { useEffect } from "react";

import { XCloseIcon } from "../Icons";
import { HyperLink, IconButton } from "../Button";
import { StyledCaption2 } from "../Typography";

import useDidMount from "../utils/useDidMount";

import InputRow from "../Form/InputRow";

const StyledIconButton = styled(IconButton)`
  margin-left: 8px;
`;

const FieldArrayComponent = ({ fields, fieldComponent: FieldComponent, addText, ...props }) => {
  const didMount = useDidMount();
  useEffect(() => {
    if (didMount && !fields.length) {
      fields.push();
    }
  }, [fields, didMount]);

  return (
    <>
      {fields.map((field, index) => (
        <InputRow key={field}>
          <FieldComponent name={field} {...props} />
          {_.size(fields) !== 1 && (
            <StyledIconButton onClick={() => fields.remove(index)} icon={XCloseIcon} />
          )}
        </InputRow>
      ))}
      <InputRow>
        <StyledCaption2>
          <HyperLink onClick={() => fields.push()}>{addText}</HyperLink>
        </StyledCaption2>
      </InputRow>
    </>
  );
};

FieldArrayComponent.propTypes = {
  fields: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  addText: PropTypes.string.isRequired,
  fieldComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

const FieldArray = props => (
  <RFFFieldArray
    {...props}
    name={props.name}
    component={FieldArrayComponent}
    validate={props.validate}
    addText={props.addText}
    fieldComponent={props.component}
  />
);

FieldArray.propTypes = {
  required: PropTypes.bool,
  addText: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  name: PropTypes.string.isRequired,
  validate: PropTypes.func,
};

FieldArray.defaultProps = {
  required: false,
  validate: null,
};

export default FieldArray;
