const SANKEY_NODE_DIRECT_APPROVED = "Direct (Approved)";
const SANKEY_NODE_DIRECT_ROFR = "Direct (ROFR'd)";
const SANKEY_NODE_SPV = "SPV";
const SANKEY_NODE_FORWARD = "Forward";
const SANKEY_NODE_UNKNOWN_SECURITY = "Unknown Security";
const SANKEY_NODE_PREFERRED = "Preferred";
const SANKEY_NODE_COMMON = "Common";

export {
  SANKEY_NODE_DIRECT_APPROVED,
  SANKEY_NODE_DIRECT_ROFR,
  SANKEY_NODE_SPV,
  SANKEY_NODE_FORWARD,
  SANKEY_NODE_UNKNOWN_SECURITY,
  SANKEY_NODE_PREFERRED,
  SANKEY_NODE_COMMON,
};
