/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``at-sign.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const AtSignIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M5.47507 1.54142C7.15855 0.745855 9.06166 0.543176 10.8749 0.966341C12.6882 1.38951 14.305 2.41363 15.4625 3.87218C16.6199 5.33074 17.2499 7.13794 17.25 8.99994V9.74999C17.25 10.5456 16.9339 11.3087 16.3713 11.8713C15.8087 12.4339 15.0457 12.75 14.25 12.75C13.4544 12.75 12.6913 12.4339 12.1287 11.8713C12.0096 11.7522 11.9016 11.6242 11.8052 11.4887C11.1183 12.2624 10.1161 12.75 9.00001 12.75C6.92894 12.75 5.25001 11.0711 5.25001 8.99999C5.25001 6.92892 6.92894 5.24999 9.00001 5.24999C9.84421 5.24999 10.6233 5.52895 11.25 5.99972C11.2502 5.58563 11.5859 5.24999 12 5.24999C12.4142 5.24999 12.75 5.58578 12.75 5.99999V9.74999C12.75 10.1478 12.908 10.5293 13.1893 10.8107C13.4707 11.092 13.8522 11.25 14.25 11.25C14.6478 11.25 15.0294 11.092 15.3107 10.8107C15.592 10.5293 15.75 10.1478 15.75 9.74999V9.00004C15.75 9.00002 15.75 9.00006 15.75 9.00004C15.7499 7.4766 15.2345 5.99795 14.2875 4.8046C13.3405 3.61124 12.0176 2.77332 10.534 2.42709C9.05045 2.08086 7.49336 2.24669 6.11597 2.89761C4.73857 3.54853 3.62188 4.64626 2.94746 6.0123C2.27304 7.37835 2.08056 8.93236 2.40132 10.4217C2.72208 11.911 3.53722 13.248 4.71418 14.2153C5.89115 15.1826 7.36072 15.7233 8.88396 15.7495C10.4072 15.7757 11.8945 15.2858 13.104 14.3595C13.4329 14.1077 13.9036 14.1701 14.1555 14.499C14.4073 14.8279 14.3449 15.2986 14.016 15.5504C12.5377 16.6825 10.7199 17.2813 8.85817 17.2492C6.99644 17.2172 5.20029 16.5564 3.76178 15.3741C2.32326 14.1919 1.32699 12.5577 0.934946 10.7375C0.542905 8.91723 0.778155 7.01788 1.60245 5.34827C2.42674 3.67866 3.79159 2.33699 5.47507 1.54142ZM11.25 8.99999C11.25 7.75735 10.2427 6.74999 9.00001 6.74999C7.75737 6.74999 6.75001 7.75735 6.75001 8.99999C6.75001 10.2426 7.75737 11.25 9.00001 11.25C10.2426 11.25 11.25 10.2426 11.25 8.99999Z"
        transform=""
      />
    </g>
  </svg>
);

AtSignIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

AtSignIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

AtSignIcon.displayName = "AtSignIcon";

export default AtSignIcon;
