import { useMemo } from "react";

import useLatestOrderflowForPortfolio from "./useLatestOrderflowForPortfolio";
import usePortfolioValue from "./usePortfolioValue";
import useShareLotsForPortfolio from "./useShareLotsForPortfolio";

/**
 * Using the current value of the portfolio and the cost basis of the portfolio, computes the
 * unrealized gain of the portfolio.
 *
 * @param {string} portfolioId
 * @return {number|null} - Returns the computed on success, or null if any of the queries are still
 *    loading or encountered errors.
 */
const usePortfolioUnrealizedGain = (portfolioId) => {
  const currentValue = usePortfolioValue(portfolioId);
  const [shareLots, shareLotsLoading] = useShareLotsForPortfolio(portfolioId);
  const [orderFlowReports, orderFlowLoading] =
    useLatestOrderflowForPortfolio(portfolioId);
  // Compute the cost basis across all share lots for portfolioID
  const costBasis = useMemo(() => {
    if (!shareLots || !orderFlowReports) {
      return null;
    }
    return shareLots.reduce((acc, companyShareLots, index) => {
      // We do not include company share lots for companies whose current ZX Index Value is null,
      // because then the share lot would be -100%.
      if (!companyShareLots || !orderFlowReports[index][0]?.zx_index_price) {
        return acc;
      }
      return (
        acc +
        companyShareLots.reduce(
          (acc, shareLot) =>
            acc + Number(shareLot.price_per_share) * shareLot.share_quantity,
          0
        )
      );
    }, 0);
  }, [shareLots, orderFlowReports]);

  const totalUnrealizedGain = useMemo(() => {
    if (costBasis === null || currentValue === null) {
      return null;
    }
    return currentValue - costBasis;
  }, [currentValue, costBasis]);

  if (shareLotsLoading || orderFlowLoading) {
    return null;
  }
  return totalUnrealizedGain;
};

export default usePortfolioUnrealizedGain;
