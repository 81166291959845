import _ from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useMemo, useRef } from "react";
import styled, { css } from "styled-components";
import {
  Card,
  CardStyles,
  Fonts,
  HyperLink,
  LinkStyles,
  LinkTypes,
  useTooltip,
} from "yuka";

import useElementDimensions from "../../utils/useElementDimensions";
import { StyledThreeLineText, StyledTwoLineText } from "../StyledComponents";

const TWO_LINE_CONTENT_HEIGHT = 108;
const THREE_LINE_CONTENT_HEIGHT = 148;
const TWO_LINE_HEIGHT = 42;
const THREE_LINE_HEIGHT = 63;

const StyledDocumentCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${(props) => {
    if (props.$titleTextLines === 3) {
      return css`
        height: ${THREE_LINE_CONTENT_HEIGHT}px;
      `;
    } else if (props.$titleTextLines === 2) {
      return css`
        height: ${TWO_LINE_CONTENT_HEIGHT}px;
      `;
    }
  }}
  padding: 0 16px;

  > :first-child {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  > :last-child {
    flex-shrink: 0;
    justify-self: flex-end;
  }
`;

const DocumentCard = ({ document, titleTextLines, onClick }) => {
  const cardRef = useRef(null);
  const [textRef, current] = useElementDimensions();
  const TextComponent =
    titleTextLines === 2 ? StyledTwoLineText : StyledThreeLineText;

  const tooltipContent = useMemo(() => {
    if (
      (titleTextLines === 2 && current?.scrollHeight > TWO_LINE_HEIGHT) ||
      (titleTextLines === 3 && current?.scrollHeight > THREE_LINE_HEIGHT)
    ) {
      return document.title;
    }
    return null;
  }, [document.title, titleTextLines, current]);

  const tooltip = useTooltip(cardRef, tooltipContent);

  return (
    <HyperLink
      linkType={LinkTypes.EXTERNAL_LINK}
      linkStyle={LinkStyles.UNSTYLED}
      url={document.link}
    >
      <Card ref={cardRef} onClick={onClick} cardStyle={CardStyles.MINIMAL}>
        <StyledDocumentCardContent $titleTextLines={titleTextLines}>
          <div>
            <Fonts.Caption2theme50>{document.source}</Fonts.Caption2theme50>
            <TextComponent ref={textRef}>
              <Fonts.Body1theme80>{document.title}</Fonts.Body1theme80>
            </TextComponent>
          </div>
          <Fonts.Caption2theme50>
            Published on {DateTime.fromISO(document.date).toLocaleString()}
          </Fonts.Caption2theme50>
        </StyledDocumentCardContent>
      </Card>
      {tooltip}
    </HyperLink>
  );
};

DocumentCard.propTypes = {
  onClick: PropTypes.func,
  document: PropTypes.shape({
    date: PropTypes.string,
    link: PropTypes.string,
    source: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  titleTextLines: PropTypes.number,
};

DocumentCard.defaultProps = {
  onClick: _.noop,
  titleTextLines: 3,
};

export default DocumentCard;
