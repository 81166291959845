import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { ColorPalette, XCloseIcon } from "yuka";

import {
  ZX_INDEX_VALUE_CHANGE_FILTER,
  ROBUSTNESS_FILTER,
  ZX_INDEX_VALUE_CHANGE_OPTIONS,
  ROBUSTNESS_SCORE_OPTIONS,
  WATCHLIST_OPTIONS,
  WATCHLIST_FILTER,
} from "./constants";
import FilterButton from "./FilterButton";
import SingleSelect from "./FilterSelectDropdown";

import { ActionButton } from "../hdYuka";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";

const FilterActionsDivider = styled.div`
  flex: 0 0 1px;
  height: 40px;
  background-color: ${ColorPalette.white15};
`;

const StyledFilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const makeSelectOptions = (options) =>
  options.map((option) => ({
    value: option,
    label: option,
  }));

const WatchlistFilterDropdown = (props) => (
  <SingleSelect
    {...props}
    label="Watchlist"
    options={makeSelectOptions(WATCHLIST_OPTIONS)}
  />
);

const ZXIndexValueChangeFilterDropdown = (props) => (
  <SingleSelect
    {...props}
    label="ZX Index Value Week Change %"
    options={makeSelectOptions(ZX_INDEX_VALUE_CHANGE_OPTIONS)}
  />
);

const RobustnessFilterDropdown = (props) => (
  <SingleSelect
    {...props}
    label="Robustness Score (RS)"
    options={makeSelectOptions(ROBUSTNESS_SCORE_OPTIONS)}
  />
);

// const PremiumToLastRoundDropdown = (props) => (
//   <SingleSelect
//     {...props}
//     label="ZX Index Value Premium to LR Price"
//     options={makeSelectOptions(PREMIUM_TO_LAST_ROUND_OPTIONS)}
//   />
// );

const CompanyFilters = ({ makeOnChangeFunction, filters, resetFilters }) => {
  return (
    <StyledFilterContainer>
      <FilterButton
        onChange={makeOnChangeFunction(WATCHLIST_FILTER)}
        value={filters.watchlist}
        dropdownComponent={WatchlistFilterDropdown}
      >
        Watchlist
      </FilterButton>
      <FilterButton
        value={filters[ROBUSTNESS_FILTER]}
        onChange={makeOnChangeFunction(ROBUSTNESS_FILTER)}
        dropdownComponent={RobustnessFilterDropdown}
      >
        Robustness Score
      </FilterButton>
      <FilterButton
        value={filters[ZX_INDEX_VALUE_CHANGE_FILTER]}
        onChange={makeOnChangeFunction(ZX_INDEX_VALUE_CHANGE_FILTER)}
        dropdownComponent={ZXIndexValueChangeFilterDropdown}
      >
        Week change %
      </FilterButton>
      {/* Removed because the filter interaction is broken as of inventing HDFundingRound */}
      {/* Filters for company list will be fixed and improved with a followup epic */}
      {/*<FilterButton*/}
      {/*  value={filters[PREMIUM_TO_LAST_ROUND_FILTER]}*/}
      {/*  onChange={makeOnChangeFunction(PREMIUM_TO_LAST_ROUND_FILTER)}*/}
      {/*  dropdownComponent={PremiumToLastRoundDropdown}*/}
      {/*>*/}
      {/*  Premium to Last Round*/}
      {/*</FilterButton>*/}
      <FilterActionsDivider />
      <ActionButton
        disabled={!Object.values(filters).some((value) => value)}
        onClick={() => {
          MixpanelEvents.clickResetAllCompanyListFilters(filters);
          resetFilters();
        }}
        icon={XCloseIcon}
      >
        Reset all
      </ActionButton>
    </StyledFilterContainer>
  );
};

CompanyFilters.propTypes = {
  resetFilters: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    watchlist: PropTypes.string,
    robustness: PropTypes.string,
    zx_index_value_change: PropTypes.string,
    last_round_price_diff: PropTypes.string,
  }).isRequired,
  makeOnChangeFunction: PropTypes.func.isRequired,
};

export default CompanyFilters;
