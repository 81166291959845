import React, { useRef } from "react";
import PropTypes from "prop-types";

import { StyledHeaderContainer } from "./StyledComponents";

import { useTooltip } from "../Tooltip";

/*
 * Exists as its own component so that we can have access to a ref for it to attach
 * tooltips conditionally. Does not handle actual value rendering/value truncation, that is handled
 * by the DefaultHeaderRenderer.
 */
const RenderedTableHeader = ({ column, manualSortBy, tooltipContent }) => {
  const headerRef = useRef(null);
  const tooltip = useTooltip(headerRef, tooltipContent);

  const headerProps = column.getHeaderProps(manualSortBy ? {} : column.getSortByToggleProps());

  return (
    <>
      <StyledHeaderContainer
        ref={headerRef}
        key={headerProps.key}
        $column={column}
        {...headerProps}
      >
        {
          // Render the header.
          column.render("Header")
        }
      </StyledHeaderContainer>
      {tooltipContent ? tooltip : null}
    </>
  );
};

RenderedTableHeader.propTypes = {
  column: PropTypes.shape({
    render: PropTypes.func,
    getHeaderProps: PropTypes.func,
    getSortByToggleProps: PropTypes.func,
  }).isRequired,
  manualSortBy: PropTypes.bool,
  tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

RenderedTableHeader.defaultProps = {
  manualSortBy: false,
};

export default RenderedTableHeader;
