import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  ColorPalette,
  YukaColorPalette,
  useCurrentTab,
} from "yuka";

import { StyledTabList } from "./component";
import {
  TABLIST_VARIANTS,
  VARIANT_BUTTON_GROUP,
  VARIANT_DEFAULT,
} from "./constants";

import useElementDimensions from "../../utils/useElementDimensions";

const StyledMoreArrowGradient = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: 24px;
  width: 64px;

  ${(props) =>
    props.$right
      ? css`
          justify-content: flex-end;

          background: linear-gradient(
            0.25turn,
            transparent,
            ${(props) =>
                YukaColorPalette[`surface${props.theme.surfaceLevel || 1}`]}
              62.5%
          );
        `
      : css`
          justify-content: flex-start;

          background: linear-gradient(
            0.75turn,
            transparent,
            ${(props) =>
                YukaColorPalette[`surface${props.theme.surfaceLevel || 1}`]}
              62.5%
          );
        `}
`;

const StyledMoreArrowButton = styled.div`
  pointer-events: all;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  overflow: auto;

  &:hover {
    svg path {
      fill: ${ColorPalette.white80};
    }
  }

  &:active {
    svg path {
      fill: ${ColorPalette.white100};
    }
  }
`;

const StyledTabGroup = styled(StyledTabList)`
  position: relative;
  margin: 0;
  flex-shrink: ${({ $resizeable }) => ($resizeable ? 1 : 0)};
  ${(props) => {
    if (props.$variant === VARIANT_BUTTON_GROUP) {
      return css`
        border-radius: 12px;
        padding: 5px;
        background-color: ${YukaColorPalette.surface1};
      `;
    }
  }}
`;

const StyledTabs = styled.div`
  display: flex;
  gap: ${(props) => (props.$variant === VARIANT_BUTTON_GROUP ? "8px" : "24px")};
  flex-grow: 1;
  overflow-x: auto;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const StyledOverlay = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: none;
`;

const TabGroup = ({
  index: groupIndex,
  variant,
  resizeable,
  routeId,
  queryParam,
  defaultTabId,
  onSelectTab,
  children,
  scrollIntoView,
}) => {
  const [tabsRef, current] = useElementDimensions();

  // We allow users of the tab list to use either route IDs or query params to select tabs.
  const { [routeId]: id } = useParams();
  const currentTab = useCurrentTab(queryParam);

  const location = useLocation();
  const navigate = useNavigate();
  const tabs = useMemo(() => React.Children.toArray(children), [children]);

  const [scrollPosition, setScrollPosition] = useState(0);

  const scroll = useCallback(
    (right) => {
      if (current) {
        if (right) {
          current.scrollTo({
            left: scrollPosition + 400,
            behavior: "smooth",
          });
        } else {
          current.scrollTo({
            left: scrollPosition - 400,
            behavior: "smooth",
          });
        }
      }
    },
    [scrollPosition, current]
  );

  useEffect(() => {
    if (
      tabs.length > 0 &&
      !id &&
      routeId &&
      defaultTabId &&
      tabs.find((tab) => tab.props.id === defaultTabId)
    ) {
      navigate(`${location.pathname}${defaultTabId}`, { replace: true });
    } else if (
      tabs.length > 0 &&
      (!currentTab || !tabs.find((tab) => tab.props.id === currentTab)) &&
      !currentTab &&
      queryParam &&
      defaultTabId &&
      tabs.find((tab) => tab.props.id === defaultTabId)
    ) {
      navigate(
        {
          search: `?${queryParam}=${defaultTabId}`,
        },
        {
          replace: true,
        }
      );
    }
  }, [
    routeId,
    queryParam,
    currentTab,
    tabs,
    tabs.length,
    id,
    defaultTabId,
    location.pathname,
    navigate,
  ]);

  const onScroll = useCallback(() => {
    setScrollPosition(current?.scrollLeft);
  }, [current?.scrollLeft]);

  useEffect(() => {
    if (!current) {
      return null;
    }
    const scrolledElement = current;

    scrolledElement.addEventListener("scroll", onScroll);
    return () => {
      scrolledElement.removeEventListener("scroll", onScroll);
    };
  });

  return (
    <StyledTabGroup $variant={variant} $resizeable={resizeable}>
      <StyledTabs $variant={variant} ref={tabsRef}>
        {React.Children.map(children, (child, index) =>
          React.cloneElement(child, {
            index,
            variant,
            scrollIntoView,
            parentWidth: current?.clientWidth,
            scrollPosition,
            key: `tab-${groupIndex}_${index}`,
            currentTab: id || currentTab,
            isSelected:
              child.props.id === currentTab ||
              String(child.props.id) === currentTab,
            onSelectTab: () => {
              if (onSelectTab) {
                onSelectTab(child.props.id);
              }
              if (child.props.onSelectTab) {
                child.props.onSelectTab();
              }
              if (routeId) {
                navigate(
                  location.pathname.replace(`/${id}`, `/${child.props.id}`)
                );
              } else {
                navigate({
                  search: `?${queryParam}=${child.props.id}`,
                });
              }
            },
          })
        )}
      </StyledTabs>
      <StyledOverlay>
        {resizeable && scrollPosition > 0 ? (
          <StyledMoreArrowGradient>
            <StyledMoreArrowButton onClick={() => scroll(false)} role="button">
              <ChevronLeftIcon size={18} color={ColorPalette.white50} />
            </StyledMoreArrowButton>
          </StyledMoreArrowGradient>
        ) : (
          <div />
        )}
        {resizeable &&
        Math.ceil(scrollPosition) <
          Math.ceil(current?.scrollWidth - current?.clientWidth) ? (
          <StyledMoreArrowGradient $right>
            <StyledMoreArrowButton onClick={() => scroll(true)} role="button">
              <ChevronRightIcon size={18} color={ColorPalette.white50} />
            </StyledMoreArrowButton>
          </StyledMoreArrowGradient>
        ) : (
          <div />
        )}
      </StyledOverlay>
    </StyledTabGroup>
  );
};

TabGroup.propTypes = {
  index: PropTypes.number,
  variant: PropTypes.oneOf(TABLIST_VARIANTS),
  defaultTabId: PropTypes.string,
  routeId: PropTypes.string,
  queryParam: PropTypes.string,
  onSelectTab: PropTypes.func,
  children: PropTypes.node.isRequired,
  resizeable: PropTypes.bool,
  scrollIntoView: PropTypes.bool,
};

TabGroup.defaultProps = {
  onSelectTab: null,
  variant: VARIANT_DEFAULT,
};

export default TabGroup;
