import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import styled from "styled-components";
import {
  YukaThemeProvider,
  Fonts,
  Z_INDEXES,
  ButtonStyles,
  Button,
} from "yuka";

import { PORTFOLIOS_FEATURE_NAME } from "./api/constants";
import { AuthContext, AuthProvider } from "./auth";
import ErrorBoundary from "./ErrorBoundary";
import { DATAVERSE_ENJOYERS } from "./global-nav/constants";
import defaultTheme from "./hdYuka/defaultTheme";
import AgreementProvider from "./routes/AgreementProvider";
import MainRoutes from "./routes/component";
import StateProvider from "./routes/StateProvider";
import MixpanelEvents from "./utils/mixpanel/MixpanelEvents";
import { MixpanelProvider } from "./utils/mixpanel/MixpanelUtils";
import useHasFeatureAccess from "./utils/useHasFeatureAccess";
import { useWalkthrough } from "./Walkthrough";

const StyledHDContainer = styled.div`
  color: rgba(255, 255, 255, 0.8);
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`;

const SiteIntroduction = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  backdrop-filter: blur(5px);
  z-index: ${Z_INDEXES.zIndexModalVisible};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 16px;
`;

const StyledSiteName = styled.div`
  display: inline-block;
  background: linear-gradient(
      90deg,
      white,
      ${(props) => props.theme.colors.branding500},
      white
    )
    0 0 / 400% 100%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  @keyframes move-bg {
    to {
      background-position: 400% 0;
    }
  }
  animation: move-bg 5s infinite linear;
`;

const REDESIGN_WALKTHROUGH_NAME = "redesign introduction";

const HDContainer = ({ children }) => {
  const { user } = useContext(AuthContext);
  const siteName = DATAVERSE_ENJOYERS.includes(user?.email)
    ? "ZX Dataverse"
    : "ZX Data";

  const { walkthroughShowing, completeWalkthrough } = useWalkthrough(
    "has_viewed_redesign_introduction"
  );
  const [portfoliosFeatureAccess] = useHasFeatureAccess(
    PORTFOLIOS_FEATURE_NAME
  );

  useEffect(() => {
    if (walkthroughShowing) {
      MixpanelEvents.startWalkthrough(REDESIGN_WALKTHROUGH_NAME);
    }
  }, [walkthroughShowing]);

  // We will not show the introduction prior to launching the dataverse project.
  if (!portfoliosFeatureAccess) {
    return <StyledHDContainer>{children}</StyledHDContainer>;
  }

  if (walkthroughShowing) {
    return (
      <StyledHDContainer>
        {children}
        <SiteIntroduction>
          <Fonts.Headline5theme100>
            Welcome to the brand new <StyledSiteName>{siteName}</StyledSiteName>
          </Fonts.Headline5theme100>
          <Fonts.Headline2theme50>
            Experience a more powerful and intuitive way to navigate private
            market data
          </Fonts.Headline2theme50>
          <div />
          <div />
          <Button
            onClick={() => {
              MixpanelEvents.completeWalkthrough(REDESIGN_WALKTHROUGH_NAME, 1);
              completeWalkthrough();
            }}
            buttonStyle={ButtonStyles.CTA}
          >
            Start Exploring
          </Button>
        </SiteIntroduction>
      </StyledHDContainer>
    );
  }
  return <StyledHDContainer>{children}</StyledHDContainer>;
};

HDContainer.propTypes = {
  children: PropTypes.node,
};

const MINUTE = 60 * 1000;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 60 * MINUTE,
      staleTime: 60 * MINUTE,
    },
  },
});

document.addEventListener("DOMContentLoaded", () => {
  ReactDOM.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <StateProvider>
            <AuthProvider>
              <MixpanelProvider>
                <YukaThemeProvider theme={defaultTheme}>
                  <AgreementProvider>
                    <HDContainer>
                      <ErrorBoundary>
                        <MainRoutes />
                      </ErrorBoundary>
                    </HDContainer>
                  </AgreementProvider>
                </YukaThemeProvider>
              </MixpanelProvider>
            </AuthProvider>
          </StateProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById("application")
  );
});
