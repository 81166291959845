/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``reverse-left.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ReverseLeftIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M6.53033 1.71967C6.82322 2.01256 6.82322 2.48744 6.53033 2.78033L4.81066 4.5H10.5C13.3995 4.5 15.75 6.85051 15.75 9.75C15.75 12.6495 13.3995 15 10.5 15H3C2.58579 15 2.25 14.6642 2.25 14.25C2.25 13.8358 2.58579 13.5 3 13.5H10.5C12.5711 13.5 14.25 11.8211 14.25 9.75C14.25 7.67893 12.5711 6 10.5 6H4.81066L6.53033 7.71967C6.82322 8.01256 6.82322 8.48744 6.53033 8.78033C6.23744 9.07322 5.76256 9.07322 5.46967 8.78033L2.46967 5.78033C2.17678 5.48744 2.17678 5.01256 2.46967 4.71967L5.46967 1.71967C5.76256 1.42678 6.23744 1.42678 6.53033 1.71967Z"
        transform=""
      />
    </g>
  </svg>
);

ReverseLeftIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ReverseLeftIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

ReverseLeftIcon.displayName = "ReverseLeftIcon";

export default ReverseLeftIcon;
