import styled from "styled-components";

import { ToastMessage, Z_INDEXES } from "yuka";

import { useSelector, useDispatch, ACTIONS } from "../routes/StateProvider";

const StyledToastContainer = styled.div`
  z-index: ${Z_INDEXES.zIndexAriaModalSelect};
  position: fixed;
  bottom: 16px;
  left: 16px;
  max-width: 480px;
`;

const ToastList = () => {
  const dispatch = useDispatch();
  const toasts = useSelector((state) => state.toasts);

  const deque = (id) => dispatch({ type: ACTIONS.removeToast, id });

  return (
    <StyledToastContainer>
      {toasts.map((toast) => (
        <ToastMessage
          key={toast.id}
          title={toast.message}
          onClose={() => deque(toast.id)}
        />
      ))}
    </StyledToastContainer>
  );
};

export default ToastList;
