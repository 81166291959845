import _ from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useEffect } from "react";

import MixpanelEvents from "../../utils/mixpanel/MixpanelEvents";

const debouncedMixpanel = _.debounce(
  MixpanelEvents.hoverCompanyProfileLineChart,
  500,
  { leading: false, trailing: true }
);

const CompanyLineGraphTooltip = ({
  setHoveredDate,
  payload,
  companyName,
  comparisonCount,
  indicatorCount,
}) => {
  // Props are injected by recharts.
  useEffect(() => {
    if (payload[0]?.payload?.time) {
      debouncedMixpanel(
        companyName,
        payload[0]?.payload?.time,
        comparisonCount,
        indicatorCount
      );
    }
    setHoveredDate(payload[0]?.payload?.time);
  }, [companyName, comparisonCount, indicatorCount, setHoveredDate, payload]);
  if (!payload[0]?.payload?.time) {
    return null;
  }

  const formattedDate = DateTime.fromISO(payload[0]?.payload?.time).toFormat(
    "dd MMM yy"
  );
  const [day, month, year] = formattedDate.split(" ");
  // Inject the apostrophe to the year.
  return `${day} ${month} '${year}`;
};

CompanyLineGraphTooltip.propTypes = {
  setHoveredDate: PropTypes.func,
  payload: PropTypes.array,
  companyName: PropTypes.string,
  comparisonCount: PropTypes.number,
  indicatorCount: PropTypes.number,
};

export default CompanyLineGraphTooltip;
