// React Documentation: https://react.dev/reference/react/Component#componentdidcatch-caveats
// If we want additional functionality, react-error-boundary can be a good place to start

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ZXLogo from "./utils/svgs/ZXLogo";
import { API_ENDPOINTS } from "./api/constants";
import AxiosInstance from "./api/AxiosInstance";

const StyledError = styled.div`
  margin: 50px auto 0;
  width: 50%;
`;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    const errorPostDetails = {
      error: error.message,
      stack: info.componentStack,
      route: window.location.href,
    };
    AxiosInstance.post(API_ENDPOINTS.REPORT_ERROR(), errorPostDetails);
  }

  render() {
    if (this.state.hasError) {
      return (
        <StyledError>
          <ZXLogo />
          <p>Oops, something went wrong... our supported platforms are:</p>
          <ul>
            <li>Chrome</li>
            <li>Firefox</li>
            <li>IE 11</li>
            <li>Edge</li>
            <li>Safari 11</li>
          </ul>
          <p>
            The development team has been notified of your issue and will work
            to resolve it as soon as possible. If you do not see your browser
            listed above, please use one of those to view this site.
          </p>
          <p>We apologize for any inconvenience.</p>
        </StyledError>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
