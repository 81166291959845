import PropTypes from "prop-types";
import React, { useContext } from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { Button, ButtonStyles, Card, InputRow } from "yuka";

import { API_ENDPOINTS } from "../../api/constants";
import useWrite from "../../api/useWrite";
import { AuthContext } from "../../auth";
import { FinalFormField } from "../../hdYuka";
import LoadingSpinner from "../../utils/LoadingSpinner";
import useLocalStorage from "../../utils/useLocalStorage";
import { StyledReverseRow } from "../StyledComponents";

const REMEMBER_2FA_KEY = "remember_2fa_token";

const DisableConfirmStep = ({ goToPrevious }) => {
  const { setUserData } = useContext(AuthContext);
  const { deleteItem } = useLocalStorage();
  const navigate = useNavigate();
  const validate = (value) => (value ? undefined : "Required");

  const disableTokenQuery = useWrite(
    API_ENDPOINTS.CHANGE_ACCOUNT_SETTINGS(),
    true,
    {
      message: "Two-factor authentication successfully disabled.",
    }
  );

  const onSubmit = () => {
    disableTokenQuery.mutate(
      {
        token_auth_enabled: false,
      },
      {
        onSuccess: () => {
          deleteItem(REMEMBER_2FA_KEY);
          setUserData();
          navigate(0);
        },
      }
    );
  };

  return (
    <Card
      title="Disable Two-Factor Authentication"
      tagline="You are about to disable two-factor authentication. This weakens your account security, are you sure?"
    >
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting, dirty, valid }) => (
          <form onSubmit={handleSubmit}>
            {submitting && <LoadingSpinner />}
            <InputRow>
              <FinalFormField
                id="confirm_disable"
                name="confirm_disable"
                type="checkbox"
                label="Yes, I am sure"
                validate={[validate]}
              />
            </InputRow>
            <StyledReverseRow>
              <Button buttonStyle={ButtonStyles.RAISED} onClick={goToPrevious}>
                Back
              </Button>
              <Button
                buttonStyle={ButtonStyles.CTA}
                disabled={submitting || !dirty || !valid}
                type="submit"
              >
                Disable
              </Button>
            </StyledReverseRow>
          </form>
        )}
      </Form>
    </Card>
  );
};

DisableConfirmStep.propTypes = {
  goToPrevious: PropTypes.func.isRequired,
};

export default DisableConfirmStep;
