import React, { useCallback, useState, useMemo, useContext } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  Button,
  ButtonStyles,
  ColorPalette,
  Fonts,
  PlusIcon,
  MaximizeIcon,
  MinimizeIcon,
  YukaThemeProvider,
} from "yuka";

import AddPortfolioCompanyModal from "./AddPortfolioCompanyModal";
import {
  MODE_ZX_INDEX_VALUE,
  SORT_CRITERIA_ADDED_DATE,
  SORT_DIRECTION_DESC,
} from "./constants";
import { usePortfolioCompanies, usePortfolioCompanyAggregates } from "./hooks";
import PortfolioCompanyAccordionTrigger from "./PortfolioCompanyAccordionTrigger";
import { PortfolioModeContext } from "./PortfolioProfile";
import SortByDropdown from "./SortByDropdown";
import {
  PortfolioTableHeader,
  StyledEmptyStateContainer,
  StyledFlexGrow,
} from "./StyledComponents";
import { SORT_FUNCTION_MAP } from "./utils/sortingUtils";

import { Accordion, AccordionItem, ActionButton } from "../hdYuka";
import { KEY_ACTION_BUTTON_CONTAINER } from "../hdYuka/yukaThemeOverrides";
import LoadingSpinner from "../utils/LoadingSpinner";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";

const StyledHorizontalRule = styled.hr`
  border: none;
  border-bottom: 1px dashed ${ColorPalette.white15};
`;

const PortfolioActionButtons = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  margin-bottom: 24px;
`;

const PortfolioSummaryTable = () => {
  const [accordionValue, setAccordionValue] = useState([]);
  const { id: portfolioId } = useParams();
  const { mode } = useContext(PortfolioModeContext);
  const [portfolioCompanies, portfolioCompaniesAreLoading] =
    usePortfolioCompanies(portfolioId);
  const [sortState, setSortState] = useState({
    criteria: SORT_CRITERIA_ADDED_DATE,
    direction: SORT_DIRECTION_DESC,
  });
  const portfolioCompanyAggregates =
    usePortfolioCompanyAggregates(portfolioCompanies);

  const sortedPortfolioCompanies = useMemo(() => {
    if (portfolioCompaniesAreLoading) {
      return null;
    }
    return portfolioCompanyAggregates.sort(
      SORT_FUNCTION_MAP[sortState.criteria](sortState.direction, mode)
    );
  }, [
    mode,
    portfolioCompaniesAreLoading,
    portfolioCompanyAggregates,
    sortState.direction,
    sortState.criteria,
  ]);

  const [addCompanyModalOpen, setAddCompanyModalOpen] = useState(false);

  const expandAll = useCallback(() => {
    MixpanelEvents.expandAllCompanies(mode);
    setAccordionValue(portfolioCompanies.map((company) => company.apiId));
  }, [portfolioCompanies, mode]);

  const collapseAll = useCallback(() => {
    MixpanelEvents.collapseAllCompanies(mode);
    setAccordionValue([]);
  }, [mode]);

  const headers = useMemo(
    () =>
      mode === MODE_ZX_INDEX_VALUE
        ? [
            "Company",
            "ZX Index Value",
            "Quantity",
            "Total value",
            "Total unrealized gain",
            "52W range",
          ]
        : [
            "Company",
            "Custom valuation",
            "Quantity",
            "Total value",
            "Total unrealized gain",
            "ZX Index Value",
          ],
    [mode]
  );

  if (portfolioCompaniesAreLoading) {
    return <LoadingSpinner />;
  }

  if (portfolioCompanies === null) {
    return <Fonts.Body1theme30>An error has occurred.</Fonts.Body1theme30>;
  }

  return (
    <>
      {portfolioCompanies.length === 0 ? (
        <StyledFlexGrow>
          <StyledEmptyStateContainer>
            <Fonts.Headline2theme80>
              Your portfolio is empty
            </Fonts.Headline2theme80>
            <Fonts.Body1theme50>
              Add companies to see market insights, performance, and more.
            </Fonts.Body1theme50>
            <YukaThemeProvider
              theme={{
                button: {
                  [ButtonStyles.CTA]: {
                    container: KEY_ACTION_BUTTON_CONTAINER,
                  },
                },
              }}
            >
              <Button
                buttonStyle={ButtonStyles.CTA}
                leadingIcon={PlusIcon}
                onClick={() => {
                  setAddCompanyModalOpen(true);
                  MixpanelEvents.openAddCompanyToPortfolioModal(
                    "portfolio - first company"
                  );
                }}
              >
                Add a company
              </Button>
            </YukaThemeProvider>
          </StyledEmptyStateContainer>
        </StyledFlexGrow>
      ) : (
        <div>
          <PortfolioActionButtons>
            {portfolioCompanies.length === accordionValue.length ? (
              <ActionButton icon={MinimizeIcon} onClick={collapseAll}>
                Collapse all
              </ActionButton>
            ) : (
              <ActionButton icon={MaximizeIcon} onClick={expandAll}>
                Expand all
              </ActionButton>
            )}
            <SortByDropdown
              mode={mode}
              currentSort={sortState}
              updateSort={setSortState}
            />
          </PortfolioActionButtons>
          <PortfolioTableHeader>
            {headers.map((header) => (
              <Fonts.Overlinetheme50 key={`header_${header}`}>
                {header}
              </Fonts.Overlinetheme50>
            ))}
            <div />
          </PortfolioTableHeader>
          <Accordion
            type="multiple"
            collapsible
            value={accordionValue}
            onValueChange={setAccordionValue}
          >
            {sortedPortfolioCompanies.map((aggregate, index) => (
              <React.Fragment key={`row_${aggregate.company.apiId}_fragment`}>
                <AccordionItem
                  key={`row_${aggregate.company.apiId}`}
                  value={aggregate.company.apiId}
                >
                  <PortfolioCompanyAccordionTrigger
                    id={index === 0 ? "expand-table-row" : undefined}
                    isOpen={accordionValue.includes(aggregate.company.apiId)}
                    companyAggregate={aggregate}
                  />
                </AccordionItem>
                <StyledHorizontalRule />
              </React.Fragment>
            ))}
          </Accordion>
        </div>
      )}
      {addCompanyModalOpen && (
        <AddPortfolioCompanyModal
          mode={mode}
          onClose={() => setAddCompanyModalOpen(false)}
        />
      )}
    </>
  );
};

export default PortfolioSummaryTable;
