/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``arrow-right.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ArrowRightIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M8.46967 3.21967C8.76256 2.92678 9.23744 2.92678 9.53033 3.21967L14.7803 8.46967C15.0732 8.76256 15.0732 9.23744 14.7803 9.53033L9.53033 14.7803C9.23744 15.0732 8.76256 15.0732 8.46967 14.7803C8.17678 14.4874 8.17678 14.0126 8.46967 13.7197L12.4393 9.75H3.75C3.33579 9.75 3 9.41421 3 9C3 8.58579 3.33579 8.25 3.75 8.25H12.4393L8.46967 4.28033C8.17678 3.98744 8.17678 3.51256 8.46967 3.21967Z"
        transform=""
      />
    </g>
  </svg>
);

ArrowRightIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ArrowRightIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

ArrowRightIcon.displayName = "ArrowRightIcon";

export default ArrowRightIcon;
