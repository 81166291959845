import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import WizardContext from "./WizardContext";

import HyperLink, { LinkStyles } from "../../Button/HyperLink";
import IconButton, { IconButtonStyles } from "../../Button/IconButton";
import { PageSection } from "../../Layout";
import { XCloseIcon } from "../../Icons";
import { LinkTypes } from "../../Button/getLinkProps";
import { Fonts } from "../../Typography";

const FullPageFormBase = styled(PageSection)`
  height: 100vh;
  padding-bottom: 32px;
  box-sizing: border-box;
`;

const FullPageFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 auto;
  padding: 0 32px 80px;
  max-width: 1264px;
  min-width: 1024px;
  height: fit-content;
`;

const FullPageFormContents = styled.div`
  display: flex;
  gap: 16px;

  > :first-child {
    min-width: 420px;
    flex-grow: 1;
  }

  > :nth-child(2) {
    width: 420px;
    flex-grow: 0;
    flex-shrink: 0;
  }
`;

const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledLogoAndCloseButton = styled.div`
  display: flex;
  margin: 32px 32px 0;
  padding-top: 4px; // to make the total height of the container with contents 80px
  align-items: center;
  justify-content: space-between;
`;

const StyledLogoElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
`;

const StyledCloseElements = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledFooter = styled(Fonts.Body1theme50)`
  margin-top: auto;
  padding-top: 32px;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FullPageFormLayout = props => {
  const { onClose } = React.useContext(WizardContext);

  const keyPressHandler = React.useCallback(
    e => {
      if (e.code === "Escape") {
        onClose();
      }
    },
    [onClose]
  );

  React.useEffect(() => {
    window.addEventListener("keyup", keyPressHandler);

    return () => {
      window.removeEventListener("keyup", keyPressHandler);
    };
  }, [keyPressHandler]);

  return (
    <FullPageFormBase>
      <StyledLogoAndCloseButton>
        <StyledLogoElement>
          <HyperLink
            linkStyle={LinkStyles.UNSTYLED}
            url={props.homeUrl}
            linkType={props.homeLinkType}
          >
            {props.logo}
          </HyperLink>
        </StyledLogoElement>
        <StyledCloseElements>
          <Fonts.Caption2theme50>"ESC" to Close</Fonts.Caption2theme50>
          <IconButton icon={XCloseIcon} buttonStyle={IconButtonStyles.OUTLINE} onClick={onClose} />
        </StyledCloseElements>
      </StyledLogoAndCloseButton>
      <FullPageFormContainer>
        <StyledHeader>
          <Fonts.Headline3theme100>{props.title}</Fonts.Headline3theme100>
          {props.subtitle && <Fonts.Body2theme80>{props.subtitle}</Fonts.Body2theme80>}
        </StyledHeader>
        {props.progressNode}
        <FullPageFormContents>
          <FormColumn>{props.leftContent}</FormColumn>
          {props.rightContent && <FormColumn>{props.rightContent}</FormColumn>}
        </FullPageFormContents>
        {props.footer && <StyledFooter>{props.footer}</StyledFooter>}
      </FullPageFormContainer>
    </FullPageFormBase>
  );
};

FullPageFormLayout.propTypes = {
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.node,
  /* Disclaimers and other footer content */
  footer: PropTypes.node,
  progressNode: PropTypes.element,
  leftContent: PropTypes.element.isRequired,
  rightContent: PropTypes.element,
  /* Site logo */
  logo: PropTypes.element.isRequired,
  /* URL to navigate to if logo clicked */
  homeUrl: PropTypes.string,
  homeLinkType: PropTypes.oneOf(Object.values(LinkTypes)),
};

FullPageFormLayout.defaultProps = {
  homeUrl: "/",
  homeLinkType: LinkTypes.LOCAL_LINK,
};

export default FullPageFormLayout;
