import styled, { css } from "styled-components";
import { Button, Fonts, YukaColorPalette } from "yuka";

import { FinalFormField } from "../hdYuka";
import { DataverseColors } from "../hdYuka/constants";
import applyOpacityToHex from "../utils/applyOpacityToHex";

const AuthFormInputErrorTrailingContent = styled(Fonts.Body1sell)`
  margin-left: -32px;
  white-space: nowrap;
  text-align: center;
  background-color: ${YukaColorPalette.surface0};
  height: 62px;
  line-height: 62px;
  border-radius: 12px;
  margin-right: 1px;
  padding-right: 20px;
  box-sizing: border-box;
  border: none;
`;

/*
 * Some seriously aggressive Yuka styling overriding for the login form.
 */
const AuthButton = styled(Button)`
  border-radius: 12px;
  height: 64px;
  width: 100%;

  > span {
    font-size: 18px;
  }
`;

const AuthFormInput = styled(FinalFormField)`
  > :nth-child(1) {
    height: 64px;
    width: 600px;
    border-radius: 13px;
    padding: 0;

    border: none;
    background-image: linear-gradient(
      to top right,
      #6e7dca99 0%,
      #74cbd399 100%
    );

    ${(props) =>
      props.$error &&
      css`
        background-image: none;
        background-color: ${DataverseColors.red};
      `}

    &:focus-within {
      background-image: linear-gradient(
        to top right,
        #6e7dca99 0%,
        #74cbd399 100%
      );
      background-color: transparent;

      > ${AuthFormInputErrorTrailingContent} {
        display: none;
      }
    }

    > input {
      box-sizing: border-box;
      border: none;
      height: 62px;
      margin: 1px;
      font-size: 20px !important;
      padding: 16px 20px;
      border-radius: 12px;

      background-color: ${YukaColorPalette.surface0};

      &:hover {
        background-color: ${YukaColorPalette.surface0};
      }

      &:focus {
        padding: 16px 19px;
        height: 60px;
        margin: 2px;
        background-color: ${YukaColorPalette.surface0};
        box-shadow: 0 0 15px 5px
          ${applyOpacityToHex(DataverseColors.branding500, 0.3)};
      }
    }
  }
`;

const FormContents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  > ${Fonts.Body2sell} {
    align-self: flex-start;
  }
`;

const AuthCheckboxes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-self: flex-start;
`;

const StyledFormDescription = styled(Fonts.Body2theme50).attrs({ as: "p" })`
  margin-bottom: 24px;
`;

export {
  AuthButton,
  AuthFormInput,
  AuthFormInputErrorTrailingContent,
  AuthCheckboxes,
  FormContents,
  StyledFormDescription,
};
