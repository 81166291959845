import styled, { css } from "styled-components";
import { ColorPalette, YukaColorPalette } from "yuka";

const StyledRow = styled.div`
  position: relative;
  display: grid;
  // Simulate a table's columns.
  grid-template-columns: 50% 20% 30%;
  row-gap: 16px;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  border-bottom: 1px dashed ${ColorPalette.white15};

  background: ${({ $isSelected }) =>
    $isSelected ? YukaColorPalette.activeTransparent : "transparent"};

  ${(props) =>
    props.$noInteraction
      ? css``
      : css`
          cursor: pointer;

          &:hover {
            background: ${YukaColorPalette.hoverTransparent};
          }
          &:active {
            background: ${YukaColorPalette.activeTransparent};
          }
        `}
`;

const StyledDetailPanelFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Styled16Gap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export { StyledRow, StyledDetailPanelFields, Styled16Gap };
