import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";
import _ from "lodash";

import FullPageFormLayout from "./internal/FullPageFormLayout";
import WizardContext from "./internal/WizardContext";

import { Fonts, FontColors } from "../Typography";
import { PrimaryColorPalette } from "../StylingConstants";
import ComponentGroup from "../Layout/ComponentGroup";
import { LinkTypes } from "../Button/getLinkProps";

const ProgressBarContainer = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
`;

const WizardStepItem = styled(Fonts.Body1theme50)`
  border-bottom: 4px solid ${PrimaryColorPalette.white50};
  padding-bottom: 8px;
  ${props =>
    props.$showHighlight
      ? css`
          border-color: ${PrimaryColorPalette.blue500};
          ${FontColors.branding}
        `
      : ""}
  flex: 1 0 100px;
`;

const actionProps = [
  "submitText",
  "advanceText",
  "submitDisabled",
  "onSubmit",
  "onClose",
  "altText",
  "onClickAlt",
];
const passthroughProps = [
  "title",
  "subtitle",
  "footer",
  "leftContent",
  "logo",
  "homeUrl",
  "homeLinkType",
];

/**
 * An interface for displaying multi step forms. This component needs state to be managed
 * externally. Consider this a more flexible version of `Wizard` when you need more control over
 * implementation details.
 */
const ControlledWizard = props => {
  let rightContent = null;
  if (props.summary && props.rightContent) {
    rightContent = (
      <>
        <ComponentGroup>{props.rightContent}</ComponentGroup>
        <ComponentGroup>{props.summary}</ComponentGroup>
      </>
    );
  } else if (props.summary) {
    rightContent = <ComponentGroup>{props.summary}</ComponentGroup>;
  } else if (props.rightContent) {
    rightContent = <ComponentGroup>{props.rightContent}</ComponentGroup>;
  }

  return (
    <WizardContext.Provider value={_.pick(props, actionProps)}>
      <FullPageFormLayout
        {..._.pick(props, passthroughProps)}
        rightContent={rightContent}
        progressNode={
          props.stepNames.length > 1 && (
            <ProgressBarContainer>
              {props.stepNames.map((name, index) => (
                <WizardStepItem key={name} $showHighlight={index < props.currentStep}>
                  {name}
                </WizardStepItem>
              ))}
            </ProgressBarContainer>
          )
        }
      />
    </WizardContext.Provider>
  );
};

ControlledWizard.propTypes = {
  /*
   * Form header. This accepts nodes in order to support custom typography formatting, but is not
   * designed for more complex elements.
   */
  title: PropTypes.node.isRequired,
  /* Disclaimers and other footer content */
  footer: PropTypes.node,
  /* The main body content of the form */
  leftContent: PropTypes.node.isRequired,
  /* Interactive elements that are supplemental parts of the form. e.g. file uploads */
  rightContent: PropTypes.node,
  /*
   * Elements that show a summary of the state of the form. Is displayed below the right side
   * content if both are specified
   */
  summary: PropTypes.node,
  stepNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentStep: PropTypes.number.isRequired,
  /* Site logo */
  logo: PropTypes.element.isRequired,
  /* URL to navigate to if logo clicked */
  homeUrl: PropTypes.string,
  homeLinkType: PropTypes.oneOf(Object.values(LinkTypes)),
  /* Close event handler */
  onClose: PropTypes.func.isRequired,
  /* Text to display for a submit button */
  submitText: PropTypes.string,
  /* Text to display for a submit button */
  advanceText: PropTypes.string,
  /* Submit event handler */
  onSubmit: PropTypes.func.isRequired,
  /* Text to display for an alternate action. Only applicable if using the default wizard submit */
  onClickAlt: PropTypes.func,
  /* Alternate action event handler. Only applicable if using the default wizard submit */
  altText: PropTypes.string,
};

ControlledWizard.defaultProps = {
  submitText: "Submit",
  advanceText: "Next",
  altText: "Back",
};

ControlledWizard.displayName = "ControlledWizard";

export default ControlledWizard;
