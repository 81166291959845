import _ from "lodash";

const COMPARISON_COLORS = [
  // Set 1
  "#A60683",
  "#D63CB4",
  "#FF82E3",
  "#EAB6DE",
  "#E4CFE0",
  // Set 2
  "#1D4CC3",
  "#346DFF",
  "#6B8BDE",
  "#8290B4",
  // Set 3
  "#964800",
  "#C86912",
  "#FF993C",
  "#615D00",
  "#A6A80B",
  "#FFFA79",
  "#E5E4BE",
];

const makeRandomColorPicker = (colorSet) => {
  let remainingColors = _.cloneDeep(colorSet);

  return () => {
    const index = _.random(0, remainingColors.length - 1);
    const ret = _.pullAt(remainingColors, [index])[0];

    if (remainingColors.length === 0) {
      remainingColors = _.cloneDeep(colorSet);
    }

    return ret;
  };
};

export default makeRandomColorPicker(COMPARISON_COLORS);
