import _ from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import {
  Card,
  CardStyles,
  Fonts,
  HyperLink,
  LinkStyles,
  LinkTypes,
  ImageIcon,
  ColorPalette,
  YukaColorPalette,
  useTooltip,
} from "yuka";
import { StyledThreeLineText } from "../StyledComponents";
import useElementDimensions from "../../utils/useElementDimensions";
import { useMemo, useRef } from "react";

const StyledArticleImage = styled.div`
  ${(props) =>
    props.$background
      ? css`
          background-image: url(${(props) => props.$background});
          background-size: cover;
          background-position: center;
        `
      : css`
          background-color: ${YukaColorPalette.surface2};
        `}
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -16px;
  width: 100%;
  height: 100px;
`;

const StyledCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
`;

const StyledCard = styled(Card)`
  height: 100%;
`;

const StyledMissingThumbnail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const MissingThumbnailIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${ColorPalette.white10};
  border-radius: 50%;
  width: 40px;
  height: 40px;
`;

const MentionArticleTitle = styled(Fonts.Body2theme80).attrs({ as: "p" })``;

const THREE_LINE_HEIGHT = 72;

const MentionArticleCard = ({ article, onClick }) => {
  const cardRef = useRef(null);
  const [textRef, current] = useElementDimensions();

  const tooltipContent = useMemo(() => {
    if (current?.scrollHeight > THREE_LINE_HEIGHT) {
      return article.title;
    }
    return null;
  }, [article.title, current]);

  const tooltip = useTooltip(cardRef, tooltipContent);

  return (
    <HyperLink
      linkStyle={LinkStyles.UNSTYLED}
      linkType={LinkTypes.EXTERNAL_LINK}
      url={article.original_url}
    >
      <StyledCard
        ref={cardRef}
        cardStyle={CardStyles.MINIMAL}
        onClick={onClick}
      >
        <StyledArticleImage
          $background={
            article.mention_image || article.mention_image_original_url
          }
        >
          {article.mention_image ||
          article.mention_image_original_url ? null : (
            <StyledMissingThumbnail>
              <MissingThumbnailIconContainer>
                <ImageIcon color={ColorPalette.white30} size={16} />
              </MissingThumbnailIconContainer>
              <Fonts.Body1theme30>Thumbnail not available</Fonts.Body1theme30>
            </StyledMissingThumbnail>
          )}
        </StyledArticleImage>
        <StyledCardContent>
          <Fonts.Body1theme50>
            {article.mention_source_name} |{" "}
            {DateTime.fromISO(article.published_at).toRelative()}
          </Fonts.Body1theme50>
          <StyledThreeLineText ref={textRef}>
            <MentionArticleTitle>{article.title}</MentionArticleTitle>
          </StyledThreeLineText>
        </StyledCardContent>
      </StyledCard>
      {tooltip}
    </HyperLink>
  );
};

MentionArticleCard.propTypes = {
  onClick: PropTypes.func,
  article: PropTypes.shape({
    published_at: PropTypes.string,
    original_url: PropTypes.string,
    title: PropTypes.string,
    mention_source_name: PropTypes.string,
    mention_image: PropTypes.string,
    mention_image_original_url: PropTypes.string,
  }).isRequired,
};

MentionArticleCard.defaultProps = {
  onClick: _.noop,
};

export default MentionArticleCard;
