/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``left-indent.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const LeftIndentIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M3.63906 1.60431C3.64606 1.60956 3.65304 1.6148 3.66001 1.62002L6.56001 3.79502C6.56561 3.79922 6.57126 3.80346 6.57696 3.80773C6.67037 3.87774 6.77598 3.9569 6.86067 4.03307C6.95406 4.11707 7.09161 4.25669 7.1735 4.46724C7.27551 4.72953 7.27551 5.02052 7.1735 5.2828C7.09161 5.49335 6.95406 5.63298 6.86067 5.71698C6.77598 5.79315 6.67037 5.8723 6.57696 5.94231C6.57126 5.94659 6.56561 5.95082 6.56001 5.95502L3.66001 8.13002C3.65304 8.13525 3.64605 8.14049 3.63906 8.14574C3.50303 8.2478 3.36304 8.35285 3.23988 8.42879C3.12011 8.50264 2.89274 8.63083 2.60162 8.62478C2.26401 8.61776 1.94741 8.45946 1.73923 8.19359C1.55972 7.96432 1.52585 7.7055 1.51308 7.56539C1.49993 7.42129 1.49997 7.24627 1.5 7.07621C1.50001 7.06746 1.50001 7.05874 1.50001 7.05002V2.70002C1.50001 2.69131 1.50001 2.68259 1.5 2.67384C1.49997 2.50378 1.49993 2.32876 1.51308 2.18466C1.52585 2.04455 1.55972 1.78573 1.73923 1.55646C1.94741 1.29059 2.26401 1.13229 2.60162 1.12527C2.89274 1.11922 3.12012 1.24742 3.23988 1.32126C3.36304 1.3972 3.50304 1.50225 3.63906 1.60431ZM3.00001 3.00002V6.75002L5.50001 4.87502L3.00001 3.00002ZM8.25001 3.00002C8.25001 2.58581 8.58579 2.25002 9.00001 2.25002L15.75 2.25003C16.1642 2.25003 16.5 2.58581 16.5 3.00003C16.5 3.41424 16.1642 3.75003 15.75 3.75003L9.00001 3.75002C8.58579 3.75002 8.25001 3.41424 8.25001 3.00002ZM8.25001 6.93752C8.25001 6.52331 8.58579 6.18752 9.00001 6.18752H15.75C16.1642 6.18752 16.5 6.52331 16.5 6.93752C16.5 7.35174 16.1642 7.68752 15.75 7.68752H9.00001C8.58579 7.68752 8.25001 7.35174 8.25001 6.93752ZM1.50001 11.0625C1.50001 10.6483 1.83579 10.3125 2.25001 10.3125H15.75C16.1642 10.3125 16.5 10.6483 16.5 11.0625C16.5 11.4767 16.1642 11.8125 15.75 11.8125H2.25001C1.83579 11.8125 1.50001 11.4767 1.50001 11.0625ZM1.50001 15C1.50001 14.5858 1.83579 14.25 2.25001 14.25H15.75C16.1642 14.25 16.5 14.5858 16.5 15C16.5 15.4142 16.1642 15.75 15.75 15.75H2.25001C1.83579 15.75 1.50001 15.4142 1.50001 15Z"
        transform=""
      />
    </g>
  </svg>
);

LeftIndentIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

LeftIndentIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

LeftIndentIcon.displayName = "LeftIndentIcon";

export default LeftIndentIcon;
