import _ from "lodash";

const settings = {
  Modal: {
    onClose: props => value => props?.onClose && props.onClose(value),
  },
  Select: {
    matchFn: (search, comparison) => _.includes(comparison, search),
  },
  logError: () => null,
};

const setSetting = (setting, value) => {
  if (_.get(settings, setting)) {
    _.set(settings, setting, value);
  } else {
    throw Error("Invalid setting specified");
  }
};

const getSetting = setting => {
  const value = _.get(settings, setting);
  // Sanity clean to prevent overwrite, etc
  if (_.isArray(value)) {
    return [...value];
  }
  if (_.isFunction(value)) {
    return value;
  }
  if (_.isObject(value)) {
    return { ...value };
  }
  return value;
};

export { getSetting, setSetting };
