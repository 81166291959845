import React, { createContext, useContext } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import { YukaColorPalette } from "../../StylingConstants";

const SurfaceContext = createContext(0);
const ElevationContext = createContext(0);

// Surfaces 0 - 5
const surfaceBackgrounds = [
  YukaColorPalette.surface0,
  YukaColorPalette.surface1,
  YukaColorPalette.surface2,
  YukaColorPalette.surface3,
  YukaColorPalette.surface4,
  YukaColorPalette.surface5,
];

// rgba(255, 255, 255, 0.05) on top of base background
const surfaceHoverBackgrounds = ["#171717", "#262626", "#353535", "#444444", "#535353", "#626262"];
// rgba(255, 255, 255, 0.07) on top of base background
const surfaceActiveBackgrounds = ["#1b1b1b", "#2a2a2a", "#393939", "#484848", "#575757", "#666666"];

// Elevation 1 - 2 + No elevation
const elevationShadows = [
  "none",
  "0px 1px 6px rgba(0, 0, 0, 0.5)",
  "0px 4px 8px rgba(0, 0, 0, 0.25)",
];

const StyledSurface = styled.div`
  background: ${props => surfaceBackgrounds[props.$surface]};
  box-shadow: ${props => elevationShadows[props.$elevation]};
  z-index: ${props => props.$elevation * 10 || "auto"};
  ${props =>
    props.onClick
      ? css`
          cursor: pointer;
          &:hover {
            background: ${props => surfaceHoverBackgrounds[props.$surface]};
          }
          &:active {
            background: ${props => surfaceActiveBackgrounds[props.$surface]};
          }
        `
      : ""}
`;

/**
 * An internal yuka component that manages background of successive layout elements stacked on
 * top of one another
 *
 * To use, have the wrapper element of your component reference this either directly or by
 * wrapping in a styled component
 *
 * See yuka documentation for Layout for how you can make use of this system in your product.
 */
const Surface = React.forwardRef((props, ref) => {
  let currentSurface = useContext(SurfaceContext);
  const currentElevation = useContext(ElevationContext);

  let computedElevation = props.elevated ? Math.min(currentElevation + 1, 2) : 0;

  if (currentSurface >= surfaceBackgrounds.length) {
    // eslint-disable-next-line no-console
    console.error("Too many surfaces stacked on each other");
    currentSurface = surfaceBackgrounds.length - 1;
  }
  if (computedElevation >= elevationShadows.length) {
    // eslint-disable-next-line no-console
    console.error("Too many elevated elements stacked on each other");
    computedElevation = elevationShadows.length - 1;
  }

  const eventProps = _.pick(props, [
    "onClick",
    "onMouseDown",
    "onMouseUp",
    "onMouseEnter",
    "onMouseLeave",
    "tabIndex",
  ]);

  const ariaProps = _.pickBy(props, prop => _.startsWith(prop, "aria-"));

  return (
    <SurfaceContext.Provider value={currentSurface + 1}>
      <ElevationContext.Provider value={props.elevated ? computedElevation : currentElevation}>
        <StyledSurface
          ref={ref}
          style={props.style}
          {...eventProps}
          {...ariaProps}
          role={props.role}
          className={props.className}
          $surface={currentSurface}
          $elevation={computedElevation}
        >
          {props.children}
        </StyledSurface>
      </ElevationContext.Provider>
    </SurfaceContext.Provider>
  );
});

Surface.propTypes = {
  elevated: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  role: PropTypes.string,
};

Surface.defaultProps = {
  elevated: false,
  style: {},
  className: "",
};

export default Surface;
export { SurfaceContext as __internalsSurfaceContext };
