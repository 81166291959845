import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { API_ENDPOINTS } from "../api/constants";
import useWrite from "../api/useWrite";
import { AuthContext } from "../auth";
import { ROUTING_PATH } from "../routes/constants";
import LoadingSpinner from "../utils/LoadingSpinner";

const ChangeEmailConfirm = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const { setUserData } = useContext(AuthContext);

  const confirmEmailChangeQuery = useWrite(
    API_ENDPOINTS.EMAIL_CHANGE_CONFIRM(),
    false,
    {
      silent: false,
      message: "Your email has been updated.",
    }
  );

  useEffect(() => {
    confirmEmailChangeQuery.mutate(
      { token },
      {
        onSettled: () => {
          setUserData();
          navigate(ROUTING_PATH.COMPANIES());
        },
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingSpinner absolute={false} />;
};

export default ChangeEmailConfirm;
