import getPercentFromFraction from "./getPercentFromFraction";

/**
 * Calculate percentage change between current value and previous value.
 * Returns the value as a decimal number.
 * ((V2 - V1) / |V1|) * 100
 * Returns '0' if `prev` is falsy.
 *
 * @param {number} curr - Current value
 * @param {number} prev - Previous value
 * @param {number} decimalPlaces
 * @returns {string} Percent change
 */
export default (curr, prev, decimalPlaces = 2) =>
  getPercentFromFraction(curr - prev, Math.abs(prev), decimalPlaces);
