import _ from "lodash";
import PropTypes from "prop-types";
import { useMemo } from "react";
import styled from "styled-components";
import { Fonts, XCloseIcon } from "yuka";

import { LegendItemContainer, StyledCircle } from "./StyledComponents";
import ToggleableIcon from "./ToggleableIcon";
import { useChartReferencePoint } from "./utils";

import RobustnessScoreLabel from "../../RobustnessScoreLabel";
import {
  percentFormat,
  shortMoneyFormat,
} from "../../utils/displayFormatUtils";

const StyledPriceDisplay = styled.div`
  display: flex;
  gap: 8px;
`;

const ColoredText = styled(Fonts.Body1theme80)`
  color: ${({ color }) => color};
`;

const LegendItem = ({
  onRemove,
  onMouseEnter,
  onMouseLeave,
  isHovered,
  chartElement,
  hoveredDate,
}) => {
  const { name, color, data, exchange } = chartElement;

  /*
   * For the sake of determining hover points, we'll trim off any data points that have a `null`
   * absolute or percent.
   */
  const trimmedData = useMemo(() => {
    return data.filter(
      (point) => !_.isNil(point.absolute) && !_.isNil(point.percent)
    );
  }, [data]);

  const strict = useMemo(() => {
    if (!hoveredDate || trimmedData?.length === 0) {
      return true;
    }
    return (
      hoveredDate < trimmedData[0].time ||
      hoveredDate > trimmedData[trimmedData.length - 1].time
    );
  }, [hoveredDate, trimmedData]);

  const referencePoint = useChartReferencePoint(
    hoveredDate,
    data,
    "absolute",
    strict
  );

  const formattedValue = useMemo(() => {
    if (!data.length) return "";

    if (!referencePoint) {
      return (
        <StyledPriceDisplay>
          <span>--</span>
        </StyledPriceDisplay>
      );
    }

    const changeAbsolute = (referencePoint.absolute - data[0].absolute).toFixed(
      2
    );
    const changePercent = referencePoint.percent.toFixed(2);
    return (
      <StyledPriceDisplay>
        <span>{shortMoneyFormat(referencePoint.absolute)}</span>
        <span>
          {changeAbsolute >= 0 ? "+" : "-"}
          {shortMoneyFormat(Math.abs(changeAbsolute))}
        </span>
        <span>
          ({changePercent > 0 ? "+" : ""}
          {percentFormat(changePercent)})
        </span>
      </StyledPriceDisplay>
    );
  }, [referencePoint, data]);

  const formattedRobustnessScore = useMemo(() => {
    if (referencePoint?.robustness) {
      return <RobustnessScoreLabel score={referencePoint.robustness} />;
    }
    return null;
  }, [referencePoint]);

  const formattedName = useMemo(() => {
    return (
      <Fonts.Body1theme80>
        {name}
        {exchange && (
          <>
            <Fonts.Body1theme30> | </Fonts.Body1theme30>
            {exchange}
          </>
        )}
      </Fonts.Body1theme80>
    );
  }, [name, exchange]);

  return (
    <LegendItemContainer
      $isHovered={isHovered}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <StyledCircle color={color} />
      {formattedName}
      {formattedRobustnessScore}
      <ColoredText color={color}>{formattedValue}</ColoredText>
      {onRemove ? (
        <ToggleableIcon icon={XCloseIcon} onClick={onRemove} />
      ) : null}
    </LegendItemContainer>
  );
};

LegendItem.propTypes = {
  isHovered: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onRemove: PropTypes.func,
  chartElement: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
    exchange: PropTypes.string,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        time: PropTypes.string,
        absolute: PropTypes.number,
      })
    ),
  }).isRequired,
  hoveredDate: PropTypes.string,
};

LegendItem.defaultProps = {
  onMouseEnter: null,
  onMouseLeave: null,
  onRemove: null,
};

export default LegendItem;
