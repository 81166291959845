/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``function.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const FunctionIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M11.4374 4.58036H9.47662L8.80751 6.64286H10.5859C11.0247 6.64286 11.4394 6.84932 11.7113 7.2032L12.8805 8.72495L14.8745 6.87132C15.2268 6.54379 15.7708 6.57174 16.0896 6.93374C16.4083 7.29575 16.3811 7.85472 16.0288 8.18225L13.9491 10.1156L15.8405 12.5773C16.1349 12.9604 16.0712 13.5163 15.6983 13.8188C15.3254 14.1213 14.7844 14.0559 14.49 13.6727L12.6703 11.3044L10.0068 13.7805C9.65449 14.108 9.11046 14.08 8.7917 13.718C8.47293 13.356 8.50013 12.7971 8.85245 12.4695L11.6018 9.9137L10.4469 8.41071H8.23398L6.36227 14.1802C6.16711 14.7817 5.6192 15.1875 5.00204 15.1875H2.54779C2.07266 15.1875 1.6875 14.7918 1.6875 14.3036C1.6875 13.8154 2.07266 13.4196 2.54779 13.4196H4.79535L6.42034 8.41071H3.69476C3.21964 8.41071 2.83447 8.01497 2.83447 7.52679C2.83447 7.03861 3.21964 6.64286 3.69476 6.64286H6.99386L7.9097 3.81984C8.10486 3.21826 8.65278 2.8125 9.26994 2.8125H11.4374C11.9125 2.8125 12.2977 3.20825 12.2977 3.69643C12.2977 4.18461 11.9125 4.58036 11.4374 4.58036Z"
        transform=""
      />
    </g>
  </svg>
);

FunctionIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

FunctionIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

FunctionIcon.displayName = "FunctionIcon";

export default FunctionIcon;
