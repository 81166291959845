/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``slash.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const SlashIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M13.0854 0.829176C13.4559 1.01442 13.606 1.46492 13.4208 1.83541L5.9208 16.8354C5.73556 17.2059 5.28506 17.3561 4.91457 17.1708C4.54409 16.9856 4.39392 16.5351 4.57916 16.1646L12.0792 1.16459C12.2644 0.794102 12.7149 0.643934 13.0854 0.829176Z"
        transform=""
      />
    </g>
  </svg>
);

SlashIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

SlashIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

SlashIcon.displayName = "SlashIcon";

export default SlashIcon;
