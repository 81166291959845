import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Card, ColorPalette, YukaColorPalette, YukaThemeProvider } from "yuka";

const StyledCard = styled(Card)`
  border: 1px solid ${ColorPalette.white30};

  &:hover {
    ${(props) =>
      props.onClick &&
      css`
        background-color: ${YukaColorPalette.surface1};
      `}
  }

  &:active {
    ${(props) =>
      props.onClick &&
      css`
        border: 1px solid ${ColorPalette.white50};
        background-color: ${YukaColorPalette.surface1};
      `}
  }
`;

const SurfaceZeroCard = ({ children, ...props }) => {
  return (
    <YukaThemeProvider theme={{ surfaceLevel: -1 }}>
      <StyledCard {...props}>
        <YukaThemeProvider theme={{ surfaceLevel: 1 }}>
          {children}
        </YukaThemeProvider>
      </StyledCard>
    </YukaThemeProvider>
  );
};

SurfaceZeroCard.propTypes = {
  children: PropTypes.node,
};

export default SurfaceZeroCard;
