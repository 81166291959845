import { useContext } from "react";

import { API_ENDPOINTS } from "../api/constants";
import useFetch from "../api/useFetch";
import { AuthContext } from "../auth";

const useHasFeatureAccess = (featureName) => {
  const { user } = useContext(AuthContext);
  const hasFeatureAccessQuery = useFetch(
    API_ENDPOINTS.HAS_FEATURE_ACCESS(),
    {},
    {
      enabled: Boolean(user),
    }
  );
  const hasFeatureAccessQueryInfo = hasFeatureAccessQuery?.cleanedData;
  const featureAccessLoading = hasFeatureAccessQuery?.isLoading;

  return [
    hasFeatureAccessQueryInfo && hasFeatureAccessQueryInfo[featureName],
    featureAccessLoading,
  ];
};

export default useHasFeatureAccess;
