import styled from "styled-components";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";

import { body1, FontColors } from "../Typography";

import useTooltip from "../Tooltip/useTooltip";

const LegendContainer = styled.div`
  display: flex;
  gap: 16px;
  padding: 0 16px;
  box-sizing: border-box;
  justify-content: space-between;
`;

const LegendAlignmentSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${props =>
    props.$align && props.$align === "right" ? "flex-end" : "flex-start"};
  gap: 16px;
`;

const LegendItemContainer = styled.div`
  display: flex;
  flex-direction: ${props => (props.$reverse ? "row-reverse" : "row")};
  gap: 12px;
  align-items: center;
  ${body1}
  ${FontColors.theme80}
`;

const LegendColor = styled.div`
  height: 14px;
  width: 14px;
  background: ${props => props.$color};
  border-radius: 4px;
`;

const LegendItem = ({ text, color, tooltip, align }) => {
  const legendRef = React.useRef(null);
  const tooltipElement = useTooltip(legendRef, tooltip);

  return (
    <LegendItemContainer $reverse={align === "right"} ref={legendRef}>
      {tooltipElement}
      {color && <LegendColor $color={color} />}
      <div>{text}</div>
    </LegendItemContainer>
  );
};

LegendItem.propTypes = {
  color: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  tooltip: PropTypes.string,
  align: PropTypes.oneOf(["left", "right"]),
};

const GraphLegend = props => (
  <LegendContainer>
    <LegendAlignmentSection $align="left">
      {_.map(
        _.filter(props.legendData, item => item.align !== "right"),
        (legendItem, index) => (
          <LegendItem key={`legend_item_${legendItem.text}_${index}`} {...legendItem} />
        )
      )}
    </LegendAlignmentSection>
    <LegendAlignmentSection $align="right">
      {_.map(
        _.filter(props.legendData, item => item.align === "right"),
        (legendItem, index) => (
          <LegendItem key={`legend_item_${legendItem.text}_${index}`} {...legendItem} />
        )
      )}
    </LegendAlignmentSection>
  </LegendContainer>
);

GraphLegend.propTypes = {
  legendData: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      tooltip: PropTypes.string,
      align: PropTypes.oneOf(["left", "right"]),
    })
  ).isRequired,
};

export default GraphLegend;
