import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import ListItem, { ListStyles } from "./ListItem";

import { LinkTypes } from "../Button/getLinkProps";
import { ChevronRightIcon } from "../Icons";
import { YukaColorPalette, PrimaryColorPalette, Z_INDEXES } from "../StylingConstants";
import { FontColors } from "../Typography";

import useWindowDimensions from "../utils/useWindowDimensions";
import useElementDimensions from "../utils/useElementDimensions";

// Minimum positioning from edge of screen if we can control it
const THRESHOLD = 16;

const DisabledText = styled.span`
  ${FontColors.theme30}
`;

const Submenu = styled.div`
  position: absolute;
  top: -12px;
  z-index: ${Z_INDEXES.zIndexVisible};
  ${props => props.$align}: 100%;
`;

const StyledMenuListItem = styled(ListItem)`
  position: relative;
  min-height: 32px;
  padding: 0 12px;

  ${Submenu} {
    display: none;
  }

  ${props =>
    props.onClick
      ? css`
          ${props.$selected
            ? `
            background: ${YukaColorPalette.hoverTransparent};
          `
            : ""}
          &:hover ${Submenu} {
            display: initial;
          }
        `
      : ""}
`;

/**
 * A specialized list item used in Menus
 */
const MenuItem = props => {
  const defaultColor = props.leadingIconColor || PrimaryColorPalette.white50;
  const iconColor = props.disabled ? PrimaryColorPalette.white15 : defaultColor;

  const hasSubmenu = Boolean(props.submenu);

  const ref = React.useRef(null);
  const { width: windowWidth } = useWindowDimensions();
  const { left, right, width } = useElementDimensions(ref);

  let trailingContent = props.trailingContent;
  if (hasSubmenu) {
    const fitsToRight = right + width + THRESHOLD < windowWidth;
    const fitsToLeft = left - width > THRESHOLD;
    // NOTE: align = css value set, opposite of actual alignment relative to item.
    // align = "right" -> submenu to left of main menu
    const align = !fitsToRight && fitsToLeft ? "right" : "left";

    // Submenu included here to be a child of the list item. Otherwise unimportant that it's here
    trailingContent = (
      <>
        <ChevronRightIcon color={iconColor} size={16} />
        <Submenu $align={align}>{props.submenu}</Submenu>
      </>
    );
  }

  const text = props.disabled ? <DisabledText>{props.text}</DisabledText> : props.text;

  return (
    <StyledMenuListItem
      tabIndex="-1"
      role={props.role}
      ref={ref}
      url={props.url}
      linkType={props.linkType}
      leadingIcon={props.leadingIcon}
      leadingIconColor={iconColor}
      avatar={props.thumbnail}
      listStyle={ListStyles.SINGLE_LINE}
      text={text}
      trailingContent={trailingContent}
      onClick={props.disabled ? null : props.onClick}
      $selected={props.selected}
    />
  );
};

MenuItem.propTypes = {
  text: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  thumbnail: PropTypes.element,
  leadingIcon: PropTypes.func,
  leadingIconColor: PropTypes.oneOf(Object.values(PrimaryColorPalette)),
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  submenu: PropTypes.element,
  url: PropTypes.string,
  linkType: PropTypes.oneOf(Object.values(LinkTypes)),
  trailingContent: PropTypes.node,
  role: PropTypes.string,
};

MenuItem.defaultProps = {
  role: "menuitem",
  disabled: false,
  selected: false,
  submenu: null,
};

export default MenuItem;
