import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import styled from "styled-components";

import ProgressBar from "./internal/ProgressBar";
import { PrimaryColorPalette } from "../StylingConstants";
import { caption2, FontColors } from "../Typography";

import Input from "./Input";

const PasswordStrengthIndicator = styled.div`
  width: 100%;
  .progress-bar {
    background: ${PrimaryColorPalette.white15};
    height: 8px;
    margin: 8px 0;
    width: 100%;
  }
  .progress-bar__completion {
    height: 8px;
  }
`;

const StyledStrengthText = styled.p`
  ${caption2}
  ${FontColors.theme50}
  margin-bottom: 0;
`;

const StyledStrengthScore = styled.span`
  ${FontColors.theme80}
`;

const StyledSuggestionList = styled.ul`
  ${caption2}
  margin: 0;
  list-style: outside;
  padding-left: 16px;
`;

let checker = null;
const MAX_SCORE = 4;

const DEFAULT_STRENGTH_INFO = {
  score: 0,
  feedback: {
    warning: "",
    suggestions: [],
  },
};

const STRENGTH_TO_FILL_MAPPING = [
  PrimaryColorPalette.sell,
  PrimaryColorPalette.sell,
  PrimaryColorPalette.alert,
  PrimaryColorPalette.buy,
  PrimaryColorPalette.buy,
];

const STRENGTH_TO_READABLE_MAPPING = ["Very Weak", "Weak", "Fair", "Good", "Strong"];

const getStyle = strengthInfo => ({
  background: STRENGTH_TO_FILL_MAPPING[strengthInfo.score],
});

/**
 * A password input field combined with a password strength indicator provided by zxcvbn.
 *
 * According to our Password Management Policy, we do not require any additional password
 * complexity requirements other than a minimum length of 8 characters. As a compensating
 * measure, we are required to present a password strength indicator for context for the user.
 */
const PasswordInput = React.forwardRef((props, ref) => {
  const [checkFn, setCheckFn] = React.useState(() => checker);
  const [strengthInfo, setStrengthInfo] = React.useState(DEFAULT_STRENGTH_INFO);

  React.useEffect(() => {
    if (props.displayIndicator && !checkFn) {
      // zxcvbn is a very large library due to storing a dictionary of terms. Lazy load like the
      // library recommends. `checker` is a convenience to store it so we don't need to re-fetch later
      import("zxcvbn").then(lib => {
        checker = lib.default;
        setCheckFn(() => lib.default);
      });
    }
  });

  const suggestions = _.get(strengthInfo, "feedback.suggestions", []);

  const { onChange: propsOnChange } = props;

  const onChange = React.useCallback(
    e => {
      propsOnChange?.(e);
      if (checkFn && e.target.value) {
        setStrengthInfo(checkFn(e.target.value));
      } else {
        setStrengthInfo(DEFAULT_STRENGTH_INFO);
      }
    },
    [checkFn, propsOnChange]
  );

  // set password as the type given in `props.input`
  return (
    <>
      <Input ref={ref} {...props} onChange={onChange} />
      {props.displayIndicator && (
        <PasswordStrengthIndicator>
          <ProgressBar
            total={MAX_SCORE}
            progress={strengthInfo.score}
            style={getStyle(strengthInfo)}
          />
          <div>
            <StyledStrengthText>
              Password strength:&nbsp;
              <StyledStrengthScore>
                {STRENGTH_TO_READABLE_MAPPING[strengthInfo.score]}
              </StyledStrengthScore>
            </StyledStrengthText>
            {!_.isEmpty(suggestions) && (
              <>
                <StyledStrengthText>Suggestion:</StyledStrengthText>
                <StyledSuggestionList>
                  {_.map(suggestions, suggestion => (
                    <li key={suggestion}>{suggestion}</li>
                  ))}
                </StyledSuggestionList>
              </>
            )}
          </div>
        </PasswordStrengthIndicator>
      )}
    </>
  );
});

PasswordInput.propTypes = {
  displayIndicator: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

PasswordInput.defaultProps = {
  displayIndicator: true,
};

export default PasswordInput;
