/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``bar-line-chart.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const BarLineChartIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M2.25 1.5C2.66421 1.5 3 1.83579 3 2.25V13.35C3 13.7824 3.00058 14.0616 3.01795 14.2741C3.03459 14.4779 3.06285 14.5534 3.08175 14.5905C3.15365 14.7316 3.26839 14.8463 3.40951 14.9183C3.44659 14.9371 3.52213 14.9654 3.72588 14.9821C3.93838 14.9994 4.21759 15 4.65 15H15.75C16.1642 15 16.5 15.3358 16.5 15.75C16.5 16.1642 16.1642 16.5 15.75 16.5H4.62108C4.22572 16.5 3.88466 16.5 3.60373 16.4771C3.30722 16.4528 3.01231 16.3994 2.72852 16.2548C2.30516 16.039 1.96095 15.6948 1.74524 15.2715C1.60064 14.9877 1.54715 14.6928 1.52293 14.3963C1.49998 14.1153 1.49999 13.7743 1.5 13.3789L1.5 2.25C1.5 1.83579 1.83579 1.5 2.25 1.5ZM15.375 3.375C15.7892 3.375 16.125 3.71079 16.125 4.125V13.125C16.125 13.5392 15.7892 13.875 15.375 13.875C14.9608 13.875 14.625 13.5392 14.625 13.125V4.125C14.625 3.71079 14.9608 3.375 15.375 3.375ZM12 5.625C12.4142 5.625 12.75 5.96079 12.75 6.375V13.125C12.75 13.5392 12.4142 13.875 12 13.875C11.5858 13.875 11.25 13.5392 11.25 13.125V6.375C11.25 5.96079 11.5858 5.625 12 5.625ZM8.625 7.875C9.03922 7.875 9.375 8.21079 9.375 8.625V13.125C9.375 13.5392 9.03922 13.875 8.625 13.875C8.21079 13.875 7.875 13.5392 7.875 13.125V8.625C7.875 8.21079 8.21079 7.875 8.625 7.875ZM5.25 10.125C5.66422 10.125 6 10.4608 6 10.875V13.125C6 13.5392 5.66422 13.875 5.25 13.875C4.83579 13.875 4.5 13.5392 4.5 13.125V10.875C4.5 10.4608 4.83579 10.125 5.25 10.125Z"
        transform=""
      />
    </g>
  </svg>
);

BarLineChartIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

BarLineChartIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

BarLineChartIcon.displayName = "BarLineChartIcon";

export default BarLineChartIcon;
