/* eslint-disable no-console, no-undef */
import React from "react";

import AxiosInstance from "../../api/AxiosInstance";
import { API_ENDPOINTS } from "../../api/constants";

import { properties, peopleProperties } from "./mixpanelHelpers";
import { AuthContext } from "../../auth";

const MixpanelAPI = {
  setPeople: (browserProperties) =>
    AxiosInstance.post(API_ENDPOINTS.MIXPANEL_SET_PEOPLE(), browserProperties),
  track: (event, data) =>
    AxiosInstance.post(API_ENDPOINTS.MIXPANEL_TRACK(), { event, ...data }),
};

const mpTrack = (eventName, data) => {
  const trackData = data || {};

  // dev logger to easily see what Mixpanel events we're triggering
  if (
    process.env.NODE_ENV !== "production" &&
    process.env.NODE_ENV !== "test" &&
    process.env.ENABLE_MIXPANEL_LOGGER === "on"
  ) {
    console.groupCollapsed(
      `%cMixpanel: ${eventName} @ ${new Date().toLocaleTimeString()}`,
      "background: #4f44e0; color: #e9e9e9"
    );
    console.log(trackData);
    console.groupEnd();
  }

  MixpanelAPI.track(eventName, { ...properties(), ...trackData });
};

const MixpanelProvider = ({ children }) => {
  const [mixpanelLoaded, setMixpanelLoaded] = React.useState(false);
  const { user } = React.useContext(AuthContext);

  if (user && !mixpanelLoaded) {
    MixpanelAPI.setPeople(peopleProperties());
    setMixpanelLoaded(true);
  }

  return children;
};

export { MixpanelProvider, mpTrack };
