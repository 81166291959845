/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``pencil.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const PencilIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M12.9697 0.96967C13.2626 0.676777 13.7374 0.676777 14.0303 0.96967L17.0303 3.96967C17.3232 4.26256 17.3232 4.73744 17.0303 5.03033C16.7374 5.32322 16.2626 5.32322 15.9697 5.03033L12.9697 2.03033C12.6768 1.73744 12.6768 1.26256 12.9697 0.96967ZM10.9024 3.21039C11.1283 3.13698 11.3717 3.13698 11.5976 3.21039C11.7721 3.26708 11.8986 3.36124 11.9819 3.43196C12.0538 3.49299 12.1292 3.56846 12.1923 3.63165C12.1965 3.6358 12.2006 3.6399 12.2046 3.64393L14.3684 5.8077C14.4316 5.87084 14.507 5.94624 14.568 6.01813C14.6388 6.10143 14.7329 6.22788 14.7896 6.40236C14.863 6.6283 14.863 6.8717 14.7896 7.09764C14.7329 7.27212 14.6388 7.39857 14.568 7.48187C14.507 7.55377 14.4315 7.62919 14.3683 7.69234L6.60601 15.4547C6.5965 15.4642 6.58707 15.4736 6.57772 15.483C6.4377 15.6232 6.31414 15.7469 6.17096 15.8512C6.04498 15.943 5.90979 16.0214 5.76758 16.0853C5.60597 16.1578 5.43725 16.2037 5.24608 16.2558C5.2333 16.2592 5.22043 16.2627 5.20744 16.2663L1.69734 17.2236C1.43768 17.2944 1.15998 17.2206 0.969672 17.0303C0.779359 16.84 0.705613 16.5623 0.776429 16.3027L1.73373 12.7926C1.73727 12.7796 1.74077 12.7667 1.74425 12.7539C1.79626 12.5627 1.84217 12.394 1.91472 12.2324C1.97857 12.0902 2.05702 11.955 2.14882 11.829C2.25313 11.6859 2.37684 11.5623 2.51703 11.4223C2.52639 11.4129 2.53584 11.4035 2.54535 11.394L10.2954 3.64393C10.2994 3.6399 10.3035 3.63581 10.3077 3.63167C10.3708 3.56847 10.4462 3.493 10.5181 3.43196C10.6014 3.36124 10.7279 3.26708 10.9024 3.21039ZM11.25 4.81066L3.60601 12.4547C3.42221 12.6385 3.38773 12.6759 3.36116 12.7123C3.33056 12.7543 3.30441 12.7994 3.28313 12.8468C3.26466 12.8879 3.24927 12.9365 3.18088 13.1872L2.56892 15.4311L4.81277 14.8191C5.06355 14.7507 5.11205 14.7353 5.1532 14.7169C5.2006 14.6956 5.24566 14.6694 5.28766 14.6388C5.32412 14.6123 5.36155 14.5778 5.54535 14.394L13.1893 6.75L11.25 4.81066Z"
        transform=""
      />
    </g>
  </svg>
);

PencilIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

PencilIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

PencilIcon.displayName = "PencilIcon";

export default PencilIcon;
