/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``shield-off.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const ShieldOffIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule=""
        d="M9.00001 17.175C8.88751 17.175 8.78251 17.1675 8.68501 17.145C8.45251 17.0925 8.28751 17.0025 8.10001 16.89C6.34501 15.8625 2.25001 13.05 2.25001 9.03V4.3575C2.25001 4.0725 2.33251 3.795 2.46751 3.555L1.71751 2.805C1.42501 2.5125 1.42501 2.04 1.71751 1.7475C2.01001 1.455 2.48251 1.455 2.77501 1.7475L16.2825 15.2475C16.575 15.54 16.575 16.0125 16.2825 16.305C15.99 16.5975 15.5175 16.5975 15.225 16.305L13.245 14.325C12.03 15.5625 10.6725 16.44 9.90751 16.8825C9.72001 16.995 9.55501 17.0925 9.31501 17.1375C9.21751 17.16 9.11251 17.1675 9.00001 17.1675V17.175ZM3.75001 4.8375V9.03C3.75001 12.36 7.65751 14.895 8.85751 15.5925C8.92501 15.63 8.97001 15.66 9.01501 15.6825C9.03001 15.6675 9.07501 15.6375 9.14251 15.5925C9.84001 15.1875 11.085 14.385 12.18 13.2675L3.75001 4.8375ZM14.76 11.3325C14.685 11.3325 14.61 11.325 14.5425 11.3025C14.145 11.1825 13.9275 10.7625 14.0475 10.365C14.1825 9.9225 14.25 9.4725 14.25 9.03V5.4375C14.25 4.965 14.25 4.6725 14.1975 4.545C14.1525 4.4325 14.085 4.3275 13.995 4.245C13.89 4.155 13.6275 4.0575 13.185 3.8925L9.15751 2.385C9.07501 2.355 9.03001 2.3325 8.98501 2.325C8.97001 2.3325 8.92501 2.355 8.84251 2.385L6.87001 3.1275C6.48001 3.27 6.05251 3.075 5.90251 2.6925C5.75251 2.31 5.95501 1.875 6.33751 1.725L8.31001 0.982499C8.49751 0.914999 8.61751 0.869999 8.77501 0.847499C8.92501 0.824999 9.06751 0.824999 9.20251 0.847499C9.36001 0.869999 9.48751 0.914999 9.66001 0.982499L13.695 2.4975C14.325 2.7375 14.6625 2.865 14.9775 3.135C15.255 3.375 15.465 3.6825 15.5925 4.0275C15.7425 4.41 15.7425 4.7775 15.7425 5.4525V9.0375C15.7425 9.63 15.6525 10.2225 15.4725 10.815C15.375 11.1375 15.075 11.3475 14.7525 11.3475L14.76 11.3325Z"
        transform=""
      />
    </g>
  </svg>
);

ShieldOffIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

ShieldOffIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

ShieldOffIcon.displayName = "ShieldOffIcon";

export default ShieldOffIcon;
