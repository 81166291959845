import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Surface from "./internal/Surface";

import { FontColors, caption1 } from "../Typography";
import { PrimaryColorPalette } from "../StylingConstants";
import { YukaThemeProvider } from "../ThemeContext";

const StyledSurface = styled(Surface)`
  border-radius: 12px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  width: 256px;
  flex-shrink: 0;
  height: fit-content;
`;

const StyledFooter = styled.div`
  ${caption1}
  ${FontColors.theme30}
  border-top: 1px solid ${PrimaryColorPalette.white15};
  margin: 24px 12px 0;
  padding: 24px 0 0;
`;

/**
 * A floating navigation should be used within a specific page to navigate between different
 * sections within it.
 *
 * In most cases, it should be fixed on the layout so a user can always see it.
 */
const FloatingNavigation = props => (
  <YukaThemeProvider theme={{ componentVersion: 2 }}>
    <StyledSurface role="menubar" aria-orientation="vertical">
      {props.children}
      {props.footer && <StyledFooter>{props.footer}</StyledFooter>}
    </StyledSurface>
  </YukaThemeProvider>
);

FloatingNavigation.propTypes = {
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
};

export default FloatingNavigation;
