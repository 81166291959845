/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``dotpoints.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const DotpointsIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M1.5 4.5C1.5 3.67157 2.17157 3 3 3C3.82843 3 4.5 3.67157 4.5 4.5C4.5 5.32843 3.82843 6 3 6C2.17157 6 1.5 5.32843 1.5 4.5ZM6 4.5C6 4.08579 6.33579 3.75 6.75 3.75H15.75C16.1642 3.75 16.5 4.08579 16.5 4.5C16.5 4.91421 16.1642 5.25 15.75 5.25L6.75 5.25C6.33579 5.25 6 4.91421 6 4.5ZM1.5 9C1.5 8.17157 2.17157 7.5 3 7.5C3.82843 7.5 4.5 8.17157 4.5 9C4.5 9.82843 3.82843 10.5 3 10.5C2.17157 10.5 1.5 9.82843 1.5 9ZM6 9C6 8.58579 6.33579 8.25 6.75 8.25L15.75 8.25C16.1642 8.25 16.5 8.58579 16.5 9C16.5 9.41421 16.1642 9.75 15.75 9.75L6.75 9.75C6.33579 9.75 6 9.41421 6 9ZM1.5 13.5C1.5 12.6716 2.17157 12 3 12C3.82843 12 4.5 12.6716 4.5 13.5C4.5 14.3284 3.82843 15 3 15C2.17157 15 1.5 14.3284 1.5 13.5ZM6 13.5C6 13.0858 6.33579 12.75 6.75 12.75L15.75 12.75C16.1642 12.75 16.5 13.0858 16.5 13.5C16.5 13.9142 16.1642 14.25 15.75 14.25L6.75 14.25C6.33579 14.25 6 13.9142 6 13.5Z"
        transform=""
      />
    </g>
  </svg>
);

DotpointsIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

DotpointsIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

DotpointsIcon.displayName = "DotpointsIcon";

export default DotpointsIcon;
