import PropTypes from "prop-types";

const IconCell = ({ value, ...props }) => {
  if (value) {
    return value;
  }
  return props.column.getIcon?.({ value, ...props });
};

IconCell.propTypes = {
  value: PropTypes.node,
  column: PropTypes.shape({
    getIcon: PropTypes.func,
  }).isRequired,
};

IconCell.displayName = "IconCell";

export default IconCell;
