import React from "react";

import useDynamicPosition from "../utils/useDynamicPosition";

import useMenu from "./internal/useMenu";

const THRESHOLD = 16;
const OVERHANG = 0;
const SPACING = 0;

/**
 * A hook that integrates the Menu element(s) into an external interface that can be used easily.
 *
 * This hook is intended to be used to anchor a menu relative to an element
 *
 * @param menuItems - An array of objects in the following shape:
 * {
 *   text: string,
 *   icon: func (Icon component),
 *   submenu: menuItems,
 *   disabled: bool,
 * }
 * Optionally, this can be an array of arrays, in which case each array is considered a distinct
 * section. In order to support this, specify the sectioned parameter.
 *
 * @param searchable - a boolean for whether this is searchable. Will error if using multiple
 *   sections in the menu.
 * @param sectioned - a boolean for whether menu items reflect multiple sections. Will error if
 *   enabling search as well
 */
const useGlobalMenu = menuConfig => {
  const elementRef = React.useRef(null);

  const [location, setLocation] = React.useState({});

  // Separating this from the lifecycle, combined with location being stored in state means that
  // the menu position is lagged by 1 render
  const onPositionElement = React.useCallback(event => {
    setLocation({ left: event.pageX, top: event.pageY, bottom: event.pageY });
  });

  // We need the position of the event to use as the focus.
  const fakedContainerRef = React.useMemo(
    () => ({
      current: {
        getBoundingClientRect: () => ({ ...location, width: 0, height: 0 }),
      },
    }),
    [location]
  );

  const [style, calculatePosition] = useDynamicPosition({
    containerRef: fakedContainerRef,
    elementRef,
    THRESHOLD,
    OVERHANG,
    SPACING,
    defaultAbove: false,
  });

  // Really want this to be immediately after the setLocation call completes. This serves that
  // purpose
  React.useEffect(() => {
    calculatePosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const menu = useMenu({ ...menuConfig, elementRef }, style);

  return [menu, onPositionElement];
};

export default useGlobalMenu;
