import _ from "lodash";
import queryString from "query-string";
import { useCallback, useMemo } from "react";
import { useQuery } from "react-query";

import AxiosInstance from "./AxiosInstance";
import { cleanJsonApiData, defaultQueryOptions } from "./utils";

/**
 * Wrapper around `useQuery`
 * https://tanstack.com/query/v4/docs/reference/useQuery?from=reactQueryV3&original=https://react-query-v3.tanstack.com/reference/useQuery
 *
 * @param {string} url
 * @param {object} queryParams
 * @param {object} options
 * @returns {Array} see useQuery documentation for return values. Also adds `cleanedData` to the returnInfo.
 */
const useFetch = (url, queryParams = {}, options = {}) => {
  const qs = _.isEmpty(queryParams)
    ? ""
    : `?${queryString.stringify(queryParams)}`;
  const apiUrl = `${url}${qs}`;

  const queryFn = useCallback(
    () =>
      AxiosInstance.get(apiUrl)
        .then((response) => response.data)
        .catch((error) => Promise.reject(error.response)),
    [apiUrl]
  );

  const queryOptions = { ...options, ...defaultQueryOptions };

  const queryInfo = useQuery([url, queryParams], queryFn, queryOptions);

  const cleanedData = useMemo(() => {
    // JSON API data
    if (queryInfo.data?.data) {
      return cleanJsonApiData(queryInfo.data);
    }
    // HD API data
    return queryInfo.data;
  }, [queryInfo.data]);

  return useMemo(
    () => ({
      ...queryInfo,
      cleanedData,
    }),
    [queryInfo, cleanedData]
  );
};

export default useFetch;
