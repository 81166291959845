import _ from "lodash";
import React, { useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import { PageSection, Fonts, HyperLink, LinkStyles, FontColors } from "yuka";

import { DATAVERSE_ENJOYERS } from "./constants";
import ProfileInfo from "./ProfileInfo";

import {
  HOME_PAGE_FEATURE_NAME,
  PORTFOLIOS_FEATURE_NAME,
} from "../api/constants";
import { AuthContext } from "../auth";

import CompanySearch from "../global-header/CompanySearch";
import { ROUTING_PATH } from "../routes/constants";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";
import { SiteLogoSmall, SiteLogoDataverse } from "../utils/svgs/SiteLogo";
import useHasFeatureAccess from "../utils/useHasFeatureAccess";

const StyledGlobalNavigationContainer = styled(PageSection)`
  width: 100%;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  box-sizing: border-box;
  padding: 16px 24px;
  overflow: hidden;
  z-index: 4;

  // Support the right-aligned profile dropdown
  justify-content: space-between;
`;

const GlobalNavigation = styled.div`
  flex-grow: 1;
  gap: 24px;
  margin-right: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;

  > :first-child {
    flex-shrink: 0;
  }
`;

const NavigationLinks = styled(Fonts.Body1theme50).attrs({ as: "div" })`
  margin-left: 24px;
  display: flex;
  flex-shrink: 0;
  gap: 32px;
`;

const StyledNavigationLink = styled(HyperLink)`
  &:hover {
    ${FontColors.theme80}
  }

  ${(props) =>
    props.$selected &&
    css`
      ${FontColors.theme80}
    `}
`;

const removeTrailingSlashes = (path) => path.replace(/\/+$/, "");

/**
 * Global navigation bar
 *
 * @returns {React.Element}
 */
const GlobalNav = () => {
  const { user } = useContext(AuthContext);
  const location = useLocation();

  const [homePageFeatureAccess, homePageAcessLoading] = useHasFeatureAccess(
    HOME_PAGE_FEATURE_NAME
  );
  const [portfoliosFeatureAccess, portfolioAccessLoading] = useHasFeatureAccess(
    PORTFOLIOS_FEATURE_NAME
  );

  const navLinks = useMemo(() => {
    // Determine the available navigation links based on feature permissions.
    const navLinks = [];
    if (homePageAcessLoading || portfolioAccessLoading) {
      return navLinks;
    }
    if (homePageFeatureAccess) {
      navLinks.push({
        url: ROUTING_PATH.HOME(),
        name: "Home",
      });
    }

    navLinks.push({
      url: ROUTING_PATH.COMPANIES(),
      id: "companies-global-nav-link",
      strictMatch: true,
      name: "Companies",
    });

    if (portfoliosFeatureAccess) {
      navLinks.push({
        url: ROUTING_PATH.PORTFOLIOS(),
        id: "portfolios-global-nav-link",
        name: "Portfolios",
      });
    }

    if (!portfoliosFeatureAccess) {
      // These two feature flags constitute the dataverse updates. Not passing 1 of them
      // leaves the old navigation items in place.
      navLinks.push({
        url: ROUTING_PATH.MARKET_ACTIVITY(),
        name: "Market Activity",
      });
      navLinks.push({
        url: ROUTING_PATH.INSIGHTS(),
        name: "Insights",
      });
    }
    return navLinks;
  }, [
    homePageAcessLoading,
    portfolioAccessLoading,
    homePageFeatureAccess,
    portfoliosFeatureAccess,
  ]);

  const siteLogo = DATAVERSE_ENJOYERS.includes(user?.email) ? (
    <SiteLogoDataverse />
  ) : (
    <SiteLogoSmall />
  );

  return (
    <StyledGlobalNavigationContainer>
      <GlobalNavigation>
        <HyperLink linkStyle={LinkStyles.UNSTYLED} url={ROUTING_PATH.HOME()}>
          {siteLogo}
        </HyperLink>
        <CompanySearch />
        <NavigationLinks>
          {navLinks.map((link) => (
            <StyledNavigationLink
              id={link.id}
              key={`nav-link-${link.url}`}
              $selected={
                link.strictMatch
                  ? removeTrailingSlashes(location.pathname) ===
                    removeTrailingSlashes(link.url)
                  : _.includes(location.pathname, link.url)
              }
              linkStyle={LinkStyles.UNSTYLED}
              url={link.url}
              onClick={() =>
                MixpanelEvents.clickGlobalNavigationPage(link.name)
              }
            >
              {link.name}
            </StyledNavigationLink>
          ))}
        </NavigationLinks>
      </GlobalNavigation>
      <ProfileInfo />
    </StyledGlobalNavigationContainer>
  );
};

export default GlobalNav;
