import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { TableColors } from "../StylingConstants";

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 48px;
  padding: 16px 0;
  color: ${TableColors.rowText};
  left: 0;
  position: sticky;
  ${props => (props.$width ? `width: ${props.$width}px;` : "")};
`;

/**
 * To be used by Table. Rendered when a table has no data and is not loading.
 * @param content - content to be rendered in the table.
 * @param width - width of the container. Is typically computed dynamically by the parent Table.
 */
const EmptyTablePlaceholder = ({ content = "No data to display", width }) => {
  return <StyledContainer $width={width}>{content}</StyledContainer>;
};

EmptyTablePlaceholder.displayName = "EmptyTablePlaceholder";

EmptyTablePlaceholder.propTypes = {
  content: PropTypes.node,
  width: PropTypes.number,
};

export default EmptyTablePlaceholder;
