/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``dots-vertical.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const DotsVerticalIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M7.5 3.75C7.5 2.92157 8.17157 2.25 9 2.25C9.82843 2.25 10.5 2.92157 10.5 3.75C10.5 4.57843 9.82843 5.25 9 5.25C8.17157 5.25 7.5 4.57843 7.5 3.75ZM7.5 9C7.5 8.17157 8.17157 7.5 9 7.5C9.82843 7.5 10.5 8.17157 10.5 9C10.5 9.82843 9.82843 10.5 9 10.5C8.17157 10.5 7.5 9.82843 7.5 9ZM7.5 14.25C7.5 13.4216 8.17157 12.75 9 12.75C9.82843 12.75 10.5 13.4216 10.5 14.25C10.5 15.0784 9.82843 15.75 9 15.75C8.17157 15.75 7.5 15.0784 7.5 14.25Z"
        transform=""
      />
    </g>
  </svg>
);

DotsVerticalIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

DotsVerticalIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

DotsVerticalIcon.displayName = "DotsVerticalIcon";

export default DotsVerticalIcon;
