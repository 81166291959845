import styled from "styled-components";
import PropTypes from "prop-types";

import InputRow from "../Form/InputRow";

const ComponentGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  ${InputRow} {
    margin-bottom: 0;
  }
`;

ComponentGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

ComponentGroup.displayName = "ComponentGroup";

/* @component */
export default ComponentGroup;
