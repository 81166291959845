import _ from "lodash";

/**
 * Convert a number into a comma-delimited string. this has some similarities to
 * ``strip_decimal_trailing_zeros``
 *
 * @param {number} num - the number to be converted
 * @param {number} minimumDecimalPlaces - optional argument
 * @returns {string} the comma-delimited string representation of num
 */
const addCommasToNum = (num, minimumDecimalPlaces = 0) => {
  if (_.isNull(num)) {
    return num;
  }
  const splitNumber = (_.isString(num) ? Number(num) : num)
    .toString()
    .split(".");
  splitNumber[0] = splitNumber[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  if (minimumDecimalPlaces !== 0) {
    if (splitNumber.length < 2) {
      splitNumber.push(_.repeat("0", minimumDecimalPlaces));
    } else {
      splitNumber[1] += _.repeat(
        "0",
        minimumDecimalPlaces - splitNumber[1].length
      );
    }
  }

  return splitNumber.join(".");
};

export default addCommasToNum;
