import _ from "lodash";

// Copied from onikiri
const _parseJsonApiObj = (obj) => {
  const attributes = obj.attributes;
  const relationships = _.mapValues(obj.relationships, (rel) => {
    // Check if rel.data is an array of objects.
    if (rel.data) {
      if (Array.isArray(rel.data)) {
        return rel.data.map((datum) => [datum.type, datum.id]);
      }
      // Not an array.
      return [rel.data.type, rel.data.id];
    }
    return null;
  });
  return { apiId: obj.id, apiType: obj.type, ...attributes, ...relationships };
};

// Copied from onikiri
const cleanJsonApiData = (data) => {
  if (data) {
    // Handle the primary objects being passed down. Flatten structure
    let mainData;
    if (Array.isArray(data.data)) {
      mainData = data.data.map((inst) => _parseJsonApiObj(inst));
    } else {
      mainData = _parseJsonApiObj(data.data);
    }
    // Handle the included objects. Returns an object shaped like
    // { [TYPE]: { [ID]: obj1, [ID2]: obj2 }, [TYPE2]: { [ID]: obj } }
    // Relationships get parsed from the api in a way you can use a get helper to access this
    // without any additional work
    const parsedIncluded = data.included
      ? _.groupBy(
          _.keyBy(
            data.included.map((inst) => _parseJsonApiObj(inst)),
            "apiId"
          ),
          "apiType"
        )
      : {};
    const parsedPaginationInfo = {
      ...data.links,
      ...data.meta?.pagination,
    };

    return {
      data: mainData,
      relationships: parsedIncluded,
      page: parsedPaginationInfo,
    };
  }
  return null;
};

// KN pagination data format
const knGetNextPageParam = (page) => {
  if (page.meta.pagination.page < page.meta.pagination.pages) {
    return page.meta.pagination.page + 1;
  }
};

const defaultQueryOptions = {
  refetchOnWindowFocus: false,
  retry: 0,
};

/**
 * Determines whether or not the provided data is for a file upload.
 * Checks if data is an instance of ``FormData`` or if it contains any ``File`` objects.
 *
 * @param {object} data - The data we are sending to the server
 * @returns {boolean} True if the data is for a file upload, False otherwise
 */
const isFileUpload = (data) => {
  if (data instanceof FormData) {
    return true;
  }

  return _.some(_.values(data), (v) => {
    if (v instanceof File) {
      return true;
    }
    if (_.isArray(v) || _.isObject(v)) {
      return isFileUpload(v);
    }
    return false;
  });
};

/**
 * Creates a FormData object for file uploads.
 *
 * @param {object} data
 * @returns {FormData}
 */
const constructFormData = (data) => {
  const formData = new FormData();
  _.forIn(data, (v, k) => {
    if (_.isArray(v)) {
      _.forEach(v, (arrayV) => formData.append(k, arrayV));
    } else {
      formData.append(k, v);
    }
  });
  return formData;
};

export {
  cleanJsonApiData,
  knGetNextPageParam,
  defaultQueryOptions,
  isFileUpload,
  constructFormData,
};
