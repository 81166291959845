import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import HyperLink from "../Button/HyperLink";
import { PrimaryColorPalette } from "../StylingConstants";
import { StyledBody1 } from "../Typography";

const StyledWrapper = styled(StyledBody1)`
  align-items: center;
  line-height: 1;
  border-bottom: 1px solid ${PrimaryColorPalette.white15};
  margin-bottom: 8px;
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
`;

/**
 * Yuka v2.0
 * This is a component used to display the standard heading for a list of definitions
 *
 * @param {object} props
 *
 * @returns {Element}
 */
const DefinitionListHeader = props => (
  <StyledWrapper className={props.className}>
    <span>{props.title}</span>
    {props.actionLink && <HyperLink {...props.linkProps}>{props.actionLink}</HyperLink>}
  </StyledWrapper>
);

DefinitionListHeader.propTypes = {
  title: PropTypes.node.isRequired,
  /** Action link text */
  actionLink: PropTypes.string,
  /** Any props to pass to the link, e.g. onClick, url, linkType */
  linkProps: PropTypes.object,
  className: PropTypes.string,
};

DefinitionListHeader.defaultProps = {
  className: "",
};

// Wrap with styled so this can be used inline for styles
const StyledDefinitionListHeader = styled(DefinitionListHeader)``;

StyledDefinitionListHeader.displayName = "DefinitionListHeader";

/* @component */
export default StyledDefinitionListHeader;
export { DefinitionListHeader as DocsDefinitionListHeader };
