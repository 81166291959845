/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``location.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const LocationIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M9 2.25C6.1005 2.25 3.75 4.6005 3.75 7.5C3.75 8.91908 4.38988 10.2585 5.46921 11.66C6.38056 12.8434 7.54705 14.0004 8.79921 15.2425C8.8659 15.3086 8.93284 15.375 9 15.4417C9.06716 15.375 9.1341 15.3086 9.20079 15.2425C10.4529 14.0004 11.6194 12.8434 12.5308 11.66C13.6101 10.2585 14.25 8.91908 14.25 7.5C14.25 4.6005 11.8995 2.25 9 2.25ZM2.25 7.5C2.25 3.77208 5.27208 0.75 9 0.75C12.7279 0.75 15.75 3.77208 15.75 7.5C15.75 9.39463 14.8899 11.0552 13.7192 12.5753C12.741 13.8455 11.4889 15.0867 10.2381 16.3266C10.0015 16.5612 9.76497 16.7957 9.53033 17.0303C9.23744 17.3232 8.76256 17.3232 8.46967 17.0303C8.23503 16.7957 7.99848 16.5612 7.76188 16.3266C6.5111 15.0867 5.25904 13.8455 4.28079 12.5753C3.11012 11.0552 2.25 9.39463 2.25 7.5ZM9 6C8.17157 6 7.5 6.67157 7.5 7.5C7.5 8.32843 8.17157 9 9 9C9.82843 9 10.5 8.32843 10.5 7.5C10.5 6.67157 9.82843 6 9 6ZM6 7.5C6 5.84315 7.34315 4.5 9 4.5C10.6569 4.5 12 5.84315 12 7.5C12 9.15685 10.6569 10.5 9 10.5C7.34315 10.5 6 9.15685 6 7.5Z"
        transform=""
      />
    </g>
  </svg>
);

LocationIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

LocationIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

LocationIcon.displayName = "LocationIcon";

export default LocationIcon;
