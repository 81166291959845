import _ from "lodash";
import { DateTime } from "luxon";

/**
 * Combines the public security quotes and pre-IPO orderflow data into a single data set.
 *
 * @param {Array} postIPOData
 * @param {Array} preIPOData
 * @returns {object}
 */
const combinePublicAndPreIPOData = (postIPOData, preIPOData) => {
  // Masquerade private company orderflow data as public security quotes.
  const mappedPreIPOData = _.map(
    preIPOData,
    ({ zx_index_price: closePrice, report_period: date }) => ({
      close_price: closePrice,
      date,
      is_market_closed_day: false,
    })
  );

  let finalPreIPODataWithWhitespace = [];
  // Populate the data with "whitespace" data points to fill in the gaps between the pre-IPO
  // data points. We take orderflow reports on a weekly basis, but public security quotes are
  // collected daily. We want lightweight charts to keep our x-axis scale consistent, so we
  // make recently IPO'd companies' data look like it's always been public.
  _.forEach(mappedPreIPOData, (dataPoint, index) => {
    const nextDataPoint = mappedPreIPOData[index + 1];
    finalPreIPODataWithWhitespace.push(dataPoint);
    let nextDate = DateTime.fromISO(dataPoint.date).minus({ days: 1 });

    while (nextDataPoint && nextDate > DateTime.fromISO(nextDataPoint.date)) {
      finalPreIPODataWithWhitespace.push({
        date: nextDate.toISODate(),
      });
      nextDate = nextDate.minus({ days: 1 });
    }
  });

  const combined = _.sortedUniqBy(
    _.orderBy(
      [...postIPOData, ...finalPreIPODataWithWhitespace],
      ["date"],
      ["desc"]
    ),
    "date"
  );

  return _.isEmpty(combined) ? {} : combined;
};

export default combinePublicAndPreIPOData;
