import _ from "lodash";
import PropTypes from "prop-types";
import { useFormState } from "react-final-form";
import { ActionChip, FinalFormField, Fonts } from "yuka";

import {
  COMPONENT_TYPE_MAP,
  VALUATION_COMPONENT_ZX_INDEX_VALUE_TRAILING,
} from "./constants";
import { debouncedModifyDefaultComponentValue } from "./debouncedMixpanelEvents";
import { useComponent, useLatestOrderFlowReport } from "./hooks";
import { Styled16Gap, StyledDetailPanelFields } from "./StyledComponents";
import { currentValueFieldName, initialValueFieldName } from "./utils";

import RobustnessScoreLabel from "../RobustnessScoreLabel";

const LastSecondaryPriceField = ({
  companyName,
  companyId,
  editable,
  resetValue,
  componentId,
}) => {
  const { values } = useFormState();

  const { name, initialValue, currentValue } = useComponent(
    values,
    componentId
  );

  const [orderFlowReport] = useLatestOrderFlowReport(
    companyId,
    values.valuation_date,
    !initialValue && Boolean(values.valuation_date) && Boolean(companyId)
  );

  const robustnessLabel =
    orderFlowReport && orderFlowReport.zx_index_value_trailing ? (
      <RobustnessScoreLabel score={orderFlowReport.robustness} />
    ) : null;

  return (
    <StyledDetailPanelFields>
      <Fonts.Body1theme50>
        If available, we use the most recent ZX Index Value (trailing) as our
        default value. ZX Index Value (trailing) is designed to provide a view
        on where the most recent transaction has closed.
      </Fonts.Body1theme50>
      {editable && (
        <Fonts.Body1theme50>
          You can override the default value by entering your own number below:
        </Fonts.Body1theme50>
      )}
      <Styled16Gap>
        <FinalFormField
          initialValue={values[initialValueFieldName(componentId)]}
          disabled={!editable}
          name={currentValueFieldName(componentId)}
          type="number"
          label="Secondary price"
          placeholder="Enter a price"
          trailingIcon={
            initialValue === currentValue ? () => robustnessLabel : null
          }
          onChange={(value) => {
            if (value !== initialValue && (value || initialValue)) {
              debouncedModifyDefaultComponentValue(
                companyName,
                name,
                COMPONENT_TYPE_MAP[VALUATION_COMPONENT_ZX_INDEX_VALUE_TRAILING],
                initialValue,
                value
              );
            }
          }}
        />
        {editable && <ActionChip text="Restore default" onClick={resetValue} />}
      </Styled16Gap>
    </StyledDetailPanelFields>
  );
};

LastSecondaryPriceField.propTypes = {
  companyName: PropTypes.string.isRequired,
  companyId: PropTypes.string,
  editable: PropTypes.bool,
  resetValue: PropTypes.func,
  componentId: PropTypes.number,
};

LastSecondaryPriceField.defaultProps = {
  resetValue: _.noop,
};

export default LastSecondaryPriceField;
