import React from "react";
import _ from "lodash";

const useMenuNavigation = (onItemClick, menuItems, sectioned) => {
  const [selection, setSelection] = React.useState(null);

  React.useEffect(() => {
    setSelection(null);
  }, [menuItems]);

  const onKeyDown = React.useCallback(
    event => {
      if ((event.key === "Enter" || event.key === "Tab") && selection !== null) {
        const selectedItem = _.get(menuItems, selection);
        const onClick = selectedItem.onClick || onItemClick;
        onClick?.(selectedItem);
        setSelection(null);
        event.preventDefault();
      } else if (event.key === "ArrowUp") {
        if (sectioned) {
          // Sectioned array
          if (selection === null) {
            // Starting arrow nav
            const menuIndex = menuItems.findLastIndex(x => x.length > 0);
            setSelection([menuIndex, 0]);
          } else if (selection[1] > 0) {
            // Still more in this section
            setSelection([selection[0], selection[1] - 1]);
          } else if (selection[0] > 0) {
            // More sections
            setSelection([selection[0] - 1, 0]);
          } else {
            // Wrap to bottom
            const menuIndex = menuItems.findLastIndex(x => x.length > 0);
            setSelection([menuIndex, 0]);
          }
        } else if (selection === null) {
          // Starting arrow nav
          setSelection([menuItems.length - 1]);
        } else if (selection.length === 2) {
          // Sub menu
          if (selection[1] > 0) {
            // More in the menu
            setSelection([selection[0], selection[1] - 1]);
          } else {
            // Wrap to bottom
            setSelection([selection[0], menuItems[selection[0]].length - 1]);
          }
        } else if (selection.length === 1) {
          // Top level menu
          if (selection[0] > 0) {
            // More in the menu
            setSelection([selection[0] - 1]);
          } else {
            // Wrap to bottom
            setSelection([menuItems.length - 1]);
          }
        }
        event.preventDefault();
      } else if (event.key === "ArrowDown") {
        if (sectioned) {
          // Sectioned array
          if (selection === null) {
            // Starting arrow nav
            const menuIndex = menuItems.findIndex(x => x.length > 0);
            setSelection([menuIndex, 0]);
          } else if (menuItems[selection[0]].length > selection[1] + 1) {
            // Still more in this section
            setSelection([selection[0], selection[1] + 1]);
          } else if (menuItems.length > selection[0] + 1) {
            // More sections
            setSelection([selection[0] + 1, 0]);
          } else {
            // Wrap to top
            const menuIndex = menuItems.findIndex(x => x.length > 0);
            setSelection([menuIndex, 0]);
          }
        } else if (selection === null) {
          // Starting arrow nav
          setSelection([0]);
        } else if (selection.length === 2) {
          // Sub menu
          if (menuItems[selection[0]].length < selection[1] + 1) {
            // More in the menu
            setSelection([selection[0], selection[1] + 1]);
          } else {
            // Wrap to top
            setSelection([selection[0], 0]);
          }
        } else if (selection.length === 1) {
          // Top level menu
          if (menuItems.length > selection[0] + 1) {
            // More in the menu
            setSelection([selection[0] + 1]);
          } else {
            // Wrap to top
            setSelection([0]);
          }
        }
        event.preventDefault();
      } else if (
        event.key === "ArrowRight" &&
        selection.length === 1 &&
        menuItems[selection[0]].menuItems
      ) {
        setSelection([selection[0], 0]);
        event.preventDefault();
      } else if (event.key === "ArrowLeft" && selection.length === 2 && !sectioned) {
        setSelection([selection[0]]);
        event.preventDefault();
      }
    },
    [selection, onItemClick, menuItems]
  );

  return [onKeyDown, selection];
};

export default useMenuNavigation;
