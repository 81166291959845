const COMPANY_SEARCH_FORM_FIELD_NAME = "companySearch";

const COMPANY_MATCH_FIELD_NAME = "companySearchMatch";
const COMPANY_MATCH_HAS_RESULTS = "hasResults";
const COMPANY_MATCH_NO_MATCHES = "noMatches";
const COMPANY_MATCH_ALREADY_IN_PORTFOLIO = "alreadyInPortfolio";

const SELECTED_COMPANY_ID_FIELD_NAME = "selectedCompany";

const SORT_CRITERIA_ADDED_DATE = "Added date";
const SORT_CRITERIA_COMPANY = "Company";
const SORT_CRITERIA_ZX_INDEX_VALUE = "ZX Index Value";
const SORT_CRITERIA_CUSTOM_VALUATION = "Custom valuation";
const SORT_CRITERIA_QUANTITY = "Quantity";
const SORT_CRITERIA_VALUE = "Total value";
const SORT_CRITERIA_UNREALIZED_GAIN = "Unrealized gain";

const SORT_CRITERIA = [
  SORT_CRITERIA_ADDED_DATE,
  SORT_CRITERIA_COMPANY,
  SORT_CRITERIA_ZX_INDEX_VALUE,
  SORT_CRITERIA_CUSTOM_VALUATION,
  SORT_CRITERIA_QUANTITY,
  SORT_CRITERIA_VALUE,
  SORT_CRITERIA_UNREALIZED_GAIN,
];

const SORT_CRITERIA_TO_LABEL = {
  [SORT_CRITERIA_ADDED_DATE]: "added date",
  [SORT_CRITERIA_COMPANY]: "company",
  [SORT_CRITERIA_ZX_INDEX_VALUE]: "ZX Index Value",
  [SORT_CRITERIA_CUSTOM_VALUATION]: "custom valuation",
  [SORT_CRITERIA_QUANTITY]: "quantity",
  [SORT_CRITERIA_VALUE]: "total value",
  [SORT_CRITERIA_UNREALIZED_GAIN]: "unrealized gain",
};

const SORT_DIRECTION_ASC = "Ascending";
const SORT_DIRECTION_DESC = "Descending";

const SORT_DIRECTIONS = [SORT_DIRECTION_ASC, SORT_DIRECTION_DESC];

const REMOVED_REASON_IPO = 1;
const REMOVED_REASON_ACQUIRED = 2;
const REMOVED_REASON_OUT_OF_BUSINESS = 3;
const REMOVED_REASON_SANCTIONED = 4;
const REMOVED_REASON_FEDERAL_LAW = 5;
const REMOVED_REASON_STATE_LAW = 6;
const REMOVED_REASON_BANKRUPT = 7;
const REMOVED_REASON_SPAC = 8;
const REMOVED_REASON_DIRECT_LISTING = 9;

/*
 * Maps the removed reasons to how they're displayed for the portfolio table.
 */
const REMOVED_REASON_MAP = {
  [REMOVED_REASON_IPO]: "IPO",
  [REMOVED_REASON_ACQUIRED]: "Acquired",
  [REMOVED_REASON_OUT_OF_BUSINESS]: "Out of Business",
  [REMOVED_REASON_SANCTIONED]: "Sanctioned",
  [REMOVED_REASON_FEDERAL_LAW]: "Federal Law",
  [REMOVED_REASON_STATE_LAW]: "State Law",
  [REMOVED_REASON_BANKRUPT]: "Bankrupt",
  [REMOVED_REASON_SPAC]: "SPAC",
  [REMOVED_REASON_DIRECT_LISTING]: "Direct Listing",
};

/*
 * Portfolio viewing modes.
 */
const MODE_ZX_INDEX_VALUE = "zx-index-value";
const MODE_CUSTOM_VALUATION = "custom-valuation";

// We need this map b/c IDs can't have whitespace and the above 2 constants will be IDs.
const MODE_TO_MIXPANEL_NAME_MAP = {
  [MODE_ZX_INDEX_VALUE]: "zx index value",
  [MODE_CUSTOM_VALUATION]: "custom valuation",
};

export {
  COMPANY_SEARCH_FORM_FIELD_NAME,
  SELECTED_COMPANY_ID_FIELD_NAME,
  COMPANY_MATCH_FIELD_NAME,
  COMPANY_MATCH_HAS_RESULTS,
  COMPANY_MATCH_NO_MATCHES,
  COMPANY_MATCH_ALREADY_IN_PORTFOLIO,
  SORT_CRITERIA,
  SORT_CRITERIA_TO_LABEL,
  SORT_CRITERIA_ADDED_DATE,
  SORT_CRITERIA_COMPANY,
  SORT_CRITERIA_ZX_INDEX_VALUE,
  SORT_CRITERIA_CUSTOM_VALUATION,
  SORT_CRITERIA_QUANTITY,
  SORT_CRITERIA_VALUE,
  SORT_CRITERIA_UNREALIZED_GAIN,
  SORT_DIRECTIONS,
  SORT_DIRECTION_ASC,
  SORT_DIRECTION_DESC,
  REMOVED_REASON_MAP,
  MODE_ZX_INDEX_VALUE,
  MODE_CUSTOM_VALUATION,
  MODE_TO_MIXPANEL_NAME_MAP,
};
