import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { ColorPalette } from "yuka";

import { TABLIST_VARIANTS, VARIANT_DEFAULT } from "./constants";

const TabDivider = styled.div`
  flex: 0 0 1px;
  height: 24px;
  background-color: ${ColorPalette.white15};
`;

const StyledTabList = styled.div`
  display: flex;
  gap: 24px;
  margin: 0;
  overflow: hidden;
`;

/**
 * A component to organize a set of tab groups. Can either be used with a route portion, or
 * a query param, and offers 2 visual variants.
 *
 * @param {React.Element} children
 * @param {string} routeId - Optional routeId to use for tab selection, should be from a <Route />
 *    component that has a :routeId parameter involved. Should be included if not using queryParam.
 * @param {string} queryParam - Optional query param to use for tab selection, should be provided
 *    if not using routeId.
 * @param {string} variant - Optional variant to use for tab selection.
 * @return {JSX.Element}
 */
const TabList = ({ children, routeId, queryParam, variant }) => {
  return (
    <StyledTabList role="tablist">
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child, {
          index,
          variant,
          routeId,
          queryParam,
          resizeable: index === children.length - 1,
          key: `tab-group-${index}`,
        })
      )}
    </StyledTabList>
  );
};

TabList.propTypes = {
  routeId: PropTypes.string,
  queryParam: PropTypes.string,
  children: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.element, PropTypes.array])
  ),
  variant: PropTypes.oneOf(TABLIST_VARIANTS),
};

TabList.defaultProps = {
  routeId: null,
  queryParam: null,
  variant: VARIANT_DEFAULT,
};

export default TabList;

export { TabDivider, StyledTabList };
