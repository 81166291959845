import styled, { css } from "styled-components";
import { ColorPalette, Fonts } from "yuka";

const borderStyle = `1px dashed ${ColorPalette.white15}`;

const StyledCenteredEmptyState = styled(Fonts.Headline1theme30).attrs({
  as: "div",
})`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.$margin &&
    css`
      margin: ${props.$margin}px 0;
    `}
`;

/**
 * Typically used to wrap StyledCenteredEmptyState for cards that contain graphs.
 */
const StyledEmptyPill = styled.div`
  background-color: rgba(255, 255, 255, 0.03);
  border-radius: 60px;
`;

const CarouselControlButtonRow = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: flex-end;
  width: 100%;
`;

const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledCarouselActions = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: flex-end;
  width: 100%;
`;

const EmptyCarouselCard = styled.div`
  height: 168px;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.03);
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  &:not(:last-child) {
    margin-right: 16px;
  }
`;

const ListItem = styled.span`
  display: flex;
  justify-content: space-between;
  width: 100%;

  &:not(:first-child) {
    padding-top: 16px;
  }

  &:not(:last-child) {
    border-bottom: ${borderStyle};
    padding-bottom: 16px;
  }
`;

const StyledCircle = styled.div`
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

const StyledTwoLineText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  // 97% global support
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const StyledThreeLineText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  // 97% global support
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export {
  borderStyle,
  StyledEmptyPill,
  StyledCenteredEmptyState,
  CarouselControlButtonRow,
  CarouselContainer,
  StyledCarouselActions,
  EmptyCarouselCard,
  List,
  ListItem,
  StyledCircle,
  StyledTwoLineText,
  StyledThreeLineText,
};
