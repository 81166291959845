/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``check.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const CheckIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M15.5303 3.96967C15.8232 4.26256 15.8232 4.73744 15.5303 5.03033L7.28033 13.2803C6.98744 13.5732 6.51256 13.5732 6.21967 13.2803L2.46967 9.53033C2.17678 9.23744 2.17678 8.76256 2.46967 8.46967C2.76256 8.17678 3.23744 8.17678 3.53033 8.46967L6.75 11.6893L14.4697 3.96967C14.7626 3.67678 15.2374 3.67678 15.5303 3.96967Z"
        transform=""
      />
    </g>
  </svg>
);

CheckIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

CheckIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

CheckIcon.displayName = "CheckIcon";

export default CheckIcon;
