/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``hand.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const HandIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M9 2.25C8.69598 2.25 8.475 2.48779 8.475 2.75V8.16667C8.475 8.58088 8.13921 8.91667 7.725 8.91667C7.31079 8.91667 6.975 8.58088 6.975 8.16667V3.58333C6.975 3.32112 6.75402 3.08333 6.45 3.08333C6.14598 3.08333 5.925 3.32112 5.925 3.58333V10.6667C5.925 11.0809 5.58921 11.4167 5.175 11.4167C4.76079 11.4167 4.425 11.0809 4.425 10.6667V8.58333C4.425 8.32112 4.20402 8.08333 3.9 8.08333C3.59598 8.08333 3.375 8.32112 3.375 8.58333V10.25C3.375 13.2736 5.87933 15.75 9 15.75C12.1207 15.75 14.625 13.2736 14.625 10.25V6.08333C14.625 5.82112 14.404 5.58333 14.1 5.58333C13.796 5.58333 13.575 5.82112 13.575 6.08333V8.16667C13.575 8.58088 13.2392 8.91667 12.825 8.91667C12.4108 8.91667 12.075 8.58088 12.075 8.16667V3.58333C12.075 3.32112 11.854 3.08333 11.55 3.08333C11.246 3.08333 11.025 3.32112 11.025 3.58333V8.16667C11.025 8.58088 10.6892 8.91667 10.275 8.91667C9.86079 8.91667 9.525 8.58088 9.525 8.16667V2.75C9.525 2.48779 9.30402 2.25 9 2.25ZM10.7518 1.7452C10.3988 1.14656 9.74068 0.75 9 0.75C8.25932 0.75 7.60124 1.14656 7.24816 1.7452C7.00229 1.64087 6.73214 1.58333 6.45 1.58333C5.34569 1.58333 4.425 2.46483 4.425 3.58333V6.65155C4.2571 6.60702 4.08102 6.58333 3.9 6.58333C2.79569 6.58333 1.875 7.46483 1.875 8.58333V10.25C1.875 14.1299 5.07904 17.25 9 17.25C12.921 17.25 16.125 14.1299 16.125 10.25V6.08333C16.125 4.96483 15.2043 4.08333 14.1 4.08333C13.919 4.08333 13.7429 4.10702 13.575 4.15155V3.58333C13.575 2.46483 12.6543 1.58333 11.55 1.58333C11.2679 1.58333 10.9977 1.64087 10.7518 1.7452Z"
        transform=""
      />
    </g>
  </svg>
);

HandIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

HandIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

HandIcon.displayName = "HandIcon";

export default HandIcon;
