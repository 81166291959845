/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``maximize.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const MaximizeIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M11.25 3C10.8358 3 10.5 2.66421 10.5 2.25C10.5 1.83579 10.8358 1.5 11.25 1.5H15.75C16.1642 1.5 16.5 1.83579 16.5 2.25V6.75C16.5 7.16421 16.1642 7.5 15.75 7.5C15.3358 7.5 15 7.16421 15 6.75V4.06066L11.0303 8.03033C10.7374 8.32322 10.2626 8.32322 9.96967 8.03033C9.67678 7.73744 9.67678 7.26256 9.96967 6.96967L13.9393 3H11.25ZM8.03033 9.96967C8.32322 10.2626 8.32322 10.7374 8.03033 11.0303L4.06066 15H6.75C7.16421 15 7.5 15.3358 7.5 15.75C7.5 16.1642 7.16421 16.5 6.75 16.5H2.25C2.05109 16.5 1.86032 16.421 1.71967 16.2803C1.57902 16.1397 1.5 15.9489 1.5 15.75L1.5 11.25C1.5 10.8358 1.83579 10.5 2.25 10.5C2.66421 10.5 3 10.8358 3 11.25L3 13.9393L6.96967 9.96967C7.26256 9.67678 7.73744 9.67678 8.03033 9.96967Z"
        transform=""
      />
    </g>
  </svg>
);

MaximizeIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

MaximizeIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

MaximizeIcon.displayName = "MaximizeIcon";

export default MaximizeIcon;
