import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import useWindowDimensions from "./useWindowDimensions";
import useDidMount from "./useDidMount";
import useScrollPosition from "./useScrollPosition";

import { Orientations } from "../StylingConstants";

const OffsetContext = React.createContext({ left: 0, top: 0 });

const useDynamicPosition = ({
  containerRef,
  elementRef,
  THRESHOLD = 0,
  SPACING = 0,
  OVERHANG = 0,
  defaultAbove = true,
  orientation = Orientations.VERTICAL,
}) => {
  const windowDimensions = useWindowDimensions();
  const didMount = useDidMount();

  const EARLIER_PRIMARY = `calc(-100% - ${SPACING}px)`;
  const LATER_PRIMARY = `${SPACING}px`;

  const defaultPrimaryDim = defaultAbove ? EARLIER_PRIMARY : LATER_PRIMARY;
  const defaultSecondaryDim = `${-OVERHANG}px`;

  const [above, setAbove] = React.useState(defaultAbove);
  const [xTranslate, setXTranslate] = React.useState(
    orientation === Orientations.VERTICAL ? defaultSecondaryDim : defaultPrimaryDim
  );
  const [yTranslate, setYTranslate] = React.useState(
    orientation === Orientations.VERTICAL ? defaultPrimaryDim : defaultSecondaryDim
  );

  const [{ top: containerTop, bottom: containerBottom, left: containerLeft }, recalculatePosition] =
    useScrollPosition(containerRef);

  const calculateVerticalPosition = React.useCallback(() => {
    if (didMount && elementRef.current) {
      const { left, width: containerWidth = 0 } =
        containerRef.current?.getBoundingClientRect?.() ?? {};
      const { width, height } = elementRef.current.getBoundingClientRect();

      const offset = width - containerWidth;
      const pastRightEdge = windowDimensions.width - left - width < THRESHOLD;
      const pastLeftEdge = left - offset < THRESHOLD;
      if (pastRightEdge && !pastLeftEdge) {
        setXTranslate(`${OVERHANG - offset}px`);
      } else {
        setXTranslate(`${-OVERHANG}px`);
      }

      const pastTopAbove = containerTop - height + SPACING < THRESHOLD;
      const pastBottomBelow =
        windowDimensions.height - containerBottom - height - SPACING < THRESHOLD;
      if (
        (defaultAbove && (!pastTopAbove || pastBottomBelow)) ||
        (!defaultAbove && pastBottomBelow && !pastTopAbove)
      ) {
        setAbove(true);
        setYTranslate(EARLIER_PRIMARY);
      } else {
        setAbove(false);
        setYTranslate(LATER_PRIMARY);
      }
    }
  }, [
    didMount,
    containerRef,
    windowDimensions.width,
    windowDimensions.height,
    containerTop,
    containerBottom,
    EARLIER_PRIMARY,
    LATER_PRIMARY,
    OVERHANG,
    SPACING,
    THRESHOLD,
    defaultAbove,
    elementRef,
  ]);

  const calculateHorizontalPosition = React.useCallback(() => {
    if (didMount && elementRef.current) {
      const {
        left,
        width: containerWidth = 0,
        height: containerHeight,
      } = containerRef.current?.getBoundingClientRect?.() ?? {};
      const { width, height } = elementRef.current.getBoundingClientRect();

      const pastRightEdge =
        windowDimensions.width - left - width - containerWidth - SPACING < THRESHOLD;
      const pastLeftEdge = left - width + SPACING < THRESHOLD;

      if (
        (defaultAbove && (!pastLeftEdge || pastRightEdge)) ||
        (!defaultAbove && pastRightEdge && !pastLeftEdge)
      ) {
        setAbove(true);
        setXTranslate(EARLIER_PRIMARY);
      } else {
        setAbove(false);
        setXTranslate(LATER_PRIMARY);
      }

      const offset = height - containerHeight;
      const pastTopAbove = containerTop - offset < THRESHOLD;
      const pastBottomBelow = windowDimensions.height - containerBottom - offset < THRESHOLD;
      if (!pastTopAbove && pastBottomBelow) {
        setYTranslate(`${OVERHANG - offset}px`);
      } else {
        setYTranslate(`${-OVERHANG}px`);
      }
    }
  }, [
    didMount,
    containerRef,
    windowDimensions.width,
    windowDimensions.height,
    containerTop,
    containerBottom,
    EARLIER_PRIMARY,
    LATER_PRIMARY,
    OVERHANG,
    SPACING,
    THRESHOLD,
    defaultAbove,
    elementRef,
  ]);

  const calculatePosition = React.useMemo(() => {
    return orientation === Orientations.VERTICAL
      ? calculateVerticalPosition
      : calculateHorizontalPosition;
  }, [orientation, calculateVerticalPosition, calculateHorizontalPosition]);

  React.useLayoutEffect(() => {
    calculatePosition();
  }, [calculatePosition]);

  let style;
  if (didMount && containerRef && containerRef.current) {
    // Refetch live position of left and top - if elements have loaded onto the page the container
    // might have moved without useScrollPosition capturing it.
    const {
      top: containerTopLive,
      left: containerLeftLive,
      height: containerHeight,
      width: containerWidth,
    } = containerRef.current.getBoundingClientRect();
    if (containerTopLive !== containerTop || containerLeftLive !== containerLeft) {
      recalculatePosition();
    }
    if (orientation === Orientations.VERTICAL) {
      style = {
        position: "fixed",
        top: above ? containerTopLive : containerTopLive + containerHeight,
        left: containerLeftLive,
        transform: `translate(${xTranslate}, ${yTranslate})`,
      };
    } else {
      style = {
        position: "fixed",
        top: containerTopLive,
        left: above ? containerLeftLive : containerLeftLive + containerWidth,
        transform: `translate(${xTranslate}, ${yTranslate})`,
      };
    }
  } else {
    style = { position: "fixed" };
  }

  return [style, calculatePosition];
};

/**
 * If we nest elements that use `translate` css, `useDynamicPosition` will position the element
 * poorly. This notably happens when we nest multiple elements that are both based on
 * `useDynamicPosition`.
 *
 * The solution here is to store the offsets, and combined with `useIsUnderAnchor` use this to
 * detect that we have nested and render the element within a portal (see `Dropdown` or `Menu`).
 *
 * Rendering in a portal takes the element out of the translated context so the offsets are
 * correct again.
 *
 * Actual values passed to this provider are ignored currently. They could be used in the future
 * if we don't want/need the Portal for any reason.
 */
const NestingAnchorContextProvider = ({ left, top, children }) => {
  const { top: baseTop, left: baseLeft } = React.useContext(OffsetContext);

  return (
    <OffsetContext.Provider value={{ left: left + baseLeft, top: top + baseTop }}>
      {children}
    </OffsetContext.Provider>
  );
};

NestingAnchorContextProvider.propTypes = {
  left: PropTypes.number,
  top: PropTypes.number,
  children: PropTypes.node.isRequired,
};

NestingAnchorContextProvider.defaultProps = {
  left: 0,
  top: 0,
};

const useRenderDynamicPosition = () => {
  const { top, left } = React.useContext(OffsetContext);
  const isUnderAnchor = Boolean(top || left);

  // When we are under a dynamically positioned element, stop propagating onClick to the body.
  // Notably, this means that `onClickOutside` in `useIsPopupOpen` doesn't trigger when nesting
  // overlay elements.
  const dontPropagateClose = e => {
    e.stopPropagation();
  };

  return (el, style) => (
    <NestingAnchorContextProvider left={style?.left} top={style?.top}>
      {isUnderAnchor
        ? ReactDOM.createPortal(
            <div onMouseDown={dontPropagateClose} role="none">
              {el}
            </div>,
            document.body
          )
        : el}
    </NestingAnchorContextProvider>
  );
};

export default useDynamicPosition;
export { useRenderDynamicPosition, NestingAnchorContextProvider };
