/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``help-circle.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const HelpCircleIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25ZM0.75 9C0.75 4.44365 4.44365 0.75 9 0.75C13.5563 0.75 17.25 4.44365 17.25 9C17.25 13.5563 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5563 0.75 9ZM9.19359 6.01824C8.84445 5.95835 8.48538 6.02396 8.17998 6.20345C7.87458 6.38294 7.64255 6.66472 7.525 6.99888C7.38755 7.38962 6.95936 7.59496 6.56862 7.4575C6.17788 7.32005 5.97255 6.89186 6.11 6.50112C6.3451 5.83279 6.80915 5.26922 7.41995 4.91025C8.03076 4.55128 8.74889 4.42006 9.44718 4.53983C10.1455 4.6596 10.7788 5.02264 11.2351 5.56465C11.6912 6.10654 11.941 6.79236 11.94 7.50068C11.9397 8.64845 11.0887 9.40642 10.481 9.81154C10.1543 10.0294 9.83292 10.1895 9.59617 10.2947C9.47673 10.3478 9.37615 10.3881 9.30368 10.4157C9.26738 10.4295 9.23795 10.4403 9.21653 10.4479L9.19048 10.457L9.18218 10.4598L9.17926 10.4608L9.17811 10.4612C9.17788 10.4613 9.17717 10.4615 8.94 9.75L9.17717 10.4615C8.78421 10.5925 8.35947 10.3801 8.22849 9.98717C8.09755 9.59434 8.30973 9.16976 8.70244 9.03862L8.71401 9.03455C8.72555 9.03045 8.74446 9.02359 8.76968 9.01398C8.82026 8.99471 8.89546 8.96468 8.98696 8.92402C9.17208 8.84174 9.4132 8.72065 9.64898 8.56346C10.1662 8.21866 10.44 7.85185 10.44 7.5V7.49888C10.4405 7.14464 10.3157 6.80165 10.0875 6.53065C9.85941 6.25964 9.54273 6.07813 9.19359 6.01824ZM8.25 12.75C8.25 12.3358 8.58579 12 9 12H9.0075C9.42171 12 9.7575 12.3358 9.7575 12.75C9.7575 13.1642 9.42171 13.5 9.0075 13.5H9C8.58579 13.5 8.25 13.1642 8.25 12.75Z"
        transform=""
      />
    </g>
  </svg>
);

HelpCircleIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

HelpCircleIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

HelpCircleIcon.displayName = "HelpCircleIcon";

export default HelpCircleIcon;
