import styled from "styled-components";

import { body1, headline1, FontColors, FontWeights } from "yuka";

const StyledCardHeaderTooltipText = styled.div`
  ${body1}
  padding: 0 16px;
  width: 300px;
`;

const StyledTooltipTextHeader = styled.div`
  ${headline1}
  ${FontWeights.bold}
  ${FontColors.theme100}
  margin-bottom: 12px;
`;

const StyledCenteredBody = styled.div`
  ${body1}
  ${FontColors.theme50}
  margin: 24px 0 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledError = styled.div`
  ${body1}
  ${FontColors.theme30}
`;

const StyledLoadingSpinnerContainer = styled.div`
  height: 48px;
  margin: 16px 0;
`;

export {
  StyledCardHeaderTooltipText,
  StyledTooltipTextHeader,
  StyledCenteredBody,
  StyledError,
  StyledLoadingSpinnerContainer,
};
