import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import styled from "styled-components";

import { PrimaryColorPalette, YukaColorPalette } from "../StylingConstants";
import { FontColors } from "../Typography";

const FONT_SIZES = {
  "18px": "9px",
  "24px": "12px",
  "32px": "16px",
  "48px": "24px",
  "64px": "32px",
  "96px": "48px",
};

const DEFAULT_BACKGROUND_COLORS = [
  PrimaryColorPalette.blue400,
  YukaColorPalette.avatarGreen,
  YukaColorPalette.avatarDarkBlue,
  YukaColorPalette.avatarPurple,
  YukaColorPalette.avatarPink,
  YukaColorPalette.avatarOrange,
];

const StyledAvatar = styled.img`
  border-radius: 50%;
  box-sizing: border-box;
  ${FontColors.theme100}
  display: flex;
  font-weight: bold;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-shrink: 0;

  ${props => `
    height: ${props.$size};
    line-height: ${props.$size};
    width: ${props.$size};
  `}

  ${props =>
    props.onClick
      ? `
    cursor: pointer;
  `
      : ""}
`;

/**
 * Yuka v2.0
 * This returns a circular avatar wrapped in a container based on the dimensions.
 * By default, this will return a gray background avatar with the user's initials.
 */
const Avatar = props => {
  const fontSize = _.get(FONT_SIZES, props.size);
  return (
    <StyledAvatar
      as={props.avatar ? "img" : "div"}
      alt={props.avatarAlt}
      className={props.className}
      onClick={props.onClick}
      src={props.avatar}
      $size={props.size}
      style={{
        fontSize, // This is for alt text / initials text
        backgroundColor:
          !props.avatar &&
          DEFAULT_BACKGROUND_COLORS[props.colorId % DEFAULT_BACKGROUND_COLORS.length],
      }}
    >
      {props.avatar ? undefined : props.avatarAlt}
    </StyledAvatar>
  );
};

Avatar.propTypes = {
  avatar: PropTypes.string,
  avatarAlt: PropTypes.string,
  className: PropTypes.string,
  /**
   * stable number to use for the random background color.
   * not using avatarAlt so that adjacent elements in a table are not the same
   */
  colorId: PropTypes.number,
  size: PropTypes.oneOf(Object.keys(FONT_SIZES)),
  onClick: PropTypes.func,
};

Avatar.defaultProps = {
  avatar: "",
  avatarAlt: "",
  colorId: 0,
  size: "32px",
};

Avatar.displayName = "Avatar";

export default Avatar;
