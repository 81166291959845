import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Surface from "./internal/Surface";
import CardHeader from "./CardHeader";

const CardStyles = {
  BASIC: "BASIC",
  TITLE: "TITLE",
  SECTIONED: "SECTIONED",
  VALUE: "VALUE",
  PADDED: "PADDED",
};

const StyledSurface = styled(Surface)`
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow-y: auto;
  padding: 16px 0;
  box-sizing: border-box;
  min-width: 0;

  ${props => {
    if (props.$cardStyle === CardStyles.TITLE || props.$cardStyle === CardStyles.SECTIONED) {
      return "gap: 24px;";
    } else if (props.$cardStyle === CardStyles.PADDED) {
      return `
        > :first-child:not(:last-child) {
          margin-bottom: 24px;
        }
        padding: 16px;
      `;
    } else if (props.$cardStyle === CardStyles.VALUE) {
      return `
        position: relative;
        gap: 8px;
        padding: 16px;
      `;
    }
    return "";
  }}
`;

/**
 * A standard layout element.
 *
 * For legacy support, include title and tagline props to handle simplified header elements
 *
 * Specify `cardStyle` to get smart layout styles applied.
 * CardStyles.BASIC - no special rules
 * CardStyles.TITLE - Apply 24px spacing from the header to the main content
 * CardStyles.SECTIONED - Apply 24px spacing between all child elements. Wrap each section in a
 *   containing element.
 * CardStyles.PADDED - Applies 24px spacing from the title, and 16px padding on the sides.
 * CardStyles.VALUE - 8px spacing between children, designed to make contained cards to highlight
 *   in individual value with full control over typography and no concerns about layout.
 */
const _Card = React.forwardRef((props, ref) => {
  let computedCardStyle =
    props.cardStyle ?? (props.title || props.tagline ? CardStyles.PADDED : CardStyles.BASIC);

  const includeHeader = Boolean(
    props.title || props.tagline || props.linkText || props.headerTrailingContent
  );

  return (
    <StyledSurface
      onClick={props.onClick}
      className={props.className}
      ref={ref}
      $cardStyle={computedCardStyle}
      elevated={props.elevated}
      id={props.id && !includeHeader ? props.id : ""}
    >
      {includeHeader && (
        <CardHeader
          id={props.id}
          padded={computedCardStyle !== CardStyles.PADDED}
          headline={props.title}
          supportingText={props.tagline}
          linkText={props.linkText}
          linkProps={props.linkProps}
          trailingContent={props.headerTrailingContent}
        />
      )}
      {computedCardStyle === CardStyles.TITLE && props.children ? (
        <div>{props.children}</div>
      ) : (
        props.children
      )}
    </StyledSurface>
  );
});

_Card.propTypes = {
  /** Structure for spacing and layout of the card. Import `CardStyles` to access the constants */
  cardStyle: PropTypes.oneOf(Object.values(CardStyles)),
  /** Indicate whether this card should receive an elevation. Generally shouldn't set explicitly in favor of Dropdowns */
  elevated: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  /** Shorthand for inserting a `CardHeader` with a headline specified */
  title: PropTypes.node,
  /** Shorthand for inserting a `CardHeader` with supportingText specified */
  tagline: PropTypes.node,
  /** Action items in the top right of a card. Intended to be a fragment of button like elements */
  headerTrailingContent: PropTypes.node,
  /** Text used for a link like object in the top right corner of a card. In deprecation in favor of headerTrailingContent */
  linkText: PropTypes.node,
  /** Props to specify configuration of the link in the header. In deprecation in favor of headerTrailingContent */
  linkProps: PropTypes.object,
  onClick: PropTypes.func,
  id: PropTypes.string,
};

_Card.displayName = "Card";

const Card = styled(_Card)``;

export default Card;
// _Card only exported for docs
export { CardStyles, StyledSurface, _Card };
