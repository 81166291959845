/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``send.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const SendIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M14.5005 4.55986L8.75102 10.3093L10.5009 14.8089L14.5005 4.55986ZM7.69036 9.24866L13.4398 3.4992L3.19079 7.49882L7.69036 9.24866ZM15.4854 1.10563C15.6228 1.06572 15.8991 0.994013 16.1959 1.09317C16.5313 1.20521 16.7945 1.46839 16.9065 1.80375C17.0057 2.10057 16.934 2.37692 16.894 2.51431C16.8506 2.66403 16.7809 2.84247 16.711 3.0215L11.7479 15.7393C11.6699 15.9395 11.5943 16.1333 11.5203 16.2829C11.4564 16.4122 11.3141 16.6869 11.0189 16.8403C10.6933 17.0094 10.3058 17.0092 9.98043 16.8397C9.68541 16.686 9.54347 16.411 9.4797 16.2817C9.4059 16.132 9.33053 15.9381 9.2527 15.7379L7.29525 10.7044L2.28825 8.75727C2.27943 8.75384 2.27061 8.75041 2.26181 8.74699C2.06159 8.66915 1.86768 8.59377 1.71798 8.51998C1.58864 8.45621 1.31372 8.31427 1.16001 8.01924C0.990499 7.6939 0.990271 7.30633 1.1594 6.98079C1.31276 6.68558 1.58752 6.54332 1.71678 6.4794C1.86639 6.40542 2.06021 6.32982 2.26034 6.25175C2.26914 6.24831 2.27796 6.24488 2.28678 6.24143L14.9782 1.28869C15.1572 1.21879 15.3356 1.14912 15.4854 1.10563Z"
        transform=""
      />
    </g>
  </svg>
);

SendIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

SendIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

SendIcon.displayName = "SendIcon";

export default SendIcon;
