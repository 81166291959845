/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``face-happy.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const FaceHappyIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25ZM0.75 9C0.75 4.44365 4.44365 0.75 9 0.75C13.5563 0.75 17.25 4.44365 17.25 9C17.25 13.5563 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5563 0.75 9ZM5.625 6.75C5.625 6.12868 6.12868 5.625 6.75 5.625C7.37132 5.625 7.875 6.12868 7.875 6.75C7.875 7.37132 7.37132 7.875 6.75 7.875C6.12868 7.875 5.625 7.37132 5.625 6.75ZM10.125 6.75C10.125 6.12868 10.6287 5.625 11.25 5.625C11.8713 5.625 12.375 6.12868 12.375 6.75C12.375 7.37132 11.8713 7.875 11.25 7.875C10.6287 7.875 10.125 7.37132 10.125 6.75ZM4.875 10.5C4.875 10.0858 5.21079 9.75 5.625 9.75H12.375C12.7892 9.75 13.125 10.0858 13.125 10.5C13.125 11.4183 12.5883 12.2689 11.8665 12.8611C11.1299 13.4655 10.1198 13.875 9 13.875C7.88016 13.875 6.87009 13.4655 6.13349 12.8611C5.41167 12.2689 4.875 11.4183 4.875 10.5ZM6.65629 11.25C6.7648 11.4018 6.9066 11.5552 7.08498 11.7015C7.56703 12.0971 8.24446 12.375 9 12.375C9.75554 12.375 10.433 12.0971 10.915 11.7015C11.0934 11.5552 11.2352 11.4018 11.3437 11.25H6.65629Z"
        transform=""
      />
    </g>
  </svg>
);

FaceHappyIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

FaceHappyIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

FaceHappyIcon.displayName = "FaceHappyIcon";

export default FaceHappyIcon;
