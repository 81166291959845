import _ from "lodash";
import { useMemo } from "react";

import useCustomValuationsForPortfolio from "./useCustomValuationsForPortfolio";
import useShareLotsForPortfolio from "./useShareLotsForPortfolio";

import { usePortfolioCompanies } from "./index";

/**
 * Same as `usePortfolioValue` except uses the custom valuation for the portfolio companies instead
 * of the current ZX Index Value.
 *
 * @param portfolioId
 */
const usePortfolioValueFromCustomValuation = (portfolioId) => {
  const [portfolioCompanies, companiesLoading] =
    usePortfolioCompanies(portfolioId);
  // Get all the share lots and latest custom valuations for all the companies. useFetches guarantees
  // the order of the results matches the order of the queries, and we will make use of that fact
  // below.
  const [shareLots, shareLotsLoading] = useShareLotsForPortfolio(portfolioId);
  const [customValuations, customValuationsLoading] =
    useCustomValuationsForPortfolio(portfolioId);

  const currentValue = useMemo(() => {
    if (!shareLots || !customValuations) {
      return null;
    }
    return (portfolioCompanies || [])
      .map((company, index) => {
        // Make use of the useFetches ordering guarantee to use `index`.
        const companyShareLots = shareLots[index];
        const valuation = customValuations[index];
        if (!valuation) {
          // We only include companies with verified data in the portfolio value calculation.
          return 0;
        }
        // Determine the current value based on the current ZX Index Value multiplied by the total
        // number of shares.
        const totalShareQuantity = companyShareLots.reduce(
          (acc, shareLot) => acc + shareLot.share_quantity,
          0
        );
        return totalShareQuantity * Number(valuation.computed_value);
      })
      .reduce((acc, value) => acc + value, 0);
  }, [customValuations, shareLots, portfolioCompanies]);

  if (
    companiesLoading ||
    customValuationsLoading ||
    shareLotsLoading ||
    _.isEmpty(portfolioCompanies)
  ) {
    return null;
  }
  return currentValue;
};

export default usePortfolioValueFromCustomValuation;
