import React, { useCallback, useContext, useState } from "react";
import { useParams } from "react-router-dom";

import AxiosInstance from "../../api/AxiosInstance";
import { API_ENDPOINTS } from "../../api/constants";
import { AuthContext } from "../../auth";
import { UNKNOWN_ERROR } from "../constants";
import SetNewPassword from "../SetNewPassword";

const ResetPasswordForm = () => {
  const [errors, setErrors] = useState({});
  const params = useParams();
  const token = params.token;
  const { authenticate } = useContext(AuthContext);

  const onSubmit = useCallback(
    (values) => {
      if (!values.password || !values.confirm_password) {
        setErrors({
          submission: undefined,
          ...(values.password ? {} : { password: "Password is required" }),
          ...(values.confirm_password
            ? {}
            : { confirm_password: "Password is required" }),
        });
        return;
      }
      if (values.password !== values.confirm_password) {
        // The view for resetting password uses a library that doesn't have support for
        // password confirmation, so we do it in the frontend.
        setErrors({ submission: "Passwords do not match" });
        return;
      }
      return AxiosInstance.post(
        API_ENDPOINTS.RESET_PASSWORD_SET_PASSWORD(),
        JSON.stringify({
          password: values.password,
          token,
        })
      )
        .then(({ data }) => {
          authenticate(data, true);
        })
        .catch(({ response: { data } }) => {
          let errorMsg = UNKNOWN_ERROR;
          if (data.password) {
            errorMsg = data.password.join(" ");
          } else if (data.detail) {
            errorMsg = "Expired or invalid URL.";
          }
          setErrors({
            submission: errorMsg,
          });
        });
    },
    [authenticate, token]
  );

  return (
    <SetNewPassword
      onSubmit={onSubmit}
      includeAgreements={false}
      errors={errors}
      setErrors={setErrors}
    />
  );
};

export default ResetPasswordForm;
