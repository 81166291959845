const convertRelativeRankingToString = (value) => {
  switch (value) {
    case 24:
      return "Bottom 25%";
    case 49:
      return "Bottom 50%";
    case 54:
      return "Top 50%";
    case 59:
      return "Top 45%";
    case 64:
      return "Top 40%";
    case 69:
      return "Top 35%";
    case 74:
      return "Top 30%";
    case 79:
      return "Top 25%";
    case 84:
      return "Top 20%";
    case 89:
      return "Top 15%";
    case 94:
      return "Top 10%";
    case 97:
      return "Top 5%";
    case 98:
      return "Top 2%";
    case 99:
      return "Top 1%";
    default:
      return "N/A";
  }
};

export default convertRelativeRankingToString;
