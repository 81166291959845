import { PrimaryColorPalette } from "../StylingConstants";

const BadgeStyles = {
  DARK_GRAY: {
    color: PrimaryColorPalette.white80,
    backgroundColor: PrimaryColorPalette.white15,
  },
  MEDIUM_GRAY: {
    color: PrimaryColorPalette.white80,
    backgroundColor: PrimaryColorPalette.white30,
  },
  LIGHT_GRAY: {
    color: PrimaryColorPalette.white80,
    backgroundColor: PrimaryColorPalette.white50,
  },
  WHITE: {
    color: PrimaryColorPalette.black80,
    backgroundColor: PrimaryColorPalette.white100,
  },
  BLACK: {
    color: PrimaryColorPalette.white80,
    backgroundColor: PrimaryColorPalette.black100,
  },
  TEAL: {
    color: PrimaryColorPalette.black80,
    backgroundColor: "#00f6ca",
  },
  LIGHT_BLUE: {
    color: PrimaryColorPalette.black80,
    backgroundColor: PrimaryColorPalette.blue500,
  },
  MEDIUM_BLUE: {
    color: PrimaryColorPalette.black80,
    backgroundColor: "#00acf6",
  },
  DARK_BLUE: {
    color: PrimaryColorPalette.white80,
    backgroundColor: "#0071f6",
  },
  PURPLE: {
    color: PrimaryColorPalette.white80,
    backgroundColor: "#7b00f6",
  },
  PINK: {
    color: PrimaryColorPalette.white80,
    backgroundColor: "#e063eb",
  },
  SELL_RED: {
    color: PrimaryColorPalette.white80,
    backgroundColor: PrimaryColorPalette.sell,
  },
  BUY_GREEN: {
    color: PrimaryColorPalette.black80,
    backgroundColor: PrimaryColorPalette.buy,
  },
  YELLOW: {
    color: PrimaryColorPalette.black80,
    backgroundColor: PrimaryColorPalette.alert,
  },
};

export default BadgeStyles;
