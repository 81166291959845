import { useMemo, useState } from "react";

const useWizardSteps = (steps, config) => {
  const [currentStepNumber, setCurrentStep] = useState(config.initialStep || 1);

  // 1 index "currentStep"
  const currentStepConfig = useMemo(() => steps[currentStepNumber - 1], [steps, currentStepNumber]);

  const stepNames = useMemo(() => steps.map(step => step.name), [steps]);

  const onClickAlt =
    currentStepNumber === 1 && !config.onClickAlt
      ? null
      : () => {
          if (currentStepNumber > 1) {
            setCurrentStep(currentStepNumber - 1);
          }
          config.onClickAlt?.();
        };

  const onSubmit = () => {
    currentStepConfig?.onAdvance?.();
    if (currentStepNumber === steps.length) {
      config.onSubmit();
    } else {
      setCurrentStep(currentStepNumber + 1);
    }
  };

  return {
    onSubmit,
    onClickAlt,
    submitText:
      currentStepNumber === steps.length
        ? config.submitText
        : currentStepConfig?.advanceText ?? config.advanceText,
    currentStep: currentStepNumber,
    leftContent: currentStepConfig?.leftContent,
    rightContent: currentStepConfig?.rightContent,
    stepNames,
  };
};

export default useWizardSteps;
