import { useMemo } from "react";

const useNoDataErrorState = (nullConditions, computedValue) => {
  return useMemo(() => {
    if (nullConditions) {
      return null;
    }
    if (!computedValue && computedValue !== 0) {
      return "No data for the selected date range";
    }
    return null;
  }, [nullConditions, computedValue]);
};

export default useNoDataErrorState;
