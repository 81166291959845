import React from "react";
import { monthDayYear } from "../utils/dateFormats";
import PropTypes from "prop-types";
import { DEFAULT_NO_VALUE } from "./constants";
import { StyledTableNoValueText } from "../StyledComponents";

const DateCell = ({ value, ...props }) => {
  const formatter = props.column.dateFormatter || monthDayYear;
  // Need to be able to render empty dates as "".
  return value ? (
    formatter(value)
  ) : (
    <StyledTableNoValueText>
      {props.column.emptyPlaceholder || DEFAULT_NO_VALUE}
    </StyledTableNoValueText>
  );
};

DateCell.propTypes = {
  value: PropTypes.string,
  column: PropTypes.shape({
    dateFormatter: PropTypes.func,
    emptyPlaceholder: PropTypes.string,
    align: PropTypes.oneOf(["left", "right", "center"]),
  }).isRequired,
};

DateCell.displayName = "DateCell";

export default DateCell;
