import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import DefinitionListHeader from "./DefinitionListHeader";
import { Fonts } from "../Typography";
import { useTooltip } from "../Tooltip";

const StyledWrapper = styled.dl`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 8px 0;
  margin: 0;

  & + ${DefinitionListHeader} {
    // When multiple lists consecutively, give a bit extra space
    // Because it's vertical margins, they collapse, we want 12px total here
    margin-top: 12px;
  }
`;

const StyledLabel = styled(Fonts.Overlinetheme50).attrs({ as: "dt" })`
  line-height: 16px;
  max-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledValue = styled(Fonts.Body1theme80).attrs({ as: "dd" })`
  line-height: 16px;
  margin-left: 24px;
  max-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  white-space: nowrap;
`;

/**
 * A label value pair displayed in a standard arrangement. Typically found in multiples forming a
 * list. Provides reasonable null text if the value is false-y. The element exapnds to fill the
 * space available and will ellipsis out overflow. Provide a formatted value if multiline display
 * is necessary.
 *
 * Include a header in the definition list if there are multiple definition list in the same section
 * or if the least needs emphasis. A header includes a title and optionally an action link.
 */
const Definition = props => {
  const widthMeasuringValueRef = React.useRef(null);
  const widthMeasuringLabelRef = React.useRef(null);
  const [valueWidth, setValueWidth] = React.useState([0, 0]);
  const [labelWidth, setLabelWidth] = React.useState([0, 0]);

  // Automatically inject a tooltip holding the props.children if the children should be truncated.
  React.useEffect(() => {
    setValueWidth([
      widthMeasuringValueRef.current?.scrollWidth,
      widthMeasuringValueRef.current?.offsetWidth,
    ]);
    setLabelWidth([
      widthMeasuringLabelRef.current?.scrollWidth,
      widthMeasuringLabelRef.current?.offsetWidth,
    ]);
  }, [widthMeasuringValueRef.current?.scrollWidth, widthMeasuringValueRef.current?.offsetWidth]);

  const valueTooltip = useTooltip(widthMeasuringValueRef, props.value || "--");
  const labelTooltip = useTooltip(widthMeasuringLabelRef, props.label || "--");

  return (
    <StyledWrapper className={props.className}>
      <StyledLabel ref={widthMeasuringLabelRef}>{props.label}</StyledLabel>
      <StyledValue ref={widthMeasuringValueRef}>{props.value || "--"}</StyledValue>
      {valueWidth[0] > valueWidth[1] && valueTooltip}
      {labelWidth[0] > labelWidth[1] && labelTooltip}
    </StyledWrapper>
  );
};

Definition.propTypes = {
  label: PropTypes.node.isRequired,
  /** If the display value is false-y, defaults to provide a reasonable null text */
  value: PropTypes.node,
  /** Explicit usage discouraged, but can be used to wrap in a styled component */
  className: PropTypes.string,
};

Definition.defaultProps = {
  value: null,
  className: "",
};

const StyledDefinition = styled(Definition)``;

StyledDefinition.displayName = "Definition";

export default StyledDefinition;
export { Definition as DocsDefinition };
