import { useCallback, useEffect, useMemo } from "react";

/**
 * Helper to enable keyboard navigating an editable table. Uses a cell coordinate
 * system to know which row/column to navigate to on various key presses. Must only be used
 * with row objects that have been processed by the useTable hook, not raw row objects,
 * since unprocessed rows may not be sorted properly to allow the coordinate system
 * to function.
 *
 * Handles
 *  - keyboard navigation to give a spreadsheet-esque experience
 */
const useKeyboardNavigation = (
  rows,
  columns,
  selectedCell,
  setSelectedCell,
  editingCell,
  setEditingCell,
  debouncedUpdate
) => {
  const selectedCellCoordinates = useMemo(() => {
    if (!debouncedUpdate) {
      return { row: undefined, col: undefined };
    }
    const row = rows.findIndex(row => row.original.id === selectedCell.row);
    const col = columns.findIndex(col => col.id === selectedCell.col);
    return { row, col };
  }, [columns, rows, selectedCell, debouncedUpdate]);

  const keyPressHandler = useCallback(
    e => {
      // Given that the table can be sorted, we need to use the coordinates of the selected cell
      // to determine the cell row/col IDs the user intends to navigate to.
      if (e.code === "Enter" || e.code === "NumpadEnter") {
        e.preventDefault();
        if (!editingCell) {
          setEditingCell(selectedCell);
        } else {
          // If already editing, stop editing and move to the next row.
          setEditingCell(null);
          if (selectedCellCoordinates.row !== rows.length - 1) {
            setSelectedCell({
              row: rows[selectedCellCoordinates.row + 1].original.id,
              col: selectedCell.col,
            });
          }
        }
      } else if (e.code === "Escape") {
        e.preventDefault();
        setEditingCell(null);
      } else if (e.code === "Tab") {
        e.preventDefault();
        setEditingCell(null);
        if (e.shiftKey && selectedCellCoordinates.col !== 0) {
          // Shift + Tab goes to the left.
          setSelectedCell({
            row: selectedCell.row,
            col: columns[selectedCellCoordinates.col - 1].id,
          });
        } else if (!e.shiftKey && selectedCellCoordinates.col !== columns.length - 1) {
          // Go to the right
          setSelectedCell({
            row: selectedCell.row,
            col: columns[selectedCellCoordinates.col + 1].id,
          });
        }
      } else if (e.code === "ArrowRight") {
        if (!editingCell) {
          e.preventDefault();
          if (selectedCellCoordinates.col !== columns.length - 1) {
            setSelectedCell({
              row: selectedCell.row,
              col: columns[selectedCellCoordinates.col + 1].id,
            });
          }
        }
      } else if (e.code === "ArrowLeft") {
        if (!editingCell) {
          e.preventDefault();
          if (selectedCellCoordinates.col !== 0) {
            setSelectedCell({
              row: selectedCell.row,
              col: columns[selectedCellCoordinates.col - 1].id,
            });
          }
        }
      } else if (e.code === "ArrowUp") {
        if (!editingCell) {
          e.preventDefault();
          if (selectedCellCoordinates.row !== 0) {
            setSelectedCell({
              row: rows[selectedCellCoordinates.row - 1].original.id,
              col: selectedCell.col,
            });
          }
        }
      } else if (e.code === "ArrowDown") {
        if (!editingCell) {
          e.preventDefault();
          if (selectedCellCoordinates.row !== rows.length - 1) {
            setSelectedCell({
              row: rows[selectedCellCoordinates.row + 1].original.id,
              col: selectedCell.col,
            });
          }
        }
      }
      // Do nothing with other keys.
    },
    [selectedCellCoordinates, rows, columns, selectedCell, editingCell]
  );

  useEffect(() => {
    if (debouncedUpdate) {
      window.addEventListener("keydown", keyPressHandler);
    }
    return () => {
      if (debouncedUpdate) {
        window.removeEventListener("keydown", keyPressHandler);
      }
    };
  }, [debouncedUpdate, keyPressHandler]);
};

export default useKeyboardNavigation;
