import styled from "styled-components";
import React from "react";

import { Button, ButtonStyles } from "../Button";

import WizardContext from "./internal/WizardContext";

const StyledButton = styled(Button)`
  width: 100%;
`;

/**
 * Renders a simplified version of the submit buttons that only includes a single button to submit
 * and which stretches the full width of its container.
 */
const SimpleWizardActions = () => {
  const { onSubmit, submitDisabled, submitText } = React.useContext(WizardContext);

  return (
    <StyledButton disabled={submitDisabled} onClick={onSubmit} buttonStyle={ButtonStyles.CTA}>
      {submitText}
    </StyledButton>
  );
};

export default SimpleWizardActions;
