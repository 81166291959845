import React from "react";
import styled from "styled-components";

import Surface from "./internal/Surface";

const StyledSurface = styled(Surface)`
  padding: 12px;
  border-radius: 12px;
`;

const TooltipCard = React.forwardRef((props, ref) => (
  <StyledSurface ref={ref} {...props} elevated />
));

TooltipCard.displayName = "TooltipCard";

export default TooltipCard;
