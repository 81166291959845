/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``refresh.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const RefreshIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M9 3C7.34219 3 5.84261 3.67136 4.75589 4.75883C4.1876 5.32752 3.54226 6.09846 3.02309 6.75H6C6.41421 6.75 6.75 7.08579 6.75 7.5C6.75 7.91421 6.41421 8.25 6 8.25H1.5C1.08579 8.25 0.75 7.91421 0.75 7.5V3C0.75 2.58579 1.08579 2.25 1.5 2.25C1.91421 2.25 2.25 2.58579 2.25 3V5.32083C2.70141 4.77186 3.21514 4.17859 3.69486 3.69853C5.05142 2.34105 6.9282 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C5.57966 16.5 2.69588 14.2111 1.79323 11.0829C1.67839 10.685 1.90791 10.2692 2.30589 10.1544C2.70386 10.0396 3.11958 10.2691 3.23442 10.6671C3.95678 13.1704 6.2657 15 9 15C12.3137 15 15 12.3137 15 9C15 5.68629 12.3137 3 9 3Z"
        transform=""
      />
    </g>
  </svg>
);

RefreshIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

RefreshIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

RefreshIcon.displayName = "RefreshIcon";

export default RefreshIcon;
