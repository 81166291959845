import { useCallback, useMemo, useState } from "react";
import _ from "lodash";

/**
 * Helper to enable editing table data in real-time. Editing rows requires
 * that each row has an `id` field in it. This field hold any primitive value.
 *
 * Handles
 *  - distinguishing the currently selected and currently editing cell
 *  - debouncing the update data function to avoid propagating updates rapid-fire
 *
 */
const useEditableData = (data, columns, updateData) => {
  // Defaults to the top-left corner of the table.
  const [selectedCell, setSelectedCell] = useState(
    updateData
      ? {
          row: data[0]?.id,
          col: columns[0]?.id,
        }
      : { row: undefined, col: undefined }
  );

  // Defaults to nothing being edited.
  const [editingCell, setEditingCell] = useState(null);

  const determineEditingStateOnCellClick = useCallback((row, column, isCellSelected) => {
    if (isCellSelected) {
      // Selected -> Editing state transition by clicking the selected cell.
      setEditingCell({
        row: row.original.id,
        col: column.id,
      });
    } else {
      // Clicking a cell other than the selected cell.
      setSelectedCell({
        row: row.original.id,
        col: column.id,
      });
      setEditingCell(null);
    }
  }, []);

  // Debounces the updateData function to prevent rapid-fire update propagation.
  const debouncedUpdate = useMemo(() => {
    if (updateData) {
      return _.debounce(updateData, 1000, { leading: false, trailing: true });
    }
    return undefined;
  }, [updateData]);

  return {
    selectedCell,
    setSelectedCell,
    editingCell,
    setEditingCell,
    determineEditingStateOnCellClick,
    debouncedUpdate,
  };
};

export default useEditableData;
