import { useRef, useEffect } from "react";

import useDynamicPosition from "../utils/useDynamicPosition";

import useMenu from "./internal/useMenu";
import useIsPopupOpen from "./internal/useIsPopupOpen";

// Possible eventual upgrade - allow a default alignment to be specified

const THRESHOLD = 16;
const OVERHANG = 0;
const SPACING = -4;

/**
 * A hook that integrates the Menu element(s) into an external interface that can be used easily.
 *
 * This hook is intended to be used to anchor a menu relative to an element
 *
 * @param menuItems - An array of objects in the following shape:
 * {
 *   text: string,
 *   icon: func (Icon component),
 *   submenu: menuItems,
 *   disabled: bool,
 * }
 * Optionally, this can be an array of arrays, in which case each array is considered a distinct
 * section. In order to support this, specify the sectioned parameter.
 *
 * @param searchable - a boolean for whether this is searchable. Will error if using multiple
 *   sections in the menu.
 * @param sectioned - a boolean for whether menu items reflect multiple sections. Will error if
 *   enabling search as well
 * @param defaultAbove - a boolean for whether the dropdown default positions above the anchor
 *   if enough space is available
 */
const useAnchoredMenu = menuConfig => {
  const containerRef = useRef(null);
  const elementRef = useRef(null);
  const [isOpen, toggleIsOpen] = useIsPopupOpen(containerRef, elementRef);

  const [style, calculatePosition] = useDynamicPosition({
    containerRef,
    elementRef,
    THRESHOLD,
    OVERHANG,
    SPACING,
    defaultAbove: menuConfig.defaultAbove ?? false,
    orientation: menuConfig.orientation,
  });

  // Once it's visible, recalculate position - unable to listen for the ref being attached
  useEffect(() => {
    if (isOpen) {
      calculatePosition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onItemClick = item => {
    menuConfig.onItemClick?.(item);
    if (menuConfig.closeOnSelect) {
      toggleIsOpen();
    }
  };

  const ariaProps = {
    "aria-haspopup": menuConfig.role || "menu",
  };

  const menuElement = useMenu({ ...menuConfig, onItemClick, elementRef }, style);

  return [isOpen ? menuElement : null, containerRef, toggleIsOpen, ariaProps];
};

export default useAnchoredMenu;
