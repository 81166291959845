import React from "react";
import PropTypes from "prop-types";

const FieldPrefixContext = React.createContext("");

const FormSection = props => {
  const parentSection = React.useContext(FieldPrefixContext);

  return (
    <FieldPrefixContext.Provider value={`${parentSection}${props.name}.`}>
      {props.className ? <div className={props.className}>{props.children}</div> : props.children}
    </FieldPrefixContext.Provider>
  );
};

FormSection.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

FormSection.defaultProps = {
  className: null,
};

export default FormSection;
export { FieldPrefixContext };
