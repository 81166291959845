/**
 * Apply opacity to a 6-character hex code.
 *
 * @param {string} hex - A color hex code of the form "#FFFFF"
 * @param {number} opacity - A number in the interval [0, 1]
 * @return {string}
 */
const applyOpacityToHex = (hex, opacity) => {
  // Convert opacity to 2-character hex suffix
  const opacityHex = Math.round(opacity * 255).toString(16);
  return `${hex}${opacityHex}`;
};

export default applyOpacityToHex;
