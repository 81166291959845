import PropTypes from "prop-types";
import React from "react";
import { Rectangle } from "recharts";

/**
 * This is a factory specifically to use as the `activeBar` prop passed into a recharts `Bar`
 * component. The factory returns a component to render a highlighted bar when the user hovers over
 * it in the company profile line graph, when displaying a volume indicator.
 *
 * `dataKey` will be used to fish the correct chart data value out of the hover state, to know if
 *    the user is hovering specifically the bar or just somewhere in the graph.
 * `barIndex` will be used to determine necessary y-offset to add to the y-value. We space our
 *    bars with 2 pixels of vertical padding between them, so the more bars we stack, the more
 *    additional spacing we need.
 */
const ActiveVolumeBar = (dataKey, color, barIndex) => {
  const Bar = ({ x, y, width, height, ...props }) => {
    return props[dataKey] ? (
      <Rectangle
        fill={color}
        x={x}
        y={y - 2 * barIndex}
        width={width}
        height={height}
      />
    ) : null;
  };

  Bar.propTypes = {
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  };

  return Bar;
};

export default ActiveVolumeBar;
