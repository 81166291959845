import React, { useRef } from "react";
import { useTooltip } from "../Tooltip";
import { StyledTableCell } from "./StyledComponents";
import PropTypes from "prop-types";

/*
 * Exists as its own component so that we can have access to a ref for it to attach
 * tooltips conditionally. Does not handle actual value rendering/value truncation, that is handled
 * by the DefaultCellRenderer.
 */
const RenderedTableCell = ({ cell, row, selectedCell, onClick, tooltipContent }) => {
  const cellRef = useRef(null);
  const tooltip = useTooltip(cellRef, tooltipContent);

  const cellProps = cell.getCellProps();

  return (
    <>
      <StyledTableCell
        ref={cellRef}
        $column={cell.column}
        $selected={row.original.id === selectedCell.row && cell.column.id === selectedCell.col}
        onClick={onClick}
        $row={row}
        {...cellProps}
      >
        {
          // Render the cell contents.
          cell.render("Cell")
        }
      </StyledTableCell>
      {tooltipContent ? tooltip : null}
    </>
  );
};

RenderedTableCell.propTypes = {
  cell: PropTypes.shape({
    render: PropTypes.func,
    getCellProps: PropTypes.func,
    column: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }).isRequired,
  selectedCell: PropTypes.shape({
    row: PropTypes.string,
    col: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
  tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default RenderedTableCell;
