/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``type.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const TypeIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M5.22601 2.25H12.774C13.103 2.24999 13.3868 2.24998 13.6218 2.26601C13.8692 2.28289 14.1166 2.32002 14.361 2.42127C14.9124 2.64963 15.3504 3.08765 15.5787 3.63896C15.68 3.88341 15.7171 4.13084 15.734 4.37821C15.75 4.61316 15.75 4.89702 15.75 5.22601V5.25C15.75 5.66421 15.4142 6 15 6C14.5858 6 14.25 5.66421 14.25 5.25C14.25 4.89028 14.2496 4.65806 14.2375 4.48032C14.2258 4.30932 14.2058 4.2442 14.1929 4.21299C14.1168 4.02922 13.9708 3.88321 13.787 3.80709C13.7558 3.79416 13.6907 3.7742 13.5197 3.76253C13.3419 3.75041 13.1097 3.75 12.75 3.75H9.75V14.25H11.25C11.6642 14.25 12 14.5858 12 15C12 15.4142 11.6642 15.75 11.25 15.75H6.75C6.33579 15.75 6 15.4142 6 15C6 14.5858 6.33579 14.25 6.75 14.25H8.25V3.75H5.25C4.89028 3.75 4.65806 3.75041 4.48032 3.76253C4.30932 3.7742 4.2442 3.79416 4.21299 3.80709C4.02922 3.88321 3.88321 4.02922 3.80709 4.21299C3.79416 4.2442 3.7742 4.30932 3.76253 4.48032C3.75041 4.65806 3.75 4.89028 3.75 5.25C3.75 5.66421 3.41421 6 3 6C2.58579 6 2.25 5.66421 2.25 5.25L2.25 5.22601C2.24999 4.89703 2.24998 4.61316 2.26601 4.37821C2.28289 4.13084 2.32002 3.88341 2.42127 3.63896C2.64963 3.08765 3.08765 2.64963 3.63896 2.42127C3.88341 2.32002 4.13084 2.28289 4.37821 2.26601C4.61316 2.24998 4.89703 2.24999 5.22601 2.25Z"
        transform=""
      />
    </g>
  </svg>
);

TypeIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

TypeIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

TypeIcon.displayName = "TypeIcon";

export default TypeIcon;
