import React, { useCallback, useContext, useEffect, useMemo } from "react";

import { Input as YukaInput } from "yuka";
import { ACTIONS, GlobalContext } from "../routes/StateProvider";
import PropTypes from "prop-types";

/**
 * Component to mimic the interface with Yuka's Input component, but with the added knowledge
 * of our hotkey system.
 */
const Input = React.forwardRef(({ autoFocus, ...props }, ref) => {
  const internalRef = React.useRef(null);
  const actualRef = useMemo(() => ref || internalRef, [ref, internalRef]);
  const { dispatch } = useContext(GlobalContext);

  const handleFocus = useCallback(
    () => dispatch({ type: ACTIONS.disableHotkeys }),
    [dispatch]
  );
  const handleBlur = useCallback(
    () => dispatch({ type: ACTIONS.enableHotkeys }),
    [dispatch]
  );

  // Prevents propagation of hotkey keydown events.
  useEffect(() => {
    const current = actualRef.current;
    if (current) {
      current.addEventListener("focus", handleFocus);
      current.addEventListener("blur", handleBlur);
    }

    return () => {
      if (current) {
        current.removeEventListener("focus", handleFocus);
        current.removeEventListener("blur", handleBlur);
      }
    };
  }, [handleFocus, handleBlur, actualRef]);

  // ORDER OF THESE useEffects IS IMPORTANT; this one must follow the event listener one so that
  // the focus element handler is set before the .focus() call is triggered.
  useEffect(() => {
    if (autoFocus && actualRef.current) {
      actualRef.current.focus();
    }
  }, [actualRef, autoFocus]);

  return <YukaInput {...props} ref={actualRef} />;
});

Input.propTypes = {
  autoFocus: PropTypes.bool,
};

Input.defaultProps = {
  autoFocus: false,
};

Input.displayName = "Input";

export default Input;
