import styled from "styled-components";
import { Fonts, Button, YukaColorPalette, ButtonStyles } from "yuka";

import { PAGE_WIDTH } from "../constants";
import earth from "../images/earth.png";
import { ROUTING_PATH } from "../routes/constants";
import applyOpacityToHex from "../utils/applyOpacityToHex";

const StyledContainer = styled.div`
  ${PAGE_WIDTH};
  flex-grow: 1;
  border: none;
  margin-top: -24px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  background-image: linear-gradient(
      to right,
      ${YukaColorPalette.surface0},
      ${applyOpacityToHex(YukaColorPalette.surface0, 0.4)},
      ${YukaColorPalette.surface0}
    ),
    url(${earth});

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

const CompanyProfileNoAccess = () => {
  return (
    <StyledContainer>
      <Fonts.Headline2theme50>
        You don't have access to this company profile
      </Fonts.Headline2theme50>
      <Button buttonStyle={ButtonStyles.RAISED} url={ROUTING_PATH.COMPANIES()}>
        Back to company screener
      </Button>
    </StyledContainer>
  );
};

export default CompanyProfileNoAccess;
