/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``flash.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const FlashIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M10.0486 0.812035C10.354 0.944612 10.5355 1.26266 10.4942 1.59304L9.84957 6.75001L14.4958 6.75001C14.6766 6.74997 14.8596 6.74994 15.0092 6.76348C15.1507 6.77629 15.4215 6.81017 15.6564 7C15.9254 7.21732 16.0794 7.54624 16.0742 7.89197C16.0697 8.19397 15.9223 8.42375 15.8416 8.54061C15.7562 8.66418 15.639 8.80479 15.5233 8.94361L8.82615 16.9801C8.61301 17.2359 8.25675 17.3206 7.95134 17.188C7.64593 17.0554 7.46448 16.7374 7.50578 16.407L8.1504 11.25L3.50416 11.25C3.32341 11.25 3.14034 11.2501 2.99077 11.2365C2.84932 11.2237 2.57845 11.1899 2.34353 11C2.0746 10.7827 1.92054 10.4538 1.92576 10.108C1.93031 9.80606 2.07769 9.57628 2.15841 9.45941C2.24376 9.33583 2.361 9.19521 2.47674 9.05637C2.48247 9.0495 2.48819 9.04263 2.49391 9.03576L9.17382 1.01987C9.38697 0.764098 9.74322 0.679459 10.0486 0.812035ZM3.85127 9.75001H8.99999C9.21511 9.75001 9.41988 9.84239 9.56225 10.0037C9.70462 10.1649 9.77088 10.3796 9.7442 10.593L9.31149 14.0547L14.1487 8.25001H8.99999C8.78486 8.25001 8.5801 8.15764 8.43773 7.99636C8.29536 7.83509 8.2291 7.62045 8.25578 7.40699L8.68848 3.94535L3.85127 9.75001Z"
        transform=""
      />
    </g>
  </svg>
);

FlashIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

FlashIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

FlashIcon.displayName = "FlashIcon";

export default FlashIcon;
