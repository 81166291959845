import { useContext } from "react";

import DefaultStyleConfiguration from "../utils/DefaultStyleConfiguration";
import { useYukaTheme } from "../../ThemeContext";
import { __internalsSurfaceContext as SurfaceContext } from "../../Layout/internal/Surface";

/**
 * Helper to determine the full style configuration object for tables, given the current
 * Yuka theme context, and the defaults.
 */
const useTableStyles = () => {
  const styles = {};
  const { tableStyles: yukaThemeTableStyles } = useYukaTheme();
  const currentSurface = useContext(SurfaceContext);
  // We always use current surface by default, while also allowing users to specify their own
  // colors.
  const allStyleKeys = Object.keys(DefaultStyleConfiguration(currentSurface - 1));
  // Merge the default styles with the yuka theme context-provided styles.
  // Assumes the keys that are present in DefaultStyleConfiguration are what
  // the Table accepts, so ignores all others.
  allStyleKeys.forEach(key => {
    styles[key] = {
      ...DefaultStyleConfiguration(currentSurface - 1)[key],
      ...yukaThemeTableStyles?.[key],
    };
  });
  return styles;
};

export default useTableStyles;
