import { percentFormat } from "../utils/displayFormatUtils";
import getPercentChange from "../utils/getPercentChange";

const getLastRoundPriceDiff = (last_round_price, zx_index_value) => {
  const lastRoundPriceDiff = percentFormat(
    getPercentChange(zx_index_value, last_round_price),
    0
  );
  return zx_index_value > 0
    ? `${zx_index_value > last_round_price ? "+" : " "}${lastRoundPriceDiff}`
    : null;
};

export { getLastRoundPriceDiff };
