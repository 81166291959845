import React from "react";
import PropTypes from "prop-types";
import NPMDatePicker from "react-datepicker";
import _ from "lodash";
import styled from "styled-components";

import "react-datepicker/dist/react-datepicker.css";

import { StyledInput } from "./internal/StyledComponents";
import InputWrapper from "./internal/InputWrapper";

import { Z_INDEXES } from "../StylingConstants";
import { CalendarIcon } from "../Icons";

const DateInputWrapper = styled(InputWrapper)`
  .react-datepicker__tab-loop {
    z-index: ${Z_INDEXES.zIndexModalVisible};
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

/**
 * A datepicker widget. This is a wrapper around https://reactdatepicker.com/
 * with our own styling.
 *
 * Note that we currently do not pass all props down to the react-datepicker library,
 * so if you need additional configuration from the original library,
 * you may need to update our implementation.
 */
const DatePicker = React.forwardRef((props, ref) => {
  const internalRef = React.useRef(null);
  return (
    <DateInputWrapper {...props} inputRef={ref || internalRef} trailingIcon={CalendarIcon}>
      <React.Suspense>
        <StyledInput
          as={NPMDatePicker}
          ref={ref || internalRef}
          autoComplete="off"
          dateFormat="MM/dd/yy"
          selected={props.value}
          onChange={props.onChange}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          disabled={props.disabled}
          maxDate={props.maxDate}
          minDate={props.minDate}
          placeholderText={props.placeholder}
          name={props.name}
          id={props.id}
        />
      </React.Suspense>
    </DateInputWrapper>
  );
});

DatePicker.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  name: PropTypes.string,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  placeholder: PropTypes.string,
  leadingIcon: PropTypes.func,
  small: PropTypes.bool,
};

DatePicker.defaultProps = {
  disabled: false,
  maxDate: null,
  minDate: null,
  placeholder: "",
  small: false,
};

const formatDate = value => {
  if (_.isDate(value)) {
    return value;
  }
  // https://stackoverflow.com/a/27768777 - see comment
  // 2020-01-30 gets converted as midnight UTC, 2020/01/30 gets converted to midnight local.
  // local time gives reliable value for date picker
  if (value && _.isString(value)) {
    if (!_.includes(value, "T")) {
      return new Date(value.replaceAll("-", "/"));
    }
    return new Date(value);
  }
  return value;
};

export default DatePicker;
export { formatDate };
