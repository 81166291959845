import React from "react";
import { DEFAULT_NO_VALUE, DEFAULT_NUMBER_PRECISION } from "./constants";
import { percentFormat } from "../../utils/displayFormatUtils";
import { StyledTableNoValueText } from "../StyledComponents";
import PropTypes from "prop-types";

const PercentageCell = ({ value, ...props }) => {
  let precision;
  const isUnitInterval = Boolean(props.column.isUnitInterval);
  if (!value && value !== 0) {
    return <StyledTableNoValueText>{DEFAULT_NO_VALUE}</StyledTableNoValueText>;
  }
  // Need to allow for 0 precision.
  if (!props.column.precision && props.column.precision !== 0) {
    precision = DEFAULT_NUMBER_PRECISION;
  } else {
    precision = props.column.precision;
  }
  return percentFormat(isUnitInterval ? value * 100 : value, precision);
};

PercentageCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  column: PropTypes.shape({
    precision: PropTypes.number,
    isUnitInterval: PropTypes.bool,
    align: PropTypes.oneOf(["left", "right", "center"]),
  }).isRequired,
};

PercentageCell.displayName = "PercentageCell";

export default PercentageCell;
