import React from "react";
import _ from "lodash";

// eslint-disable-next-line no-magic-numbers
const FETCH_RATES_MS = [1000, 750, 500, 250];
const DEFAULT_VALUES = "__DEFAULT_VALUES"; // need something that won't conflict with search terms

// Determines if the cachedValue should be updated with the options returned from a fetch. The
// cache should be updated if one of the following conditions holds:
// 1. The returned list of options is not a proper subset of the cached options and the two lists
//    are not equal
// 2. The cache is undefined.
const cacheNeedsUpdate = (retOptions, cachedValue) =>
  !_.isEmpty(_.differenceWith(retOptions, cachedValue, _.isEqual)) || _.isUndefined(cachedValue);

/**
 * React hook to handle parsing local options and remote options into a single source and provide
 * appropriate utils to handle remote search
 *
 * @param {Array} options - If provided, is a default set of options for the select, and can be
 *    supplemented by loadOptions results
 * @param {Function} loadOptions - If provided, is used to retrieve options based on the entered
 *    search to add to the existing option list.
 * @param {Function} loadOptionFromValue - given a value, fetch options to store. This is
 *    useful when an async select has values partially derived outside the context
 * @param {string|Function} uniqBy - The differentiator to determine which options are considered
 *    unique
 *
 * @returns {Array}
 * - parsed options
 * - parsed option groups
 * - fetch function
 * - cached values
 */
const useSelectAsync = ({
  initialOptions: options,
  loadOptions,
  loadOptionFromValue,
  uniqBy = "value",
}) => {
  // The base set of options / option groups and canonical set of options not mapped to internal
  // state. These are unordered and just a set of working options to simulate a set of initially
  // provided options
  const [storedOptions, setStoredOptions] = React.useState(options || []);
  const [fetchingCount, setFetchingCount] = React.useState(0);

  // The cache is used to determine if we have a result set for a given search. Otherwise not used
  const cache = React.useRef({
    // Options defaults to an empty list if not provided. Prevents `null` from appearing in cache
    // results when we have no initial options for async selects
    [DEFAULT_VALUES]: options,
  });

  // Helper method to update the options list to be additive
  const setOptions = React.useCallback(
    newOptions => {
      // Always drop newest version first so if there are dupes, uniqBy doesn't use the cache
      const allOptions = _.uniqBy([...newOptions, ...storedOptions], uniqBy);
      if (cacheNeedsUpdate(allOptions, storedOptions)) {
        setStoredOptions(allOptions);
      }
    },
    [uniqBy, storedOptions]
  );

  React.useEffect(() => {
    if (options !== cache.current[DEFAULT_VALUES]) {
      // Different from setOptions above, use cached values first, so we don't update unnecessarily
      const allOptions = _.uniqBy([...storedOptions, ...options], uniqBy);
      if (cacheNeedsUpdate(allOptions, storedOptions)) {
        setStoredOptions(allOptions);
      }
      cache.current[DEFAULT_VALUES] = options;
    }
  }, [options, storedOptions, uniqBy]);

  // A helper wrapper to conditionally fetch if the callback is provided and hook into state
  const handleFetchFromValue = React.useCallback(
    value => {
      if (loadOptionFromValue && value) {
        loadOptionFromValue(value).then(option => {
          if (option) {
            setOptions([option]);
          }
        });
      }
    },
    [loadOptionFromValue, setOptions]
  );

  // Helper function to trigger any applicable async call to fetch options based on search
  // Cache and debounce to retain reference so we can clear old debounced copies as you continue to
  // search.
  const rawHandleFetch = React.useCallback(
    search => {
      // General structure
      // - Update cache to indicate options fetched
      // - Insert new options into option list
      if (loadOptions) {
        setFetchingCount(count => count + 1);
        loadOptions(_.trim(search)).then(retOptions => {
          setFetchingCount(count => count - 1);
          if (retOptions) {
            cache.current[search] = retOptions;
          }
          setOptions(cache.current[search]);
        });
      }
    },
    [loadOptions, setOptions]
  );
  // Maintain multiple debounce rate versions of the raw fetch to use in adaptive debouncing.
  // Adaptive debouncing increases the debounce duration for shorter-length search queries in
  // an attempt to reduce server load because users are more likely to type more characters.
  const debouncedFetches = React.useMemo(
    () => _.map(FETCH_RATES_MS, rate => _.debounce(rawHandleFetch, rate)),
    [rawHandleFetch]
  );

  // Triggers API call on debounce, cancels any pending ones to prevent the network call
  // We debounce the fetch call since it is typically an API call and to minimize the work the
  // network needs to do.
  const handleFetch = React.useCallback(
    search => {
      if (search.length) {
        // Don't perform unnecessary searches
        const applicableRate = Math.min(search.length, FETCH_RATES_MS.length) - 1;
        // Cancel all pending queries with an equal or greater rate to the applicable rate.
        for (let i = 0; i <= applicableRate; i++) {
          debouncedFetches[i].cancel();
        }
        debouncedFetches[applicableRate](search);
      }
    },
    [debouncedFetches]
  );

  return [
    // Convenience selectors to bypass local state work when unnecessary.
    storedOptions,
    // All in 1 fetch trigger regardless of using option or grouped options
    handleFetch,
    handleFetchFromValue,
    fetchingCount > 0,
  ];
};

export default useSelectAsync;
