/* eslint-disable no-unused-vars */
// needed since color isn't used in the template but appears in the replaced body
import React from "react";
import PropTypes from "prop-types";

/*
 * Yuka v2.0
 * This component corresponds to ``currency.svg``
 *
 * @param {Object} props
 *
 * @returns {React.Element}
 */
const CurrencyIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 18 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="evenodd"
        d="M9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25ZM0.75 9C0.75 4.44365 4.44365 0.75 9 0.75C13.5563 0.75 17.25 4.44365 17.25 9C17.25 13.5563 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5563 0.75 9ZM9 3.375C9.41421 3.375 9.75 3.71079 9.75 4.125V4.5H9.875C11.2557 4.5 12.375 5.61929 12.375 7C12.375 7.41421 12.0392 7.75 11.625 7.75C11.2108 7.75 10.875 7.41421 10.875 7C10.875 6.44772 10.4273 6 9.875 6H8.25C7.62868 6 7.125 6.50368 7.125 7.125C7.125 7.74632 7.62868 8.25 8.25 8.25H9.75C11.1997 8.25 12.375 9.42525 12.375 10.875C12.375 12.3247 11.1997 13.5 9.75 13.5V13.875C9.75 14.2892 9.41421 14.625 9 14.625C8.58579 14.625 8.25 14.2892 8.25 13.875V13.5H8.125C6.74429 13.5 5.625 12.3807 5.625 11C5.625 10.5858 5.96079 10.25 6.375 10.25C6.78921 10.25 7.125 10.5858 7.125 11C7.125 11.5523 7.57271 12 8.125 12H9.75C10.3713 12 10.875 11.4963 10.875 10.875C10.875 10.2537 10.3713 9.75 9.75 9.75H8.25C6.80025 9.75 5.625 8.57475 5.625 7.125C5.625 5.67525 6.80025 4.5 8.25 4.5V4.125C8.25 3.71079 8.58579 3.375 9 3.375Z"
        transform=""
      />
    </g>
  </svg>
);

CurrencyIcon.propTypes = {
  // all of these can be overriden in CSS as necessary
  color: PropTypes.string,
  size: PropTypes.number,
};

CurrencyIcon.defaultProps = {
  color: "#ffffff",
  size: 16,
};

CurrencyIcon.displayName = "CurrencyIcon";

export default CurrencyIcon;
